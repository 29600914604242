export const dummy = {
  map: "https://earthengine.googleapis.com/v1alpha/projects/save-me-1053/maps/f10f505ba48f41320e966fa1e53442f5-a6ffb4e66cb168593552e2b5433e5d7f/tiles/{z}/{x}/{y}",
  statistics: [
    {
      data_date: "2002-01-01",
      mean: 34.8057048845,
      max: 100.0,
      min: 0.0,
      median: 34.2503244152,
      stdDev: 21.029606937,
      variance: 442.2443679239,
      sum: 47423768.8942247182,
    },
    {
      data_date: "2002-02-01",
      mean: 63.748268216,
      max: 100.0,
      min: 0.0,
      median: 62.7524221611,
      stdDev: 18.6478272986,
      variance: 347.7414629602,
      sum: 86858839.6447218359,
    },
    {
      data_date: "2002-03-01",
      mean: 41.7812005379,
      max: 100.0,
      min: 0.0,
      median: 40.7497215981,
      stdDev: 23.2560459918,
      variance: 540.8436751709,
      sum: 56928081.3305832222,
    },
    {
      data_date: "2002-04-01",
      mean: 30.7572566862,
      max: 100.0,
      min: 0.0,
      median: 28.2470892365,
      stdDev: 20.928117822,
      variance: 437.9861155724,
      sum: 41907642.3749205023,
    },
    {
      data_date: "2002-05-01",
      mean: 61.0212451668,
      max: 100.0,
      min: 0.0,
      median: 60.2497967714,
      stdDev: 21.4418518963,
      variance: 459.7530127435,
      sum: 83143192.7045263052,
    },
    {
      data_date: "2002-06-01",
      mean: 14.7131780451,
      max: 100.0,
      min: 0.0,
      median: 7.7497865998,
      stdDev: 17.9834411081,
      variance: 323.4041540884,
      sum: 20047126.1141147017,
    },
    {
      data_date: "2002-07-01",
      mean: 68.4594276315,
      max: 100.0,
      min: 0.0,
      median: 68.753630805,
      stdDev: 19.5654055952,
      variance: 382.8050961051,
      sum: 93277929.1614225209,
    },
    {
      data_date: "2002-08-01",
      mean: 31.7437192594,
      max: 100.0,
      min: 0.0,
      median: 30.2501734412,
      stdDev: 21.4386736033,
      variance: 459.6167258688,
      sum: 43251725.8592273295,
    },
    {
      data_date: "2002-09-01",
      mean: 23.2010215718,
      max: 100.0,
      min: 0.0,
      median: 20.7514292183,
      stdDev: 19.4371382769,
      variance: 377.8023443935,
      sum: 31612055.8047880568,
    },
    {
      data_date: "2002-10-01",
      mean: 40.764758716,
      max: 100.0,
      min: 0.0,
      median: 38.7496299824,
      stdDev: 26.6876125632,
      variance: 712.2286643249,
      sum: 55543150.2621639073,
    },
    {
      data_date: "2002-11-01",
      mean: 59.9173638346,
      max: 100.0,
      min: 0.0,
      median: 59.7506577034,
      stdDev: 20.8213098831,
      variance: 433.5269452463,
      sum: 81639122.8011853844,
    },
    {
      data_date: "2002-12-01",
      mean: 34.188856852,
      max: 100.0,
      min: 0.0,
      median: 31.7510795161,
      stdDev: 24.5774298923,
      variance: 604.0500601095,
      sum: 46583295.7984002978,
    },
    {
      data_date: "2003-01-01",
      mean: 56.8221786802,
      max: 100.0,
      min: 0.0,
      median: 56.7477772364,
      stdDev: 27.88203434,
      variance: 777.4078389394,
      sum: 77421844.4573761821,
    },
    {
      data_date: "2003-02-01",
      mean: 51.9848101213,
      max: 100.0,
      min: 0.0,
      median: 50.7497684346,
      stdDev: 19.7851468237,
      variance: 391.4520348363,
      sum: 70830791.3712646663,
    },
    {
      data_date: "2003-03-01",
      mean: 54.4175154238,
      max: 100.0,
      min: 0.0,
      median: 53.7500828346,
      stdDev: 20.8358163869,
      variance: 434.131244507,
      sum: 74145421.9594281316,
    },
    {
      data_date: "2003-04-01",
      mean: 29.5037357933,
      max: 100.0,
      min: 0.0,
      median: 27.2485579217,
      stdDev: 18.0330038306,
      variance: 325.1892271534,
      sum: 40199684.288039647,
    },
    {
      data_date: "2003-05-01",
      mean: 53.8203409579,
      max: 100.0,
      min: 0.0,
      median: 52.7474095297,
      stdDev: 21.9792390078,
      variance: 483.0869473607,
      sum: 73331754.6611845046,
    },
    {
      data_date: "2003-06-01",
      mean: 50.4367585285,
      max: 100.0,
      min: 0.0,
      median: 49.7501588729,
      stdDev: 20.0852970185,
      variance: 403.4191563234,
      sum: 68721526.7775117755,
    },
    {
      data_date: "2003-07-01",
      mean: 43.832590888,
      max: 100.0,
      min: 0.0,
      median: 42.7483762245,
      stdDev: 18.6611635923,
      variance: 348.2390266179,
      sum: 59723159.3845793754,
    },
    {
      data_date: "2003-08-01",
      mean: 58.0329515774,
      max: 100.0,
      min: 0.0,
      median: 56.7504391072,
      stdDev: 21.7403751639,
      variance: 472.643912268,
      sum: 79071557.1769946814,
    },
    {
      data_date: "2003-09-01",
      mean: 81.1060530555,
      max: 100.0,
      min: 0.0,
      median: 83.2504882192,
      stdDev: 17.0293880005,
      variance: 290.0000556717,
      sum: 110509318.1934904605,
    },
    {
      data_date: "2003-10-01",
      mean: 30.8475232903,
      max: 100.0,
      min: 0.0,
      median: 28.750661878,
      stdDev: 18.6170379525,
      variance: 346.5941021266,
      sum: 42030633.2061236054,
    },
    {
      data_date: "2003-11-01",
      mean: 6.5839364089,
      max: 100.0,
      min: 0.0,
      median: 0.7497698363,
      stdDev: 9.6129936227,
      variance: 92.4096463909,
      sum: 8970801.7610322833,
    },
    {
      data_date: "2003-12-01",
      mean: 24.0735627264,
      max: 100.0,
      min: 0.0,
      median: 20.750574336,
      stdDev: 20.5496772019,
      variance: 422.2892331027,
      sum: 32800918.0962300897,
    },
    {
      data_date: "2004-01-01",
      mean: 31.4539028121,
      max: 100.0,
      min: 0.0,
      median: 28.2503022481,
      stdDev: 25.3711298318,
      variance: 643.694228941,
      sum: 42856842.6565072089,
    },
    {
      data_date: "2004-02-01",
      mean: 61.7951653414,
      max: 100.0,
      min: 0.0,
      median: 60.7492354311,
      stdDev: 21.2233900158,
      variance: 450.4322837616,
      sum: 84197681.0886563957,
    },
    {
      data_date: "2004-03-01",
      mean: 69.0036494729,
      max: 100.0,
      min: 0.0,
      median: 68.7471819412,
      stdDev: 21.1536843483,
      variance: 447.4783615066,
      sum: 94019446.9935678244,
    },
    {
      data_date: "2004-04-01",
      mean: 23.6043906844,
      max: 100.0,
      min: 0.0,
      median: 19.7500557519,
      stdDev: 20.5563603535,
      variance: 422.5639509817,
      sum: 32161657.7633606978,
    },
    {
      data_date: "2004-05-01",
      mean: 24.5456190484,
      max: 100.0,
      min: 0.0,
      median: 23.751416981,
      stdDev: 16.9921163052,
      variance: 288.7320165301,
      sum: 33444108.3431756534,
    },
    {
      data_date: "2004-06-01",
      mean: 22.1256572552,
      max: 100.0,
      min: 0.0,
      median: 18.7481572716,
      stdDev: 18.080698434,
      variance: 326.9116558607,
      sum: 30146841.1511358768,
    },
    {
      data_date: "2004-07-01",
      mean: 37.7988859297,
      max: 100.0,
      min: 0.0,
      median: 36.7490331986,
      stdDev: 16.8926591785,
      variance: 285.3619341222,
      sum: 51502063.7202699631,
    },
    {
      data_date: "2004-08-01",
      mean: 18.9759533887,
      max: 100.0,
      min: 0.0,
      median: 14.7479961676,
      stdDev: 19.2180048975,
      variance: 369.3317122395,
      sum: 25855279.5019653663,
    },
    {
      data_date: "2004-09-01",
      mean: 41.6679104665,
      max: 100.0,
      min: 0.0,
      median: 40.2508013751,
      stdDev: 22.4528096048,
      variance: 504.1286591474,
      sum: 56773720.3664320558,
    },
    {
      data_date: "2004-10-01",
      mean: 20.8740573096,
      max: 100.0,
      min: 0.0,
      median: 17.2487544472,
      stdDev: 19.0870063794,
      variance: 364.3138125265,
      sum: 28441500.4098279029,
    },
    {
      data_date: "2004-11-01",
      mean: 39.7355904658,
      max: 100.0,
      min: 0.0,
      median: 38.2502667856,
      stdDev: 19.1468143157,
      variance: 366.6004984411,
      sum: 54140879.070903793,
    },
    {
      data_date: "2004-12-01",
      mean: 70.5717883634,
      max: 100.0,
      min: 0.0,
      median: 73.2501245785,
      stdDev: 23.9895462374,
      variance: 575.4983286771,
      sum: 96156081.1052731574,
    },
    {
      data_date: "2005-01-01",
      mean: 66.4054406505,
      max: 100.0,
      min: 0.0,
      median: 68.2490121947,
      stdDev: 26.1881413916,
      variance: 685.8187495485,
      sum: 90479313.1234990954,
    },
    {
      data_date: "2005-02-01",
      mean: 68.2813260711,
      max: 100.0,
      min: 0.0,
      median: 71.250020196,
      stdDev: 26.3182063239,
      variance: 692.6479841089,
      sum: 93035260.6888965964,
    },
    {
      data_date: "2005-03-01",
      mean: 61.070284041,
      max: 100.0,
      min: 0.0,
      median: 63.2489955363,
      stdDev: 29.3209874882,
      variance: 859.7203072811,
      sum: 83210009.5740034282,
    },
    {
      data_date: "2005-04-01",
      mean: 30.6999501674,
      max: 100.0,
      min: 0.0,
      median: 27.749775062,
      stdDev: 21.5322249136,
      variance: 463.636709731,
      sum: 41829560.6032555103,
    },
    {
      data_date: "2005-05-01",
      mean: 69.6782599461,
      max: 100.0,
      min: 0.0,
      median: 69.248541634,
      stdDev: 20.618494288,
      variance: 425.1223067045,
      sum: 94938623.067836374,
    },
    {
      data_date: "2005-06-01",
      mean: 83.6884821369,
      max: 100.0,
      min: 0.0,
      median: 91.2502320624,
      stdDev: 19.1155618633,
      variance: 365.4047053514,
      sum: 114027951.7149360329,
    },
    {
      data_date: "2005-07-01",
      mean: 69.7061271488,
      max: 100.0,
      min: 0.0,
      median: 69.2499073982,
      stdDev: 20.5831789101,
      variance: 423.6672540432,
      sum: 94976592.928945303,
    },
    {
      data_date: "2005-08-01",
      mean: 64.7179683701,
      max: 100.0,
      min: 0.0,
      median: 64.7491575693,
      stdDev: 22.2640218104,
      variance: 495.6866671746,
      sum: 88180083.8533186316,
    },
    {
      data_date: "2005-09-01",
      mean: 66.2787511847,
      max: 100.0,
      min: 0.0,
      median: 66.7500306341,
      stdDev: 24.2746817568,
      variance: 589.2601743956,
      sum: 90306695.1010419428,
    },
    {
      data_date: "2005-10-01",
      mean: 59.8342217666,
      max: 100.0,
      min: 0.0,
      median: 58.7495304543,
      stdDev: 26.7930123091,
      variance: 717.8655085935,
      sum: 81525839.3543612659,
    },
    {
      data_date: "2005-11-01",
      mean: 61.6342890658,
      max: 100.0,
      min: 0.0,
      median: 62.753392746,
      stdDev: 26.4720864394,
      variance: 700.7713604553,
      sum: 83978482.5596166551,
    },
    {
      data_date: "2005-12-01",
      mean: 45.6263736917,
      max: 100.0,
      min: 0.0,
      median: 42.7505900295,
      stdDev: 19.9260961369,
      variance: 397.0493072587,
      sum: 62167239.7849485204,
    },
    {
      data_date: "2006-01-01",
      mean: 55.3037917736,
      max: 100.0,
      min: 0.0,
      median: 53.7461288872,
      stdDev: 20.0828349826,
      variance: 403.3202609372,
      sum: 75352998.8475433886,
    },
    {
      data_date: "2006-02-01",
      mean: 55.0732260949,
      max: 100.0,
      min: 0.0,
      median: 54.2511682683,
      stdDev: 21.6030486664,
      variance: 466.6917116809,
      sum: 75038846.5123978257,
    },
    {
      data_date: "2006-03-01",
      mean: 37.252147462,
      max: 100.0,
      min: 0.0,
      median: 36.2505830835,
      stdDev: 20.9237178151,
      variance: 437.801967207,
      sum: 50757116.9126960337,
    },
    {
      data_date: "2006-04-01",
      mean: 33.6453956974,
      max: 100.0,
      min: 0.0,
      median: 30.7497771182,
      stdDev: 22.2209854272,
      variance: 493.7721933571,
      sum: 45842814.423749581,
    },
    {
      data_date: "2006-05-01",
      mean: 51.8181302457,
      max: 100.0,
      min: 0.0,
      median: 51.2496958728,
      stdDev: 18.1684441672,
      variance: 330.0923634582,
      sum: 70603685.2711329609,
    },
    {
      data_date: "2006-06-01",
      mean: 53.8054569552,
      max: 100.0,
      min: 0.0,
      median: 51.7484296523,
      stdDev: 19.1528189914,
      variance: 366.8304753162,
      sum: 73311474.781475246,
    },
    {
      data_date: "2006-07-01",
      mean: 38.0788375056,
      max: 100.0,
      min: 0.0,
      median: 37.7503077403,
      stdDev: 17.6645042043,
      variance: 312.0347087845,
      sum: 51883505.7534446865,
    },
    {
      data_date: "2006-08-01",
      mean: 62.4969939211,
      max: 100.0,
      min: 0.0,
      median: 62.2498181245,
      stdDev: 20.6792313717,
      variance: 427.6306101247,
      sum: 85153942.6118547767,
    },
    {
      data_date: "2006-09-01",
      mean: 56.1869320316,
      max: 100.0,
      min: 0.0,
      median: 55.7495724025,
      stdDev: 22.7054902291,
      variance: 515.5392865438,
      sum: 76556302.7207018435,
    },
    {
      data_date: "2006-10-01",
      mean: 32.0119876121,
      max: 100.0,
      min: 0.0,
      median: 28.7491053808,
      stdDev: 24.1975881436,
      variance: 585.523271967,
      sum: 43617249.1664888859,
    },
    {
      data_date: "2006-11-01",
      mean: 65.7186482622,
      max: 100.0,
      min: 0.0,
      median: 65.2512089925,
      stdDev: 20.6422852023,
      variance: 426.103938372,
      sum: 89543538.8415023834,
    },
    {
      data_date: "2006-12-01",
      mean: 52.8909655415,
      max: 100.0,
      min: 0.0,
      median: 52.2494054196,
      stdDev: 22.3820270718,
      variance: 500.955135845,
      sum: 72065454.0615704954,
    },
    {
      data_date: "2007-01-01",
      mean: 63.0207815976,
      max: 100.0,
      min: 0.0,
      median: 62.2511527682,
      stdDev: 23.6927901866,
      variance: 561.3483068281,
      sum: 85867618.3096401989,
    },
    {
      data_date: "2007-02-01",
      mean: 74.5464339655,
      max: 100.0,
      min: 0.0,
      median: 75.7499271835,
      stdDev: 20.6407056133,
      variance: 426.038728216,
      sum: 101571649.4754048288,
    },
    {
      data_date: "2007-03-01",
      mean: 75.3155418125,
      max: 100.0,
      min: 0.0,
      median: 76.7521577531,
      stdDev: 20.2742252948,
      variance: 411.0442113043,
      sum: 102619580.9254136086,
    },
    {
      data_date: "2007-04-01",
      mean: 49.8823733855,
      max: 100.0,
      min: 0.0,
      median: 46.7497713325,
      stdDev: 23.2141236681,
      variance: 538.8955376797,
      sum: 67966161.1560377032,
    },
    {
      data_date: "2007-05-01",
      mean: 49.3811851123,
      max: 100.0,
      min: 0.0,
      median: 47.2496739669,
      stdDev: 22.1612270729,
      variance: 491.119985377,
      sum: 67283277.7919599712,
    },
    {
      data_date: "2007-06-01",
      mean: 26.2162349567,
      max: 100.0,
      min: 0.0,
      median: 23.2512671142,
      stdDev: 20.7681658462,
      variance: 431.3167126159,
      sum: 35720370.3240418732,
    },
    {
      data_date: "2007-07-01",
      mean: 85.0115000886,
      max: 100.0,
      min: 0.0,
      median: 89.2492595887,
      stdDev: 15.8097458091,
      variance: 249.9480625483,
      sum: 115830601.5331099033,
    },
    {
      data_date: "2007-08-01",
      mean: 65.9441814684,
      max: 100.0,
      min: 0.0,
      median: 64.7505776764,
      stdDev: 20.9169804285,
      variance: 437.5200702475,
      sum: 89850834.2887366712,
    },
    {
      data_date: "2007-09-01",
      mean: 34.0025110411,
      max: 100.0,
      min: 0.0,
      median: 31.7510405411,
      stdDev: 24.6695866859,
      variance: 608.5885072544,
      sum: 46329394.2986619473,
    },
    {
      data_date: "2007-10-01",
      mean: 56.9811874238,
      max: 100.0,
      min: 0.0,
      median: 55.247332326,
      stdDev: 27.169035189,
      variance: 738.1564731019,
      sum: 77638498.420747146,
    },
    {
      data_date: "2007-11-01",
      mean: 37.5853447977,
      max: 100.0,
      min: 0.0,
      median: 35.7509201156,
      stdDev: 18.9590255662,
      variance: 359.4446504217,
      sum: 51211107.8173429966,
    },
    {
      data_date: "2007-12-01",
      mean: 19.8694850679,
      max: 100.0,
      min: 0.0,
      median: 18.7493327551,
      stdDev: 12.4698062476,
      variance: 155.4960678517,
      sum: 27072741.9840137511,
    },
    {
      data_date: "2008-01-01",
      mean: 28.7956141435,
      max: 100.0,
      min: 0.0,
      median: 27.2493729571,
      stdDev: 15.3485548561,
      variance: 235.5781361702,
      sum: 39234848.2767292559,
    },
    {
      data_date: "2008-02-01",
      mean: 37.3867160068,
      max: 100.0,
      min: 0.0,
      median: 34.2496957927,
      stdDev: 21.7664798325,
      variance: 473.7796442984,
      sum: 50940470.4057777748,
    },
    {
      data_date: "2008-03-01",
      mean: 52.604977866,
      max: 100.0,
      min: 0.0,
      median: 49.7502817473,
      stdDev: 21.1846579846,
      variance: 448.7897339256,
      sum: 71675787.6699411869,
    },
    {
      data_date: "2008-04-01",
      mean: 34.8617985668,
      max: 100.0,
      min: 0.0,
      median: 32.7495631872,
      stdDev: 18.3437320246,
      variance: 336.4925045917,
      sum: 47500198.141508311,
    },
    {
      data_date: "2008-05-01",
      mean: 16.7258090982,
      max: 100.0,
      min: 0.0,
      median: 14.7507248855,
      stdDev: 15.5833334888,
      variance: 242.8402826235,
      sum: 22789393.5167710856,
    },
    {
      data_date: "2008-06-01",
      mean: 27.7858822931,
      max: 100.0,
      min: 0.0,
      median: 26.7488264397,
      stdDev: 16.9302509012,
      variance: 286.6333955762,
      sum: 37859059.7364399284,
    },
    {
      data_date: "2008-07-01",
      mean: 34.9228270388,
      max: 100.0,
      min: 0.0,
      median: 34.2490827815,
      stdDev: 17.0134063479,
      variance: 289.455995558,
      sum: 47583351.18109072,
    },
    {
      data_date: "2008-08-01",
      mean: 41.0870289326,
      max: 100.0,
      min: 0.0,
      median: 39.7505883167,
      stdDev: 20.4227136788,
      variance: 417.0872340058,
      sum: 55982252.6542227045,
    },
    {
      data_date: "2008-09-01",
      mean: 36.5367641786,
      max: 100.0,
      min: 0.0,
      median: 35.248882722,
      stdDev: 20.7286722082,
      variance: 429.6778515143,
      sum: 49782386.7178912461,
    },
    {
      data_date: "2008-10-01",
      mean: 42.7454657244,
      max: 100.0,
      min: 0.0,
      median: 41.2507919723,
      stdDev: 22.3640152576,
      variance: 500.1491784427,
      sum: 58241920.2403012365,
    },
    {
      data_date: "2008-11-01",
      mean: 54.2115355884,
      max: 100.0,
      min: 0.0,
      median: 52.253922421,
      stdDev: 21.6754194676,
      variance: 469.8238090972,
      sum: 73864768.5394323766,
    },
    {
      data_date: "2008-12-01",
      mean: 25.1765054435,
      max: 100.0,
      min: 0.0,
      median: 22.7496503735,
      stdDev: 15.0100235559,
      variance: 225.3008071487,
      sum: 34303709.1097019762,
    },
    {
      data_date: "2009-01-01",
      mean: 21.239965019,
      max: 100.0,
      min: 0.0,
      median: 18.2506938088,
      stdDev: 16.7257334365,
      variance: 279.7501589903,
      sum: 28940060.1345528439,
    },
    {
      data_date: "2009-02-01",
      mean: 18.6761199131,
      max: 100.0,
      min: 0.0,
      median: 16.2500347155,
      stdDev: 15.646626819,
      variance: 244.8169308129,
      sum: 25446747.8115246519,
    },
    {
      data_date: "2009-03-01",
      mean: 43.5074686507,
      max: 100.0,
      min: 0.0,
      median: 41.2484354625,
      stdDev: 22.4062177802,
      variance: 502.0385952149,
      sum: 59280171.0326076299,
    },
    {
      data_date: "2009-04-01",
      mean: 19.4018547826,
      max: 100.0,
      min: 0.0,
      median: 16.7490531202,
      stdDev: 16.7059546625,
      variance: 279.0889211867,
      sum: 26435582.3386504464,
    },
    {
      data_date: "2009-05-01",
      mean: 34.4520376346,
      max: 100.0,
      min: 0.0,
      median: 31.2475598984,
      stdDev: 19.9105557437,
      variance: 396.4302300242,
      sum: 46941887.1458517462,
    },
    {
      data_date: "2009-06-01",
      mean: 41.9014683967,
      max: 100.0,
      min: 0.0,
      median: 39.7495720293,
      stdDev: 17.1956841893,
      variance: 295.6915547393,
      sum: 57091949.7298366725,
    },
    {
      data_date: "2009-07-01",
      mean: 37.6511363791,
      max: 100.0,
      min: 0.0,
      median: 36.248309721,
      stdDev: 16.6879818143,
      variance: 278.4887370341,
      sum: 51300750.7296045274,
    },
    {
      data_date: "2009-08-01",
      mean: 56.9381055512,
      max: 100.0,
      min: 0.0,
      median: 55.2515888485,
      stdDev: 17.6630393297,
      variance: 311.9829583642,
      sum: 77579798.1364241242,
    },
    {
      data_date: "2009-09-01",
      mean: 33.3434181423,
      max: 100.0,
      min: 0.0,
      median: 32.2494842777,
      stdDev: 19.7322297561,
      variance: 389.3608911485,
      sum: 45431361.3636684716,
    },
    {
      data_date: "2009-10-01",
      mean: 43.3401900898,
      max: 100.0,
      min: 0.0,
      median: 41.7517512377,
      stdDev: 22.413844329,
      variance: 502.3804176069,
      sum: 59052249.2066467106,
    },
    {
      data_date: "2009-11-01",
      mean: 39.0149634837,
      max: 100.0,
      min: 0.0,
      median: 37.2488239355,
      stdDev: 21.57630532,
      variance: 465.5369512618,
      sum: 53159004.1864676774,
    },
    {
      data_date: "2009-12-01",
      mean: 19.2396063173,
      max: 100.0,
      min: 0.0,
      median: 14.7486924841,
      stdDev: 18.0759388753,
      variance: 326.7395662238,
      sum: 26214514.1618511155,
    },
    {
      data_date: "2010-01-01",
      mean: 24.884969506,
      max: 100.0,
      min: 0.0,
      median: 23.7495658281,
      stdDev: 18.2711198822,
      variance: 333.8338217511,
      sum: 33906483.0523556918,
    },
    {
      data_date: "2010-02-01",
      mean: 19.6462174054,
      max: 100.0,
      min: 0.0,
      median: 16.251190334,
      stdDev: 19.7380181287,
      variance: 389.5893596487,
      sum: 26768533.4049040154,
    },
    {
      data_date: "2010-03-01",
      mean: 20.1347899591,
      max: 100.0,
      min: 0.0,
      median: 17.2509229018,
      stdDev: 18.5004978345,
      variance: 342.2684201255,
      sum: 27434227.4901442677,
    },
    {
      data_date: "2010-04-01",
      mean: 35.9398600097,
      max: 100.0,
      min: 0.0,
      median: 33.7515709973,
      stdDev: 19.0239644455,
      variance: 361.911223223,
      sum: 48969087.7069431841,
    },
    {
      data_date: "2010-05-01",
      mean: 25.4540030634,
      max: 100.0,
      min: 0.0,
      median: 24.2499163939,
      stdDev: 15.7087149196,
      variance: 246.7637244239,
      sum: 34681807.5577021465,
    },
    {
      data_date: "2010-06-01",
      mean: 49.329649827,
      max: 100.0,
      min: 0.0,
      median: 46.2519052252,
      stdDev: 28.0278152528,
      variance: 785.5584278459,
      sum: 67213059.491058141,
    },
    {
      data_date: "2010-07-01",
      mean: 49.7780533794,
      max: 100.0,
      min: 0.0,
      median: 47.7495272166,
      stdDev: 22.0019348446,
      variance: 484.0851369054,
      sum: 67824022.1625986993,
    },
    {
      data_date: "2010-08-01",
      mean: 35.2120443008,
      max: 100.0,
      min: 0.0,
      median: 29.752065087,
      stdDev: 28.9006015068,
      variance: 835.2447674524,
      sum: 47977417.9767166674,
    },
    {
      data_date: "2010-09-01",
      mean: 35.3349871126,
      max: 100.0,
      min: 0.0,
      median: 30.7494292429,
      stdDev: 25.9565180884,
      variance: 673.7408312717,
      sum: 48144931.075837113,
    },
    {
      data_date: "2010-10-01",
      mean: 53.1446832516,
      max: 100.0,
      min: 0.0,
      median: 52.7489146272,
      stdDev: 23.7442990827,
      variance: 563.7917389276,
      sum: 72411151.7018541843,
    },
    {
      data_date: "2010-11-01",
      mean: 31.7680707321,
      max: 100.0,
      min: 0.0,
      median: 31.2501818069,
      stdDev: 18.0723401916,
      variance: 326.6094800017,
      sum: 43284905.4376981184,
    },
    {
      data_date: "2010-12-01",
      mean: 15.4296701901,
      max: 100.0,
      min: 0.0,
      median: 14.7489205523,
      stdDev: 12.7298741192,
      variance: 162.0496950914,
      sum: 21023367.1646122709,
    },
    {
      data_date: "2011-01-01",
      mean: 12.776533084,
      max: 100.0,
      min: 0.0,
      median: 8.2500816776,
      stdDev: 14.3092859422,
      variance: 204.7556641769,
      sum: 17408391.9361832142,
    },
    {
      data_date: "2011-02-01",
      mean: 33.7593681625,
      max: 100.0,
      min: 0.0,
      median: 33.7505348552,
      stdDev: 17.3045374265,
      variance: 299.447015546,
      sum: 45998105.1689520478,
    },
    {
      data_date: "2011-03-01",
      mean: 40.077495889,
      max: 100.0,
      min: 0.0,
      median: 38.2459904021,
      stdDev: 25.886005448,
      variance: 670.0852780549,
      sum: 54606734.9938375428,
    },
    {
      data_date: "2011-04-01",
      mean: 27.7445291788,
      max: 100.0,
      min: 0.0,
      median: 25.7470218151,
      stdDev: 20.0960656389,
      variance: 403.8518541616,
      sum: 37802714.9348762259,
    },
    {
      data_date: "2011-05-01",
      mean: 17.4035513297,
      max: 100.0,
      min: 0.0,
      median: 15.7497422982,
      stdDev: 14.045842102,
      variance: 197.2856803532,
      sum: 23712836.7012989968,
    },
    {
      data_date: "2011-06-01",
      mean: 45.47263489,
      max: 100.0,
      min: 0.0,
      median: 43.7475606686,
      stdDev: 22.9409788123,
      variance: 526.2885088667,
      sum: 61957766.2682837173,
    },
    {
      data_date: "2011-07-01",
      mean: 33.4097051904,
      max: 100.0,
      min: 0.0,
      median: 31.7487340573,
      stdDev: 18.2143432861,
      variance: 331.7623013456,
      sum: 45521679.3635889292,
    },
    {
      data_date: "2011-08-01",
      mean: 38.9891049758,
      max: 100.0,
      min: 0.0,
      median: 37.2501871882,
      stdDev: 21.8365532082,
      variance: 476.8350560147,
      sum: 53123771.2295322567,
    },
    {
      data_date: "2011-09-01",
      mean: 31.9741827854,
      max: 100.0,
      min: 0.0,
      median: 29.7501444758,
      stdDev: 22.1159538721,
      variance: 489.1154156719,
      sum: 43565739.0083026588,
    },
    {
      data_date: "2011-10-01",
      mean: 46.3931932643,
      max: 100.0,
      min: 0.0,
      median: 44.7503897755,
      stdDev: 23.7039329899,
      variance: 561.8764391915,
      sum: 63212053.3956072032,
    },
    {
      data_date: "2011-11-01",
      mean: 43.2672765268,
      max: 100.0,
      min: 0.0,
      median: 42.7498829106,
      stdDev: 21.9715606528,
      variance: 482.749477518,
      sum: 58952902.3905230612,
    },
    {
      data_date: "2011-12-01",
      mean: 40.6476944064,
      max: 100.0,
      min: 0.0,
      median: 38.249788108,
      stdDev: 19.472277258,
      variance: 379.1695816123,
      sum: 55383646.7904313058,
    },
    {
      data_date: "2012-01-01",
      mean: 44.6239360246,
      max: 100.0,
      min: 0.0,
      median: 43.2505476136,
      stdDev: 19.2545616891,
      variance: 370.7381458394,
      sum: 60801389.7781334221,
    },
    {
      data_date: "2012-02-01",
      mean: 33.4650570812,
      max: 100.0,
      min: 0.0,
      median: 32.25105551,
      stdDev: 21.1147862193,
      variance: 445.8341970875,
      sum: 45597097.8987130821,
    },
    {
      data_date: "2012-03-01",
      mean: 54.5892026372,
      max: 100.0,
      min: 0.0,
      median: 53.2494895518,
      stdDev: 19.8186008255,
      variance: 392.7769386812,
      sum: 74379350.7006499916,
    },
    {
      data_date: "2012-04-01",
      mean: 24.3785836462,
      max: 100.0,
      min: 0.0,
      median: 22.2496137724,
      stdDev: 17.9035484739,
      variance: 320.5370479583,
      sum: 33216517.827907674,
    },
    {
      data_date: "2012-05-01",
      mean: 31.1278520906,
      max: 100.0,
      min: 0.0,
      median: 30.2499214724,
      stdDev: 16.8173897625,
      variance: 282.8245984243,
      sum: 42412589.2182377651,
    },
    {
      data_date: "2012-06-01",
      mean: 38.4442089995,
      max: 100.0,
      min: 0.0,
      median: 37.2488131997,
      stdDev: 18.2236333772,
      variance: 332.1008134684,
      sum: 52381334.869281143,
    },
    {
      data_date: "2012-07-01",
      mean: 40.2086932879,
      max: 100.0,
      min: 0.0,
      median: 39.2504249915,
      stdDev: 18.3428311461,
      variance: 336.4594544538,
      sum: 54785495.2041022927,
    },
    {
      data_date: "2012-08-01",
      mean: 33.7675008938,
      max: 100.0,
      min: 0.0,
      median: 32.7494118306,
      stdDev: 21.1031047098,
      variance: 445.3410283944,
      sum: 46009186.2479823083,
    },
    {
      data_date: "2012-09-01",
      mean: 48.1959347883,
      max: 100.0,
      min: 0.0,
      median: 46.7506176247,
      stdDev: 22.1906184275,
      variance: 492.4235461935,
      sum: 65668340.3088067323,
    },
    {
      data_date: "2012-10-01",
      mean: 55.0113647254,
      max: 100.0,
      min: 0.0,
      median: 53.7495354299,
      stdDev: 24.9710261556,
      variance: 623.5521472648,
      sum: 74954558.6263138205,
    },
    {
      data_date: "2012-11-01",
      mean: 63.7087896978,
      max: 100.0,
      min: 0.0,
      median: 63.2501153892,
      stdDev: 21.6642762709,
      variance: 469.3408663423,
      sum: 86805049.0339349359,
    },
    {
      data_date: "2012-12-01",
      mean: 38.5959415424,
      max: 100.0,
      min: 0.0,
      median: 37.7507889802,
      stdDev: 18.2246158865,
      variance: 332.136624212,
      sum: 52588074.800900802,
    },
    {
      data_date: "2013-01-01",
      mean: 57.5491031866,
      max: 100.0,
      min: 0.0,
      median: 56.7489730439,
      stdDev: 23.1894024254,
      variance: 537.7483848457,
      sum: 78412299.898785904,
    },
    {
      data_date: "2013-02-01",
      mean: 61.6590353732,
      max: 100.0,
      min: 0.0,
      median: 60.7474936061,
      stdDev: 22.5752367956,
      variance: 509.6413163795,
      sum: 84012200.1115973294,
    },
    {
      data_date: "2013-03-01",
      mean: 39.1946706576,
      max: 100.0,
      min: 0.0,
      median: 37.2503564436,
      stdDev: 28.6742702067,
      variance: 822.2137718849,
      sum: 53403860.3533674553,
    },
    {
      data_date: "2013-04-01",
      mean: 85.1356870044,
      max: 100.0,
      min: 0.0,
      median: 100.0,
      stdDev: 21.1819171303,
      variance: 448.6736133137,
      sum: 115999809.759665966,
    },
    {
      data_date: "2013-05-01",
      mean: 76.6495319203,
      max: 100.0,
      min: 0.0,
      median: 77.751125753,
      stdDev: 18.8394036511,
      variance: 354.9231299288,
      sum: 104437180.6204144359,
    },
    {
      data_date: "2013-06-01",
      mean: 25.9241243861,
      max: 100.0,
      min: 0.0,
      median: 24.2500011673,
      stdDev: 17.8658741438,
      variance: 319.1894589223,
      sum: 35322361.312642388,
    },
    {
      data_date: "2013-07-01",
      mean: 46.8940182826,
      max: 100.0,
      min: 0.0,
      median: 45.2494803888,
      stdDev: 18.8794199959,
      variance: 356.4324993801,
      sum: 63894441.8145328984,
    },
    {
      data_date: "2013-08-01",
      mean: 83.238784001,
      max: 100.0,
      min: 0.0,
      median: 89.2455176266,
      stdDev: 18.9904753645,
      variance: 360.6381545697,
      sum: 113415225.1363525093,
    },
    {
      data_date: "2013-09-01",
      mean: 65.9999022438,
      max: 100.0,
      min: 0.0,
      median: 65.7512375165,
      stdDev: 20.1732939783,
      variance: 406.9617899352,
      sum: 89926755.4396379292,
    },
    {
      data_date: "2013-10-01",
      mean: 53.432791864,
      max: 100.0,
      min: 0.0,
      median: 52.2504460803,
      stdDev: 24.7278780087,
      variance: 611.4679508146,
      sum: 72803707.9307209253,
    },
    {
      data_date: "2013-11-01",
      mean: 72.3924019529,
      max: 100.0,
      min: 0.0,
      median: 73.7501464485,
      stdDev: 21.6484617223,
      variance: 468.6558949429,
      sum: 98636719.2191559076,
    },
    {
      data_date: "2013-12-01",
      mean: 65.5676698532,
      max: 100.0,
      min: 0.0,
      median: 65.2478540292,
      stdDev: 20.5438200794,
      variance: 422.0485434541,
      sum: 89337826.4388762414,
    },
    {
      data_date: "2014-01-01",
      mean: 79.130278693,
      max: 100.0,
      min: 0.0,
      median: 82.2480659567,
      stdDev: 19.6556331882,
      variance: 386.343916031,
      sum: 107817269.0864907205,
    },
    {
      data_date: "2014-02-01",
      mean: 58.4355209291,
      max: 100.0,
      min: 0.0,
      median: 57.7506410682,
      stdDev: 19.2048554662,
      variance: 368.8264734765,
      sum: 79620069.4383699596,
    },
    {
      data_date: "2014-03-01",
      mean: 43.8140276171,
      max: 100.0,
      min: 0.0,
      median: 42.2484962267,
      stdDev: 22.3035918257,
      variance: 497.4502083275,
      sum: 59697866.3967030644,
    },
    {
      data_date: "2014-04-01",
      mean: 46.9482137956,
      max: 100.0,
      min: 0.0,
      median: 44.248947061,
      stdDev: 19.9378069651,
      variance: 397.5161465758,
      sum: 63968284.7518024892,
    },
    {
      data_date: "2014-05-01",
      mean: 27.0686350012,
      max: 100.0,
      min: 0.0,
      median: 24.7487971355,
      stdDev: 18.2547073345,
      variance: 333.2343398692,
      sum: 36881789.7767113,
    },
    {
      data_date: "2014-06-01",
      mean: 40.3280159879,
      max: 100.0,
      min: 0.0,
      median: 37.7493069008,
      stdDev: 20.9477785746,
      variance: 438.80942721,
      sum: 54948075.7973763347,
    },
    {
      data_date: "2014-07-01",
      mean: 63.4571908546,
      max: 100.0,
      min: 0.0,
      median: 62.2487162733,
      stdDev: 22.0622113287,
      variance: 486.7411687126,
      sum: 86462238.4104092121,
    },
    {
      data_date: "2014-08-01",
      mean: 47.3742745907,
      max: 100.0,
      min: 0.0,
      median: 46.7514245779,
      stdDev: 21.3916967339,
      variance: 457.6046891551,
      sum: 64548804.7772580609,
    },
    {
      data_date: "2014-09-01",
      mean: 52.8725272369,
      max: 100.0,
      min: 0.0,
      median: 51.7492718709,
      stdDev: 22.4380220466,
      variance: 503.4648333622,
      sum: 72040331.3439144194,
    },
    {
      data_date: "2014-10-01",
      mean: 59.2104775308,
      max: 100.0,
      min: 0.0,
      median: 57.7491996321,
      stdDev: 25.3279942511,
      variance: 641.5072927833,
      sum: 80675969.9841008186,
    },
    {
      data_date: "2014-11-01",
      mean: 34.1913429364,
      max: 100.0,
      min: 0.0,
      median: 32.2515445697,
      stdDev: 20.8338054992,
      variance: 434.0474515798,
      sum: 46586683.1595995724,
    },
    {
      data_date: "2014-12-01",
      mean: 41.8476588249,
      max: 100.0,
      min: 0.0,
      median: 39.7501672126,
      stdDev: 18.8551531113,
      variance: 355.5167988524,
      sum: 57018632.6483965218,
    },
    {
      data_date: "2015-01-01",
      mean: 44.9661293632,
      max: 100.0,
      min: 0.0,
      median: 43.2494925732,
      stdDev: 18.2625263376,
      variance: 333.5198682332,
      sum: 61267637.9940160066,
    },
    {
      data_date: "2015-02-01",
      mean: 42.622222722,
      max: 100.0,
      min: 0.0,
      median: 41.7500854152,
      stdDev: 17.4167741714,
      variance: 303.3440225371,
      sum: 58073998.1229175627,
    },
    {
      data_date: "2015-03-01",
      mean: 35.1977405509,
      max: 100.0,
      min: 0.0,
      median: 34.2504079817,
      stdDev: 19.0805825392,
      variance: 364.068630034,
      sum: 47957928.7080849707,
    },
    {
      data_date: "2015-04-01",
      mean: 30.326342267,
      max: 100.0,
      min: 0.0,
      median: 27.246678894,
      stdDev: 23.2632395916,
      variance: 541.1783162963,
      sum: 41320509.1478929073,
    },
    {
      data_date: "2015-05-01",
      mean: 57.9314948085,
      max: 100.0,
      min: 0.0,
      median: 57.2491412562,
      stdDev: 19.6286290832,
      variance: 385.2830796861,
      sum: 78933319.4260066599,
    },
    {
      data_date: "2015-06-01",
      mean: 48.8809394913,
      max: 100.0,
      min: 0.0,
      median: 45.7480272995,
      stdDev: 20.6195416303,
      variance: 425.165497045,
      sum: 66601678.8185154796,
    },
    {
      data_date: "2015-07-01",
      mean: 52.8334145002,
      max: 100.0,
      min: 0.0,
      median: 50.2498815821,
      stdDev: 19.4469317832,
      variance: 378.1831557821,
      sum: 71987039.1209778786,
    },
    {
      data_date: "2015-08-01",
      mean: 52.7505458803,
      max: 100.0,
      min: 0.0,
      median: 50.7516185668,
      stdDev: 20.9820936173,
      variance: 440.2482525646,
      sum: 71874128.254983142,
    },
    {
      data_date: "2015-09-01",
      mean: 59.3523824267,
      max: 100.0,
      min: 0.0,
      median: 58.2499957839,
      stdDev: 21.2495450575,
      variance: 451.5431651508,
      sum: 80869319.4655125141,
    },
    {
      data_date: "2015-10-01",
      mean: 45.3893265468,
      max: 100.0,
      min: 0.0,
      median: 43.7495343762,
      stdDev: 26.3715897318,
      variance: 695.4607449821,
      sum: 61844256.2667216808,
    },
    {
      data_date: "2015-11-01",
      mean: 74.3258690716,
      max: 100.0,
      min: 0.0,
      median: 77.2491284957,
      stdDev: 23.1320003836,
      variance: 535.089441747,
      sum: 101271123.4957860112,
    },
    {
      data_date: "2015-12-01",
      mean: 47.242815798,
      max: 100.0,
      min: 0.0,
      median: 46.7489104619,
      stdDev: 21.8946663482,
      variance: 479.376414497,
      sum: 64369688.4103316367,
    },
    {
      data_date: "2016-01-01",
      mean: 47.7132946644,
      max: 100.0,
      min: 0.0,
      median: 45.7503004398,
      stdDev: 21.6246772,
      variance: 467.6266640033,
      sum: 65010729.32892555,
    },
    {
      data_date: "2016-02-01",
      mean: 59.2044525273,
      max: 100.0,
      min: 0.0,
      median: 57.7494529054,
      stdDev: 22.2888882323,
      variance: 496.794538634,
      sum: 80667760.7444821596,
    },
    {
      data_date: "2016-03-01",
      mean: 56.0101215163,
      max: 100.0,
      min: 0.0,
      median: 55.7511071599,
      stdDev: 23.6499433378,
      variance: 559.319819883,
      sum: 76315393.3340432346,
    },
    {
      data_date: "2016-04-01",
      mean: 30.4932672255,
      max: 100.0,
      min: 0.0,
      median: 29.2484995414,
      stdDev: 16.7803936812,
      variance: 281.581612095,
      sum: 41547949.1804757267,
    },
    {
      data_date: "2016-05-01",
      mean: 30.8843790032,
      max: 100.0,
      min: 0.0,
      median: 30.2499404344,
      stdDev: 18.4417927579,
      variance: 340.0997201269,
      sum: 42080850.1695921719,
    },
    {
      data_date: "2016-06-01",
      mean: 29.2115706846,
      max: 100.0,
      min: 0.0,
      median: 26.2473861048,
      stdDev: 21.10122898,
      variance: 445.2618644681,
      sum: 39801600.9669289738,
    },
    {
      data_date: "2016-07-01",
      mean: 19.3989259875,
      max: 100.0,
      min: 0.0,
      median: 17.748581212,
      stdDev: 15.5928016424,
      variance: 243.1354630604,
      sum: 26431591.7714928128,
    },
    {
      data_date: "2016-08-01",
      mean: 48.0164845098,
      max: 100.0,
      min: 0.0,
      median: 46.2522657641,
      stdDev: 21.455233409,
      variance: 460.3270406347,
      sum: 65423834.1692107543,
    },
    {
      data_date: "2016-09-01",
      mean: 49.6208111705,
      max: 100.0,
      min: 0.0,
      median: 48.2499410648,
      stdDev: 21.628702952,
      variance: 467.8007913859,
      sum: 67609775.1533350348,
    },
    {
      data_date: "2016-10-01",
      mean: 16.9314852313,
      max: 100.0,
      min: 0.0,
      median: 13.2470873428,
      stdDev: 16.986246542,
      variance: 288.5325715863,
      sum: 23069633.1336631849,
    },
    {
      data_date: "2016-11-01",
      mean: 20.1026717478,
      max: 100.0,
      min: 0.0,
      median: 17.749300342,
      stdDev: 16.1762095384,
      variance: 261.6697550295,
      sum: 27390465.5081149563,
    },
    {
      data_date: "2016-12-01",
      mean: 16.4088048795,
      max: 100.0,
      min: 0.0,
      median: 14.2489963998,
      stdDev: 12.9005153378,
      variance: 166.4232959813,
      sum: 22357466.1975267529,
    },
    {
      data_date: "2017-01-01",
      mean: 34.6963443764,
      max: 100.0,
      min: 0.0,
      median: 33.2504264054,
      stdDev: 16.6966402069,
      variance: 278.7777941983,
      sum: 47274762.0725120604,
    },
    {
      data_date: "2017-02-01",
      mean: 15.3678565665,
      max: 100.0,
      min: 0.0,
      median: 10.7490163823,
      stdDev: 16.2519838616,
      variance: 264.1269794373,
      sum: 20939144.3336419016,
    },
    {
      data_date: "2017-03-01",
      mean: 49.9886418659,
      max: 100.0,
      min: 0.0,
      median: 50.2501648874,
      stdDev: 22.976152716,
      variance: 527.9035936303,
      sum: 68110955.0016255826,
    },
    {
      data_date: "2017-04-01",
      mean: 28.1699262004,
      max: 100.0,
      min: 0.0,
      median: 26.2493321935,
      stdDev: 16.5774349462,
      variance: 274.8113493943,
      sum: 38382330.5498317778,
    },
    {
      data_date: "2017-05-01",
      mean: 17.4155232143,
      max: 100.0,
      min: 0.0,
      median: 14.7509943136,
      stdDev: 15.5001280117,
      variance: 240.2539683794,
      sum: 23729148.7366748117,
    },
    {
      data_date: "2017-06-01",
      mean: 28.6216032072,
      max: 100.0,
      min: 0.0,
      median: 26.7518341955,
      stdDev: 17.6426304975,
      variance: 311.2624108704,
      sum: 38997753.3965693489,
    },
    {
      data_date: "2017-07-01",
      mean: 21.4787140985,
      max: 100.0,
      min: 0.0,
      median: 20.2496899156,
      stdDev: 13.7764426962,
      variance: 189.7903733611,
      sum: 29265362.5872977786,
    },
    {
      data_date: "2017-08-01",
      mean: 24.411185933,
      max: 100.0,
      min: 0.0,
      median: 23.2507347485,
      stdDev: 19.0570377366,
      variance: 363.1706872938,
      sum: 33260939.3765656352,
    },
    {
      data_date: "2017-09-01",
      mean: 25.1402654495,
      max: 100.0,
      min: 0.0,
      median: 23.2484107716,
      stdDev: 18.705605301,
      variance: 349.8996696755,
      sum: 34254331.0809150413,
    },
    {
      data_date: "2017-10-01",
      mean: 32.2751575428,
      max: 100.0,
      min: 0.0,
      median: 30.2505125949,
      stdDev: 19.2030514759,
      variance: 368.7571859878,
      sum: 43975825.7278839648,
    },
    {
      data_date: "2017-11-01",
      mean: 35.6162577231,
      max: 100.0,
      min: 0.0,
      median: 34.2508250237,
      stdDev: 19.3684821613,
      variance: 375.1381012344,
      sum: 48528170.3313833401,
    },
    {
      data_date: "2017-12-01",
      mean: 40.0451814754,
      max: 100.0,
      min: 0.0,
      median: 38.7477533773,
      stdDev: 19.3220680293,
      variance: 373.3423129279,
      sum: 54562705.6805871502,
    },
    {
      data_date: "2018-01-01",
      mean: 45.9884901147,
      max: 100.0,
      min: 0.0,
      median: 44.7481826129,
      stdDev: 20.6556705966,
      variance: 426.6567277932,
      sum: 62660633.7735050097,
    },
    {
      data_date: "2018-02-01",
      mean: 19.1925341396,
      max: 100.0,
      min: 0.0,
      median: 16.7496078292,
      stdDev: 16.8561914658,
      variance: 284.1311907325,
      sum: 26150376.972802382,
    },
    {
      data_date: "2018-03-01",
      mean: 36.438136101,
      max: 100.0,
      min: 0.0,
      median: 35.7532559804,
      stdDev: 21.3842039307,
      variance: 457.2841777482,
      sum: 49648003.1398629695,
    },
    {
      data_date: "2018-04-01",
      mean: 35.4490687842,
      max: 100.0,
      min: 0.0,
      median: 32.7513601376,
      stdDev: 23.4168861752,
      variance: 548.3505581436,
      sum: 48300370.6179004014,
    },
    {
      data_date: "2018-05-01",
      mean: 46.3879951234,
      max: 100.0,
      min: 0.0,
      median: 45.2502580738,
      stdDev: 18.8601208144,
      variance: 355.7041571347,
      sum: 63204970.7800106257,
    },
    {
      data_date: "2018-06-01",
      mean: 57.0411844429,
      max: 100.0,
      min: 0.0,
      median: 56.2495368631,
      stdDev: 22.4357028313,
      variance: 503.3607615364,
      sum: 77720246.0761255771,
    },
    {
      data_date: "2018-07-01",
      mean: 75.470744256,
      max: 100.0,
      min: 0.0,
      median: 76.7489860386,
      stdDev: 20.2068569757,
      variance: 408.3170688346,
      sum: 102831048.6959680319,
    },
    {
      data_date: "2018-08-01",
      mean: 49.5726900872,
      max: 100.0,
      min: 0.0,
      median: 47.7480206695,
      stdDev: 24.9670082684,
      variance: 623.3515018745,
      sum: 67544208.8003609776,
    },
    {
      data_date: "2018-09-01",
      mean: 49.3905535029,
      max: 100.0,
      min: 0.0,
      median: 48.2496790436,
      stdDev: 24.8015862055,
      variance: 615.1186783069,
      sum: 67296042.4923333973,
    },
    {
      data_date: "2018-10-01",
      mean: 57.8949681902,
      max: 100.0,
      min: 0.0,
      median: 56.2480392351,
      stdDev: 24.2952856614,
      variance: 590.2609053714,
      sum: 78883550.8634428084,
    },
    {
      data_date: "2018-11-01",
      mean: 24.9482828883,
      max: 100.0,
      min: 0.0,
      median: 23.2515433648,
      stdDev: 16.6882355529,
      variance: 278.4972058703,
      sum: 33992749.3474919945,
    },
    {
      data_date: "2018-12-01",
      mean: 20.1058912807,
      max: 100.0,
      min: 0.0,
      median: 15.7483785007,
      stdDev: 18.1748151302,
      variance: 330.3239050159,
      sum: 27394852.2137715816,
    },
    {
      data_date: "2019-01-01",
      mean: 37.9573091233,
      max: 100.0,
      min: 0.0,
      median: 35.7500289229,
      stdDev: 19.1957105821,
      variance: 368.4753047536,
      sum: 51717919.8550036028,
    },
    {
      data_date: "2019-02-01",
      mean: 42.7049194499,
      max: 100.0,
      min: 0.0,
      median: 41.7492713221,
      stdDev: 18.6632895446,
      variance: 348.3183766257,
      sum: 58186674.7810933739,
    },
    {
      data_date: "2019-03-01",
      mean: 50.8116074029,
      max: 100.0,
      min: 0.0,
      median: 49.2510685549,
      stdDev: 20.6346517914,
      variance: 425.7888545536,
      sum: 69232269.095518738,
    },
    {
      data_date: "2019-04-01",
      mean: 34.2117462384,
      max: 100.0,
      min: 0.0,
      median: 31.2502862288,
      stdDev: 20.3545426054,
      variance: 414.307404677,
      sum: 46614483.2424501926,
    },
    {
      data_date: "2019-05-01",
      mean: 26.4360210981,
      max: 100.0,
      min: 0.0,
      median: 24.2503728704,
      stdDev: 18.1273900139,
      variance: 328.6022687151,
      sum: 36019835.2310645208,
    },
    {
      data_date: "2019-06-01",
      mean: 50.6055682734,
      max: 100.0,
      min: 0.0,
      median: 48.7512553791,
      stdDev: 20.2532101178,
      variance: 410.1925200746,
      sum: 68951534.8856202662,
    },
    {
      data_date: "2019-07-01",
      mean: 53.6139427939,
      max: 100.0,
      min: 0.0,
      median: 52.2500027589,
      stdDev: 19.5356326397,
      variance: 381.6409426337,
      sum: 73050531.256459713,
    },
    {
      data_date: "2019-08-01",
      mean: 50.676537923,
      max: 100.0,
      min: 0.0,
      median: 49.7485537242,
      stdDev: 19.9381541117,
      variance: 397.5299893801,
      sum: 69048233.0640528947,
    },
    {
      data_date: "2019-09-01",
      mean: 48.8903622933,
      max: 100.0,
      min: 0.0,
      median: 47.7507423523,
      stdDev: 20.5467757354,
      variance: 422.1699931208,
      sum: 66614517.655840978,
    },
    {
      data_date: "2019-10-01",
      mean: 46.8287328409,
      max: 100.0,
      min: 0.0,
      median: 44.7498434068,
      stdDev: 26.555652671,
      variance: 705.2026887807,
      sum: 63805488.5320247859,
    },
    {
      data_date: "2019-11-01",
      mean: 33.3126450805,
      max: 100.0,
      min: 0.0,
      median: 32.2498314687,
      stdDev: 18.2032732525,
      variance: 331.3591571049,
      sum: 45389432.1863158196,
    },
    {
      data_date: "2019-12-01",
      mean: 73.4648108973,
      max: 100.0,
      min: 0.0,
      median: 74.2492762856,
      stdDev: 19.7646083233,
      variance: 390.6397421721,
      sum: 100097907.0935065746,
    },
    {
      data_date: "2020-01-01",
      mean: 64.2778064474,
      max: 100.0,
      min: 0.0,
      median: 65.7497205885,
      stdDev: 28.290629253,
      variance: 800.3597035308,
      sum: 87580350.6381722391,
    },
    {
      data_date: "2020-02-01",
      mean: 71.8477474164,
      max: 100.0,
      min: 0.0,
      median: 72.7502266177,
      stdDev: 23.0474853471,
      variance: 531.1865808243,
      sum: 97894611.8273865879,
    },
    {
      data_date: "2020-03-01",
      mean: 58.8925097914,
      max: 100.0,
      min: 0.0,
      median: 58.2495426951,
      stdDev: 26.8693869593,
      variance: 721.9639555711,
      sum: 80242729.8404004872,
    },
    {
      data_date: "2020-04-01",
      mean: 66.3757067315,
      max: 100.0,
      min: 0.0,
      median: 66.749399927,
      stdDev: 26.6847645639,
      variance: 712.0766598304,
      sum: 90438799.8080016077,
    },
    {
      data_date: "2020-05-01",
      mean: 88.4927320809,
      max: 100.0,
      min: 0.0,
      median: 99.9911871936,
      stdDev: 16.437954978,
      variance: 270.2063638586,
      sum: 120573879.7404464185,
    },
    {
      data_date: "2020-06-01",
      mean: 75.4881751467,
      max: 100.0,
      min: 0.0,
      median: 78.7501592583,
      stdDev: 22.3387247011,
      variance: 499.0186212737,
      sum: 102854798.7832931727,
    },
    {
      data_date: "2020-07-01",
      mean: 44.383005724,
      max: 100.0,
      min: 0.0,
      median: 42.2510725212,
      stdDev: 23.3945084597,
      variance: 547.3030260693,
      sum: 60473115.3490824848,
    },
    {
      data_date: "2020-08-01",
      mean: 48.6151677512,
      max: 100.0,
      min: 0.0,
      median: 47.2495157381,
      stdDev: 28.6614839794,
      variance: 821.4806639019,
      sum: 66239557.2174120396,
    },
    {
      data_date: "2020-09-01",
      mean: 66.2411926629,
      max: 100.0,
      min: 0.0,
      median: 66.7474866381,
      stdDev: 25.9666185865,
      variance: 674.2652808192,
      sum: 90255520.5404199511,
    },
    {
      data_date: "2020-10-01",
      mean: 60.6275006611,
      max: 100.0,
      min: 0.0,
      median: 59.7490634798,
      stdDev: 27.3150491379,
      variance: 746.1119094053,
      sum: 82606704.548270911,
    },
    {
      data_date: "2020-11-01",
      mean: 63.0398527488,
      max: 100.0,
      min: 0.0,
      median: 62.2472331393,
      stdDev: 23.5046860433,
      variance: 552.4702659953,
      sum: 85893603.2988319099,
    },
    {
      data_date: "2020-12-01",
      mean: 37.9750617726,
      max: 100.0,
      min: 0.0,
      median: 36.7472608153,
      stdDev: 18.0788124636,
      variance: 326.8434600928,
      sum: 51742108.3475615308,
    },
    {
      data_date: "2021-01-01",
      mean: 55.2479375272,
      max: 100.0,
      min: 0.0,
      median: 53.750322878,
      stdDev: 19.3291327867,
      variance: 373.6153742858,
      sum: 75276895.8384551406,
    },
    {
      data_date: "2021-02-01",
      mean: 49.1657420806,
      max: 100.0,
      min: 0.0,
      median: 47.7490927183,
      stdDev: 22.1240364991,
      variance: 489.4729910128,
      sum: 66989730.496229291,
    },
    {
      data_date: "2021-03-01",
      mean: 66.245258061,
      max: 100.0,
      min: 0.0,
      median: 66.24928385,
      stdDev: 21.8442173208,
      variance: 477.169830358,
      sum: 90261059.761594981,
    },
    {
      data_date: "2021-04-01",
      mean: 38.067430767,
      max: 100.0,
      min: 0.0,
      median: 35.7491798089,
      stdDev: 21.7821118756,
      variance: 474.4603977627,
      sum: 51867963.7457132041,
    },
    {
      data_date: "2021-05-01",
      mean: 36.6914925084,
      max: 100.0,
      min: 0.0,
      median: 36.7513450438,
      stdDev: 21.4466314767,
      variance: 459.9580016964,
      sum: 49993208.4948968813,
    },
    {
      data_date: "2021-06-01",
      mean: 63.0662779961,
      max: 100.0,
      min: 0.0,
      median: 62.7487990347,
      stdDev: 21.2625421821,
      variance: 452.0957000446,
      sum: 85929608.4544874132,
    },
    {
      data_date: "2021-07-01",
      mean: 19.7017184627,
      max: 100.0,
      min: 0.0,
      median: 17.2488701424,
      stdDev: 16.9428534211,
      variance: 287.0602820502,
      sum: 26844155.1835608557,
    },
    {
      data_date: "2021-08-01",
      mean: 50.057201162,
      max: 100.0,
      min: 0.0,
      median: 48.2489248228,
      stdDev: 25.2071698474,
      variance: 635.4014117175,
      sum: 68204369.0043469816,
    },
    {
      data_date: "2021-09-01",
      mean: 59.4467787328,
      max: 100.0,
      min: 0.0,
      median: 58.250883585,
      stdDev: 21.3396574547,
      variance: 455.3809802832,
      sum: 80997937.1337792873,
    },
    {
      data_date: "2021-10-01",
      mean: 37.2964840569,
      max: 100.0,
      min: 0.0,
      median: 36.2524384129,
      stdDev: 22.2297908958,
      variance: 494.1636032713,
      sum: 50817526.7919644713,
    },
    {
      data_date: "2021-11-01",
      mean: 39.2189333641,
      max: 100.0,
      min: 0.0,
      median: 36.7500262643,
      stdDev: 19.050475209,
      variance: 362.9206056906,
      sum: 53436918.9852850661,
    },
    {
      data_date: "2021-12-01",
      mean: 17.988233527,
      max: 100.0,
      min: 0.0,
      median: 16.2482030896,
      stdDev: 12.8871186017,
      variance: 166.0778258543,
      sum: 24509482.9261556566,
    },
    {
      data_date: "2022-01-01",
      mean: 17.7821609709,
      max: 100.0,
      min: 0.0,
      median: 15.7504901236,
      stdDev: 14.778840113,
      variance: 218.4141150857,
      sum: 24228703.1715499274,
    },
    {
      data_date: "2022-02-01",
      mean: 33.7915363783,
      max: 100.0,
      min: 0.0,
      median: 32.7501868863,
      stdDev: 17.6577946429,
      variance: 311.7977116502,
      sum: 46041935.2834763899,
    },
    {
      data_date: "2022-03-01",
      mean: 40.3610810314,
      max: 100.0,
      min: 0.0,
      median: 37.7512254347,
      stdDev: 23.3173014807,
      variance: 543.6965483439,
      sum: 54993127.8653233647,
    },
    {
      data_date: "2022-04-01",
      mean: 43.1422937022,
      max: 100.0,
      min: 0.0,
      median: 38.7493029587,
      stdDev: 27.9516341529,
      variance: 781.2938518174,
      sum: 58782609.7155866474,
    },
    {
      data_date: "2022-05-01",
      mean: 30.7639293884,
      max: 100.0,
      min: 0.0,
      median: 30.2504448506,
      stdDev: 17.9694560554,
      variance: 322.9013509258,
      sum: 41916734.1226465702,
    },
    {
      data_date: "2022-06-01",
      mean: 26.0335707073,
      max: 100.0,
      min: 0.0,
      median: 23.7513882785,
      stdDev: 19.9213491776,
      variance: 396.8601530551,
      sum: 35471485.0571785793,
    },
    {
      data_date: "2022-07-01",
      mean: 6.3215854179,
      max: 100.0,
      min: 0.0,
      median: 0.0060437441,
      stdDev: 10.3124160785,
      variance: 106.3459253758,
      sum: 8613341.0284291487,
    },
    {
      data_date: "2022-08-01",
      mean: 24.4512535291,
      max: 100.0,
      min: 0.0,
      median: 22.7512528911,
      stdDev: 18.7044098318,
      variance: 349.8549471554,
      sum: 33315532.6227841489,
    },
    {
      data_date: "2022-09-01",
      mean: 14.8073368126,
      max: 100.0,
      min: 0.0,
      median: 10.7506537424,
      stdDev: 16.198364608,
      variance: 262.3870159729,
      sum: 20175420.1292579696,
    },
    {
      data_date: "2022-10-01",
      mean: 34.6680258321,
      max: 100.0,
      min: 0.0,
      median: 31.250452484,
      stdDev: 24.7529898483,
      variance: 612.7105064288,
      sum: 47236177.2456164286,
    },
    {
      data_date: "2022-11-01",
      mean: 25.673247517,
      max: 100.0,
      min: 0.0,
      median: 22.7509884194,
      stdDev: 19.4882991756,
      variance: 379.7938047562,
      sum: 34980534.3994619474,
    },
    {
      data_date: "2022-12-01",
      mean: 23.6168705071,
      max: 100.0,
      min: 0.0,
      median: 21.7526245831,
      stdDev: 15.4857262477,
      variance: 239.8077174182,
      sum: 32178661.8789033256,
    },
    {
      data_date: "2023-01-01",
      mean: 38.5063126718,
      max: 100.0,
      min: 0.0,
      median: 37.2507071742,
      stdDev: 19.4992763832,
      variance: 380.2217794667,
      sum: 52465952.8999050558,
    },
    {
      data_date: "2023-02-01",
      mean: 36.5551942015,
      max: 100.0,
      min: 0.0,
      median: 35.249699288,
      stdDev: 21.2855731435,
      variance: 453.0756240486,
      sum: 49807498.1514796615,
    },
    {
      data_date: "2023-03-01",
      mean: 17.2595992357,
      max: 100.0,
      min: 0.0,
      median: 11.2512141507,
      stdDev: 19.7369110841,
      variance: 389.5456591405,
      sum: 23516697.8539851382,
    },
    {
      data_date: "2023-04-01",
      mean: 48.2949609321,
      max: 100.0,
      min: 0.0,
      median: 45.2482527555,
      stdDev: 27.1353310495,
      variance: 736.3261911648,
      sum: 65803266.263415657,
    },
    {
      data_date: "2023-05-01",
      mean: 50.7949674062,
      max: 100.0,
      min: 0.0,
      median: 50.7504821485,
      stdDev: 22.4898785943,
      variance: 505.7946391877,
      sum: 69209596.6238113344,
    },
    {
      data_date: "2023-06-01",
      mean: 49.8828843215,
      max: 100.0,
      min: 0.0,
      median: 48.2493275011,
      stdDev: 22.6088285072,
      variance: 511.1591264678,
      sum: 67966857.3210698813,
    },
    {
      data_date: "2023-07-01",
      mean: 44.641111963,
      max: 100.0,
      min: 0.0,
      median: 42.7490882844,
      stdDev: 23.0461969479,
      variance: 531.1271937617,
      sum: 60824792.4856329113,
    },
    {
      data_date: "2023-08-01",
      mean: 43.9961896851,
      max: 100.0,
      min: 0.0,
      median: 41.2467017711,
      stdDev: 25.3474496222,
      variance: 642.4932023519,
      sum: 59946067.4271200448,
    },
    {
      data_date: "2023-09-01",
      mean: 44.2373910223,
      max: 100.0,
      min: 0.0,
      median: 42.2508905248,
      stdDev: 23.7294758546,
      variance: 563.0880243351,
      sum: 60274711.1511876881,
    },
    {
      data_date: "2023-10-01",
      mean: 42.3966154379,
      max: 100.0,
      min: 0.0,
      median: 40.2500324456,
      stdDev: 25.5115107645,
      variance: 650.8371814889,
      sum: 57766601.742327407,
    },
    {
      data_date: "2023-11-01",
      mean: 51.6693855547,
      max: 100.0,
      min: 0.0,
      median: 48.7501321455,
      stdDev: 28.2599347391,
      variance: 798.6239114606,
      sum: 70401016.3731885701,
    },
    {
      data_date: "2023-12-01",
      mean: 87.4997245672,
      max: 100.0,
      min: 0.0,
      median: 100.0,
      stdDev: 18.0357717876,
      variance: 325.2890639746,
      sum: 119220878.5875448436,
    },
    {
      data_date: "2024-01-01",
      mean: 82.3175995502,
      max: 306.8153394054,
      min: -271.1134307083,
      median: 81.9960975415,
      stdDev: 32.0939392274,
      variance: 1030.0209351292,
      sum: 112160084.9617173523,
    },
  ],
};
