import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { Container, Fab } from "@mui/material";
import { Language } from "@mui/icons-material";
import ModalDisplay from "../../utils/map/modalDisplay";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function SelectBasemap() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Fab
        color="#008080"
        sx={{ "& > :not(style)": { m: 1 } }}
        onClick={handleOpen}
      >
        <Language sx={{ color: "#008080" }} />
      </Fab>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Container>
          <Box sx={style}>
            <ModalDisplay />
          </Box>
        </Container>
      </Modal>
    </div>
  );
}
