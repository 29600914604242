import LoginPage from "./auth/LoginPage";
import RegistrationPage from "./auth/RegistrationPage";
import HomeBarComponent from "./navigation/HomeBar";
import { Box, Typography } from "@mui/material";

export default function HomeComponent({ loginPg }) {
  return (
    <>
      <div className="bg-cover bg-[url('../public/bground.jpg')] w-full overflow-hidden">
        <div className="image-overlay w-full ">
          <HomeBarComponent />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 place-items-center container">
            <div className="">
              <div className="grid grid-rows-1  grid-flow-col gap-4 mt-10">
                <div className="row-span-1">
                  <Box
                    component="img"
                    alt="SomRep Logo"
                    className="sm:w-[50] sm:h-[50]"
                    src="//images.squarespace-cdn.com/content/v1/6231e566c73e710fad2e1cf1/802d4237-de98-4021-b490-962ba57db3cc/SomReP+Logo+White.png?format=1500w"
                  />
                  <Typography />
                </div>
                <div className="col-span-3 ...">
                  <p>
                    <span className="mb-4 text-4xl font-extrabold  md:text-6xl lg:text-6xl text-transparent bg-clip-text bg-gradient-to-r to-teal-200 from-teal-400">
                      SomReP
                    </span>
                  </p>
                  <p className="text-bse font-normal text-gray-500 lg:text-xl dark:text-gray-400 ">
                    Early Warning System
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-2" style={{ width: "100vh" }}>
              {loginPg ? <LoginPage /> : <RegistrationPage />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
