export const hirshabelle = {
  type: "FeatureCollection",
  name: "hirshabelle",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        OBJECTID_1: 7,
        Name: "Hirshabelle",
        Shape_Leng: 9.6658439132900007,
        Shape_Area: 4.2888148476900003,
        "area-km2": 52644.3375543,
        style: {
          color: "black",
          fill: false,
          opacity: 1,
          clickable: false,
        },
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [45.710977554000067, 5.111379623000062],
              [46.040987015000042, 5.031389237000042],
              [46.230989456000088, 5.081384660000083],
              [46.229829788000075, 5.073255540000048],
              [46.210988998000062, 4.941396713000074],
              [46.110986710000077, 4.741415024000048],
              [46.150987625000084, 4.431444168000041],
              [46.39682960500005, 4.234792709000089],
              [46.400991440000041, 4.231462479000072],
              [46.390989305000062, 3.891492845000073],
              [47.036885559000041, 3.472918749000087],
              [47.036842680000063, 3.472880916000065],
              [47.036635996000086, 3.472688498000082],
              [47.036598294000044, 3.472645791000048],
              [47.036526576000085, 3.47259534300008],
              [47.035431819000053, 3.471531150000089],
              [47.033657325000036, 3.47010307000005],
              [47.03229564600008, 3.468783629000086],
              [47.031177124000067, 3.467659659000049],
              [47.029766814000084, 3.46634021400007],
              [47.028483793000078, 3.464892454000051],
              [47.027579597000056, 3.463916875000052],
              [47.026305042000047, 3.462450760000081],
              [47.024938396000039, 3.460775986000044],
              [47.019486648000054, 3.455325131000052],
              [47.012615135000033, 3.449014692000048],
              [47.010566451000045, 3.446927523000056],
              [47.009114980000049, 3.445642614000064],
              [47.007282795000037, 3.443858018000071],
              [47.005426816000067, 3.442168601000049],
              [47.003132017000041, 3.439924965000046],
              [47.000797711000075, 3.43767695400004],
              [46.998852455000076, 3.435673288000089],
              [46.997442145000036, 3.43391396800007],
              [46.99535099600007, 3.43147046200005],
              [46.993405741000061, 3.429369042000076],
              [46.991460486000051, 3.427511969000079],
              [46.988737129000071, 3.425019576000068],
              [46.987035031000062, 3.42333354200008],
              [46.98405925600008, 3.420182381000075],
              [46.981534245000034, 3.417610674000059],
              [46.978967209000075, 3.41518551300004],
              [46.976358917000084, 3.412137914000084],
              [46.973272351000048, 3.408330149000051],
              [46.972376102000055, 3.406619454000065],
              [46.97078186300007, 3.404596912000045],
              [46.966026275000047, 3.400315210000088],
              [46.963215177000052, 3.39764888600007],
              [46.958765585000037, 3.393318266000051],
              [46.957694828000058, 3.392080947000068],
              [46.955765055000086, 3.390345314000058],
              [46.952912111000046, 3.387274436000041],
              [46.948275823000074, 3.382745642000089],
              [46.944920258000082, 3.379275675000088],
              [46.940300277000063, 3.374192884000081],
              [46.936020715000041, 3.369549926000047],
              [46.931984311000065, 3.365737796000076],
              [46.928385589000072, 3.361876778000067],
              [46.925143801000047, 3.358506752000039],
              [46.923892076000072, 3.356640047000042],
              [46.922258036000073, 3.354838932000064],
              [46.920976962000054, 3.353246254000055],
              [46.918181095000079, 3.349855522000041],
              [46.915255117000072, 3.346701360000054],
              [46.911726099000077, 3.34311462800008],
              [46.910724267000035, 3.341850525000041],
              [46.908416190000082, 3.339042761000087],
              [46.906393648000062, 3.336734684000078],
              [46.904204544000038, 3.333807947000082],
              [46.902677066000081, 3.332181835000085],
              [46.901729905000082, 3.330905004000044],
              [46.899034990000075, 3.327275473000043],
              [46.897685553000088, 3.325089562000073],
              [46.895519512000078, 3.321767874000045],
              [46.894567727000037, 3.320530554000072],
              [46.892738789000077, 3.318549229000041],
              [46.891814793000037, 3.317278454000075],
              [46.890209958000071, 3.315518916000087],
              [46.889142556000081, 3.31417739300008],
              [46.887535232000062, 3.31239084200007],
              [46.886513973000035, 3.310948992000078],
              [46.880092250000075, 3.303310658000044],
              [46.879705580000063, 3.303128750000042],
              [46.878587058000051, 3.301662448000059],
              [46.877274011000054, 3.299951759000066],
              [46.876293467000039, 3.298377772000038],
              [46.87507994200007, 3.29711665800005],
              [46.873699854000051, 3.295451035000042],
              [46.872391151000045, 3.293571261000068],
              [46.872323197000071, 3.293475836000084],
              [46.871737507000034, 3.292653378000068],
              [46.871272804000057, 3.292000817000087],
              [46.870178252000073, 3.290644524000072],
              [46.868607808000036, 3.288598188000037],
              [46.866769634000036, 3.286608285000057],
              [46.864678484000081, 3.284017772000084],
              [46.857724197000039, 3.274877608000054],
              [46.855839217000039, 3.27186868800004],
              [46.853493267000033, 3.268596751000075],
              [46.851951581000037, 3.266159870000081],
              [46.850186334000057, 3.263660019000042],
              [46.847219820000078, 3.260238506000064],
              [46.845812571000067, 3.258022114000084],
              [46.843575878000081, 3.254310155000042],
              [46.841384054000059, 3.252124587000083],
              [46.83919564200005, 3.249533985000085],
              [46.837457881000034, 3.247955105000074],
              [46.834305095000047, 3.24414796700006],
              [46.832044607000057, 3.241352100000086],
              [46.830539257000055, 3.239489226000046],
              [46.828302214000075, 3.236996351000073],
              [46.826086840000073, 3.234370271000046],
              [46.823147288000087, 3.231277378000073],
              [46.819208146000051, 3.227024787000062],
              [46.812166993000062, 3.218998953000039],
              [46.808455033000087, 3.214739718000089],
              [46.806126306000067, 3.212165018000064],
              [46.803372102000083, 3.208812971000043],
              [46.800485066000078, 3.20522386600004],
              [46.797319156000071, 3.201914423000062],
              [46.794211618000077, 3.198429312000087],
              [46.790029319000041, 3.194469875000038],
              [46.785944284000038, 3.190803715000072],
              [46.776655691000087, 3.181124991000047],
              [46.773689177000051, 3.177874288000055],
              [46.770868557000085, 3.175479026000062],
              [46.76547047400004, 3.169515288000071],
              [46.763427956000044, 3.167168890000085],
              [46.760842019000052, 3.164699653000071],
              [46.758905238000068, 3.162891588000093],
              [46.756814089000045, 3.160936244000084],
              [46.755250285000045, 3.159226893000039],
              [46.753944837000063, 3.157856570000092],
              [46.753377766000085, 3.15733040300006],
              [46.753289548000055, 3.157248548000041],
              [46.753300184000068, 3.157228606000047],
              [46.753263998000079, 3.157221080000056],
              [46.749859801000071, 3.153701439000088],
              [46.741803548000064, 3.14605468700006],
              [46.738869110000053, 3.143142442000055],
              [46.73624301600006, 3.140209369000047],
              [46.733811447000051, 3.137569595000059],
              [46.731527059000086, 3.134885007000094],
              [46.726515511000059, 3.12922],
              [46.721578130000069, 3.123449807000043],
              [46.718336247000082, 3.119618132000085],
              [46.716001941000059, 3.116978306000078],
              [46.712833610000075, 3.113158639000062],
              [46.710371123000073, 3.109998224000037],
              [46.707661065000082, 3.106688394000059],
              [46.703732172000059, 3.102272604000063],
              [46.699380431000066, 3.097629978000043],
              [46.698390802000063, 3.096612018000087],
              [46.69795193300007, 3.09592845800006],
              [46.696899377000079, 3.094658759000083],
              [46.695606238000039, 3.093207673000052],
              [46.69503485000007, 3.092633284000044],
              [46.694523609000044, 3.092149588000041],
              [46.693982295000069, 3.091393812000092],
              [46.692749301000049, 3.089821796000081],
              [46.691907257000082, 3.088945094000053],
              [46.691801343000066, 3.088846229000069],
              [46.690529521000087, 3.087168184000063],
              [46.688195215000064, 3.084528276000071],
              [46.685374595000042, 3.082083911000041],
              [46.681581347000076, 3.077977364000049],
              [46.679247041000053, 3.075532984000063],
              [46.679036878000034, 3.075365210000086],
              [46.678885643000058, 3.075099153000053],
              [46.678344328000037, 3.074615448000088],
              [46.677412065000055, 3.073799197000085],
              [46.676780532000066, 3.073255029000052],
              [46.676239217000045, 3.072741092000058],
              [46.675697903000071, 3.072000418000073],
              [46.674872093000033, 3.071044068000049],
              [46.673848924000083, 3.070282640000073],
              [46.673301648000063, 3.069687775000091],
              [46.672730578000085, 3.069021526000085],
              [46.672183302000064, 3.068402866000042],
              [46.671337316000063, 3.067556363000051],
              [46.670675710000069, 3.066770338000083],
              [46.669382571000085, 3.065893617000086],
              [46.667788701000063, 3.064472722000062],
              [46.666777425000078, 3.06358008300009],
              [46.663498269000058, 3.060455466000064],
              [46.660976040000037, 3.058147389000055],
              [46.660210302000053, 3.057428686000094],
              [46.658616432000088, 3.055675227000052],
              [46.657716179000033, 3.054697171000043],
              [46.656601540000054, 3.05380083700004],
              [46.656120372000032, 3.053286891000084],
              [46.655428693000033, 3.052833409000073],
              [46.654315991000033, 3.05204737300005],
              [46.653173217000074, 3.051412497000058],
              [46.651721681000083, 3.050355533000072],
              [46.646936353000058, 3.046450503000074],
              [46.646857884000042, 3.046273012000086],
              [46.646767665000084, 3.046121850000077],
              [46.646647373000064, 3.045970688000068],
              [46.646436862000087, 3.045819527000049],
              [46.646196278000048, 3.045638133000068],
              [46.645835402000046, 3.045366041000079],
              [46.644903138000075, 3.044307908000064],
              [46.643549853000081, 3.043098612000051],
              [46.641925910000055, 3.04161722200007],
              [46.640692916000035, 3.040438155000061],
              [46.639700507000043, 3.039440482000089],
              [46.638850769000044, 3.038769289000072],
              [46.638396684000043, 3.038328812000088],
              [46.631977342000084, 3.032950966000044],
              [46.628087377000043, 3.029577090000089],
              [46.628062251000074, 3.029524165000055],
              [46.627761521000082, 3.029131139000071],
              [46.625212741000041, 3.027071628000044],
              [46.624603854000043, 3.026591579000069],
              [46.624144045000037, 3.026249913000072],
              [46.624122686000078, 3.026168318000089],
              [46.620032757000047, 3.022661295000091],
              [46.618168230000038, 3.020786847000068],
              [46.617326185000081, 3.020182186000056],
              [46.616002973000036, 3.018882163000058],
              [46.614769979000073, 3.017763537000064],
              [46.611863965000055, 3.015174323000053],
              [46.611483251000038, 3.014817404000041],
              [46.610388699000055, 3.013461112000073],
              [46.608461336000062, 3.011581337000052],
              [46.607557141000086, 3.010843704000081],
              [46.604938296000057, 3.00821252500009],
              [46.602883942000062, 3.006147212000087],
              [46.60274077400004, 3.006123713000079],
              [46.601718292000044, 3.005337643000075],
              [46.599913911000044, 3.003523632000054],
              [46.599162086000035, 3.002858493000076],
              [46.597718581000038, 3.001346814000044],
              [46.596726172000047, 3.000137469000038],
              [46.596094638000068, 2.999381628000037],
              [46.594981937000057, 2.998323449000054],
              [46.593388067000035, 2.996841997000047],
              [46.592606169000078, 2.996176855000044],
              [46.591553613000087, 2.995118673000093],
              [46.590801788000078, 2.994393062000086],
              [46.590492066000081, 2.994019409000089],
              [46.58679692000004, 2.990486836000059],
              [46.586772004000068, 2.990311492000046],
              [46.586591566000038, 2.990039386000092],
              [46.586381055000061, 2.98976728100007],
              [46.586020179000059, 2.989404474000082],
              [46.585448792000079, 2.988648625000053],
              [46.584336090000079, 2.987318330000051],
              [46.583794776000047, 2.986834586000043],
              [46.583253462000073, 2.986381076000043],
              [46.582952732000081, 2.985957799000062],
              [46.581719738000061, 2.984476330000064],
              [46.580306307000058, 2.982934391000072],
              [46.578772583000045, 2.981483153000056],
              [46.577878914000053, 2.980738716000076],
              [46.577224349000062, 2.980053223000084],
              [46.576817837000078, 2.979759804000082],
              [46.57594572000005, 2.97888301200004],
              [46.574622507000072, 2.977734110000085],
              [46.574602029000062, 2.977589993000038],
              [46.57281555600008, 2.976046017000044],
              [46.57245725000007, 2.975980521000054],
              [46.570803235000085, 2.974650211000039],
              [46.566171991000033, 2.97084067600008],
              [46.563826296000059, 2.968935903000045],
              [46.560909213000059, 2.966577609000069],
              [46.559225125000069, 2.965126349000059],
              [46.557631255000047, 2.96367508600008],
              [46.556067458000086, 2.962465699000063],
              [46.551706872000068, 2.958867765000093],
              [46.550203221000061, 2.957779312000071],
              [46.548428913000066, 2.956237336000072],
              [46.546444094000037, 2.954604652000057],
              [46.54611159600006, 2.954047505000062],
              [46.542787395000062, 2.951253288000089],
              [46.538338620000047, 2.947359685000038],
              [46.535411883000052, 2.94476607200005],
              [46.531771308000089, 2.942005898000048],
              [46.530510193000055, 2.941553800000065],
              [46.529783645000066, 2.940726742000038],
              [46.529487025000037, 2.940578221000067],
              [46.529153901000086, 2.940292686000078],
              [46.52877318700007, 2.939745410000057],
              [46.528368678000049, 2.939626437000072],
              [46.527768753000032, 2.939214977000063],
              [46.527751581000075, 2.939208502000042],
              [46.52598643500005, 2.938030309000055],
              [46.523606974000074, 2.936364686000047],
              [46.52287212400006, 2.935795504000055],
              [46.521872750000057, 2.935153050000054],
              [46.519850208000037, 2.933749168000077],
              [46.517604075000065, 2.931867767000085],
              [46.516070351000053, 2.930688581000084],
              [46.515168161000076, 2.929841985000053],
              [46.513363780000077, 2.928783739000039],
              [46.513140752000083, 2.928680712000073],
              [46.512667875000034, 2.928314231000058],
              [46.512659230000054, 2.928192548000084],
              [46.512171556000055, 2.927787509000041],
              [46.511736890000066, 2.927457082000046],
              [46.511482451000063, 2.927201267000044],
              [46.510888761000047, 2.92671095500009],
              [46.510220860000061, 2.926252620000071],
              [46.509552959000075, 2.925751649000063],
              [46.509330325000064, 2.925581106000038],
              [46.509160699000063, 2.925431881000065],
              [46.508991073000061, 2.925325291000092],
              [46.508863854000083, 2.92522936000006],
              [46.508673025000064, 2.925048158000038],
              [46.508598814000038, 2.925058817000092],
              [46.508529321000083, 2.925097633000064],
              [46.50766765700007, 2.924427456000046],
              [46.507665872000075, 2.924408620000065],
              [46.507602263000081, 2.924302030000092],
              [46.507453840000039, 2.92416346300007],
              [46.507294816000069, 2.924035556000092],
              [46.50688135300004, 2.923726445000057],
              [46.506499696000049, 2.923427994000065],
              [46.506277062000038, 2.923268109000048],
              [46.506213452000054, 2.923129542000083],
              [46.506065030000059, 2.923001634000059],
              [46.505895404000057, 2.922895045000075],
              [46.50571517700007, 2.922735160000059],
              [46.505651567000086, 2.922681865000072],
              [46.505556152000054, 2.922564616000045],
              [46.505375925000067, 2.922447367000075],
              [46.505301714000041, 2.922415390000083],
              [46.505227503000071, 2.92242604900008],
              [46.505176092000056, 2.922454243000061],
              [46.504549902000065, 2.921952714000042],
              [46.504495992000045, 2.921999689000074],
              [46.504358171000035, 2.922074302000055],
              [46.504252155000074, 2.922106279000047],
              [46.504167342000073, 2.922116938000045],
              [46.504071927000041, 2.922063643000058],
              [46.503997716000072, 2.922010348000072],
              [46.503902302000085, 2.921914417000039],
              [46.503817489000085, 2.921839804000058],
              [46.50331295400008, 2.921590122000055],
              [46.502813267000079, 2.921042846000091],
              [46.50207563400005, 2.920257624000044],
              [46.501029042000084, 2.919132774000047],
              [46.501029266000046, 2.919132416000082],
              [46.500997461000054, 2.919004508000057],
              [46.500891445000036, 2.918844622000051],
              [46.500721819000034, 2.918652760000043],
              [46.500626405000048, 2.918556828000078],
              [46.500530990000073, 2.918460897000045],
              [46.500467381000078, 2.918354307000072],
              [46.500382568000077, 2.918247717000042],
              [46.500297755000076, 2.918173103000072],
              [46.500202340000044, 2.918109149000088],
              [46.50006452000008, 2.918055854000045],
              [46.49997970700008, 2.91803453600005],
              [46.499905495000064, 2.91803453600005],
              [46.499725268000077, 2.917959923000069],
              [46.499619252000059, 2.917906627000093],
              [46.499386017000063, 2.917672129000039],
              [46.499354212000071, 2.917565538000076],
              [46.499280001000045, 2.917416312000057],
              [46.499205789000086, 2.917277744000046],
              [46.499110375000043, 2.917192472000067],
              [46.498972554000034, 2.917064563000054],
              [46.498834733000081, 2.916925996000089],
              [46.498654506000037, 2.916851383000051],
              [46.498484880000035, 2.916734133000091],
              [46.498294051000073, 2.916435680000063],
              [46.498039612000071, 2.916073272000062],
              [46.497986604000062, 2.91597734000004],
              [46.497880588000044, 2.915774818000045],
              [46.497753369000066, 2.915689546000067],
              [46.497636751000073, 2.91567888700007],
              [46.497583743000064, 2.91567888700007],
              [46.497520134000069, 2.915689546000067],
              [46.497392914000045, 2.915732182000056],
              [46.497276297000042, 2.915732182000056],
              [46.497170281000081, 2.915646910000078],
              [46.497021858000039, 2.915519001000064],
              [46.496799224000085, 2.915359115000058],
              [46.496608395000067, 2.915220547000047],
              [46.496300949000045, 2.915103298000076],
              [46.49620034600008, 2.915087206000067],
              [46.493864229000053, 2.913018043000079],
              [46.492909338000061, 2.912205678000078],
              [46.492908434000071, 2.912172052000074],
              [46.492844825000077, 2.91204414300006],
              [46.492728207000084, 2.91186293800007],
              [46.492452565000065, 2.911628438000093],
              [46.492219330000069, 2.911415256000055],
              [46.491922485000089, 2.91121273300007],
              [46.491530225000076, 2.91103152900007],
              [46.491527697000038, 2.911030258000039],
              [46.491335398000047, 2.910866660000067],
              [46.490671495000072, 2.910438774000056],
              [46.490671495000072, 2.910418630000038],
              [46.490607885000088, 2.910226766000051],
              [46.490565479000054, 2.910109516000091],
              [46.490438260000076, 2.910034902000064],
              [46.490332243000069, 2.910045561000061],
              [46.490258032000042, 2.910024243000066],
              [46.490077805000055, 2.90986435700006],
              [46.490014195000072, 2.90972578800006],
              [46.489961187000063, 2.909640515000092],
              [46.489780960000076, 2.909491288000083],
              [46.489643139000066, 2.909427333000053],
              [46.489420505000055, 2.909267446000058],
              [46.489250879000053, 2.909150196000041],
              [46.488964636000048, 2.908926354000073],
              [46.488763205000055, 2.908766467000078],
              [46.488455759000033, 2.908638558000064],
              [46.488127109000061, 2.908393398000044],
              [46.48776665400004, 2.908212193000054],
              [46.487353192000057, 2.907967033000091],
              [46.486526266000055, 2.907295508000061],
              [46.485413097000048, 2.906069706000039],
              [46.485264675000053, 2.905931137000039],
              [46.484692188000054, 2.905451475000064],
              [46.484268124000039, 2.905174337000062],
              [46.483928872000035, 2.904843903000085],
              [46.483589621000078, 2.904588084000068],
              [46.483250369000075, 2.904481492000059],
              [46.482932321000078, 2.90430028600008],
              [46.482667281000033, 2.904119080000044],
              [46.482487054000046, 2.904012489000081],
              [46.482317428000044, 2.903927215000067],
              [46.482211412000083, 2.903884579000078],
              [46.482155257000045, 2.903884579000078],
              [46.478691239000057, 2.901185386000066],
              [46.476182066000035, 2.899503516000038],
              [46.476179097000056, 2.89947167400004],
              [46.47551119600007, 2.89887475800009],
              [46.475118936000058, 2.898618937000037],
              [46.474800888000061, 2.89839509300009],
              [46.473942158000057, 2.897595651000074],
              [46.473698321000086, 2.89743576300009],
              [46.473518093000052, 2.897339830000078],
              [46.473316663000048, 2.897201259000042],
              [46.472977411000045, 2.897073349000038],
              [46.472883272000047, 2.89704058500007],
              [46.470812956000088, 2.89531790500007],
              [46.470238446000053, 2.894821186000058],
              [46.469616702000053, 2.894323263000047],
              [46.468991207000045, 2.893651729000055],
              [46.468174883000074, 2.892990854000061],
              [46.467682736000086, 2.892475833000049],
              [46.462534828000059, 2.888663181000084],
              [46.462375803000043, 2.888460654000085],
              [46.461813918000075, 2.887959666000086],
              [46.461379252000086, 2.88768252400007],
              [46.460955188000071, 2.887479997000071],
              [46.460950902000036, 2.887478884000075],
              [46.460196452000048, 2.886819868000089],
              [46.459831417000032, 2.886595272000079],
              [46.458516818000078, 2.885529338000083],
              [46.457674892000057, 2.884898833000079],
              [46.456054239000082, 2.883398218000082],
              [46.455821252000078, 2.883270441000093],
              [46.455651807000038, 2.883078777000094],
              [46.455418820000034, 2.882844521000038],
              [46.454995208000071, 2.88248248900004],
              [46.454656318000048, 2.882269529000041],
              [46.454402151000068, 2.882035273000042],
              [46.454275067000083, 2.881651944000055],
              [46.454273994000062, 2.881646548000049],
              [46.454124557000057, 2.881516013000066],
              [46.454106549000073, 2.881425483000044],
              [46.454053541000064, 2.881372186000078],
              [46.453905119000069, 2.881137680000052],
              [46.45371429000005, 2.880977789000042],
              [46.45355526600008, 2.880881855000041],
              [46.453163006000068, 2.880583392000062],
              [46.452972177000049, 2.880402182000068],
              [46.452495105000082, 2.879986466000048],
              [46.452049837000061, 2.879602727000076],
              [46.451848407000057, 2.879474815000037],
              [46.451646976000063, 2.879282945000057],
              [46.451445546000059, 2.879048438000041],
              [46.451116896000087, 2.878675359000056],
              [46.450841254000068, 2.878483490000065],
              [46.448276021000083, 2.876713488000064],
              [46.447055231000036, 2.875235205000081],
              [46.447052479000035, 2.875199227000053],
              [46.446777131000033, 2.874922377000075],
              [46.446586505000084, 2.874815896000086],
              [46.44635351900007, 2.874645527000041],
              [46.446268796000084, 2.874326084000074],
              [46.445591017000083, 2.873900161000051],
              [46.445061501000055, 2.873474237000039],
              [46.444616709000059, 2.873090906000073],
              [46.44425663800007, 2.87268627800006],
              [46.443769484000086, 2.872153873000059],
              [46.443367053000088, 2.871813133000046],
              [46.443249255000069, 2.871688458000051],
              [46.443238965000035, 2.871676538000088],
              [46.44254305000004, 2.870853346000047],
              [46.442541009000081, 2.870833507000043],
              [46.442456286000038, 2.870620544000076],
              [46.442244480000056, 2.870301101000052],
              [46.442075035000073, 2.870088138000085],
              [46.441757326000072, 2.869789990000072],
              [46.441587881000032, 2.869577028000037],
              [46.44134230700007, 2.869432994000078],
              [46.440528851000067, 2.86878906600009],
              [46.44012641900008, 2.868448325000088],
              [46.439660446000062, 2.867809437000062],
              [46.439067389000058, 2.867149251000058],
              [46.438304887000072, 2.866127028000051],
              [46.436925992000056, 2.864721004000046],
              [46.434971631000053, 2.862679486000047],
              [46.434296255000049, 2.861901043000046],
              [46.434259390000079, 2.861889051000048],
              [46.433618541000044, 2.861365964000072],
              [46.43248021800008, 2.860291819000054],
              [46.432095686000082, 2.859771725000087],
              [46.43104872300006, 2.858248870000068],
              [46.429882787000054, 2.856892577000053],
              [46.42864546800007, 2.855155571000068],
              [46.427907835000042, 2.854298965000055],
              [46.42740814800004, 2.853656510000064],
              [46.426884667000081, 2.853228207000086],
              [46.426337391000061, 2.852752315000089],
              [46.425385606000077, 2.851396022000074],
              [46.424715487000071, 2.850525583000092],
              [46.422458869000081, 2.847826831000077],
              [46.420371542000055, 2.845719968000083],
              [46.420364909000057, 2.845703665000087],
              [46.420131922000053, 2.845277731000067],
              [46.419708310000033, 2.844681423000054],
              [46.419056240000089, 2.844019693000064],
              [46.417992680000054, 2.842679528000076],
              [46.417230178000068, 2.842019328000049],
              [46.416679483000053, 2.841550799000061],
              [46.416492380000079, 2.841428516000065],
              [46.412422608000043, 2.836926176000077],
              [46.412168012000052, 2.836886793000076],
              [46.411917857000049, 2.836571980000087],
              [46.411556253000072, 2.836193837000053],
              [46.411151343000085, 2.835885839000071],
              [46.410261757000058, 2.835310823000043],
              [46.40918154600007, 2.834437649000051],
              [46.408334322000087, 2.833798741000066],
              [46.407783626000082, 2.83320242700006],
              [46.406936401000053, 2.83252092500004],
              [46.406428067000036, 2.832073689000083],
              [46.405919732000086, 2.831583858000045],
              [46.405496120000066, 2.831221810000045],
              [46.405305494000061, 2.831072731000063],
              [46.405136050000067, 2.830817167000077],
              [46.404755679000061, 2.830551944000092],
              [46.403946662000067, 2.829719133000083],
              [46.403494565000074, 2.829362213000081],
              [46.403060350000032, 2.828943030000062],
              [46.402403751000065, 2.828368010000077],
              [46.401471804000039, 2.827409643000067],
              [46.400561038000035, 2.826344790000064],
              [46.399840897000047, 2.825854958000093],
              [46.39876068600006, 2.82502437100004],
              [46.398061725000048, 2.824577132000059],
              [46.397405126000081, 2.824066001000062],
              [46.396960334000084, 2.823618762000081],
              [46.396282554000038, 2.822979848000045],
              [46.395541233000074, 2.822319636000088],
              [46.395054079000033, 2.822000179000042],
              [46.394397480000066, 2.821616830000039],
              [46.39352907500006, 2.820977915000071],
              [46.392554767000036, 2.820232514000054],
              [46.391977974000042, 2.81986816400007],
              [46.390907216000073, 2.818892585000071],
              [46.389431950000073, 2.817631471000084],
              [46.388805798000078, 2.817165715000044],
              [46.387937393000072, 2.816718473000094],
              [46.386576606000062, 2.815919166000072],
              [46.384924519000037, 2.81488624800005],
              [46.382493976000035, 2.813215070000069],
              [46.380223436000051, 2.811873175000073],
              [46.377986743000065, 2.809898222000072],
              [46.374572428000079, 2.807635159000085],
              [46.37127666300006, 2.806186263000086],
              [46.369182737000074, 2.805067917000088],
              [46.365846016000035, 2.803290478000065],
              [46.363376852000044, 2.801808055000038],
              [46.359110582000085, 2.798487881000085],
              [46.353716241000086, 2.794193780000057],
              [46.351019588000042, 2.792077290000066],
              [46.349346320000052, 2.790842022000049],
              [46.347736593000036, 2.789478967000093],
              [46.347263169000087, 2.788903748000052],
              [46.346437737000088, 2.788358599000048],
              [46.346126867000066, 2.787988123000048],
              [46.345046656000079, 2.787008425000067],
              [46.343182762000083, 2.785176812000088],
              [46.342250815000057, 2.784388792000072],
              [46.341806022000071, 2.783941537000089],
              [46.340344560000062, 2.78247198400004],
              [46.33939143300006, 2.781577473000084],
              [46.339166048000038, 2.78138517900004],
              [46.338715081000032, 2.780656340000064],
              [46.338154566000071, 2.779750452000087],
              [46.338149830000077, 2.779746175000071],
              [46.336201303000053, 2.777986368000086],
              [46.334450573000083, 2.775443522000046],
              [46.333040263000044, 2.773291879000055],
              [46.331532690000074, 2.769722099000091],
              [46.331385870000076, 2.768583197000055],
              [46.331363981000038, 2.768585682000094],
              [46.331300439000074, 2.768585682000094],
              [46.331173356000079, 2.768415297000047],
              [46.330961549000051, 2.767478179000079],
              [46.330728563000037, 2.766328078000072],
              [46.330230961000041, 2.764973999000063],
              [46.330304950000084, 2.764134364000085],
              [46.330114325000068, 2.763325031000079],
              [46.330050783000047, 2.762686084000052],
              [46.33001699600004, 2.762261398000078],
              [46.329826452000077, 2.761880699000073],
              [46.329683684000088, 2.761357218000057],
              [46.329540917000088, 2.760167487000047],
              [46.329183998000076, 2.759191908000048],
              [46.328446365000048, 2.757740437000052],
              [46.327756321000038, 2.757478697000067],
              [46.326519001000065, 2.757621464000067],
              [46.326043109000068, 2.757740437000052],
              [46.324401281000064, 2.757859410000037],
              [46.321664901000076, 2.756693474000087],
              [46.319569371000057, 2.755638477000048],
              [46.318238477000079, 2.755004057000065],
              [46.317533042000036, 2.754571425000051],
              [46.316715622000061, 2.753861916000062],
              [46.315430713000069, 2.752886337000064],
              [46.314144144000068, 2.752207301000055],
              [46.313551087000064, 2.751781333000054],
              [46.31300039100006, 2.751248872000076],
              [46.311877819000074, 2.749949665000088],
              [46.311546494000083, 2.749542459000054],
              [46.311369484000068, 2.749417203000064],
              [46.310065304000034, 2.747668617000045],
              [46.310035106000043, 2.747628129000077],
              [46.309018436000088, 2.745902948000094],
              [46.308044128000063, 2.74400737600007],
              [46.307112181000036, 2.742133099000057],
              [46.306985098000041, 2.741771023000069],
              [46.306243211000037, 2.740654018000043],
              [46.30560353900006, 2.738324035000062],
              [46.305032468000036, 2.736039753000057],
              [46.304556576000039, 2.734017211000094],
              [46.303699970000082, 2.730305252000051],
              [46.30358099700004, 2.728782397000089],
              [46.303661496000075, 2.727519394000069],
              [46.303899442000045, 2.725675311000089],
              [46.303747560000033, 2.724570751000044],
              [46.30334305100007, 2.722524414000077],
              [46.302860234000036, 2.721242142000051],
              [46.302763423000044, 2.720868959000086],
              [46.302553666000051, 2.720317298000055],
              [46.302343909000058, 2.719976566000071],
              [46.302315843000088, 2.719943211000043],
              [46.302174631000071, 2.719316734000074],
              [46.301933912000038, 2.718640622000066],
              [46.30179531400006, 2.718467608000083],
              [46.301537152000037, 2.717899720000048],
              [46.301230584000052, 2.717283155000075],
              [46.300924016000067, 2.716634140000053],
              [46.30034315100005, 2.715790419000086],
              [46.299955907000083, 2.715108952000094],
              [46.29906847400008, 2.713989398000081],
              [46.298584420000054, 2.713518861000068],
              [46.297906743000055, 2.712740039000039],
              [46.297370604000037, 2.712078581000071],
              [46.296664337000038, 2.711231072000089],
              [46.296196418000079, 2.710711857000092],
              [46.295873715000084, 2.710354897000059],
              [46.295744633000083, 2.710225093000076],
              [46.295728498000074, 2.710062838000056],
              [46.295728498000074, 2.709996849000049],
              [46.295658026000069, 2.709927271000083],
              [46.295565456000077, 2.709773317000042],
              [46.295421930000089, 2.709738328000071],
              [46.295292849000077, 2.709624750000046],
              [46.295179903000076, 2.709527397000045],
              [46.294905606000043, 2.709251564000056],
              [46.294760389000032, 2.709089309000092],
              [46.294663578000041, 2.708927054000071],
              [46.294650833000048, 2.708890738000093],
              [46.294572843000083, 2.708887471000082],
              [46.292445120000082, 2.70724827500004],
              [46.291540925000049, 2.706796177000058],
              [46.290422578000062, 2.70622510600009],
              [46.289230065000083, 2.705319022000083],
              [46.288090706000048, 2.704488100000049],
              [46.287019949000069, 2.703583905000073],
              [46.286579701000051, 2.703329916000087],
              [46.286401289000082, 2.703226986000061],
              [46.285901602000081, 2.702917656000068],
              [46.284926023000082, 2.702227612000058],
              [46.283807676000038, 2.701727925000057],
              [46.280642993000072, 2.70125203300006],
              [46.27866804100006, 2.700347838000084],
              [46.27667649600005, 2.69910669300009],
              [46.275600079000071, 2.698447808000083],
              [46.275591833000078, 2.698380435000047],
              [46.274898022000059, 2.697796312000094],
              [46.274397832000034, 2.697504250000065],
              [46.274123534000069, 2.697341993000066],
              [46.273833102000083, 2.69719596200008],
              [46.273494264000078, 2.696920126000066],
              [46.273219966000056, 2.696692967000047],
              [46.272913398000071, 2.69651448500008],
              [46.272445479000055, 2.696173746000056],
              [46.27210664100005, 2.695897909000053],
              [46.271672425000077, 2.695755478000081],
              [46.271029971000075, 2.695422353000083],
              [46.270485460000089, 2.695185936000087],
              [46.268198412000061, 2.693518785000037],
              [46.265199491000033, 2.69118420500007],
              [46.262178376000065, 2.689021603000072],
              [46.258780149000074, 2.68648937200004],
              [46.255667741000082, 2.684337568000046],
              [46.253333435000059, 2.682674807000069],
              [46.250852141000053, 2.68114558700006],
              [46.249229393000064, 2.680118617000062],
              [46.24769219500007, 2.679055850000054],
              [46.245783889000052, 2.677980904000037],
              [46.244094472000086, 2.676672201000088],
              [46.241564642000071, 2.674263160000066],
              [46.239771803000053, 2.671818770000073],
              [46.239026220000085, 2.670913905000077],
              [46.238550328000088, 2.670390424000061],
              [46.23828737000008, 2.669952779000084],
              [46.237674234000053, 2.669612032000089],
              [46.237190180000084, 2.669336190000081],
              [46.236980423000034, 2.669271286000082],
              [46.236770148000062, 2.66921086900004],
              [46.234505244000047, 2.668082347000052],
              [46.232519205000074, 2.666829562000089],
              [46.229175252000061, 2.664703512000074],
              [46.226534050000055, 2.66251440800005],
              [46.222501142000056, 2.659689354000079],
              [46.219291471000076, 2.656363763000058],
              [46.216778260000069, 2.653186921000042],
              [46.215540940000039, 2.652116163000073],
              [46.215288503000068, 2.651830315000041],
              [46.215175340000087, 2.651784794000093],
              [46.215016911000077, 2.651648231000081],
              [46.214813217000085, 2.651488907000044],
              [46.214632155000061, 2.651329584000052],
              [46.214451094000083, 2.651170260000072],
              [46.214337930000056, 2.650942655000051],
              [46.214337930000056, 2.650624008000079],
              [46.214337930000056, 2.650534444000073],
              [46.214216497000052, 2.650401706000082],
              [46.214213728000061, 2.650351586000056],
              [46.214197593000051, 2.650286681000068],
              [46.214149188000079, 2.65022177600008],
              [46.214036242000077, 2.650059513000087],
              [46.213923296000075, 2.649913477000041],
              [46.213681268000073, 2.649734988000091],
              [46.213663500000052, 2.649534883000058],
              [46.213413279000065, 2.649300700000083],
              [46.212899738000033, 2.64899906900007],
              [46.212542819000078, 2.648761123000043],
              [46.211900365000076, 2.648309026000049],
              [46.211237252000046, 2.647824462000074],
              [46.210264047000067, 2.64668643300007],
              [46.20972086200004, 2.645912573000089],
              [46.209404005000067, 2.645434600000044],
              [46.209132412000088, 2.644615218000069],
              [46.208878449000053, 2.643835639000088],
              [46.208235995000052, 2.642860060000089],
              [46.207186002000071, 2.641997743000047],
              [46.206371225000055, 2.641474247000076],
              [46.205782775000046, 2.641110076000075],
              [46.205285463000052, 2.64069475000008],
              [46.204476446000058, 2.639957117000051],
              [46.203881581000076, 2.639505020000058],
              [46.203381894000074, 2.63907671700008],
              [46.202787029000035, 2.638696003000064],
              [46.202494386000069, 2.638346779000074],
              [46.202269738000041, 2.637925062000079],
              [46.202199040000039, 2.637647133000087],
              [46.201799119000043, 2.637306824000063],
              [46.201610769000069, 2.63732260900008],
              [46.201490956000043, 2.637292486000092],
              [46.201311237000084, 2.63721718000005],
              [46.201161471000034, 2.637262364000094],
              [46.201026682000077, 2.637262364000094],
              [46.200802034000048, 2.637187057000062],
              [46.200682221000079, 2.637111750000088],
              [46.200592362000066, 2.637006321000058],
              [46.200397666000072, 2.636674972000094],
              [46.200128088000042, 2.636358684000072],
              [46.199873486000058, 2.636117703000082],
              [46.199723721000055, 2.635876721000045],
              [46.199709074000054, 2.635850209000068],
              [46.199491624000075, 2.635704421000071],
              [46.199318145000063, 2.635467653000092],
              [46.19875201900004, 2.634932828000046],
              [46.197647393000068, 2.633223243000089],
              [46.196314895000057, 2.631605209000043],
              [46.195339316000059, 2.630486863000044],
              [46.194377414000087, 2.629187755000089],
              [46.193725512000071, 2.628376887000059],
              [46.192864677000046, 2.626774903000069],
              [46.192650525000033, 2.626203833000091],
              [46.192222222000055, 2.624990308000065],
              [46.191770125000062, 2.624347853000074],
              [46.191603562000068, 2.624038523000081],
              [46.191389411000046, 2.623229507000076],
              [46.191151465000075, 2.622015981000061],
              [46.191082099000084, 2.621299204000081],
              [46.191008697000086, 2.62054071600005],
              [46.190944581000053, 2.619623982000064],
              [46.190965566000045, 2.619001412000046],
              [46.190976059000036, 2.618589882000038],
              [46.191007536000086, 2.618093935000047],
              [46.190913519000048, 2.617209470000091],
              [46.190580394000051, 2.61656701600009],
              [46.19008070700005, 2.61640045300004],
              [46.189390664000086, 2.615853177000076],
              [46.188824821000082, 2.615759060000073],
              [46.188730661000079, 2.615761929000087],
              [46.188415085000088, 2.615615231000049],
              [46.187677452000059, 2.615282107000041],
              [46.186957426000049, 2.615097148000075],
              [46.186792290000085, 2.614857694000079],
              [46.186774257000081, 2.61485556000008],
              [46.18639083000005, 2.614675065000085],
              [46.186249796000084, 2.614602572000081],
              [46.185972207000077, 2.614459887000066],
              [46.185919907000084, 2.614387365000084],
              [46.185897682000075, 2.614390159000038],
              [46.185771772000066, 2.614369055000054],
              [46.185551429000043, 2.614284638000072],
              [46.185477982000066, 2.614210773000082],
              [46.185415027000033, 2.614168565000057],
              [46.185331086000076, 2.614126357000089],
              [46.185215669000058, 2.61404194000005],
              [46.185152714000083, 2.613989179000043],
              [46.185068774000058, 2.613862554000093],
              [46.184921878000068, 2.613704273000053],
              [46.184843045000036, 2.613588955000068],
              [46.183917903000065, 2.612783673000081],
              [46.182894735000048, 2.611308407000081],
              [46.182299870000065, 2.61021385500004],
              [46.18218089700008, 2.609928320000051],
              [46.182014334000087, 2.609262070000057],
              [46.181871567000087, 2.608905151000044],
              [46.18177638800006, 2.608548232000089],
              [46.181758526000067, 2.608378545000051],
              [46.181728799000041, 2.608096135000039],
              [46.181871567000087, 2.607358502000068],
              [46.181823978000068, 2.606692253000062],
              [46.181895361000045, 2.606359128000065],
              [46.181966745000068, 2.605669084000056],
              [46.181705005000083, 2.604622121000091],
              [46.180800809000061, 2.603955872000085],
              [46.179539695000074, 2.603789310000082],
              [46.178968624000049, 2.603813105000086],
              [46.178492732000052, 2.603741721000063],
              [46.178399574000082, 2.603723690000038],
              [46.17775509900008, 2.603598953000073],
              [46.177088850000075, 2.603408596000065],
              [46.176404348000062, 2.603115078000087],
              [46.17571775600004, 2.602786270000081],
              [46.172996177000073, 2.60071980500004],
              [46.171195038000064, 2.598726904000046],
              [46.170969638000088, 2.59843305600009],
              [46.170777230000056, 2.59818221900008],
              [46.170711895000068, 2.597531328000059],
              [46.170194545000072, 2.596811209000066],
              [46.169712521000065, 2.595651554000085],
              [46.169093861000079, 2.594628385000078],
              [46.168617969000081, 2.592367897000088],
              [46.168860581000047, 2.590625505000048],
              [46.168879710000056, 2.590488123000057],
              [46.169308013000034, 2.58932218700005],
              [46.169522164000057, 2.588251430000071],
              [46.169093861000079, 2.587418618000072],
              [46.168522791000044, 2.586276477000069],
              [46.167594801000064, 2.585610228000064],
              [46.166428865000057, 2.58520572000009],
              [46.165810205000071, 2.585229514000048],
              [46.165623535000066, 2.585281782000038],
              [46.165215340000088, 2.585396077000041],
              [46.164430118000041, 2.585610228000064],
              [46.163668690000065, 2.585634023000068],
              [46.162978646000056, 2.585705407000091],
              [46.162193424000066, 2.585657817000083],
              [46.161297029000082, 2.585403887000041],
              [46.161094353000067, 2.585469832000058],
              [46.160301609000044, 2.585374920000049],
              [46.159328982000034, 2.584690199000079],
              [46.158243519000052, 2.583611481000048],
              [46.157471291000036, 2.582377854000072],
              [46.157589278000046, 2.581926946000067],
              [46.157363807000081, 2.581649798000058],
              [46.156934815000056, 2.581350993000058],
              [46.156512026000087, 2.581019916000059],
              [46.156211398000039, 2.580793158000063],
              [46.155484879000085, 2.580264057000079],
              [46.154307418000087, 2.579734956000038],
              [46.15400678900005, 2.579709761000061],
              [46.153756266000073, 2.579583784000079],
              [46.151977548000048, 2.578424799000061],
              [46.151326186000063, 2.577895697000088],
              [46.150524510000082, 2.577517767000074],
              [46.150223882000034, 2.577467376000072],
              [46.148470216000078, 2.576459562000082],
              [46.146691498000052, 2.57454471200009],
              [46.144870948000062, 2.572689755000056],
              [46.144204699000056, 2.572356630000058],
              [46.143184166000083, 2.571344891000081],
              [46.141682470000035, 2.570119937000072],
              [46.140802070000063, 2.568478109000068],
              [46.13908607500008, 2.566715419000047],
              [46.137970511000049, 2.565384810000069],
              [46.136423862000072, 2.563814365000042],
              [46.134758239000064, 2.56214874300008],
              [46.132926054000052, 2.56067347700008],
              [46.132164626000076, 2.559483746000069],
              [46.130546593000076, 2.557681305000074],
              [46.129404452000074, 2.556325012000059],
              [46.129142711000043, 2.55576583800007],
              [46.129089073000046, 2.555662688000041],
              [46.128988046000075, 2.555468406000045],
              [46.128869073000033, 2.555242357000054],
              [46.128714408000064, 2.554956822000065],
              [46.127879963000055, 2.553971020000063],
              [46.127232273000061, 2.553339361000042],
              [46.126162382000075, 2.55182315400009],
              [46.12575127100007, 2.551139344000092],
              [46.125136305000069, 2.550404889000049],
              [46.124143061000041, 2.548997152000084],
              [46.122775488000059, 2.547480145000065],
              [46.122527809000076, 2.547062960000062],
              [46.121775497000044, 2.546371027000077],
              [46.121180631000072, 2.545478729000081],
              [46.12052906200006, 2.544183812000085],
              [46.119267948000072, 2.54149502100006],
              [46.118649288000086, 2.540519442000061],
              [46.118006833000038, 2.53799721300004],
              [46.117861283000082, 2.536948361000043],
              [46.117861283000082, 2.535937090000061],
              [46.118745750000073, 2.535398033000092],
              [46.119244153000068, 2.535094271000048],
              [46.120169360000034, 2.534652182000059],
              [46.11856322400007, 2.532808099000079],
              [46.117791089000036, 2.531642936000082],
              [46.117649914000083, 2.531429901000081],
              [46.117220054000086, 2.530653129000086],
              [46.116484738000054, 2.529324387000088],
              [46.115800683000089, 2.528982414000041],
              [46.115603578000048, 2.528883878000045],
              [46.115004827000064, 2.528045628000086],
              [46.114770766000049, 2.527717942000038],
              [46.114634882000075, 2.527610172000038],
              [46.113390679000076, 2.526623390000054],
              [46.10982148800008, 2.523030404000053],
              [46.108898077000049, 2.521313927000051],
              [46.108212569000045, 2.520315929000049],
              [46.105654648000041, 2.517163143000062],
              [46.103513133000035, 2.515081115000044],
              [46.099587023000083, 2.512166275000084],
              [46.096342761000074, 2.510042758000054],
              [46.093831510000086, 2.50842051300009],
              [46.091258909000032, 2.506515055000079],
              [46.091270222000048, 2.506406458000072],
              [46.091333076000069, 2.505803106000087],
              [46.089834015000065, 2.504875116000051],
              [46.087311786000043, 2.502709807000087],
              [46.087639173000071, 2.502313728000047],
              [46.086740716000065, 2.501353514000073],
              [46.08604811400005, 2.500928698000052],
              [46.084194693000086, 2.49956891800008],
              [46.080696885000066, 2.496689770000046],
              [46.078892683000049, 2.494988552000052],
              [46.076675596000086, 2.493358525000076],
              [46.074321334000047, 2.491466876000061],
              [46.073391940000079, 2.490883885000073],
              [46.072554538000077, 2.490313479000065],
              [46.070084489000067, 2.488908933000062],
              [46.067371903000037, 2.48693398000006],
              [46.066610476000051, 2.48614875800007],
              [46.065567686000065, 2.485328377000087],
              [46.06370753300007, 2.482817513000043],
              [46.062708160000057, 2.482198853000057],
              [46.060209726000039, 2.480557025000053],
              [46.059424503000059, 2.480390462000059],
              [46.058377541000084, 2.479057964000049],
              [46.056997453000065, 2.477368547000083],
              [46.055950490000043, 2.476012254000068],
              [46.055022501000053, 2.474536988000068],
              [46.054568282000048, 2.473722137000038],
              [46.054070716000069, 2.473846945000048],
              [46.051952996000068, 2.473608999000078],
              [46.050908918000061, 2.473095246000071],
              [46.050453935000064, 2.472871366000049],
              [46.049621124000055, 2.472205117000044],
              [46.048931080000045, 2.471753019000062],
              [46.046610888000089, 2.469814362000079],
              [46.044598989000065, 2.467650396000067],
              [46.044364405000067, 2.467304011000067],
              [46.043405786000051, 2.46582071000006],
              [46.042149617000064, 2.46387700300005],
              [46.039936718000035, 2.461925845000053],
              [46.039460826000038, 2.460759909000046],
              [46.038723193000067, 2.460093660000041],
              [46.037692982000067, 2.458765163000066],
              [46.037033775000054, 2.458190091000063],
              [46.036415116000057, 2.457785583000089],
              [46.035439536000069, 2.456881388000056],
              [46.033559762000039, 2.454644694000081],
              [46.03168690800004, 2.452471616000082],
              [46.028824635000035, 2.448505685000043],
              [46.028896019000058, 2.448101177000069],
              [46.028627670000049, 2.447656769000048],
              [46.027294791000088, 2.446955990000049],
              [46.025778925000054, 2.446007251000083],
              [46.025207854000087, 2.444984083000065],
              [46.02482714000007, 2.444294039000056],
              [46.024517810000077, 2.443818147000059],
              [46.024184686000069, 2.441629043000091],
              [46.022360161000051, 2.439809410000066],
              [46.021448306000082, 2.439201992000051],
              [46.02074768500006, 2.438399185000094],
              [46.019378175000043, 2.437369807000039],
              [46.017879114000038, 2.436441818000048],
              [46.015866418000087, 2.435607919000063],
              [46.013726637000048, 2.433260030000042],
              [46.013143060000061, 2.431694768000057],
              [46.009741358000042, 2.42918446200008],
              [46.007988134000072, 2.427634862000048],
              [46.005910425000081, 2.42637669800007],
              [46.004863463000049, 2.424568307000072],
              [46.004483250000078, 2.424663502000044],
              [46.003411991000064, 2.423568934000059],
              [46.001912931000049, 2.422759917000064],
              [46.000175924000075, 2.421237062000046],
              [45.998629275000042, 2.420689786000082],
              [45.997748874000081, 2.420118715000058],
              [45.996657023000068, 2.419539471000064],
              [45.994614506000062, 2.418463343000042],
              [45.992395087000034, 2.416787470000088],
              [45.991210309000053, 2.416017594000039],
              [45.990791547000072, 2.416512763000071],
              [45.990539107000075, 2.416811264000046],
              [45.990229777000081, 2.41762028100004],
              [45.989605474000086, 2.417974194000067],
              [45.988183441000047, 2.417358540000066],
              [45.987222536000047, 2.41675131900007],
              [45.986327461000087, 2.415573945000062],
              [45.985042552000039, 2.414098679000062],
              [45.98227959500008, 2.413002238000047],
              [45.979331846000036, 2.41124332600009],
              [45.978784570000073, 2.410648460000061],
              [45.977880375000041, 2.409149400000047],
              [45.976965387000064, 2.408114171000079],
              [45.976281458000074, 2.407485086000065],
              [45.97576265400005, 2.407007885000041],
              [45.97555445900008, 2.406977655000048],
              [45.975280575000056, 2.406937886000094],
              [45.974367887000085, 2.406220774000076],
              [45.972074490000068, 2.404842575000089],
              [45.971127811000088, 2.404523499000049],
              [45.969647440000074, 2.403748023000048],
              [45.968338736000078, 2.402439320000042],
              [45.967622921000043, 2.401985015000093],
              [45.967030033000071, 2.401487535000058],
              [45.966292400000043, 2.400773697000091],
              [45.966031165000061, 2.40033057900007],
              [45.965826457000048, 2.399983344000077],
              [45.964983601000085, 2.399227154000073],
              [45.961009996000087, 2.395848213000079],
              [45.95860674100004, 2.393897055000082],
              [45.957428764000042, 2.39330816100005],
              [45.955899184000089, 2.392299415000082],
              [45.954323711000086, 2.39137482600006],
              [45.953673452000032, 2.390960649000078],
              [45.952694258000065, 2.39033696000007],
              [45.951468358000056, 2.389376079000044],
              [45.950231038000084, 2.388210143000038],
              [45.948731977000079, 2.386925234000046],
              [45.945162786000083, 2.383808140000042],
              [45.941866049000055, 2.381481711000049],
              [45.941265884000074, 2.381238561000089],
              [45.939746730000081, 2.380463108000072],
              [45.93855699900007, 2.379927730000077],
              [45.937813056000039, 2.378694912000071],
              [45.937188809000077, 2.377488782000057],
              [45.935939592000068, 2.376418025000078],
              [45.935700526000062, 2.376263600000073],
              [45.934597979000046, 2.375551410000071],
              [45.933024752000051, 2.374395483000058],
              [45.931180877000088, 2.373677918000055],
              [45.930481512000085, 2.372934003000069],
              [45.929386959000055, 2.372553290000042],
              [45.928108623000071, 2.371913946000063],
              [45.926627839000048, 2.370966102000068],
              [45.925246697000034, 2.369745526000088],
              [45.92391419900008, 2.368650974000047],
              [45.922317178000071, 2.367495046000045],
              [45.920651555000063, 2.366364802000078],
              [45.918132109000055, 2.364534506000041],
              [45.917088066000076, 2.363793386000054],
              [45.915633675000038, 2.362369196000088],
              [45.914323183000079, 2.361534917000085],
              [45.91358733800007, 2.360893931000078],
              [45.912016894000033, 2.359751789000086],
              [45.910303682000063, 2.358633443000087],
              [45.907900427000072, 2.357324739000092],
              [45.905116457000076, 2.355611527000065],
              [45.904259851000063, 2.354540770000085],
              [45.902506676000087, 2.353258159000063],
              [45.901870070000086, 2.352831259000084],
              [45.90075594700005, 2.35208414300007],
              [45.899001242000054, 2.351828184000055],
              [45.897481331000051, 2.351433402000055],
              [45.897169057000042, 2.351352292000058],
              [45.895552389000045, 2.350420953000082],
              [45.895315522000033, 2.350111130000073],
              [45.89440888300004, 2.348925242000064],
              [45.893528482000079, 2.348473144000081],
              [45.893242947000033, 2.347592744000053],
              [45.89255290300008, 2.348306582000077],
              [45.89237587000008, 2.348315434000085],
              [45.891601119000086, 2.34835417100004],
              [45.890348832000086, 2.347730494000075],
              [45.889230310000073, 2.346996732000093],
              [45.887984338000081, 2.346117478000053],
              [45.886271126000054, 2.345165693000069],
              [45.884415804000071, 2.344012760000055],
              [45.882762337000088, 2.343132243000071],
              [45.881845329000043, 2.342262751000078],
              [45.87982278700008, 2.340763691000063],
              [45.878490289000069, 2.339883290000046],
              [45.878233849000083, 2.339675955000075],
              [45.876253595000037, 2.338074900000038],
              [45.874592265000047, 2.336870770000075],
              [45.873133324000037, 2.335794576000069],
              [45.871042175000071, 2.335501069000088],
              [45.870423541000036, 2.335541552000052],
              [45.869162801000073, 2.335624055000039],
              [45.866949903000034, 2.33469606500006],
              [45.865641199000038, 2.333149415000094],
              [45.864956551000034, 2.333048278000092],
              [45.864356290000046, 2.332459372000073],
              [45.864156327000046, 2.332235414000081],
              [45.863166560000082, 2.331126873000073],
              [45.861358169000084, 2.330413035000049],
              [45.860572947000037, 2.329675402000078],
              [45.859618380000086, 2.329483156000038],
              [45.857884304000038, 2.328926738000064],
              [45.857182232000071, 2.328701462000083],
              [45.856313712000087, 2.328557056000079],
              [45.854695678000041, 2.328342904000067],
              [45.853764149000085, 2.32823837400008],
              [45.851649968000061, 2.327486298000053],
              [45.84924671300007, 2.32648692500004],
              [45.846867252000038, 2.325368578000052],
              [45.84502357100007, 2.32451554700009],
              [45.843179087000067, 2.323393625000051],
              [45.840871010000058, 2.321775592000051],
              [45.839919226000063, 2.321109343000046],
              [45.838348781000036, 2.319610282000042],
              [45.837515970000084, 2.318896444000075],
              [45.836683159000074, 2.318230195000069],
              [45.83587414200008, 2.318016043000057],
              [45.835374455000078, 2.317706713000064],
              [45.834303698000042, 2.316826313000092],
              [45.833304324000039, 2.31592211800006],
              [45.832638075000034, 2.315303458000074],
              [45.832566691000068, 2.313471273000062],
              [45.833756422000079, 2.313542657000085],
              [45.833994368000049, 2.313161943000068],
              [45.833494681000047, 2.313019175000079],
              [45.832257361000075, 2.311972212000057],
              [45.831900442000062, 2.31218636400007],
              [45.83041049600007, 2.311994118000086],
              [45.829925489000061, 2.311163196000052],
              [45.829235446000041, 2.311139401000048],
              [45.827926742000045, 2.310996633000059],
              [45.827760180000041, 2.311877034000076],
              [45.826665628000057, 2.311639088000049],
              [45.824547908000056, 2.310187617000054],
              [45.823405766000064, 2.31002105400006],
              [45.822287420000066, 2.309378600000059],
              [45.821240457000044, 2.308593378000069],
              [45.819479656000055, 2.308236459000057],
              [45.818146757000079, 2.308078444000046],
              [45.817457114000035, 2.308046102000048],
              [45.815895784000077, 2.307592115000091],
              [45.814673144000039, 2.306642220000072],
              [45.813554798000041, 2.306094944000051],
              [45.812644855000087, 2.305294135000054],
              [45.811484667000059, 2.304762446000041],
              [45.810080785000082, 2.303477537000049],
              [45.808510340000055, 2.302121244000091],
              [45.807510967000042, 2.300883924000061],
              [45.806574572000045, 2.300166521000051],
              [45.80560461500005, 2.299620921000042],
              [45.805417041000055, 2.298266517000059],
              [45.805036327000039, 2.297814419000076],
              [45.804560435000042, 2.29821892800004],
              [45.803989364000074, 2.298052366000093],
              [45.803394499000035, 2.297481295000068],
              [45.802847223000072, 2.297814419000076],
              [45.801609903000042, 2.297314733000064],
              [45.801609903000042, 2.296553305000089],
              [45.801229190000072, 2.296172591000072],
              [45.800753297000085, 2.296696073000078],
              [45.80023488900008, 2.297051056000043],
              [45.798921113000063, 2.296101207000049],
              [45.798579714000084, 2.295155714000089],
              [45.798112096000068, 2.294863888000066],
              [45.797493436000082, 2.294768709000039],
              [45.797993123000083, 2.295411164000086],
              [45.797255490000055, 2.295791878000045],
              [45.796336918000065, 2.296449067000083],
              [45.795280537000053, 2.296767457000044],
              [45.794376342000078, 2.297005403000071],
              [45.793376968000075, 2.297124376000056],
              [45.792996255000048, 2.296838840000078],
              [45.792544157000066, 2.296600894000051],
              [45.792163443000049, 2.296315359000062],
              [45.792472773000043, 2.295744288000094],
              [45.791735140000071, 2.295339780000063],
              [45.790474026000084, 2.294744915000081],
              [45.789379474000043, 2.293912103000082],
              [45.788403895000044, 2.29286514100005],
              [45.78759487800005, 2.292222686000059],
              [45.786167202000058, 2.291270902000065],
              [45.785596131000034, 2.29046188500007],
              [45.785286801000041, 2.289557690000038],
              [45.784596757000088, 2.289248360000045],
              [45.783338265000054, 2.288802301000089],
              [45.782777303000046, 2.288324160000059],
              [45.781836582000039, 2.287725505000083],
              [45.780932387000064, 2.286607158000038],
              [45.779956808000065, 2.286797515000046],
              [45.779933014000051, 2.287344791000066],
              [45.779184092000037, 2.287352954000085],
              [45.77850533700007, 2.287106845000039],
              [45.777981856000054, 2.286845104000065],
              [45.777363196000067, 2.286440596000091],
              [45.776655744000038, 2.285853109000072],
              [45.776423958000066, 2.285759845000086],
              [45.775496815000054, 2.285293525000043],
              [45.774662386000045, 2.284873838000067],
              [45.773596171000065, 2.284174358000087],
              [45.771771462000061, 2.282086182000057],
              [45.771152803000064, 2.281324755000071],
              [45.770486553000069, 2.280420559000049],
              [45.769582358000036, 2.279635337000059],
              [45.768321244000049, 2.278493196000056],
              [45.766679416000045, 2.277374849000068],
              [45.764799642000071, 2.276185119000047],
              [45.763728884000045, 2.275614048000079],
              [45.762729511000089, 2.275114361000078],
              [45.761682548000067, 2.274448112000073],
              [45.759897952000074, 2.273163203000081],
              [45.75904134600006, 2.272877668000092],
              [45.758134368000071, 2.272078660000091],
              [45.753544791000081, 2.269284682000091],
              [45.753854121000074, 2.268856379000056],
              [45.753497202000062, 2.268428076000077],
              [45.753068899000084, 2.26888017400006],
              [45.752569212000083, 2.26902294100006],
              [45.748405155000057, 2.266833837000092],
              [45.746766161000039, 2.265734264000059],
              [45.745359445000076, 2.264501965000079],
              [45.744550429000071, 2.263502592000066],
              [45.743003779000048, 2.263145673000054],
              [45.74285283200004, 2.262752843000044],
              [45.742289941000081, 2.262027326000066],
              [45.74110021000007, 2.262550807000082],
              [45.739958069000068, 2.262074915000085],
              [45.739196641000035, 2.262170094000055],
              [45.738269359000071, 2.261452806000079],
              [45.737126510000053, 2.261218309000071],
              [45.735084903000086, 2.260367476000056],
              [45.734390130000065, 2.260671033000051],
              [45.733557319000056, 2.26017134600005],
              [45.732819686000084, 2.259552686000063],
              [45.731844107000086, 2.259171973000093],
              [45.730511609000075, 2.258291572000076],
              [45.730511609000075, 2.257197020000092],
              [45.729714653000087, 2.256815950000089],
              [45.72851286100007, 2.255031711000072],
              [45.727251747000082, 2.254365461000077],
              [45.726369555000076, 2.254725159000088],
              [45.724564465000071, 2.253561166000054],
              [45.720576692000066, 2.250821601000041],
              [45.715973102000078, 2.247917122000047],
              [45.713783998000054, 2.246370472000081],
              [45.712475294000058, 2.245609045000037],
              [45.711000028000058, 2.244562082000073],
              [45.708760552000058, 2.243168208000043],
              [45.707621194000069, 2.242610924000076],
              [45.707002534000083, 2.241825702000085],
              [45.706574231000047, 2.241016685000091],
              [45.705836598000076, 2.240873918000091],
              [45.70545588400006, 2.240516998000089],
              [45.70438512700008, 2.24070735500004],
              [45.702886066000076, 2.239731776000042],
              [45.701188065000053, 2.239026110000054],
              [45.699459642000079, 2.237923386000091],
              [45.698853759000087, 2.238243370000077],
              [45.697517599000037, 2.237457502000041],
              [45.69597095000006, 2.236386744000072],
              [45.693591489000084, 2.234721122000053],
              [45.69127151400005, 2.232996013000047],
              [45.689370728000085, 2.231760582000049],
              [45.68761991000008, 2.230856238000058],
              [45.686087072000078, 2.22990460200009],
              [45.684799290000058, 2.229192905000048],
              [45.684397654000065, 2.228714872000069],
              [45.683477846000073, 2.228401386000087],
              [45.683106988000077, 2.228168217000075],
              [45.682643417000065, 2.228028315000074],
              [45.681762631000083, 2.227841780000062],
              [45.681577202000085, 2.227748512000062],
              [45.68102091600008, 2.227701878000062],
              [45.680557344000079, 2.22751534300005],
              [45.679491130000088, 2.227282174000038],
              [45.678901099000086, 2.227168222000046],
              [45.678330029000051, 2.22695407100008],
              [45.677687574000061, 2.226692330000049],
              [45.677034200000037, 2.226116327000057],
              [45.676759584000081, 2.225835724000092],
              [45.676440387000071, 2.225507033000042],
              [45.675617443000078, 2.224431842000058],
              [45.674695157000087, 2.223203154000089],
              [45.674213561000045, 2.223218317000089],
              [45.673618696000062, 2.222718630000088],
              [45.672881063000034, 2.221481310000058],
              [45.67154856500008, 2.220339169000056],
              [45.670739548000086, 2.219863277000059],
              [45.67007329900008, 2.219292206000091],
              [45.669668791000049, 2.219054260000064],
              [45.669145309000044, 2.218530779000048],
              [45.66897874700004, 2.217269664000071],
              [45.668717006000065, 2.217840735000038],
              [45.667979373000037, 2.217983503000085],
              [45.666313751000075, 2.217341048000094],
              [45.665162462000069, 2.216687888000081],
              [45.663910495000039, 2.216008550000083],
              [45.663172862000067, 2.215723015000037],
              [45.662530408000066, 2.215342301000078],
              [45.662137719000043, 2.215383822000092],
              [45.661234224000054, 2.215462856000045],
              [45.660252165000088, 2.21510720200007],
              [45.658413940000059, 2.213480373000039],
              [45.657200415000034, 2.212992583000073],
              [45.656236733000071, 2.212695151000048],
              [45.655403922000062, 2.212207361000083],
              [45.654499727000086, 2.212005107000039],
              [45.653857272000039, 2.212207361000083],
              [45.653181337000035, 2.212301480000065],
              [45.65168006500005, 2.211648188000083],
              [45.650942433000068, 2.211136604000046],
              [45.650145313000053, 2.21066071100006],
              [45.64955044800007, 2.21027999800009],
              [45.648884199000065, 2.209756516000084],
              [45.648192475000087, 2.209258648000059],
              [45.647681804000058, 2.208902992000048],
              [45.647611663000077, 2.208867712000085],
              [45.646659403000058, 2.208019510000042],
              [45.645767105000061, 2.206698909000067],
              [45.645362597000087, 2.206020762000094],
              [45.645338802000083, 2.205330719000074],
              [45.646028846000036, 2.205509178000057],
              [45.646100229000069, 2.205271232000086],
              [45.64570483600005, 2.204977859000053],
              [45.645231726000077, 2.204628778000085],
              [45.644969985000046, 2.204152886000088],
              [45.644660656000042, 2.204022015000078],
              [45.64457737400005, 2.203903042000093],
              [45.644398915000068, 2.203593712000043],
              [45.64406579000007, 2.203486636000093],
              [45.643827844000043, 2.203248690000066],
              [45.643447130000084, 2.203105923000066],
              [45.643173492000074, 2.203320074000089],
              [45.643268671000044, 2.204033913000046],
              [45.64300693000007, 2.204367037000054],
              [45.642064424000068, 2.204753666000045],
              [45.641579254000078, 2.204759648000049],
              [45.640770237000083, 2.204604983000081],
              [45.640127782000036, 2.20468231600006],
              [45.639604301000077, 2.204515753000067],
              [45.63912840900008, 2.204349191000063],
              [45.638700106000044, 2.204230218000077],
              [45.637350539000067, 2.203884281000057],
              [45.636839868000038, 2.203607659000056],
              [45.636289915000077, 2.20337055400006],
              [45.635700679000081, 2.203133449000063],
              [45.635347138000043, 2.202975379000065],
              [45.635118941000087, 2.20284625100004],
              [45.635034911000048, 2.202649007000048],
              [45.634888205000038, 2.202584839000053],
              [45.634167741000056, 2.20173926700005],
              [45.634090101000083, 2.201473713000041],
              [45.633815125000069, 2.201236608000045],
              [45.633540148000066, 2.200999503000048],
              [45.633225889000073, 2.200643845000059],
              [45.632872348000035, 2.200327704000074],
              [45.632513507000056, 2.200137545000075],
              [45.632037615000058, 2.200089956000056],
              [45.631497465000052, 2.200248669000075],
              [45.630776501000071, 2.199756831000059],
              [45.630229225000051, 2.199376118000089],
              [45.629658154000083, 2.198971609000068],
              [45.629087083000059, 2.198495717000071],
              [45.628468423000072, 2.19823397600004],
              [45.627659407000067, 2.197710495000081],
              [45.626969363000057, 2.197329781000064],
              [45.626445882000041, 2.19706804000009],
              [45.625993784000059, 2.196830094000063],
              [45.625565481000081, 2.196639737000055],
              [45.625137178000045, 2.195997283000054],
              [45.624161599000047, 2.195568980000075],
              [45.622980983000048, 2.194567717000041],
              [45.620473434000075, 2.192975367000088],
              [45.619140936000065, 2.191857021000089],
              [45.618117768000047, 2.191357334000088],
              [45.616615925000076, 2.190403660000072],
              [45.616080546000035, 2.190106228000047],
              [45.614712356000041, 2.189749309000092],
              [45.613344166000047, 2.189570849000063],
              [45.612013405000084, 2.188353831000086],
              [45.611502734000055, 2.187958652000077],
              [45.611143165000044, 2.187310361000073],
              [45.610310353000045, 2.187012929000048],
              [45.609061136000037, 2.186834469000075],
              [45.607336027000088, 2.185704225000052],
              [45.606577382000069, 2.184623459000079],
              [45.606057251000038, 2.184617691000085],
              [45.605861128000072, 2.18442039100006],
              [45.587583543000051, 2.183156967000059],
              [45.460969925000086, 2.171648026000071],
              [45.39097023100004, 2.151651382000068],
              [45.330968857000073, 2.181650162000039],
              [45.340970994000088, 2.721601486000054],
              [45.330968857000073, 2.731599807000066],
              [45.333665848000066, 2.73221778900006],
              [45.120962143000042, 3.041570662000083],
              [45.070962906000034, 3.251550674000043],
              [44.879796900000088, 3.265242633000071],
              [44.78095817600007, 3.27155113200007],
              [44.770959855000058, 3.70150947500008],
              [44.800958632000061, 3.861497880000059],
              [44.750959395000052, 4.07147789000004],
              [44.749658585000077, 4.077772140000093],
              [44.680955887000039, 4.411447525000085],
              [44.677564621000045, 4.431444168000041],
              [44.609581372000036, 4.831744426000057],
              [44.592083837000075, 4.93477374400004],
              [44.621108979000041, 4.933471781000037],
              [44.981742829000041, 4.917295058000093],
              [45.000000087000046, 4.936026541000047],
              [45.014419573000055, 4.950820415000067],
              [45.06234747700006, 5.000000965000083],
              [45.531912653000063, 5.48179646300008],
              [45.536031372000082, 5.486022458000093],
              [45.536192092000078, 5.486187366000081],
              [45.67097663900006, 5.191373825000085],
              [45.67282676800005, 5.18766975300008],
              [45.710977554000067, 5.111379623000062],
            ],
          ],
        ],
      },
    },
  ],
};
