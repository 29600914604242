export const galmudug = {
  type: "FeatureCollection",
  name: "galmudug",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        OBJECTID_1: 5,
        Name: "Galmudug Administration",
        Shape_Leng: 11.412535649900001,
        Shape_Area: 6.8720327479899996,
        "area-km2": 84297.566815300001,
        style: {
          color: "black",
          fill: false,
          opacity: 1,
          clickable: false,
        },
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [46.898634811000079, 6.88412268400009],
              [47.111009598000066, 6.731229782000071],
              [47.240026440000065, 6.872517538000068],
              [47.430611, 6.76924],
              [47.688884205000079, 6.603415526000049],
              [47.691019058000052, 6.611242295000068],
              [47.891023637000046, 6.771226883000054],
              [48.491037369000082, 6.741228104000072],
              [48.671037674000047, 6.631235123000067],
              [49.153100649000066, 6.601877142000092],
              [49.153053206000038, 6.601706352000065],
              [49.153031904000045, 6.601629665000075],
              [49.152671767000072, 6.600909407000074],
              [49.152266612000062, 6.599468889000093],
              [49.15195149200008, 6.598748628000067],
              [49.151456303000032, 6.597848300000067],
              [49.151096166000059, 6.596857938000085],
              [49.150736029000086, 6.596182690000091],
              [49.150420909000047, 6.595822558000066],
              [49.149970737000046, 6.595057275000045],
              [49.149835686000074, 6.594382024000083],
              [49.149475548000055, 6.593706773000065],
              [49.149205446000053, 6.59316657100004],
              [49.148890325000082, 6.592446301000052],
              [49.14803499900006, 6.590870706000089],
              [49.146594450000066, 6.587944588000084],
              [49.146144279000055, 6.587224311000057],
              [49.145739124000045, 6.58650403200005],
              [49.145469021000054, 6.585783752000054],
              [49.145153901000072, 6.58510848800006],
              [49.144793764000042, 6.584523259000093],
              [49.144300519000069, 6.583211794000078],
              [49.143705654000087, 6.582260009000038],
              [49.142944226000054, 6.581236841000077],
              [49.141827556000067, 6.578897023000081],
              [49.141611728000044, 6.577786623000065],
              [49.141710989000046, 6.57685704000005],
              [49.141706907000071, 6.575621313000056],
              [49.140255435000086, 6.569363331000091],
              [49.139922311000078, 6.568982617000074],
              [49.139979564000043, 6.568003231000091],
              [49.140136462000044, 6.567364583000085],
              [49.14004022000006, 6.566890309000087],
              [49.14004022000006, 6.566061712000078],
              [49.140015112000071, 6.565358660000072],
              [49.139990004000083, 6.56458027900004],
              [49.139964897000084, 6.563927443000068],
              [49.139964897000084, 6.563249496000083],
              [49.139889573000062, 6.562671986000055],
              [49.139779543000088, 6.561415931000056],
              [49.13926187900006, 6.560135953000042],
              [49.139327446000038, 6.559631335000063],
              [49.139375035000057, 6.55903647000008],
              [49.139186555000038, 6.558554064000077],
              [49.139184678000049, 6.558108480000044],
              [49.138803964000033, 6.557251874000087],
              [49.137931166000044, 6.555741805000082],
              [49.137328579000041, 6.554511437000087],
              [49.136650669000062, 6.553080188000081],
              [49.135996200000079, 6.551564962000043],
              [49.135043771000085, 6.549163065000073],
              [49.13434075300006, 6.547982900000079],
              [49.133402588000081, 6.545711488000052],
              [49.13221285700007, 6.543950687000063],
              [49.131142100000034, 6.542142297000055],
              [49.130423939000082, 6.540901852000047],
              [49.129857191000042, 6.539834219000056],
              [49.129381299000045, 6.539025203000051],
              [49.128405296000039, 6.537363147000065],
              [49.126525945000083, 6.533385880000083],
              [49.124550993000071, 6.528293834000067],
              [49.123385057000064, 6.524986383000055],
              [49.122695013000055, 6.522773484000083],
              [49.122790192000082, 6.521750316000066],
              [49.121933586000068, 6.519370855000091],
              [49.120040699000072, 6.515281652000056],
              [49.118816492000064, 6.511780374000068],
              [49.117745734000039, 6.509543681000082],
              [49.117031896000071, 6.508877432000077],
              [49.115723192000075, 6.50623623000007],
              [49.115885078000076, 6.505595281000069],
              [49.11529488900004, 6.503761591000057],
              [49.113795829000082, 6.501953200000059],
              [49.113475847000075, 6.500935429000037],
              [49.112677482000038, 6.499359588000061],
              [49.111870627000087, 6.496993148000058],
              [49.111620956000081, 6.496197172000052],
              [49.111643316000084, 6.496178415000088],
              [49.111718640000049, 6.495902176000072],
              [49.111718640000049, 6.495575713000051],
              [49.111593101000039, 6.495199024000044],
              [49.111517777000074, 6.494947898000078],
              [49.111417346000053, 6.494621434000067],
              [49.111241592000056, 6.494294970000055],
              [49.111040729000081, 6.493918280000059],
              [49.110845297000083, 6.493387141000085],
              [49.11048837800007, 6.492054642000085],
              [49.109679361000076, 6.489698976000057],
              [49.10913253800004, 6.48887060800007],
              [49.108856352000032, 6.488443688000075],
              [49.108680598000035, 6.488066994000064],
              [49.108303981000063, 6.487564735000092],
              [49.108052903000043, 6.487238266000077],
              [49.107902256000045, 6.487012249000088],
              [49.107726502000048, 6.486685779000084],
              [49.10713333800004, 6.485392152000088],
              [49.106514678000053, 6.483655145000057],
              [49.106300527000087, 6.483131664000041],
              [49.105919813000071, 6.482156085000042],
              [49.105642556000078, 6.481638034000071],
              [49.105567232000055, 6.481261335000056],
              [49.105491909000079, 6.480909748000045],
              [49.105372537000051, 6.480561846000057],
              [49.105229769000061, 6.480395284000053],
              [49.104989753000041, 6.479528514000037],
              [49.104839107000032, 6.478976019000072],
              [49.104763783000067, 6.478624431000071],
              [49.104663352000046, 6.478222616000039],
              [49.104537813000036, 6.477946367000072],
              [49.104412274000083, 6.477695233000077],
              [49.104186304000052, 6.477218076000042],
              [49.103968655000074, 6.476873681000086],
              [49.103587941000058, 6.475826718000064],
              [49.102825191000079, 6.474277500000085],
              [49.101279864000048, 6.470187396000085],
              [49.101303659000052, 6.469878066000092],
              [49.10109804700005, 6.469382600000074],
              [49.100872077000076, 6.469005891000052],
              [49.100771646000055, 6.468855207000047],
              [49.100746538000067, 6.468604068000047],
              [49.100696322000033, 6.468302700000038],
              [49.100646107000046, 6.468101789000059],
              [49.100595891000069, 6.467825535000088],
              [49.10057078300008, 6.467574395000042],
              [49.100470352000059, 6.467373483000074],
              [49.100344813000049, 6.467247913000051],
              [49.100294598000062, 6.467197685000087],
              [49.10021927400004, 6.46697165900008],
              [49.100143951000064, 6.466594948000079],
              [49.100043520000042, 6.466268466000088],
              [49.099867765000056, 6.466042439000091],
              [49.099742227000036, 6.465891755000087],
              [49.099542858000063, 6.465428474000078],
              [49.099174806000065, 6.464421193000078],
              [49.099214963000065, 6.464334679000046],
              [49.099214963000065, 6.464008195000076],
              [49.099214963000065, 6.463681711000049],
              [49.099164748000078, 6.463304998000069],
              [49.099039209000068, 6.463104084000065],
              [49.098863454000082, 6.462752485000067],
              [49.098788131000049, 6.462576685000045],
              [49.098537053000086, 6.462300428000049],
              [49.09798468200006, 6.461295857000039],
              [49.097960235000073, 6.460929064000084],
              [49.097864790000074, 6.460657343000094],
              [49.096892163000064, 6.457349534000059],
              [49.096330585000032, 6.455625094000084],
              [49.096001167000054, 6.455343730000038],
              [49.095373472000063, 6.453686162000054],
              [49.094595131000062, 6.451425835000066],
              [49.094243622000079, 6.449416646000088],
              [49.093264418000047, 6.446277273000078],
              [49.09253629300008, 6.443765760000076],
              [49.091958813000076, 6.442057924000039],
              [49.091356227000063, 6.440601236000077],
              [49.090961968000045, 6.439556133000053],
              [49.090959135000048, 6.439545365000072],
              [49.089208405000079, 6.434583434000047],
              [49.087554938000039, 6.428162039000085],
              [49.086455822000062, 6.424382771000069],
              [49.086295117000077, 6.423448479000058],
              [49.08611936300008, 6.422468946000038],
              [49.085860957000079, 6.421384651000039],
              [49.085385065000082, 6.419814206000069],
              [49.085104388000047, 6.419152268000062],
              [49.085048874000051, 6.418985668000062],
              [49.084937611000043, 6.418184293000081],
              [49.084903526000062, 6.418122493000055],
              [49.08440137000008, 6.416288987000087],
              [49.084200508000038, 6.415058274000046],
              [49.084117623000054, 6.414791106000052],
              [49.083761691000063, 6.414346033000072],
              [49.083027386000083, 6.411274208000066],
              [49.083070658000054, 6.411014480000063],
              [49.083045550000065, 6.410411676000081],
              [49.082894903000067, 6.409884222000073],
              [49.082794472000046, 6.40933165000007],
              [49.082668933000036, 6.408452557000089],
              [49.082242101000077, 6.406568782000079],
              [49.081911051000077, 6.404990164000083],
              [49.08116246600008, 6.402248630000088],
              [49.080864089000045, 6.400017091000052],
              [49.080649937000032, 6.398803566000083],
              [49.080507169000043, 6.398446646000082],
              [49.079871180000055, 6.392842902000041],
              [49.080245429000058, 6.391950718000089],
              [49.07926985000006, 6.387786661000064],
              [49.079093078000085, 6.383501890000048],
              [49.078770163000058, 6.378292612000052],
              [49.078175298000076, 6.374652036000043],
              [49.078470619000086, 6.373533153000039],
              [49.078413244000046, 6.37248672700008],
              [49.078365655000084, 6.370511774000079],
              [49.076819005000061, 6.365514906000044],
              [49.076390702000083, 6.362992678000069],
              [49.075891015000082, 6.359613843000091],
              [49.075200971000072, 6.357234382000058],
              [49.074985951000087, 6.356332178000059],
              [49.074609335000048, 6.353970935000063],
              [49.074383365000074, 6.351710160000039],
              [49.074182502000042, 6.350630009000042],
              [49.074146880000058, 6.350501705000056],
              [49.074132678000069, 6.349833895000074],
              [49.073963652000032, 6.348430376000067],
              [49.073606733000076, 6.347240646000046],
              [49.073429269000087, 6.346585700000048],
              [49.073344992000045, 6.344052168000076],
              [49.073797089000038, 6.34283864300005],
              [49.074180969000054, 6.341105696000056],
              [49.074424466000039, 6.337864677000084],
              [49.074344365000059, 6.335533698000063],
              [49.074534722000067, 6.333606334000081],
              [49.074534722000067, 6.331417230000056],
              [49.074725079000075, 6.328300136000053],
              [49.075430186000062, 6.327661741000043],
              [49.075748247000035, 6.326182416000051],
              [49.076009988000067, 6.325516167000046],
              [49.076128961000052, 6.324588177000066],
              [49.076414497000087, 6.324088490000065],
              [49.076676237000072, 6.323208089000047],
              [49.076516985000069, 6.322055419000037],
              [49.076819005000061, 6.320900012000038],
              [49.076854525000044, 6.319331060000081],
              [49.076959819000081, 6.318804304000082],
              [49.077117760000078, 6.318145858000094],
              [49.077604227000052, 6.316902518000063],
              [49.077509049000071, 6.316164885000092],
              [49.077670554000065, 6.315196011000069],
              [49.078175298000076, 6.314879976000043],
              [49.078246682000042, 6.313761629000055],
              [49.078556011000046, 6.312452926000049],
              [49.078793958000063, 6.311191811000072],
              [49.078907761000039, 6.308980206000058],
              [49.079065702000037, 6.306873136000092],
              [49.079118349000055, 6.304529011000056],
              [49.079065702000037, 6.302579954000066],
              [49.078984052000067, 6.302348481000081],
              [49.078995815000042, 6.302101313000037],
              [49.078817752000077, 6.297914419000051],
              [49.078120451000075, 6.294607720000045],
              [49.077033156000084, 6.288610726000059],
              [49.074789722000048, 6.280104675000075],
              [49.072844467000039, 6.272318786000085],
              [49.070323076000079, 6.263769154000045],
              [49.067810598000051, 6.255872859000078],
              [49.065802100000042, 6.248683371000084],
              [49.064503665000075, 6.244388094000044],
              [49.063065880000067, 6.240441709000038],
              [49.061099468000066, 6.235044368000047],
              [49.05905695000007, 6.229301786000065],
              [49.055879748000052, 6.220843677000062],
              [49.053221185000041, 6.214409709000051],
              [49.052929216000052, 6.213276991000043],
              [49.052262967000047, 6.21149239500005],
              [49.050109811000084, 6.206751034000092],
              [49.047480251000081, 6.200499285000092],
              [49.044767665000052, 6.194622017000086],
              [49.042864096000073, 6.190172425000071],
              [49.038631771000041, 6.181704043000082],
              [49.036677498000074, 6.17787061100006],
              [49.032115167000086, 6.168806156000073],
              [49.028278, 6.159524967000038],
              [49.027849697000079, 6.158739745000048],
              [49.02577956600004, 6.153790466000089],
              [49.024304301000086, 6.149721588000091],
              [49.023736125000084, 6.148458921000042],
              [49.022683059000087, 6.145976197000039],
              [49.021972429000073, 6.144772309000075],
              [49.019500458000039, 6.138434265000058],
              [49.018664978000061, 6.136682141000051],
              [49.017855961000066, 6.135159286000089],
              [49.017394326000044, 6.134054273000061],
              [49.01723730100008, 6.133612637000056],
              [49.016166544000043, 6.131328354000061],
              [49.014976813000033, 6.128639563000092],
              [49.01359672600006, 6.125950772000067],
              [49.010931730000038, 6.120382833000065],
              [49.008552269000063, 6.114862484000071],
              [49.00747231400004, 6.112870543000042],
              [49.007100797000078, 6.112030925000056],
              [49.005363791000036, 6.108223788000089],
              [49.003811705000032, 6.104360640000039],
              [49.002056340000081, 6.100133620000065],
              [48.999143092000054, 6.093408013000044],
              [48.995013136000068, 6.083834312000079],
              [48.992086399000073, 6.077029054000093],
              [48.991557194000052, 6.07572186200008],
              [48.989828037000052, 6.072057492000056],
              [48.98771257900006, 6.067325078000067],
              [48.984953286000064, 6.061929706000058],
              [48.984543507000069, 6.060943897000072],
              [48.983782080000083, 6.059278275000054],
              [48.982901679000065, 6.05725573300009],
              [48.982211635000056, 6.055804262000038],
              [48.981809671000065, 6.054482654000083],
              [48.980220688000088, 6.05123937500008],
              [48.978985597000076, 6.04901385900007],
              [48.978042965000043, 6.047441139000057],
              [48.977888591000067, 6.047119185000042],
              [48.97693580300006, 6.045132094000053],
              [48.975739501000078, 6.04319311800009],
              [48.975005859000078, 6.041815216000089],
              [48.974402037000061, 6.040551322000056],
              [48.971575050000069, 6.034863762000043],
              [48.969159761000071, 6.03008286700009],
              [48.967512973000055, 6.026703244000089],
              [48.966113203000077, 6.023928090000084],
              [48.965269873000068, 6.022634575000041],
              [48.964490149000085, 6.020968636000077],
              [48.964397371000075, 6.020738290000054],
              [48.963061247000041, 6.018389311000078],
              [48.960030528000061, 6.012092692000067],
              [48.957325693000087, 6.006676888000072],
              [48.956022157000064, 6.004034217000083],
              [48.954034266000065, 5.999597110000082],
              [48.953382499000043, 5.99835732400004],
              [48.952665555000067, 5.997150161000093],
              [48.952274494000051, 5.996236630000055],
              [48.950286603000052, 5.991995217000067],
              [48.94790798400004, 5.987010143000077],
              [48.946375998000065, 5.984066949000066],
              [48.945935359000032, 5.98342217700008],
              [48.945843102000083, 5.983187710000038],
              [48.943411533000074, 5.978903071000047],
              [48.940201862000038, 5.972670809000078],
              [48.937089454000045, 5.967022759000088],
              [48.93556374800005, 5.964241817000072],
              [48.935417102000088, 5.963893109000082],
              [48.933558002000041, 5.960252728000057],
              [48.932077303000085, 5.957584198000063],
              [48.930925648000084, 5.955179215000044],
              [48.92962592300006, 5.95274127600004],
              [48.928655242000048, 5.950986942000043],
              [48.926713881000069, 5.947231165000062],
              [48.924558641000033, 5.943392997000046],
              [48.923127298000054, 5.94077380300007],
              [48.920741727000063, 5.936260194000056],
              [48.918438417000061, 5.93201011900004],
              [48.916859004000059, 5.929176717000075],
              [48.914967, 5.925849098000072],
              [48.913502753000046, 5.923312187000079],
              [48.912170123000067, 5.920931762000066],
              [48.910755233000089, 5.918707827000048],
              [48.909636482000053, 5.917126357000086],
              [48.908543523000048, 5.915201911000054],
              [48.906329587000073, 5.911558227000057],
              [48.902858170000059, 5.905866484000057],
              [48.901295209000068, 5.903280058000064],
              [48.901147140000035, 5.90295057600008],
              [48.900966165000057, 5.902752887000076],
              [48.900883904000068, 5.902703464000069],
              [48.900828085000057, 5.902688756000089],
              [48.900810446000037, 5.902650313000038],
              [48.897812153000075, 5.897736667000061],
              [48.894552292000071, 5.892406674000085],
              [48.892410777000066, 5.88917060700004],
              [48.889460245000066, 5.884506864000059],
              [48.887290922000034, 5.881077515000072],
              [48.884035074000053, 5.87591701000008],
              [48.881274899000061, 5.871824337000078],
              [48.87809229700008, 5.866909538000073],
              [48.87611146900008, 5.863948321000066],
              [48.874374463000038, 5.861140557000056],
              [48.869589101000088, 5.854682555000068],
              [48.864499699000078, 5.846673434000081],
              [48.862391657000046, 5.843383956000082],
              [48.858987460000037, 5.838416396000071],
              [48.855194213000061, 5.83237734100004],
              [48.850914652000085, 5.826143409000053],
              [48.84867322100007, 5.822475186000077],
              [48.845588012000064, 5.817871729000046],
              [48.841871025000046, 5.812052276000088],
              [48.838743890000046, 5.807128851000073],
              [48.837683174000063, 5.805318402000069],
              [48.837183487000061, 5.804533179000089],
              [48.83506576700006, 5.801558853000074],
              [48.834851615000048, 5.801130550000039],
              [48.834113982000076, 5.799560106000058],
              [48.832733895000047, 5.79775171600005],
              [48.830592380000041, 5.794468059000053],
              [48.829926131000036, 5.79308797200008],
              [48.82837948100007, 5.790803689000086],
              [48.82552412800004, 5.785854411000059],
              [48.822383240000079, 5.780643391000069],
              [48.82045587600004, 5.777169378000053],
              [48.818790253000088, 5.775075452000067],
              [48.814840348000075, 5.769269567000038],
              [48.811009416000047, 5.763178147000076],
              [48.809795891000078, 5.761226989000079],
              [48.807939911000062, 5.758205074000045],
              [48.804965585000048, 5.753850660000069],
              [48.80477522800004, 5.75356512500008],
              [48.804227145000084, 5.752719260000049],
              [48.803680676000056, 5.751685351000049],
              [48.803323757000044, 5.751138074000039],
              [48.802878063000037, 5.750346340000078],
              [48.802746445000082, 5.749654237000072],
              [48.802318688000071, 5.749093962000074],
              [48.801627695000036, 5.748072284000045],
              [48.800640562000069, 5.746655113000088],
              [48.799785047000057, 5.745369769000092],
              [48.799126958000045, 5.744611745000043],
              [48.798896627000033, 5.744018507000078],
              [48.798612424000055, 5.742690988000049],
              [48.797646259000032, 5.741447805000064],
              [48.794805287000088, 5.737479968000059],
              [48.790760203000048, 5.731650289000072],
              [48.788471962000074, 5.728193330000067],
              [48.78607396600006, 5.725087362000068],
              [48.785340002000055, 5.723848064000038],
              [48.784056730000088, 5.722167161000073],
              [48.781385126000032, 5.717920799000069],
              [48.779022352000084, 5.714158085000065],
              [48.776060954000059, 5.710301822000076],
              [48.773296982000033, 5.706082975000072],
              [48.771177239000053, 5.702977784000041],
              [48.769315546000087, 5.700479770000072],
              [48.768369475000043, 5.698932700000057],
              [48.766465906000064, 5.696005963000061],
              [48.765252381000039, 5.694173778000049],
              [48.764609926000048, 5.69310302100007],
              [48.763800910000043, 5.691675344000089],
              [48.763158455000053, 5.691675344000089],
              [48.762845903000084, 5.691218972000058],
              [48.762750618000041, 5.690709917000049],
              [48.762528287000066, 5.690328125000065],
              [48.762115387000051, 5.689659989000063],
              [48.761802162000038, 5.688701018000074],
              [48.761278681000078, 5.688082358000088],
              [48.760660021000035, 5.687558876000082],
              [48.760255513000061, 5.687273341000093],
              [48.759851004000041, 5.686845038000058],
              [48.759034513000074, 5.686192033000054],
              [48.758431043000087, 5.685173913000085],
              [48.757922858000086, 5.684346690000041],
              [48.757541719000073, 5.683042219000072],
              [48.757224103000055, 5.682214993000059],
              [48.756938249000086, 5.681387765000068],
              [48.756811203000041, 5.681133233000082],
              [48.756620633000068, 5.680910518000076],
              [48.755127839000068, 5.679224240000053],
              [48.754365561000043, 5.677856125000062],
              [48.75332835200004, 5.676322252000091],
              [48.752693858000043, 5.67505106100009],
              [48.750095214000055, 5.672044791000076],
              [48.747501602000057, 5.668975286000091],
              [48.745676349000064, 5.664864957000077],
              [48.742272153000044, 5.659700948000079],
              [48.739815943000053, 5.655721688000085],
              [48.736627465000083, 5.651081739000062],
              [48.732941401000062, 5.645687851000048],
              [48.73227305100005, 5.645085497000082],
              [48.730773991000035, 5.642896393000058],
              [48.729146865000075, 5.640056660000084],
              [48.728207339000051, 5.639045726000063],
              [48.727561718000061, 5.637780552000038],
              [48.726943059000064, 5.636091135000072],
              [48.726300604000073, 5.635091761000069],
              [48.724706365000088, 5.633545112000093],
              [48.723944938000045, 5.632022257000074],
              [48.724135295000053, 5.631356007000079],
              [48.723683873000084, 5.630988234000085],
              [48.723112165000032, 5.629938197000058],
              [48.72209579400004, 5.628506324000057],
              [48.720888854000066, 5.62646987800008],
              [48.720094815000039, 5.625069816000064],
              [48.719396060000065, 5.623606112000061],
              [48.718729067000083, 5.622046945000079],
              [48.718252643000085, 5.621251450000045],
              [48.718125597000039, 5.620774152000081],
              [48.717829723000079, 5.620386692000068],
              [48.717204511000034, 5.61969227600008],
              [48.716711376000035, 5.619292140000084],
              [48.716427887000066, 5.618344783000055],
              [48.716029332000062, 5.618037638000089],
              [48.715838763000079, 5.617814898000063],
              [48.715552909000053, 5.617401237000081],
              [48.715425862000075, 5.617083037000043],
              [48.715267054000037, 5.616987577000089],
              [48.712120699000081, 5.611809848000064],
              [48.709144690000073, 5.607133094000062],
              [48.706170364000059, 5.601993459000084],
              [48.705789650000042, 5.601493772000083],
              [48.705218580000064, 5.600851317000092],
              [48.703506688000061, 5.598063248000074],
              [48.702980217000061, 5.597074264000071],
              [48.702881504000061, 5.596546806000049],
              [48.702743940000062, 5.59566409200005],
              [48.701363853000032, 5.593260837000059],
              [48.700974343000041, 5.592644713000084],
              [48.700483452000071, 5.59188074900004],
              [48.700102738000055, 5.591357268000081],
              [48.699894449000055, 5.590958176000072],
              [48.699926211000047, 5.590544497000053],
              [48.699799164000069, 5.590258103000053],
              [48.69919854300008, 5.589715440000077],
              [48.697336760000042, 5.587643546000038],
              [48.694986897000035, 5.584504420000087],
              [48.694915513000069, 5.584147501000075],
              [48.695177254000043, 5.583505047000074],
              [48.694971404000057, 5.583002746000091],
              [48.694558503000053, 5.582493595000074],
              [48.694240888000081, 5.582048087000089],
              [48.693955033000066, 5.581729867000092],
              [48.693678194000086, 5.581101791000037],
              [48.69339265800005, 5.580673488000059],
              [48.692906901000072, 5.579947832000073],
              [48.692494001000057, 5.579279568000061],
              [48.692271670000082, 5.578738591000047],
              [48.692081100000053, 5.578261258000055],
              [48.691858769000078, 5.577911214000039],
              [48.691668200000038, 5.577497525000069],
              [48.691382345000079, 5.577083835000053],
              [48.691064730000051, 5.57679743500006],
              [48.690683591000038, 5.576447390000055],
              [48.69055654400006, 5.576224634000084],
              [48.690111882000053, 5.575524542000039],
              [48.689680699000064, 5.574891398000091],
              [48.689323780000052, 5.574415506000094],
              [48.688492041000075, 5.573360619000084],
              [48.68823794900004, 5.573010571000054],
              [48.687888571000087, 5.572533234000048],
              [48.687698002000047, 5.572055896000052],
              [48.687316863000035, 5.571642203000067],
              [48.686999247000074, 5.571228509000093],
              [48.68674515400005, 5.571069396000041],
              [48.686444632000075, 5.570560779000061],
              [48.685728784000048, 5.569669200000078],
              [48.684775936000051, 5.567982596000093],
              [48.684108943000069, 5.567091558000072],
              [48.683803431000058, 5.566444311000055],
              [48.683327538000071, 5.565778062000049],
              [48.682399549000081, 5.56430279600005],
              [48.679634938000049, 5.559724363000043],
              [48.677592420000053, 5.556605907000062],
              [48.675142193000056, 5.552048572000047],
              [48.674000051000064, 5.549002862000066],
              [48.674142819000053, 5.548122461000048],
              [48.675047014000086, 5.547408623000081],
              [48.67530875500006, 5.547361034000062],
              [48.674642506000055, 5.546409250000067],
              [48.674333176000061, 5.546099920000074],
              [48.674000051000064, 5.545386081000061],
              [48.673857284000064, 5.545029162000048],
              [48.673500364000063, 5.544362913000043],
              [48.673027886000057, 5.543761210000071],
              [48.672453402000087, 5.542602112000054],
              [48.670169119000036, 5.538557028000071],
              [48.667956220000065, 5.534702301000038],
              [48.665629101000036, 5.530488178000041],
              [48.664767743000084, 5.52915815700004],
              [48.663911137000071, 5.527397356000051],
              [48.663411450000069, 5.527016642000092],
              [48.662745201000064, 5.525779323000052],
              [48.661626854000076, 5.524351646000071],
              [48.660484713000073, 5.522352899000055],
              [48.659532928000033, 5.520925222000074],
              [48.658271814000045, 5.518950270000062],
              [48.657867306000071, 5.51790330700004],
              [48.657391413000084, 5.517022906000079],
              [48.657010700000058, 5.516523219000078],
              [48.65667757500006, 5.515952149000043],
              [48.656463424000037, 5.51540487300008],
              [48.656106504000036, 5.51495277500004],
              [48.655773380000085, 5.514667240000051],
              [48.654431379000073, 5.511839651000059],
              [48.652057205000062, 5.508333778000065],
              [48.651585529000045, 5.507314705000056],
              [48.650562360000038, 5.505292163000092],
              [48.648901177000084, 5.502758639000092],
              [48.647540445000061, 5.500461858000051],
              [48.646090331000039, 5.498573012000065],
              [48.645931523000058, 5.498318400000073],
              [48.645351341000037, 5.497392353000066],
              [48.640283089000036, 5.490372943000068],
              [48.636594924000065, 5.484733620000043],
              [48.635620631000052, 5.483433537000053],
              [48.634751446000053, 5.482341249000058],
              [48.634497354000075, 5.481863837000049],
              [48.633449221000035, 5.480304290000049],
              [48.63322689000006, 5.479986014000076],
              [48.633131606000063, 5.479858704000037],
              [48.632877513000039, 5.479795049000074],
              [48.63259165900007, 5.479731394000055],
              [48.632337566000047, 5.479508601000077],
              [48.63208347300008, 5.479190325000047],
              [48.632051712000077, 5.47890387700005],
              [48.632178758000066, 5.478617428000064],
              [48.632274043000052, 5.478299152000091],
              [48.632248744000037, 5.477944228000069],
              [48.631835967000086, 5.477312494000046],
              [48.631765858000051, 5.477312494000046],
              [48.631352957000047, 5.477248838000094],
              [48.630971818000035, 5.476898734000088],
              [48.630654202000073, 5.476516801000059],
              [48.630400110000039, 5.476230351000083],
              [48.63011425500008, 5.475975729000083],
              [48.629987209000035, 5.475625623000042],
              [48.629796639000062, 5.475275518000046],
              [48.629510785000036, 5.474766272000068],
              [48.629193169000075, 5.474320683000087],
              [48.628980649000084, 5.473883278000073],
              [48.628718908000053, 5.473550154000066],
              [48.628462653000042, 5.473111223000046],
              [48.628462653000042, 5.472633804000054],
              [48.628560683000046, 5.47229980700007],
              [48.628085677000058, 5.471572827000045],
              [48.627861261000078, 5.471272979000048],
              [48.627605090000088, 5.471265200000062],
              [48.627350998000054, 5.471137888000044],
              [48.626125296000055, 5.469362302000093],
              [48.625421402000086, 5.468942362000064],
              [48.625167309000062, 5.46849676800008],
              [48.624911771000086, 5.468006009000078],
              [48.624650030000055, 5.467553912000085],
              [48.624412084000085, 5.467006636000065],
              [48.624047793000045, 5.466777432000072],
              [48.62384101300006, 5.466292797000051],
              [48.623484094000048, 5.465745521000088],
              [48.623246148000078, 5.465459986000042],
              [48.622682045000033, 5.46528150100005],
              [48.622396191000064, 5.464867733000062],
              [48.622269145000075, 5.464517620000038],
              [48.62214209800004, 5.464199336000092],
              [48.621913650000067, 5.463580212000068],
              [48.621199812000043, 5.462818784000092],
              [48.620961865000083, 5.462580838000065],
              [48.620700125000042, 5.461985973000083],
              [48.620390795000048, 5.461319724000077],
              [48.619891108000047, 5.460796243000061],
              [48.619224859000042, 5.46043932300006],
              [48.619343832000084, 5.45974928000004],
              [48.618986913000072, 5.45882129000006],
              [48.616559863000077, 5.45656080200007],
              [48.616157692000058, 5.456623406000062],
              [48.615750846000083, 5.456370445000061],
              [48.615441516000033, 5.456084910000072],
              [48.615179775000058, 5.455823169000041],
              [48.614773412000034, 5.455637434000039],
              [48.614582842000061, 5.455382803000077],
              [48.614290203000053, 5.454972240000075],
              [48.613466563000088, 5.454181341000037],
              [48.612514779000037, 5.453348530000085],
              [48.611967503000074, 5.452706075000037],
              [48.611705762000042, 5.452396745000044],
              [48.611586789000057, 5.452063621000093],
              [48.611348843000087, 5.451682907000077],
              [48.611120830000061, 5.451213205000045],
              [48.611089068000069, 5.450831255000082],
              [48.611134691000075, 5.450350409000066],
              [48.609944961000053, 5.448970321000047],
              [48.609707015000083, 5.449327240000059],
              [48.609112150000044, 5.449232062000078],
              [48.608374517000073, 5.448161305000042],
              [48.607589295000082, 5.447399877000066],
              [48.606161618000044, 5.446210147000045],
              [48.605197624000084, 5.444964367000068],
              [48.605181414000072, 5.444688203000055],
              [48.605376396000054, 5.444211399000039],
              [48.605114655000079, 5.44368791800008],
              [48.604829120000034, 5.443188231000079],
              [48.604101520000086, 5.442619284000045],
              [48.603758362000065, 5.441998501000057],
              [48.602797361000057, 5.440918922000037],
              [48.601545464000083, 5.438691050000045],
              [48.600998188000062, 5.437691676000043],
              [48.599808457000051, 5.435978464000073],
              [48.599451538000039, 5.435550161000037],
              [48.599023235000061, 5.434931501000051],
              [48.598713905000068, 5.434408020000092],
              [48.598166629000048, 5.43359900300004],
              [48.597785915000088, 5.433123111000043],
              [48.59724101900008, 5.432592887000055],
              [48.596959370000036, 5.43228240500008],
              [48.596738952000067, 5.43188579100007],
              [48.596405828000059, 5.431409899000073],
              [48.595891705000042, 5.430976774000044],
              [48.593113441000071, 5.427571129000057],
              [48.58956962600007, 5.423373843000093],
              [48.587054546000047, 5.41994089700006],
              [48.583271203000038, 5.41551510000005],
              [48.579551562000063, 5.411481885000057],
              [48.576775275000045, 5.407662878000053],
              [48.573896127000069, 5.404284044000065],
              [48.572308178000071, 5.40270367100004],
              [48.571778406000078, 5.40199976100007],
              [48.570279346000063, 5.40038172800007],
              [48.569755865000047, 5.39973927300008],
              [48.56913202000004, 5.399106679000056],
              [48.568877927000074, 5.398692864000054],
              [48.568465026000069, 5.398119889000043],
              [48.568115649000049, 5.397419586000069],
              [48.567798033000088, 5.397069434000059],
              [48.567512179000062, 5.396814778000078],
              [48.566876947000083, 5.395700657000077],
              [48.566654616000051, 5.395509664000087],
              [48.566432285000076, 5.395318672000087],
              [48.56614643100005, 5.395000351000078],
              [48.56586057700008, 5.394586533000052],
              [48.565606484000057, 5.394077219000053],
              [48.565320630000087, 5.393790729000045],
              [48.56516182200005, 5.393567904000065],
              [48.56475899600008, 5.393029193000075],
              [48.564045158000056, 5.392124998000043],
              [48.563637266000057, 5.391530641000088],
              [48.563131437000038, 5.390543413000046],
              [48.560488062000047, 5.387599778000038],
              [48.557375654000055, 5.383993009000051],
              [48.553361378000034, 5.379323498000076],
              [48.549400108000043, 5.374537325000063],
              [48.544747729000051, 5.368687307000073],
              [48.542883503000041, 5.366056326000091],
              [48.540060191000066, 5.362500708000084],
              [48.536966892000066, 5.358193884000059],
              [48.536253054000042, 5.357146921000037],
              [48.535919929000045, 5.356742413000063],
              [48.535634394000056, 5.356242726000062],
              [48.535348858000077, 5.355695450000042],
              [48.534825377000061, 5.354791255000066],
              [48.534392498000045, 5.354301784000086],
              [48.534233690000065, 5.353856104000045],
              [48.534016360000066, 5.35333978400007],
              [48.533813141000053, 5.352853161000041],
              [48.533559049000075, 5.35122960700005],
              [48.533589946000063, 5.35103605900008],
              [48.533545884000034, 5.350896210000087],
              [48.53362257200007, 5.350752091000061],
              [48.53362257200007, 5.350401912000052],
              [48.533590810000078, 5.35008356700007],
              [48.533754620000082, 5.349699208000061],
              [48.533730825000077, 5.349437467000087],
              [48.533445290000088, 5.349080548000074],
              [48.533050863000085, 5.348714682000093],
              [48.532987340000034, 5.348332668000069],
              [48.532765009000059, 5.348173495000083],
              [48.532588684000075, 5.347748050000064],
              [48.532326943000044, 5.347391131000052],
              [48.532279354000082, 5.346724882000046],
              [48.532326943000044, 5.345892071000037],
              [48.53201761300005, 5.345321],
              [48.531589310000072, 5.344892697000091],
              [48.530922837000048, 5.343875810000043],
              [48.530827553000051, 5.343525627000076],
              [48.530764030000057, 5.343048105000094],
              [48.530891076000046, 5.342347737000068],
              [48.531716877000065, 5.341169845000081],
              [48.532517300000052, 5.341513862000056],
              [48.531565515000068, 5.339967213000079],
              [48.531375159000049, 5.340252748000069],
              [48.53030440100008, 5.339300964000074],
              [48.529542974000037, 5.338753688000054],
              [48.52882913500008, 5.337706725000089],
              [48.529138465000074, 5.336826324000072],
              [48.527972529000067, 5.334565836000081],
              [48.524209054000039, 5.330571315000043],
              [48.521291171000087, 5.326671737000083],
              [48.520043627000064, 5.32498361800009],
              [48.519854689000056, 5.324550751000061],
              [48.519250100000079, 5.323804902000063],
              [48.518924553000033, 5.323385362000067],
              [48.518599005000056, 5.322965821000082],
              [48.518412978000072, 5.322686127000054],
              [48.517808389000038, 5.322080123000092],
              [48.516878253000073, 5.321147808000092],
              [48.515855103000035, 5.320215491000056],
              [48.514290629000072, 5.319194518000074],
              [48.513743353000052, 5.318266528000038],
              [48.512897015000078, 5.31718406300007],
              [48.510878873000081, 5.315274189000093],
              [48.509055814000078, 5.313507606000087],
              [48.508088464000082, 5.312570440000059],
              [48.507604343000082, 5.31219890300008],
              [48.506485996000038, 5.31110435100004],
              [48.50590264300007, 5.310752396000055],
              [48.505158534000088, 5.309913297000094],
              [48.504460932000086, 5.309260663000089],
              [48.503205248000086, 5.308095244000071],
              [48.501624016000051, 5.306323803000055],
              [48.500647373000049, 5.305018528000062],
              [48.500085246000083, 5.304156325000065],
              [48.499918684000079, 5.303894584000091],
              [48.499537970000063, 5.303323513000066],
              [48.499181051000051, 5.303061772000092],
              [48.498276856000075, 5.301443739000092],
              [48.497681991000036, 5.299944679000077],
              [48.497800964000078, 5.299492581000038],
              [48.497824758000036, 5.299135662000083],
              [48.497824758000036, 5.298945305000075],
              [48.497824758000036, 5.298588386000063],
              [48.497586812000065, 5.298255261000065],
              [48.497206099000039, 5.297898342000053],
              [48.496780957000055, 5.297229109000057],
              [48.496682617000033, 5.296161336000068],
              [48.496968152000079, 5.29573303300009],
              [48.496754001000056, 5.295376113000088],
              [48.496477806000087, 5.295539545000054],
              [48.49630423800005, 5.295539545000054],
              [48.496058349000066, 5.295510548000038],
              [48.495826925000074, 5.29542355600006],
              [48.495682285000044, 5.295307568000055],
              [48.495421932000056, 5.295162583000092],
              [48.495132652000052, 5.294988600000067],
              [48.494713195000088, 5.294785621000074],
              [48.494685062000087, 5.294762844000047],
              [48.494326951000062, 5.293924642000093],
              [48.493919559000062, 5.293736378000062],
              [48.492899274000081, 5.292330403000051],
              [48.492500199000062, 5.291668425000069],
              [48.492413415000044, 5.291494442000044],
              [48.492153062000057, 5.291407450000065],
              [48.491863782000053, 5.291262464000056],
              [48.491511216000049, 5.291035272000045],
              [48.491138473000035, 5.290331656000092],
              [48.490822372000082, 5.289841596000088],
              [48.490735588000064, 5.289493628000059],
              [48.490793444000076, 5.289319644000045],
              [48.491053797000063, 5.289174657000046],
              [48.490909156000043, 5.28885568600009],
              [48.490735588000064, 5.288652705000061],
              [48.489591823000069, 5.287452508000058],
              [48.489020753000034, 5.286738670000091],
              [48.488663834000079, 5.286262778000093],
              [48.488330709000081, 5.285953448000043],
              [48.487949995000065, 5.285596529000088],
              [48.487712049000038, 5.285073047000083],
              [48.487188568000079, 5.284240236000073],
              [48.485784686000045, 5.282289078000076],
              [48.484313561000079, 5.280794363000041],
              [48.483358696000039, 5.279777344000081],
              [48.482358262000048, 5.278006048000066],
              [48.480502282000089, 5.275578998000071],
              [48.477337599000066, 5.272271547000059],
              [48.475695771000062, 5.269939675000046],
              [48.474196711000047, 5.268036106000068],
              [48.470325487000082, 5.263153831000068],
              [48.469155262000072, 5.261771539000051],
              [48.468894910000074, 5.261336559000085],
              [48.468663485000036, 5.260988575000056],
              [48.468374205000032, 5.260582593000038],
              [48.467748371000084, 5.259826966000048],
              [48.466927802000043, 5.258813669000062],
              [48.465799608000054, 5.25765371600005],
              [48.463716789000046, 5.255072813000083],
              [48.460997552000038, 5.251708922000091],
              [48.459927214000061, 5.250432959000079],
              [48.459681332000059, 5.250268635000054],
              [48.45818293800005, 5.248476937000078],
              [48.455470352000077, 5.244883951000077],
              [48.453135172000088, 5.241721971000061],
              [48.452368322000041, 5.240783440000087],
              [48.448427148000064, 5.236008561000062],
              [48.444064543000081, 5.231270527000049],
              [48.439075866000053, 5.225300987000082],
              [48.434840426000051, 5.219637870000042],
              [48.430155969000054, 5.214109413000074],
              [48.426869231000069, 5.209763106000082],
              [48.424013878000039, 5.206170120000081],
              [48.421824774000072, 5.203457535000041],
              [48.42053986500008, 5.201649144000044],
              [48.419373929000074, 5.20000731600004],
              [48.418574684000077, 5.198768801000085],
              [48.417636922000042, 5.197199552000086],
              [48.416304424000089, 5.195819465000056],
              [48.41394875800006, 5.191917149000062],
              [48.411497913000062, 5.187753092000094],
              [48.410831664000057, 5.187039254000069],
              [48.40887667800007, 5.184119811000073],
              [48.40830797700005, 5.183635174000074],
              [48.407399842000075, 5.18262715700007],
              [48.407075508000048, 5.182269473000076],
              [48.40681604100007, 5.181781722000039],
              [48.406751174000078, 5.181098870000085],
              [48.406556574000035, 5.180806218000043],
              [48.406199807000064, 5.180513567000048],
              [48.405713306000052, 5.179960781000091],
              [48.405324105000034, 5.179407994000087],
              [48.405161938000049, 5.178985275000059],
              [48.404578137000044, 5.178367453000078],
              [48.404318670000066, 5.178074801000093],
              [48.404221370000073, 5.177782148000063],
              [48.40412407000008, 5.177359427000056],
              [48.404026770000087, 5.177001741000083],
              [48.404026406000071, 5.176522036000051],
              [48.403455335000046, 5.17564163600008],
              [48.401813507000043, 5.174547084000039],
              [48.401140198000064, 5.173815066000088],
              [48.400718564000044, 5.173002136000093],
              [48.400167196000041, 5.172254240000086],
              [48.399680695000086, 5.171473825000078],
              [48.399291495000057, 5.170855997000047],
              [48.398981948000085, 5.170335438000052],
              [48.398767797000062, 5.169407448000072],
              [48.399127882000073, 5.168328853000048],
              [48.397864734000052, 5.167450234000057],
              [48.396745255000042, 5.166314148000083],
              [48.395841060000066, 5.165076829000043],
              [48.393585173000076, 5.162086886000054],
              [48.391105932000073, 5.158390543000053],
              [48.389630666000073, 5.156201439000085],
              [48.388155401000063, 5.154416843000092],
              [48.387571810000054, 5.153195951000043],
              [48.385633172000041, 5.150228992000052],
              [48.38139773100005, 5.144827616000043],
              [48.377685772000063, 5.140354229000081],
              [48.374806624000087, 5.13714195700004],
              [48.374011468000049, 5.136281457000052],
              [48.37315650000005, 5.135292348000064],
              [48.369789243000071, 5.131612847000042],
              [48.369552483000064, 5.131322706000049],
              [48.36930256900007, 5.130861118000041],
              [48.369039502000078, 5.130623730000082],
              [48.368842202000053, 5.130399530000091],
              [48.368684362000067, 5.130241271000045],
              [48.368539675000079, 5.130003883000086],
              [48.368394988000034, 5.129832436000072],
              [48.368158228000084, 5.129634612000075],
              [48.367974081000057, 5.129489541000055],
              [48.367829394000069, 5.129278529000089],
              [48.367671554000083, 5.128803751000078],
              [48.367369027000052, 5.128474044000086],
              [48.367092806000073, 5.128104772000086],
              [48.366737666000063, 5.127893760000063],
              [48.366632439000057, 5.127814630000046],
              [48.366553519000036, 5.127682747000051],
              [48.366435139000032, 5.127484923000054],
              [48.366158919000043, 5.127326663000076],
              [48.365974772000072, 5.12723434500009],
              [48.365830085000084, 5.127076085000056],
              [48.365724858000078, 5.126917825000078],
              [48.365553865000038, 5.126627682000048],
              [48.365369718000068, 5.126311163000082],
              [48.365014577000068, 5.12584957100006],
              [48.364567363000049, 5.125585804000082],
              [48.364199070000041, 5.125256096000044],
              [48.364041230000055, 5.124992329000065],
              [48.363909696000064, 5.124768127000038],
              [48.363488789000087, 5.124207621000039],
              [48.362844275000043, 5.123416319000057],
              [48.361957535000045, 5.122651039000061],
              [48.359982582000043, 5.12043814000009],
              [48.358911825000064, 5.119462561000091],
              [48.357507943000087, 5.118034885000043],
              [48.356698926000035, 5.117083100000059],
              [48.356318212000076, 5.116607208000062],
              [48.355937499000049, 5.116012343000079],
              [48.354747768000038, 5.11477502300005],
              [48.354423731000054, 5.114240158000086],
              [48.354169639000077, 5.113921691000087],
              [48.353693215000078, 5.113189218000059],
              [48.353343838000058, 5.112584130000073],
              [48.352891789000068, 5.111301010000091],
              [48.351678263000053, 5.110087485000065],
              [48.350774068000078, 5.109016727000039],
              [48.348357991000057, 5.105964018000066],
              [48.348239292000073, 5.105790900000045],
              [48.348325508000073, 5.105545963000054],
              [48.348703937000039, 5.105162001000053],
              [48.348293746000081, 5.104399467000064],
              [48.348039654000047, 5.104049149000048],
              [48.347658515000035, 5.103730677000044],
              [48.347055045000047, 5.103284816000041],
              [48.346705667000037, 5.10290265000009],
              [48.346483336000063, 5.102584178000086],
              [48.346356290000074, 5.102138316000094],
              [48.346229243000039, 5.101947233000089],
              [48.346134119000055, 5.101545220000048],
              [48.345848584000066, 5.101259685000059],
              [48.345539254000073, 5.100712409000039],
              [48.34532510300005, 5.10042687300006],
              [48.345110951000038, 5.10018892700009],
              [48.344704688000036, 5.100036394000085],
              [48.34432354900008, 5.099749768000038],
              [48.343910648000076, 5.099367599000061],
              [48.342035912000085, 5.097674379000068],
              [48.340375824000034, 5.095358621000059],
              [48.339876927000034, 5.094717862000039],
              [48.33981340400004, 5.094144604000064],
              [48.339622835000057, 5.09388982300004],
              [48.339400503000036, 5.093539499000087],
              [48.339178172000061, 5.093029935000061],
              [48.339051126000072, 5.092711458000053],
              [48.338797033000048, 5.092392981000046],
              [48.337748901000055, 5.091342004000069],
              [48.337590093000074, 5.091119070000047],
              [48.337431285000036, 5.090832439000053],
              [48.337240716000053, 5.090291026000045],
              [48.336669008000058, 5.089367438000068],
              [48.336383153000043, 5.089017111000089],
              [48.336129061000065, 5.088698632000046],
              [48.335874968000041, 5.088443848000054],
              [48.335589114000072, 5.088125369000068],
              [48.335303260000046, 5.087902433000068],
              [48.334403376000068, 5.086554616000058],
              [48.332411569000044, 5.08475500500009],
              [48.331357666000088, 5.083842030000085],
              [48.330403473000047, 5.082857754000088],
              [48.328835438000056, 5.080677347000062],
              [48.326099058000068, 5.07658467400006],
              [48.323069675000056, 5.072804812000072],
              [48.321554287000083, 5.070850173000053],
              [48.32093562700004, 5.069565264000062],
              [48.319246210000074, 5.06759031100006],
              [48.317913712000063, 5.066305402000069],
              [48.316105321000066, 5.064068709000082],
              [48.313202379000074, 5.06021398200005],
              [48.310061490000066, 5.056168899000056],
              [48.309088424000038, 5.054390821000084],
              [48.30741037100006, 5.051835599000071],
              [48.304779087000043, 5.048316677000059],
              [48.302019129000087, 5.044723518000069],
              [48.301352663000046, 5.043890880000049],
              [48.300423231000082, 5.042609144000039],
              [48.299635004000038, 5.041275297000084],
              [48.29753874000005, 5.038568016000056],
              [48.295760091000034, 5.036115528000039],
              [48.294489628000065, 5.034522998000057],
              [48.292869787000086, 5.032675658000073],
              [48.292554996000035, 5.032455642000059],
              [48.291929998000057, 5.031725885000071],
              [48.290400422000062, 5.02984183600006],
              [48.290385187000084, 5.029823901000043],
              [48.290264375000049, 5.029512987000089],
              [48.289455358000055, 5.02854930500007],
              [48.286985193000078, 5.025354890000074],
              [48.283768446000067, 5.020845800000075],
              [48.280468588000076, 5.015796207000051],
              [48.27677260300004, 5.011016813000083],
              [48.274774084000057, 5.008377424000059],
              [48.273703326000089, 5.007140104000086],
              [48.273586510000086, 5.006186897000077],
              [48.273132256000054, 5.005498276000083],
              [48.272085293000032, 5.002642923000053],
              [48.270324492000043, 5.000763149000079],
              [48.26908717200007, 4.998883375000048],
              [48.26761190600007, 4.997503287000086],
              [48.267231192000054, 4.996741860000043],
              [48.267397755000047, 4.996146995000061],
              [48.267159808000088, 4.995766281000044],
              [48.265861428000051, 4.993828146000055],
              [48.26535141800008, 4.992982311000048],
              [48.264406238000049, 4.99215247300009],
              [48.263638206000053, 4.991031153000051],
              [48.262686422000058, 4.989722450000045],
              [48.262380561000043, 4.988841336000064],
              [48.262249388000043, 4.988203654000074],
              [48.259529443000076, 4.984475580000037],
              [48.258427187000052, 4.982964781000078],
              [48.258858656000086, 4.982195515000058],
              [48.257023305000075, 4.980680498000083],
              [48.25580977900006, 4.979324205000069],
              [48.25553648600004, 4.978100748000088],
              [48.253742815000066, 4.975889944000073],
              [48.251360187000046, 4.972661715000072],
              [48.24967077000008, 4.969568415000083],
              [48.247481666000056, 4.965737483000055],
              [48.245356733000051, 4.962099143000046],
              [48.243603145000066, 4.959408117000066],
              [48.243314215000055, 4.958587464000061],
              [48.242737807000083, 4.956973229000084],
              [48.239129758000047, 4.951389333000066],
              [48.235183019000033, 4.946503600000085],
              [48.233296151000047, 4.942589574000067],
              [48.231682045000071, 4.939825153000072],
              [48.229697429000055, 4.936736589000077],
              [48.226399641000057, 4.932329850000087],
              [48.223569876000056, 4.928932529000065],
              [48.219359603000044, 4.923958207000055],
              [48.217345059000081, 4.921323480000069],
              [48.214816228000075, 4.917909135000059],
              [48.212812919000044, 4.915316704000077],
              [48.21079037700008, 4.912318583000058],
              [48.209386495000047, 4.909867739000049],
              [48.207911229000047, 4.907274126000061],
              [48.207102213000042, 4.905727476000038],
              [48.205793509000046, 4.904157032000057],
              [48.20434203800005, 4.902515204000053],
              [48.202152934000082, 4.899207753000042],
              [48.200534900000036, 4.896590346000039],
              [48.19898825100006, 4.894662983000046],
              [48.197298833000048, 4.892688030000045],
              [48.196228076000068, 4.89016580100008],
              [48.194099261000076, 4.886057318000042],
              [48.191764955000053, 4.882447674000048],
              [48.189779737000038, 4.878768183000091],
              [48.188172056000042, 4.875338226000054],
              [48.186995767000042, 4.873176450000074],
              [48.184830458000079, 4.868941009000082],
              [48.18385487900008, 4.867322976000082],
              [48.18276671700005, 4.864857500000085],
              [48.182022694000068, 4.863444454000046],
              [48.17941258500008, 4.85839913600006],
              [48.177358950000041, 4.854402503000074],
              [48.175169846000074, 4.850167062000082],
              [48.172837974000061, 4.845907827000076],
              [48.170077799000069, 4.840101942000047],
              [48.167936285000053, 4.83615203700009],
              [48.165241019000064, 4.82991037000005],
              [48.165437850000046, 4.828728118000072],
              [48.165794770000048, 4.82837119900006],
              [48.166437224000049, 4.828276021000079],
              [48.165017697000053, 4.825910279000084],
              [48.164200531000063, 4.824516472000084],
              [48.164248120000082, 4.823326742000063],
              [48.164842985000064, 4.822708082000077],
              [48.164010174000055, 4.821280405000039],
              [48.163019345000066, 4.819623904000082],
              [48.161917484000071, 4.817781776000061],
              [48.161273794000067, 4.816307332000065],
              [48.159037100000035, 4.811881534000065],
              [48.157395272000088, 4.808169575000079],
              [48.156395899000074, 4.805290427000045],
              [48.15475407100007, 4.801007398000081],
              [48.152612556000065, 4.796795752000094],
              [48.150256889000048, 4.79165611600007],
              [48.146794597000053, 4.785962194000092],
              [48.144110205000061, 4.782435253000074],
              [48.142333284000074, 4.780115730000091],
              [48.139501726000049, 4.77583270000008],
              [48.136519709000083, 4.771222028000068],
              [48.134052760000088, 4.767100078000055],
              [48.131649504000052, 4.762936021000087],
              [48.129841114000044, 4.759081295000044],
              [48.128698973000041, 4.757201520000081],
              [48.126307120000035, 4.753365673000076],
              [48.123194712000043, 4.748291650000056],
              [48.120276829000034, 4.743120011000087],
              [48.11813704900004, 4.739509598000041],
              [48.116191794000088, 4.736387064000041],
              [48.11266140500004, 4.730289816000038],
              [48.109687079000082, 4.724936029000048],
              [48.106562781000036, 4.719017705000056],
              [48.104380881000054, 4.714513990000057],
              [48.101213329000075, 4.708478778000085],
              [48.098781760000065, 4.703502006000065],
              [48.096076562000064, 4.698024325000063],
              [48.093816074000074, 4.693360582000082],
              [48.091983889000062, 4.689696212000058],
              [48.089866169000061, 4.685936663000064],
              [48.087105994000069, 4.681153947000041],
              [48.085440371000061, 4.677822701000082],
              [48.081633234000037, 4.671017443000039],
              [48.080538682000054, 4.669209052000042],
              [48.078842895000037, 4.666955787000063],
              [48.075633224000057, 4.662271486000066],
              [48.072543693000057, 4.657335542000055],
              [48.069408408000072, 4.652512423000076],
              [48.066004212000053, 4.64773043200006],
              [48.062210964000087, 4.64246044500004],
              [48.056307038000057, 4.633961397000064],
              [48.051706587000069, 4.627723788000083],
              [48.048624482000037, 4.622408317000065],
              [48.045773558000064, 4.618159433000073],
              [48.044228107000038, 4.615718769000068],
              [48.043847393000078, 4.615219082000067],
              [48.04365703600007, 4.614790779000089],
              [48.043349942000077, 4.614210032000074],
              [48.043225026000073, 4.61395934300009],
              [48.04288150800005, 4.613520637000079],
              [48.042420881000055, 4.613050594000072],
              [48.042256929000075, 4.612862577000044],
              [48.041610700000035, 4.611530918000085],
              [48.040730299000074, 4.610222214000089],
              [48.038636373000088, 4.607105120000085],
              [48.035852404000082, 4.602845885000079],
              [48.034393816000033, 4.60049431200008],
              [48.032254035000051, 4.596785557000089],
              [48.029623404000063, 4.592625970000086],
              [48.028380897000034, 4.590187152000055],
              [48.026515533000065, 4.58770878200005],
              [48.023305862000086, 4.582633546000068],
              [48.021623227000077, 4.580407568000055],
              [48.020193454000037, 4.577753476000055],
              [48.018248199000084, 4.573946998000054],
              [48.016302943000085, 4.570238103000065],
              [48.013676849000035, 4.56574836100009],
              [48.012309686000037, 4.563461772000039],
              [48.012464532000081, 4.563286960000084],
              [48.012558219000084, 4.563067591000049],
              [48.012558219000084, 4.562691530000052],
              [48.012339617000066, 4.562378145000082],
              [48.012183472000061, 4.562064761000045],
              [48.012089785000057, 4.561751376000075],
              [48.012089785000057, 4.561155945000053],
              [48.012308388000065, 4.560748544000091],
              [48.012370845000078, 4.560247128000071],
              [48.012308388000065, 4.559933742000055],
              [48.01215224300006, 4.559557680000069],
              [48.012009504000048, 4.559414440000069],
              [48.012023382000052, 4.559306508000077],
              [48.011148017000039, 4.55754962900005],
              [48.008910974000059, 4.553450228000088],
              [48.008061253000051, 4.552381111000045],
              [48.007811422000088, 4.552130399000077],
              [48.006249976000049, 4.549873995000041],
              [48.004395930000044, 4.546452659000067],
              [48.001445398000044, 4.541122666000092],
              [47.999280088000035, 4.537458297000057],
              [47.998066563000066, 4.535388165000086],
              [47.995472951000067, 4.530200940000043],
              [47.991951349000033, 4.522753228000056],
              [47.99126130500008, 4.521563497000045],
              [47.990679405000037, 4.520464232000052],
              [47.98977461700008, 4.518605966000052],
              [47.989458993000085, 4.518183633000092],
              [47.989122328000064, 4.517719065000051],
              [47.988977022000086, 4.517399440000077],
              [47.988470039000049, 4.516768813000056],
              [47.987882470000045, 4.515614845000073],
              [47.986954480000065, 4.513973016000079],
              [47.986383410000087, 4.513045027000089],
              [47.98614546400006, 4.512521545000084],
              [47.985859928000082, 4.512069448000091],
              [47.985122295000053, 4.510356236000064],
              [47.984432252000033, 4.50940445100008],
              [47.983742208000081, 4.508381283000062],
              [47.982746081000073, 4.506737754000085],
              [47.981996546000062, 4.505444401000091],
              [47.980767882000066, 4.503788923000059],
              [47.978054453000084, 4.500234221000085],
              [47.976602583000044, 4.497911323000039],
              [47.976270701000033, 4.497150227000077],
              [47.975651189000075, 4.496440843000073],
              [47.975209315000086, 4.495765088000041],
              [47.974245925000048, 4.494659254000055],
              [47.973486731000037, 4.493557241000076],
              [47.972604682000053, 4.492188516000056],
              [47.972436349000077, 4.49163946200008],
              [47.972204891000047, 4.491259347000039],
              [47.97203655900006, 4.491027055000075],
              [47.971636769000042, 4.490520235000076],
              [47.970921355000087, 4.489675534000071],
              [47.970392805000074, 4.489239801000053],
              [47.969622195000056, 4.488067995000051],
              [47.967929837000042, 4.485435362000089],
              [47.967189430000076, 4.484395045000042],
              [47.966491116000043, 4.483682478000048],
              [47.965682099000048, 4.482349980000038],
              [47.965372769000055, 4.481945471000074],
              [47.964072844000043, 4.479998626000054],
              [47.963017006000086, 4.47876498800008],
              [47.962937381000074, 4.478365423000071],
              [47.962577755000041, 4.477898337000056],
              [47.96215466600006, 4.477473714000041],
              [47.96162580400005, 4.476858009000068],
              [47.96105463400005, 4.475902605000044],
              [47.960652699000036, 4.475074587000051],
              [47.959806520000086, 4.473758248000081],
              [47.959214194000083, 4.473078846000078],
              [47.958473788000049, 4.472356981000075],
              [47.957902617000059, 4.471762504000083],
              [47.956866048000052, 4.470446159000062],
              [47.956316032000075, 4.469703060000086],
              [47.955385235000051, 4.468726415000049],
              [47.954658434000066, 4.468258822000053],
              [47.953374314000087, 4.466430532000061],
              [47.95337556000004, 4.466412185000081],
              [47.953058243000044, 4.465860165000038],
              [47.95161973900008, 4.46407671500009],
              [47.950350471000036, 4.462632967000047],
              [47.949271593000049, 4.461231679000093],
              [47.947515772000088, 4.459596840000074],
              [47.947171017000073, 4.459314566000046],
              [47.946744009000042, 4.458659109000052],
              [47.946711902000061, 4.458280473000059],
              [47.945971495000038, 4.457282580000083],
              [47.945421479000061, 4.456348382000044],
              [47.94432144600006, 4.455095704000087],
              [47.943432959000063, 4.454076576000091],
              [47.942502162000039, 4.452823894000062],
              [47.941677137000056, 4.451868458000092],
              [47.941211739000039, 4.451422588000071],
              [47.941016652000087, 4.451299305000077],
              [47.940243469000052, 4.450323028000071],
              [47.936450221000086, 4.446320634000074],
              [47.935343294000063, 4.445230218000063],
              [47.935330796000073, 4.445159137000076],
              [47.935140406000073, 4.444904352000037],
              [47.934907707000036, 4.444607102000077],
              [47.934675007000067, 4.44441601200009],
              [47.933490357000039, 4.443269476000069],
              [47.932115316000079, 4.441815070000075],
              [47.930846048000035, 4.440583600000082],
              [47.928878682000061, 4.438885017000075],
              [47.927926731000071, 4.437929562000079],
              [47.924732406000032, 4.43451114800007],
              [47.922024633000035, 4.431644764000055],
              [47.919676487000061, 4.429181787000061],
              [47.916439853000043, 4.42589072800007],
              [47.913033983000048, 4.422472257000038],
              [47.911320471000067, 4.42092226200009],
              [47.90952234100007, 4.419096236000087],
              [47.907946551000066, 4.417737655000053],
              [47.903380884000057, 4.41337326200005],
              [47.901450418000081, 4.411409439000067],
              [47.90059156600006, 4.410743313000069],
              [47.896155932000056, 4.406430303000093],
              [47.892205445000059, 4.402604224000072],
              [47.889606441000069, 4.399978131000069],
              [47.886903476000043, 4.397473769000044],
              [47.882537149000086, 4.39343894600006],
              [47.878170822000072, 4.389125834000083],
              [47.875537164000036, 4.386447518000068],
              [47.873354, 4.384151811000038],
              [47.872245092000071, 4.38300395400006],
              [47.871690638000075, 4.382343067000079],
              [47.871170837000079, 4.381751746000077],
              [47.870824303000063, 4.381369126000038],
              [47.870477769000047, 4.381056073000082],
              [47.870235195000078, 4.380882155000052],
              [47.870061928000041, 4.380708237000078],
              [47.869888661000061, 4.380603886000074],
              [47.869519285000081, 4.38019941500005],
              [47.868949869000062, 4.379985081000086],
              [47.863989468000057, 4.374908383000047],
              [47.851554418000035, 4.361513112000068],
              [47.845412282000041, 4.355138217000047],
              [47.842631439000058, 4.351638349000041],
              [47.839478653000072, 4.348485563000054],
              [47.835790489000033, 4.344559452000055],
              [47.832161811000049, 4.340573855000059],
              [47.829633634000061, 4.337510299000087],
              [47.828373339000052, 4.336175438000055],
              [47.828182405000064, 4.335959867000042],
              [47.828151017000039, 4.335924429000045],
              [47.827864150000039, 4.335508472000072],
              [47.826687994000054, 4.334059793000051],
              [47.823209089000045, 4.329777051000065],
              [47.821329315000071, 4.327790200000038],
              [47.819842152000035, 4.325827145000062],
              [47.817319923000071, 4.322936100000049],
              [47.813586952000037, 4.319095160000074],
              [47.809229755000047, 4.31425106800009],
              [47.807326187000058, 4.312406985000052],
              [47.803994941000042, 4.308480875000043],
              [47.793279445000053, 4.296752144000038],
              [47.790268234000052, 4.292231045000051],
              [47.786665578000054, 4.287964549000037],
              [47.781345255000076, 4.28180900600006],
              [47.776491154000041, 4.276312451000081],
              [47.776258463000033, 4.275759386000061],
              [47.773374061000084, 4.272077011000079],
              [47.77197017900005, 4.270530361000056],
              [47.769471744000043, 4.267199115000039],
              [47.767449203000069, 4.264153405000059],
              [47.765545634000034, 4.261797739000087],
              [47.764236930000038, 4.260322473000087],
              [47.759787338000081, 4.254825918000051],
              [47.75550430800007, 4.248353784000074],
              [47.754338372000063, 4.247045081000067],
              [47.752526350000039, 4.24475738700005],
              [47.750269494000065, 4.241120223000053],
              [47.748556282000038, 4.23771759400006],
              [47.746204271000067, 4.235090364000087],
              [47.742964549000078, 4.232411396000089],
              [47.740284873000064, 4.230077635000043],
              [47.737848708000058, 4.22701001900009],
              [47.734493668000084, 4.223131498000043],
              [47.730447705000074, 4.21897838600006],
              [47.72856881000007, 4.216516596000076],
              [47.723191228000076, 4.211448344000075],
              [47.717998072000057, 4.206772117000071],
              [47.714791731000048, 4.203691301000049],
              [47.708174534000079, 4.197641701000066],
              [47.702299560000085, 4.192721986000038],
              [47.698448258000042, 4.189341229000092],
              [47.693779646000053, 4.184263249000082],
              [47.690256705000081, 4.180822792000072],
              [47.685378810000088, 4.17552849100008],
              [47.683080743000062, 4.173032982000052],
              [47.680146778000051, 4.169450857000072],
              [47.67788629000006, 4.166072023000083],
              [47.676758664000033, 4.165122871000051],
              [47.672865628000068, 4.160361316000092],
              [47.672104200000035, 4.158338774000072],
              [47.671533129000068, 4.15798185500006],
              [47.669439204000071, 4.155721367000069],
              [47.665941396000051, 4.151224186000093],
              [47.663300194000044, 4.148345038000059],
              [47.661039706000054, 4.146060756000054],
              [47.659731003000047, 4.144609284000069],
              [47.655800009000075, 4.141584740000042],
              [47.652230709000037, 4.13817485900006],
              [47.648765370000035, 4.134625784000093],
              [47.64564656500005, 4.131807390000063],
              [47.64308221400006, 4.129302142000085],
              [47.641696078000052, 4.12766676800004],
              [47.640379249000034, 4.126344547000087],
              [47.639408954000032, 4.125370278000048],
              [47.637966145000064, 4.124020334000079],
              [47.637950824000086, 4.124008528000047],
              [47.633390370000086, 4.119386998000039],
              [47.632390996000083, 4.118340035000074],
              [47.631320238000058, 4.117150304000063],
              [47.630416043000082, 4.116055752000079],
              [47.629654616000039, 4.115294325000093],
              [47.628203145000043, 4.112938658000076],
              [47.627227566000045, 4.112201026000093],
              [47.625371586000085, 4.110226073000092],
              [47.624583581000081, 4.108665637000058],
              [47.61985995200007, 4.103548211000088],
              [47.615065737000066, 4.097839089000047],
              [47.614439595000078, 4.097197775000041],
              [47.613428537000061, 4.096162219000064],
              [47.611009041000045, 4.09368409700005],
              [47.608834332000072, 4.09123797400008],
              [47.607882548000077, 4.08976270800008],
              [47.606787996000037, 4.089286816000083],
              [47.606542918000059, 4.088388635000058],
              [47.60513148800004, 4.086715109000068],
              [47.601481797000076, 4.082457763000093],
              [47.601362824000034, 4.081006292000041],
              [47.600863138000079, 4.080720756000062],
              [47.600239137000074, 4.08066849100004],
              [47.599714403000064, 4.080021270000088],
              [47.596770465000077, 4.076390137000089],
              [47.59653251900005, 4.076937413000053],
              [47.595727642000043, 4.076837624000063],
              [47.595176226000035, 4.076342548000071],
              [47.595556940000051, 4.075866656000073],
              [47.594581361000053, 4.075604915000042],
              [47.594381024000086, 4.075046652000083],
              [47.593076190000033, 4.07384262700009],
              [47.592947303000074, 4.073595035000039],
              [47.592838343000039, 4.073485621000088],
              [47.590032610000037, 4.070367315000055],
              [47.588779564000049, 4.068917572000089],
              [47.586681550000037, 4.066610553000089],
              [47.585539409000035, 4.066443990000039],
              [47.58531140000008, 4.065927302000091],
              [47.584444857000051, 4.065420822000078],
              [47.583740141000078, 4.064103313000089],
              [47.581660887000055, 4.061375738000038],
              [47.579492795000078, 4.057923631000051],
              [47.576521509000088, 4.054419999000061],
              [47.573906457000078, 4.050904981000087],
              [47.571836207000047, 4.048361029000091],
              [47.570828323000057, 4.046856537000053],
              [47.569629757000087, 4.045187916000089],
              [47.568621873000041, 4.043765482000083],
              [47.568131550000032, 4.043108973000074],
              [47.564614707000032, 4.038501396000072],
              [47.561673415000087, 4.034130910000044],
              [47.559888819000037, 4.031941806000077],
              [47.558129644000076, 4.029846189000068],
              [47.556843109000056, 4.028562971000042],
              [47.556248244000074, 4.027230473000088],
              [47.555034719000048, 4.026302483000052],
              [47.554487443000085, 4.024969985000041],
              [47.552203160000033, 4.022971238000082],
              [47.551132416000087, 4.021544520000077],
              [47.54856635200008, 4.018869108000047],
              [47.545564464000051, 4.015190400000051],
              [47.543146528000079, 4.011881060000064],
              [47.537807421000082, 4.006576752000058],
              [47.534404792000032, 4.002388900000085],
              [47.533248120000053, 4.000788450000073],
              [47.530359708000049, 3.997606184000063],
              [47.527852444000075, 3.994074327000078],
              [47.526314624000065, 3.992323780000049],
              [47.522697844000049, 3.988397669000051],
              [47.52053253400004, 3.985304370000051],
              [47.516275117000077, 3.979603098000041],
              [47.512632724000071, 3.975048893000064],
              [47.507910520000053, 3.968565295000076],
              [47.504352199000039, 3.963698864000094],
              [47.502269280000064, 3.960653150000041],
              [47.499059609000085, 3.956745889000047],
              [47.496452389000069, 3.953015084000072],
              [47.493237334000071, 3.947695100000089],
              [47.489311223000072, 3.942757718000053],
              [47.484745441000086, 3.938214837000089],
              [47.480269271000054, 3.933477820000064],
              [47.476008336000064, 3.928320007000082],
              [47.474344448000068, 3.925249187000077],
              [47.471037514000045, 3.92143944500009],
              [47.468225846000053, 3.918917454000052],
              [47.465601222000032, 3.915816215000063],
              [47.459924880000074, 3.909564237000041],
              [47.456144319000089, 3.905949346000057],
              [47.451984436000032, 3.901407314000039],
              [47.448767990000078, 3.897811589000071],
              [47.444580139000038, 3.893219229000067],
              [47.440993744000082, 3.889880013000038],
              [47.434229483000081, 3.88334446600004],
              [47.428252323000038, 3.876691803000085],
              [47.426615208000044, 3.875230504000058],
              [47.423895504000086, 3.872263946000089],
              [47.421523162000085, 3.869852922000064],
              [47.419906283000046, 3.868614496000077],
              [47.417164369000034, 3.865750315000071],
              [47.411717655000075, 3.859693358000072],
              [47.41019497700006, 3.857939025000064],
              [47.408340948000046, 3.856075843000042],
              [47.406813412000076, 3.854744990000086],
              [47.40409124200005, 3.852010753000059],
              [47.401154975000054, 3.849246790000052],
              [47.399320129000046, 3.847424271000079],
              [47.398180649000039, 3.846105901000044],
              [47.39789511400005, 3.845820366000055],
              [47.397062302000052, 3.845249295000087],
              [47.395848777000083, 3.844035770000062],
              [47.393319648000045, 3.841808736000075],
              [47.392824817000076, 3.841153558000087],
              [47.392362367000032, 3.840740663000076],
              [47.388829367000085, 3.837539842000069],
              [47.38733030700007, 3.835755246000076],
              [47.386137550000058, 3.834488133000093],
              [47.386198535000062, 3.834415392000039],
              [47.380162558000052, 3.829316405000043],
              [47.380060049000065, 3.829048690000093],
              [47.379152659000056, 3.82816665200005],
              [47.378362352000067, 3.827490423000086],
              [47.377776939000057, 3.826843594000081],
              [47.377191526000047, 3.826284968000039],
              [47.376723196000057, 3.825873349000062],
              [47.376020701000073, 3.825255921000064],
              [47.375259664000055, 3.824697294000089],
              [47.375069998000072, 3.824560362000057],
              [47.371533046000081, 3.82116041200004],
              [47.37127885700005, 3.821022111000048],
              [47.371161775000076, 3.820963308000046],
              [47.370664174000069, 3.820551686000044],
              [47.370254385000067, 3.82022826900004],
              [47.368205440000054, 3.818228961000045],
              [47.366888262000089, 3.817141100000072],
              [47.365746707000085, 3.815847426000062],
              [47.364780776000089, 3.814935973000047],
              [47.363727033000032, 3.813848108000059],
              [47.363653421000038, 3.81358595100005],
              [47.362402782000061, 3.812383741000076],
              [47.362322042000073, 3.81211340100009],
              [47.36176590000008, 3.81140775700004],
              [47.361443923000081, 3.811054934000083],
              [47.361004864000051, 3.81076091500006],
              [47.360476676000076, 3.810005892000049],
              [47.360243827000033, 3.809673045000068],
              [47.359921850000035, 3.809290820000058],
              [47.359559446000048, 3.809123973000055],
              [47.35921935500005, 3.808967399000039],
              [47.358693875000085, 3.808291724000071],
              [47.358086731000071, 3.80751104400008],
              [47.355802449000066, 3.805393323000089],
              [47.353779907000046, 3.80318042500005],
              [47.351019732000054, 3.800658196000086],
              [47.34818817300004, 3.797826637000071],
              [47.346284605000051, 3.795875479000074],
              [47.345546972000079, 3.795042668000065],
              [47.344606354000064, 3.794138669000063],
              [47.341299420000041, 3.790621434000059],
              [47.337311647000035, 3.787006483000084],
              [47.333129349000046, 3.783196112000041],
              [47.330989568000064, 3.780948962000082],
              [47.327605836000032, 3.776911175000066],
              [47.325507869000035, 3.775015359000065],
              [47.324375701000065, 3.773621259000038],
              [47.32087424100007, 3.76981082900005],
              [47.317183797000041, 3.765299406000054],
              [47.313040857000033, 3.761709126000085],
              [47.31133032200006, 3.760017002000041],
              [47.306925537000041, 3.755732083000055],
              [47.301741095000068, 3.750570542000048],
              [47.298114757000064, 3.747045598000057],
              [47.294905086000085, 3.744309822000048],
              [47.289555634000067, 3.740010727000083],
              [47.284561386000064, 3.735508554000091],
              [47.281396703000041, 3.732676995000077],
              [47.276036111000053, 3.727894982000066],
              [47.271756550000077, 3.724182058000054],
              [47.269324981000068, 3.722130173000039],
              [47.267738597000061, 3.720708306000063],
              [47.265760862000036, 3.718553005000047],
              [47.263098648000039, 3.715759027000047],
              [47.260154033000049, 3.712756674000047],
              [47.257031022000035, 3.709572429000048],
              [47.256048615000054, 3.708572950000075],
              [47.25317629500006, 3.705551140000068],
              [47.251534467000056, 3.703933106000079],
              [47.249969693000082, 3.702124035000054],
              [47.248267594000083, 3.700462943000048],
              [47.24666275900006, 3.698752992000038],
              [47.245252449000077, 3.697385029000088],
              [47.243744876000051, 3.695723928000064],
              [47.241897650000055, 3.693867986000043],
              [47.240146154000058, 3.692352860000085],
              [47.239173527000048, 3.691375737000044],
              [47.237114934000033, 3.68958495600009],
              [47.234991228000069, 3.687760371000081],
              [47.23255965900006, 3.685659543000043],
              [47.229982196000037, 3.683460997000054],
              [47.227273080000089, 3.680719575000069],
              [47.224304670000038, 3.677909541000076],
              [47.222249807000082, 3.675985899000068],
              [47.220720447000076, 3.674380200000087],
              [47.219150003000038, 3.67307149700008],
              [47.217674737000038, 3.671524847000057],
              [47.216199471000039, 3.670311322000089],
              [47.214760575000071, 3.668535167000073],
              [47.214294442000039, 3.667668811000055],
              [47.206800600000065, 3.659603748000052],
              [47.20591608400008, 3.658662253000045],
              [47.205423351000036, 3.658421613000087],
              [47.203231409000068, 3.656010762000051],
              [47.201338315000044, 3.654024380000067],
              [47.197107385000038, 3.649187398000038],
              [47.194189502000086, 3.645913870000072],
              [47.192238299000053, 3.643994483000085],
              [47.188169421000055, 3.639354534000063],
              [47.186116694000077, 3.637070398000048],
              [47.183879650000051, 3.634480856000039],
              [47.181837133000045, 3.63179358800005],
              [47.179127469000036, 3.628789728000072],
              [47.178294658000084, 3.627290667000068],
              [47.178389836000065, 3.626505445000078],
              [47.179103674000032, 3.626576829000044],
              [47.177675998000041, 3.625291920000052],
              [47.176293128000054, 3.623136619000093],
              [47.174445163000087, 3.621337596000046],
              [47.17318074700006, 3.62001837300005],
              [47.172579323000036, 3.618623941000067],
              [47.170140987000082, 3.616067691000069],
              [47.169922445000054, 3.615962972000091],
              [47.167879927000058, 3.614155137000068],
              [47.165255211000044, 3.61220488400005],
              [47.162822264000056, 3.610001988000079],
              [47.160496289000037, 3.608064622000086],
              [47.15883449100005, 3.606484012000067],
              [47.158973434000075, 3.604209895000054],
              [47.158473747000073, 3.604067128000054],
              [47.157861863000051, 3.603503494000051],
              [47.157117455000048, 3.602068381000038],
              [47.156332233000057, 3.60002204400007],
              [47.155138506000071, 3.598421931000075],
              [47.153436408000061, 3.596272031000069],
              [47.151393890000065, 3.594170987000041],
              [47.148670368000069, 3.59117044900006],
              [47.147552011000073, 3.589529128000038],
              [47.144828654000037, 3.586548553000057],
              [47.142031672000087, 3.583960682000054],
              [47.139961541000048, 3.582104703000084],
              [47.138157969000076, 3.580217575000063],
              [47.136512688000039, 3.578706175000093],
              [47.133886594000046, 3.575725565000084],
              [47.131752400000039, 3.573919357000079],
              [47.129372939000064, 3.571563690000062],
              [47.126922094000065, 3.569112846000053],
              [47.124646632000065, 3.56639277000005],
              [47.122555483000042, 3.564535973000091],
              [47.120616523000081, 3.56235517600004],
              [47.118859498000063, 3.560382596000068],
              [47.116833180000071, 3.55819112000006],
              [47.115024789000074, 3.556382729000063],
              [47.113882648000072, 3.555216793000056],
              [47.112780576000034, 3.553883744000075],
              [47.110786690000054, 3.551391691000049],
              [47.109279117000085, 3.549925774000087],
              [47.107868807000045, 3.548313263000068],
              [47.105388607000066, 3.546114379000073],
              [47.103151279000087, 3.543628818000059],
              [47.10188714800006, 3.542156376000094],
              [47.100533872000085, 3.539179226000044],
              [47.099115159000064, 3.537269928000057],
              [47.097413061000054, 3.535364206000054],
              [47.095273280000072, 3.532823237000059],
              [47.093571182000062, 3.530770911000047],
              [47.092306766000036, 3.529304961000093],
              [47.090604668000083, 3.527545819000068],
              [47.08899983200007, 3.525395750000087],
              [47.086780587000078, 3.523165454000093],
              [47.084853224000085, 3.520857376000038],
              [47.082775016000085, 3.518652322000094],
              [47.080879524000068, 3.516764703000092],
              [47.078057772000079, 3.513619151000057],
              [47.074907077000034, 3.510625694000055],
              [47.072076113000037, 3.507852961000083],
              [47.070569583000065, 3.506426902000044],
              [47.068721590000052, 3.504814313000054],
              [47.066191716000048, 3.50184240100009],
              [47.063128745000085, 3.49925187000008],
              [47.060842265000076, 3.496906872000068],
              [47.056903123000041, 3.493217425000068],
              [47.054467507000084, 3.490614427000082],
              [47.051602303000038, 3.487890912000069],
              [47.049559785000042, 3.485887354000056],
              [47.047517267000046, 3.483932658000072],
              [47.046136611000065, 3.48262944600009],
              [47.044616538000071, 3.48085863700004],
              [47.042848655000057, 3.479290240000068],
              [47.041000663000034, 3.477237795000065],
              [47.039298564000035, 3.475283081000043],
              [47.037478155000088, 3.473434719000068],
              [47.036979590000044, 3.473003860000063],
              [47.036984922000045, 3.47300040500005],
              [47.036967298000036, 3.472990869000057],
              [47.036885559000041, 3.472918749000087],
              [46.390989305000062, 3.891492845000073],
              [46.400991440000041, 4.231462479000072],
              [46.39682960500005, 4.234792709000089],
              [46.150987625000084, 4.431444168000041],
              [46.110986710000077, 4.741415024000048],
              [46.210988998000062, 4.941396713000074],
              [46.229829788000075, 5.073255540000048],
              [46.230989456000088, 5.081384660000083],
              [46.040987015000042, 5.031389237000042],
              [45.710977554000067, 5.111379623000062],
              [45.67282676800005, 5.18766975300008],
              [45.67097663900006, 5.191373825000085],
              [45.536192092000078, 5.486187366000081],
              [45.629516552000041, 5.581942559000083],
              [46.434513035000066, 6.407909827000083],
              [46.524234412000055, 6.499968583000054],
              [46.527490258000057, 6.503309250000086],
              [46.541529662000073, 6.517714399000056],
              [46.541548843000044, 6.517734080000082],
              [46.898634811000079, 6.88412268400009],
            ],
          ],
        ],
      },
    },
  ],
};
