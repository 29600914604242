import { useEffect, useRef, useState } from "react";
import { dummy } from "./dummyData";
import Plot from "react-plotly.js";
import { UseStatsContext } from "../../context/ApiContext";

/* const data = dummy.statistics;

const trace1X = [];

const trace1Y = [];
const trace2Y = [];

for (const d of data) {
  d.date = new Date(d.date);
  trace1X.push(d.date);
  trace1Y.push(d.mean);
  trace2Y.push(d.median);
}
 */
function PlotlyPlot() {
  const statistics = UseStatsContext();
  const [trace1X, setTrace1X] = useState([]);
  const [trace2Y, setTrace2Y] = useState([]);
  const [trace1Y, setTrace1Y] = useState([]);

  useEffect(() => {
    const data = statistics;

    console.log("stats res", data);

    let _1X = [],
      _1Y = [],
      _2Y = [];

    if (data !== undefined) {
      for (const d of data) {
        d.date = new Date(d.data_date);
        _1X.push(d.date);
        _1Y.push(d.mean);
        _2Y.push(d.median);
      }
    }

    setTrace1X(_1X);
    setTrace1Y(_1Y);
    setTrace2Y(_2Y);
  }, [statistics]);

  let trace1 = {
    type: "scatter",
    mode: "lines",
    name: "mean",
    x: trace1X,
    y: trace1Y,
    line: { color: "#17BECF" },
  };
  let trace2 = {
    type: "scatter",
    mode: "lines",
    name: "median",
    x: trace1X,
    y: trace2Y,
    line: { color: "#7F7F7F" },
  };

  let plotData = [trace1, trace2];

  let layout = {
    //title: "Time Series Plot for product x ",
    autosize: true,
    width: "50%",
    height: "100%",
    //showlegend: false,
    margin: {
      t: 30,
      b: 10,
      l: 35,
      r: 30,
    },

    xaxis: {
      autorange: true,
      range: [
        statistics[0] ? statistics[0].data_date : dummy.statistics[0].data_date,
        statistics[0]
          ? statistics[statistics.length - 1].data_date
          : dummy.statistics[dummy.statistics.length - 1].data_date,
      ],
      rangeselector: {
        buttons: [
          {
            count: 1,
            label: "1 Month",
            step: "month",
            stepmode: "backward",
          },
          {
            count: 6,
            label: "6 Months",
            step: "month",
            stepmode: "backward",
          },
          { step: "all", label: "All Available Data" },
        ],
      },
      rangeslider: {
        range: [
          statistics[0]
            ? statistics[0].data_date
            : dummy.statistics[0].data_date,
          statistics[0]
            ? statistics[statistics.length - 1].data_date
            : dummy.statistics[dummy.statistics.length - 1].data_date,
        ],
      },
      type: "date",
    },
    yaxis: {
      autorange: true,
      range: [0, 100],
      type: "linear",
    },
  };
  return (
    <div>
      <Plot data={plotData} layout={layout} />
    </div>
  );
}

export default PlotlyPlot;
