import * as React from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { RefreshTwoTone } from "@mui/icons-material";

export default function ButtonComponent({ name, type, onClick }) {
  const [bgColor, setBgColor] = React.useState("#008080");
  return (
    <Button
      component="label"
      variant="contained"
      startIcon={<RefreshTwoTone />}
      type={type}
      onClick={onClick}
      style={{ backgroundColor: bgColor }}
      onMouseEnter={() => {
        setBgColor("#005959");
      }}
      onMouseLeave={() => {
        setBgColor("#008080");
      }}
    >
      {name}
    </Button>
  );
}
