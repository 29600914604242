export const jubaland = {
  type: "FeatureCollection",
  name: "jubaland",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        OBJECTID_1: 6,
        Name: "Jubaland Administration",
        Shape_Leng: 17.315600866400001,
        Shape_Area: 9.1002673810400001,
        "area-km2": 112179.979998,
        style: {
          color: "black",
          fill: false,
          opacity: 1,
          clickable: false,
        },
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [42.890477541000052, 4.290922768000087],
              [43.091444016000082, 3.947744370000066],
              [43.100923538000075, 3.931493759000091],
              [43.140924454000071, 3.851499557000068],
              [43.120923996000045, 3.671514512000044],
              [43.102094650000083, 3.257364273000064],
              [43.100923538000075, 3.081567764000056],
              [43.100923538000075, 3.061571122000089],
              [42.930921556000044, 3.041570662000083],
              [42.60091209400008, 3.071569443000044],
              [42.542623520000063, 2.751028060000067],
              [42.515989304000072, 2.600419998000064],
              [42.400911331000088, 1.951669693000042],
              [42.520910263000076, 1.881677628000091],
              [42.900918961000059, 1.521711349000043],
              [42.95330238300005, 1.482427598000072],
              [42.980920793000053, 1.46171379000009],
              [43.261045456000033, 1.046407700000088],
              [43.339689255000053, 0.937211990000094],
              [43.51984000200008, 0.677023088000055],
              [43.51945949800006, 0.676679706000073],
              [43.513201516000038, 0.670754848000058],
              [43.509779132000062, 0.667267964000075],
              [43.506191878000038, 0.663986497000053],
              [43.503166762000035, 0.661219250000045],
              [43.502024621000089, 0.660148493000065],
              [43.501310783000065, 0.659541730000058],
              [43.49634960700007, 0.654616246000046],
              [43.492137961000083, 0.650511676000065],
              [43.487063137000064, 0.645556356000043],
              [43.44641004500005, 0.604689114000053],
              [43.438344012000073, 0.596360665000077],
              [43.433834594000075, 0.591792435000059],
              [43.429896586000041, 0.587699762000057],
              [43.424002440000038, 0.581899057000044],
              [43.422722511000075, 0.58066845500008],
              [43.421734564000076, 0.579443507000065],
              [43.420200282000053, 0.577920177000067],
              [43.418475173000047, 0.576254554000059],
              [43.417187527000067, 0.574829636000061],
              [43.416345556000067, 0.573994067000058],
              [43.413696617000085, 0.571277346000045],
              [43.411146433000056, 0.568735458000049],
              [43.408790767000085, 0.566248921000067],
              [43.40765646400007, 0.565011549000076],
              [43.40673664600007, 0.564008151000053],
              [43.40499552700004, 0.562251426000046],
              [43.403055112000061, 0.560225761000083],
              [43.400379372000089, 0.557587683000065],
              [43.398937013000079, 0.556115186000056],
              [43.395465785000056, 0.552650301000085],
              [43.392026169000076, 0.549011196000038],
              [43.388327402000073, 0.54525017800006],
              [43.383627967000052, 0.540384180000046],
              [43.382193147000066, 0.538702052000076],
              [43.380395586000077, 0.53684747300008],
              [43.379487705000088, 0.535910793000085],
              [43.378238324000051, 0.534552741000084],
              [43.37086910000005, 0.526572953000084],
              [43.364616073000036, 0.519801842000049],
              [43.363592905000075, 0.518814366000072],
              [43.363378754000053, 0.518647804000068],
              [43.363353217000054, 0.51832915600005],
              [43.362960413000053, 0.517901031000065],
              [43.361879693000049, 0.516958386000056],
              [43.361320520000049, 0.516113678000067],
              [43.360729917000072, 0.515456202000053],
              [43.358919281000055, 0.513481329000058],
              [43.356586097000047, 0.510936509000089],
              [43.355189513000084, 0.509443700000077],
              [43.354550953000057, 0.508761143000072],
              [43.35326703000004, 0.507093886000064],
              [43.352804544000037, 0.506602153000074],
              [43.350458280000055, 0.504287756000053],
              [43.349280447000069, 0.502895772000045],
              [43.346984267000039, 0.50033785100004],
              [43.346448889000044, 0.499362272000042],
              [43.345211569000071, 0.498398590000079],
              [43.34446242100006, 0.497149021000041],
              [43.342058783000084, 0.494543864000093],
              [43.339715718000036, 0.492067384000052],
              [43.338396168000088, 0.490826472000094],
              [43.337692472000072, 0.490439293000065],
              [43.336752585000056, 0.489368536000086],
              [43.33623914900005, 0.488037569000085],
              [43.335824595000076, 0.487536351000074],
              [43.334801427000059, 0.486536977000071],
              [43.333861540000044, 0.485525706000089],
              [43.333375163000085, 0.484797619000062],
              [43.332053149000046, 0.483562651000057],
              [43.331223091000084, 0.482596329000046],
              [43.330732549000061, 0.482242050000082],
              [43.330078197000034, 0.481575801000076],
              [43.329590626000083, 0.480942336000055],
              [43.328908652000052, 0.480056296000043],
              [43.328498017000072, 0.479658105000055],
              [43.327674941000055, 0.479136854000046],
              [43.326782643000058, 0.477994712000054],
              [43.325519788000065, 0.476512],
              [43.325212199000077, 0.475781814000072],
              [43.324926664000088, 0.475555765000081],
              [43.32459278500005, 0.47549592300004],
              [43.32136937000007, 0.47196277900008],
              [43.320857785000044, 0.471320324000089],
              [43.319894808000072, 0.469914602000074],
              [43.318802476000087, 0.469010905000061],
              [43.317859665000071, 0.468072360000065],
              [43.31736178400007, 0.467402111000069],
              [43.311284214000068, 0.460757673000046],
              [43.310159173000045, 0.459581526000079],
              [43.308640360000084, 0.45789962200007],
              [43.306804366000051, 0.455980225000076],
              [43.305177137000044, 0.454140616000075],
              [43.303868434000037, 0.453034166000066],
              [43.302845757000057, 0.451796382000055],
              [43.301607946000047, 0.450488143000086],
              [43.300056452000035, 0.448637579000092],
              [43.29845516000006, 0.446895157000085],
              [43.294707509000034, 0.442885765000085],
              [43.29337501100008, 0.441446191000068],
              [43.291655417000072, 0.43950118500004],
              [43.290686220000055, 0.438495660000058],
              [43.285344330000044, 0.432856337000089],
              [43.281314911000038, 0.428272440000057],
              [43.280038132000072, 0.426871993000077],
              [43.278147165000064, 0.424585870000044],
              [43.27687344900005, 0.423267109000051],
              [43.27498177700005, 0.421101800000088],
              [43.271359672000074, 0.417288806000045],
              [43.270603569000059, 0.416414261000057],
              [43.265309268000067, 0.410691658000076],
              [43.264916657000072, 0.410156279000091],
              [43.261918536000053, 0.406872623000083],
              [43.259455794000075, 0.404231421000077],
              [43.25760051900005, 0.402147552000088],
              [43.255743835000033, 0.400103056000091],
              [43.254316863000042, 0.398768718000042],
              [43.252424487000042, 0.396462481000071],
              [43.250306767000041, 0.394154404000062],
              [43.246511526000063, 0.389954655000054],
              [43.24541767900007, 0.388822571000048],
              [43.243856698000059, 0.38715009100008],
              [43.242086433000054, 0.385253379000062],
              [43.238385667000045, 0.381305314000087],
              [43.235625492000054, 0.378188220000084],
              [43.234327076000056, 0.37693007300004],
              [43.232402027000035, 0.374712367000086],
              [43.231152105000035, 0.373560169000086],
              [43.230474664000042, 0.372547057000077],
              [43.230093245000035, 0.372025416000042],
              [43.229007882000076, 0.371097871000075],
              [43.227868514000079, 0.369860986000049],
              [43.22619092900004, 0.368039819000046],
              [43.225455815000089, 0.367112937000059],
              [43.224722235000058, 0.366225572000076],
              [43.223585419000074, 0.365017904000069],
              [43.222145846000046, 0.363340384000082],
              [43.220908526000073, 0.361865118000082],
              [43.219647412000086, 0.360437441000045],
              [43.218517168000062, 0.359271505000038],
              [43.217422615000089, 0.358165056000075],
              [43.216102719000048, 0.356842615000062],
              [43.21539793900007, 0.355911960000071],
              [43.214959873000055, 0.355333497000061],
              [43.214710030000049, 0.354857605000063],
              [43.214391334000084, 0.354477621000058],
              [43.214091370000062, 0.354119972000092],
              [43.213084336000065, 0.352925371000083],
              [43.211866574000055, 0.35159774400006],
              [43.209023118000061, 0.348575828000094],
              [43.206762630000071, 0.346148778000043],
              [43.204751986000076, 0.343995366000058],
              [43.203934868000033, 0.342992559000038],
              [43.202108690000045, 0.34097306600006],
              [43.200968643000067, 0.339712336000048],
              [43.200718799000072, 0.339331622000088],
              [43.200338085000055, 0.33896280600004],
              [43.199260236000043, 0.338013830000079],
              [43.199243533000072, 0.337999124000078],
              [43.199200142000052, 0.337942590000068],
              [43.198726355000076, 0.337325300000089],
              [43.198568910000063, 0.337194281000052],
              [43.197934830000065, 0.336666626000067],
              [43.197114620000036, 0.335713001000045],
              [43.197014206000063, 0.335351219000074],
              [43.196983045000081, 0.335238949000086],
              [43.196519755000054, 0.334404297000049],
              [43.195769520000056, 0.33375178600005],
              [43.195385318000035, 0.333420214000057],
              [43.194901017000063, 0.333002256000043],
              [43.194392295000057, 0.332313363000083],
              [43.193783375000066, 0.331596533000038],
              [43.193533376000062, 0.331232898000053],
              [43.19350322300005, 0.331189039000094],
              [43.192998153000076, 0.330454392000092],
              [43.192655386000069, 0.330183950000048],
              [43.19191479400007, 0.32959962700005],
              [43.191475676000039, 0.328978950000078],
              [43.191302237000059, 0.328765487000055],
              [43.191035982000074, 0.328489764000039],
              [43.190797274000033, 0.328227858000048],
              [43.189591569000072, 0.326904979000062],
              [43.187977490000037, 0.32498163200006],
              [43.185787681000079, 0.32263970300005],
              [43.18384842100005, 0.320676648000074],
              [43.183015609000051, 0.319939015000045],
              [43.182640795000054, 0.319337941000072],
              [43.182206593000046, 0.318880155000045],
              [43.181397576000052, 0.317987857000048],
              [43.180433894000089, 0.317166943000075],
              [43.179857604000063, 0.31642250200008],
              [43.179533306000053, 0.316104406000079],
              [43.178530325000054, 0.31512060600005],
              [43.176829011000052, 0.313181346000079],
              [43.175888773000054, 0.311886123000079],
              [43.172936119000042, 0.308580568000082],
              [43.171010897000087, 0.306425243000092],
              [43.170083239000064, 0.305626557000039],
              [43.169366707000052, 0.304584540000064],
              [43.168108286000063, 0.303175712000041],
              [43.166989939000075, 0.301974084000051],
              [43.165973578000035, 0.30086160400009],
              [43.165300522000052, 0.300011029000075],
              [43.164646170000083, 0.299213910000049],
              [43.162397580000061, 0.296774962000086],
              [43.161814612000057, 0.29597784300006],
              [43.161088876000065, 0.295371080000052],
              [43.160077605000083, 0.294371706000049],
              [43.159411356000078, 0.293181976000085],
              [43.158043166000084, 0.291563942000039],
              [43.156877336000036, 0.290302729000075],
              [43.154879187000063, 0.288171370000043],
              [43.149085119000063, 0.282111626000074],
              [43.144255630000089, 0.277130390000082],
              [43.143611735000036, 0.276466262000042],
              [43.142772351000076, 0.275628579000056],
              [43.141827139000043, 0.274657872000091],
              [43.140291687000058, 0.273016361000089],
              [43.139602343000035, 0.272349795000082],
              [43.138103283000078, 0.270886426000061],
              [43.137231977000056, 0.269830704000071],
              [43.136865963000048, 0.269458750000069],
              [43.134367529000087, 0.266805651000084],
              [43.132024699000056, 0.263997006000068],
              [43.129679991000046, 0.261582734000058],
              [43.123624317000065, 0.255039165000085],
              [43.123267343000066, 0.254658502000041],
              [43.121574938000037, 0.252722279000068],
              [43.120471477000081, 0.251636587000064],
              [43.119388822000076, 0.250078040000062],
              [43.115450814000042, 0.245616550000079],
              [43.11381999300005, 0.243359930000054],
              [43.111001222000084, 0.240119996000089],
              [43.11043015100006, 0.239501336000046],
              [43.108467096000084, 0.237502588000041],
              [43.106634911000071, 0.235456252000063],
              [43.105670480000072, 0.233874477000086],
              [43.103471237000065, 0.230968503000042],
              [43.101894480000055, 0.229312116000074],
              [43.100067599000056, 0.227330393000045],
              [43.098199722000061, 0.225260262000063],
              [43.096627371000068, 0.223749866000048],
              [43.09539195800005, 0.222381114000086],
              [43.093107675000056, 0.219799399000067],
              [43.092405734000067, 0.218990382000072],
              [43.090014376000056, 0.216218310000045],
              [43.087682504000043, 0.213636595000082],
              [43.086902552000083, 0.21311931200006],
              [43.08533873500005, 0.211447490000069],
              [43.081983695000076, 0.20803296400004],
              [43.078350664000084, 0.203660385000092],
              [43.077308054000071, 0.20240553900004],
              [43.074976887000048, 0.200631],
              [43.074643058000049, 0.199954694000041],
              [43.074583571000062, 0.199026704000062],
              [43.073227279000037, 0.197503849000043],
              [43.072144624000089, 0.196373605000076],
              [43.070824023000057, 0.195207669000069],
              [43.06871882300004, 0.193179271000076],
              [43.06794487500008, 0.192233343000055],
              [43.066493404000084, 0.190960331000042],
              [43.065886641000077, 0.190175109000052],
              [43.065286290000074, 0.189213694000046],
              [43.064435170000081, 0.188033594000046],
              [43.06430430000006, 0.187533907000045],
              [43.064244813000073, 0.187331653000058],
              [43.063697537000053, 0.186653507000074],
              [43.062888520000058, 0.186463150000066],
              [43.060877876000063, 0.184892706000085],
              [43.059664351000038, 0.183702975000074],
              [43.059116370000083, 0.18279517600007],
              [43.058141496000076, 0.181716125000094],
              [43.05785596000004, 0.181739920000041],
              [43.057391966000068, 0.181228336000061],
              [43.055155272000036, 0.178765593000094],
              [43.054334358000062, 0.17685012700008],
              [43.053426333000061, 0.175997066000093],
              [43.051728848000039, 0.174827586000049],
              [43.050669988000038, 0.173506985000074],
              [43.049326216000054, 0.171883095000055],
              [43.046958716000063, 0.168670535000047],
              [43.045577942000079, 0.166796905000069],
              [43.045185331000084, 0.166416191000053],
              [43.044019395000078, 0.165488201000073],
              [43.041474076000043, 0.162773775000062],
              [43.039760159000082, 0.161014814000055],
              [43.038303563000056, 0.159829688000059],
              [43.037107060000039, 0.158718635000071],
              [43.034680010000045, 0.156089330000043],
              [43.032962285000053, 0.154237530000046],
              [43.032074500000078, 0.153364847000091],
              [43.031039435000082, 0.152460652000059],
              [43.029457798000067, 0.150662318000059],
              [43.02653035600008, 0.147451887000045],
              [43.024912323000081, 0.145869545000039],
              [43.02210455900007, 0.143061781000085],
              [43.020484700000054, 0.141481673000044],
              [43.01681411900006, 0.137655847000076],
              [43.011135244000059, 0.131438114000048],
              [43.009719464000057, 0.130272178000041],
              [43.003105186000084, 0.123163631000068],
              [42.995894796000073, 0.115852644000086],
              [42.98955423700005, 0.108950367000091],
              [42.983950525000068, 0.102938212000083],
              [42.980178456000033, 0.098863293000079],
              [42.978845958000079, 0.097280951000073],
              [42.976728942000079, 0.095006726000065],
              [42.974289290000058, 0.092105624000055],
              [42.97223167900006, 0.090029636000054],
              [42.968281855000043, 0.085512676000064],
              [42.963427755000055, 0.079968532000066],
              [42.95874021700007, 0.074353004000045],
              [42.954409598000041, 0.06911819000004],
              [42.952490767000086, 0.06710117700004],
              [42.95062045700007, 0.06513516800004],
              [42.947425175000035, 0.061339193000094],
              [42.943868586000065, 0.057458831000076],
              [42.939300020000076, 0.05241437400008],
              [42.934731455000076, 0.047346122000079],
              [42.931186058000037, 0.043229654000072],
              [42.927664456000059, 0.039184570000089],
              [42.925688799000056, 0.036985409000067],
              [42.92386851100008, 0.034974765000072],
              [42.921679407000056, 0.032607201000076],
              [42.920251730000075, 0.030893989000049],
              [42.918612202000077, 0.029498433000072],
              [42.916242339000064, 0.026860803000091],
              [42.915005019000034, 0.025647278000065],
              [42.913472303000049, 0.024237361000075],
              [42.910495940000033, 0.020590923000043],
              [42.908651858000042, 0.018782533000092],
              [42.907212284000082, 0.017128807000063],
              [42.905165948000047, 0.015011087000062],
              [42.90159675600006, 0.011311025000055],
              [42.898635032000072, 0.008251577000067],
              [42.893375718000073, 0.002959117000046],
              [42.892102707000049, 0.001602824000088],
              [42.890912976000038, 0.000496375000068],
              [42.89091189800007, -0.000050041999941],
              [42.890644192000082, -0.000471171999948],
              [42.887748293000072, -0.004107881999914],
              [42.885594881000088, -0.006606315999932],
              [42.883102062000034, -0.009527205999916],
              [42.881335646000082, -0.011424724999927],
              [42.877944914000068, -0.015136683999913],
              [42.876909848000082, -0.016088467999907],
              [42.875393003000056, -0.017772826999931],
              [42.871663841000043, -0.021337019999919],
              [42.865096529000084, -0.028213662999917],
              [42.857755187000066, -0.035266923999927],
              [42.853436465000073, -0.039894975999914],
              [42.851164080000046, -0.042179257999919],
              [42.847430668000072, -0.045620249999956],
              [42.845263017000036, -0.04752114799993],
              [42.843549805000066, -0.049519894999946],
              [42.841408290000061, -0.051363977999927],
              [42.839599900000053, -0.053219956999953],
              [42.839218371000072, -0.054135183999961],
              [42.838850369000056, -0.054504865999945],
              [42.836958698000046, -0.055944439999962],
              [42.834579237000071, -0.058312003999959],
              [42.83417472900004, -0.059204301999955],
              [42.833080261000077, -0.060037191999925],
              [42.832295659000067, -0.060883662999913],
              [42.830546051000056, -0.062678314999914],
              [42.829582369000036, -0.063451639999926],
              [42.828956070000061, -0.064337599999931],
              [42.82830935700008, -0.064748445999953],
              [42.827643108000075, -0.065343310999936],
              [42.826822194000044, -0.066128532999926],
              [42.825108982000074, -0.067782258999955],
              [42.823669408000058, -0.069281318999913],
              [42.820921131000034, -0.071839239999917],
              [42.819707606000065, -0.073266915999909],
              [42.817316247000065, -0.075527403999956],
              [42.816043236000041, -0.076693339999906],
              [42.814936786000032, -0.077395280999951],
              [42.814829711000073, -0.077918762999957],
              [42.814724004000084, -0.078610319999939],
              [42.81406828300004, -0.079203670999959],
              [42.813449623000054, -0.079286952999951],
              [42.813013731000069, -0.079550490999907],
              [42.812366968000049, -0.080036482999958],
              [42.812319379000087, -0.080595655999957],
              [42.811691787000086, -0.08094177199996],
              [42.810844113000087, -0.081642618999922],
              [42.810356324000054, -0.082404046999955],
              [42.809939918000055, -0.083272549999947],
              [42.808905211000081, -0.084760163999931],
              [42.802504103000047, -0.089851758999941],
              [42.80013653900005, -0.09267142099992],
              [42.799300153000047, -0.093585249999933],
              [42.798851630000058, -0.094075302999954],
              [42.796650629000055, -0.095871795999926],
              [42.79450911400005, -0.098120385999948],
              [42.792700723000053, -0.099797905999935],
              [42.792193705000045, -0.100456946999941],
              [42.78957173200007, -0.103010178999909],
              [42.785485540000082, -0.106714876999945],
              [42.783052009000073, -0.108375862999935],
              [42.780196656000044, -0.111255010999912],
              [42.778864157000044, -0.11268268799995],
              [42.775896070000044, -0.115640819999953],
              [42.774110886000074, -0.1171060699999],
              [42.773296219000088, -0.117774733999909],
              [42.771749569000065, -0.119285691999949],
              [42.76997687100004, -0.12112977399994],
              [42.767204799000069, -0.123628207999957],
              [42.766290444000049, -0.124990206999939],
              [42.765765225000052, -0.125472290999937],
              [42.765467792000038, -0.125876798999911],
              [42.764980003000062, -0.126031463999936],
              [42.764742056000046, -0.126626328999919],
              [42.764170986000067, -0.127256885999941],
              [42.763611812000079, -0.127780367999947],
              [42.762517260000038, -0.128446616999952],
              [42.761244249000072, -0.130397774999949],
              [42.759459653000079, -0.132170472999917],
              [42.754534169000067, -0.137143546999937],
              [42.752705178000042, -0.139251790999936],
              [42.751928659000043, -0.140070283999933],
              [42.749454019000041, -0.142199901999959],
              [42.745016325000051, -0.146078422999949],
              [42.742614961000072, -0.148400601999924],
              [42.741711708000082, -0.149332966999907],
              [42.739317516000085, -0.151241852999931],
              [42.735784016000082, -0.154454125999962],
              [42.731108375000076, -0.159320123999919],
              [42.73007331000008, -0.160331393999911],
              [42.728499093000039, -0.161832671999946],
              [42.726732479000077, -0.163427696999918],
              [42.725314388000072, -0.164519245999941],
              [42.722304369000085, -0.167505468999934],
              [42.721578634000082, -0.16830258899995],
              [42.721295835000035, -0.168610194999928],
              [42.720900487000051, -0.169040221999921],
              [42.720210444000088, -0.16977785499995],
              [42.71914815000008, -0.170752817999926],
              [42.718021340000064, -0.171598141999937],
              [42.716415203000054, -0.173358942999926],
              [42.714273688000048, -0.175429074999954],
              [42.712465298000041, -0.17702331299995],
              [42.709157466000079, -0.180158873999915],
              [42.703732676000072, -0.185660756999937],
              [42.698865368000043, -0.190658431999907],
              [42.69599942800005, -0.193405901999938],
              [42.69115722500004, -0.198117234999927],
              [42.686789307000083, -0.202608578999957],
              [42.682650652000063, -0.206814164999912],
              [42.681873401000075, -0.207632550999961],
              [42.680270979000056, -0.208929028999933],
              [42.678394716000071, -0.210886420999941],
              [42.676917440000068, -0.212427573999946],
              [42.67631067700006, -0.213263954999945],
              [42.675855434000084, -0.21354994099994],
              [42.67538268800007, -0.213846922999949],
              [42.674563178000085, -0.214836015999936],
              [42.671900073000074, -0.217149063999955],
              [42.669379307000042, -0.219880045999957],
              [42.666009991000067, -0.223434960999953],
              [42.663511557000049, -0.226033331999929],
              [42.662041050000084, -0.227275410999937],
              [42.660632988000089, -0.228838355999926],
              [42.65694222500008, -0.232276678999938],
              [42.653580139000042, -0.235108787999934],
              [42.651247264000062, -0.237042910999946],
              [42.647613088000071, -0.240055234999943],
              [42.646727609000038, -0.240585242999941],
              [42.645697622000057, -0.241661371999953],
              [42.643544210000073, -0.243148534999932],
              [42.641533566000078, -0.243374583999923],
              [42.64111747000004, -0.242800006999914],
              [42.640668731000062, -0.242180354999959],
              [42.638333190000083, -0.244647594999947],
              [42.637256584000056, -0.24623495599991],
              [42.636014505000048, -0.248704836999934],
              [42.63574324700005, -0.247919614999944],
              [42.635471323000047, -0.248639048999962],
              [42.635799065000072, -0.249846717999958],
              [42.635620605000042, -0.250239328999953],
              [42.633815883000068, -0.251560189999907],
              [42.631974180000043, -0.253359061999959],
              [42.629818389000036, -0.25508655099992],
              [42.627719704000071, -0.256085924999923],
              [42.626056461000076, -0.257342279999932],
              [42.625235547000045, -0.258115604999944],
              [42.624380716000076, -0.25865521399993],
              [42.623415259000069, -0.259531383999956],
              [42.621809123000048, -0.260792498999933],
              [42.62110718200006, -0.261423055999956],
              [42.62027437100005, -0.261946536999915],
              [42.619596224000077, -0.262755553999909],
              [42.619096538000065, -0.263219548999928],
              [42.617875125000069, -0.264170017999959],
              [42.617216763000044, -0.264837581999927],
              [42.616693282000085, -0.265432447999956],
              [42.615999253000041, -0.265866661999951],
              [42.615396476000058, -0.266277155999944],
              [42.615122838000048, -0.266836329999933],
              [42.61478971300005, -0.267133761999958],
              [42.614539870000044, -0.267347913999913],
              [42.614099669000041, -0.26809744399992],
              [42.613147885000046, -0.268751795999947],
              [42.612398355000039, -0.269489428999918],
              [42.612029538000058, -0.26998911499993],
              [42.611636927000063, -0.270964693999929],
              [42.611898668000038, -0.271238331999939],
              [42.611981018000051, -0.271367471999952],
              [42.612422149000054, -0.272059245999912],
              [42.612279382000054, -0.272273397999925],
              [42.611125343000083, -0.272903954999947],
              [42.610292532000074, -0.273641587999919],
              [42.609459720000075, -0.274343528999907],
              [42.608841061000078, -0.275009777999912],
              [42.608418899000071, -0.27556173399995],
              [42.607425281000076, -0.276354173999948],
              [42.606411963000085, -0.277436638999916],
              [42.605676377000066, -0.27805548799995],
              [42.605233300000066, -0.278607243999943],
              [42.605045820000043, -0.2788407099999],
              [42.603701425000054, -0.280875148999939],
              [42.603526962000046, -0.281438864999927],
              [42.603307524000058, -0.282147900999917],
              [42.602928100000042, -0.282826307999926],
              [42.603213635000088, -0.284218291999935],
              [42.602904305000038, -0.285419919999924],
              [42.60315286000008, -0.285836064999955],
              [42.602297543000077, -0.286431190999906],
              [42.600971876000074, -0.286203137999962],
              [42.600500946000068, -0.28646045399995],
              [42.600380787000063, -0.286526108999908],
              [42.599571771000058, -0.287097179999932],
              [42.597727688000077, -0.289012645999946],
              [42.596526061000077, -0.290666370999929],
              [42.594336956000063, -0.292879269999958],
              [42.592897383000036, -0.293866745999935],
              [42.59201760600007, -0.295657197999958],
              [42.590851669000074, -0.296644673999936],
              [42.590233009000087, -0.297441793999951],
              [42.589363883000033, -0.298387721999916],
              [42.587888617000033, -0.300243701999932],
              [42.586576138000055, -0.301479276999942],
              [42.585092750000058, -0.303598741999906],
              [42.583201079000048, -0.305645077999941],
              [42.582582419000062, -0.306561170999942],
              [42.581333202000053, -0.308369560999949],
              [42.580318155000043, -0.310307077999937],
              [42.579286866000075, -0.31224808199994],
              [42.578644411000084, -0.313866115999929],
              [42.576383923000037, -0.317958788999931],
              [42.574266203000036, -0.322717710999939],
              [42.573893610000084, -0.323893799999951],
              [42.573516673000086, -0.324918711999942],
              [42.573416874000088, -0.325451414999918],
              [42.573084593000033, -0.327225044999921],
              [42.572529196000062, -0.331236180999952],
              [42.572346961000051, -0.333768562999921],
              [42.571720180000057, -0.336054589999947],
              [42.570946855000045, -0.338671996999949],
              [42.570831676000068, -0.340172645999928],
              [42.571490355000037, -0.341430426999921],
              [42.57217227700005, -0.340896792999956],
              [42.572707656000034, -0.341253711999912],
              [42.571181025000044, -0.342929487999925],
              [42.570038883000052, -0.344024039999908],
              [42.568733956000074, -0.345334487999935],
              [42.568087117000061, -0.346251258999928],
              [42.567068333000066, -0.346904931999916],
              [42.566711414000054, -0.347880510999914],
              [42.566207951000081, -0.349449210999921],
              [42.566231746000085, -0.350543762999962],
              [42.566735209000058, -0.350521712999921],
              [42.566165925000064, -0.353020618999949],
              [42.565795322000042, -0.355173558999923],
              [42.565273943000079, -0.355852696999932],
              [42.564962510000043, -0.356720208999945],
              [42.564189186000078, -0.357172305999939],
              [42.563641910000058, -0.357612505999953],
              [42.563090857000077, -0.357372815999952],
              [42.563118428000053, -0.356934359999912],
              [42.563522936000084, -0.355768423999962],
              [42.564296261000038, -0.353710189999958],
              [42.562930254000037, -0.352825342999949],
              [42.561036400000035, -0.35273461099996],
              [42.559304527000052, -0.353158768999947],
              [42.558665060000067, -0.353613267999947],
              [42.558022605000076, -0.35392259799994],
              [42.557526043000053, -0.354530781999927],
              [42.556618723000042, -0.355588219999959],
              [42.556462029000045, -0.355954219999944],
              [42.555736600000046, -0.357648647999952],
              [42.556011056000045, -0.360895174999939],
              [42.55680908000005, -0.362417273999938],
              [42.557380151000075, -0.363131111999962],
              [42.557932247000053, -0.363520024999957],
              [42.558805616000086, -0.36407812099992],
              [42.559259925000049, -0.364368431999935],
              [42.55990237900005, -0.36463017199992],
              [42.560429047000071, -0.36405868099996],
              [42.561020726000038, -0.363416646999951],
              [42.561621550000041, -0.361629348999941],
              [42.562662554000042, -0.360014017999958],
              [42.563051150000035, -0.360065870999961],
              [42.563566749000074, -0.361203747999923],
              [42.562805322000088, -0.36213173799996],
              [42.56289350600008, -0.362837206999927],
              [42.562948090000077, -0.363273879999952],
              [42.562424608000072, -0.364772939999909],
              [42.562202336000041, -0.36570267399992],
              [42.561663181000085, -0.366605124999921],
              [42.561167270000055, -0.367534858999932],
              [42.56059242300006, -0.367723471999909],
              [42.560334459000046, -0.36703517199993],
              [42.560893632000045, -0.366333230999942],
              [42.560572405000073, -0.365619392999918],
              [42.55902575500005, -0.365274370999941],
              [42.557653789000085, -0.365546264999921],
              [42.556563013000073, -0.366119079999919],
              [42.555944353000086, -0.366856711999958],
              [42.555397077000066, -0.368189210999958],
              [42.555444666000085, -0.369819140999937],
              [42.55565881800004, -0.370687644999919],
              [42.555313796000064, -0.371924963999959],
              [42.555706407000059, -0.373876121999956],
              [42.556105797000043, -0.37445381099991],
              [42.556372656000065, -0.374839803999919],
              [42.557635953000045, -0.375132789999952],
              [42.558002826000063, -0.374068418999912],
              [42.558216738000056, -0.373447818999921],
              [42.558561760000089, -0.373495408999929],
              [42.558792933000063, -0.37390933699993],
              [42.558944656000051, -0.374181005999958],
              [42.559763388000079, -0.373959403999947],
              [42.560393945000044, -0.374078376999933],
              [42.560417740000048, -0.374589960999913],
              [42.559513545000073, -0.374982571999908],
              [42.559346982000079, -0.375244312999939],
              [42.559977998000079, -0.375766330999909],
              [42.559953745000087, -0.376005739999925],
              [42.559180420000075, -0.376898037999922],
              [42.558740220000061, -0.378301919999956],
              [42.558181047000062, -0.378777811999953],
              [42.557621873000073, -0.379503547999946],
              [42.55717749300004, -0.380374307999944],
              [42.556432143000052, -0.38124055399993],
              [42.554683239000042, -0.382156646999931],
              [42.55394560600007, -0.382787203999953],
              [42.553497875000062, -0.383249073999934],
              [42.552696389000062, -0.38367950199995],
              [42.551756502000046, -0.383750885999916],
              [42.550542977000077, -0.384571799999947],
              [42.547723315000042, -0.386665724999943],
              [42.543987562000041, -0.389414002999956],
              [42.543676174000041, -0.389143517999912],
              [42.541179798000087, -0.386975054999937],
              [42.54135825700007, -0.386844184999916],
              [42.541703279000046, -0.387070233999907],
              [42.542191069000069, -0.386534854999923],
              [42.544225508000068, -0.388140990999943],
              [42.546355125000048, -0.386094654999908],
              [42.546676353000066, -0.38632070299991],
              [42.547786238000072, -0.386160008999923],
              [42.547939649000057, -0.386137796999947],
              [42.55057866900006, -0.384167290999926],
              [42.551982551000037, -0.383001354999919],
              [42.553207973000042, -0.380264974999932],
              [42.553909914000087, -0.379241806999914],
              [42.55459995800004, -0.378742119999913],
              [42.555765894000047, -0.376457837999908],
              [42.555675045000044, -0.37583576499992],
              [42.55556918800005, -0.374963503999936],
              [42.55545873300008, -0.374053359999948],
              [42.555462144000046, -0.373950343999923],
              [42.555075850000037, -0.373209872999951],
              [42.553886119000083, -0.372198601999912],
              [42.551479088000065, -0.369888780999929],
              [42.54873458600008, -0.368331977999958],
              [42.545772157000044, -0.367237425999917],
              [42.540525446000061, -0.366392717999929],
              [42.537705785000071, -0.36648789599991],
              [42.53458491400005, -0.36734275799995],
              [42.532181659000059, -0.368532487999914],
              [42.530555268000057, -0.370427521999943],
              [42.528318811000076, -0.37197255399991],
              [42.525090865000038, -0.373743507999961],
              [42.522274980000077, -0.375351387999956],
              [42.521358887000076, -0.376100917999906],
              [42.520549871000071, -0.377112188999945],
              [42.519479113000045, -0.378670735999947],
              [42.518253691000041, -0.379896158999941],
              [42.517991950000066, -0.380705175999935],
              [42.517825388000063, -0.381609370999911],
              [42.516745614000058, -0.383175163999908],
              [42.516171662000033, -0.384345750999955],
              [42.513554255000088, -0.385916194999936],
              [42.512102784000035, -0.387260590999915],
              [42.511668231000044, -0.387739530999909],
              [42.511206125000058, -0.388248838999914],
              [42.509434012000042, -0.390613885999926],
              [42.507522322000057, -0.392876118999936],
              [42.506463462000056, -0.394398973999955],
              [42.506078972000068, -0.395182451999915],
              [42.504869223000071, -0.397182942999962],
              [42.50322361800005, -0.399632043999929],
              [42.49980097100007, -0.40392871499995],
              [42.499963757000046, -0.404414759999952],
              [42.499702016000072, -0.405580695999959],
              [42.499273713000036, -0.40577105299991],
              [42.498916794000081, -0.405961409999918],
              [42.498298134000038, -0.406508685999938],
              [42.497588072000042, -0.406486635999954],
              [42.496898028000089, -0.406534224999916],
              [42.495300013000076, -0.408745378999924],
              [42.491611849000037, -0.415074745999959],
              [42.490003573000081, -0.418520696999906],
              [42.489961899000036, -0.418609988999947],
              [42.489922117000049, -0.418599014999927],
              [42.489271856000073, -0.418419631999939],
              [42.488083939000035, -0.419159991999948],
              [42.487630028000069, -0.419585567999945],
              [42.486043910000035, -0.420952013999909],
              [42.485714562000055, -0.42066822299995],
              [42.485131594000052, -0.42066822299995],
              [42.482466597000041, -0.42292871099994],
              [42.481136281000033, -0.425101470999948],
              [42.480051444000082, -0.425891139999919],
              [42.478897406000044, -0.426140982999925],
              [42.478005108000048, -0.427532967999923],
              [42.477150684000037, -0.429443986999956],
              [42.475768415000061, -0.432696397999962],
              [42.474580866000053, -0.434869158999959],
              [42.474174176000076, -0.4364440499999],
              [42.473640979000038, -0.437950559999933],
              [42.473591208000073, -0.439941856999951],
              [42.474622497000041, -0.442271984999934],
              [42.476407093000034, -0.444651445999909],
              [42.477787180000064, -0.445769792999954],
              [42.480047668000054, -0.446436041999959],
              [42.482847311000057, -0.446854190999943],
              [42.483932148000065, -0.447349430999907],
              [42.485246173000064, -0.447824462999961],
              [42.485390176000067, -0.447901670999954],
              [42.485829758000079, -0.448137355999961],
              [42.485702664000087, -0.448674478999919],
              [42.48568436100004, -0.448700809999934],
              [42.484346372000061, -0.450625636999916],
              [42.482847311000057, -0.452529205999952],
              [42.480658207000033, -0.454884871999923],
              [42.478552384000068, -0.456645673999958],
              [42.477269657000079, -0.457581113999936],
              [42.475899285000082, -0.458929955999906],
              [42.475149755000075, -0.459834150999939],
              [42.474949683000034, -0.46097184599995],
              [42.475470982000047, -0.461571157999913],
              [42.476458458000081, -0.461868589999938],
              [42.476910556000064, -0.462392071999943],
              [42.476793765000082, -0.46323233399994],
              [42.476853252000069, -0.46388668499992],
              [42.476615306000042, -0.464838469999961],
              [42.474473791000037, -0.465730767999958],
              [42.472772595000038, -0.466297832999942],
              [42.472689195000044, -0.46632563299994],
              [42.472332276000088, -0.466979984999909],
              [42.472332276000088, -0.467455876999907],
              [42.471380491000048, -0.467396389999919],
              [42.470726140000068, -0.467455876999907],
              [42.470754012000043, -0.468419118999918],
              [42.470500091000076, -0.468431455999962],
              [42.470321631000047, -0.467360697999936],
              [42.470125316000065, -0.466280745999939],
              [42.469357949000084, -0.465968713999928],
              [42.468763084000045, -0.466206659999955],
              [42.468406165000033, -0.465076415999931],
              [42.467989759000034, -0.464184117999935],
              [42.467079606000084, -0.463853695999944],
              [42.467811300000051, -0.462756440999954],
              [42.469120003000057, -0.462518494999927],
              [42.468578666000042, -0.461022137999919],
              [42.467793444000051, -0.459998968999912],
              [42.467930273000036, -0.458592384999918],
              [42.468174158000068, -0.457167410999944],
              [42.469649424000067, -0.456572545999961],
              [42.470738037000046, -0.455356317999929],
              [42.470910538000055, -0.455977679999933],
              [42.471021390000033, -0.45641962499991],
              [42.471461590000047, -0.456514802999948],
              [42.471679542000061, -0.456199407999918],
              [42.471996969000088, -0.455289380999943],
              [42.472020763000046, -0.45401636899993],
              [42.470735855000044, -0.453088378999951],
              [42.469201102000056, -0.452326951999908],
              [42.468963156000086, -0.452969405999909],
              [42.469605611000077, -0.453052686999911],
              [42.470166966000079, -0.453357569999923],
              [42.470807238000077, -0.454147238999951],
              [42.470952188000069, -0.454713862999938],
              [42.470212373000038, -0.455099023999935],
              [42.468106550000073, -0.454278109999962],
              [42.466450867000049, -0.453899667999906],
              [42.466024522000055, -0.453802217999907],
              [42.46428751500008, -0.453790319999939],
              [42.461396470000068, -0.454420876999961],
              [42.45880285800007, -0.455479737999951],
              [42.458255582000049, -0.455979423999906],
              [42.458041430000037, -0.456717056999935],
              [42.457103725000081, -0.457890443999929],
              [42.456068660000085, -0.458604281999953],
              [42.455568973000084, -0.459675038999933],
              [42.455328845000054, -0.460774037999954],
              [42.454555520000042, -0.461404595999909],
              [42.453591838000079, -0.461428389999924],
              [42.453199227000084, -0.462582428999951],
              [42.452878, -0.463367650999942],
              [42.45262815600006, -0.464367023999955],
              [42.452321009000059, -0.464743290999934],
              [42.45199978100004, -0.465385745999924],
              [42.451714246000051, -0.466170967999915],
              [42.450974431000077, -0.466877355999941],
              [42.450795971000048, -0.46743652899994],
              [42.450215186000037, -0.468276790999937],
              [42.449249322000071, -0.470161011999949],
              [42.448573358000033, -0.470668148999948],
              [42.447631288000082, -0.471517304999907],
              [42.447119704000045, -0.472421499999939],
              [42.446096536000084, -0.473266208999917],
              [42.444131819000063, -0.474190738999937],
              [42.443919329000039, -0.474681987999929],
              [42.443598102000067, -0.474765268999931],
              [42.443455334000078, -0.474467835999917],
              [42.442908058000057, -0.474848549999933],
              [42.441159154000047, -0.475717052999926],
              [42.439505429000064, -0.4765260699999],
              [42.439043616000049, -0.476093319999961],
              [42.437613757000065, -0.477406470999938],
              [42.435438733000069, -0.479127132999906],
              [42.433938604000048, -0.480679305999956],
              [42.431933961000084, -0.48275349499994],
              [42.428960640000071, -0.486077861999945],
              [42.427227410000057, -0.488304401999926],
              [42.426192345000061, -0.489268083999946],
              [42.42476466800008, -0.490422121999927],
              [42.423842008000065, -0.491452342999935],
              [42.423444067000048, -0.491814106999925],
              [42.422385207000048, -0.493063323999934],
              [42.421947479000039, -0.493345528999953],
              [42.421767426000088, -0.493525263999913],
              [42.420983507000074, -0.494724499999961],
              [42.420498439000085, -0.495590322999931],
              [42.418728958000088, -0.496856819999948],
              [42.417836439000041, -0.498430181999936],
              [42.417614388000061, -0.498821619999944],
              [42.416569673000083, -0.501005405999933],
              [42.416008251000051, -0.501831637999942],
              [42.415064588000064, -0.502734088999944],
              [42.414493517000039, -0.503757256999961],
              [42.41333135800005, -0.504484736999927],
              [42.412617519000037, -0.505281855999954],
              [42.41176091400007, -0.506983170999945],
              [42.409734595000089, -0.510538720999932],
              [42.40899696300005, -0.511466710999912],
              [42.408170110000071, -0.513070143999926],
              [42.406728353000062, -0.515656305999926],
              [42.40595502900004, -0.517333825999913],
              [42.405403976000059, -0.518200585999921],
              [42.404600918000085, -0.518638082999928],
              [42.402445324000041, -0.521271833999947],
              [42.399853893000056, -0.522814036999932],
              [42.399848719000033, -0.522818414999961],
              [42.397997914000086, -0.524384481999959],
              [42.396605929000089, -0.525633698999911],
              [42.395463788000086, -0.527132758999926],
              [42.394285955000043, -0.52941704199992],
              [42.392893970000046, -0.531986858999915],
              [42.392287207000038, -0.533236075999923],
              [42.390823839000063, -0.534735136999927],
              [42.389717390000044, -0.5368409599999],
              [42.389003551000087, -0.538054484999918],
              [42.388111253000034, -0.539660620999939],
              [42.387485122000044, -0.540403240999922],
              [42.386576501000036, -0.541480908999915],
              [42.385130969000045, -0.542709033999927],
              [42.383777208000083, -0.543387429999939],
              [42.382923067000036, -0.543815456999937],
              [42.381085885000061, -0.544588807999958],
              [42.378900557000065, -0.545506644999932],
              [42.377330113000085, -0.545899255999927],
              [42.374891165000065, -0.548742711999921],
              [42.372870806000037, -0.550760806999961],
              [42.369259964000037, -0.554487365999933],
              [42.368012347000047, -0.556075241999906],
              [42.367951260000041, -0.556152988999941],
              [42.367075459000034, -0.557465447999959],
              [42.365524210000046, -0.559365260999925],
              [42.361482902000034, -0.563721418999933],
              [42.359718325000074, -0.566170518999911],
              [42.357790962000081, -0.568978282999922],
              [42.357162395000046, -0.569744183999944],
              [42.354959403000066, -0.572428501999923],
              [42.352869254000041, -0.574976268999933],
              [42.351144144000045, -0.576713275999907],
              [42.349821059000078, -0.578545601999906],
              [42.348653831000036, -0.579685857999948],
              [42.348249323000061, -0.580494874999943],
              [42.347268017000033, -0.58176540799991],
              [42.346867758000087, -0.582283637999922],
              [42.345988835000071, -0.583421611999938],
              [42.345639146000053, -0.584050100999946],
              [42.34531510100004, -0.58428844599996],
              [42.344584953000037, -0.584825493999915],
              [42.34332383900005, -0.586419732999957],
              [42.342185474000075, -0.588551093999911],
              [42.340674516000036, -0.590335689999961],
              [42.339891476000048, -0.5916280499999],
              [42.339177638000081, -0.592663114999937],
              [42.338285340000084, -0.593626796999956],
              [42.335320729000046, -0.596355725999956],
              [42.331682336000085, -0.601050714999928],
              [42.328898366000033, -0.604905441999961],
              [42.325826679000045, -0.609799680999913],
              [42.32336611900007, -0.612864738999917],
              [42.320187357000066, -0.616854782999951],
              [42.319358519000048, -0.618322847999934],
              [42.318226484000036, -0.620217273999913],
              [42.317217858000049, -0.621860960999925],
              [42.316299120000053, -0.623358161999931],
              [42.316119775000061, -0.625480415999959],
              [42.316084969000087, -0.625892287999932],
              [42.31626342800007, -0.627498423999953],
              [42.316477580000083, -0.62824795399996],
              [42.316299120000053, -0.629247327999906],
              [42.316126609000037, -0.630212072999939],
              [42.315406822000057, -0.630675004999944],
              [42.314178078000054, -0.630795469999953],
              [42.31358653500007, -0.630853463999927],
              [42.311526119000064, -0.631262418999938],
              [42.31066951300005, -0.632499738999911],
              [42.309872393000035, -0.633951209999907],
              [42.309303505000059, -0.634315579999907],
              [42.308518283000069, -0.635671872999922],
              [42.306805071000042, -0.637313700999925],
              [42.305484470000067, -0.638313073999939],
              [42.304627864000054, -0.638777068999957],
              [42.304378021000048, -0.639455215999931],
              [42.304407622000042, -0.639471560999937],
              [42.305053985000086, -0.639828478999959],
              [42.30453050300008, -0.640506624999944],
              [42.303158537000058, -0.641694611999924],
              [42.301094364000051, -0.64416654899992],
              [42.300410884000087, -0.645259120999924],
              [42.300116603000049, -0.645729541999913],
              [42.299188613000069, -0.647073937999949],
              [42.299105332000067, -0.647728289999918],
              [42.296739951000063, -0.650626784999929],
              [42.291990744000088, -0.656829727999934],
              [42.290551170000072, -0.659042626999906],
              [42.290991370000086, -0.659494723999956],
              [42.291069031000063, -0.659826119999934],
              [42.29000011800008, -0.662943198999926],
              [42.290523599000039, -0.663538063999908],
              [42.290170456000055, -0.664491591999933],
              [42.289456618000088, -0.664943689999916],
              [42.288409655000066, -0.663063915999942],
              [42.288148420000084, -0.663161443999911],
              [42.286625059000073, -0.663730164999947],
              [42.285389922000036, -0.66551031399996],
              [42.284543040000074, -0.666892829999938],
              [42.283997937000038, -0.667782698999929],
              [42.282100307000064, -0.669498613999906],
              [42.281210071000089, -0.67070782199994],
              [42.279744641000036, -0.672532425999918],
              [42.277940027000056, -0.675139679999916],
              [42.27704772900006, -0.676079566999931],
              [42.276614678000044, -0.67710461899992],
              [42.275548668000056, -0.678578001999938],
              [42.274819156000035, -0.67948045299994],
              [42.273216796000042, -0.681242997999959],
              [42.271773446000054, -0.684548704999941],
              [42.269489164000049, -0.687380262999909],
              [42.266768457000069, -0.690665662999947],
              [42.265438141000061, -0.69213648199991],
              [42.264552969000079, -0.693018731999928],
              [42.263438704000066, -0.69412931599993],
              [42.262521119000041, -0.695043871999928],
              [42.261012344000051, -0.697240425999951],
              [42.260046480000085, -0.699874177999959],
              [42.259477591000064, -0.702130218999912],
              [42.258763753000039, -0.702487137999924],
              [42.258085607000055, -0.703450819999944],
              [42.257667019000053, -0.705204169999945],
              [42.257764380000083, -0.708697530999927],
              [42.258121299000038, -0.709946747999936],
              [42.258192683000061, -0.710696277999944],
              [42.258371142000044, -0.712016878999918],
              [42.258556111000075, -0.712667825999915],
              [42.258499008000058, -0.712869016999946],
              [42.258866521000073, -0.71346095499996],
              [42.259185033000051, -0.713929571999927],
              [42.259356539000066, -0.714225540999962],
              [42.259415923000063, -0.7148290899999],
              [42.259713355000088, -0.715186008999922],
              [42.260320118000038, -0.715423954999949],
              [42.260784113000057, -0.715459646999932],
              [42.260998098000073, -0.715656055999943],
              [42.261316609000062, -0.716445305999912],
              [42.261267607000036, -0.716765937999924],
              [42.261120602000062, -0.716938586999959],
              [42.260728588000063, -0.717135898999913],
              [42.260434578000059, -0.717209890999925],
              [42.25989181500006, -0.716970604999915],
              [42.259047106000082, -0.715507235999951],
              [42.257478844000048, -0.715193459999909],
              [42.25514101400006, -0.714886831999934],
              [42.253731388000062, -0.714975626999944],
              [42.252119098000037, -0.715077188999942],
              [42.249287540000068, -0.715457902999958],
              [42.248926564000044, -0.71555735599992],
              [42.246955668000055, -0.716100356999959],
              [42.244437216000051, -0.718886070999929],
              [42.240364561000035, -0.723833605999914],
              [42.238934380000046, -0.725722265999934],
              [42.236466021000069, -0.728784628999961],
              [42.235391488000062, -0.73040091799993],
              [42.23466952800004, -0.731294959999957],
              [42.233202383000048, -0.733922519999908],
              [42.232107831000064, -0.73708720399992],
              [42.231698237000046, -0.738512145999948],
              [42.230954895000082, -0.739895069999932],
              [42.23139399300004, -0.741156081999918],
              [42.231013279000081, -0.741822330999923],
              [42.229871138000078, -0.743297596999923],
              [42.228110337000089, -0.744868040999961],
              [42.227396499000065, -0.745938797999941],
              [42.225968822000084, -0.747985134999908],
              [42.224588735000054, -0.75036459599994],
              [42.224065253000049, -0.751935039999921],
              [42.223303826000063, -0.752720261999912],
              [42.222784120000085, -0.75329307699991],
              [42.222518603000083, -0.754647625999951],
              [42.221424051000042, -0.756122891999951],
              [42.221082806000084, -0.756588630999943],
              [42.220852981000064, -0.75719364899993],
              [42.220781597000041, -0.757788513999913],
              [42.220202405000066, -0.75826615099993],
              [42.219377715000064, -0.758407173999956],
              [42.21787865500005, -0.75973967199991],
              [42.216284416000065, -0.761405294999918],
              [42.215038975000084, -0.762013801999956],
              [42.214571204000038, -0.762571230999924],
              [42.213742169000056, -0.763334402999931],
              [42.213159201000053, -0.764262391999921],
              [42.211882413000069, -0.76526002199995],
              [42.211263753000082, -0.766235600999948],
              [42.209887442000081, -0.768355064999923],
              [42.209122238000077, -0.76968581899996],
              [42.207765945000062, -0.770780370999944],
              [42.206330148000063, -0.772483429999909],
              [42.205386484000087, -0.774777865999908],
              [42.20399827600005, -0.776956816999927],
              [42.202554926000062, -0.778418440999928],
              [42.199096586000053, -0.782072657999947],
              [42.196534889000077, -0.784319503999939],
              [42.193703331000052, -0.788031463999914],
              [42.192588760000035, -0.789889186999915],
              [42.191466637000076, -0.790744048999954],
              [42.190137915000037, -0.79095994499994],
              [42.188825436000059, -0.792005163999931],
              [42.187516732000063, -0.793052125999907],
              [42.186350796000056, -0.794765337999934],
              [42.184399638000059, -0.797311361999959],
              [42.183233702000052, -0.799476670999923],
              [42.182579350000083, -0.801201779999928],
              [42.182107235000046, -0.802393254999913],
              [42.180179871000064, -0.804594255999916],
              [42.179418444000078, -0.8057245],
              [42.17876031600008, -0.808399649999956],
              [42.178454762000058, -0.809043847999931],
              [42.175432846000035, -0.811232952999944],
              [42.171360192000066, -0.813705847999927],
              [42.168353950000039, -0.81494491199993],
              [42.167529260000038, -0.816204281999944],
              [42.166653185000087, -0.816781913999932],
              [42.165363950000085, -0.817631958999925],
              [42.164760964000038, -0.818323745999919],
              [42.163936274000037, -0.819107223999936],
              [42.16292900500008, -0.819711945999927],
              [42.161980338000035, -0.82047237799992],
              [42.16136270700008, -0.820967457999927],
              [42.160613827000077, -0.821567744999925],
              [42.158134165000035, -0.823689430999934],
              [42.15568550200004, -0.824910406999948],
              [42.153851135000082, -0.826092686999914],
              [42.15300860900004, -0.827194688999953],
              [42.152471048000052, -0.827710719999914],
              [42.151919995000071, -0.829291317999946],
              [42.150305738000043, -0.829697569999951],
              [42.149425338000071, -0.830946786999959],
              [42.149968837000074, -0.831528010999932],
              [42.149903412000072, -0.832262940999954],
              [42.148759089000066, -0.832541025999944],
              [42.148021456000038, -0.831220424999913],
              [42.144131037000079, -0.832386360999919],
              [42.142330768000079, -0.833645730999933],
              [42.140569966000044, -0.834240595999916],
              [42.139404031000083, -0.834216801999958],
              [42.138249992000055, -0.834062136999933],
              [42.136243124000089, -0.833564193999962],
              [42.133812297000077, -0.834526131999951],
              [42.131079693000061, -0.834825307999949],
              [42.126784766000071, -0.836598006999907],
              [42.124627578000059, -0.838095322999948],
              [42.12300954400007, -0.838951928999961],
              [42.121153565000043, -0.839570588999948],
              [42.118702720000044, -0.841307595999922],
              [42.116132902000061, -0.84311598599993],
              [42.112492327000041, -0.844876786999919],
              [42.10704336100008, -0.844710224999915],
              [42.102990156000033, -0.843712594999943],
              [42.099292277000075, -0.843398818999958],
              [42.095972928000037, -0.84372004599993],
              [42.095106845000089, -0.843311382999957],
              [42.094156128000066, -0.842786565999916],
              [42.090441897000062, -0.841609518999917],
              [42.08640749500006, -0.840329313999916],
              [42.083415313000046, -0.839213669999936],
              [42.080845495000062, -0.838666393999915],
              [42.076276930000063, -0.839665766999929],
              [42.073165795000079, -0.840721924999912],
              [42.070845005000081, -0.841035989999909],
              [42.069846447000089, -0.841007459999958],
              [42.068315635000033, -0.84070098299992],
              [42.067812007000043, -0.841150227999947],
              [42.067348013000071, -0.842328060999932],
              [42.066759086000047, -0.84287804],
              [42.066634174000058, -0.84413645099994],
              [42.066348639000068, -0.844850288999908],
              [42.066209813000057, -0.846184754999911],
              [42.066140426000061, -0.8468517399999],
              [42.064926901000035, -0.849921243999916],
              [42.064207124000063, -0.853321170999948],
              [42.069332680000059, -0.858167820999938],
              [42.069915648000062, -0.855788359999906],
              [42.070022724000069, -0.855050726999934],
              [42.070177389000037, -0.853623049999953],
              [42.071831114000076, -0.852135886999918],
              [42.072997050000083, -0.85114841099994],
              [42.075388408000038, -0.849006895999935],
              [42.076375885000061, -0.847460245999912],
              [42.077161107000052, -0.847150915999919],
              [42.080397174000041, -0.846294310999951],
              [42.082062797000049, -0.84579462399995],
              [42.083692727000084, -0.846389488999932],
              [42.084703998000066, -0.846544153999957],
              [42.085584399000084, -0.847567321999918],
              [42.086484818000088, -0.847922496999956],
              [42.088578743000085, -0.848802897999917],
              [42.088711638000063, -0.848850148999929],
              [42.089649501000054, -0.849183611999933],
              [42.090831110000067, -0.849982474999933],
              [42.091997046000074, -0.850541647999933],
              [42.093841129000054, -0.851196],
              [42.09505465400008, -0.851445843999954],
              [42.096315768000068, -0.851862248999907],
              [42.09819176600007, -0.852776597999934],
              [42.099337683000044, -0.85437258099995],
              [42.09917112100004, -0.855217288999938],
              [42.100206187000083, -0.855883537999944],
              [42.101253150000048, -0.85581215499991],
              [42.103608816000076, -0.857442084999946],
              [42.104346449000047, -0.858988734999912],
              [42.103668302000074, -0.861915471999907],
              [42.102758540000082, -0.863976936999961],
              [42.101856136000038, -0.865744659999962],
              [42.100671112000043, -0.868341830999952],
              [42.09913761200005, -0.870370008999942],
              [42.097636369000043, -0.872634943999913],
              [42.094765342000073, -0.875904958999911],
              [42.092960728000037, -0.87766750399993],
              [42.091564708000078, -0.879434982999953],
              [42.090672669000071, -0.880140398999913],
              [42.089554322000083, -0.881044593999945],
              [42.088959457000044, -0.882258118999914],
              [42.088531154000066, -0.885422802999926],
              [42.087674548000052, -0.888825431999919],
              [42.087008299000047, -0.891514222999945],
              [42.086080309000067, -0.895678279999913],
              [42.086130543000081, -0.896451876999947],
              [42.086199282000052, -0.897510464999925],
              [42.086131675000047, -0.89888039899995],
              [42.083248751000042, -0.901864877999913],
              [42.081773485000042, -0.904196749999926],
              [42.078109115000075, -0.906076523999957],
              [42.074777870000048, -0.907742146999908],
              [42.072445998000035, -0.909502947999954],
              [42.070542429000056, -0.911828870999955],
              [42.06604524800008, -0.915998876999936],
              [42.061064053000052, -0.920200368999929],
              [42.058256289000042, -0.922912954999958],
              [42.055436628000052, -0.92608953499996],
              [42.054389665000087, -0.928326228999936],
              [42.052982007000082, -0.930846712999937],
              [42.052434731000062, -0.932750281999915],
              [42.051887455000042, -0.934957231999931],
              [42.048556209000083, -0.940001688999928],
              [42.047503308000046, -0.941515892999917],
              [42.04526661500006, -0.943800175999911],
              [42.043077510000046, -0.945275441999911],
              [42.04169524100007, -0.945958034999933],
              [42.040124796000043, -0.947468991999926],
              [42.037984913000059, -0.949636206999912],
              [42.035231228000043, -0.951994172999946],
              [42.032946945000049, -0.954801936999957],
              [42.030761617000053, -0.95768877799992],
              [42.029472932000033, -0.959941572999924],
              [42.029282575000082, -0.960940945999937],
              [42.029432212000074, -0.961389382999926],
              [42.029500994000045, -0.963189510999939],
              [42.030678336000051, -0.965671868999948],
              [42.03017864900005, -0.967147134999948],
              [42.029512400000044, -0.967837178999957],
              [42.029913687000089, -0.969628424999939],
              [42.030104905000087, -0.970176250999941],
              [42.030044357000065, -0.970501302999935],
              [42.030131060000087, -0.971013758999959],
              [42.030238136000037, -0.971501548999925],
              [42.030418732000044, -0.972042931999908],
              [42.03075907200008, -0.972454032999906],
              [42.030895208000061, -0.97262532499991],
              [42.031201515000078, -0.973036425999908],
              [42.031541855000057, -0.973207717999912],
              [42.031916229000046, -0.973276234999958],
              [42.032308267000076, -0.97309578799991],
              [42.032724673000075, -0.973155273999907],
              [42.033010208000064, -0.973928598999919],
              [42.033107421000068, -0.974098435999906],
              [42.033243557000048, -0.974406761999944],
              [42.033549863000076, -0.974543794999931],
              [42.034094408000044, -0.974578053999949],
              [42.034502816000042, -0.97461231199992],
              [42.03463895200008, -0.974646569999948],
              [42.034979293000049, -0.97488637899994],
              [42.035047361000068, -0.975057670999945],
              [42.035231010000075, -0.975361369999916],
              [42.034522084000059, -0.976482738999948],
              [42.032252277000055, -0.977244328999916],
              [42.031463558000041, -0.978175936999946],
              [42.030238136000037, -0.978497163999918],
              [42.029638558000045, -0.97738279899994],
              [42.028125353000064, -0.979182919999914],
              [42.027781443000038, -0.981536920999929],
              [42.027537448000089, -0.982863474999931],
              [42.026526177000051, -0.984529097999939],
              [42.025300754000057, -0.985373805999927],
              [42.024931938000066, -0.985944876999952],
              [42.024503635000087, -0.986682509999923],
              [42.023789797000063, -0.987860342999909],
              [42.023705610000036, -0.988804083999923],
              [42.023385288000043, -0.989787705999959],
              [42.022481093000067, -0.989894781999908],
              [42.021247147000054, -0.989706677999948],
              [42.019940288000043, -0.988668150999956],
              [42.018337055000075, -0.988257158999943],
              [42.017257788000052, -0.98846044599992],
              [42.014101614000083, -0.991160100999934],
              [42.01287996800005, -0.992286140999909],
              [42.011700968000071, -0.993620152999938],
              [42.010448364000069, -0.994622369999945],
              [42.007962605000046, -0.996061790999931],
              [42.005253795000044, -0.998199100999955],
              [42.001490471000068, -1.000582766999912],
              [41.999471705000076, -1.001589832999912],
              [41.99650927600004, -1.003659963999951],
              [41.994831756000053, -1.005385072999957],
              [41.993903766000074, -1.006491522999909],
              [41.992807508000055, -1.00682500399995],
              [41.992063539000071, -1.007335922999914],
              [41.992009833000054, -1.007372805999921],
              [41.990878075000069, -1.008625344999928],
              [41.989593166000077, -1.010481323999954],
              [41.988831738000044, -1.012765606999949],
              [41.987737186000061, -1.015287835999914],
              [41.986737812000058, -1.017048636999959],
              [41.985881206000045, -1.018761848999929],
              [41.985119779000058, -1.020998541999916],
              [41.984596298000042, -1.022283450999907],
              [41.984215584000083, -1.023520770999937],
              [41.982787907000045, -1.023663537999937],
              [41.981215990000067, -1.023493252999913],
              [41.979840349000085, -1.023285549999912],
              [41.979687623000075, -1.022698580999929],
              [41.979206052000052, -1.020847758999935],
              [41.977295413000036, -1.02051617199993],
              [41.975887470000089, -1.020046757999921],
              [41.973365242000057, -1.019428097999935],
              [41.970033996000041, -1.017334171999948],
              [41.968273195000052, -1.015906495999957],
              [41.967610722000074, -1.015735728999914],
              [41.964945726000053, -1.016425771999934],
              [41.965808809000066, -1.01767755599991],
              [41.968035249000081, -1.02009434699994],
              [41.971265377000066, -1.022560334999923],
              [41.974263498000084, -1.025701223999931],
              [41.975215282000079, -1.026748185999907],
              [41.976309834000062, -1.029032468999958],
              [41.976491345000056, -1.02937733899995],
              [41.977261619000046, -1.030840858999909],
              [41.977737511000043, -1.031745053999941],
              [41.978023046000089, -1.033315498999912],
              [41.978546528000038, -1.033838979999928],
              [41.979260366000062, -1.036361208999949],
              [41.979212777000043, -1.038312366999946],
              [41.977283231000058, -1.041172166999957],
              [41.977118851000057, -1.042595396999957],
              [41.974174258000062, -1.044840740999916],
              [41.967606946000046, -1.053549568999927],
              [41.967178643000068, -1.058260900999926],
              [41.966179269000065, -1.063448125999912],
              [41.967035875000079, -1.065589640999917],
              [41.967749714000036, -1.06915883299996],
              [41.96698828600006, -1.071538293999936],
              [41.96381800100005, -1.075351591999947],
              [41.962300748000075, -1.076880183999947],
              [41.96039717900004, -1.079783125999938],
              [41.956804193000039, -1.082257765999941],
              [41.95706593400007, -1.083566468999948],
              [41.957280085000036, -1.084042360999945],
              [41.957613210000034, -1.084827583999925],
              [41.957232496000074, -1.085874545999957],
              [41.95744664700004, -1.086683562999951],
              [41.956994550000047, -1.08765914199995],
              [41.954734062000057, -1.091085565999947],
              [41.951545584000087, -1.095677925999951],
              [41.949308891000044, -1.09915193899991],
              [41.948190544000056, -1.100912739999956],
              [41.946239386000059, -1.103101843999923],
              [41.943764747000046, -1.105124385999943],
              [41.941884972000082, -1.106813802999909],
              [41.940314528000044, -1.108622193999906],
              [41.938982030000034, -1.110501967999937],
              [41.937459175000072, -1.112167590999945],
              [41.936102882000057, -1.113785623999945],
              [41.934960741000054, -1.115641603999961],
              [41.934318286000064, -1.118259010999907],
              [41.934056546000079, -1.119282178999924],
              [41.933128556000042, -1.121471282999948],
              [41.932061575000034, -1.124123746999942],
              [41.931391549000068, -1.125635339999917],
              [41.930534943000055, -1.127158194999936],
              [41.929511775000037, -1.128014800999949],
              [41.928941626000039, -1.130873949999909],
              [41.928298250000068, -1.134344166999938],
              [41.928599459000054, -1.137650982999958],
              [41.928599459000054, -1.137996003999945],
              [41.928643272000045, -1.138341661999959],
              [41.928762245000087, -1.138746169999933],
              [41.928750348000051, -1.139007910999908],
              [41.928750348000051, -1.139245856999935],
              [41.928441018000058, -1.13950759699992],
              [41.928131688000065, -1.139602775999947],
              [41.927346466000074, -1.139602775999947],
              [41.92663262700006, -1.139341034999916],
              [41.926109146000044, -1.138912731999937],
              [41.925323924000054, -1.138413044999936],
              [41.924919415000033, -1.137841974999958],
              [41.923967631000039, -1.137627822999946],
              [41.923206204000053, -1.137627822999946],
              [41.921992678000038, -1.137913358999924],
              [41.921397813000056, -1.138246482999932],
              [41.920755359000054, -1.138936526999942],
              [41.920184288000087, -1.139531391999924],
              [41.919637012000067, -1.14015005199991],
              [41.919303888000059, -1.140887684999939],
              [41.91887558500008, -1.142125004999912],
              [41.918804201000057, -1.143195761999948],
              [41.918590049000045, -1.1436478599999],
              [41.918304514000056, -1.143862010999953],
              [41.918042773000082, -1.144218929999909],
              [41.91792380000004, -1.144694821999906],
              [41.918185541000071, -1.144956562999937],
              [41.918209335000086, -1.145289687999934],
              [41.918161746000067, -1.145670400999961],
              [41.917876211000078, -1.145979730999954],
              [41.917662059000065, -1.145979730999954],
              [41.917019605000064, -1.145741784999927],
              [41.916686480000067, -1.146051114999921],
              [41.916162999000051, -1.146455623999941],
              [41.915568134000068, -1.146455623999941],
              [41.914616349000084, -1.146146293999948],
              [41.914164252000035, -1.146550801999922],
              [41.913854922000041, -1.147074282999938],
              [41.913569386000063, -1.147526380999921],
              [41.912855548000039, -1.14781191599991],
              [41.911951353000063, -1.148026067999922],
              [41.912189299000033, -1.148430575999953],
              [41.912141710000071, -1.149049235999939],
              [41.911475461000066, -1.149263387999952],
              [41.911047158000088, -1.149239592999947],
              [41.910452293000048, -1.149310976999914],
              [41.909976400000062, -1.149786868999911],
              [41.90938153500008, -1.150262760999908],
              [41.908762875000036, -1.150262760999908],
              [41.908310778000043, -1.150524501999939],
              [41.907620734000034, -1.151000393999936],
              [41.907620734000034, -1.151381107999953],
              [41.907335199000045, -1.151571464999961],
              [41.906787923000081, -1.151571464999961],
              [41.906169263000038, -1.151595258999919],
              [41.906240647000061, -1.151975972999935],
              [41.906002700000045, -1.152356686999951],
              [41.905717165000056, -1.152356686999951],
              [41.905098505000069, -1.152332891999947],
              [41.904027748000033, -1.152975346999938],
              [41.902956990000064, -1.153641595999943],
              [41.902124179000054, -1.154545790999919],
              [41.901463429000046, -1.155675459999941],
              [41.901386546000083, -1.155806904999906],
              [41.901386546000083, -1.15594809199996],
              [41.901386546000083, -1.156615921999958],
              [41.901410341000087, -1.157282170999906],
              [41.901886233000084, -1.156925251999951],
              [41.902385920000086, -1.156496948999916],
              [41.902719044000037, -1.156925251999951],
              [41.902481098000067, -1.157234581999944],
              [41.902266947000044, -1.157496322999918],
              [41.90186243800008, -1.158019803999935],
              [41.901933822000046, -1.158614668999917],
              [41.90171967100008, -1.15909056199996],
              [41.901338957000064, -1.159637837999924],
              [41.900696502000073, -1.159875783999951],
              [41.900257803000045, -1.159950683999909],
              [41.899720923000075, -1.160042345999955],
              [41.89876913900008, -1.160375470999952],
              [41.898269452000079, -1.160875156999907],
              [41.897460435000085, -1.161588995999921],
              [41.896722803000046, -1.162255244999926],
              [41.896223116000044, -1.162945287999946],
              [41.895985170000074, -1.163825688999907],
              [41.896056553000051, -1.164634705999958],
              [41.89619932100004, -1.16527716],
              [41.896413473000052, -1.165967203999912],
              [41.896151732000078, -1.166347917999929],
              [41.896175527000082, -1.167252112999961],
              [41.896318294000082, -1.167870772999947],
              [41.896794186000079, -1.168608405999919],
              [41.896914475000074, -1.16872406899995],
              [41.897412846000066, -1.169203270999958],
              [41.898079095000071, -1.169821930999944],
              [41.898554988000058, -1.170321617999946],
              [41.89914985300004, -1.170678536999958],
              [41.899506772000052, -1.171225812999921],
              [41.89969712900006, -1.171487552999906],
              [41.900101637000034, -1.171915855999941],
              [41.899554361000071, -1.172225185999935],
              [41.899102264000078, -1.172534515999928],
              [41.898340836000045, -1.173081791999948],
              [41.897888738000063, -1.173414916999945],
              [41.897198695000043, -1.173938397999962],
              [41.897079722000058, -1.17436670099994],
              [41.896437267000067, -1.174795003999918],
              [41.896437267000067, -1.175199512999939],
              [41.89674659700006, -1.175699198999951],
              [41.897151106000081, -1.176008528999944],
              [41.897508025000036, -1.176032323999948],
              [41.898126685000079, -1.175889555999959],
              [41.898626371000034, -1.175604020999913],
              [41.899173647000055, -1.17515192299993],
              [41.899768513000083, -1.174818798999922],
              [41.900149226000053, -1.1744618799999],
              [41.900149226000053, -1.173938397999962],
              [41.90062511900004, -1.173224559999937],
              [41.900886859000082, -1.172463131999962],
              [41.90117239500006, -1.172130007999954],
              [41.901101011000037, -1.171725499999923],
              [41.901077216000033, -1.171320990999959],
              [41.901362752000068, -1.170868893999909],
              [41.901505519000068, -1.170345411999961],
              [41.902029001000074, -1.170083670999929],
              [41.902695250000079, -1.170297822999942],
              [41.903551856000036, -1.169203270999958],
              [41.904789175000076, -1.168346664999945],
              [41.904661560000079, -1.169574343999955],
              [41.903448556000058, -1.171808144999943],
              [41.901663960000064, -1.174425551999946],
              [41.899935075000087, -1.176413037999907],
              [41.898745344000076, -1.177269643999921],
              [41.900434762000089, -1.178078659999926],
              [41.899681734000069, -1.179106673999911],
              [41.89891190700007, -1.179434952999941],
              [41.897912533000067, -1.178007276999949],
              [41.89674659700006, -1.178364195999961],
              [41.895652045000077, -1.176960313999928],
              [41.89393883300005, -1.177935892999926],
              [41.892606335000039, -1.179078033999929],
              [41.891702140000064, -1.180696067999918],
              [41.891511783000055, -1.181719235999935],
              [41.891654551000045, -1.182837581999934],
              [41.891394301000048, -1.183795495999959],
              [41.890347338000083, -1.184318976999919],
              [41.88934796500007, -1.184937636999962],
              [41.888277207000044, -1.184485538999922],
              [41.887610958000039, -1.185508707999929],
              [41.886920914000086, -1.185532501999944],
              [41.886373638000066, -1.186603259999913],
              [41.885873951000065, -1.188720979999914],
              [41.885540827000057, -1.190339012999914],
              [41.885350470000049, -1.19195704699996],
              [41.884993551000036, -1.193170571999929],
              [41.884398686000054, -1.193836820999934],
              [41.883068473000037, -1.194091797999931],
              [41.882352349000087, -1.195002756999941],
              [41.881109990000084, -1.195875427999908],
              [41.881069725000089, -1.196935253999925],
              [41.880765378000035, -1.197192187999917],
              [41.880258424000033, -1.197620163999943],
              [41.879368411000087, -1.197779961999913],
              [41.878759363000086, -1.198429180999938],
              [41.877307892000033, -1.199071634999939],
              [41.876620133000074, -1.199564557999906],
              [41.875904010000056, -1.200998998999921],
              [41.875430372000039, -1.203364584999917],
              [41.874714279000045, -1.204163681999944],
              [41.874738074000049, -1.205972071999952],
              [41.874833252000087, -1.207661489999907],
              [41.875428118000059, -1.209160549999922],
              [41.875689858000044, -1.21082617299993],
              [41.875832520000074, -1.211383851999926],
              [41.875951599000075, -1.211849340999947],
              [41.875689858000044, -1.21244420599993],
              [41.875071199000047, -1.211992108999937],
              [41.874357360000033, -1.213300811999943],
              [41.87459530600006, -1.214728488999924],
              [41.875233717000071, -1.215234814999917],
              [41.87528535000007, -1.215275764999944],
              [41.875737448000052, -1.215156791999959],
              [41.876094367000064, -1.216322727999909],
              [41.87754583800006, -1.215299558999959],
              [41.878450033000036, -1.215180585999917],
              [41.879663558000061, -1.215323353999906],
              [41.880424986000037, -1.215466121999953],
              [41.881329181000069, -1.214918845999932],
              [41.879639764000046, -1.214133622999952],
              [41.878997309000056, -1.21322942799992],
              [41.879211461000068, -1.213134249999939],
              [41.881852662000085, -1.213800498999944],
              [41.881138824000061, -1.212896303999912],
              [41.879687353000065, -1.212468000999934],
              [41.87833106000005, -1.212015902999951],
              [41.878116909000084, -1.211468626999931],
              [41.87847382800004, -1.211254475999908],
              [41.879449407000038, -1.211658983999939],
              [41.880948467000053, -1.212158670999941],
              [41.882780652000065, -1.212230054999907],
              [41.883970383000076, -1.21151621599995],
              [41.884779399000081, -1.211111707999919],
              [41.884993551000036, -1.210350279999943],
              [41.883256544000062, -1.210397869999952],
              [41.882280965000064, -1.209803003999923],
              [41.879989527000077, -1.209907654999938],
              [41.880829494000068, -1.209089165999956],
              [41.881757484000047, -1.208922603999952],
              [41.884422480000069, -1.20894639799991],
              [41.881305386000065, -1.207233186999929],
              [41.881947841000056, -1.206828677999908],
              [41.882685474000084, -1.206685910999909],
              [41.886587790000078, -1.208446711999954],
              [41.887563369000077, -1.207947024999953],
              [41.889804635000075, -1.207651125999917],
              [41.890636068000049, -1.205937578999908],
              [41.891560863000052, -1.204853725999953],
              [41.892560237000055, -1.203949529999932],
              [41.894756943000061, -1.201697336999928],
              [41.896391169000083, -1.200308954999912],
              [41.897699873000079, -1.199119224999947],
              [41.897890230000087, -1.198405385999934],
              [41.898056792000034, -1.197858109999913],
              [41.899103755000056, -1.197596368999939],
              [41.900150718000077, -1.197072887999923],
              [41.900650404000089, -1.195978335999939],
              [41.900555226000051, -1.194574453999905],
              [41.901887724000062, -1.192623295999908],
              [41.903973739000037, -1.191174328999921],
              [41.907193922000033, -1.189672763999909],
              [41.908716777000052, -1.188911336999922],
              [41.908100663000084, -1.18972048899991],
              [41.907206496000072, -1.190897406999909],
              [41.906312329000059, -1.192489706999936],
              [41.905143034000048, -1.193805084999951],
              [41.905080788000078, -1.194180994999954],
              [41.902724125000077, -1.196143461999952],
              [41.901360021000073, -1.198408900999937],
              [41.901293015000078, -1.198543786999949],
              [41.900299739000047, -1.200543282999945],
              [41.898746303000053, -1.201697336999928],
              [41.897370662000071, -1.203358860999913],
              [41.895926238000072, -1.205158843999925],
              [41.894487600000048, -1.208137381999961],
              [41.893345459000045, -1.210040950999939],
              [41.891661751000072, -1.211735693999913],
              [41.890442517000054, -1.212610767999934],
              [41.889460725000049, -1.214020280999932],
              [41.888729305000084, -1.214633309999954],
              [41.887753726000085, -1.215156791999959],
              [41.887275781000085, -1.215843738999922],
              [41.886373638000066, -1.216441700999951],
              [41.885960797000052, -1.215692470999954],
              [41.885221885000078, -1.216089914999941],
              [41.883953668000061, -1.217678366999962],
              [41.882016693000082, -1.220479366999939],
              [41.880719462000059, -1.220883496999932],
              [41.878735568000081, -1.222152406999953],
              [41.879378023000072, -1.221176827999955],
              [41.88118641300008, -1.219463615999928],
              [41.880796087000078, -1.21908803599996],
              [41.878517483000053, -1.22053326799994],
              [41.878524378000066, -1.220597109999915],
              [41.87669151700004, -1.222205128999917],
              [41.875839169000074, -1.223149683999907],
              [41.874787709000088, -1.224619166999958],
              [41.874228775000063, -1.225964677999912],
              [41.87356305600008, -1.226360069999942],
              [41.872953031000065, -1.227035462999936],
              [41.872479871000053, -1.227761170999941],
              [41.871575676000077, -1.228058602999909],
              [41.871361524000065, -1.228736749999939],
              [41.870730967000043, -1.229200744999957],
              [41.870528713000056, -1.228915208999922],
              [41.871041658000081, -1.226433491999956],
              [41.872333993000041, -1.224681657999952],
              [41.872493129000077, -1.223387781999918],
              [41.873434505000034, -1.22170478399994],
              [41.874090766000052, -1.221456788999944],
              [41.873087994000059, -1.220246893999956],
              [41.87096891300007, -1.219572255999935],
              [41.868852554000057, -1.21839091399994],
              [41.866143607000083, -1.218070224999906],
              [41.858343757000057, -1.21833653799996],
              [41.85726457800007, -1.218771627999956],
              [41.856003464000082, -1.219199930999935],
              [41.854027151000082, -1.219781647999923],
              [41.852047440000035, -1.21989586299992],
              [41.849534729000084, -1.218258792999961],
              [41.848260272000061, -1.218900981999923],
              [41.846106077000059, -1.221882277999953],
              [41.846403157000054, -1.223227836999911],
              [41.845302644000071, -1.225235494999936],
              [41.846128029000056, -1.227866216999928],
              [41.845736862000081, -1.228445204999957],
              [41.846334375000083, -1.229527724999912],
              [41.848191490000033, -1.231120001999955],
              [41.847710016000065, -1.233681488999935],
              [41.84578411800004, -1.238389079999934],
              [41.843927003000033, -1.242335142999934],
              [41.841794759000038, -1.245381222999924],
              [41.840307179000035, -1.248399425999935],
              [41.83725514300005, -1.251611828999955],
              [41.834347812000033, -1.254911590999939],
              [41.833754326000076, -1.255479063999928],
              [41.833425977000047, -1.256012276999911],
              [41.831967168000062, -1.258381264999912],
              [41.830628607000051, -1.26010141699993],
              [41.828637247000074, -1.263297228999932],
              [41.828906500000073, -1.263929388999941],
              [41.829006612000057, -1.264164433999952],
              [41.828909967000072, -1.265109107999933],
              [41.827721864000068, -1.266525158999912],
              [41.826356819000068, -1.269961860999956],
              [41.82612559100005, -1.271833547999961],
              [41.824459301000047, -1.273717189999957],
              [41.823579568000071, -1.274343878999957],
              [41.822952236000049, -1.275229328999956],
              [41.821555072000081, -1.277188569999907],
              [41.820880579000061, -1.278939039999955],
              [41.820157908000056, -1.27959747299991],
              [41.819493441000077, -1.280420618999926],
              [41.819011003000071, -1.281018266999922],
              [41.818469770000036, -1.282076157999938],
              [41.818018355000049, -1.282610223999939],
              [41.817238304000057, -1.283588084999906],
              [41.815937343000087, -1.285790041999917],
              [41.81489909700008, -1.287376498999947],
              [41.812817069000062, -1.289755959999923],
              [41.811517975000072, -1.290871139999922],
              [41.809445570000037, -1.292796598999928],
              [41.80782432400008, -1.294532672999935],
              [41.805720548000068, -1.296780981999916],
              [41.804644572000086, -1.298129967999955],
              [41.803722966000066, -1.299732727999924],
              [41.800902742000062, -1.303654385999948],
              [41.799104094000086, -1.306834137999942],
              [41.798702610000078, -1.308632785999919],
              [41.799216510000065, -1.309548168999925],
              [41.800228249000043, -1.308825497999919],
              [41.801239988000077, -1.309548168999925],
              [41.801223929000059, -1.310431433999952],
              [41.799927726000078, -1.310999475999949],
              [41.797786211000073, -1.314199850999955],
              [41.796679761000064, -1.31621049499995],
              [41.795160524000039, -1.319247535999921],
              [41.794541485000082, -1.32111666499992],
              [41.794679049000081, -1.322224295999945],
              [41.794825744000036, -1.322181232999924],
              [41.794597166000074, -1.322548787999949],
              [41.794833806000042, -1.322696149999956],
              [41.795173539000075, -1.322907709999924],
              [41.795554253000034, -1.323098066999933],
              [41.795530458000087, -1.323217039999918],
              [41.795197334000079, -1.323478779999959],
              [41.794721442000082, -1.323859493999919],
              [41.794388317000084, -1.324525742999924],
              [41.794031398000072, -1.324097439999946],
              [41.793996212000081, -1.324043307999943],
              [41.793722068000079, -1.323621547999949],
              [41.792722694000076, -1.323431190999941],
              [41.791770910000082, -1.323526369999911],
              [41.791176045000043, -1.323835698999915],
              [41.79100948200005, -1.323930877999942],
              [41.790509796000038, -1.324454358999958],
              [41.789867341000047, -1.325096813999949],
              [41.789320065000084, -1.325953419999962],
              [41.788487254000074, -1.32707176599996],
              [41.788089907000085, -1.327822264999952],
              [41.787559264000038, -1.328761183999916],
              [41.787154756000064, -1.32971296799991],
              [41.786607480000043, -1.331093055999929],
              [41.785877008000057, -1.332664467999962],
              [41.785227392000081, -1.333972202999917],
              [41.784846678000065, -1.334709835999945],
              [41.784442170000034, -1.33575679899991],
              [41.784061456000074, -1.337660367999945],
              [41.78403766200006, -1.339421168999934],
              [41.784318596000048, -1.33960845799993],
              [41.784394581000072, -1.339659114999961],
              [41.784941857000035, -1.339754293999931],
              [41.785584311000036, -1.340063623999924],
              [41.786131587000057, -1.340563309999936],
              [41.786726453000085, -1.341158175999908],
              [41.787011988000074, -1.341729245999943],
              [41.786702658000081, -1.342181343999926],
              [41.786369533000084, -1.343061743999954],
              [41.786083998000038, -1.343418663999955],
              [41.785608106000041, -1.343252100999962],
              [41.78482288400005, -1.343347279999932],
              [41.783871099000066, -1.343347279999932],
              [41.782538601000056, -1.343751787999906],
              [41.78163440600008, -1.344132501999923],
              [41.781499645000054, -1.344142362999946],
              [41.780658827000082, -1.344203885999946],
              [41.779873605000034, -1.344751161999909],
              [41.778898026000036, -1.345417410999914],
              [41.777922447000037, -1.346345400999951],
              [41.777184814000066, -1.347154416999956],
              [41.776304413000048, -1.348058611999932],
              [41.775376424000058, -1.349272137999947],
              [41.774971915000037, -1.350104948999956],
              [41.774377050000055, -1.351128116999917],
              [41.773663212000088, -1.352056106999953],
              [41.773139730000082, -1.353483783999934],
              [41.772759016000066, -1.355339762999961],
              [41.772552027000074, -1.356494769999927],
              [41.772045178000042, -1.357290920999958],
              [41.771093394000047, -1.357814402999907],
              [41.769570539000085, -1.358337883999923],
              [41.767595586000084, -1.359337257999925],
              [41.76595375800008, -1.360645960999932],
              [41.764216751000049, -1.361835691999943],
              [41.762884253000038, -1.363144394999949],
              [41.76176590700004, -1.364357919999918],
              [41.761361398000076, -1.3647148399999],
              [41.760671355000056, -1.365571444999944],
              [41.760005105000062, -1.366451845999961],
              [41.75936265100006, -1.367284656999914],
              [41.758506045000047, -1.368426798999906],
              [41.757982564000088, -1.369116841999926],
              [41.757292520000078, -1.370330367999941],
              [41.756626271000073, -1.371710454999914],
              [41.75602667000004, -1.372472850999941],
              [41.755300934000047, -1.373496018999958],
              [41.754556140000034, -1.37449442399992],
              [41.753628150000054, -1.375398618999952],
              [41.752581187000089, -1.376255224999909],
              [41.751867349000065, -1.377040447999946],
              [41.751224894000075, -1.377706696999951],
              [41.750606235000078, -1.378277766999929],
              [41.749797218000083, -1.37892022199992],
              [41.748845433000042, -1.379657854999948],
              [41.748488514000087, -1.380038567999918],
              [41.748345747000087, -1.380538254999919],
              [41.748084006000056, -1.381109325999944],
              [41.747441551000065, -1.382608385999959],
              [41.747084632000053, -1.383036688999937],
              [41.746608740000056, -1.383369813999934],
              [41.746180437000078, -1.38375052799995],
              [41.745633161000057, -1.384321597999929],
              [41.745276242000045, -1.384749900999907],
              [41.744681377000063, -1.385154409999927],
              [41.744205484000076, -1.385773068999924],
              [41.743800976000045, -1.386248961999911],
              [41.743348878000063, -1.386772442999927],
              [41.742754013000081, -1.387557664999918],
              [41.742373299000064, -1.38831909299995],
              [41.74220673700006, -1.388771189999943],
              [41.741992586000038, -1.389960920999954],
              [41.741802229000086, -1.390555785999936],
              [41.741635667000082, -1.390936499999953],
              [41.741135980000081, -1.39141239199995],
              [41.740469731000076, -1.392078640999955],
              [41.739898660000051, -1.392768684999908],
              [41.738589956000055, -1.394148771999937],
              [41.738066475000039, -1.394815020999943],
              [41.737352637000072, -1.395814394999945],
              [41.736662593000062, -1.39676617899994],
              [41.736258085000088, -1.397670373999915],
              [41.736115317000042, -1.39809867699995],
              [41.736091522000038, -1.398598363999952],
              [41.73630567400005, -1.398931488999949],
              [41.736733977000085, -1.399121845999957],
              [41.737090896000041, -1.399478764999913],
              [41.737162280000064, -1.399978451999914],
              [41.737067101000036, -1.400573316999953],
              [41.737067101000036, -1.400930235999908],
              [41.737162280000064, -1.401406127999905],
              [41.737305047000063, -1.401691663999941],
              [41.737376431000087, -1.401691663999941],
              [41.737519199000076, -1.401477511999929],
              [41.737876118000088, -1.401263360999906],
              [41.738304421000066, -1.401191976999939],
              [41.73870892900004, -1.401191976999939],
              [41.739018259000034, -1.401382333999948],
              [41.739208616000042, -1.401763046999918],
              [41.739421873000083, -1.402107538999928],
              [41.739517946000035, -1.402262733999919],
              [41.739851071000089, -1.402381706999961],
              [41.740315066000051, -1.4024590399999],
              [41.740875546000041, -1.403262106999932],
              [41.741814126000065, -1.403601180999942],
              [41.741755947000058, -1.403975945999946],
              [41.74156559000005, -1.404071123999927],
              [41.740891480000073, -1.404118355999913],
              [41.739240711000036, -1.404810601999941],
              [41.738415326000052, -1.405987417999938],
              [41.737495404000072, -1.406447610999919],
              [41.737315952000074, -1.404997718999937],
              [41.737233664000087, -1.404332864999958],
              [41.736139111000057, -1.404282301999956],
              [41.735187327000062, -1.404996139999923],
              [41.734759024000084, -1.405495826999925],
              [41.735330095000052, -1.405995513999926],
              [41.73483040800005, -1.406471405999923],
              [41.73497317600004, -1.407328011999937],
              [41.734233655000082, -1.408132760999933],
              [41.73404518600006, -1.407851492999953],
              [41.734150839000051, -1.407025784999917],
              [41.732879250000053, -1.40856533199991],
              [41.73149916300008, -1.410302337999951],
              [41.730594967000059, -1.411492068999962],
              [41.729548005000083, -1.412824566999916],
              [41.728811679000046, -1.413969681999959],
              [41.727156262000051, -1.415607441999953],
              [41.726193313000067, -1.416922701999908],
              [41.724836672000038, -1.418416299999933],
              [41.724194217000047, -1.419344289999913],
              [41.723599352000065, -1.420034333999922],
              [41.723242433000053, -1.420724376999942],
              [41.72279033500007, -1.421438215999956],
              [41.722362032000035, -1.422033080999938],
              [41.721434043000045, -1.423151427999926],
              [41.720553642000084, -1.423889059999908],
              [41.719958777000045, -1.424626692999936],
              [41.719507987000043, -1.425319710999929],
              [41.719174862000045, -1.426176316999943],
              [41.718960711000079, -1.426913949999914],
              [41.718792841000038, -1.427485020999939],
              [41.718340743000056, -1.427865734999955],
              [41.718007619000048, -1.428817518999949],
              [41.717222397000057, -1.430123247999916],
              [41.71688927200006, -1.431455745999926],
              [41.716294407000078, -1.432931011999926],
              [41.716389585000059, -1.434311099999945],
              [41.717412753000076, -1.434620429999939],
              [41.717841056000054, -1.435024937999913],
              [41.717505581000069, -1.436238318999926],
              [41.717198602000053, -1.436690560999921],
              [41.716532353000048, -1.437999263999927],
              [41.715648465000072, -1.439907164999909],
              [41.713860132000036, -1.441430080999908],
              [41.714041081000062, -1.442286236999962],
              [41.713636572000041, -1.442678847999957],
              [41.713053604000038, -1.442000700999927],
              [41.711316598000053, -1.443416480999929],
              [41.711498555000048, -1.443661384999928],
              [41.711340392000068, -1.444011345999911],
              [41.710721733000071, -1.443880475999947],
              [41.709056110000063, -1.445189178999954],
              [41.708306580000055, -1.44580783899994],
              [41.706018977000042, -1.448490857999957],
              [41.705586832000051, -1.449135141999932],
              [41.704182950000074, -1.451419423999937],
              [41.702279381000039, -1.454108214999906],
              [41.699971304000087, -1.457249103999914],
              [41.698496038000087, -1.459723742999927],
              [41.697829789000082, -1.46205561499994],
              [41.696925594000049, -1.463554674999955],
              [41.695521712000073, -1.465577216999918],
              [41.694427160000089, -1.4666241799999],
              [41.693261224000082, -1.467718731999923],
              [41.691833548000034, -1.46888466799993],
              [41.690762790000065, -1.469884041999933],
              [41.689550573000076, -1.471243307999941],
              [41.688359535000075, -1.473048724999956],
              [41.687241188000087, -1.475047471999915],
              [41.686218020000069, -1.477022424999916],
              [41.684742754000069, -1.47921152899994],
              [41.683077131000061, -1.482423800999925],
              [41.681637558000034, -1.484065628999929],
              [41.678987230000075, -1.488012829999946],
              [41.677747139000076, -1.491465752999943],
              [41.678836955000065, -1.492469068999924],
              [41.67893686900004, -1.493821418999914],
              [41.677985085000046, -1.495772576999912],
              [41.677437809000082, -1.496986102999927],
              [41.676081516000067, -1.497604761999924],
              [41.675082142000065, -1.499056233999909],
              [41.674261228000034, -1.500430371999926],
              [41.673511698000084, -1.502500502999908],
              [41.673083395000049, -1.503642644999957],
              [41.67278688600004, -1.504743118999954],
              [41.672585250000054, -1.505047504999936],
              [41.672352924000052, -1.505472823999924],
              [41.672067388000073, -1.505924920999917],
              [41.671734264000065, -1.506293737999954],
              [41.671365447000085, -1.506555478999928],
              [41.670882394000046, -1.507024453999918],
              [41.670068641000057, -1.507887976999939],
              [41.667844935000062, -1.509579476999932],
              [41.66681320400005, -1.510548573999927],
              [41.666409007000084, -1.510879179999961],
              [41.666123472000038, -1.511140920999935],
              [41.665623785000037, -1.511640607999936],
              [41.665346088000035, -1.512042706999921],
              [41.66429583300004, -1.513026773999911],
              [41.664749742000083, -1.513732748999928],
              [41.664846810000085, -1.513996504999909],
              [41.664956691000043, -1.514077597999915],
              [41.665225539000062, -1.514314341999921],
              [41.665527993000069, -1.514584905999925],
              [41.665595205000045, -1.514889289999928],
              [41.665628810000044, -1.51522749499992],
              [41.665595205000045, -1.5155995199999],
              [41.665393569000059, -1.515768622999929],
              [41.665292751000038, -1.516005365999945],
              [41.665191933000074, -1.516208288999906],
              [41.664990297000088, -1.516242108999961],
              [41.664697801000045, -1.51628416099993],
              [41.664612178000084, -1.516363149999961],
              [41.664337050000086, -1.518716663999953],
              [41.663098973000046, -1.518855105999933],
              [41.663303810000059, -1.515049185999942],
              [41.662637561000054, -1.514906417999953],
              [41.661614393000036, -1.515548872999943],
              [41.660971938000046, -1.516786191999927],
              [41.660622181000065, -1.5188216],
              [41.660967203000041, -1.519071442999916],
              [41.661528880000049, -1.520165279999958],
              [41.661360850000051, -1.520841687999962],
              [41.661024790000056, -1.521382813999935],
              [41.660621519000074, -1.5218901199999],
              [41.660117429000081, -1.52243124599994],
              [41.659411703000046, -1.523344395999914],
              [41.658806795000089, -1.524223724999956],
              [41.658033858000067, -1.525576537999939],
              [41.657563374000063, -1.526794068999948],
              [41.657328132000089, -1.527402834999918],
              [41.657126496000046, -1.527977779999958],
              [41.656819779000045, -1.528296834999935],
              [41.656756830000063, -1.529026207999948],
              [41.65670080600006, -1.529605538999931],
              [41.655887054000061, -1.530290601999923],
              [41.65553013400006, -1.531087721999938],
              [41.655143743000053, -1.531596546999936],
              [41.654841289000046, -1.532171490999929],
              [41.654639653000061, -1.532712613999934],
              [41.654542658000082, -1.532931803999929],
              [41.654468688000065, -1.533187430999931],
              [41.654197636000049, -1.534121533999951],
              [41.653274382000063, -1.535173476999944],
              [41.652608133000058, -1.536220439999909],
              [41.652044224000065, -1.537024476999932],
              [41.650057374000085, -1.53892804599991],
              [41.647094945000049, -1.541676322999933],
              [41.645786816000054, -1.54393048299994],
              [41.645480310000039, -1.545315550999931],
              [41.645608785000036, -1.546552120999934],
              [41.645755285000064, -1.54773703099994],
              [41.644982470000059, -1.549234032999948],
              [41.64332823500007, -1.55161555299992],
              [41.640877390000071, -1.554685057999961],
              [41.638279042000079, -1.557190408999929],
              [41.63739130600004, -1.55827813399992],
              [41.637174320000042, -1.558486258999949],
              [41.635850805000075, -1.559755725999935],
              [41.635683835000066, -1.559915877999913],
              [41.632075756000063, -1.562001065999937],
              [41.629115828000067, -1.565180971999951],
              [41.627542012000049, -1.567316865999942],
              [41.627670486000056, -1.568135892999919],
              [41.627278597000043, -1.56911657899991],
              [41.626952808000055, -1.56926617299996],
              [41.626457683000069, -1.571507937999911],
              [41.626174692000063, -1.572565872999917],
              [41.625413265000077, -1.572756229999925],
              [41.625870153000051, -1.573703867999939],
              [41.625365676000058, -1.574231495999925],
              [41.625127730000088, -1.57504051199993],
              [41.625234415000079, -1.57542709899991],
              [41.62535856900007, -1.576071431999935],
              [41.624961167000038, -1.576753723999957],
              [41.624770811000076, -1.577277205999906],
              [41.624604248000082, -1.577729302999956],
              [41.624580454000068, -1.578252784999961],
              [41.62448527500004, -1.578585908999912],
              [41.624014173000035, -1.57931939599996],
              [41.624430579000034, -1.579640622999932],
              [41.624216428000068, -1.579997541999944],
              [41.623681049000083, -1.581115888999932],
              [41.623432364000053, -1.582045050999909],
              [41.623105188000068, -1.582788631999961],
              [41.623103220000075, -1.582792029999951],
              [41.622778012000083, -1.583353753999916],
              [41.622408037000071, -1.583578630999909],
              [41.62144435600004, -1.5839831399999],
              [41.620428294000078, -1.584335281999927],
              [41.620402423000087, -1.584339593999914],
              [41.619892915000037, -1.584424511999941],
              [41.61594301000008, -1.585438756999906],
              [41.613080550000063, -1.586886081999921],
              [41.611336382000047, -1.588336584999922],
              [41.609837321000043, -1.590549483999951],
              [41.609266251000065, -1.591929570999923],
              [41.608270786000048, -1.594842598999946],
              [41.606513238000048, -1.597308120999912],
              [41.604737728000089, -1.598969853999961],
              [41.604223829000034, -1.599451633999934],
              [41.603196030000049, -1.600575788999947],
              [41.602294430000086, -1.601447414999939],
              [41.601076169000066, -1.602554832999942],
              [41.599779363000039, -1.603958714999919],
              [41.598672914000076, -1.605077060999918],
              [41.597518875000048, -1.606933040999934],
              [41.595650998000053, -1.608991274999937],
              [41.59428280800006, -1.61143022199991],
              [41.592795645000081, -1.614095218999921],
              [41.592093704000035, -1.615927403999933],
              [41.590178238000078, -1.618866037999908],
              [41.588738664000061, -1.621126525999955],
              [41.588179491000062, -1.622399537999911],
              [41.587656009000057, -1.624909868999907],
              [41.587358577000089, -1.627063280999948],
              [41.586823198000047, -1.629835352999919],
              [41.587263398000061, -1.632571732999907],
              [41.588203285000077, -1.636021951999908],
              [41.589476297000033, -1.637866033999956],
              [41.589940292000051, -1.63840141299994],
              [41.590469816000052, -1.638448032999918],
              [41.591260893000083, -1.637687574999916],
              [41.592040261000079, -1.638947719999919],
              [41.592516153000076, -1.638471827999922],
              [41.59375347200006, -1.638448032999918],
              [41.594610078000073, -1.636877588999937],
              [41.595799809000084, -1.635283349999952],
              [41.596442263000085, -1.633332191999955],
              [41.596861851000085, -1.633066552999935],
              [41.598375057000055, -1.632443598999942],
              [41.598099928000067, -1.633620289999953],
              [41.596861851000085, -1.63590445299991],
              [41.595014587000037, -1.638329059999933],
              [41.594248133000065, -1.639157649999959],
              [41.594205570000042, -1.640161244999945],
              [41.593491732000075, -1.640470574999938],
              [41.593087223000055, -1.641565126999922],
              [41.591897493000033, -1.64189825099993],
              [41.591064682000081, -1.643183159999921],
              [41.59010773600005, -1.644241788999921],
              [41.589607167000054, -1.645016313999918],
              [41.589344887000038, -1.645305914999938],
              [41.589036097000076, -1.645646871999929],
              [41.588346053000066, -1.646408298999916],
              [41.588012928000069, -1.647098342999925],
              [41.586751814000081, -1.646943677999957],
              [41.587042085000064, -1.645515032999924],
              [41.587779718000036, -1.64230276],
              [41.587926093000078, -1.641895],
              [41.588112842000044, -1.64137477099996],
              [41.588422172000037, -1.640708521999954],
              [41.587156322000055, -1.640031343999908],
              [41.58715017600008, -1.64002962],
              [41.585626306000051, -1.639602071999946],
              [41.583901196000056, -1.639923298999918],
              [41.582925617000058, -1.640089861999911],
              [41.582092806000048, -1.640208834999953],
              [41.581241908000038, -1.640444189999926],
              [41.580974459000061, -1.640518164999946],
              [41.580308210000055, -1.640851288999954],
              [41.580022675000066, -1.641041645999906],
              [41.579380221000065, -1.641255797999918],
              [41.578951918000087, -1.641398564999918],
              [41.57723870600006, -1.642136197999946],
              [41.574787861000061, -1.644158739999909],
              [41.573288800000057, -1.645919540999955],
              [41.572003891000065, -1.648251412999912],
              [41.571075902000075, -1.650559489999921],
              [41.570451765000087, -1.651618911999947],
              [41.569213688000048, -1.652864808999936],
              [41.568410905000064, -1.653295869999909],
              [41.567257966000057, -1.65423822799994],
              [41.567280028000084, -1.652209171999914],
              [41.567943568000089, -1.591184262999946],
              [41.535392701000035, -1.547965089999934],
              [41.50441363300007, -1.506827983999926],
              [41.495613123000055, -1.492408677999947],
              [41.491939434000074, -1.490139641999917],
              [41.485244778000038, -1.48168252399995],
              [41.481296537000048, -1.473896420999949],
              [41.466766402000076, -1.457221144999949],
              [41.459632843000065, -1.447811070999933],
              [41.445979974000068, -1.429789889999938],
              [41.398384018000058, -1.36347812899993],
              [41.392166111000051, -1.357319240999914],
              [41.389602610000054, -1.351130978999947],
              [41.385589674000073, -1.346062726999946],
              [41.351756967000085, -1.303367336999941],
              [41.308967507000034, -1.245905463999918],
              [41.291492364000078, -1.22243614599995],
              [41.271854330000053, -1.193849198999942],
              [41.234027969000067, -1.144497718999958],
              [41.195476619000033, -1.094189678999953],
              [41.168453181000075, -1.055315390999908],
              [41.159072842000057, -1.043122320999942],
              [41.086338095000087, -0.948573852999914],
              [41.082439322000084, -0.943010921999928],
              [41.033473944000036, -0.873113684999907],
              [41.025886433000039, -0.866044731999921],
              [41.007236586000033, -0.842077670999913],
              [41.000987953000049, -0.834049544999914],
              [40.999511755000071, -0.717891265999924],
              [40.998794515000043, -0.661483807999957],
              [40.998882188000039, -0.591543950999949],
              [40.998893722000048, -0.582215963999943],
              [40.999958047000064, -0.571866180999962],
              [40.998825061000048, -0.542005772999914],
              [41.000816392000047, -0.502928206999911],
              [40.999450663000061, -0.465360357999941],
              [40.998237574000086, -0.432192989999919],
              [40.999988593000069, -0.422072253999943],
              [40.997798850000038, -0.403710834999913],
              [41.000011570000083, -0.394048735999945],
              [40.999381913000036, -0.340070610999931],
              [41.001407574000041, -0.243583704999935],
              [41.000854597000057, -0.213330255999949],
              [41.000548881000043, -0.196500145999948],
              [40.999481209000066, -0.137722946999929],
              [41.000320361000036, -0.128070489999914],
              [40.999061588000075, -0.112007389999917],
              [41.000476784000057, -0.087654226999916],
              [41.000514989000067, -0.000000989999933],
              [40.988632242000051, 0.014012481000066],
              [40.98930749200008, 0.093185444000085],
              [40.990959215000089, 0.106642334000071],
              [40.989265413000055, 0.119313594000062],
              [40.989368403000071, 0.128975422000053],
              [40.990093302000048, 0.19721273600004],
              [40.989726933000043, 0.255182172000048],
              [40.989070875000039, 0.358227224000075],
              [40.989250095000045, 0.381408385000043],
              [40.990249635000055, 0.507647663000057],
              [40.991245390000074, 0.633906675000048],
              [40.991378746000066, 0.650817847000042],
              [40.990005539000038, 0.722352210000054],
              [40.99020377100004, 0.760168930000077],
              [40.990879021000069, 0.886463893000041],
              [40.99128728900007, 0.963060110000072],
              [40.990371818000085, 1.012762191000093],
              [40.990100961000053, 1.027148879000038],
              [40.990425070000072, 1.139086619000068],
              [40.990666850000082, 1.223529005000046],
              [40.990673130000062, 1.225722566000059],
              [40.990692399000068, 1.232406299000047],
              [40.990787564000073, 1.265417534000051],
              [40.991149968000059, 1.391768183000067],
              [40.991512282000087, 1.518128743000091],
              [40.991802332000077, 1.619202828000084],
              [40.991645819000041, 1.644502188000047],
              [40.990955341000074, 1.757638331000067],
              [40.991149968000059, 1.77088563500007],
              [40.991481737000072, 1.792802977000065],
              [40.993831687000068, 1.823881611000047],
              [40.99128728900007, 1.831670777000056],
              [40.990711155000042, 1.89678125100005],
              [40.990314421000051, 1.941991214000041],
              [40.991760163000038, 2.017510491000053],
              [40.991649513000084, 2.023167852000086],
              [40.990268737000065, 2.095262130000037],
              [40.991436053000086, 2.109327862000043],
              [40.991336847000071, 2.149518500000056],
              [40.99102012600008, 2.275941143000068],
              [40.990935989000036, 2.311639495000065],
              [40.990917136000064, 2.319638596000061],
              [40.992080758000043, 2.333334265000076],
              [40.992084452000086, 2.402324770000064],
              [40.992092111000034, 2.528763542000092],
              [40.992099770000038, 2.655209342000092],
              [40.992111123000086, 2.781664513000067],
              [40.992111123000086, 2.82956917000007],
              [41.048114729000076, 2.884392034000086],
              [41.081230107000067, 2.916806481000037],
              [41.133541010000044, 2.970752078000089],
              [41.13693627400005, 2.974009841000054],
              [41.227935758000058, 3.061420424000062],
              [41.260128096000074, 3.092332183000053],
              [41.285678940000082, 3.115215275000082],
              [41.311301205000063, 3.141422072000069],
              [41.318717908000053, 3.149007975000075],
              [41.328128793000076, 3.158637456000065],
              [41.392414081000084, 3.251365343000089],
              [41.464393564000034, 3.35516034300008],
              [41.53638836600004, 3.458952099000044],
              [41.592529382000066, 3.539863376000085],
              [41.609184205000076, 3.562176911000051],
              [41.610645265000073, 3.564138146000062],
              [41.661449515000072, 3.639192299000058],
              [41.680938695000066, 3.666138966000062],
              [41.749000483000088, 3.760232131000066],
              [41.75542833600008, 3.768149157000039],
              [41.76455304600006, 3.77940251800004],
              [41.79057703400008, 3.819478452000055],
              [41.828048740000042, 3.871443081000052],
              [41.829750111000067, 3.873800420000066],
              [41.83538440600006, 3.879222426000069],
              [41.853584679000051, 3.903759814000068],
              [41.858230860000049, 3.910023739000053],
              [41.859688938000033, 3.911989503000086],
              [41.860187555000039, 3.912661732000061],
              [41.87288278300008, 3.936615728000049],
              [41.899078332000045, 3.975493259000075],
              [41.904964310000082, 3.977384959000062],
              [41.906902369000079, 3.97800783200006],
              [41.908683455000073, 3.983225824000044],
              [41.908785562000048, 3.983287089000044],
              [41.909441934000085, 3.984152308000091],
              [41.910038637000071, 3.984928022000076],
              [41.910765873000059, 3.985966930000075],
              [41.911453044000041, 3.987382552000042],
              [41.911649736000072, 3.988478403000045],
              [41.911769076000041, 3.989850821000061],
              [41.911888416000068, 3.990835380000078],
              [41.912395614000047, 3.992297302000054],
              [41.912723801000084, 3.993311697000081],
              [41.912841575000073, 3.994433441000069],
              [41.913260834000084, 3.995579168000063],
              [41.913768030000085, 3.996444387000054],
              [41.914543744000071, 3.996832244000075],
              [41.915587973000072, 3.996802409000054],
              [41.916900721000047, 3.996504058000085],
              [41.918989181000086, 3.996265378000089],
              [41.920819999000059, 3.995780009000043],
              [41.922116374000041, 3.995297451000056],
              [41.922241213000063, 3.995250982000073],
              [41.923076596000044, 3.994982464000088],
              [41.923882144000061, 3.995042135000062],
              [41.924329671000066, 3.995340487000078],
              [41.924598188000061, 3.995907355000043],
              [41.924836869000046, 3.996891915000049],
              [41.924836869000046, 3.998085321000076],
              [41.925015881000036, 3.999308561000078],
              [41.925612583000088, 4.00074064800009],
              [41.926149616000089, 4.001516360000039],
              [41.926681044000077, 4.001723915000071],
              [41.92773087900008, 4.001546197000039],
              [41.928655767000066, 4.001188175000038],
              [41.930071775000044, 4.000623415000064],
              [41.931648169000084, 4.000266496000052],
              [41.932974718000082, 3.999998807000054],
              [41.934134706000066, 4.000028550000081],
              [41.93487828800005, 4.000474699000051],
              [41.935681355000042, 4.001575201000094],
              [41.936454680000054, 4.002645958000073],
              [41.93731723500008, 4.003657228000066],
              [41.938239276000047, 4.00437106600009],
              [41.939310034000073, 4.00535259500009],
              [41.940142845000082, 4.00642335200007],
              [41.940975656000035, 4.007226421000041],
              [41.942076157000088, 4.007642825000062],
              [41.943503833000079, 4.008059232000051],
              [41.944277158000034, 4.008535124000048],
              [41.944921890000046, 4.009392840000089],
              [41.945876613000053, 4.01079509200008],
              [41.946622492000074, 4.012018332000082],
              [41.947010348000049, 4.013152068000068],
              [41.947219195000059, 4.014852672000075],
              [41.947314206000044, 4.016179141000066],
              [41.947398204000081, 4.017328988000088],
              [41.947199994000073, 4.018806066000082],
              [41.946835079000039, 4.020432429000039],
              [41.946590851000053, 4.021642384000074],
              [41.946590851000053, 4.022403811000061],
              [41.946781207000072, 4.023298489000069],
              [41.947199994000073, 4.023831489000088],
              [41.947828169000047, 4.024021846000039],
              [41.94868477700004, 4.024040880000086],
              [41.949579453000069, 4.023850524000068],
              [41.950835809000068, 4.023736310000061],
              [41.951485620000085, 4.02362420500009],
              [41.952034586000082, 4.02362420500009],
              [41.952392608000082, 4.023743545000059],
              [41.95255968400005, 4.024161237000044],
              [41.952644201000055, 4.024592916000074],
              [41.952644201000055, 4.025144950000083],
              [41.952777450000042, 4.025792165000041],
              [41.952986843000076, 4.026477449000083],
              [41.953207655000085, 4.027695732000041],
              [41.952998849000039, 4.029509661000077],
              [41.952903085000059, 4.030436871000063],
              [41.952979228000061, 4.031921656000065],
              [41.952998264000087, 4.032892476000086],
              [41.952674658000035, 4.034738938000089],
              [41.952503336000063, 4.036299865000046],
              [41.952332015000081, 4.037689470000089],
              [41.952141658000073, 4.038660290000053],
              [41.951970336000045, 4.039840502000061],
              [41.951936377000038, 4.040031522000049],
              [41.951608671000088, 4.041874891000077],
              [41.951389013000039, 4.044290549000038],
              [41.95094240800006, 4.046091822000051],
              [41.950504589000047, 4.04725299800009],
              [41.950067643000068, 4.048052976000065],
              [41.949536756000043, 4.049024950000046],
              [41.949191126000073, 4.049537281000084],
              [41.948658, 4.050125491000074],
              [41.948182235000047, 4.05066038700005],
              [41.947611165000069, 4.050983993000045],
              [41.946944915000074, 4.051593136000065],
              [41.946526129000063, 4.051871056000039],
              [41.946202523000068, 4.052289841000061],
              [41.946156344000087, 4.05243299600005],
              [41.94601216600006, 4.052879947000065],
              [41.945955059000084, 4.053374876000078],
              [41.94601216600006, 4.053869804000044],
              [41.946192233000033, 4.054139905000056],
              [41.946316738000064, 4.054326660000072],
              [41.946887808000042, 4.054954838000072],
              [41.947573092000084, 4.056039873000088],
              [41.948543914000084, 4.057277192000072],
              [41.948943662000033, 4.058057655000084],
              [41.949203921000048, 4.05877462300009],
              [41.949515270000063, 4.059549486000037],
              [41.949672437000061, 4.05994062700006],
              [41.949990624000066, 4.060939658000052],
              [41.950216562000037, 4.061778855000057],
              [41.950257124000075, 4.061929515000088],
              [41.95079664900004, 4.062758392000092],
              [41.951246980000064, 4.063204905000077],
              [41.951837087000058, 4.063433334000081],
              [41.952541409000048, 4.063395261000039],
              [41.95271166100008, 4.063322296000081],
              [41.953340906000051, 4.063052620000065],
              [41.954140405000089, 4.062652870000079],
              [41.954920868000045, 4.062157942000056],
              [41.955372857000043, 4.061778855000057],
              [41.955510975000038, 4.061663015000079],
              [41.955948796000087, 4.061282301000062],
              [41.956424688000084, 4.061053872000059],
              [41.956862509000075, 4.06111098000008],
              [41.957300330000066, 4.061358444000064],
              [41.957600624000065, 4.061778855000057],
              [41.957681044000083, 4.061891442000046],
              [41.958099828000059, 4.062595763000047],
              [41.958423435000043, 4.063509476000092],
              [41.958744489000082, 4.064071124000066],
              [41.959154057000035, 4.064787615000057],
              [41.959622682000088, 4.065260760000058],
              [41.960288932000083, 4.065774723000061],
              [41.961103659000059, 4.066345793000039],
              [41.961522445000071, 4.066821686000083],
              [41.961693766000053, 4.067430829000045],
              [41.961807981000049, 4.067963828000074],
              [41.961807981000049, 4.068496827000047],
              [41.961865088000081, 4.069182111000089],
              [41.962188694000076, 4.069791254000052],
              [41.962311567000086, 4.069906200000048],
              [41.96277880100007, 4.070343288000061],
              [41.96340697800008, 4.070933394000065],
              [41.963965098000074, 4.071690335000085],
              [41.96439683400007, 4.072399144000087],
              [41.964625261000037, 4.073046356000077],
              [41.964682369000059, 4.073864890000038],
              [41.964739476000034, 4.074626318000071],
              [41.964853690000041, 4.075635209000041],
              [41.964910797000073, 4.07651085100008],
              [41.96512019000005, 4.077641572000061],
              [41.965380249000077, 4.078275465000047],
              [41.965424762000055, 4.078383964000068],
              [41.965938724000068, 4.079202498000086],
              [41.966643045000069, 4.079925854000066],
              [41.966900269000064, 4.080069596000044],
              [41.966928655000061, 4.080085460000078],
              [41.967290258000048, 4.080287531000067],
              [41.967918436000048, 4.080592102000082],
              [41.968622756000059, 4.080991852000068],
              [41.969280353000045, 4.081606987000043],
              [41.969726825000066, 4.082156835000092],
              [41.970069468000077, 4.082689836000043],
              [41.97031693200006, 4.08307054800008],
              [41.970678611000039, 4.083241870000052],
              [41.971116430000052, 4.083279942000047],
              [41.971782681000036, 4.083127656000045],
              [41.972334715000045, 4.082918265000046],
              [41.973343606000071, 4.082328157000063],
              [41.974535378000041, 4.081522907000078],
              [41.974752248000073, 4.081376373000069],
              [41.975913425000044, 4.080748196000059],
              [41.976898196000036, 4.080175395000083],
              [41.977965334000089, 4.079510375000041],
              [41.978429922000089, 4.079054278000058],
              [41.978744598000048, 4.078745353000045],
              [41.979092385000058, 4.078654269000083],
              [41.979415991000053, 4.078673305000052],
              [41.979739597000048, 4.07888269700004],
              [41.980329704000042, 4.079415696000069],
              [41.980786561000059, 4.08010098200009],
              [41.980995955000083, 4.080672052000068],
              [41.981072097000038, 4.081224088000056],
              [41.981053060000079, 4.08181419400006],
              [41.980900777000045, 4.082271050000088],
              [41.980748490000053, 4.082632729000068],
              [41.980443919000038, 4.083127656000045],
              [41.980196455000055, 4.083565477000093],
              [41.979796705000069, 4.083927156000073],
              [41.979492134000054, 4.084345940000048],
              [41.97924467100006, 4.084821832000046],
              [41.979073351000068, 4.085792653000055],
              [41.978850127000044, 4.086485179000078],
              [41.978654564000067, 4.08699190100009],
              [41.978445171000033, 4.087334544000043],
              [41.978121565000038, 4.087658150000038],
              [41.977417245000083, 4.088000792000059],
              [41.977207852000049, 4.08841957900006],
              [41.977207852000049, 4.088762220000092],
              [41.977398208000068, 4.08906679100005],
              [41.978692635000073, 4.08994243300009],
              [41.980215489000045, 4.091008432000081],
              [41.980786561000059, 4.09136630200004],
              [41.981205346000081, 4.091766052000082],
              [41.981452810000064, 4.092299049000076],
              [41.981624132000036, 4.092908193000085],
              [41.981719310000074, 4.093650585000091],
              [41.981700274000048, 4.09431683400004],
              [41.981606197000076, 4.094965804000083],
              [41.981469680000089, 4.095907522000061],
              [41.980383171000085, 4.097025631000065],
              [41.979975712000055, 4.097719022000092],
              [41.979965876000051, 4.098589509000078],
              [41.980634671000075, 4.099080796000067],
              [41.981884687000047, 4.09928850700004],
              [41.982944247000034, 4.099300579000044],
              [41.984689225000068, 4.09875472300007],
              [41.98579294700005, 4.098790220000069],
              [41.986396815000035, 4.099069783000061],
              [41.98678474500008, 4.099490824000043],
              [41.987201588000062, 4.099837183000091],
              [41.987525195000046, 4.100351148000072],
              [41.98775545500007, 4.101403668000046],
              [41.988012289000039, 4.102647305000062],
              [41.988290334000055, 4.10361782800004],
              [41.988958053000033, 4.104205852000064],
              [41.989913659000081, 4.104893896000078],
              [41.990680990000044, 4.105192850000037],
              [41.991644257000075, 4.10520383100004],
              [41.992415965000077, 4.105115889000047],
              [41.993383609000034, 4.104739971000072],
              [41.993848851000052, 4.104223006000041],
              [41.994286672000044, 4.103975543000047],
              [41.994876776000069, 4.10376614900008],
              [41.995675598000048, 4.103722210000058],
              [41.996171205000053, 4.103842292000081],
              [41.996609024000065, 4.104013614000053],
              [41.997046845000057, 4.104051684000069],
              [41.997389488000067, 4.103918435000082],
              [41.997808274000079, 4.10369000500009],
              [41.998208022000085, 4.103214113000092],
              [41.99862680800004, 4.102585938000061],
              [41.999159807000069, 4.102071973000079],
              [41.999749913000073, 4.10178643800009],
              [42.000244842000086, 4.101424760000043],
              [42.000644592000071, 4.100948866000067],
              [42.001025304000052, 4.100415867000038],
              [42.001321317000077, 4.09994101400008],
              [42.001606853000055, 4.099774453000066],
              [42.001963772000067, 4.099845836000043],
              [42.002249308000046, 4.100202757000091],
              [42.002296896000075, 4.100702442000056],
              [42.002320691000079, 4.101368692000051],
              [42.002106539000067, 4.102106325000079],
              [42.001654441000085, 4.102986725000051],
              [42.001202344000035, 4.103700565000054],
              [42.000821630000075, 4.104604757000061],
              [42.000583685000038, 4.105342392000068],
              [42.000547583000071, 4.10581905500004],
              [42.000702657000033, 4.107126987000072],
              [42.001154755000073, 4.107817031000081],
              [42.001535468000043, 4.108221538000066],
              [42.00201136000004, 4.108554664000053],
              [42.002701405000039, 4.109040075000053],
              [42.003034529000047, 4.109396994000065],
              [42.003343860000086, 4.109849091000058],
              [42.003391448000059, 4.110229805000074],
              [42.003320065000082, 4.110753285000044],
              [42.003040663000036, 4.111234748000072],
              [42.002701405000039, 4.111705070000085],
              [42.002450648000035, 4.112292155000091],
              [42.002537131000054, 4.113163795000048],
              [42.002635054000052, 4.113291715000059],
              [42.002986941000074, 4.113751408000041],
              [42.003938725000069, 4.113989353000079],
              [42.004937748000089, 4.113868352000054],
              [42.00550916800006, 4.113751408000041],
              [42.005961267000089, 4.113418282000055],
              [42.006145844000059, 4.113291715000059],
              [42.006794076000062, 4.112847210000041],
              [42.007841040000073, 4.112014400000078],
              [42.00831693300006, 4.111847838000074],
              [42.008717653000076, 4.111879890000068],
              [42.009125949000065, 4.111966811000059],
              [42.009506663000082, 4.112323730000071],
              [42.009887379000077, 4.11260926600005],
              [42.010244296000053, 4.112775827000064],
              [42.010648805000073, 4.112847210000041],
              [42.011211368000033, 4.112875718000055],
              [42.012309668000057, 4.112942390000057],
              [42.013499399000068, 4.112918597000089],
              [42.014213237000035, 4.112823417000072],
              [42.01533158400008, 4.112561676000041],
              [42.016283368000074, 4.112109579000048],
              [42.016568902000074, 4.111919221000051],
              [42.016997206000042, 4.111490918000072],
              [42.017155832000071, 4.111252980000074],
              [42.017282742000077, 4.111062616000083],
              [42.017853812000055, 4.110539134000078],
              [42.018686624000054, 4.110253600000078],
              [42.019305284000041, 4.110229805000074],
              [42.019876354000075, 4.110301188000051],
              [42.020365127000048, 4.110608239000044],
              [42.020970907000049, 4.111086410000041],
              [42.021161264000057, 4.11182404300007],
              [42.021375413000044, 4.112442703000056],
              [42.021399210000084, 4.113061363000043],
              [42.021372109000083, 4.113291715000059],
              [42.021304031000057, 4.113870380000037],
              [42.021113674000048, 4.114536629000042],
              [42.020833870000047, 4.115492629000073],
              [42.020828140000049, 4.115512208000041],
              [42.02078055000004, 4.116059484000061],
              [42.020947111000055, 4.11651158300009],
              [42.021304031000057, 4.116858981000064],
              [42.021637156000054, 4.117073134000066],
              [42.021970280000062, 4.117263492000063],
              [42.022327199000074, 4.11750143800009],
              [42.022684119000075, 4.11773938400006],
              [42.022918641000047, 4.11789573100009],
              [42.024077936000083, 4.119232042000078],
              [42.024159385000075, 4.119476390000045],
              [42.024230768000052, 4.120095050000089],
              [42.024206973000048, 4.120785093000052],
              [42.024064207000038, 4.12152272600008],
              [42.02387384900004, 4.122403127000041],
              [42.02363590300007, 4.12337870500005],
              [42.02363590300007, 4.123902189000091],
              [42.023707286000047, 4.12456843700005],
              [42.023850056000072, 4.124925354000084],
              [42.024159385000075, 4.125139507000085],
              [42.024754248000079, 4.125187097000094],
              [42.025539472000048, 4.125329865000083],
              [42.02644366700008, 4.125544016000049],
              [42.027466835000041, 4.125567810000064],
              [42.029291095000076, 4.125241216000063],
              [42.029327573000046, 4.125234685000066],
              [42.030112796000083, 4.125020534000043],
              [42.030351406000079, 4.124726218000092],
              [42.030707661000065, 4.124473259000069],
              [42.031112170000085, 4.124306695000087],
              [42.031730830000072, 4.124163928000087],
              [42.03230190000005, 4.124235313000042],
              [42.032968149000055, 4.12456843700005],
              [42.033468240000047, 4.124888270000042],
              [42.033916240000053, 4.12528026800004],
              [42.034181674000081, 4.125996113000042],
              [42.034205468000039, 4.126638567000043],
              [42.034253059000037, 4.127209638000068],
              [42.034324440000034, 4.127828297000065],
              [42.034514798000089, 4.128565932000072],
              [42.034800334000067, 4.12907513600004],
              [42.035157253000079, 4.12947964500006],
              [42.035466583000073, 4.129955537000058],
              [42.035752118000062, 4.130407634000051],
              [42.03594247500007, 4.130907321000052],
              [42.036013859000036, 4.131502186000091],
              [42.036132832000078, 4.132001872000046],
              [42.036489751000033, 4.132549148000066],
              [42.036846670000045, 4.133144014000038],
              [42.037274973000081, 4.133453345000078],
              [42.03765568700004, 4.134000620000052],
              [42.037988813000084, 4.134524102000057],
              [42.038226759000054, 4.135190350000073],
              [42.038172836000058, 4.135675638000066],
              [42.038131579000037, 4.136046956000087],
              [42.037846043000059, 4.137022534000039],
              [42.037512919000051, 4.137736374000042],
              [42.037298768000085, 4.13847400700007],
              [42.037274973000081, 4.139045077000048],
              [42.037389188000077, 4.139525727000091],
              [42.037784531000057, 4.140074222000067],
              [42.038354913000035, 4.140757987000086],
              [42.039116884000066, 4.141540692000092],
              [42.039788, 4.141838616000086],
              [42.040458692000072, 4.142190724000045],
              [42.041386682000052, 4.142404876000057],
              [42.042076726000062, 4.14233349400007],
              [42.042433644000084, 4.142024163000087],
              [42.042766770000071, 4.141643449000071],
              [42.04295363600005, 4.141518870000084],
              [42.043052304000071, 4.141453092000063],
              [42.043218867000064, 4.141167557000074],
              [42.043409223000083, 4.140810637000072],
              [42.043599580000034, 4.140358540000079],
              [42.043694759000061, 4.139692292000063],
              [42.043670964000057, 4.13897845200006],
              [42.043694759000061, 4.138121846000047],
              [42.043766142000038, 4.13750318600006],
              [42.043956500000036, 4.13695591000004],
              [42.044170651000059, 4.13643243000007],
              [42.044479982000041, 4.136075511000058],
              [42.044836901000053, 4.136027919000071],
              [42.045265203000042, 4.136146892000056],
              [42.045526944000073, 4.13643243000007],
              [42.045955247000052, 4.136932116000082],
              [42.046169398000075, 4.137336625000046],
              [42.046335961000068, 4.137836311000058],
              [42.046597702000042, 4.138407382000082],
              [42.046811853000065, 4.13874050600009],
              [42.047168772000077, 4.139026042000069],
              [42.047597074000066, 4.139335371000072],
              [42.04802823700004, 4.139476269000056],
              [42.04840609200005, 4.139620907000051],
              [42.048858190000033, 4.139668497000059],
              [42.049453055000072, 4.139501934000066],
              [42.049976536000088, 4.139216398000087],
              [42.050404839000066, 4.139002247000064],
              [42.050928320000082, 4.138907068000037],
              [42.051428006000037, 4.139049835000037],
              [42.051808720000054, 4.139454346000093],
              [42.052122172000054, 4.139900194000063],
              [42.052270890000045, 4.140405831000066],
              [42.052330375000054, 4.140970953000078],
              [42.052213229000074, 4.14207175100006],
              [42.052070463000064, 4.142999742000086],
              [42.05214184600004, 4.143451839000079],
              [42.052508836000072, 4.144123738000076],
              [42.053163187000052, 4.14507552200007],
              [42.053787795000062, 4.145997565000073],
              [42.054442147000088, 4.147008835000065],
              [42.05503701300006, 4.147901132000072],
              [42.055483160000051, 4.148436511000057],
              [42.05610777000004, 4.148793431000058],
              [42.056643148000035, 4.148971889000052],
              [42.05714878200007, 4.14894214800006],
              [42.057567017000054, 4.14861527000005],
              [42.057951851000041, 4.148168822000059],
              [42.058219541000085, 4.147633445000054],
              [42.058487231000072, 4.147008835000065],
              [42.058903635000036, 4.14647345700007],
              [42.059379527000033, 4.146176023000066],
              [42.059885164000036, 4.146116538000058],
              [42.060361056000033, 4.146413969000093],
              [42.060740237000061, 4.147092270000087],
              [42.060926178000045, 4.147633445000054],
              [42.060866691000058, 4.148168822000059],
              [42.06059900200006, 4.14870420200009],
              [42.060242084000038, 4.149239580000085],
              [42.059885164000036, 4.149953418000052],
              [42.059468758000037, 4.150459053000077],
              [42.059082096000054, 4.151053920000038],
              [42.058695433000082, 4.151856987000087],
              [42.058457488000045, 4.152660055000069],
              [42.058516973000053, 4.15387953000004],
              [42.058754921000059, 4.154861055000083],
              [42.059320042000081, 4.155723611000042],
              [42.060361056000033, 4.156199503000039],
              [42.061431814000059, 4.156526679000081],
              [42.062115908000067, 4.156794368000078],
              [42.062740517000066, 4.157478463000075],
              [42.063513842000077, 4.157865126000047],
              [42.06390050400006, 4.158192302000089],
              [42.064138450000087, 4.158727681000073],
              [42.064584599000057, 4.159352289000083],
              [42.065149722000058, 4.159976898000082],
              [42.065250738000088, 4.16005201300004],
              [42.066309708000063, 4.160839453000051],
              [42.067404235000083, 4.161260269000081],
              [42.068820273000085, 4.161682288000065],
              [42.069486521000044, 4.161765569000067],
              [42.070212258000083, 4.161825056000055],
              [42.070919913000068, 4.161880466000071],
              [42.071604242000035, 4.162015412000073],
              [42.072228618000054, 4.162177899000085],
              [42.072758282000052, 4.162229563000039],
              [42.07336504500006, 4.162336639000046],
              [42.073936113000059, 4.16250320100005],
              [42.074233547000063, 4.162681661000079],
              [42.07437013200007, 4.162980966000077],
              [42.074340388000053, 4.163456860000053],
              [42.074191674000076, 4.16414095500005],
              [42.073864498000034, 4.165122482000072],
              [42.073674374000063, 4.165905830000042],
              [42.073531605000085, 4.16691710200007],
              [42.073591093000061, 4.167435824000052],
              [42.07403129100004, 4.168518479000056],
              [42.074768926000047, 4.169208522000076],
              [42.075006872000074, 4.169636824000065],
              [42.075020454000082, 4.17049429900004],
              [42.074915289000046, 4.171267163000039],
              [42.074906558000066, 4.172034333000056],
              [42.074903177000067, 4.172331369000062],
              [42.074893266000061, 4.173202083000092],
              [42.074979711000083, 4.174073904000068],
              [42.075357428000075, 4.174755554000058],
              [42.076117272000033, 4.175731866000092],
              [42.077070936000041, 4.176613649000046],
              [42.077547218000063, 4.177102915000091],
              [42.078314780000085, 4.177402009000048],
              [42.078891830000089, 4.177505395000082],
              [42.080322262000038, 4.177329315000065],
              [42.087665541000035, 4.179366679000054],
              [42.097743927000067, 4.179726650000077],
              [42.141509950000057, 4.179605459000072],
              [42.196739279000042, 4.178941201000043],
              [42.252380390000042, 4.181956851000052],
              [42.29863359400008, 4.184389067000041],
              [42.316948069000034, 4.185541696000087],
              [42.370975572000077, 4.189451192000092],
              [42.401600051000059, 4.193087129000048],
              [42.416503968000086, 4.194856618000074],
              [42.445548922000057, 4.19936513500005],
              [42.475524845000052, 4.202731115000063],
              [42.485828494000089, 4.203320404000067],
              [42.497524182000063, 4.202318702000071],
              [42.526618874000064, 4.202911416000063],
              [42.542629263000038, 4.204947979000053],
              [42.554531022000049, 4.206251084000087],
              [42.565715813000054, 4.209839354000053],
              [42.573936404000051, 4.212007111000048],
              [42.587879232000034, 4.215405438000062],
              [42.599494907000064, 4.220608218000052],
              [42.638263591000054, 4.235577527000089],
              [42.671546836000061, 4.250484845000074],
              [42.683410571000081, 4.254302883000094],
              [42.694366493000075, 4.257887729000061],
              [42.702362091000055, 4.260055486000056],
              [42.709903738000037, 4.261758299000064],
              [42.720203422000054, 4.262805865000075],
              [42.728900852000038, 4.263372448000041],
              [42.737602246000051, 4.263938941000049],
              [42.750198358000034, 4.264554542000042],
              [42.817337016000067, 4.26491117900008],
              [42.825099961000035, 4.267072268000049],
              [42.833309199000041, 4.270624676000068],
              [42.849014310000086, 4.279553496000062],
              [42.862419209000052, 4.289375802000052],
              [42.877628290000075, 4.301519855000038],
              [42.881704993000085, 4.304921507000074],
              [42.885265730000071, 4.307892628000047],
              [42.890477541000052, 4.290922768000087],
            ],
          ],
          [
            [
              [42.196710176000067, -0.833404801999961],
              [42.195128189000059, -0.835551185999918],
              [42.193697392000047, -0.836748224999951],
              [42.192864581000038, -0.838175901999932],
              [42.18947384900008, -0.842399444999955],
              [42.185131332000083, -0.848467070999959],
              [42.177451200000064, -0.856149480999932],
              [42.171398379000038, -0.861411547999921],
              [42.163419660000045, -0.867781409999907],
              [42.156816583000079, -0.874428209999962],
              [42.151864275000037, -0.881074998999907],
              [42.143885556000043, -0.889937364999923],
              [42.143885556000043, -0.894645487999924],
              [42.14113427400008, -0.899353604999931],
              [42.138888485000052, -0.89944055199993],
              [42.138783642000078, -0.899267170999906],
              [42.138013045000037, -0.898879324999939],
              [42.136685906000082, -0.899267170999906],
              [42.135615633000043, -0.899956675999931],
              [42.133517898000036, -0.900818557999912],
              [42.131377351000083, -0.901939002999939],
              [42.130735187000084, -0.903102541999942],
              [42.12970772500006, -0.904309173999934],
              [42.128808696000078, -0.905645087999915],
              [42.128466208000077, -0.906205309999962],
              [42.128080910000051, -0.906205309999962],
              [42.127324093000084, -0.905665686999953],
              [42.128168676000087, -0.904579360999946],
              [42.127928119000046, -0.9024000299999],
              [42.132605299000033, -0.897968864999939],
              [42.137007350000033, -0.891322106999951],
              [42.138722572000063, -0.888362268999913],
              [42.142453989000046, -0.881923223999934],
              [42.14278504300006, -0.881351947999917],
              [42.142750859000046, -0.881145486999912],
              [42.144023120000043, -0.879586395999922],
              [42.144435813000086, -0.877855461999957],
              [42.144497906000083, -0.876292859999921],
              [42.148012480000034, -0.873597360999952],
              [42.148590325000043, -0.873197460999961],
              [42.148631518000059, -0.873355029999914],
              [42.148975428000085, -0.875224440999943],
              [42.150832544000082, -0.875639864999926],
              [42.152208185000063, -0.874185878999924],
              [42.153652608000073, -0.871554854999943],
              [42.156403891000082, -0.868993066999906],
              [42.159774211000069, -0.867054414999927],
              [42.162044019000064, -0.864700336999931],
              [42.163969917000088, -0.862207781999928],
              [42.166308507000053, -0.858745896999949],
              [42.168096840000089, -0.853553062999936],
              [42.168421698000088, -0.851847937999935],
              [42.169006191000051, -0.850985],
              [42.170572994000054, -0.848671792999937],
              [42.172275553000077, -0.84792198699995],
              [42.172705238000049, -0.848498697999958],
              [42.172838580000075, -0.848594573999947],
              [42.174149661000058, -0.84953726699996],
              [42.176029894000067, -0.850132692999921],
              [42.176148867000052, -0.848586043999944],
              [42.176743732000034, -0.847217852999961],
              [42.178276585000049, -0.847460128999955],
              [42.180253437000033, -0.848467070999959],
              [42.181205222000074, -0.847693745999948],
              [42.183044961000064, -0.84596899],
              [42.183991981000077, -0.844965600999956],
              [42.185961284000086, -0.842810864999933],
              [42.187844965000068, -0.839277095999932],
              [42.190927352000074, -0.836260460999938],
              [42.192725410000037, -0.8343642899999],
              [42.193408638000051, -0.833733844999927],
              [42.193408638000051, -0.83429296099996],
              [42.194371586000045, -0.833958707999955],
              [42.196159920000071, -0.832435467999915],
              [42.199048766000033, -0.82890431699991],
              [42.200011715000073, -0.826550214999941],
              [42.201847046000069, -0.824137081999936],
              [42.202977290000035, -0.823185296999952],
              [42.204048047000072, -0.822471458999928],
              [42.206821139000056, -0.819695616999923],
              [42.210053895000044, -0.816649123999923],
              [42.212392485000066, -0.814779684999962],
              [42.214577162000069, -0.8123587499999],
              [42.215487678000045, -0.810625370999958],
              [42.217895050000038, -0.808617451999908],
              [42.21872043500008, -0.807371155999931],
              [42.22023364000006, -0.805363234999959],
              [42.222709794000082, -0.802593686999955],
              [42.224773256000049, -0.800239568999928],
              [42.22759332000004, -0.79816240599996],
              [42.228281141000082, -0.799200987999939],
              [42.226080115000059, -0.801347389999933],
              [42.224360563000062, -0.803216834999944],
              [42.221953191000068, -0.80591714499991],
              [42.220027294000033, -0.808617451999908],
              [42.217482358000041, -0.810763848999954],
              [42.216037934000042, -0.812979482999935],
              [42.214112037000064, -0.815264353999908],
              [42.211979793000069, -0.817410747999929],
              [42.210122678000062, -0.818933993999906],
              [42.208196780000037, -0.820595715999957],
              [42.205583062000073, -0.823711443999912],
              [42.20351960000005, -0.825719355999922],
              [42.201318574000084, -0.828350410999917],
              [42.199874151000074, -0.830150605999961],
              [42.19794825300005, -0.831466132999935],
              [42.196710176000067, -0.833404801999961],
            ],
          ],
          [
            [
              [42.346807022000064, -0.640574439999909],
              [42.345091672000081, -0.642646599999921],
              [42.344199690000039, -0.643199175999939],
              [42.342896025000073, -0.643682679999927],
              [42.341249289000075, -0.645409479999955],
              [42.340288693000048, -0.64665277499995],
              [42.340014237000048, -0.647550709999962],
              [42.339533939000034, -0.65024451499994],
              [42.339602553000077, -0.651349664999941],
              [42.339053642000067, -0.653283676999934],
              [42.337749976000055, -0.654872329999932],
              [42.336446310000042, -0.65722077199996],
              [42.334044821000077, -0.65853313599996],
              [42.332741155000065, -0.65977642799993],
              [42.332657915000084, -0.661025029999962],
              [42.331094420000056, -0.661157862999914],
              [42.33157471700008, -0.65977642799993],
              [42.331825103000085, -0.65864556899993],
              [42.331309573000055, -0.657904803999941],
              [42.331025806000071, -0.657497058999923],
              [42.329516298000044, -0.657082627999955],
              [42.328707734000034, -0.65833069699994],
              [42.328487088000088, -0.658671278999918],
              [42.328212632000088, -0.660052714999949],
              [42.328144018000046, -0.662055794999958],
              [42.327252036000061, -0.663230014999954],
              [42.326085599000066, -0.663575372999958],
              [42.324919161000082, -0.662332081999921],
              [42.323684109000055, -0.661848579999912],
              [42.323889951000069, -0.659845498999914],
              [42.322624419000078, -0.659326471999918],
              [42.322037374000047, -0.659085709999943],
              [42.321771881000075, -0.65799121699996],
              [42.322604692000084, -0.65620662099991],
              [42.322174602000075, -0.654734185999928],
              [42.320641637000051, -0.652399483999943],
              [42.321031408000067, -0.650716161999924],
              [42.321158192000041, -0.650480537999954],
              [42.322742196000036, -0.649704798999949],
              [42.322955794000052, -0.649188743999957],
              [42.323170306000065, -0.648670478999918],
              [42.322656574000064, -0.647549965999929],
              [42.322203820000084, -0.646016900999939],
              [42.322271276000038, -0.645783001999916],
              [42.322913440000036, -0.644490101999907],
              [42.323170847000085, -0.644295757999942],
              [42.323427171000048, -0.644102231999909],
              [42.324326201000076, -0.644231521999927],
              [42.32573896100007, -0.644791778999945],
              [42.32646674700004, -0.645222745999945],
              [42.326894856000081, -0.646429452999939],
              [42.326637991000041, -0.647205192999934],
              [42.326894856000081, -0.648325705999923],
              [42.327280155000039, -0.649661701999946],
              [42.327836697000066, -0.650394344999938],
              [42.328692915000033, -0.650308151999923],
              [42.329163835000088, -0.649704798999949],
              [42.329463512000075, -0.648454995999941],
              [42.329634756000075, -0.647636158999944],
              [42.329292268000074, -0.646688032999919],
              [42.328521671000033, -0.645998485999939],
              [42.326894856000081, -0.644403908999948],
              [42.32629550300004, -0.643111007999948],
              [42.326595180000083, -0.642162879999944],
              [42.32646674700004, -0.641473332999908],
              [42.325781772000084, -0.641300945999944],
              [42.325139608000086, -0.642119783999931],
              [42.324497444000087, -0.64324029799991],
              [42.323341549000077, -0.643628167999907],
              [42.322529986000063, -0.642913309999926],
              [42.323213116000034, -0.642292169999962],
              [42.323940902000061, -0.641861202999962],
              [42.324435418000064, -0.641101376999927],
              [42.324797121000074, -0.6403959],
              [42.325268041000072, -0.639706351999962],
              [42.325524907000045, -0.639146094999944],
              [42.326038638000057, -0.638930610999921],
              [42.326894856000081, -0.639404674999923],
              [42.327793886000052, -0.639964932999931],
              [42.328607293000061, -0.640740673999915],
              [42.329410972000062, -0.641255519999959],
              [42.329790754000044, -0.641334231999906],
              [42.33212362900008, -0.641541447999941],
              [42.335037376000059, -0.641275502999918],
              [42.336446310000042, -0.6382259899999],
              [42.338230274000068, -0.635877538999921],
              [42.336743174000048, -0.632642400999941],
              [42.336227106000081, -0.631519712999932],
              [42.336762485000065, -0.630686901999923],
              [42.338246735000041, -0.630315839999923],
              [42.339617838000038, -0.629973063999955],
              [42.341045515000076, -0.626879763999909],
              [42.343239095000058, -0.624411558999952],
              [42.345915040000079, -0.62296104099994],
              [42.346120728000074, -0.622831991999931],
              [42.349909007000065, -0.6204552199999],
              [42.351061089000041, -0.623099185999934],
              [42.350801305000061, -0.625095168999906],
              [42.352913666000063, -0.626207436999948],
              [42.352639210000063, -0.627381664999916],
              [42.35133554500004, -0.628555891999952],
              [42.349757423000085, -0.63062805699991],
              [42.351039251000088, -0.632471497999916],
              [42.350265926000077, -0.635505309999928],
              [42.346807022000064, -0.640574439999909],
            ],
          ],
          [
            [
              [42.054443354000057, -0.996966291999911],
              [42.052301839000052, -0.999226779999958],
              [42.051231081000083, -0.999881131999928],
              [42.050338783000086, -0.999048320999918],
              [42.048494701000038, -0.999702671999955],
              [42.047126511000045, -1.001487267999948],
              [42.047007538000059, -1.003093403999912],
              [42.047602403000042, -1.004342620999921],
              [42.049446485000033, -1.004045188999953],
              [42.048970593000035, -1.005234918999918],
              [42.047423944000059, -1.006067730999916],
              [42.043854752000072, -1.009458461999941],
              [42.041832210000052, -1.011302544999921],
              [42.040701966000086, -1.01296816699994],
              [42.040047614000059, -1.011778436999919],
              [42.037965586000041, -1.012016382999946],
              [42.036894829000062, -1.014157897999951],
              [42.037192261000087, -1.015823520999959],
              [42.038025073000085, -1.016299412999956],
              [42.037489694000044, -1.016953764999926],
              [42.036299963000033, -1.016775304999953],
              [42.035645612000053, -1.017429656999923],
              [42.036299963000033, -1.018024521999962],
              [42.03612150400005, -1.019333225999958],
              [42.035050746000081, -1.020701415999952],
              [42.034396395000044, -1.021772172999931],
              [42.032076420000067, -1.022902416999955],
              [42.029102094000052, -1.023437795999939],
              [42.026425200000062, -1.023378308999952],
              [42.024912094000058, -1.0221894399999],
              [42.023926766000045, -1.021415253999919],
              [42.02326409300008, -1.020801667999933],
              [42.022320630000081, -1.01992809099994],
              [42.022320630000081, -1.018804090999936],
              [42.022320630000081, -1.018381440999917],
              [42.023867280000047, -1.016537358999926],
              [42.025057010000069, -1.01499070899996],
              [42.027198525000074, -1.013087139999925],
              [42.028804661000038, -1.011599976999946],
              [42.030648743000086, -1.009993840999925],
              [42.032076420000067, -1.008447191999949],
              [42.032552312000064, -1.007376433999923],
              [42.032076420000067, -1.00559183799993],
              [42.03118412200007, -1.003390836999927],
              [42.031606282000041, -1.002486208999926],
              [42.032016934000069, -1.001606240999934],
              [42.032127449000086, -1.001727806999952],
              [42.033801529000073, -1.00356929599991],
              [42.03386101600006, -1.005532351999932],
              [42.034574854000084, -1.006424649999929],
              [42.035824071000036, -1.005770297999959],
              [42.037073288000045, -1.004640053999935],
              [42.037965586000041, -1.003152890999957],
              [42.039095830000065, -1.001665727999921],
              [42.039036343000078, -1.000594969999952],
              [42.038738911000053, -0.998631914999919],
              [42.039393263000079, -0.996014507999917],
              [42.041594264000082, -0.992504802999918],
              [42.042902968000078, -0.990660720999927],
              [42.044092698000043, -0.98976842299993],
              [42.045222942000066, -0.988995097999918],
              [42.046353186000033, -0.988578691999919],
              [42.048137782000083, -0.988519205999921],
              [42.04870111300005, -0.988350206999939],
              [42.049922378000076, -0.987983826999937],
              [42.051409541000055, -0.987983826999937],
              [42.052539785000079, -0.987626907999925],
              [42.054324380000082, -0.986199230999944],
              [42.056822815000089, -0.984236175999911],
              [42.058309978000068, -0.983403363999912],
              [42.053907975000072, -0.98816228599992],
              [42.053432083000075, -0.991196098999922],
              [42.052539785000079, -0.993991965999953],
              [42.052956190000089, -0.996371426999929],
              [42.054443354000057, -0.996966291999911],
            ],
          ],
          [
            [
              [42.289634736000039, -0.72373002899991],
              [42.28784640300006, -0.725461028999916],
              [42.285439031000067, -0.728161387999933],
              [42.283513133000042, -0.730377064999914],
              [42.281243325000048, -0.733008180999946],
              [42.278835953000055, -0.735085376999962],
              [42.276291017000062, -0.73743953099995],
              [42.273883645000069, -0.739447484999914],
              [42.272095311000044, -0.741316957999913],
              [42.270444542000064, -0.743463388999942],
              [42.268793773000084, -0.745540579999954],
              [42.267418131000056, -0.747271571999931],
              [42.266248837000035, -0.749279520999949],
              [42.263910247000069, -0.75038735499993],
              [42.262397041000042, -0.750733552999918],
              [42.26411659300004, -0.747963967999908],
              [42.265629798000077, -0.744432744999926],
              [42.267005439000059, -0.743117190999953],
              [42.268793773000084, -0.741593916999932],
              [42.270788452000033, -0.738616606999926],
              [42.273895849000041, -0.735616206999907],
              [42.274158773000067, -0.735362335999923],
              [42.276772491000088, -0.731484903999956],
              [42.277322748000074, -0.729061506999926],
              [42.277257413000086, -0.727680346999932],
              [42.277683695000064, -0.727447906999942],
              [42.280250286000069, -0.726698153999962],
              [42.278839590000075, -0.724560478999933],
              [42.279438943000059, -0.723827846999939],
              [42.281695825000043, -0.725308367999958],
              [42.282272828000089, -0.725686882999923],
              [42.284176397000067, -0.724616124999955],
              [42.285425614000076, -0.72497304399991],
              [42.28661534400004, -0.722474609999949],
              [42.287369615000046, -0.72057112899995],
              [42.28784640300006, -0.719367905999945],
              [42.290253775000053, -0.718260063999935],
              [42.291021790000059, -0.718085486999939],
              [42.292386018000059, -0.717775383999935],
              [42.29374223800005, -0.716233969999962],
              [42.294509300000072, -0.715362163999941],
              [42.294518262000054, -0.715351978999934],
              [42.29478924700004, -0.715144420999934],
              [42.29562147300004, -0.714174373999924],
              [42.300030999000057, -0.708959746999938],
              [42.303113386000064, -0.704520845999923],
              [42.304783012000087, -0.701805787999945],
              [42.306581071000039, -0.700986960999955],
              [42.307951020000075, -0.700426709999931],
              [42.308764428000075, -0.700383613999918],
              [42.309535025000059, -0.70055599899996],
              [42.309192537000058, -0.701331729999936],
              [42.307779777000064, -0.702279845999954],
              [42.30559641900004, -0.704391557999941],
              [42.304140848000088, -0.705813729999932],
              [42.302428411000051, -0.707537575999936],
              [42.300887217000081, -0.7098216699999],
              [42.298532616000045, -0.712493626999958],
              [42.297391843000071, -0.713756834999913],
              [42.297269544000073, -0.714105655999958],
              [42.295549993000066, -0.716667541999925],
              [42.29355531300007, -0.718883224999956],
              [42.291560634000064, -0.72158358799993],
              [42.289634736000039, -0.72373002899991],
            ],
          ],
          [
            [
              [41.93430662600008, -1.15538195299996],
              [41.933618806000084, -1.156697348999955],
              [41.932174383000074, -1.159051211999952],
              [41.93024848500005, -1.160920455999928],
              [41.927565985000058, -1.163689702999932],
              [41.925296177000064, -1.165766635999944],
              [41.923645408000084, -1.167566643999919],
              [41.920343869000078, -1.171097424999914],
              [41.918624317000081, -1.173797430999912],
              [41.916698420000046, -1.177120511999931],
              [41.915666689000034, -1.179197434999935],
              [41.91470374000005, -1.177189741999939],
              [41.915941817000089, -1.17497435599995],
              [41.916835984000045, -1.173174352999922],
              [41.917354221000039, -1.171663218999925],
              [41.915165116000082, -1.170425898999952],
              [41.913642261000064, -1.170116568999958],
              [41.913047396000081, -1.169283757999949],
              [41.91264288800005, -1.168070232999924],
              [41.912976012000058, -1.166523582999957],
              [41.91402297500008, -1.164310683999929],
              [41.913785029000053, -1.162859212999933],
              [41.913951591000057, -1.162502293999921],
              [41.91426092100005, -1.162097785999947],
              [41.914594046000047, -1.1618598399999],
              [41.915117527000064, -1.161836044999916],
              [41.915759982000054, -1.162216758999932],
              [41.91652140900004, -1.162906801999952],
              [41.91697350700008, -1.163216131999945],
              [41.917520783000043, -1.162954391999961],
              [41.917949086000078, -1.162883007999937],
              [41.918282210000086, -1.162811623999914],
              [41.918543951000061, -1.162573677999944],
              [41.91868671900005, -1.162216758999932],
              [41.918996049000043, -1.161812249999912],
              [41.919179316000054, -1.16164043699996],
              [41.91937676200007, -1.161455330999956],
              [41.919519530000059, -1.161383946999933],
              [41.919971628000042, -1.161693276999927],
              [41.920045322000078, -1.161956471999929],
              [41.920138190000046, -1.162288141999909],
              [41.920280958000035, -1.163430283999958],
              [41.920566493000081, -1.163406488999954],
              [41.920566493000081, -1.163073364999946],
              [41.920614082000043, -1.16278782899991],
              [41.920590288000085, -1.162335731999917],
              [41.920479965000084, -1.162115086999961],
              [41.920352341000068, -1.1618598399999],
              [41.920328547000054, -1.161574303999942],
              [41.920590288000085, -1.16131256299991],
              [41.92108997400004, -1.161003233999907],
              [41.921851402000073, -1.160789081999951],
              [41.922446267000055, -1.160670108999909],
              [41.923326668000072, -1.16052734099992],
              [41.923469435000072, -1.160408367999935],
              [41.923421846000053, -1.160075243999927],
              [41.923041132000037, -1.159956270999942],
              [41.92242247300004, -1.159765913999934],
              [41.921994170000062, -1.159527967999907],
              [41.921732429000087, -1.159385199999917],
              [41.921446894000042, -1.159290021999936],
              [41.920804439000051, -1.159123458999943],
              [41.920661671000062, -1.15890930799992],
              [41.920590288000085, -1.158623772999931],
              [41.920828234000055, -1.158481004999942],
              [41.92163725000006, -1.158433415999923],
              [41.922398678000036, -1.158385825999915],
              [41.922755597000048, -1.158385825999915],
              [41.923421846000053, -1.158171674999949],
              [41.923850149000089, -1.158100290999926],
              [41.924421220000056, -1.157981317999941],
              [41.92513505800008, -1.157981317999941],
              [41.925563361000059, -1.157909933999917],
              [41.926586529000076, -1.157909933999917],
              [41.926872065000055, -1.157767166999918],
              [41.926586529000076, -1.157529219999958],
              [41.926348583000049, -1.157505425999943],
              [41.926015459000041, -1.157267479999916],
              [41.925991664000037, -1.156934354999919],
              [41.925991664000037, -1.156696408999949],
              [41.925634745000082, -1.156268105999914],
              [41.925610950000078, -1.155934981999906],
              [41.925610950000078, -1.155744624999954],
              [41.926039253000056, -1.155625651999912],
              [41.926705502000061, -1.155625651999912],
              [41.927371751000067, -1.15572083],
              [41.928394920000073, -1.156172927999933],
              [41.92901358000006, -1.156529846999945],
              [41.929371012000047, -1.156551508999939],
              [41.92979880200005, -1.156577435999907],
              [41.93048884500007, -1.156458462999922],
              [41.930893354000034, -1.156291900999918],
              [41.931369246000088, -1.155887391999954],
              [41.93182134400007, -1.155411499999957],
              [41.932059290000041, -1.154840429999922],
              [41.932249646000059, -1.154340742999921],
              [41.932368620000034, -1.153745876999949],
              [41.932558976000053, -1.153246190999937],
              [41.932939690000069, -1.152794092999955],
              [41.93343937700007, -1.152532351999923],
              [41.933843885000044, -1.152579941999932],
              [41.934034242000052, -1.152936860999944],
              [41.93412942100008, -1.153341368999918],
              [41.93412942100008, -1.15369828799993],
              [41.934492600000056, -1.153971988999956],
              [41.934444191000068, -1.15399732599991],
              [41.93430662600008, -1.15538195299996],
            ],
          ],
          [
            [
              [41.993115286000034, -1.085803603999921],
              [41.990639132000069, -1.087880592999909],
              [41.989401055000087, -1.089126785999952],
              [41.98788784900006, -1.089334484999938],
              [41.986168298000052, -1.087603660999946],
              [41.984173618000057, -1.088088291999952],
              [41.982454067000049, -1.089196018999928],
              [41.980253041000083, -1.09120377399995],
              [41.977914451000061, -1.093073060999927],
              [41.976194900000053, -1.094873114999928],
              [41.974888040000053, -1.095911605999959],
              [41.974337784000056, -1.096396235999919],
              [41.974475348000055, -1.094596182999908],
              [41.974888040000053, -1.092173033999927],
              [41.975163169000041, -1.089472950999948],
              [41.975850989000037, -1.090511444999947],
              [41.976882720000049, -1.090996074999907],
              [41.977089066000076, -1.088434456999948],
              [41.978327143000058, -1.087603660999946],
              [41.978602272000046, -1.086703632999956],
              [41.979909131000056, -1.085457437999935],
              [41.980046695000055, -1.08421124399996],
              [41.981353554000066, -1.081857318999937],
              [41.981900784000061, -1.080617976999918],
              [41.98217893900005, -1.079988024999921],
              [41.983692144000088, -1.07777256299994],
              [41.98658099000005, -1.077218697999911],
              [41.989813747000085, -1.077841795999916],
              [41.99050156800007, -1.078672594999944],
              [41.990533594000055, -1.078725189999943],
              [41.99180842700008, -1.080818821999912],
              [41.994353363000073, -1.081234220999931],
              [41.995041183000069, -1.083311213999934],
              [41.993115286000034, -1.085803603999921],
            ],
          ],
          [
            [
              [41.610851053000033, -1.612290035999933],
              [41.610649417000047, -1.612492948999943],
              [41.610481388000039, -1.612492948999943],
              [41.610078116000068, -1.612492948999943],
              [41.609876480000082, -1.612492948999943],
              [41.60967484400004, -1.612459129999934],
              [41.609513616000072, -1.612378005999915],
              [41.609311404000039, -1.612405137999929],
              [41.609126637000088, -1.612033263999933],
              [41.609204360000035, -1.611681294999926],
              [41.60923796600008, -1.610937278999927],
              [41.609170754000047, -1.610328537999919],
              [41.608935512000073, -1.609990348999929],
              [41.608599452000078, -1.609347788999912],
              [41.608364210000047, -1.608975780999913],
              [41.608095363000075, -1.608671409999943],
              [41.60775930300008, -1.608468495999944],
              [41.607628175000059, -1.608393090999925],
              [41.607110378000073, -1.6076983199999],
              [41.606009865000033, -1.606660049999959],
              [41.60572717000008, -1.606310906999909],
              [41.604496660000052, -1.604791161999913],
              [41.603866874000062, -1.60434751799994],
              [41.604197999000064, -1.60366031399991],
              [41.604507329000057, -1.602994064999962],
              [41.60457871300008, -1.602422993999937],
              [41.604435945000034, -1.601804333999951],
              [41.604459740000038, -1.601138084999945],
              [41.604769069000042, -1.60047183599994],
              [41.605149783000059, -1.5999245599999],
              [41.605816032000064, -1.5999245599999],
              [41.606315719000065, -1.600947727999937],
              [41.606363308000084, -1.601447414999939],
              [41.606110911000087, -1.601912861999949],
              [41.607316724000043, -1.602991490999955],
              [41.607776258000058, -1.603574576999961],
              [41.608898712000041, -1.60499881699991],
              [41.611374866000062, -1.606729267999924],
              [41.61343832700004, -1.607767537999962],
              [41.613231981000069, -1.609082679999915],
              [41.612956853000071, -1.610674691999918],
              [41.613157331000082, -1.611279937999939],
              [41.613371502000064, -1.611376924999945],
              [41.61343871400004, -1.611444562999907],
              [41.613573138000049, -1.611647475999916],
              [41.613539532000061, -1.611884208999925],
              [41.613371502000064, -1.612120940999944],
              [41.613237078000054, -1.612256216999924],
              [41.613136260000033, -1.612256216999924],
              [41.61300183600008, -1.612290035999933],
              [41.61286741300006, -1.612290035999933],
              [41.612531353000065, -1.612290035999933],
              [41.612128081000037, -1.612290035999933],
              [41.611590385000056, -1.612290035999933],
              [41.611388749000071, -1.612290035999933],
              [41.610851053000033, -1.612290035999933],
            ],
          ],
          [
            [
              [41.630771406000065, -1.586379066999939],
              [41.629533329000083, -1.588040314999944],
              [41.628432816000043, -1.589563124999927],
              [41.627882559000057, -1.58824797099993],
              [41.626436433000038, -1.58838036599991],
              [41.625406405000035, -1.589563124999927],
              [41.624718585000039, -1.590739840999959],
              [41.624237110000081, -1.590255310999908],
              [41.62411194200007, -1.590003384999932],
              [41.624192630000039, -1.589698883999915],
              [41.624125418000062, -1.589326871999958],
              [41.624125418000062, -1.58925923299995],
              [41.623755752000079, -1.589022497999906],
              [41.62362132800007, -1.588954859999944],
              [41.623590986000067, -1.588954859999944],
              [41.623549290000085, -1.588870938999946],
              [41.622243483000034, -1.587932298999931],
              [41.622243483000034, -1.587872641999923],
              [41.622176271000058, -1.587771183999962],
              [41.621840211000062, -1.587500629999909],
              [41.621436939000034, -1.587162436999961],
              [41.621168091000072, -1.587162436999961],
              [41.620932418000052, -1.587111614999912],
              [41.620866790000036, -1.587071253999909],
              [41.619952402000081, -1.585614241999906],
              [41.61939936400006, -1.585433406999925],
              [41.620309321000036, -1.585049119999951],
              [41.621855971000059, -1.584543484999926],
              [41.623283647000051, -1.584246051999912],
              [41.623789464000083, -1.58376422799995],
              [41.625484649000043, -1.582045050999909],
              [41.627061042000037, -1.583710672999928],
              [41.628914290000068, -1.584440941999958],
              [41.629120636000039, -1.585202347999939],
              [41.630771406000065, -1.586379066999939],
            ],
          ],
          [
            [
              [42.102009055000053, -0.951108916999942],
              [42.100938782000071, -0.951927691999913],
              [42.100125374000072, -0.952789559999928],
              [42.099055101000033, -0.952272438999955],
              [42.098640668000087, -0.952050818999908],
              [42.098883857000089, -0.951755377999916],
              [42.098954712000079, -0.95150065699994],
              [42.099397588000045, -0.951626037999915],
              [42.099740076000046, -0.951496757999962],
              [42.100681916000042, -0.950117767999927],
              [42.101238458000068, -0.948997337999913],
              [42.102094677000082, -0.947747626999956],
              [42.102351542000065, -0.946713383999906],
              [42.102608408000037, -0.945980793999922],
              [42.103635870000062, -0.944946549999941],
              [42.104406467000047, -0.944213959999956],
              [42.105519551000043, -0.94339518299995],
              [42.106161715000042, -0.94300734199993],
              [42.107446043000039, -0.942662592999909],
              [42.107745719000036, -0.942964247999953],
              [42.107916963000037, -0.943826118999937],
              [42.107874152000079, -0.944644895999943],
              [42.107488854000053, -0.945118923999928],
              [42.106761068000083, -0.945420578999915],
              [42.105562362000057, -0.94615316799991],
              [42.105091442000059, -0.947273598999914],
              [42.104706143000044, -0.948307841999906],
              [42.103892736000034, -0.948824963999925],
              [42.103464627000051, -0.949385178999933],
              [42.103164950000064, -0.950290140999925],
              [42.102009055000053, -0.951108916999942],
            ],
          ],
          [
            [
              [41.943550595000033, -1.14245812899992],
              [41.943479211000067, -1.14286263799994],
              [41.943336444000067, -1.14310058399991],
              [41.943122292000055, -1.143362323999952],
              [41.942931935000047, -1.143528886999945],
              [41.943112025000062, -1.143856932999938],
              [41.942051535000076, -1.144623438999929],
              [41.94138528600007, -1.145170714999949],
              [41.940933188000088, -1.146645980999949],
              [41.939981404000036, -1.147692942999925],
              [41.937292613000068, -1.151428696999915],
              [41.936031498000034, -1.152808784999934],
              [41.935222482000086, -1.15333226599995],
              [41.934984536000059, -1.153260881999927],
              [41.934889357000088, -1.152951551999934],
              [41.934841768000069, -1.152570837999917],
              [41.934675206000065, -1.152142534999939],
              [41.934913152000036, -1.151761821999912],
              [41.935151098000063, -1.151500080999938],
              [41.935531812000079, -1.15116695599994],
              [41.935841141000083, -1.15047691299992],
              [41.936269444000061, -1.150048609999942],
              [41.936816721000071, -1.149548922999941],
              [41.936935694000056, -1.149168208999924],
              [41.936935694000056, -1.148549548999938],
              [41.936985827000058, -1.148148479999918],
              [41.937007077000089, -1.14797847899996],
              [41.937316407000083, -1.147312229999955],
              [41.937601943000061, -1.147074282999938],
              [41.938125424000077, -1.146479417999956],
              [41.938601316000074, -1.146027320999906],
              [41.938863057000049, -1.145408660999919],
              [41.939196182000046, -1.144671027999948],
              [41.939600690000077, -1.144433081999921],
              [41.940076582000074, -1.144195135999951],
              [41.940076582000074, -1.143814421999934],
              [41.940290734000087, -1.143171966999944],
              [41.940552474000071, -1.142696074999947],
              [41.940933188000088, -1.142196387999945],
              [41.941456669000047, -1.142029825999941],
              [41.941884972000082, -1.14231536099993],
              [41.942075329000033, -1.142529512999943],
              [41.942150420000075, -1.142491966999955],
              [41.942503632000069, -1.14231536099993],
              [41.942931935000047, -1.142101209999907],
              [41.943288854000059, -1.142029825999941],
              [41.943503006000071, -1.142220182999949],
              [41.943550595000033, -1.14245812899992],
            ],
          ],
          [
            [
              [42.237910629000055, -0.785076250999907],
              [42.236947680000071, -0.786737986999924],
              [42.236328641000057, -0.788053527999921],
              [42.235090564000075, -0.790061457999911],
              [42.233921269000064, -0.791515474999926],
              [42.233577359000037, -0.79262329799991],
              [42.232683192000081, -0.793384924999941],
              [42.232683192000081, -0.791653952999923],
              [42.233508577000066, -0.790407651999942],
              [42.234196398000051, -0.789022872999908],
              [42.234264929000062, -0.788806056999931],
              [42.234787146000087, -0.788408240999956],
              [42.235086823000074, -0.787869548999936],
              [42.235022606000086, -0.787438595999959],
              [42.234594497000046, -0.786899904999927],
              [42.234080766000034, -0.7860164499999],
              [42.234123577000048, -0.785520853999913],
              [42.234380442000088, -0.785499305999906],
              [42.235643365000044, -0.785671686999933],
              [42.236542394000082, -0.785757877999913],
              [42.236842071000069, -0.785240733999956],
              [42.237270180000053, -0.784098706999941],
              [42.237447604000067, -0.783622431999959],
              [42.237979411000083, -0.783276035999961],
              [42.239492616000064, -0.781337340999926],
              [42.240423214000089, -0.781025080999939],
              [42.241143385000044, -0.780783428999939],
              [42.241549117000034, -0.78110109499994],
              [42.241762424000058, -0.781268101999956],
              [42.240730693000046, -0.782583644999931],
              [42.239698962000034, -0.782929839999952],
              [42.238460885000052, -0.784314620999908],
              [42.237910629000055, -0.785076250999907],
            ],
          ],
          [
            [
              [42.084242520000089, -0.971535031999906],
              [42.083771600000034, -0.972439988999952],
              [42.08317224700005, -0.973301851999906],
              [42.082316028000037, -0.973646596999913],
              [42.08163105400007, -0.973948248999932],
              [42.081331377000083, -0.974939390999907],
              [42.08120294400004, -0.975671973999908],
              [42.080475159000059, -0.976102904999948],
              [42.079447696000045, -0.976189090999924],
              [42.079404885000088, -0.976102927999932],
              [42.078178884000067, -0.977522121999925],
              [42.077820881000036, -0.977438961999951],
              [42.077443122000034, -0.976732785999957],
              [42.077992125000037, -0.975111762999916],
              [42.078805532000047, -0.97399134199992],
              [42.079276453000034, -0.97308638599992],
              [42.079747373000089, -0.972138335999944],
              [42.079942593000055, -0.971889426999951],
              [42.080775025000037, -0.971470466999961],
              [42.081160133000083, -0.971621217999939],
              [42.081887919000053, -0.971621217999939],
              [42.083300680000036, -0.971190286999956],
              [42.083728789000077, -0.970759354999927],
              [42.084713440000087, -0.970414608999931],
              [42.084799062000059, -0.970586981999929],
              [42.084756251000044, -0.971017913999958],
              [42.084242520000089, -0.971535031999906],
            ],
          ],
          [
            [
              [42.511270164000052, -0.435234181999931],
              [42.511274739000044, -0.436670126999957],
              [42.50968544300008, -0.440665576999947],
              [42.509008212000083, -0.44085520099992],
              [42.508276802000069, -0.439446559999908],
              [42.507012920000079, -0.440211047999924],
              [42.50659825300005, -0.438740164999956],
              [42.507599571000071, -0.438674516999924],
              [42.507558937000056, -0.438051463999955],
              [42.50701715200006, -0.437428410999928],
              [42.506159445000037, -0.437183649999952],
              [42.505310529000042, -0.436900170999934],
              [42.504714566000075, -0.436859536999918],
              [42.505676234000077, -0.43649383199994],
              [42.506204474000072, -0.436575099999914],
              [42.507166143000063, -0.435870779999959],
              [42.507667294000044, -0.436141671999906],
              [42.508619056000043, -0.436225450999927],
              [42.509332894000067, -0.43574955899993],
              [42.509925053000075, -0.434933211999919],
              [42.51075546800007, -0.434597584999949],
              [42.511270164000052, -0.435234181999931],
            ],
          ],
          [
            [
              [42.254330334000088, -0.766580408999914],
              [42.253816603000075, -0.767916370999956],
              [42.253260061000049, -0.768778280999925],
              [42.252575086000036, -0.769640190999951],
              [42.251975733000052, -0.77011424199992],
              [42.25107670400007, -0.770200432999957],
              [42.249877998000045, -0.769683286999907],
              [42.248850536000077, -0.769424713999911],
              [42.248643790000074, -0.769447837999962],
              [42.248508048000076, -0.769338522999931],
              [42.248037128000078, -0.769123044999958],
              [42.246966855000039, -0.769510904999947],
              [42.246443428000077, -0.769603887999949],
              [42.246924044000082, -0.768692089999945],
              [42.24756620800008, -0.767873274999943],
              [42.248508048000076, -0.768002561999936],
              [42.249535511000033, -0.768261134999932],
              [42.249657037000077, -0.768218151999918],
              [42.249830339000084, -0.768282623999937],
              [42.250943423000081, -0.767894764999937],
              [42.25175683100008, -0.767248331999951],
              [42.251911867000047, -0.766865257999939],
              [42.252260213000056, -0.767019099999914],
              [42.252489465000053, -0.766882077999924],
              [42.253260061000049, -0.766192548999925],
              [42.253730981000047, -0.765287541999953],
              [42.254159091000076, -0.763736101999939],
              [42.254672822000089, -0.763693006999915],
              [42.255015309000044, -0.763994675999925],
              [42.254929687000072, -0.76485658699994],
              [42.254501578000088, -0.765675401999943],
              [42.254330334000088, -0.766580408999914],
            ],
          ],
          [
            [
              [42.119904023000061, -0.920383203999961],
              [42.119561535000059, -0.921072704999915],
              [42.118405640000049, -0.920857235999961],
              [42.118063153000037, -0.921374361999938],
              [42.11819158600008, -0.922150049999914],
              [42.118020342000079, -0.923485957999958],
              [42.117378178000081, -0.923485957999958],
              [42.116821636000054, -0.923572144999923],
              [42.116564770000082, -0.92456330199991],
              [42.116008228000055, -0.92564064599992],
              [42.115109199000074, -0.925123520999932],
              [42.115109199000074, -0.924304738999922],
              [42.115751363000072, -0.922408612999959],
              [42.116479148000053, -0.920771048999939],
              [42.116736014000082, -0.919305858999905],
              [42.117257935000055, -0.91807999599996],
              [42.117549422000081, -0.91796996599993],
              [42.118085579000081, -0.91796996599993],
              [42.118105964000051, -0.91818541899994],
              [42.118320018000077, -0.91865945099994],
              [42.119004993000033, -0.919219670999951],
              [42.119904023000061, -0.920383203999961],
            ],
          ],
          [
            [
              [41.758245612000053, -1.380776200999946],
              [41.757626952000066, -1.381561422999937],
              [41.75700829200008, -1.382156287999919],
              [41.756437221000056, -1.382679769999925],
              [41.755889945000035, -1.383322223999926],
              [41.755414053000038, -1.383964677999927],
              [41.754771599000037, -1.384369186999947],
              [41.753986377000047, -1.385035435999953],
              [41.753558074000068, -1.385487532999946],
              [41.753129771000033, -1.386106192999932],
              [41.752899539000055, -1.386443865999922],
              [41.752822282000068, -1.38655717599994],
              [41.752783586000078, -1.386613930999943],
              [41.752392138000062, -1.386320344999945],
              [41.75243972700008, -1.385987219999947],
              [41.752868030000059, -1.385416149999912],
              [41.752915619000078, -1.384940256999926],
              [41.753010798000048, -1.384392980999962],
              [41.753010798000048, -1.384059856999954],
              [41.752987003000044, -1.383774321999908],
              [41.752749057000074, -1.38348878599993],
              [41.752558700000066, -1.383108071999914],
              [41.752392138000062, -1.382774947999906],
              [41.752344548000053, -1.382465617999912],
              [41.75258249500007, -1.382180082999923],
              [41.752915619000078, -1.382322849999923],
              [41.752987003000044, -1.382608385999959],
              [41.753224949000071, -1.383036688999937],
              [41.753534279000064, -1.383227045999945],
              [41.753605663000087, -1.383036688999937],
              [41.754033966000065, -1.382870125999943],
              [41.754343296000059, -1.38279874299991],
              [41.75443847400004, -1.382703563999939],
              [41.754866777000075, -1.382632179999916],
              [41.755223696000087, -1.38256079599995],
              [41.755223696000087, -1.382156287999919],
              [41.754866777000075, -1.381727984999941],
              [41.754605036000044, -1.381299681999906],
              [41.754652626000052, -1.381180708999921],
              [41.755033339000079, -1.381299681999906],
              [41.755318875000057, -1.381371065999929],
              [41.75539025900008, -1.381156913999916],
              [41.75539025900008, -1.380895173999932],
              [41.755485437000061, -1.380752405999942],
              [41.75591374000004, -1.380657227999961],
              [41.756437221000056, -1.380657227999961],
              [41.756675168000072, -1.380443075999949],
              [41.756865524000034, -1.379800621999948],
              [41.757912487000056, -1.379324728999961],
              [41.758364585000038, -1.379253345999928],
              [41.758888066000054, -1.379491291999955],
              [41.758983245000081, -1.379967183999952],
              [41.758554942000046, -1.380371691999926],
              [41.758245612000053, -1.380776200999946],
            ],
          ],
          [
            [
              [42.40204127800007, -0.561486369999955],
              [42.400969013000065, -0.56302842599996],
              [42.400765111000055, -0.563036978999946],
              [42.400144353000087, -0.562993881999944],
              [42.399630622000075, -0.563597243999936],
              [42.399352351000061, -0.564114411999924],
              [42.399330945000088, -0.56467467799996],
              [42.39950865600008, -0.564896512999951],
              [42.399022263000063, -0.565492605999907],
              [42.398093481000046, -0.566026887999953],
              [42.397725535000063, -0.565730561999942],
              [42.397083371000065, -0.565838305999932],
              [42.396376991000068, -0.566506313999923],
              [42.395831055000087, -0.567416569999921],
              [42.395141451000086, -0.567869319999943],
              [42.394985636000058, -0.56835949799995],
              [42.394643149000046, -0.568531887999939],
              [42.394215039000073, -0.568273303999945],
              [42.394236445000047, -0.567325162999907],
              [42.394646699000077, -0.56672702599991],
              [42.394630968000058, -0.566666845999919],
              [42.394633153000086, -0.566640446999941],
              [42.394857203000072, -0.566312375999928],
              [42.395360861000086, -0.566096889999926],
              [42.395841854000082, -0.566096889999926],
              [42.396312775000069, -0.565622818999941],
              [42.397383048000052, -0.564868615999956],
              [42.398046617000034, -0.564308349999919],
              [42.398453321000034, -0.563532597999938],
              [42.398881430000074, -0.562821491999955],
              [42.399309540000047, -0.562218128999916],
              [42.399673432000043, -0.56191644799992],
              [42.399953328000038, -0.561819286999935],
              [42.400122947000057, -0.562024190999921],
              [42.400679489000083, -0.562088836999919],
              [42.401064788000042, -0.561614766999924],
              [42.401321653000082, -0.561162243999945],
              [42.401816487000076, -0.560951490999912],
              [42.40204127800007, -0.561486369999955],
            ],
          ],
          [
            [
              [42.358617753000033, -0.606155515999944],
              [42.358253860000048, -0.606780421999929],
              [42.357761535000066, -0.607146746999945],
              [42.357333425000036, -0.607620813999915],
              [42.357265852000069, -0.607813551999925],
              [42.357204993000039, -0.607987137999942],
              [42.357194463000042, -0.60827334399994],
              [42.356211890000054, -0.60885318399994],
              [42.355676752000079, -0.608575664999933],
              [42.35566379900007, -0.608568947999913],
              [42.355342717000042, -0.608310365999955],
              [42.354828986000086, -0.608008686999938],
              [42.354763065000043, -0.607925734999924],
              [42.354550715000073, -0.607340682999961],
              [42.354400877000046, -0.606737324999926],
              [42.35444368800006, -0.606371000999957],
              [42.354786175000072, -0.606241709999949],
              [42.355171474000088, -0.606371000999957],
              [42.355620988000055, -0.606371000999957],
              [42.355920665000042, -0.606155515999944],
              [42.355978169000082, -0.605866071999912],
              [42.356541423000067, -0.605509060999907],
              [42.357247804000053, -0.605422866999959],
              [42.357761535000066, -0.605034992999947],
              [42.358318077000035, -0.604841056999931],
              [42.358724781000035, -0.604819507999935],
              [42.359024457000089, -0.605078089999949],
              [42.359045863000063, -0.605530608999914],
              [42.358617753000033, -0.606155515999944],
            ],
          ],
          [
            [
              [42.436112921000074, -0.519937786999947],
              [42.435948185000086, -0.520153209999933],
              [42.435656730000062, -0.520660088999932],
              [42.435453978000055, -0.520812152999952],
              [42.435276571000088, -0.520837495999956],
              [42.435213211000075, -0.520900855999912],
              [42.435175195000056, -0.52109093599995],
              [42.435099163000075, -0.521166967999932],
              [42.434921756000051, -0.521166967999932],
              [42.434820380000076, -0.521040247999906],
              [42.434744348000038, -0.520926199999906],
              [42.434579613000039, -0.521002231999944],
              [42.434389533000058, -0.521230326999955],
              [42.433857310000064, -0.521737205999955],
              [42.433768607000047, -0.522028661999911],
              [42.433781279000073, -0.522269428999948],
              [42.433755935000079, -0.522421492999911],
              [42.433654559000047, -0.522598899999934],
              [42.433477151000034, -0.522776307999948],
              [42.433325088000061, -0.522712947999935],
              [42.433274400000073, -0.522383476999948],
              [42.433058976000041, -0.522408820999942],
              [42.432780193000042, -0.522586227999909],
              [42.432463394000081, -0.522788979999916],
              [42.432184610000036, -0.522991730999934],
              [42.432133923000038, -0.52332120199992],
              [42.432133923000038, -0.523739376999913],
              [42.432133923000038, -0.524195567999925],
              [42.432045219000088, -0.524537710999937],
              [42.431982744000038, -0.524658755999951],
              [42.431878555000083, -0.524860621999949],
              [42.431690404000051, -0.524791150999931],
              [42.431652388000089, -0.524791150999931],
              [42.431601700000044, -0.524791150999931],
              [42.431512996000038, -0.524816494999925],
              [42.431386276000069, -0.524879854999938],
              [42.431322917000045, -0.524968557999955],
              [42.431259557000033, -0.525057261999962],
              [42.431196197000077, -0.525120621999918],
              [42.431120165000038, -0.525082605999955],
              [42.431082149000076, -0.524917869999911],
              [42.431132837000064, -0.524778478999906],
              [42.431386276000069, -0.524613742999918],
              [42.431398948000037, -0.524474351999913],
              [42.431576356000051, -0.523980144999939],
              [42.431601700000044, -0.523650673999953],
              [42.43161437200007, -0.5234352499999],
              [42.43161437200007, -0.52323249899996],
              [42.431804451000062, -0.522915698999952],
              [42.431817123000087, -0.52261157199996],
              [42.431741092000038, -0.522294772999942],
              [42.431804451000062, -0.522155380999948],
              [42.431905827000037, -0.522066676999941],
              [42.432146595000063, -0.522041333999937],
              [42.432324002000087, -0.522028661999911],
              [42.432412706000036, -0.521927285999936],
              [42.432400034000068, -0.521711861999961],
              [42.432387362000043, -0.521534454999937],
              [42.432564770000056, -0.521357046999924],
              [42.432894241000042, -0.521166967999932],
              [42.433135008000079, -0.520900855999912],
              [42.433325088000061, -0.52071077699992],
              [42.43345180700004, -0.520546040999932],
              [42.433603871000059, -0.520254585999908],
              [42.433831966000071, -0.519887098999959],
              [42.434148766000078, -0.51973503499994],
              [42.434376861000089, -0.51984908299994],
              [42.434554269000046, -0.520051833999958],
              [42.434769692000089, -0.5200898499999],
              [42.435010459000068, -0.520115193999914],
              [42.435251227000037, -0.5200898499999],
              [42.435301915000082, -0.519925114999921],
              [42.435403291000057, -0.519722362999914],
              [42.435568026000055, -0.519405563999953],
              [42.435846809000054, -0.51896204499991],
              [42.435960857000055, -0.518746621999924],
              [42.436201625000081, -0.518341118999956],
              [42.436303, -0.518062334999911],
              [42.436581784000055, -0.517796223999937],
              [42.436847895000085, -0.517555456999958],
              [42.437164694000046, -0.517340032999925],
              [42.437266070000078, -0.517200640999931],
              [42.437367446000053, -0.517035905999933],
              [42.437595541000064, -0.516871169999945],
              [42.437861652000038, -0.516719106999915],
              [42.438039060000051, -0.516681090999953],
              [42.438178452000045, -0.516719106999915],
              [42.438203796000039, -0.516947201999926],
              [42.438140436000083, -0.51723865699995],
              [42.438026388000083, -0.517454080999926],
              [42.437722261000033, -0.517644159999918],
              [42.437367446000053, -0.517808895999906],
              [42.437215382000034, -0.518087678999962],
              [42.436974615000054, -0.518341118999956],
              [42.436822551000034, -0.518531197999948],
              [42.436632471000053, -0.518683261999911],
              [42.436607128000048, -0.518924028999947],
              [42.436607128000048, -0.519126780999954],
              [42.436429720000035, -0.519354875999909],
              [42.436112921000074, -0.519937786999947],
            ],
          ],
          [
            [
              [41.949713399000075, -1.139055499999927],
              [41.949594426000033, -1.139674159999913],
              [41.949332685000059, -1.139602775999947],
              [41.948832999000047, -1.139412418999939],
              [41.948737820000076, -1.139721748999932],
              [41.949047150000069, -1.140126256999906],
              [41.949166123000055, -1.140554559999941],
              [41.948951972000089, -1.140721122999935],
              [41.948595052000087, -1.14098286299992],
              [41.948380901000064, -1.141315987999917],
              [41.948285723000083, -1.141649111999925],
              [41.948071571000071, -1.141863263999937],
              [41.94780983000004, -1.142196387999945],
              [41.947405322000066, -1.142386744999953],
              [41.947143581000034, -1.14286263799994],
              [41.946929430000068, -1.143171966999944],
              [41.946453538000071, -1.143528886999945],
              [41.946334565000086, -1.143885805999957],
              [41.945882467000047, -1.144266519999917],
              [41.945596932000058, -1.144528259999959],
              [41.94540657500005, -1.145099330999926],
              [41.944906888000048, -1.145432454999934],
              [41.944624017000081, -1.145809616999941],
              [41.944621353000059, -1.14581316899995],
              [41.944448467000086, -1.145986054999923],
              [41.944382940000082, -1.146051581999927],
              [41.944312023000066, -1.145670400999961],
              [41.944621353000059, -1.144837589999952],
              [41.944764120000059, -1.144337902999951],
              [41.945477959000073, -1.14355268099996],
              [41.945930056000066, -1.143076788999906],
              [41.945977645000085, -1.142672280999932],
              [41.946215591000055, -1.142053620999945],
              [41.946762868000064, -1.14153013899994],
              [41.947595679000074, -1.141054246999943],
              [41.94821433900006, -1.140721122999935],
              [41.948285723000083, -1.140316613999914],
              [41.948285723000083, -1.139888310999936],
              [41.948285723000083, -1.139602775999947],
              [41.948595052000087, -1.139317240999958],
              [41.948951972000089, -1.139007910999908],
              [41.949380275000067, -1.138960320999956],
              [41.949404069000082, -1.138484428999959],
              [41.949642015000052, -1.137794385999939],
              [41.949642015000052, -1.137294698999938],
              [41.950165497000057, -1.136866395999959],
              [41.950712773000078, -1.13710434199993],
              [41.950855540000077, -1.13788956399992],
              [41.95061759400005, -1.138555812999925],
              [41.950332059000061, -1.138722374999929],
              [41.949808578000045, -1.138793758999952],
              [41.949713399000075, -1.139055499999927],
            ],
          ],
          [
            [
              [42.382613278000065, -0.583303368999907],
              [42.382677494000063, -0.583486531999938],
              [42.382634683000049, -0.583712791999915],
              [42.38239922300005, -0.583895954999946],
              [42.38224938500008, -0.583992923999915],
              [42.382099547000053, -0.584046794999949],
              [42.381767762000038, -0.584262280999951],
              [42.381500194000068, -0.584930286999906],
              [42.381446680000067, -0.585393581999938],
              [42.381350356000041, -0.585835327999916],
              [42.381189815000084, -0.586093910999921],
              [42.38100786800004, -0.586093910999921],
              [42.380761705000054, -0.586050813999918],
              [42.380590462000043, -0.586244750999924],
              [42.380312191000087, -0.58661107699993],
              [42.380173055000057, -0.587042047999944],
              [42.38016235200007, -0.587613085999919],
              [42.379819865000059, -0.587710053999956],
              [42.379670027000088, -0.587828571999921],
              [42.379616513000087, -0.588237994999929],
              [42.379577071000085, -0.588641669999959],
              [42.378026384000066, -0.590358829999957],
              [42.377867372000082, -0.589558452999938],
              [42.37790407600005, -0.589326197999924],
              [42.378310780000049, -0.588658192999958],
              [42.378824511000062, -0.588291866999953],
              [42.379038566000077, -0.587602311999945],
              [42.379274026000076, -0.586998951999931],
              [42.379680729000086, -0.586137008999913],
              [42.380108839000059, -0.585576745999958],
              [42.380387110000072, -0.585296613999958],
              [42.380515542000069, -0.584650155999952],
              [42.380665381000085, -0.584089892999941],
              [42.380922246000068, -0.583766663999938],
              [42.381221923000055, -0.583551177999936],
              [42.381366190000051, -0.583405945999914],
              [42.38141457200004, -0.58342188599994],
              [42.38196041100008, -0.583475756999917],
              [42.38228149300005, -0.583324916999914],
              [42.382613278000065, -0.583303368999907],
            ],
          ],
          [
            [
              [42.406009450000056, -0.555645779999907],
              [42.405517124000085, -0.555688876999909],
              [42.405367286000057, -0.555171708999922],
              [42.40547431300007, -0.554158919999907],
              [42.405781498000067, -0.55348665799994],
              [42.406801452000082, -0.552909094999961],
              [42.40744361600008, -0.552391925999927],
              [42.408257023000033, -0.551723915999958],
              [42.408353955000052, -0.551450691999946],
              [42.409794658000067, -0.55126355099992],
              [42.40984343100007, -0.552049148999913],
              [42.409541351000087, -0.552090243999942],
              [42.408856376000074, -0.552628960999925],
              [42.408149996000077, -0.553232324999954],
              [42.407828914000049, -0.553318519999948],
              [42.407122534000052, -0.553771042999927],
              [42.406694425000069, -0.554719185999943],
              [42.406571098000086, -0.55518475599996],
              [42.406480370000054, -0.555301000999918],
              [42.406009450000056, -0.555645779999907],
            ],
          ],
          [
            [
              [41.751265757000056, -1.390079258999947],
              [41.750722180000082, -1.390747909999959],
              [41.750420192000036, -1.391234200999918],
              [41.750299397000049, -1.391538132999926],
              [41.750299397000049, -1.391842064999935],
              [41.751507347000086, -1.392085210999937],
              [41.751567745000045, -1.392510714999958],
              [41.750782577000052, -1.393057792999912],
              [41.750601385000039, -1.393057792999912],
              [41.750359795000065, -1.392449928999952],
              [41.750178602000062, -1.392206782999949],
              [41.749695422000059, -1.392206782999949],
              [41.749453832000086, -1.392814646999909],
              [41.749031049000052, -1.393361723999931],
              [41.748608266000076, -1.393665655999939],
              [41.748487471000033, -1.393300937999925],
              [41.748910254000066, -1.392693073999908],
              [41.748789459000079, -1.392085210999937],
              [41.748402414000054, -1.391584377999948],
              [41.748417130000064, -1.39093055099994],
              [41.748651923000068, -1.390483248999942],
              [41.749031049000052, -1.390626336999958],
              [41.749997409000059, -1.389957685999946],
              [41.750722180000082, -1.3898969],
              [41.751265757000056, -1.390079258999947],
            ],
          ],
          [
            [
              [41.784685324000066, -1.347256616999914],
              [41.784372094000048, -1.34732097899996],
              [41.784348299000044, -1.346821291999959],
              [41.784705218000056, -1.346392988999924],
              [41.785181110000053, -1.345917096999926],
              [41.785371467000061, -1.345583971999929],
              [41.785728386000073, -1.344798749999939],
              [41.786013922000052, -1.344084911999914],
              [41.786561198000072, -1.343513840999947],
              [41.78698950100005, -1.343299689999924],
              [41.787370214000077, -1.342800002999923],
              [41.787536777000071, -1.342395494999948],
              [41.787727134000079, -1.341967191999913],
              [41.787941285000045, -1.34156268299995],
              [41.788274410000042, -1.341086790999952],
              [41.788893070000086, -1.340587103999951],
              [41.789226194000037, -1.340420541999947],
              [41.789416551000045, -1.340539514999932],
              [41.789440346000049, -1.340896433999944],
              [41.789202399000089, -1.341253352999956],
              [41.788845480000077, -1.341538888999935],
              [41.78836958800008, -1.342133753999917],
              [41.788131642000053, -1.342633440999919],
              [41.787750928000037, -1.343846965999944],
              [41.787394009000081, -1.34437044699996],
              [41.786822938000057, -1.344774955999924],
              [41.78634704600006, -1.345227052999917],
              [41.785609413000088, -1.345798123999941],
              [41.785323878000042, -1.346178837999958],
              [41.785109727000076, -1.346726113999921],
              [41.784931881000034, -1.346948421999912],
              [41.78472901300006, -1.347202005999918],
              [41.784685324000066, -1.347256616999914],
            ],
          ],
          [
            [
              [42.408490992000054, -0.556996620999939],
              [42.407550168000057, -0.557628033999947],
              [42.407143939000036, -0.557434321999949],
              [42.406887074000053, -0.557455869999956],
              [42.406673019000038, -0.557757551999941],
              [42.406266315000039, -0.558339366999917],
              [42.405838206000055, -0.558791888999906],
              [42.405559935000042, -0.559136667999951],
              [42.405364001000066, -0.559714314999951],
              [42.405000305000044, -0.559822],
              [42.404960582000058, -0.559933969999918],
              [42.404832149000072, -0.560343393999915],
              [42.404639500000087, -0.560774367999954],
              [42.404232796000088, -0.5610760499999],
              [42.403826092000088, -0.561291535999942],
              [42.403483605000076, -0.561700960999929],
              [42.403183929000079, -0.561873350999917],
              [42.402862847000051, -0.561550120999925],
              [42.403183929000079, -0.5610760499999],
              [42.403526416000034, -0.560817464999957],
              [42.403997336000089, -0.560601978999955],
              [42.404275607000045, -0.560321845999908],
              [42.404318418000059, -0.560235650999914],
              [42.404408016000048, -0.559784663999949],
              [42.404459460000055, -0.559875292999948],
              [42.404786865000062, -0.559470789999921],
              [42.404917771000044, -0.55930905799994],
              [42.405217448000087, -0.559007375999954],
              [42.405196042000057, -0.558705693999912],
              [42.405024798000056, -0.558404012999915],
              [42.405046204000087, -0.55792994199993],
              [42.405410097000072, -0.55754206499995],
              [42.405795395000041, -0.557240383999954],
              [42.406351937000068, -0.557046444999912],
              [42.406801452000082, -0.556917152999915],
              [42.407315183000037, -0.556658568999921],
              [42.407657670000049, -0.556313789999933],
              [42.407964982000067, -0.556268515999932],
              [42.408490992000054, -0.556996620999939],
            ],
          ],
          [
            [
              [41.828064852000068, -1.277020772999947],
              [41.827886392000039, -1.277032669999926],
              [41.827672241000073, -1.27692559399992],
              [41.827517576000048, -1.276604366999948],
              [41.827493781000044, -1.276437804999944],
              [41.827636549000033, -1.275878630999955],
              [41.827755522000075, -1.275557403999926],
              [41.827767419000054, -1.27530756099992],
              [41.827791214000058, -1.275093408999908],
              [41.827910187000043, -1.274724592999917],
              [41.828041057000064, -1.274391467999919],
              [41.82814813300007, -1.274165418999928],
              [41.82842177100008, -1.273939370999926],
              [41.828624025000067, -1.273760910999954],
              [41.829052328000046, -1.27359434899995],
              [41.829421145000083, -1.273808499999916],
              [41.829730475000076, -1.274094035999951],
              [41.830039805000069, -1.274498543999925],
              [41.830004113000086, -1.274819770999954],
              [41.829849448000061, -1.275152895999952],
              [41.829611502000034, -1.275378944999943],
              [41.82934976100006, -1.275533608999922],
              [41.829088020000086, -1.275712068999951],
              [41.828814382000076, -1.275878630999955],
              [41.828742998000052, -1.276116576999925],
              [41.828695409000034, -1.276330728999937],
              [41.828493155000046, -1.27650918799992],
              [41.828243312000041, -1.276711442999954],
              [41.828064852000068, -1.277020772999947],
            ],
          ],
          [
            [
              [41.774045233000038, -1.361478771999941],
              [41.773735903000045, -1.361550155999907],
              [41.773664519000079, -1.361288414999933],
              [41.773759698000049, -1.360931495999921],
              [41.77416420600008, -1.360574576999909],
              [41.774473536000073, -1.360217657999954],
              [41.774568714000054, -1.35974176499991],
              [41.77494942800007, -1.359265872999913],
              [41.775330142000087, -1.35881377599992],
              [41.775901212000065, -1.358433061999961],
              [41.776400899000066, -1.357957169999906],
              [41.776829202000044, -1.357576455999947],
              [41.777305094000042, -1.357576455999947],
              [41.777328889000046, -1.357814401999917],
              [41.777162327000042, -1.35812373199991],
              [41.776876791000063, -1.358456855999918],
              [41.776353310000047, -1.358932748999962],
              [41.77587741800005, -1.359456229999921],
              [41.775663266000038, -1.360003505999941],
              [41.775187374000041, -1.360598370999924],
              [41.774854250000033, -1.360931495999921],
              [41.774640098000077, -1.361264619999929],
              [41.774145198000042, -1.361450207999951],
              [41.774045233000038, -1.361478771999941],
            ],
          ],
          [
            [
              [41.630476109000085, -1.582771071999957],
              [41.630449890000079, -1.582801036999911],
              [41.630430545000081, -1.582790056999954],
              [41.630331642000044, -1.582733922999921],
              [41.630355437000048, -1.582198543999937],
              [41.630403026000067, -1.581972494999945],
              [41.630593383000075, -1.581686959999956],
              [41.630890816000033, -1.581341937999923],
              [41.631057378000037, -1.581092094999917],
              [41.631176351000079, -1.580770866999956],
              [41.631438092000053, -1.580390153999929],
              [41.631711730000063, -1.58006892599991],
              [41.631973471000038, -1.579854774999944],
              [41.632270903000062, -1.579497855999932],
              [41.632485055000075, -1.578950579999912],
              [41.632663514000058, -1.578653146999955],
              [41.63274679500006, -1.578272432999938],
              [41.632925255000089, -1.577986897999949],
              [41.63311561200004, -1.577784643999962],
              [41.633139406000055, -1.577511005999952],
              [41.63329407100008, -1.57729685399994],
              [41.633520120000071, -1.577130291999936],
              [41.633627196000077, -1.577284956999961],
              [41.633579607000058, -1.577760848999958],
              [41.633579607000058, -1.578201048999915],
              [41.633543915000075, -1.578581762999931],
              [41.633044228000074, -1.579093346999912],
              [41.63274679500006, -1.579426471999909],
              [41.632366082000033, -1.579795287999957],
              [41.632247109000048, -1.580199796999921],
              [41.631937779000054, -1.580723277999937],
              [41.631485681000072, -1.581472807999944],
              [41.630997892000039, -1.582174748999932],
              [41.630476109000085, -1.582771071999957],
            ],
          ],
          [
            [
              [42.362492142000065, -0.606349451999961],
              [42.361978411000052, -0.606586485999912],
              [42.361849978000066, -0.60654338899991],
              [42.361828573000082, -0.606198612999947],
              [42.362064033000081, -0.605832287999931],
              [42.362248632000046, -0.605671233999942],
              [42.362234450000074, -0.605660315999955],
              [42.362297547000082, -0.605628556999932],
              [42.36270619700008, -0.605422866999959],
              [42.36277053200007, -0.605380768999908],
              [42.363134306000063, -0.605142735999948],
              [42.363263503000042, -0.604939516999934],
              [42.363265668000054, -0.604936111999962],
              [42.363476794000064, -0.604604022999922],
              [42.363883497000074, -0.604173052999954],
              [42.364461445000074, -0.603914470999939],
              [42.364695088000076, -0.603518336999912],
              [42.364852499000051, -0.603551697999933],
              [42.365198976000045, -0.603793168999914],
              [42.365146420000087, -0.603936018999946],
              [42.364739716000088, -0.604668668999921],
              [42.364461445000074, -0.604927250999935],
              [42.363990525000077, -0.605099638999945],
              [42.363690848000033, -0.605509060999907],
              [42.363477357000079, -0.605752010999936],
              [42.363209313000084, -0.606057041999918],
              [42.363241513000048, -0.606058288999918],
              [42.362492142000065, -0.606349451999961],
            ],
          ],
          [
            [
              [42.353052333000051, -0.611219411999912],
              [42.352752656000064, -0.611284056999921],
              [42.352538602000038, -0.610917732999951],
              [42.35288108900005, -0.610508311999922],
              [42.353116549000049, -0.610249729999907],
              [42.353246717000047, -0.609914854999943],
              [42.353480442000034, -0.609797211999933],
              [42.353608875000077, -0.60940933899991],
              [42.353587469000047, -0.60876288399993],
              [42.353673091000076, -0.60841810799991],
              [42.353972768000062, -0.60822417199995],
              [42.354407173000084, -0.608182522999925],
              [42.354529310000089, -0.608568947999913],
              [42.354700553000043, -0.608784431999936],
              [42.35477467700008, -0.609008288999917],
              [42.354828986000086, -0.609172304999959],
              [42.354743364000058, -0.609538629999918],
              [42.354379471000072, -0.609775662999937],
              [42.353780119000078, -0.610508311999922],
              [42.353748192000069, -0.610594017999915],
              [42.353614954000079, -0.610951691999958],
              [42.353191127000059, -0.6110796899999],
              [42.353052333000051, -0.611219411999912],
            ],
          ],
          [
            [
              [41.639294875000076, -1.563929353999924],
              [41.638386118000085, -1.564602429999923],
              [41.638164631000052, -1.564476629999945],
              [41.638413953000054, -1.563767164999945],
              [41.639018838000084, -1.563306283999907],
              [41.639225295000074, -1.563206384999944],
              [41.639499719000071, -1.562860371999932],
              [41.639750280000044, -1.562681398999928],
              [41.639917321000041, -1.56235924899994],
              [41.639845732000083, -1.562072892999936],
              [41.64053775900004, -1.561321208999914],
              [41.64090834700005, -1.561521027999959],
              [41.640039843000068, -1.562865422999948],
              [41.639294875000076, -1.563929353999924],
            ],
          ],
          [
            [
              [42.403248257000087, -0.556127154999956],
              [42.403012685000078, -0.556270691999941],
              [42.402306305000081, -0.55605520499995],
              [42.402242357000034, -0.555604579999908],
              [42.402306305000081, -0.55551648799991],
              [42.402713008000035, -0.555344097999921],
              [42.403419389000078, -0.55495622199993],
              [42.403633443000047, -0.554589893999946],
              [42.403975931000048, -0.55428821199996],
              [42.404382634000058, -0.554525247999948],
              [42.404382634000058, -0.554805380999937],
              [42.404254202000061, -0.555193256999928],
              [42.40350501000006, -0.555688876999909],
              [42.403248257000087, -0.556127154999956],
            ],
          ],
          [
            [
              [42.384571878000088, -0.580976118999956],
              [42.384454148000088, -0.581213153999954],
              [42.384272201000044, -0.581234701999961],
              [42.384090255000046, -0.581245476999925],
              [42.384068849000073, -0.581493285999954],
              [42.384100958000033, -0.581751868999959],
              [42.383951119000074, -0.581924257999958],
              [42.383737065000048, -0.582506069999909],
              [42.383445683000048, -0.582666067999924],
              [42.383148697000081, -0.583144422999908],
              [42.382923657000049, -0.583163301999946],
              [42.38270960300008, -0.582937041999912],
              [42.382784522000065, -0.582570715999907],
              [42.382955765000077, -0.582128968999939],
              [42.383244739000077, -0.581644125999958],
              [42.383565821000047, -0.581504059999929],
              [42.383779876000062, -0.581223927999929],
              [42.383875080000053, -0.580963788999952],
              [42.384090255000046, -0.581029975999911],
              [42.384325715000045, -0.580911458999935],
              [42.384464851000075, -0.580814489999909],
              [42.384595923000063, -0.580855089999943],
              [42.384571878000088, -0.580976118999956],
            ],
          ],
          [
            [
              [42.401286524000056, -0.55897809399994],
              [42.400679489000083, -0.55958919099993],
              [42.400315596000041, -0.559567641999934],
              [42.399951704000046, -0.559265959999948],
              [42.400337002000072, -0.558447109999918],
              [42.401171815000055, -0.558016135999935],
              [42.401292190000049, -0.557978528999911],
              [42.401286524000056, -0.558032716999946],
              [42.401286524000056, -0.55897809399994],
            ],
          ],
          [
            [
              [42.455766059000041, -0.472490746999938],
              [42.455709508000041, -0.472492513999953],
              [42.455677262000052, -0.472451172999911],
              [42.455626277000079, -0.472385807999956],
              [42.455566791000081, -0.472147861999929],
              [42.455602483000064, -0.472112169999946],
              [42.455864223000049, -0.472064580999927],
              [42.455971299000055, -0.471921812999938],
              [42.455947504000051, -0.471755250999934],
              [42.455899915000089, -0.471624380999913],
              [42.455840429000034, -0.471457817999919],
              [42.455840429000034, -0.471231768999928],
              [42.455864223000049, -0.471112795999943],
              [42.456054580000057, -0.470993822999958],
              [42.456197348000046, -0.47089864499992],
              [42.456209245000082, -0.470755876999931],
              [42.456221142000061, -0.470648801999914],
              [42.456256834000044, -0.470482238999921],
              [42.456375807000086, -0.470398957999919],
              [42.456494780000071, -0.470422752999923],
              [42.456685137000079, -0.470601211999906],
              [42.456816008000033, -0.47089864499992],
              [42.456780316000049, -0.471100898999907],
              [42.456554267000058, -0.471207974999913],
              [42.456387705000054, -0.471303152999951],
              [42.456280629000048, -0.471410228999957],
              [42.456280629000048, -0.471600585999909],
              [42.456280629000048, -0.471814736999931],
              [42.456280629000048, -0.472112169999946],
              [42.456280629000048, -0.472338218999937],
              [42.456244937000065, -0.472457191999922],
              [42.456078375000061, -0.472480986999926],
              [42.455766059000041, -0.472490746999938],
            ],
          ],
          [
            [
              [42.455680481000059, -0.468280629999924],
              [42.455650072000083, -0.468281237999918],
              [42.455507304000037, -0.46822175099993],
              [42.455388331000051, -0.468114675999914],
              [42.455352639000068, -0.467888626999923],
              [42.455376434000073, -0.467686372999935],
              [42.455531099000041, -0.467543604999946],
              [42.455757148000089, -0.467460323999944],
              [42.455947504000051, -0.467305658999919],
              [42.455876121000074, -0.46716289099993],
              [42.455769045000068, -0.467127198999947],
              [42.455697661000045, -0.466913047999924],
              [42.455542996000077, -0.466710793999937],
              [42.455626277000079, -0.466591820999952],
              [42.455840429000034, -0.466591820999952],
              [42.456173553000042, -0.466627511999945],
              [42.456352013000071, -0.466805971999918],
              [42.456423397000037, -0.467008225999962],
              [42.456280629000048, -0.467091506999907],
              [42.456197348000046, -0.467174787999909],
              [42.456292526000084, -0.467246171999932],
              [42.456375807000086, -0.467400836999957],
              [42.456375807000086, -0.467519809999942],
              [42.456173553000042, -0.4675674],
              [42.455971299000055, -0.467626885999948],
              [42.455888018000053, -0.467769653999937],
              [42.45585232600007, -0.46798380499996],
              [42.455782078000084, -0.468105142999946],
              [42.455721456000049, -0.468209853999952],
              [42.455680481000059, -0.468280629999924],
            ],
          ],
          [
            [
              [42.402563170000064, -0.557132639999907],
              [42.402713008000035, -0.557477418999952],
              [42.402696149000064, -0.557517727999937],
              [42.402536346000034, -0.55735685499991],
              [42.40237052100008, -0.557843746999936],
              [42.402113655000051, -0.55792994199993],
              [42.401728357000081, -0.557843746999936],
              [42.401492897000082, -0.557391223999957],
              [42.401771168000039, -0.556938701999911],
              [42.401806042000089, -0.55691275199996],
              [42.401793514000076, -0.556906445999914],
              [42.402242088000037, -0.556938701999911],
              [42.402563170000064, -0.557132639999907],
            ],
          ],
          [
            [
              [42.387619357000062, -0.577839378999954],
              [42.387515129000064, -0.577862330999949],
              [42.387333183000067, -0.577614521999919],
              [42.387440210000079, -0.577399035999917],
              [42.387643562000051, -0.57723742099995],
              [42.387793400000078, -0.577021934999948],
              [42.387889725000036, -0.576741802999948],
              [42.387946649000071, -0.576480746999948],
              [42.388426106000054, -0.57601664699996],
              [42.388446267000063, -0.576030697999954],
              [42.388414159000035, -0.576224635999949],
              [42.388296429000036, -0.576407798999924],
              [42.388285726000049, -0.576601736999919],
              [42.388274859000035, -0.576776767999945],
              [42.387619357000062, -0.577839378999954],
            ],
          ],
          [
            [
              [42.389657566000039, -0.575777330999927],
              [42.389570054000046, -0.575804437999921],
              [42.389238269000089, -0.575761340999918],
              [42.389174053000033, -0.57559972599995],
              [42.38932389100006, -0.575405787999955],
              [42.389548648000073, -0.57531959399995],
              [42.38973059500006, -0.575201075999928],
              [42.389955352000072, -0.574770103999924],
              [42.390105190000043, -0.574500745999956],
              [42.390244326000072, -0.574328356999956],
              [42.390372759000059, -0.57430680799996],
              [42.390447678000044, -0.574382228999923],
              [42.390469083000085, -0.574543842999958],
              [42.390351353000085, -0.574780877999956],
              [42.390073082000072, -0.575050235999925],
              [42.389869730000044, -0.575287270999922],
              [42.389805514000045, -0.57552430599992],
              [42.389828083000054, -0.575569744999939],
              [42.389657566000039, -0.575777330999927],
            ],
          ],
          [
            [
              [42.386733830000082, -0.578939761999948],
              [42.38648766700004, -0.579079827999919],
              [42.38636993700004, -0.579025955999953],
              [42.386391342000081, -0.578745823999952],
              [42.386691019000068, -0.578260979999925],
              [42.386937182000054, -0.578045493999923],
              [42.387161939000066, -0.577959299999918],
              [42.387397399000065, -0.577948524999954],
              [42.387458808000076, -0.578099638999959],
              [42.387396454000054, -0.578200718999938],
              [42.387119128000052, -0.578433368999924],
              [42.386894371000039, -0.578735049999921],
              [42.386733830000082, -0.578939761999948],
            ],
          ],
          [
            [
              [41.645047863000059, -1.557049734999907],
              [41.644845028000077, -1.557204843999955],
              [41.644594466000058, -1.556930419999958],
              [41.644618329000082, -1.556524748999948],
              [41.644868891000044, -1.556369639999957],
              [41.645083657000043, -1.556644063999954],
              [41.645047863000059, -1.557049734999907],
            ],
          ],
          [
            [
              [42.391742708000038, -0.57265833799994],
              [42.391469112000038, -0.573115086999906],
              [42.391218274000039, -0.572970792999911],
              [42.391151280000088, -0.572876373999918],
              [42.391646384000069, -0.57248594899994],
              [42.391742708000038, -0.57265833799994],
            ],
          ],
        ],
      },
    },
  ],
};
