const items = ["pptanomaly", "tempanomaly", "spi", "ndvianomaly", "vci"];

const wind_items = ["era5", "gfs"];

// airsensor = 5
//air sensor needs product dropdown

const sensor_items = ["pptanomaly", "tempanomaly", "spi", "ndvianomaly", "vci"];

export function BaseSelectComponent({
  onChange,
  itemId,
  title,
  platform,
  product,
}) {
  return platform === "windspeed" ? (
    <div className="input-group mb-2">
      <div className="input-group-prepend">
        <label className="input-group-text" htmlFor="inputGroupSelect01">
          {title}
        </label>
      </div>
      <select className="custom-select" id={itemId} onChange={onChange}>
        <option selected>Choose...</option>
        {wind_items.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  ) : null;
}

export function SensorSelect({ onChange, itemId, title, product }) {
  return (
    <div className="input-group mb-2">
      <div className="input-group-prepend">
        <label className="input-group-text" htmlFor="inputGroupSelect01">
          {title}
        </label>
      </div>
      <select className="custom-select" id={itemId} onChange={onChange}>
        <option selected>Choose...</option>

        {sensor_items.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
}
