import { createContext, useContext, useState } from "react";
import { banadir, somaliland, puntland, galmudug } from "../data";

export const MapContext = createContext();
const BaseMapUpdateContext = createContext();
export const GeoJSONContext = createContext();
export const ChangeGeoJson = createContext();
export const TileLayerContext = createContext();
export const UpdateTileLayerContext = createContext();
export const TileOpacityContext = createContext();
export const UpdateTileOpacityContext = createContext();

//Bad practice! The AuthContext is here even tho is shouldn't be!:
export const AuthContext = createContext();
export const UpdateAuthContext = createContext();

export const UseBaseMap = () => {
  return useContext(MapContext);
};

export const UseGeoJSON = () => {
  return useContext(GeoJSONContext);
};

export const UseTileLayer = () => {
  return useContext(TileLayerContext);
};

export const UseOpacity = () => {
  return useContext(TileOpacityContext);
};

export const UseAuth = () => {
  return useContext(AuthContext);
};

export const UpdateBaseMap = () => {
  return useContext(BaseMapUpdateContext);
};

export const UpdateGeojson = () => {
  return useContext(ChangeGeoJson);
};

export const UpdateAuth = () => {
  return useContext(UpdateAuthContext);
};

export const ChangeTileLayer = () => {
  return useContext(UpdateTileLayerContext);
};

export const UpdateTileOpacity = () => {
  return useContext(UpdateTileOpacityContext);
};

export const MapProvider = ({ children }) => {
  const [geoJson, setGeoJson] = useState(somaliland);

  /* let geoJsonName = localStorage.getItem("state");

  if (geoJsonName === "somaliland") {
    setGeoJson(somaliland);
  } else if (geoJsonName === "puntland") {
    setGeoJson(puntland);
  } else if (geoJsonName === "galmudug") {
    setGeoJson(galmudug);
  } */

  const [baseMap, setBaseMap] = useState({
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  });

  const [TileLayerURL, SetTileLayerURL] = useState(
    "https://earthengine.googleapis.com/v1/projects/earthengine-legacy/maps/618c2b2c0a050ba8ae1eb3b8c4a988a1-d0485bb1e15a2422a67982efb026d375/tiles/{z}/{x}/{y}"
  );

  const [TileOpacity, SetTileOpacity] = useState(0.5);

  const [auth_credentials, setAuthCredentials] = useState({});

  const changeTileLayer = (value) => {
    SetTileLayerURL(value);
  };

  const changeBaseMap = (value) => {
    setBaseMap(value);
  };

  const changeGeoJson = (value) => {
    setGeoJson(value);
  };

  const changeTileOpacity = (value) => {
    SetTileOpacity(value);
  };

  const changeCredentials = (value) => {
    setAuthCredentials(value);
  };

  return (
    <>
      <AuthContext.Provider value={auth_credentials}>
        <UpdateAuthContext.Provider value={changeCredentials}>
          <MapContext.Provider value={baseMap}>
            <BaseMapUpdateContext.Provider value={changeBaseMap}>
              <GeoJSONContext.Provider value={geoJson}>
                <ChangeGeoJson.Provider value={changeGeoJson}>
                  <TileLayerContext.Provider value={TileLayerURL}>
                    <UpdateTileLayerContext.Provider value={changeTileLayer}>
                      <TileOpacityContext.Provider value={TileOpacity}>
                        <UpdateTileOpacityContext.Provider
                          value={changeTileOpacity}
                        >
                          {children}
                        </UpdateTileOpacityContext.Provider>
                      </TileOpacityContext.Provider>
                    </UpdateTileLayerContext.Provider>
                  </TileLayerContext.Provider>
                </ChangeGeoJson.Provider>
              </GeoJSONContext.Provider>
            </BaseMapUpdateContext.Provider>
          </MapContext.Provider>
        </UpdateAuthContext.Provider>
      </AuthContext.Provider>
    </>
  );
};
