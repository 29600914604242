import axios from "axios";
import {
  UseUpdateDataSentContext,
  UseUpdateResponseContext,
} from "../../context/ApiContext";

const firstDay = function (date) {
  return date.concat("-01");
};

const endDateGivenMonth = function (date) {
  let y, m;

  let dateArr = date.split("-");

  y = dateArr[0];
  m = dateArr[1];

  const next_month = Number(m) + 1;

  next_month < 10
    ? (date = `${y}-0${next_month}-01`)
    : (date = `${y}-${next_month}-01`);

  return date;
};

const getApiData = async (apiData) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
      platform: apiData.platform,
      sensor: apiData.sensor,
      product: apiData.product,
      start_date: apiData.start_date,
      end_date: apiData.end_date,
      reducer: apiData.reducer,
      roi_name: apiData.roi_name,
    };

    console.log("user", user);

    data.roi_name = user.state;

    if (data.product === "pptanomaly") {
      data.sensor = data.platform = "pptanomaly";
      data.start_date = firstDay(data.start_date);
      data.end_date = endDateGivenMonth(data.start_date);
    }
    if (data.product === "tempanomaly") {
      data.sensor = data.platform = "tempanomaly";
      data.start_date = firstDay(data.start_date);
      data.end_date = endDateGivenMonth(data.start_date);
    }
    if (data.product === "spi") {
      data.sensor = data.platform = "spi";
      data.start_date = firstDay(data.start_date);
      data.end_date = endDateGivenMonth(data.start_date);
    }
    if (data.product === "ndvianomaly") {
      data.sensor = data.platform = "ndvianomaly";
      data.start_date = firstDay(data.start_date);
      data.end_date = endDateGivenMonth(data.start_date);
    }
    if (data.product === "vci" && data.sensor === "vci") {
      data.sensor = data.platform = "vci";
      data.start_date = firstDay(data.start_date);
      data.end_date = endDateGivenMonth(data.start_date);
    }
    if (data.product === "windspeed") {
      if (data.platform === "era5") {
        //data.platform = "era5";
        data.sensor = "era5_wind";
        data.reducer = "mean";
      }
      /* if (data.platform === "era5_wind_east") {
        data.platform = "era5";
        data.sensor = "era5_wind_east";
      } */
      if (data.platform === "gfs") {
        //data.platform = "gfs";
        data.sensor = "gfs_wind";
        data.reducer = "mean";
      }
      /*  if (data.platform === "gfs_wind_east") {
        data.platform = "gfs";
        data.sensor = "gfs_wind_east";
      } */
    }
    if (
      data.product === "cloud" ||
      data.product === "ch4" ||
      data.product === "so2" ||
      data.product === "ozone" ||
      data.product === "co"
    ) {
      data.sensor = "5";
      data.platform = "sentinel";
    }

    console.log("data sent:", JSON.stringify(data));

    const params = {
      method: "post",
      data: data,
      //url: "https://sews.firsake.com/auth/login",
      url: "https://apisews.somrep.org/fetch_data/",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: false,
    };

    const response = await axios(params);

    console.log("API RES :- ", response.data.url);
    return response.data.url;
  } catch (error) {
    console.error("Error:", error);
  }
};

export default getApiData;
