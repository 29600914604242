import React from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import {
  FloodTwoTone,
  WaterDropTwoTone,
  IndeterminateCheckBox,
  DataThresholdingTwoTone,
  ThermostatTwoTone,
  AirTwoTone,
  MenuOpen,
  BoltTwoTone,
  ShowerTwoTone,
  CloudTwoTone,
  Co2TwoTone,
  OpacityTwoTone,
  SouthAmericaTwoTone,
  AgricultureTwoTone,
} from "@mui/icons-material";
import NavCardComponent from "./NavCard";
import { Container } from "react-bootstrap";
import { UseApiContext, UseUpdateApiContext } from "../../context/ApiContext";

const air_quality_options = [
  {
    icon: <CloudTwoTone />,
    name: "Cloud",
    product: "cloud",
    description: "Data product showing effective radiometric cloud fraction",
  },
  {
    icon: <Co2TwoTone />,
    name: "CO",
    product: "co",
    description: "Data product showing Carbon monoxide total column (mol/m2)",
  },
  {
    icon: <SouthAmericaTwoTone />,
    name: "Ozone",
    product: "ozone",
    description: "Data product showing Ozone total column (mol/m2)",
  },
  {
    icon: <OpacityTwoTone />,
    name: "SO2",
    product: "so2",
    description: "Data product showing Sulfur dioxide total column (mol/m2)",
  },
  {
    icon: <AgricultureTwoTone />,
    name: "CH4",
    product: "ch4",
    description:
      "Data product showing Column averaged dry air mixing ratio of methane (parts per billion)",
  },
];

const menu_options = [
  {
    icon: <BoltTwoTone />,
    name: "Anomalies",

    child: [
      {
        icon: <ThermostatTwoTone />,
        name: "Temperature Absolute Anomaly",
        product: "tempanomaly",
        description:
          "Analysis of the deviation of mean monthly temperature values from the long term mean for the respective month.",
      },
      {
        icon: <IndeterminateCheckBox />,
        name: "NDVI Absolute Anomaly",
        product: "ndvianomaly",
        description:
          "Analysis of the deviation of mean monthly vegetation values from the long term mean for the respective month.",
      },
      {
        icon: <ShowerTwoTone />,
        name: "Precipitation Absolute Anomaly",
        product: "pptanomaly",
        description:
          "Analysis of the deviation of mean monthly precipitation values from the long term mean for the respective month.",
      },
    ],
  },
  {
    icon: <DataThresholdingTwoTone />,
    name: "Vegetation Condition Index",
    product: "vci",
    description:
      "The Vegetation Condition Index (VCI) compares the current NDVI to the range of values observed in the same period in previous years. The VCI is expressed in % and gives an idea where the observed value is situated between the extreme values (minimum and maximum) in the previous years.",
  },
  {
    icon: <AirTwoTone />,
    name: "Air Quality",
    child: air_quality_options,
  },
  {
    icon: <FloodTwoTone />,
    name: "Windspeed",
    product: "windspeed",
    description:
      "Components of the 10m wind, being the horizontal speed of air moving towards the respective direction, at a height of 10m above sea level, in m/s",
  },
  {
    icon: <WaterDropTwoTone />,
    name: "Standardized Precipitation Index",
    product: "spi",
    description:
      "A metric used to quantify rainfall anomalies and drought severity on various timescales. It normalizes precipitation measurements to allow comparison across different regions and time periods. Negative SPI values indicate drought conditions, while positive values signify wetter periods.",
  },
];

export default function SideNavigation() {
  const apiData = UseApiContext();
  const updateApi = UseUpdateApiContext();
  const [collapsed, setCollapsed] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const [menuIconColor, setMenuIconColor] = React.useState("white");

  // where the selection has a child
  const [openParentMenu, setOpenParentMenu] = React.useState(null);
  const [openChildMenu, setOpenChildMenu] = React.useState(null);
  const [hoveredParent, setHoveredParent] = React.useState({
    parent: "",
    iconColor: "white",
  });
  //for the children
  const [hoveredChild, setHoveredChild] = React.useState({
    child: "",
    iconColor: "white",
  });

  const handleClick = (event, index) => {
    setSelectedIndex(index);
    setCollapsed(!collapsed);
  };

  const handleClose = (index, option) => {
    setCollapsed(null);

    if (option) {
      const newdata = { ...apiData };

      newdata["product"] = option.product;

      updateApi(newdata);

      //console.log("NEW DATA -> \n", newdata);
    }
    //console.log(index);
  };

  const childHandleClick = (opt) => {
    setCollapsed(null);

    //update api to take product name as selected index's name

    const newdata = { ...apiData };

    newdata["product"] = opt.product;

    updateApi(newdata);

    //console.log("NEW DATA -> \n", newdata);
  };

  const checkIfParentMenuCanBeOpened = (openParentMenu, option_name) => {
    return openParentMenu !== option_name;
  };

  const checkIfChildMenuCanBeOpened = (openChildMenu, opt_name) => {
    return openChildMenu !== opt_name;
  };

  return (
    <>
      <Sidebar
        width="fit-content"
        style={{
          height: "100vh",

          overflowX: "hidden",
        }}
        collapsed={collapsed}
        backgroundColor="rgb(0,128,128)"
      >
        <Menu
          color="#008080"
          menuItemStyles={{
            button: {
              [`&.active`]: {
                backgroundColor: "red",
                color: "#b6c8d9",
              },
              width: "105%",
              overflowX: "hidden",
              WebkitOverflowScrolling: "none",
            },
          }}
        >
          <MenuItem
            icon={<MenuOpen />}
            style={{ color: menuIconColor }}
            onMouseEnter={() => {
              setMenuIconColor("black");
            }}
            onMouseLeave={() => {
              setMenuIconColor("white");
            }}
            onClick={handleClick}
          ></MenuItem>

          {menu_options.map((option, index) => {
            return option.child ? (
              <SubMenu
                key={option.name}
                onChange={handleClose}
                onClick={(event, index) => handleClose(event, index)}
                onOpenChange={(open) => {
                  open
                    ? setOpenParentMenu(option.name)
                    : setOpenParentMenu(null);
                }}
                onMouseEnter={() => {
                  setHoveredParent({
                    parent: option.name,
                    iconColor: "black",
                  });
                }}
                onMouseLeave={() => {
                  setHoveredParent({
                    parent: "",
                    iconColor: "white",
                  });
                }}
                icon={option.icon}
                selected={index === selectedIndex}
                label={option.name}
                style={
                  hoveredParent.parent === option.name
                    ? { color: hoveredParent.iconColor }
                    : { color: "white" }
                }
                disabled={
                  !openParentMenu
                    ? false
                    : checkIfParentMenuCanBeOpened(openParentMenu, option.name)
                }
              >
                {option.child.map((opt, index) => {
                  return (
                    <SubMenu
                      key={opt.name}
                      onClick={(event) => childHandleClick(opt)}
                      icon={opt.icon}
                      selected={index === selectedIndex}
                      label={opt.name}
                      onOpenChange={(open) => {
                        open
                          ? setOpenChildMenu(opt.name)
                          : setOpenChildMenu(null);
                      }}
                      onMouseEnter={() => {
                        setHoveredChild({
                          child: opt.name,
                          iconColor: "black",
                        });
                      }}
                      onMouseLeave={() => {
                        setHoveredChild({
                          child: "",
                          iconColor: "white",
                        });
                      }}
                      style={
                        hoveredChild.child === opt.name
                          ? {
                              color: hoveredChild.iconColor,
                              backgroundColor: "white",
                              width: "101%",
                            }
                          : {
                              color: "white",
                              backgroundColor: "#007373",
                              width: "101%",
                            }
                      }
                      disabled={
                        !openChildMenu
                          ? false
                          : checkIfChildMenuCanBeOpened(openChildMenu, opt.name)
                      }
                    >
                      <Container>
                        <NavCardComponent
                          cardTitle={opt.name}
                          product={opt.product}
                          description={opt.description}
                        />
                      </Container>
                    </SubMenu>
                  );
                })}
              </SubMenu>
            ) : (
              <SubMenu
                key={option.name}
                onClick={(event) => handleClose(index, option)}
                onOpenChange={(open) => {
                  open
                    ? setOpenParentMenu(option.name)
                    : setOpenParentMenu(null);
                }}
                onMouseEnter={() => {
                  setHoveredParent({
                    parent: option.name,
                    iconColor: "black",
                  });
                }}
                onMouseLeave={() => {
                  setHoveredParent({
                    parent: "",
                    iconColor: "white",
                  });
                }}
                icon={option.icon}
                selected={index === selectedIndex}
                label={option.name}
                style={
                  hoveredParent.parent == option.name
                    ? { color: hoveredParent.iconColor }
                    : { color: "white" }
                }
                disabled={
                  !openParentMenu
                    ? false
                    : checkIfParentMenuCanBeOpened(openParentMenu, option.name)
                }
              >
                <Container>
                  <NavCardComponent
                    cardTitle={option.name}
                    product={option.product}
                    description={option.description}
                  />
                </Container>
              </SubMenu>
            );
          })}
        </Menu>
      </Sidebar>
    </>
  );
}
