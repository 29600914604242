import axios from "axios";

export async function LogInUSer(credentials) {
  try {
    const params = {
      method: "post",
      data: credentials,
      url: "https://apisews.somrep.org/login/",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: false,
    };

    const response = await axios(params);

    let data = await response.data;

    //console.log("login", data);

    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
}
