export const puntland = {
  type: "FeatureCollection",
  name: "puntland",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        OBJECTID_1: 4,
        Name: "Puntland",
        Shape_Leng: 18.750169375,
        Shape_Area: 9.7845187498499993,
        "area-km2": 119558.59531400001,
        style: {
          color: "black",
          fill: false,
          opacity: 1,
          clickable: false,
        },
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [50.787959155000067, 11.987828686000057],
              [50.788316074000079, 11.987781097000038],
              [50.788789456000075, 11.987806019000061],
              [50.789151147000041, 11.987806019000061],
              [50.789532932000043, 11.98784558400007],
              [50.789803237000058, 11.98790007000008],
              [50.790136362000055, 11.987947659000042],
              [50.790564665000034, 11.98813801600005],
              [50.790879227000062, 11.988280799000052],
              [50.791080167000075, 11.988261016000081],
              [50.791803549000065, 11.988221451000072],
              [50.792486744000087, 11.988102756000046],
              [50.792908717000046, 11.988003844000048],
              [50.793551723000064, 11.987825802000089],
              [50.793993790000059, 11.987746672000071],
              [50.794676985000081, 11.987509282000076],
              [50.795480743000041, 11.987291674000062],
              [50.796123750000049, 11.987074066000048],
              [50.796947602000046, 11.986797110000055],
              [50.798690524000051, 11.985996501000045],
              [50.799214005000067, 11.985770452000054],
              [50.799701795000033, 11.985544403000063],
              [50.801129471000081, 11.984759181000072],
              [50.803056835000064, 11.983557553000082],
              [50.805174555000065, 11.981903828000043],
              [50.806523522000077, 11.980928838000068],
              [50.808434417000058, 11.979345907000038],
              [50.811289770000087, 11.977156803000071],
              [50.814216507000083, 11.975241337000057],
              [50.816584071000079, 11.973432947000049],
              [50.817012374000058, 11.973230693000062],
              [50.817583444000036, 11.972504957000069],
              [50.817964158000052, 11.972338395000065],
              [50.817952261000073, 11.972528752000073],
              [50.818582818000039, 11.972588238000071],
              [50.819165786000042, 11.972385984000084],
              [50.819475116000035, 11.971933886000045],
              [50.819808240000043, 11.971660248000092],
              [50.820664846000057, 11.970934513000088],
              [50.821977071000049, 11.969701504000057],
              [50.823127588000034, 11.968543155000077],
              [50.823948503000054, 11.967948289000049],
              [50.824448189000066, 11.967579473000058],
              [50.825054952000073, 11.967139273000043],
              [50.825589803000071, 11.966795559000047],
              [50.826042428000051, 11.966449229000091],
              [50.829254701000082, 11.964272022000046],
              [50.830920323000043, 11.963082292000081],
              [50.836357392000082, 11.959108592000064],
              [50.84093785400006, 11.955801141000052],
              [50.845054322000067, 11.953290809000066],
              [50.848195210000085, 11.951791749000051],
              [50.848635410000043, 11.951482419000058],
              [50.849146995000069, 11.951315857000054],
              [50.850027395000041, 11.951030322000065],
              [50.850574671000061, 11.950887554000076],
              [50.851205228000083, 11.951173089000065],
              [50.85168112100007, 11.950970835000078],
              [50.851859580000053, 11.950792375000049],
              [50.852180807000082, 11.950661505000085],
              [50.852799467000068, 11.950209407000045],
              [50.853560895000044, 11.949733515000048],
              [50.854001095000058, 11.94940039100004],
              [50.854615612000032, 11.949091688000067],
              [50.855279181000071, 11.948691230000065],
              [50.855792912000084, 11.94831184800006],
              [50.85664913100004, 11.947869235000041],
              [50.857605978000038, 11.947544411000081],
              [50.858284125000068, 11.947235081000088],
              [50.858730287000071, 11.947127801000079],
              [50.859607911000069, 11.946895955000059],
              [50.859988383000086, 11.94664677600008],
              [50.860901532000071, 11.946497448000059],
              [50.862055571000042, 11.946188118000066],
              [50.862578444000064, 11.945993390000069],
              [50.862856715000078, 11.945993390000069],
              [50.864470723000068, 11.945640842000046],
              [50.865862708000066, 11.945450485000038],
              [50.867844188000049, 11.945150310000088],
              [50.869727869000087, 11.94510815600006],
              [50.871525825000049, 11.945236334000072],
              [50.873109932000034, 11.94538215700004],
              [50.874559638000051, 11.94542669100008],
              [50.875404347000085, 11.945533767000086],
              [50.877331710000078, 11.945581356000048],
              [50.880068090000066, 11.944915107000043],
              [50.882507038000085, 11.945081669000047],
              [50.884557398000084, 11.944649092000077],
              [50.885647926000047, 11.944284550000077],
              [50.886409354000079, 11.944046603000061],
              [50.886868059000051, 11.943875367000089],
              [50.887349241000038, 11.943761068000072],
              [50.887801339000077, 11.943637938000052],
              [50.888837267000042, 11.943558795000058],
              [50.889514551000048, 11.943368457000076],
              [50.890335465000078, 11.943320868000058],
              [50.891449481000052, 11.94336093700008],
              [50.892745801000046, 11.943135146000088],
              [50.893629935000035, 11.942976860000044],
              [50.894343011000046, 11.94284650600008],
              [50.895296641000073, 11.942428570000061],
              [50.895605971000066, 11.94229770000004],
              [50.896176388000072, 11.942072335000091],
              [50.896709546000068, 11.941823658000089],
              [50.897188312000083, 11.941595759000052],
              [50.897616615000061, 11.94151247700006],
              [50.898266928000055, 11.941450643000053],
              [50.898901524000053, 11.941107969000086],
              [50.899520184000039, 11.94082243400004],
              [50.899936554000078, 11.940607902000067],
              [50.900341098000069, 11.940429823000045],
              [50.900745606000044, 11.940287055000056],
              [50.90107706100008, 11.940049223000074],
              [50.902127131000043, 11.939623668000081],
              [50.905040534000079, 11.938442973000065],
              [50.906301648000067, 11.937955183000042],
              [50.907431892000034, 11.937562572000047],
              [50.910037402000057, 11.936372842000083],
              [50.911676990000046, 11.935774189000085],
              [50.912250704000087, 11.935564712000087],
              [50.914141972000039, 11.935206906000076],
              [50.914597720000074, 11.935327785000084],
              [50.916699893000043, 11.934445478000043],
              [50.919674219000058, 11.933469899000045],
              [50.92123276600006, 11.93293452100005],
              [50.922648545000072, 11.932684677000054],
              [50.924644698000066, 11.932122371000048],
              [50.925765639000076, 11.931828071000041],
              [50.92681484600007, 11.931687065000062],
              [50.929608468000083, 11.931138028000078],
              [50.931964135000044, 11.930911979000086],
              [50.934724310000036, 11.930650238000055],
              [50.936651673000085, 11.930364703000066],
              [50.937201179000056, 11.930571529000076],
              [50.939661691000083, 11.929638967000074],
              [50.941231959000049, 11.929602507000084],
              [50.94202925500008, 11.929591378000055],
              [50.944991684000058, 11.928651491000039],
              [50.947882729000071, 11.92781867900004],
              [50.95084515800005, 11.927081046000069],
              [50.952022991000035, 11.926843100000042],
              [50.953797919000067, 11.92700233700009],
              [50.955021112000054, 11.92661705200004],
              [50.956175151000082, 11.926057878000051],
              [50.958292871000083, 11.924820559000068],
              [50.959791931000041, 11.923952055000086],
              [50.962075824000067, 11.92295615800009],
              [50.966258123000046, 11.921519477000061],
              [50.967994727000075, 11.921003944000063],
              [50.969726181000055, 11.920489940000039],
              [50.971308523000062, 11.920014047000052],
              [50.972629123000047, 11.919978355000069],
              [50.974222964000035, 11.920122578000075],
              [50.975277686000084, 11.920061976000056],
              [50.976001575000055, 11.919550038000068],
              [50.976329185000054, 11.919335901000068],
              [50.976881924000054, 11.918992729000081],
              [50.977435635000063, 11.918633960000079],
              [50.977762273000053, 11.918311571000061],
              [50.978625365000084, 11.917705970000043],
              [50.978946592000057, 11.917468024000073],
              [50.979334325000082, 11.917165984000064],
              [50.980419041000061, 11.916314531000069],
              [50.981330831000037, 11.915679810000086],
              [50.981550918000039, 11.915416632000074],
              [50.981802446000074, 11.915199898000083],
              [50.98244698700006, 11.914781909000055],
              [50.983372390000056, 11.914089189000038],
              [50.983975273000055, 11.913771906000079],
              [50.985342958000047, 11.912641780000058],
              [50.985638252000058, 11.912289444000066],
              [50.98593694200008, 11.912258481000038],
              [50.986346716000071, 11.911959572000058],
              [50.987084349000042, 11.911531269000079],
              [50.98769111200005, 11.911221939000086],
              [50.988115303000086, 11.91096636900005],
              [50.989137137000057, 11.910470967000037],
              [50.989757028000042, 11.910307840000087],
              [50.990322967000054, 11.910044657000071],
              [50.990480172000048, 11.909874362000039],
              [50.990904626000088, 11.909750511000084],
              [50.991831374000071, 11.909246986000085],
              [50.992307266000068, 11.908794889000092],
              [50.992795055000045, 11.908556943000065],
              [50.993498511000041, 11.908109481000054],
              [50.993875803000037, 11.907846296000059],
              [50.994268816000044, 11.907552148000093],
              [50.994591549000063, 11.90723634200009],
              [50.995019852000041, 11.907022190000077],
              [50.995369253000035, 11.906809035000038],
              [50.995924047000074, 11.906498709000061],
              [50.996391086000074, 11.906313625000053],
              [50.997316031000082, 11.905713487000071],
              [50.997732437000082, 11.905416054000057],
              [50.998214666000081, 11.904966726000055],
              [50.99874916400006, 11.904517758000054],
              [50.999267941000085, 11.904285533000063],
              [50.999881041000037, 11.904084271000045],
              [51.000242613000069, 11.903790118000074],
              [51.000557023000056, 11.903434039000047],
              [51.001301629000068, 11.903119874000083],
              [51.001751783000032, 11.902985068000078],
              [51.002789337000081, 11.902520615000071],
              [51.002930822000053, 11.902164534000065],
              [51.00393693500007, 11.901808452000068],
              [51.004550035000079, 11.901374960000055],
              [51.005548966000049, 11.900823694000053],
              [51.005870558000083, 11.900647312000046],
              [51.006467938000071, 11.900368638000089],
              [51.006908112000076, 11.900105445000065],
              [51.007583406000037, 11.899752937000073],
              [51.007987914000068, 11.899574477000044],
              [51.008197195000037, 11.89940875800005],
              [51.008689855000057, 11.899146174000066],
              [51.009187587000042, 11.898851407000052],
              [51.009701126000039, 11.898598898000046],
              [51.010081840000055, 11.898337158000061],
              [51.010462553000082, 11.898075417000086],
              [51.010878959000081, 11.897873163000042],
              [51.011309857000072, 11.897643808000055],
              [51.011531937000086, 11.897592347000057],
              [51.011687814000084, 11.897494657000038],
              [51.011970119000068, 11.897272238000085],
              [51.012944791000052, 11.896560061000059],
              [51.013730817000067, 11.89620397200008],
              [51.014438240000061, 11.895770471000048],
              [51.015082782000036, 11.895336970000074],
              [51.016324702000077, 11.894640270000082],
              [51.016875201000062, 11.894363458000043],
              [51.017252213000063, 11.894175803000053],
              [51.017648526000073, 11.89395894900008],
              [51.018409953000059, 11.893506852000087],
              [51.019004818000042, 11.893019062000064],
              [51.019531688000086, 11.892735948000052],
              [51.020003731000088, 11.892384581000044],
              [51.020396744000038, 11.892229757000052],
              [51.021098744000085, 11.891841229000079],
              [51.021653958000059, 11.891435428000079],
              [51.021999809000079, 11.891048367000053],
              [51.022424263000062, 11.890723235000053],
              [51.022927320000065, 11.890444551000087],
              [51.023341205000065, 11.890424170000074],
              [51.023619023000037, 11.890305208000086],
              [51.024326446000032, 11.889856216000055],
              [51.024846395000054, 11.889485563000051],
              [51.025453158000062, 11.889081054000087],
              [51.025835615000062, 11.888849850000042],
              [51.026338672000065, 11.888555681000071],
              [51.026340029000039, 11.88855497600008],
              [51.02687230500004, 11.888175606000061],
              [51.027178267000068, 11.887938913000085],
              [51.02763036500005, 11.887629583000091],
              [51.028141949000087, 11.887272664000079],
              [51.028724917000034, 11.886832464000065],
              [51.029236501000071, 11.886749182000074],
              [51.029724290000047, 11.886487442000089],
              [51.030176388000086, 11.886154317000091],
              [51.030945326000051, 11.885761680000087],
              [51.031175762000032, 11.88561893800005],
              [51.031532681000044, 11.885488068000086],
              [51.032068059000039, 11.885190635000072],
              [51.032627233000085, 11.885012176000089],
              [51.032900871000038, 11.884786127000041],
              [51.033271420000062, 11.884638557000073],
              [51.033711595000057, 11.88432890100006],
              [51.03449511000008, 11.884060392000038],
              [51.035197051000068, 11.883477424000091],
              [51.035896747000038, 11.883198651000043],
              [51.036211157000082, 11.882981891000043],
              [51.036862673000087, 11.882727893000038],
              [51.037166901000035, 11.882519207000087],
              [51.037697628000046, 11.882199342000092],
              [51.03930162100005, 11.881074168000055],
              [51.041189651000082, 11.879920180000056],
              [51.041913033000071, 11.87940562700004],
              [51.042194348000066, 11.879207722000046],
              [51.042882710000072, 11.878849372000047],
              [51.043440174000068, 11.878416100000038],
              [51.04452524800007, 11.877664056000071],
              [51.045500117000074, 11.877183749000039],
              [51.046405088000085, 11.87666674400009],
              [51.047168658000032, 11.876389674000052],
              [51.048236497000062, 11.875720381000065],
              [51.049307254000041, 11.875327770000069],
              [51.05031852500008, 11.874887569000066],
              [51.05175809900004, 11.874732904000041],
              [51.052352964000079, 11.874768596000081],
              [51.053685463000079, 11.874161834000063],
              [51.054865525000082, 11.87364292400008],
              [51.055995770000038, 11.873464464000051],
              [51.056709608000062, 11.873107545000039],
              [51.057756571000084, 11.87282201000005],
              [51.058648868000034, 11.872465091000038],
              [51.059860164000042, 11.872151189000078],
              [51.06113846900007, 11.871897998000065],
              [51.062322906000077, 11.871663400000045],
              [51.063431585000046, 11.871501409000075],
              [51.064369243000044, 11.871389762000092],
              [51.064987902000041, 11.871389762000092],
              [51.065535178000061, 11.871365967000088],
              [51.066065970000068, 11.871370560000059],
              [51.067736180000054, 11.871235097000067],
              [51.068628478000051, 11.871294583000065],
              [51.069806311000036, 11.871651502000077],
              [51.069519403000072, 11.871895905000088],
              [51.069485084000064, 11.871925140000087],
              [51.069446155000037, 11.871891402000074],
              [51.069128165000052, 11.871615810000037],
              [51.068295353000053, 11.871532529000092],
              [51.06811689400007, 11.871746681000047],
              [51.068141471000047, 11.871811195000078],
              [51.068212072000051, 11.871996524000053],
              [51.069508878000079, 11.872317751000082],
              [51.070949067000072, 11.871983795000062],
              [51.071150706000083, 11.871937038000056],
              [51.071213734000082, 11.872000066000055],
              [51.071626599000069, 11.872412930000053],
              [51.071900237000079, 11.872817438000084],
              [51.072447513000043, 11.873269536000066],
              [51.073098855000069, 11.873547572000064],
              [51.073420358000078, 11.873666318000062],
              [51.073701674000063, 11.87382464500007],
              [51.074220211000068, 11.874221320000061],
              [51.074947499000075, 11.873982972000078],
              [51.075670882000054, 11.873785063000071],
              [51.076635392000071, 11.873587154000063],
              [51.077198023000051, 11.873468408000065],
              [51.078283096000064, 11.87307258900006],
              [51.07896723600004, 11.872984001000077],
              [51.079585896000083, 11.872984001000077],
              [51.080654185000071, 11.872580425000081],
              [51.081192032000047, 11.87237723800007],
              [51.08258320300007, 11.871845546000088],
              [51.083619082000041, 11.872020319000057],
              [51.084428099000036, 11.872151189000078],
              [51.084689840000067, 11.872079805000055],
              [51.085427472000049, 11.871877551000068],
              [51.086079552000058, 11.871687217000044],
              [51.086843122000062, 11.871489306000058],
              [51.087652268000056, 11.871223199000042],
              [51.088410451000072, 11.871172648000083],
              [51.089214209000033, 11.870935155000041],
              [51.089897404000055, 11.870855990000052],
              [51.090888335000045, 11.870640231000039],
              [51.091699582000047, 11.870585317000064],
              [51.092280320000043, 11.870652129000064],
              [51.092672931000038, 11.870830588000047],
              [51.094136300000059, 11.871080432000042],
              [51.094221311000069, 11.871100898000066],
              [51.095421209000051, 11.871389762000092],
              [51.096337301000062, 11.871187507000059],
              [51.09694406400007, 11.87113991800004],
              [51.097907745000043, 11.870985253000072],
              [51.098609686000032, 11.870961459000057],
              [51.099656649000053, 11.871056637000038],
              [51.100441871000044, 11.871032842000091],
              [51.101230396000062, 11.870855990000052],
              [51.102157313000077, 11.870847057000049],
              [51.103287557000044, 11.870728084000064],
              [51.104058652000049, 11.870878178000055],
              [51.104796285000077, 11.871223199000042],
              [51.105168812000045, 11.871608052000056],
              [51.106093134000048, 11.871924710000087],
              [51.106856704000052, 11.872043456000085],
              [51.10765163800005, 11.872246368000049],
              [51.108819804000063, 11.872155761000045],
              [51.109973842000045, 11.872048685000038],
              [51.111163573000056, 11.871751252000081],
              [51.113326653000058, 11.871699092000085],
              [51.116170109000052, 11.871794270000066],
              [51.118335418000072, 11.871829962000049],
              [51.120298473000048, 11.871496837000052],
              [51.121024209000041, 11.871484940000073],
              [51.122016030000054, 11.871268416000078],
              [51.122713626000063, 11.871116124000082],
              [51.122796908000055, 11.871223199000042],
              [51.123166582000067, 11.87143394800006],
              [51.124069919000078, 11.871948935000091],
              [51.124820702000079, 11.872162202000084],
              [51.125509493000038, 11.872067908000076],
              [51.126865786000053, 11.872293957000068],
              [51.12807592300004, 11.872660936000045],
              [51.128317051000067, 11.872676769000066],
              [51.128900225000052, 11.872734157000082],
              [51.129495090000034, 11.872793644000069],
              [51.130326447000073, 11.872676769000066],
              [51.130768514000067, 11.872755933000064],
              [51.131090017000076, 11.872953843000062],
              [51.13156284300004, 11.873230350000085],
              [51.132754952000084, 11.873519379000072],
              [51.133896657000037, 11.87358630500006],
              [51.134551445000056, 11.873507482000036],
              [51.135193899000058, 11.873519379000072],
              [51.136169478000056, 11.873305228000049],
              [51.136751138000079, 11.873402935000058],
              [51.136802453000087, 11.87339576700009],
              [51.137620950000041, 11.873281433000045],
              [51.138858269000082, 11.872912617000054],
              [51.139512621000051, 11.873079179000058],
              [51.140024205000032, 11.87292451400009],
              [51.140534177000063, 11.872953842000072],
              [51.14223710400006, 11.872853130000067],
              [51.143129402000056, 11.872484314000076],
              [51.143938419000051, 11.872662773000059],
              [51.144557078000048, 11.87223447000008],
              [51.14523616300005, 11.872201784000083],
              [51.145413983000083, 11.872243822000087],
              [51.14587767900008, 11.872353443000065],
              [51.146436853000068, 11.872139292000043],
              [51.147007923000047, 11.871603913000058],
              [51.148330633000057, 11.871291395000071],
              [51.149616646000084, 11.870618496000077],
              [51.151023223000038, 11.869628936000083],
              [51.151545666000061, 11.869272694000074],
              [51.152027921000069, 11.868916451000075],
              [51.152751304000049, 11.868520625000087],
              [51.15362282500007, 11.868391641000073],
              [51.15427717700004, 11.868106105000038],
              [51.154904145000046, 11.867775633000065],
              [51.157513244000086, 11.866357202000074],
              [51.160249624000073, 11.86458450300006],
              [51.162548221000065, 11.863022797000042],
              [51.164425578000078, 11.861931404000075],
              [51.164568345000077, 11.861788636000085],
              [51.165044238000064, 11.861526896000044],
              [51.165932940000062, 11.861039406000089],
              [51.16697782600005, 11.860524817000055],
              [51.167781584000068, 11.860049811000067],
              [51.168665718000057, 11.85957480400009],
              [51.16959004000006, 11.858981044000075],
              [51.170659766000085, 11.85844549400008],
              [51.17172, 11.857833105000054],
              [51.173622195000064, 11.856875050000042],
              [51.174835720000033, 11.85628018400007],
              [51.175311612000087, 11.856327773000089],
              [51.178274041000066, 11.854566972000043],
              [51.179717395000068, 11.853914242000087],
              [51.180802469000071, 11.853241300000093],
              [51.181887542000084, 11.852647526000055],
              [51.18310434700004, 11.852199409000036],
              [51.183865774000083, 11.851771106000058],
              [51.184900840000068, 11.851128651000067],
              [51.186150057000077, 11.850343429000077],
              [51.186947176000047, 11.849915126000042],
              [51.187835354000072, 11.849678638000057],
              [51.188719488000061, 11.849322369000049],
              [51.189445610000064, 11.849355953000043],
              [51.190540162000048, 11.849118007000072],
              [51.191123130000051, 11.848999034000087],
              [51.192098709000049, 11.848891958000081],
              [51.192931521000048, 11.848844369000062],
              [51.193681051000056, 11.848689704000037],
              [51.194546736000063, 11.84845148800008],
              [51.195382366000047, 11.848201914000072],
              [51.196154253000032, 11.848016046000055],
              [51.196877635000078, 11.848016046000055],
              [51.197922521000066, 11.848016046000055],
              [51.198726279000084, 11.84813480300005],
              [51.199617806000049, 11.848594525000067],
              [51.200367336000056, 11.848880061000045],
              [51.201057380000066, 11.848999034000087],
              [51.202128137000045, 11.848677806000069],
              [51.202770592000036, 11.848297093000042],
              [51.203436841000041, 11.847904482000047],
              [51.203910520000079, 11.847580604000086],
              [51.204794655000057, 11.847224333000042],
              [51.205598413000075, 11.846907646000091],
              [51.206321795000065, 11.846709717000067],
              [51.207045178000044, 11.846709717000067],
              [51.207567621000067, 11.846749303000081],
              [51.207889124000076, 11.846511788000043],
              [51.208813446000079, 11.846234687000049],
              [51.209175137000045, 11.846195101000092],
              [51.209657392000054, 11.846195101000092],
              [51.210480046000043, 11.846167475000072],
              [51.210777478000068, 11.846655264000049],
              [51.211455625000042, 11.846750443000076],
              [51.211907722000035, 11.846821827000042],
              [51.213525756000081, 11.846476805000066],
              [51.21473928100005, 11.846072297000092],
              [51.215084303000083, 11.845941426000081],
              [51.215833833000033, 11.845548815000086],
              [51.216288399000064, 11.845344003000037],
              [51.21690459000007, 11.845037231000049],
              [51.217594634000079, 11.844501852000064],
              [51.218132547000039, 11.844148631000053],
              [51.218735365000043, 11.843713182000045],
              [51.218998516000056, 11.843573862000085],
              [51.219403024000087, 11.843300224000075],
              [51.21995030000005, 11.842990895000071],
              [51.220267002000071, 11.842652139000052],
              [51.220347378000042, 11.842612553000038],
              [51.220783112000049, 11.84234844000008],
              [51.220950197000036, 11.84209792900009],
              [51.221191324000074, 11.841979169000069],
              [51.221432452000045, 11.841820823000091],
              [51.221633391000069, 11.841543717000093],
              [51.221949048000056, 11.841372861000082],
              [51.222139405000064, 11.841194401000052],
              [51.222484426000051, 11.840908866000063],
              [51.223031702000071, 11.840575742000055],
              [51.22331723800005, 11.840385385000047],
              [51.223567081000056, 11.840218822000054],
              [51.22400728100007, 11.839885698000046],
              [51.225434958000051, 11.838922016000083],
              [51.225681095000084, 11.838704576000055],
              [51.225973686000032, 11.838416359000064],
              [51.226134438000088, 11.838337185000057],
              [51.226415753000083, 11.838337185000057],
              [51.226777445000039, 11.838455946000067],
              [51.227139136000062, 11.838733055000091],
              [51.227380263000043, 11.838891403000048],
              [51.227661579000085, 11.838970577000055],
              [51.227983082000037, 11.838891403000048],
              [51.228730512000084, 11.838660276000041],
              [51.229432453000072, 11.838279562000082],
              [51.229884550000065, 11.837958335000053],
              [51.230229572000042, 11.837839361000078],
              [51.230586491000054, 11.837767978000045],
              [51.23096720500007, 11.83779177200006],
              [51.231324124000082, 11.837767978000045],
              [51.231716735000077, 11.837827464000043],
              [51.232145038000056, 11.837827464000043],
              [51.232537649000051, 11.837541929000054],
              [51.233037336000052, 11.837256394000065],
              [51.233406152000043, 11.837149318000058],
              [51.23364409800007, 11.83710172900004],
              [51.233977223000068, 11.837208804000056],
              [51.234262758000057, 11.837244496000039],
              [51.234417423000082, 11.837196907000077],
              [51.234810034000077, 11.837030345000073],
              [51.235178851000057, 11.836839988000065],
              [51.235557709000034, 11.836595982000063],
              [51.235798836000072, 11.836358458000063],
              [51.236154430000056, 11.835864409000067],
              [51.236824424000076, 11.835486902000071],
              [51.236976599000059, 11.835364255000059],
              [51.237266491000071, 11.835130614000093],
              [51.237748746000079, 11.834853502000044],
              [51.238748042000054, 11.83446052700009],
              [51.239069270000073, 11.834686576000081],
              [51.239616546000036, 11.834876933000089],
              [51.240080541000054, 11.834865035000064],
              [51.240568330000087, 11.834769857000083],
              [51.241124531000082, 11.834695151000062],
              [51.241758061000041, 11.834793651000041],
              [51.242245850000074, 11.834734165000043],
              [51.242732047000061, 11.834774326000058],
              [51.24289279900006, 11.83509102700009],
              [51.24287981100008, 11.83528294000007],
              [51.242971668000052, 11.835326708000082],
              [51.243656935000047, 11.835653225000044],
              [51.243977873000063, 11.835684839000066],
              [51.244460128000071, 11.835882776000062],
              [51.244821819000038, 11.835961951000058],
              [51.244994127000041, 11.836114252000073],
              [51.245232074000057, 11.83617373900006],
              [51.245529506000082, 11.836221328000079],
              [51.245850733000054, 11.836340301000064],
              [51.246386112000039, 11.836602042000038],
              [51.247028567000086, 11.836923269000067],
              [51.247209024000085, 11.836563763000072],
              [51.24749033900008, 11.836563763000072],
              [51.247671021000087, 11.836709117000055],
              [51.248075529000062, 11.83668532300004],
              [51.248418742000069, 11.836604100000045],
              [51.248563319000084, 11.83668532300004],
              [51.249277157000051, 11.836661528000093],
              [51.249560191000057, 11.836637942000038],
              [51.249562693000087, 11.836637734000078],
              [51.249567864000085, 11.836640643000067],
              [51.249753050000038, 11.836744809000038],
              [51.250443093000058, 11.837077934000092],
              [51.250823807000074, 11.83713742000009],
              [51.251335391000055, 11.837470545000087],
              [51.251752511000063, 11.837828675000083],
              [51.252583984000069, 11.838097067000092],
              [51.252751170000067, 11.838148691000072],
              [51.252905835000035, 11.838172486000076],
              [51.253108090000069, 11.838279562000082],
              [51.253286549000052, 11.838350946000048],
              [51.253441214000077, 11.838481816000069],
              [51.253631571000085, 11.838600789000054],
              [51.253881414000034, 11.838755454000079],
              [51.254274025000086, 11.839052887000037],
              [51.25440489600004, 11.839314627000078],
              [51.254868891000058, 11.839338422000083],
              [51.254987864000043, 11.83963585500004],
              [51.255475653000076, 11.839552573000049],
              [51.255439961000036, 11.839850006000063],
              [51.255535140000063, 11.839945184000044],
              [51.256087440000044, 11.839815049000038],
              [51.256034827000065, 11.840218822000054],
              [51.256665384000087, 11.84059953600007],
              [51.257664757000043, 11.840896969000084],
              [51.258093060000078, 11.841004045000091],
              [51.258390493000036, 11.841087326000093],
              [51.258676028000082, 11.841158710000059],
              [51.258973461000039, 11.841123018000076],
              [51.259211407000066, 11.841241991000061],
              [51.259485045000076, 11.841325272000063],
              [51.259741119000068, 11.841488479000077],
              [51.259927293000032, 11.841626020000092],
              [51.260229827000046, 11.841729177000047],
              [51.260520111000062, 11.841932034000081],
              [51.260996003000059, 11.842134289000057],
              [51.261448100000052, 11.842134289000057],
              [51.262530755000057, 11.842181878000076],
              [51.262804393000067, 11.842193775000055],
              [51.263185350000072, 11.842160231000037],
              [51.26351115600005, 11.842183155000043],
              [51.263732383000047, 11.842193775000055],
              [51.26405804400008, 11.842050107000091],
              [51.264255855000044, 11.842050107000091],
              [51.264570024000079, 11.842027183000084],
              [51.264942374000043, 11.842027183000084],
              [51.265612157000078, 11.842015316000072],
              [51.266245596000033, 11.842084492000083],
              [51.267146909000076, 11.842193775000055],
              [51.26740865000005, 11.842158083000072],
              [51.267874625000047, 11.84210741600009],
              [51.268258610000032, 11.842118878000065],
              [51.26864597000008, 11.842193775000055],
              [51.268955300000073, 11.842205672000091],
              [51.269145657000081, 11.842277056000057],
              [51.269490679000057, 11.842633975000069],
              [51.270145030000037, 11.842812435000042],
              [51.272143778000043, 11.842883819000065],
              [51.274242577000052, 11.842553130000056],
              [51.277664127000037, 11.841194401000052],
              [51.27990082000008, 11.84008795200009],
              [51.280475943000056, 11.839615278000053],
              [51.28041240500005, 11.839433600000064],
              [51.280578967000054, 11.839290833000064],
              [51.280769324000062, 11.839171860000079],
              [51.280953396000086, 11.839150259000064],
              [51.281058119000079, 11.839092949000076],
              [51.281453741000064, 11.83892102100009],
              [51.28168645900007, 11.838760554000089],
              [51.281947157000047, 11.838374740000063],
              [51.282327871000064, 11.838208178000059],
              [51.282506330000047, 11.838077308000038],
              [51.282791871000086, 11.838038451000045],
              [51.283082769000032, 11.83786652200007],
              [51.283234036000067, 11.837740440000061],
              [51.283594749000088, 11.837476815000059],
              [51.283885647000034, 11.837201727000092],
              [51.284302823000075, 11.83695896100005],
              [51.284528872000067, 11.836732912000059],
              [51.285218916000076, 11.836328404000085],
              [51.285528246000069, 11.835888203000081],
              [51.286224467000068, 11.834978091000039],
              [51.286422277000042, 11.83488639400008],
              [51.286643360000085, 11.834771773000057],
              [51.28685280600007, 11.834703001000037],
              [51.28702734500007, 11.834622766000052],
              [51.287201884000069, 11.834301827000047],
              [51.287271699000087, 11.834141357000078],
              [51.287271699000087, 11.833935039000039],
              [51.287318243000072, 11.833682872000054],
              [51.28745787400004, 11.833361932000059],
              [51.287574233000043, 11.833189999000069],
              [51.287753042000077, 11.832854391000069],
              [51.287895809000076, 11.832640239000057],
              [51.288002885000083, 11.83243798500007],
              [51.288026680000087, 11.832271423000066],
              [51.288098064000053, 11.832033477000039],
              [51.288109961000032, 11.831855017000066],
              [51.288169447000087, 11.831640866000043],
              [51.288121858000068, 11.831212563000065],
              [51.288252729000078, 11.830855643000064],
              [51.288295660000074, 11.829923263000069],
              [51.288295660000074, 11.829395997000063],
              [51.288514469000063, 11.828702231000079],
              [51.288264626000057, 11.828214442000046],
              [51.288295660000074, 11.827596406000055],
              [51.288307296000085, 11.827172296000072],
              [51.28834220400006, 11.826656487000037],
              [51.288276523000036, 11.826203797000062],
              [51.288307296000085, 11.825544628000046],
              [51.288272388000053, 11.825120516000084],
              [51.288324112000055, 11.824811812000064],
              [51.28833601000008, 11.824597661000041],
              [51.28834220400006, 11.824077426000088],
              [51.288454983000065, 11.823895720000053],
              [51.288526367000088, 11.823693466000066],
              [51.288237480000078, 11.823286508000081],
              [51.288249116000088, 11.822942631000046],
              [51.288466880000044, 11.822872552000092],
              [51.288514469000063, 11.822765476000086],
              [51.288562059000071, 11.822575119000078],
              [51.28847877700008, 11.822194405000062],
              [51.287808848000054, 11.820878873000083],
              [51.287360431000081, 11.81980304700005],
              [51.287008588000049, 11.819602501000077],
              [51.286872641000059, 11.818113630000084],
              [51.286480030000064, 11.815615196000067],
              [51.286361057000079, 11.815353455000093],
              [51.286170700000071, 11.815020330000038],
              [51.286016035000046, 11.814663411000083],
              [51.285873268000046, 11.81431838900005],
              [51.285635321000086, 11.813699730000053],
              [51.285575835000031, 11.81353316700006],
              [51.285503040000037, 11.813141931000075],
              [51.285456496000052, 11.812603169000056],
              [51.285456496000052, 11.812373908000041],
              [51.285421588000077, 11.812167573000067],
              [51.285340137000048, 11.811846608000053],
              [51.285293593000063, 11.811720514000058],
              [51.285258685000088, 11.811582957000041],
              [51.285258685000088, 11.811456863000046],
              [51.285258954000085, 11.811456228000054],
              [51.285242588000074, 11.81142344400007],
              [51.285195121000072, 11.810903863000078],
              [51.285064251000051, 11.809571365000068],
              [51.285123737000049, 11.808845629000075],
              [51.284698998000067, 11.808575188000077],
              [51.284041083000034, 11.807406055000058],
              [51.283641334000038, 11.806301341000051],
              [51.283160682000073, 11.804324653000037],
              [51.283172579000052, 11.803789274000053],
              [51.28257771400007, 11.803289588000041],
              [51.28236213200006, 11.802314125000066],
              [51.281768697000075, 11.800874435000082],
              [51.281542649000073, 11.800493721000066],
              [51.281090551000034, 11.800577002000068],
              [51.281000453000047, 11.800110226000072],
              [51.280317226000079, 11.798899482000081],
              [51.279736037000077, 11.798529156000086],
              [51.278974170000083, 11.796924924000052],
              [51.278830063000044, 11.795889464000084],
              [51.279135173000043, 11.79570788500007],
              [51.278556425000033, 11.794283328000063],
              [51.277521359000048, 11.792974624000067],
              [51.276950289000069, 11.79193955900007],
              [51.276426807000064, 11.791273310000065],
              [51.276403013000049, 11.790785520000043],
              [51.27662906200004, 11.790285833000041],
              [51.276057991000073, 11.78991701700005],
              [51.275498818000074, 11.789310254000043],
              [51.274856363000083, 11.788977130000092],
              [51.274190114000078, 11.787787399000081],
              [51.274011654000049, 11.786704744000076],
              [51.273738016000038, 11.786466798000049],
              [51.273351859000059, 11.786115838000057],
              [51.272702951000042, 11.785015327000053],
              [51.272750540000061, 11.784527538000077],
              [51.272346032000087, 11.784396667000067],
              [51.271722713000088, 11.783562337000092],
              [51.270930252000085, 11.782076693000079],
              [51.270306693000066, 11.781454756000073],
              [51.269909314000074, 11.780605999000045],
              [51.269419295000034, 11.779376005000074],
              [51.269050478000054, 11.778555091000044],
              [51.268039207000072, 11.777186900000061],
              [51.267410880000057, 11.775966050000079],
              [51.266875501000072, 11.775252211000065],
              [51.266280636000033, 11.774835806000056],
              [51.265993739000066, 11.774708296000085],
              [51.265745257000049, 11.774597860000085],
              [51.264743654000085, 11.773855655000091],
              [51.264113097000063, 11.772915768000075],
              [51.263518231000035, 11.772047265000083],
              [51.263197004000062, 11.771345324000038],
              [51.262614036000059, 11.770631485000081],
              [51.262161939000066, 11.769774879000067],
              [51.261483792000035, 11.768537560000084],
              [51.261182787000052, 11.767835213000069],
              [51.26112687300008, 11.767704748000085],
              [51.260828211000046, 11.767328655000085],
              [51.260484419000079, 11.76689573200008],
              [51.260405324000033, 11.76672450500007],
              [51.259401764000074, 11.764551962000041],
              [51.258699823000086, 11.762136810000072],
              [51.25831910900007, 11.760268933000077],
              [51.258290264000038, 11.759222872000066],
              [51.258009779000076, 11.757199428000092],
              [51.257783730000085, 11.756116773000088],
              [51.257736141000066, 11.754498740000088],
              [51.257414230000052, 11.752961646000074],
              [51.257343530000071, 11.75241671100008],
              [51.25731058000008, 11.752149445000043],
              [51.257450606000077, 11.751560105000067],
              [51.257724244000087, 11.750441759000068],
              [51.258130982000068, 11.749970438000048],
              [51.258249955000053, 11.749554033000038],
              [51.258818796000071, 11.748621471000092],
              [51.259818170000074, 11.748359730000061],
              [51.260094037000044, 11.747888410000087],
              [51.260198883000044, 11.747015335000071],
              [51.259960937000073, 11.746123037000075],
              [51.259342277000087, 11.744968998000047],
              [51.258854488000054, 11.743660295000041],
              [51.259032947000037, 11.741697240000065],
              [51.259074789000067, 11.740666701000066],
              [51.259175715000083, 11.739936438000086],
              [51.259330380000051, 11.739115524000056],
              [51.259352890000059, 11.738507958000071],
              [51.259032947000037, 11.736807447000047],
              [51.259068639000077, 11.73595084100009],
              [51.258970434000048, 11.735326740000062],
              [51.258386858000051, 11.733026451000057],
              [51.258081163000043, 11.731929552000054],
              [51.257331633000035, 11.731025357000078],
              [51.255880162000039, 11.729038507000041],
              [51.25495217200006, 11.728181901000085],
              [51.254431583000041, 11.727299663000053],
              [51.253929522000078, 11.726681216000088],
              [51.253595879000045, 11.725933310000073],
              [51.253238960000033, 11.725374137000074],
              [51.252917733000061, 11.724862553000037],
              [51.252572711000084, 11.724469942000042],
              [51.252144408000049, 11.724041639000063],
              [51.25172800200005, 11.723482466000064],
              [51.25172800200005, 11.723089854000079],
              [51.25107365000008, 11.72294708700008],
              [51.250764320000087, 11.722649654000065],
              [51.250538272000085, 11.722292735000053],
              [51.250336017000052, 11.721864432000075],
              [51.250169455000048, 11.721507513000063],
              [51.249895817000038, 11.72101972300004],
              [51.249764947000074, 11.720746085000087],
              [51.24959838500007, 11.720460550000041],
              [51.249372336000079, 11.719984658000044],
              [51.249158184000066, 11.719639636000068],
              [51.248698151000042, 11.718682690000037],
              [51.248563319000084, 11.71824765100007],
              [51.248384859000055, 11.717890732000058],
              [51.248170708000032, 11.717379148000077],
              [51.247920865000083, 11.716831872000057],
              [51.247778097000037, 11.716201315000092],
              [51.247635329000047, 11.715594552000084],
              [51.247480664000079, 11.714904509000064],
              [51.24743307500006, 11.714404822000063],
              [51.247385486000042, 11.713845648000074],
              [51.247314102000075, 11.713274578000039],
              [51.247444972000039, 11.712525048000089],
              [51.247659124000052, 11.711989669000047],
              [51.247789994000073, 11.711347214000057],
              [51.247916609000072, 11.710664530000088],
              [51.248277784000038, 11.710097997000048],
              [51.248301578000053, 11.709503132000066],
              [51.248218297000051, 11.708824986000081],
              [51.24814146500006, 11.708226941000078],
              [51.24814146500006, 11.70805458600006],
              [51.248216417000037, 11.707906852000065],
              [51.248416289000033, 11.707882230000052],
              [51.24851622400007, 11.70785760800004],
              [51.248591176000048, 11.70756214000005],
              [51.248741080000059, 11.707143561000066],
              [51.248766064000051, 11.706577247000041],
              [51.248691112000074, 11.70618328900008],
              [51.248766064000051, 11.705395371000066],
              [51.248915968000063, 11.704755186000057],
              [51.249240759000088, 11.704164245000072],
              [51.24936567900005, 11.703696415000081],
              [51.249465615000076, 11.703400943000076],
              [51.249590535000038, 11.703105471000072],
              [51.249765423000042, 11.702859244000081],
              [51.249840374000087, 11.702686885000048],
              [51.249890342000072, 11.70261301700009],
              [51.250040246000083, 11.702489903000071],
              [51.250664845000074, 11.702157496000041],
              [51.251214492000088, 11.702034382000079],
              [51.25150953900004, 11.701643731000047],
              [51.251634459000059, 11.701397503000067],
              [51.251787489000037, 11.70118691600004],
              [51.251939027000049, 11.700778618000072],
              [51.25208893100006, 11.700458520000041],
              [51.252313787000048, 11.700187668000069],
              [51.252513658000055, 11.699966061000055],
              [51.252667889000065, 11.699616472000059],
              [51.252560813000059, 11.699045401000092],
              [51.252489430000082, 11.69854571400009],
              [51.252713530000051, 11.697774608000088],
              [51.252938386000039, 11.697429884000087],
              [51.253108090000069, 11.69703475600005],
              [51.253488803000039, 11.696737324000082],
              [51.253812825000068, 11.696518824000066],
              [51.254112632000044, 11.696272591000081],
              [51.254362472000082, 11.696050982000088],
              [51.254512376000037, 11.695706255000061],
              [51.254537359000039, 11.69526303400005],
              [51.25444058800008, 11.694845652000083],
              [51.254562343000032, 11.694512020000047],
              [51.254619047000062, 11.694215095000061],
              [51.254833199000075, 11.693929560000072],
              [51.255071145000045, 11.693655922000062],
              [51.25522581000007, 11.693310900000085],
              [51.255451859000061, 11.692787419000069],
              [51.255582729000082, 11.692466191000051],
              [51.25564221500008, 11.692144964000079],
              [51.255677907000063, 11.691657175000046],
              [51.255654113000048, 11.691002823000076],
              [51.255761189000054, 11.689872579000053],
              [51.255761189000054, 11.689301508000085],
              [51.255594626000061, 11.688504389000059],
              [51.255761189000054, 11.688052291000076],
              [51.256036397000059, 11.687912854000047],
              [51.256136333000086, 11.687789733000045],
              [51.256211285000063, 11.687543493000078],
              [51.255892059000075, 11.687041020000038],
              [51.255808778000073, 11.68661271700006],
              [51.255961445000082, 11.686189164000041],
              [51.256141902000081, 11.685720419000063],
              [51.256260875000066, 11.685375398000076],
              [51.256403643000056, 11.684804327000052],
              [51.256463130000043, 11.684280845000046],
              [51.256586044000073, 11.683825230000082],
              [51.256586044000073, 11.683554362000052],
              [51.256689178000045, 11.683233883000071],
              [51.256684759000052, 11.683021444000076],
              [51.256724870000085, 11.682793682000067],
              [51.256498821000037, 11.681603952000046],
              [51.256511092000039, 11.680550163000078],
              [51.256461125000044, 11.680180792000044],
              [51.256511092000039, 11.679712921000089],
              [51.256636012000058, 11.679343549000066],
              [51.256675987000051, 11.679259825000088],
              [51.25670107600007, 11.678903264000041],
              [51.256617795000068, 11.678617728000063],
              [51.25642743800006, 11.678272706000087],
              [51.25628623700004, 11.677989181000044],
              [51.256086365000044, 11.677742931000068],
              [51.255911477000041, 11.677496681000036],
              [51.255439961000036, 11.677094873000044],
              [51.255332886000076, 11.676940208000076],
              [51.255106837000085, 11.676702262000049],
              [51.254904583000041, 11.676464316000079],
              [51.254690431000085, 11.676190678000069],
              [51.254583355000079, 11.675774272000069],
              [51.254476280000063, 11.675512532000084],
              [51.254321615000038, 11.675286483000036],
              [51.254143155000065, 11.675179407000087],
              [51.253940901000078, 11.674774899000056],
              [51.253833825000072, 11.674489363000077],
              [51.253738647000034, 11.674180033000084],
              [51.253691057000083, 11.673906395000074],
              [51.253679160000047, 11.67359706600007],
              [51.253726749000066, 11.673097379000069],
              [51.253766275000032, 11.672825104000083],
              [51.25366133700004, 11.672204512000064],
              [51.253662921000057, 11.672202258000084],
              [51.253595879000045, 11.672038519000068],
              [51.253512598000043, 11.671788675000073],
              [51.253381728000079, 11.671550729000046],
              [51.25321320900008, 11.671241863000091],
              [51.253063305000069, 11.670995607000066],
              [51.25283845000007, 11.67074935100004],
              [51.252608403000067, 11.670658431000049],
              [51.252251484000055, 11.670420485000079],
              [51.251835078000056, 11.670146847000069],
              [51.251549543000067, 11.669956490000061],
              [51.251323494000076, 11.66982562000004],
              [51.250573964000068, 11.669409214000041],
              [51.249950813000055, 11.66870330200004],
              [51.249946469000065, 11.66869408000008],
              [51.249562693000087, 11.667993435000085],
              [51.24905110900005, 11.666946472000063],
              [51.24889098400007, 11.666538342000081],
              [51.248884286000077, 11.666439326000045],
              [51.248682292000069, 11.665685358000076],
              [51.248682292000069, 11.665471206000063],
              [51.248658497000065, 11.665209465000089],
              [51.248456243000078, 11.664757368000039],
              [51.248266384000033, 11.664420500000062],
              [51.248166449000053, 11.664124986000047],
              [51.247966577000057, 11.663903350000055],
              [51.247666769000034, 11.663558583000054],
              [51.247541849000072, 11.663336947000062],
              [51.247416930000043, 11.663090684000053],
              [51.247292010000081, 11.662869047000072],
              [51.247092138000085, 11.662548905000051],
              [51.246892266000032, 11.662302642000043],
              [51.246467539000037, 11.661711608000076],
              [51.246192715000063, 11.661465344000078],
              [51.245792972000061, 11.661145200000078],
              [51.245618084000057, 11.660948189000067],
              [51.245513677000076, 11.660858139000084],
              [51.24541821300005, 11.660775803000092],
              [51.245218341000054, 11.66070192400008],
              [51.245190695000076, 11.660690245000069],
              [51.245159120000039, 11.66065328600007],
              [51.244610547000036, 11.659842199000082],
              [51.244593742000063, 11.659593729000051],
              [51.244493806000037, 11.65937209000009],
              [51.244318918000033, 11.659125823000068],
              [51.244144030000086, 11.658953437000037],
              [51.243994127000065, 11.658731797000087],
              [51.243794255000068, 11.65851015700008],
              [51.243185737000033, 11.656447100000037],
              [51.243331541000032, 11.656222786000058],
              [51.243495067000083, 11.655971208000039],
              [51.24328091600006, 11.655233575000068],
              [51.242779696000071, 11.653640144000065],
              [51.242257747000053, 11.652973087000078],
              [51.242069620000052, 11.652293564000047],
              [51.241055242000073, 11.651387408000062],
              [51.240052708000064, 11.650491831000068],
              [51.239907386000084, 11.650173502000087],
              [51.239882614000066, 11.650119238000059],
              [51.238146786000073, 11.649201108000057],
              [51.237923023000064, 11.649201108000057],
              [51.237598232000039, 11.649053342000059],
              [51.237379573000055, 11.648970443000053],
              [51.237273440000081, 11.648930205000056],
              [51.237184496000054, 11.648877600000048],
              [51.236898681000071, 11.648708557000077],
              [51.236473954000076, 11.64836377000006],
              [51.236124178000068, 11.648043611000048],
              [51.235749419000058, 11.647723452000037],
              [51.235500078000086, 11.64728617500009],
              [51.234691061000035, 11.646405775000062],
              [51.23433414200008, 11.646096445000069],
              [51.233763072000045, 11.645525374000044],
              [51.232064741000045, 11.643651534000071],
              [51.230455621000033, 11.642360691000079],
              [51.23000352300005, 11.641837210000062],
              [51.229503836000049, 11.641361318000065],
              [51.22921830100006, 11.641075782000087],
              [51.228789998000082, 11.64059989000009],
              [51.228242722000061, 11.639552927000068],
              [51.227304839000055, 11.638438665000081],
              [51.227257688000066, 11.637997105000068],
              [51.227043928000057, 11.637127887000076],
              [51.226022669000088, 11.635018532000061],
              [51.224954882000077, 11.633874390000074],
              [51.224887682000087, 11.633342534000064],
              [51.22479250400005, 11.633056999000075],
              [51.22464973600006, 11.632485928000051],
              [51.224456667000084, 11.631715003000068],
              [51.224475012000084, 11.631140785000071],
              [51.224483174000056, 11.62967816400004],
              [51.223882888000048, 11.628882135000083],
              [51.222764366000035, 11.627443897000092],
              [51.220478691000039, 11.626245359000052],
              [51.220404138000049, 11.626250844000083],
              [51.220367596000074, 11.626152984000043],
              [51.219565685000077, 11.625216705000071],
              [51.219058003000043, 11.624847858000066],
              [51.218082424000045, 11.623824690000049],
              [51.217654121000066, 11.623396387000071],
              [51.217307805000075, 11.623088550000091],
              [51.217225818000088, 11.623015673000054],
              [51.216797515000053, 11.622587370000076],
              [51.215787232000082, 11.621764667000036],
              [51.215130898000041, 11.620973043000049],
              [51.215108097000041, 11.620945542000072],
              [51.214703589000067, 11.620683801000041],
              [51.214205242000048, 11.620348480000075],
              [51.212357249000036, 11.61852665400005],
              [51.210411993000037, 11.616800702000091],
              [51.208374223000078, 11.614949300000092],
              [51.208088687000043, 11.614616176000084],
              [51.207499023000082, 11.614211115000046],
              [51.207079064000084, 11.613741892000064],
              [51.206491122000045, 11.613134661000061],
              [51.20618315300004, 11.612858647000053],
              [51.205455224000048, 11.612499828000068],
              [51.205091260000074, 11.612196211000082],
              [51.204587310000079, 11.611699383000087],
              [51.204083360000084, 11.611257757000089],
              [51.202879479000046, 11.610070885000084],
              [51.202459520000048, 11.609656858000051],
              [51.202011564000088, 11.609215229000085],
              [51.201535611000054, 11.608828803000051],
              [51.201115653000045, 11.608276765000085],
              [51.201077708000071, 11.608237017000079],
              [51.200667697000085, 11.607807532000038],
              [51.200275736000037, 11.60731069600007],
              [51.199927136000042, 11.606835338000053],
              [51.19933227100006, 11.606335652000041],
              [51.199043857000049, 11.605985796000084],
              [51.198539907000054, 11.605488957000091],
              [51.198190130000057, 11.605050743000049],
              [51.197428702000082, 11.604003780000085],
              [51.197119372000031, 11.603408915000045],
              [51.196619685000087, 11.602671282000074],
              [51.195858258000044, 11.601243605000093],
              [51.19545374900008, 11.600434588000041],
              [51.195245616000079, 11.59956369400004],
              [51.195062017000055, 11.598795461000066],
              [51.193883305000043, 11.596984370000087],
              [51.192908725000052, 11.596088336000037],
              [51.192126592000079, 11.595369238000046],
              [51.190035442000067, 11.593259563000061],
              [51.187944293000044, 11.591245768000078],
              [51.186340414000085, 11.58922732700006],
              [51.184555818000035, 11.587157196000078],
              [51.182104973000037, 11.584515994000071],
              [51.179868280000051, 11.582017560000054],
              [51.177774354000064, 11.579661894000083],
              [51.176953600000047, 11.578794648000041],
              [51.176197499000068, 11.577995718000068],
              [51.174254981000047, 11.576210298000092],
              [51.172872664000067, 11.573641857000041],
              [51.169255884000052, 11.56888293500009],
              [51.166283787000054, 11.564967345000071],
              [51.163890357000071, 11.562025128000073],
              [51.160713619000035, 11.55731875500004],
              [51.160428083000056, 11.556842863000043],
              [51.159245829000042, 11.555358538000064],
              [51.158230515000071, 11.554083806000051],
              [51.156771574000061, 11.551446315000078],
              [51.155507158000034, 11.549000620000072],
              [51.152832431000036, 11.543629605000092],
              [51.150696088000075, 11.539567976000058],
              [51.148854235000044, 11.537008678000063],
              [51.147780916000045, 11.535260012000037],
              [51.147528941000076, 11.534928702000059],
              [51.146493043000078, 11.533493017000069],
              [51.146101082000087, 11.53299604700004],
              [51.145877104000078, 11.532581905000086],
              [51.145681123000088, 11.532195372000047],
              [51.145485142000041, 11.531946886000071],
              [51.145177173000036, 11.531615571000088],
              [51.145065184000032, 11.531311866000067],
              [51.144673223000041, 11.530704453000055],
              [51.14428126100006, 11.530290308000076],
              [51.144113278000077, 11.529986601000076],
              [51.144086525000034, 11.529722771000081],
              [51.143981519000079, 11.529578155000081],
              [51.142423568000083, 11.526198073000046],
              [51.141841738000039, 11.525453703000039],
              [51.141015005000042, 11.523727170000086],
              [51.139488826000047, 11.522126527000069],
              [51.139214805000051, 11.521253081000054],
              [51.139108113000077, 11.520913001000054],
              [51.138821800000073, 11.520461074000082],
              [51.138679810000042, 11.52005639500004],
              [51.138513247000049, 11.519699476000085],
              [51.138394274000063, 11.519390146000092],
              [51.138180123000041, 11.518961843000056],
              [51.138061150000055, 11.518557335000082],
              [51.137728025000058, 11.517914881000081],
              [51.137513874000035, 11.51755796100008],
              [51.137204544000042, 11.517129658000044],
              [51.13693894000005, 11.516669855000089],
              [51.136854948000064, 11.516172855000093],
              [51.136657268000079, 11.515297473000089],
              [51.133587763000037, 11.51137136300008],
              [51.13124313600008, 11.508306717000039],
              [51.127639111000065, 11.503709498000092],
              [51.126711641000043, 11.502371546000063],
              [51.126425589000064, 11.502000713000086],
              [51.12411843600006, 11.498286065000059],
              [51.123750862000065, 11.496628789000056],
              [51.122778235000055, 11.494134646000077],
              [51.122389184000042, 11.491208795000091],
              [51.122451557000034, 11.490870441000084],
              [51.122523270000045, 11.49033692800009],
              [51.122642243000087, 11.488956840000071],
              [51.122713626000063, 11.488243002000047],
              [51.122808805000034, 11.487790904000065],
              [51.123308492000035, 11.487172244000078],
              [51.12397474100004, 11.486886709000089],
              [51.12456960600008, 11.486601174000043],
              [51.124881165000033, 11.485731294000061],
              [51.125178598000048, 11.485255401000074],
              [51.125263956000083, 11.483963397000082],
              [51.125112541000078, 11.482718859000045],
              [51.125075193000043, 11.482322857000042],
              [51.125116882000043, 11.481961225000077],
              [51.124902731000077, 11.481437743000072],
              [51.124759963000088, 11.48039078100004],
              [51.12520291900006, 11.479022021000048],
              [51.125485889000061, 11.477936613000054],
              [51.125759336000044, 11.476702616000068],
              [51.126496969000073, 11.475655653000047],
              [51.126824536000072, 11.47502182900007],
              [51.127210808000086, 11.471229856000036],
              [51.127520138000079, 11.468398297000078],
              [51.127900851000049, 11.467565486000069],
              [51.128043619000039, 11.466161604000092],
              [51.128030424000087, 11.463171836000072],
              [51.127446847000044, 11.461828677000085],
              [51.127329781000071, 11.460784022000041],
              [51.12761531600006, 11.458880453000063],
              [51.127738636000061, 11.456264094000062],
              [51.127446847000044, 11.454105389000063],
              [51.126863271000047, 11.452714215000071],
              [51.126474220000034, 11.450507512000058],
              [51.126163845000065, 11.449481582000089],
              [51.125497596000059, 11.44800631600009],
              [51.125404329000048, 11.447053505000042],
              [51.125878310000076, 11.446364488000086],
              [51.126116256000046, 11.445412704000091],
              [51.125735542000086, 11.443651903000045],
              [51.124674858000049, 11.442064310000092],
              [51.124139913000079, 11.440529155000092],
              [51.124091282000052, 11.438418303000049],
              [51.124007153000036, 11.438017176000074],
              [51.123848125000052, 11.43777065200004],
              [51.123237108000069, 11.43522861100007],
              [51.12309434000008, 11.434348210000053],
              [51.122166350000043, 11.432611204000068],
              [51.121904610000058, 11.430969375000075],
              [51.121547691000046, 11.42947031500006],
              [51.120762468000066, 11.427495362000059],
              [51.120120014000065, 11.42628183700009],
              [51.119644122000068, 11.424473447000082],
              [51.119647585000052, 11.423788971000079],
              [51.119786889000068, 11.422974386000078],
              [51.119620327000064, 11.422070191000046],
              [51.118984986000044, 11.419923680000068],
              [51.118240240000034, 11.417715778000058],
              [51.118145061000064, 11.416145333000088],
              [51.117502607000063, 11.414170381000076],
              [51.116229063000048, 11.412821009000083],
              [51.115765600000032, 11.411315028000047],
              [51.115265913000087, 11.408911772000067],
              [51.114837610000052, 11.406556106000039],
              [51.11482794300008, 11.404472700000042],
              [51.114114104000066, 11.402569131000064],
              [51.113830010000072, 11.401241435000088],
              [51.113857413000062, 11.401094430000057],
              [51.113528907000045, 11.400083972000061],
              [51.112196409000035, 11.398037635000037],
              [51.110425862000056, 11.395338754000079],
              [51.108966920000057, 11.393035634000057],
              [51.107070296000074, 11.389245041000038],
              [51.105757249000078, 11.386558008000065],
              [51.105903143000035, 11.384782632000054],
              [51.104638727000065, 11.381759670000065],
              [51.103082523000069, 11.37739311200005],
              [51.101012942000068, 11.374219230000051],
              [51.099726957000087, 11.371466959000088],
              [51.097927596000034, 11.367916007000076],
              [51.096809074000078, 11.365276756000071],
              [51.095398764000038, 11.36220559700007],
              [51.094088710000051, 11.359062064000057],
              [51.093210351000039, 11.356783],
              [51.092821300000082, 11.354863471000044],
              [51.092334986000083, 11.352080132000083],
              [51.091185768000059, 11.34904453300004],
              [51.089686708000045, 11.346117796000044],
              [51.08863974500008, 11.344023870000058],
              [51.087228691000064, 11.340490586000044],
              [51.085189526000079, 11.334862945000054],
              [51.084019019000038, 11.330556311000066],
              [51.083997908000072, 11.330475581000087],
              [51.083781443000078, 11.330193989000065],
              [51.082806771000037, 11.327339395000081],
              [51.082286584000087, 11.324202960000036],
              [51.082147181000039, 11.321937749000085],
              [51.081926422000038, 11.320668221000062],
              [51.081882076000056, 11.319943725000087],
              [51.081620335000082, 11.317754621000063],
              [51.081287210000085, 11.315494133000072],
              [51.081733344000043, 11.312222577000057],
              [51.08164412900004, 11.307356376000087],
              [51.081490187000043, 11.303823218000048],
              [51.081477567000036, 11.300408350000055],
              [51.081096853000076, 11.298052684000083],
              [51.080906497000058, 11.296244293000086],
              [51.081003873000043, 11.294175646000042],
              [51.081476394000049, 11.29260693000009],
              [51.081344293000086, 11.291511707000041],
              [51.080906610000056, 11.289543735000052],
              [51.080517559000043, 11.28805574800009],
              [51.080142402000035, 11.286408446000053],
              [51.079954712000074, 11.285584308000068],
              [51.079739457000073, 11.283495737000067],
              [51.079930918000059, 11.282372036000083],
              [51.079911215000038, 11.280263826000066],
              [51.079907123000055, 11.279826012000058],
              [51.078907749000052, 11.276756508000062],
              [51.078243730000054, 11.27479207500005],
              [51.077886811000042, 11.272888507000062],
              [51.077813197000069, 11.272378299000081],
              [51.077718019000088, 11.271854818000065],
              [51.077273149000064, 11.269217324000067],
              [51.077170743000067, 11.267524199000093],
              [51.077051770000082, 11.262479742000039],
              [51.077051770000082, 11.256578678000039],
              [51.076707210000052, 11.249975146000054],
              [51.076581446000034, 11.243891863000044],
              [51.07693279700004, 11.237352633000057],
              [51.077027975000078, 11.235924957000066],
              [51.077170743000067, 11.233950004000064],
              [51.077741813000046, 11.229952510000089],
              [51.078741187000048, 11.224622517000057],
              [51.07978815000007, 11.219125962000078],
              [51.080430604000071, 11.216151636000063],
              [51.081099083000083, 11.213223522000078],
              [51.082072432000075, 11.209274994000054],
              [51.083761850000087, 11.204754018000074],
              [51.084894383000062, 11.201835164000045],
              [51.085570240000038, 11.199519203000079],
              [51.087821060000067, 11.193533482000078],
              [51.090186394000057, 11.188764040000081],
              [51.09197099000005, 11.184837929000082],
              [51.09570674400004, 11.178342],
              [51.098608434000084, 11.174009539000053],
              [51.102157313000077, 11.168727601000057],
              [51.103933956000049, 11.167171870000061],
              [51.105488558000047, 11.165217896000058],
              [51.109222082000088, 11.16211407600008],
              [51.111887079000041, 11.160519838000084],
              [51.11488520000006, 11.159520464000082],
              [51.117802269000038, 11.158376946000089],
              [51.121416554000064, 11.157778677000067],
              [51.125093087000039, 11.157355154000072],
              [51.126761338000051, 11.156880523000041],
              [51.130413412000053, 11.156235431000084],
              [51.134517982000034, 11.155819025000085],
              [51.138608426000076, 11.155094666000082],
              [51.143200786000079, 11.154523596000047],
              [51.149197027000071, 11.153714579000052],
              [51.153051754000046, 11.153143508000085],
              [51.15512115100006, 11.152844392000077],
              [51.156825429000037, 11.152190347000044],
              [51.159476299000062, 11.151858599000093],
              [51.161760634000075, 11.151613958000041],
              [51.163131001000067, 11.15308264500004],
              [51.16533200300006, 11.15349905100004],
              [51.167205609000064, 11.15289838700005],
              [51.16924812700006, 11.152082006000057],
              [51.172277799000085, 11.152382081000042],
              [51.174667921000037, 11.15316856000004],
              [51.176941543000055, 11.15380975700009],
              [51.176870159000032, 11.151834805000078],
              [51.177940916000068, 11.150097798000047],
              [51.179416182000068, 11.149502933000065],
              [51.179628323000088, 11.148030113000061],
              [51.180406425000058, 11.147117669000068],
              [51.181865366000068, 11.146349293000071],
              [51.18254620600004, 11.14505265300005],
              [51.183080552000035, 11.142531112000086],
              [51.182303049000041, 11.139962086000082],
              [51.182128768000041, 11.137843574000044],
              [51.181890822000071, 11.137224914000058],
              [51.181343546000051, 11.13665384400008],
              [51.180748680000079, 11.135868621000043],
              [51.179480989000069, 11.134671662000073],
              [51.179194537000058, 11.134169338000049],
              [51.178266644000075, 11.132542183000055],
              [51.176798775000066, 11.130086531000075],
              [51.17554328600005, 11.127691528000071],
              [51.175510710000083, 11.127379013000052],
              [51.175428633000081, 11.126591601000086],
              [51.17510513700006, 11.12615082700006],
              [51.174959709000063, 11.124953993000076],
              [51.173743925000053, 11.122552625000083],
              [51.171897085000069, 11.119759670000064],
              [51.169850749000034, 11.116047711000078],
              [51.168208921000087, 11.113929991000077],
              [51.165758076000088, 11.110789102000069],
              [51.164140043000032, 11.108219285000075],
              [51.162117501000068, 11.105007012000044],
              [51.161926498000071, 11.102716563000058],
              [51.160759344000041, 11.100170916000081],
              [51.160671430000036, 11.100004808000051],
              [51.160175768000045, 11.099546509000049],
              [51.159024201000079, 11.098796619000041],
              [51.158380691000048, 11.097535338000057],
              [51.157834471000058, 11.096464747000084],
              [51.155847574000063, 11.091957450000052],
              [51.154777684000067, 11.089795972000047],
              [51.154120867000074, 11.087088689000041],
              [51.154074922000063, 11.08689931400005],
              [51.153958750000072, 11.086730181000064],
              [51.152456889000064, 11.084543648000079],
              [51.150458142000048, 11.080665126000042],
              [51.148459394000042, 11.076477275000059],
              [51.147007923000047, 11.073098440000081],
              [51.144710987000053, 11.067075464000084],
              [51.144274113000051, 11.065313365000065],
              [51.144127410000067, 11.06472165200006],
              [51.14281436300007, 11.06157519900006],
              [51.141892082000084, 11.058369577000065],
              [51.141379742000083, 11.057313608000072],
              [51.139988513000048, 11.05382480600008],
              [51.139653323000061, 11.053216518000056],
              [51.138608426000076, 11.049851106000062],
              [51.137680436000039, 11.047424056000068],
              [51.13605460000008, 11.042695766000065],
              [51.134372985000084, 11.038953175000074],
              [51.133611558000041, 11.036050232000036],
              [51.132614414000045, 11.033276783000076],
              [51.13218388100006, 11.031219926000063],
              [51.131615830000044, 11.029430371000046],
              [51.130641804000049, 11.027175106000072],
              [51.130174026000077, 11.026349106000055],
              [51.127258397000048, 11.012969461000068],
              [51.126163845000065, 11.007044603000054],
              [51.125687953000067, 11.002261886000042],
              [51.124950320000039, 10.997955062000074],
              [51.12326454500004, 10.991335571000093],
              [51.121819247000076, 10.984861898000077],
              [51.121805604000087, 10.984800789000076],
              [51.121124764000058, 10.979467145000058],
              [51.119994954000049, 10.971513390000041],
              [51.119362746000036, 10.96531453700004],
              [51.119344931000057, 10.963536668000074],
              [51.119334792000075, 10.962524887000086],
              [51.119073051000044, 10.959383999000067],
              [51.118813540000076, 10.95517097600009],
              [51.118754053000032, 10.953624326000067],
              [51.118716132000088, 10.952055259000076],
              [51.118694567000034, 10.949757702000056],
              [51.118549570000084, 10.947772229000066],
              [51.118549570000084, 10.938896839000051],
              [51.118963273000077, 10.936791436000078],
              [51.119011904000047, 10.931697206000081],
              [51.119001667000077, 10.926000161000047],
              [51.118930283000054, 10.921050882000088],
              [51.118954078000058, 10.917386512000064],
              [51.118882694000035, 10.914626337000072],
              [51.119348919000061, 10.906867917000056],
              [51.12023898700005, 10.896280693000051],
              [51.121371955000086, 10.891951913000071],
              [51.121951497000055, 10.891351837000059],
              [51.123022956000057, 10.889118515000064],
              [51.123213313000065, 10.887667044000068],
              [51.123221124000054, 10.883759739000084],
              [51.123260902000084, 10.882075311000051],
              [51.12337020700005, 10.880051392000041],
              [51.123570232000077, 10.878648887000054],
              [51.123864039000068, 10.877411881000057],
              [51.124056297000038, 10.866228285000091],
              [51.12411750800004, 10.862849266000069],
              [51.124355454000067, 10.857995165000091],
              [51.124640990000046, 10.852308253000047],
              [51.124664784000061, 10.849643257000082],
              [51.125069293000081, 10.845764736000092],
              [51.12553551700006, 10.837923035000074],
              [51.126130382000042, 10.832747707000067],
              [51.126401791000035, 10.829655785000057],
              [51.126782505000051, 10.826681458000053],
              [51.127282192000052, 10.816164241000081],
              [51.127377370000033, 10.813642012000059],
              [51.127401165000038, 10.811809827000047],
              [51.127329781000071, 10.81040594500007],
              [51.127496343000075, 10.808430992000069],
              [51.127979393000032, 10.803362115000084],
              [51.128091208000058, 10.800388414000054],
              [51.128091208000058, 10.796985785000061],
              [51.128614690000063, 10.794344583000054],
              [51.128924020000056, 10.791798560000075],
              [51.129569492000087, 10.789163927000061],
              [51.130121569000039, 10.786910506000083],
              [51.130461989000082, 10.784121694000078],
              [51.130232723000063, 10.780115407000039],
              [51.130327902000033, 10.776355858000045],
              [51.130089956000063, 10.775499252000088],
              [51.130089956000063, 10.773643273000062],
              [51.129994777000036, 10.77088309800007],
              [51.129756831000066, 10.768075334000059],
              [51.129828215000032, 10.763578153000083],
              [51.130113750000078, 10.759747220000065],
              [51.130613437000079, 10.755464190000055],
              [51.130637232000083, 10.754155487000048],
              [51.131288722000079, 10.752529355000092],
              [51.131142828000065, 10.748922710000045],
              [51.130899671000066, 10.745508380000047],
              [51.130510620000052, 10.741276479000078],
              [51.130170200000066, 10.737236882000047],
              [51.129185760000041, 10.735119799000074],
              [51.129019198000037, 10.733977658000072],
              [51.128091208000058, 10.724840527000083],
              [51.127996030000077, 10.723341467000068],
              [51.127877057000035, 10.72181861200005],
              [51.127761238000062, 10.718498482000086],
              [51.127662905000079, 10.71567960200008],
              [51.127829468000073, 10.70839845200004],
              [51.128672007000034, 10.70411411200007],
              [51.129140547000077, 10.700810907000061],
              [51.129147899000088, 10.700759072000039],
              [51.129683886000066, 10.69900227100004],
              [51.129828215000032, 10.697381547000077],
              [51.130446875000075, 10.691956376000064],
              [51.130375491000052, 10.687958882000089],
              [51.130661026000041, 10.683985182000072],
              [51.13088930400005, 10.682187312000053],
              [51.131351070000051, 10.680320812000048],
              [51.131707989000063, 10.679464206000091],
              [51.132141802000035, 10.677177632000053],
              [51.132504507000078, 10.676588266000067],
              [51.134277807000046, 10.673682116000066],
              [51.136638174000041, 10.670671843000036],
              [51.137727517000087, 10.66905562900007],
              [51.13780532800007, 10.668651575000069],
              [51.13901293400005, 10.667424133000054],
              [51.140625948000036, 10.667449027000089],
              [51.142084889000046, 10.666775599000061],
              [51.142534537000074, 10.665472975000057],
              [51.143414937000045, 10.664973288000056],
              [51.14423303500007, 10.664895182000066],
              [51.144580873000052, 10.664426012000092],
              [51.145992874000058, 10.663839091000057],
              [51.147039837000079, 10.662375722000093],
              [51.147628867000037, 10.659656404000089],
              [51.147587113000043, 10.65626050700007],
              [51.148170081000046, 10.653428949000045],
              [51.148893283000064, 10.651286325000058],
              [51.148515103000079, 10.648146545000088],
              [51.148729254000045, 10.644969965000087],
              [51.14899054600005, 10.64195389300005],
              [51.149133762000076, 10.640496578000068],
              [51.149692936000065, 10.63666564600004],
              [51.150394877000053, 10.633619936000059],
              [51.150525747000074, 10.630609918000061],
              [51.151251483000067, 10.627528516000041],
              [51.151858245000085, 10.624197270000082],
              [51.152405521000048, 10.621841604000053],
              [51.153012284000056, 10.619973727000058],
              [51.154035452000073, 10.616904222000073],
              [51.155118101000085, 10.613930807000088],
              [51.156426810000085, 10.609968094000067],
              [51.157009778000088, 10.608219190000057],
              [51.157925871000032, 10.604471539000087],
              [51.159349031000033, 10.601566145000049],
              [51.160174462000043, 10.59851098900009],
              [51.160314877000076, 10.59802953500008],
              [51.162161858000047, 10.594219721000059],
              [51.162994123000033, 10.592705104000061],
              [51.164503958000068, 10.59057223700006],
              [51.165813784000079, 10.588196025000059],
              [51.167741148000061, 10.585733283000081],
              [51.169999304000044, 10.58152662100008],
              [51.170923301000073, 10.578687782000088],
              [51.171701403000043, 10.57681124700008],
              [51.171254631000068, 10.574363413000071],
              [51.170659766000085, 10.569414134000056],
              [51.167762261000064, 10.567813338000065],
              [51.167655396000043, 10.567792873000087],
              [51.167641686000081, 10.56776327700004],
              [51.16759430500008, 10.567732024000065],
              [51.167531131000032, 10.567664307000086],
              [51.16742057600004, 10.567575755000064],
              [51.16729422700007, 10.567487203000042],
              [51.167167878000043, 10.567424695000057],
              [51.167083645000048, 10.567351770000073],
              [51.166994148000072, 10.567299681000065],
              [51.166904651000038, 10.567252800000063],
              [51.166888663000066, 10.567244891000087],
              [51.166866450000043, 10.567198747000077],
              [51.166032137000059, 10.566879648000054],
              [51.16152263500004, 10.565154899000049],
              [51.157835968000086, 10.563763118000054],
              [51.156285253000078, 10.563819528000067],
              [51.152346111000043, 10.563434580000092],
              [51.151154834000067, 10.563353600000084],
              [51.150904356000069, 10.563422443000093],
              [51.149455540000076, 10.563354179000044],
              [51.148903611000037, 10.563320047000047],
              [51.147834247000048, 10.563251784000045],
              [51.14707534300004, 10.563251784000045],
              [51.146281944000066, 10.563251784000045],
              [51.145936988000074, 10.563251784000045],
              [51.145109093000087, 10.56311525600006],
              [51.144315694000056, 10.562944597000069],
              [51.143763764000084, 10.562808069000084],
              [51.142832383000041, 10.562637409000047],
              [51.142107975000044, 10.562535013000058],
              [51.141590541000085, 10.562432618000059],
              [51.140797142000054, 10.562261958000079],
              [51.140003743000079, 10.562057166000045],
              [51.139451813000051, 10.561920638000061],
              [51.138865387000067, 10.561784110000076],
              [51.138382449000062, 10.561681713000041],
              [51.138046728000063, 10.561582059000045],
              [51.136929962000067, 10.561461713000085],
              [51.133282608000059, 10.561076761000038],
              [51.130413356000076, 10.561172999000064],
              [51.127981787000067, 10.560980523000069],
              [51.126276939000036, 10.560591242000044],
              [51.125355967000075, 10.560568798000077],
              [51.125239621000048, 10.560623618000079],
              [51.124618700000042, 10.560691882000071],
              [51.124308239000072, 10.560726015000057],
              [51.12406677000007, 10.560691882000071],
              [51.123618327000088, 10.560555354000087],
              [51.123066397000059, 10.560316428000078],
              [51.122066024000048, 10.559940974000085],
              [51.121721068000056, 10.55983857700005],
              [51.120858678000047, 10.559428990000072],
              [51.120030783000061, 10.559190063000074],
              [51.11903041000005, 10.558917005000069],
              [51.118306003000043, 10.558439151000073],
              [51.117547099000035, 10.558063694000055],
              [51.116477735000046, 10.557858900000042],
              [51.11554635400006, 10.557517574000087],
              [51.114890937000041, 10.557210382000051],
              [51.114201025000057, 10.556903188000092],
              [51.111510367000051, 10.554889359000072],
              [51.110716968000077, 10.554445632000068],
              [51.11009604700007, 10.554070170000045],
              [51.109440630000051, 10.553455777000067],
              [51.109061178000047, 10.552977915000042],
              [51.108819709000045, 10.552602452000087],
              [51.10874424800005, 10.552341115000047],
              [51.108443614000066, 10.552145192000069],
              [51.108368829000085, 10.551939638000079],
              [51.10833677100004, 10.551851523000039],
              [51.107957319000036, 10.551407792000077],
              [51.107577867000032, 10.550895793000052],
              [51.107336398000086, 10.550349660000052],
              [51.10702593700006, 10.549769393000076],
              [51.106542999000055, 10.549154992000069],
              [51.106163547000051, 10.548506455000052],
              [51.106129051000039, 10.547892051000076],
              [51.106129051000039, 10.547209378000048],
              [51.106094556000073, 10.54546855500007],
              [51.106025565000039, 10.544922413000052],
              [51.105956573000071, 10.544342135000079],
              [51.106025564000049, 10.543659454000078],
              [51.106094556000073, 10.543113309000091],
              [51.105884015000072, 10.542756163000092],
              [51.110714097000084, 10.539731973000073],
              [51.110851607000086, 10.539266141000041],
              [51.110372012000084, 10.53874410700007],
              [51.110027055000046, 10.538334491000057],
              [51.109821741000076, 10.538171958000078],
              [51.109509621000086, 10.537924875000044],
              [51.109026683000081, 10.537481123000077],
              [51.108371266000063, 10.537071506000075],
              [51.107750345000056, 10.536661888000083],
              [51.107198415000084, 10.536149864000038],
              [51.106784468000058, 10.535706110000092],
              [51.106611990000033, 10.535125815000072],
              [51.106680981000068, 10.534818599000062],
              [51.106704197000056, 10.534627155000067],
              [51.106609693000053, 10.534399339000061],
              [51.105785507000064, 10.532412523000062],
              [51.108412162000036, 10.524242643000036],
              [51.108543744000087, 10.524134134000064],
              [51.108578240000043, 10.523690362000082],
              [51.108578240000043, 10.52276868000007],
              [51.108612735000065, 10.52191526900009],
              [51.108612735000065, 10.521061854000038],
              [51.108612735000065, 10.520344985000065],
              [51.108474753000053, 10.519730524000067],
              [51.108198788000038, 10.519286745000045],
              [51.107922823000081, 10.518808830000069],
              [51.107853832000046, 10.518228502000056],
              [51.108405762000075, 10.516931296000052],
              [51.108577874000048, 10.516335162000075],
              [51.111255784000036, 10.513304704000063],
              [51.11167540100007, 10.505954736000092],
              [51.111423888000047, 10.496529826000085],
              [51.111776405000057, 10.493004650000046],
              [51.112011417000076, 10.491124557000091],
              [51.113708575000032, 10.486024924000048],
              [51.116541796000035, 10.481302890000052],
              [51.119563898000081, 10.475825331000067],
              [51.124285932000078, 10.46789231300005],
              [51.126363627000046, 10.462981398000068],
              [51.129385729000035, 10.458259363000082],
              [51.135241051000037, 10.450137465000068],
              [51.138829797000085, 10.445037668000055],
              [51.142040780000059, 10.44182668500008],
              [51.14562952600005, 10.438049057000057],
              [51.148651628000039, 10.435404718000086],
              [51.149218272000041, 10.433138142000075],
              [51.150083317000053, 10.43190160100005],
              [51.150652548000039, 10.431306818000053],
              [51.150817035000046, 10.43130139100009],
              [51.151449308000053, 10.431256685000051],
              [51.151946094000039, 10.431211980000057],
              [51.15235255500005, 10.431189627000037],
              [51.152853538000045, 10.431301868000048],
              [51.15324614900004, 10.431289971000069],
              [51.153745836000041, 10.43127807400009],
              [51.154221728000039, 10.431218587000046],
              [51.154589834000035, 10.43117501200004],
              [51.154887977000044, 10.431230485000071],
              [51.155090231000088, 10.431206690000067],
              [51.155280588000039, 10.431254279000086],
              [51.155625610000072, 10.431373252000071],
              [51.156065810000086, 10.43142084100009],
              [51.156363243000044, 10.431468431000042],
              [51.156684470000073, 10.431516020000061],
              [51.157041389000085, 10.431599301000062],
              [51.157588665000048, 10.431599301000062],
              [51.158052660000067, 10.431634993000046],
              [51.158302503000073, 10.43169448000009],
              [51.158584960000042, 10.431726092000076],
              [51.158923678000065, 10.431770798000059],
              [51.159172071000057, 10.431770798000059],
              [51.159444645000065, 10.431777761000092],
              [51.159730180000054, 10.431753966000088],
              [51.159991921000085, 10.431682582000064],
              [51.160527300000069, 10.431611198000041],
              [51.161159215000055, 10.431480212000054],
              [51.161305717000062, 10.431457009000042],
              [51.161723744000085, 10.431390801000077],
              [51.162335690000077, 10.431480328000077],
              [51.162835377000079, 10.431492225000056],
              [51.163703880000071, 10.431539815000065],
              [51.164298745000053, 10.431575506000058],
              [51.164988789000063, 10.431611198000041],
              [51.165547962000062, 10.431730171000083],
              [51.165828895000061, 10.431854112000053],
              [51.165952471000082, 10.431908631000056],
              [51.166416466000044, 10.432491599000059],
              [51.166761487000088, 10.432741442000065],
              [51.167273071000068, 10.432955594000077],
              [51.167915526000058, 10.432836621000092],
              [51.16868885100007, 10.432658161000063],
              [51.169010078000042, 10.43262246900008],
              [51.169295613000088, 10.432539188000078],
              [51.169819095000037, 10.432491599000059],
              [51.170413960000076, 10.432396420000089],
              [51.171698869000068, 10.432372626000074],
              [51.17178636500006, 10.432377136000071],
              [51.172852908000038, 10.432432112000072],
              [51.173029252000049, 10.432486253000093],
              [51.174209200000064, 10.432848518000071],
              [51.174701638000045, 10.432633077000048],
              [51.17497062800004, 10.432515394000063],
              [51.177029960000084, 10.432577438000067],
              [51.177457165000078, 10.43244401000004],
              [51.178718279000066, 10.43247970200008],
              [51.179051403000074, 10.432681956000067],
              [51.179824809000081, 10.433071668000082],
              [51.181145856000057, 10.433108504000074],
              [51.182769403000066, 10.432821372000092],
              [51.183221027000059, 10.432754315000068],
              [51.183650069000066, 10.432597846000078],
              [51.183966206000036, 10.432553141000085],
              [51.184350086000052, 10.432486083000072],
              [51.184655034000059, 10.432598675000065],
              [51.185107132000041, 10.432908005000058],
              [51.185618716000079, 10.433003183000039],
              [51.186368246000086, 10.432931799000073],
              [51.187150152000072, 10.432843725000055],
              [51.187692100000049, 10.432843725000055],
              [51.188888903000077, 10.432866078000075],
              [51.189634082000055, 10.432888430000048],
              [51.190040543000066, 10.432910783000068],
              [51.190314533000048, 10.432988273000092],
              [51.190356679000047, 10.433000193000055],
              [51.190537329000051, 10.433089604000088],
              [51.190818780000086, 10.433262072000048],
              [51.191688902000067, 10.433318244000077],
              [51.194515178000074, 10.43342315700005],
              [51.195098727000072, 10.433313130000045],
              [51.196453598000062, 10.433179014000075],
              [51.19761119900005, 10.433110259000046],
              [51.198705751000034, 10.432884210000054],
              [51.199300617000063, 10.432908005000058],
              [51.200038250000034, 10.432967491000056],
              [51.200597423000033, 10.432943697000042],
              [51.201727667000057, 10.43299128600006],
              [51.202798424000036, 10.433122156000081],
              [51.203976257000079, 10.43313405300006],
              [51.204701993000072, 10.433169745000043],
              [51.205058912000084, 10.433157848000064],
              [51.206248643000038, 10.433336308000037],
              [51.207723909000038, 10.433169745000043],
              [51.211876068000038, 10.433181643000069],
              [51.218883581000057, 10.432693853000046],
              [51.226212321000048, 10.432396420000089],
              [51.228853522000065, 10.432372626000074],
              [51.23017412300004, 10.432384523000053],
              [51.231447135000053, 10.432396420000089],
              [51.233588650000058, 10.432432112000072],
              [51.233843124000032, 10.43244059500006],
              [51.234302488000083, 10.432455907000076],
              [51.235432732000049, 10.432693853000046],
              [51.235784692000038, 10.43286369100008],
              [51.236420208000084, 10.433728919000089],
              [51.236432106000052, 10.435263671000087],
              [51.236551079000037, 10.437083959000063],
              [51.236479695000071, 10.438654403000044],
              [51.23649159200005, 10.439368241000068],
              [51.236325030000046, 10.44037951200005],
              [51.236313133000067, 10.441581140000039],
              [51.236075186000051, 10.442616206000082],
              [51.235884830000032, 10.443472811000049],
              [51.235682575000055, 10.443829731000051],
              [51.235492219000037, 10.444198547000042],
              [51.235123402000056, 10.444507877000092],
              [51.234696227000086, 10.445050868000067],
              [51.23436313600007, 10.445223571000042],
              [51.23381469800006, 10.445745197000065],
              [51.233326909000084, 10.44586417000005],
              [51.232862914000066, 10.445995040000071],
              [51.232660660000079, 10.446232986000041],
              [51.232398919000047, 10.44641144600007],
              [51.232137178000073, 10.446482830000093],
              [51.231804054000065, 10.446542316000091],
              [51.231516007000039, 10.446701837000091],
              [51.230786379000051, 10.447047242000053],
              [51.230576221000035, 10.447287525000093],
              [51.230352583000069, 10.44737512800009],
              [51.229650642000081, 10.447720149000077],
              [51.228843281000081, 10.448313202000065],
              [51.227795241000081, 10.449043209000081],
              [51.227530233000039, 10.449227799000084],
              [51.225779503000069, 10.450768166000046],
              [51.224970320000068, 10.45168925300004],
              [51.224970322000047, 10.451689287000079],
              [51.224886140000081, 10.451766661000079],
              [51.224826524000036, 10.451847797000084],
              [51.224759457000062, 10.451973190000047],
              [51.224662582000065, 10.452120711000077],
              [51.224610418000054, 10.452231352000069],
              [51.224520995000034, 10.452378873000043],
              [51.224371956000084, 10.452519018000089],
              [51.224260177000076, 10.452644410000062],
              [51.224178206000033, 10.452747675000069],
              [51.224111138000069, 10.452873067000041],
              [51.224088782000081, 10.45299846000006],
              [51.224105516000066, 10.453020200000083],
              [51.224089050000032, 10.453058230000067],
              [51.224045841000077, 10.453195088000086],
              [51.224015773000076, 10.453291817000093],
              [51.224011275000066, 10.453306286000043],
              [51.223924858000032, 10.453417483000067],
              [51.223803875000044, 10.453571448000048],
              [51.223544625000045, 10.454007682000054],
              [51.223276733000034, 10.45430705800004],
              [51.223034767000058, 10.45456366600007],
              [51.22281008300007, 10.454794613000047],
              [51.222550833000071, 10.455085435000058],
              [51.22238664200006, 10.455496007000079],
              [51.222167237000065, 10.45619103100006],
              [51.221822215000032, 10.456762101000038],
              [51.221370117000049, 10.457071431000088],
              [51.220846636000033, 10.457416453000064],
              [51.220312642000067, 10.457608730000061],
              [51.219930544000078, 10.457844756000043],
              [51.21840768800007, 10.458784643000058],
              [51.218241126000066, 10.458951205000062],
              [51.218074564000062, 10.459117768000056],
              [51.217931796000073, 10.459224843000072],
              [51.217789029000073, 10.45937950800004],
              [51.217551083000046, 10.459629352000093],
              [51.217417684000054, 10.459841188000041],
              [51.217296701000066, 10.459935276000067],
              [51.217115226000033, 10.460132005000048],
              [51.216994242000055, 10.460268860000042],
              [51.216821409000033, 10.460388608000073],
              [51.216657217000034, 10.460499802000072],
              [51.216570801000046, 10.460593890000041],
              [51.21649302600008, 10.460756405000041],
              [51.216278071000033, 10.460902363000059],
              [51.216191572000071, 10.461344337000071],
              [51.214534798000045, 10.462981567000043],
              [51.213184772000034, 10.463959971000065],
              [51.212259978000077, 10.464871791000064],
              [51.209731146000081, 10.467471023000087],
              [51.207747703000052, 10.468992531000083],
              [51.205320653000058, 10.470336926000073],
              [51.204868555000076, 10.470872305000057],
              [51.202738938000039, 10.472097727000062],
              [51.201130404000082, 10.472840070000075],
              [51.20111950200004, 10.472833596000044],
              [51.201015802000086, 10.472842149000087],
              [51.200998519000052, 10.472867808000046],
              [51.200963952000052, 10.472961892000058],
              [51.200877536000064, 10.473021764000066],
              [51.200817044000075, 10.473098741000058],
              [51.200808402000064, 10.473244144000091],
              [51.200765194000041, 10.473372440000048],
              [51.200747911000065, 10.473492183000076],
              [51.200765194000041, 10.47362047900009],
              [51.200747911000065, 10.473731668000084],
              [51.200765194000041, 10.473834305000082],
              [51.200799761000042, 10.47393694200008],
              [51.200838174000069, 10.474038327000073],
              [51.200680704000035, 10.474477188000037],
              [51.200469255000087, 10.475420099000075],
              [51.200462736000077, 10.475425169000061],
              [51.200333111000077, 10.475604782000062],
              [51.200315827000054, 10.475621888000092],
              [51.200229411000066, 10.47570741800007],
              [51.200108427000032, 10.475784395000062],
              [51.200082502000043, 10.475827160000051],
              [51.200004727000078, 10.475998220000065],
              [51.199633136000045, 10.476280469000073],
              [51.199581286000068, 10.476348893000079],
              [51.199407692000079, 10.476666293000051],
              [51.199252903000058, 10.477041684000085],
              [51.199235619000035, 10.477221296000039],
              [51.199218336000058, 10.477494990000082],
              [51.199209694000047, 10.477708814000039],
              [51.19919241100007, 10.477879873000063],
              [51.199088711000059, 10.478093697000077],
              [51.199002294000081, 10.478422984000076],
              [51.198950444000047, 10.478491408000082],
              [51.19886402800006, 10.478628255000046],
              [51.19881282700004, 10.478950575000056],
              [51.198634368000057, 10.479105240000081],
              [51.198479703000032, 10.479271802000085],
              [51.198051400000054, 10.479700105000063],
              [51.197682583000073, 10.480104614000084],
              [51.197412228000076, 10.480343110000092],
              [51.197325811000042, 10.48051416800007],
              [51.197299886000053, 10.480727989000059],
              [51.19719479400004, 10.481127782000044],
              [51.197349459000066, 10.481627469000045],
              [51.19737325300008, 10.481865415000073],
              [51.19737325300008, 10.482258026000068],
              [51.197438153000064, 10.482643824000093],
              [51.197455436000041, 10.48305435900005],
              [51.197429511000053, 10.483400747000076],
              [51.197438153000064, 10.483520486000089],
              [51.197394944000052, 10.483785622000084],
              [51.197438153000064, 10.484067864000053],
              [51.197515928000087, 10.484256024000047],
              [51.197550494000041, 10.484452738000073],
              [51.197567778000064, 10.484563924000042],
              [51.197576419000086, 10.484760637000079],
              [51.197602344000074, 10.484948798000062],
              [51.197619628000041, 10.485154063000039],
              [51.197662836000063, 10.485333671000092],
              [51.197706044000086, 10.485461962000045],
              [51.197706044000086, 10.485641569000052],
              [51.19771468600004, 10.485889598000085],
              [51.197731969000074, 10.486082034000049],
              [51.197775178000086, 10.486159008000072],
              [51.197818386000051, 10.486218877000056],
              [51.197852953000051, 10.486295852000069],
              [51.19787887800004, 10.486432695000076],
              [51.197730172000036, 10.486945564000052],
              [51.197742070000061, 10.487076434000073],
              [51.197837248000042, 10.487242997000067],
              [51.197913444000051, 10.487535992000062],
              [51.197913444000051, 10.487664282000082],
              [51.197904803000085, 10.487843888000043],
              [51.19775396700004, 10.488028219000057],
              [51.197634994000055, 10.488135295000063],
              [51.197516021000069, 10.48820667800004],
              [51.197420842000042, 10.48833754900005],
              [51.197504124000034, 10.488551700000073],
              [51.197567778000064, 10.488818890000061],
              [51.197567778000064, 10.488964284000076],
              [51.197515928000087, 10.489169547000074],
              [51.197515928000087, 10.489468889000079],
              [51.197559136000052, 10.489648494000051],
              [51.197550494000041, 10.489879415000075],
              [51.197559136000052, 10.490170203000048],
              [51.197550494000041, 10.490358360000073],
              [51.197507286000075, 10.490563622000082],
              [51.197490003000041, 10.490674806000072],
              [51.197412228000076, 10.490948488000072],
              [51.197325811000042, 10.491170855000064],
              [51.197187544000087, 10.491341907000049],
              [51.197083844000076, 10.491530063000084],
              [51.197014711000065, 10.491692562000082],
              [51.196876445000044, 10.491957691000039],
              [51.196781386000055, 10.492133018000061],
              [51.196315401000049, 10.492265027000087],
              [51.196029865000071, 10.492740919000084],
              [51.195982276000052, 10.493288195000048],
              [51.196148838000056, 10.493930650000038],
              [51.196504750000088, 10.494333790000042],
              [51.196540442000071, 10.494536045000075],
              [51.196588031000033, 10.494762093000077],
              [51.196647518000077, 10.494976245000089],
              [51.196707004000075, 10.495178499000076],
              [51.196754593000037, 10.495321267000065],
              [51.196742696000058, 10.495452137000086],
              [51.196720895000055, 10.495853351000051],
              [51.196694970000067, 10.496024400000067],
              [51.196720895000055, 10.496135582000079],
              [51.196815953000055, 10.496246763000045],
              [51.196859161000077, 10.496400707000078],
              [51.196850520000055, 10.496503336000046],
              [51.196850520000055, 10.496802670000079],
              [51.197325811000042, 10.497341472000073],
              [51.197539815000084, 10.497557960000051],
              [51.198206065000079, 10.497843495000041],
              [51.199032241000054, 10.497986282000056],
              [51.20032378500008, 10.498200415000042],
              [51.200621217000048, 10.498164723000059],
              [51.200763985000037, 10.498117133000051],
              [51.200903461000053, 10.498145396000041],
              [51.201162711000052, 10.498196710000059],
              [51.201396036000062, 10.498205263000045],
              [51.201551586000051, 10.498205263000045],
              [51.201733061000084, 10.498230920000083],
              [51.20181083600005, 10.498299339000084],
              [51.201923177000083, 10.498320720000038],
              [51.20200959400006, 10.498372034000056],
              [51.202216994000082, 10.498483215000078],
              [51.202355261000037, 10.498560186000077],
              [51.202528094000058, 10.498585843000058],
              [51.202640436000081, 10.498739785000055],
              [51.202770061000081, 10.498850966000077],
              [51.20286511900008, 10.498936490000062],
              [51.202905500000043, 10.499187891000076],
              [51.202857911000081, 10.499306864000062],
              [51.202798424000036, 10.499556707000067],
              [51.20283055200008, 10.500185130000091],
              [51.202726852000069, 10.50028775800007],
              [51.202675002000035, 10.500390386000049],
              [51.202657719000058, 10.50053577500006],
              [51.202571302000081, 10.500689717000057],
              [51.202398469000059, 10.500818001000084],
              [51.202346619000082, 10.500852210000062],
              [51.202234277000059, 10.500971943000081],
              [51.202199711000048, 10.501100227000052],
              [51.202182427000082, 10.501245616000062],
              [51.202182427000082, 10.501408109000067],
              [51.20217378600006, 10.501596260000042],
              [51.202156502000037, 10.501810066000075],
              [51.202113294000071, 10.501929798000049],
              [51.202035519000049, 10.502032425000039],
              [51.201983669000072, 10.502126500000088],
              [51.202018236000072, 10.502271889000042],
              [51.202052802000082, 10.50229754500009],
              [51.202156502000037, 10.502383068000086],
              [51.202072689000033, 10.502626212000052],
              [51.201977510000063, 10.502816569000061],
              [51.201846640000042, 10.503185385000052],
              [51.201828119000083, 10.503358024000079],
              [51.201758986000073, 10.503417889000048],
              [51.20168121100005, 10.50351196400004],
              [51.201551586000051, 10.503588934000049],
              [51.201491094000062, 10.503640247000078],
              [51.201352827000051, 10.503751426000065],
              [51.201240486000074, 10.503896814000086],
              [51.201128144000052, 10.504007992000084],
              [51.201041727000074, 10.504110619000073],
              [51.200981236000075, 10.504247454000051],
              [51.200989878000087, 10.504375737000089],
              [51.200981236000075, 10.504538229000048],
              [51.201024444000041, 10.50470927300006],
              [51.201179994000086, 10.504726377000054],
              [51.201300977000074, 10.504726377000054],
              [51.20151701900005, 10.504743481000048],
              [51.201568869000084, 10.504803347000063],
              [51.201663927000084, 10.504871764000086],
              [51.201784911000061, 10.504974390000086],
              [51.201854044000072, 10.505119778000051],
              [51.20184540200006, 10.505248060000042],
              [51.201871327000049, 10.505530282000052],
              [51.201879969000061, 10.505667116000041],
              [51.201871327000049, 10.505932233000067],
              [51.201836761000038, 10.506154589000062],
              [51.201733061000084, 10.506342737000068],
              [51.20177626900005, 10.506505227000048],
              [51.201930929000071, 10.507136659000082],
              [51.202121285000032, 10.507374605000052],
              [51.202501999000049, 10.507755318000079],
              [51.202882713000065, 10.507731524000064],
              [51.203073070000073, 10.507779113000083],
              [51.203287221000039, 10.507826702000045],
              [51.203525167000066, 10.507969470000091],
              [51.20369173000006, 10.508088443000076],
              [51.203858292000064, 10.508136032000039],
              [51.204014461000043, 10.508147234000091],
              [51.204100877000087, 10.508258411000043],
              [51.204282352000064, 10.508438005000073],
              [51.204489752000086, 10.508617599000047],
              [51.204731719000051, 10.508891265000045],
              [51.204818135000039, 10.509045202000038],
              [51.204861344000051, 10.509318868000037],
              [51.204844060000084, 10.509609638000086],
              [51.204930477000062, 10.509900408000078],
              [51.204930477000062, 10.51002868900008],
              [51.204930477000062, 10.510156969000093],
              [51.20492183500005, 10.510233938000056],
              [51.204878627000085, 10.510379322000063],
              [51.20475764400004, 10.510618779000083],
              [51.20472307700004, 10.510883892000038],
              [51.204610736000063, 10.511183212000049],
              [51.204610736000063, 10.511482532000059],
              [51.204499739000084, 10.511620575000052],
              [51.204452150000066, 10.511870418000058],
              [51.204416458000082, 10.512167851000072],
              [51.204416458000082, 10.512346310000055],
              [51.204416458000082, 10.512477181000065],
              [51.204321279000055, 10.512750819000075],
              [51.204047641000045, 10.513631219000047],
              [51.204142820000072, 10.513892960000078],
              [51.204130922000047, 10.514083317000086],
              [51.20405953900007, 10.514261776000069],
              [51.20392866800006, 10.514666285000089],
              [51.203690722000033, 10.51517786900007],
              [51.202790899000036, 10.515688547000082],
              [51.202554019000047, 10.515715743000044],
              [51.202458961000048, 10.515749950000043],
              [51.202346619000082, 10.515844021000078],
              [51.201870434000057, 10.515939296000056],
              [51.201727667000057, 10.516046372000062],
              [51.201477823000062, 10.516117756000085],
              [51.201370748000045, 10.516260524000074],
              [51.201216083000077, 10.516415189000043],
              [51.201120904000049, 10.516593648000082],
              [51.200930547000041, 10.516950567000038],
              [51.201067653000052, 10.517212318000077],
              [51.201007161000064, 10.517417563000038],
              [51.200938028000053, 10.517776739000055],
              [51.200955311000087, 10.517999087000078],
              [51.201015803000075, 10.51820433000006],
              [51.201076294000075, 10.518426677000093],
              [51.201145428000075, 10.518666128000064],
              [51.201240486000074, 10.518871371000046],
              [51.201344186000085, 10.51911937300008],
              [51.201421961000051, 10.51933316700007],
              [51.201551586000051, 10.519495651000057],
              [51.201612078000039, 10.519564065000054],
              [51.201741703000039, 10.519700894000039],
              [51.201828693000039, 10.519820089000063],
              [51.201819478000061, 10.519965998000089],
              [51.201836761000038, 10.520231103000071],
              [51.201888611000072, 10.520504759000062],
              [51.201923178000072, 10.52063303500006],
              [51.201949103000061, 10.520761311000058],
              [51.201940461000049, 10.520881036000048],
              [51.201940461000049, 10.520983656000055],
              [51.201940461000049, 10.521223105000047],
              [51.201957744000083, 10.52136848400005],
              [51.202018236000072, 10.521513863000052],
              [51.202087369000083, 10.521633587000053],
              [51.202096011000037, 10.52173620800005],
              [51.202070086000049, 10.521813173000055],
              [51.20204416100006, 10.521941449000053],
              [51.202018236000072, 10.522061173000054],
              [51.201996240000085, 10.522097451000093],
              [51.202002464000032, 10.522131668000043],
              [51.201983669000072, 10.522163793000061],
              [51.201862686000084, 10.522274965000065],
              [51.201698494000084, 10.522386137000069],
              [51.201663928000073, 10.522454551000067],
              [51.201612078000039, 10.522574275000068],
              [51.201620719000061, 10.522702550000076],
              [51.201620719000061, 10.522805170000083],
              [51.201663928000073, 10.522941997000089],
              [51.201793553000073, 10.523070272000041],
              [51.201836761000038, 10.52319854700005],
              [51.20184540300005, 10.523369581000054],
              [51.201836761000038, 10.523574821000068],
              [51.201784911000061, 10.523685992000082],
              [51.201672569000038, 10.523874129000092],
              [51.201560228000062, 10.524062265000055],
              [51.201442132000068, 10.524100848000046],
              [51.201287467000043, 10.524148437000065],
              [51.201073315000087, 10.524279307000086],
              [51.200906753000083, 10.524410178000039],
              [51.200752088000058, 10.524576740000043],
              [51.200633115000073, 10.524755199000083],
              [51.200502244000063, 10.524957454000059],
              [51.200133428000072, 10.525064529000076],
              [51.199788406000039, 10.525171605000082],
              [51.199598049000087, 10.525302475000046],
              [51.200670136000042, 10.52570417700008],
              [51.200860253000087, 10.525832451000042],
              [51.201128144000052, 10.526106102000085],
              [51.201249128000086, 10.526251479000052],
              [51.201326903000052, 10.52635409800007],
              [51.201370111000074, 10.526482371000043],
              [51.201395698000056, 10.526578589000053],
              [51.201282454000079, 10.527587162000088],
              [51.201122241000064, 10.527698570000041],
              [51.200935176000087, 10.52785723900007],
              [51.200828281000042, 10.52793657400008],
              [51.200507599000048, 10.528201022000076],
              [51.200160192000055, 10.528544805000081],
              [51.199705892000054, 10.52875636300007],
              [51.199586152000052, 10.528895462000037],
              [51.199324411000077, 10.529169100000047],
              [51.199015081000084, 10.529537916000038],
              [51.198423160000061, 10.529893487000038],
              [51.198182648000056, 10.530290157000081],
              [51.198008633000086, 10.530496796000079],
              [51.196552041000075, 10.531217489000085],
              [51.195552966000037, 10.53164373900006],
              [51.194862922000084, 10.531964966000089],
              [51.194089597000072, 10.532452756000055],
              [51.193530424000073, 10.533047621000037],
              [51.191186655000081, 10.53486790900007],
              [51.191888596000069, 10.535474671000088],
              [51.191888703000075, 10.535578158000078],
              [51.192388282000081, 10.535272417000044],
              [51.193494732000033, 10.534546681000052],
              [51.194874819000063, 10.533559205000074],
              [51.19627870100004, 10.533690076000084],
              [51.19682597700006, 10.533416438000074],
              [51.197266177000074, 10.533095210000056],
              [51.197432740000067, 10.53284536700005],
              [51.197662208000054, 10.532721072000072],
              [51.198003810000046, 10.532536037000057],
              [51.198875354000052, 10.532106799000076],
              [51.199189844000045, 10.531792309000082],
              [51.200158147000081, 10.531351106000045],
              [51.200574407000033, 10.530964495000092],
              [51.200881729000059, 10.530924828000082],
              [51.201148964000083, 10.530885161000072],
              [51.201376115000073, 10.530805827000052],
              [51.201509732000034, 10.530752938000091],
              [51.201832348000039, 10.530493708000051],
              [51.20233203500004, 10.53026765900006],
              [51.202486700000065, 10.530208172000073],
              [51.202665159000048, 10.530124891000071],
              [51.202979529000061, 10.529986043000065],
              [51.20305969900005, 10.529893487000038],
              [51.203246764000085, 10.529774486000065],
              [51.203407106000043, 10.529695152000045],
              [51.203674341000067, 10.529642262000038],
              [51.204115280000053, 10.529483594000055],
              [51.204275622000068, 10.529351370000086],
              [51.204435963000037, 10.529245591000063],
              [51.204716560000065, 10.529113368000083],
              [51.204983796000079, 10.529020811000066],
              [51.205291117000058, 10.528941477000046],
              [51.20549154400004, 10.52887536500009],
              [51.205798865000077, 10.528743141000064],
              [51.206198659000052, 10.528625831000056],
              [51.207067162000044, 10.528197528000078],
              [51.207293211000035, 10.528138041000091],
              [51.207455726000035, 10.528029131000039],
              [51.207602541000085, 10.528019068000049],
              [51.207804795000072, 10.528054760000089],
              [51.208233098000051, 10.528102349000051],
              [51.208265739000069, 10.528260038000042],
              [51.208272205000071, 10.528257869000072],
              [51.209089704000064, 10.527947684000083],
              [51.209327650000034, 10.527852506000045],
              [51.209577494000087, 10.527769225000043],
              [51.209720261000086, 10.527662149000037],
              [51.20999389900004, 10.527626457000054],
              [51.210208051000052, 10.527507484000068],
              [51.210529278000081, 10.527364716000079],
              [51.21105275900004, 10.527329024000039],
              [51.21146916500004, 10.52731712700006],
              [51.212242490000051, 10.527198154000075],
              [51.213147078000077, 10.527499951000038],
              [51.214351128000033, 10.527391633000093],
              [51.216734066000072, 10.527199136000092],
              [51.217846121000036, 10.527162462000092],
              [51.218680942000049, 10.52692391100004],
              [51.21890662800007, 10.526689943000065],
              [51.219164663000072, 10.526364958000045],
              [51.219364054000039, 10.52620246500004],
              [51.219610360000047, 10.525958726000056],
              [51.219704191000062, 10.52584266000008],
              [51.220020871000088, 10.525494461000051],
              [51.220278906000033, 10.525192688000061],
              [51.220478297000057, 10.524983768000084],
              [51.221123386000045, 10.524461467000037],
              [51.221334505000073, 10.52422933400004],
              [51.221686372000079, 10.524043627000083],
              [51.222164842000041, 10.523795525000082],
              [51.222593145000076, 10.523414811000066],
              [51.222902475000069, 10.523141173000056],
              [51.223306984000033, 10.522736665000082],
              [51.223562992000041, 10.522267796000051],
              [51.223809298000049, 10.521942807000073],
              [51.223985232000075, 10.521664244000078],
              [51.224161165000055, 10.521362467000074],
              [51.224325369000042, 10.521176758000081],
              [51.224513031000072, 10.520956229000092],
              [51.224700693000045, 10.520596417000093],
              [51.224841440000034, 10.520410708000043],
              [51.22529886600006, 10.51997545100005],
              [51.22547479900004, 10.519766527000058],
              [51.225592088000042, 10.519627245000038],
              [51.225721105000048, 10.519487963000074],
              [51.22583839400005, 10.519337073000088],
              [51.226166803000069, 10.51907011500009],
              [51.226201356000047, 10.519054023000081],
              [51.226816689000032, 10.51850122400009],
              [51.227340170000048, 10.517989640000053],
              [51.227542424000035, 10.517716002000043],
              [51.227720884000064, 10.517382877000045],
              [51.228113495000059, 10.517014061000054],
              [51.229077177000079, 10.515895714000067],
              [51.22917235500006, 10.515800536000086],
              [51.229161593000072, 10.515573084000039],
              [51.230401303000065, 10.514768362000041],
              [51.231538675000081, 10.514155021000079],
              [51.234181120000073, 10.512171858000045],
              [51.235846201000072, 10.510740178000049],
              [51.236477300000047, 10.510363467000047],
              [51.236834219000059, 10.509958959000073],
              [51.237179241000035, 10.509554451000042],
              [51.23733390600006, 10.509435478000057],
              [51.237631339000075, 10.509221326000045],
              [51.237786004000043, 10.509066661000077],
              [51.237940669000068, 10.50882871500005],
              [51.238202410000042, 10.508709742000065],
              [51.238357075000067, 10.508566974000075],
              [51.238555360000078, 10.508405513000071],
              [51.238801667000075, 10.50825461800008],
              [51.238892453000062, 10.508091082000078],
              [51.239094707000049, 10.507948314000089],
              [51.239314874000058, 10.507747383000037],
              [51.239455621000047, 10.507700954000086],
              [51.239678469000069, 10.507596488000047],
              [51.239948234000053, 10.507550059000039],
              [51.240264913000033, 10.507538451000073],
              [51.240393931000085, 10.507515237000064],
              [51.240522949000081, 10.50745720000009],
              [51.240734068000052, 10.507341127000075],
              [51.240792713000076, 10.507294697000077],
              [51.240945188000069, 10.507132194000064],
              [51.241085935000058, 10.50699290600005],
              [51.241226681000057, 10.506900047000045],
              [51.241332241000066, 10.506818796000061],
              [51.241461259000062, 10.506725937000056],
              [51.241637192000042, 10.506563434000043],
              [51.241789667000035, 10.506458968000061],
              [51.241906956000037, 10.506412538000063],
              [51.242059431000087, 10.506400931000087],
              [51.242171019000068, 10.506387127000039],
              [51.242314175000047, 10.506264343000055],
              [51.243027508000068, 10.50549957000004],
              [51.244900592000079, 10.504569480000043],
              [51.246539863000066, 10.504247756000041],
              [51.246657151000079, 10.504294185000049],
              [51.246833085000048, 10.504340615000046],
              [51.247079391000057, 10.504340615000046],
              [51.247337426000058, 10.504340615000046],
              [51.247595462000049, 10.504352223000069],
              [51.247736208000049, 10.504375437000078],
              [51.247853497000051, 10.504375437000078],
              [51.248123261000046, 10.50427097000005],
              [51.24832265200007, 10.504154896000045],
              [51.248592416000065, 10.504038821000051],
              [51.24886218000006, 10.503957569000079],
              [51.249167131000036, 10.503864709000084],
              [51.249343064000072, 10.503748635000079],
              [51.249460353000075, 10.503644167000061],
              [51.249706659000083, 10.503423625000039],
              [51.249859135000065, 10.50321469000005],
              [51.250046797000039, 10.503098615000056],
              [51.250175815000034, 10.503040578000082],
              [51.250422121000042, 10.502866465000068],
              [51.250480765000077, 10.502773605000073],
              [51.250517628000068, 10.50260944200005],
              [51.250621512000066, 10.502506632000063],
              [51.250926463000042, 10.502390557000069],
              [51.251137582000069, 10.50230930400005],
              [51.251407347000054, 10.502204837000079],
              [51.25157155100004, 10.502100369000061],
              [51.251853044000086, 10.501926256000047],
              [51.251958604000038, 10.50175214300009],
              [51.252017248000072, 10.501647675000072],
              [51.252146266000068, 10.501473561000068],
              [51.252204910000046, 10.501427131000071],
              [51.252333928000041, 10.501334271000076],
              [51.252556777000052, 10.50126462500009],
              [51.252685794000058, 10.501171765000038],
              [51.252836095000077, 10.50079803400007],
              [51.253121630000066, 10.500619575000087],
              [51.253335782000079, 10.500536293000039],
              [51.253538036000066, 10.50052439600006],
              [51.253729664000048, 10.500556563000089],
              [51.253918750000082, 10.500405423000075],
              [51.255108480000047, 10.499846250000076],
              [51.255334529000038, 10.499691585000051],
              [51.25544208000008, 10.49959313100004],
              [51.255688387000077, 10.499569915000052],
              [51.255969880000066, 10.49948866200009],
              [51.256524259000059, 10.499298974000055],
              [51.256904973000076, 10.498930157000075],
              [51.257345173000033, 10.498549444000048],
              [51.257647109000061, 10.498269858000072],
              [51.258139722000067, 10.498060920000057],
              [51.258210095000038, 10.498026096000046],
              [51.258362571000077, 10.497851981000053],
              [51.258632335000073, 10.497585004000086],
              [51.258784810000066, 10.497457319000091],
              [51.259089761000041, 10.497120695000092],
              [51.259406441000067, 10.496853717000079],
              [51.260045862000084, 10.496538799000064],
              [51.260462267000037, 10.496277058000089],
              [51.260696617000065, 10.496041175000073],
              [51.260954653000056, 10.495797412000059],
              [51.261411859000077, 10.495439632000057],
              [51.262401528000055, 10.494777998000075],
              [51.263957245000086, 10.494926827000086],
              [51.264198021000084, 10.494777998000075],
              [51.264733400000068, 10.494801792000089],
              [51.264977658000078, 10.494984866000038],
              [51.265352982000081, 10.49511255200008],
              [51.265822137000043, 10.49517059100009],
              [51.266138817000069, 10.495240238000065],
              [51.266584514000044, 10.495344708000061],
              [51.266854278000039, 10.495449178000058],
              [51.267253060000087, 10.495634903000052],
              [51.267663571000071, 10.495750980000082],
              [51.268214828000055, 10.495797412000059],
              [51.268613610000045, 10.495809019000092],
              [51.268801272000076, 10.495809019000092],
              [51.269117951000055, 10.495809019000092],
              [51.26957537800007, 10.495797411000069],
              [51.269903786000043, 10.495762588000048],
              [51.270243924000056, 10.495600079000042],
              [51.270693950000066, 10.495527528000082],
              [51.271086561000061, 10.495337171000074],
              [51.271287794000045, 10.495263453000064],
              [51.271681426000043, 10.494944560000079],
              [51.271871783000051, 10.494777998000075],
              [51.27247854500007, 10.494456771000046],
              [51.274119102000043, 10.493702777000067],
              [51.274934001000076, 10.493189556000061],
              [51.275285532000055, 10.493030099000066],
              [51.275557339000045, 10.492927245000089],
              [51.275949060000073, 10.492824391000056],
              [51.276316798000039, 10.492666155000052],
              [51.276508661000037, 10.492531653000071],
              [51.276700525000081, 10.492349680000075],
              [51.276932360000046, 10.492128148000063],
              [51.277108235000071, 10.49197782300007],
              [51.277372047000085, 10.491756291000058],
              [51.277539927000078, 10.491605965000076],
              [51.277739785000051, 10.491471463000039],
              [51.277971620000073, 10.49134487300006],
              [51.278163483000071, 10.491249930000038],
              [51.278339358000039, 10.491178723000075],
              [51.278579187000048, 10.491115428000057],
              [51.278755062000073, 10.491052133000039],
              [51.278898960000049, 10.49100466200008],
              [51.279218732000061, 10.490897851000057],
              [51.279514522000056, 10.490644670000052],
              [51.279738362000046, 10.49042313700005],
              [51.279902464000088, 10.490316508000092],
              [51.280080923000071, 10.490221330000054],
              [51.280269702000055, 10.489992144000041],
              [51.280525519000037, 10.489905113000077],
              [51.280865560000052, 10.489758536000068],
              [51.281249287000037, 10.48956073800008],
              [51.28152908800007, 10.489370852000093],
              [51.281824877000076, 10.489196789000061],
              [51.28222459300008, 10.488919871000064],
              [51.282560354000054, 10.488753720000091],
              [51.282912103000058, 10.488627129000065],
              [51.283343796000054, 10.48844515400009],
              [51.283799472000055, 10.488176147000047],
              [51.284598902000084, 10.487748901000089],
              [51.285142516000064, 10.487274182000078],
              [51.285438305000071, 10.487100118000058],
              [51.285718106000047, 10.486910230000092],
              [51.285941946000037, 10.486657046000062],
              [51.286157793000086, 10.486498806000043],
              [51.28622174700007, 10.486380126000086],
              [51.286268831000086, 10.486315405000084],
              [51.286643926000067, 10.48606482200006],
              [51.286701406000077, 10.486000349000051],
              [51.286797338000042, 10.485897493000039],
              [51.28691725200008, 10.485794637000083],
              [51.287077138000086, 10.485620572000073],
              [51.287229030000049, 10.485462331000065],
              [51.287348945000076, 10.485383211000055],
              [51.287468860000047, 10.485343651000051],
              [51.287612757000034, 10.485256618000051],
              [51.287764649000053, 10.485137938000037],
              [51.287908547000086, 10.48501925700009],
              [51.288132387000076, 10.484853104000081],
              [51.288332245000049, 10.484718599000075],
              [51.288492131000055, 10.484615742000074],
              [51.288811904000056, 10.484402117000059],
              [51.288987778000035, 10.484251787000062],
              [51.289211619000071, 10.484141018000059],
              [51.289347522000071, 10.483990689000052],
              [51.289483426000061, 10.483872008000048],
              [51.289627323000047, 10.483737503000043],
              [51.289827181000078, 10.483539701000041],
              [51.289990074000059, 10.483456491000084],
              [51.291392878000067, 10.48213992500007],
              [51.291450026000064, 10.482012664000081],
              [51.291569940000045, 10.481878158000086],
              [51.291737821000083, 10.48170409100004],
              [51.291811666000058, 10.481548195000073],
              [51.291906844000039, 10.48147681100005],
              [51.292002023000066, 10.481357838000065],
              [51.292156688000034, 10.48120317300004],
              [51.292358942000078, 10.480905740000082],
              [51.292620683000052, 10.480762972000036],
              [51.292858629000079, 10.480584513000053],
              [51.293242446000079, 10.480279555000038],
              [51.293418321000047, 10.480168785000046],
              [51.293810413000074, 10.480013442000086],
              [51.294112131000077, 10.480050451000068],
              [51.294200068000066, 10.479995066000072],
              [51.294343966000042, 10.479892208000081],
              [51.294495858000062, 10.479828910000037],
              [51.294639755000048, 10.47975770000005],
              [51.294767664000062, 10.479702315000054],
              [51.294935545000044, 10.479646930000058],
              [51.295143397000061, 10.479575720000071],
              [51.295303283000067, 10.479512423000074],
              [51.295447180000053, 10.47947286200008],
              [51.295527123000056, 10.479449125000087],
              [51.295563904000062, 10.479446615000086],
              [51.296975854000038, 10.478926634000061],
              [51.299310160000061, 10.477915862000089],
              [51.30010169600007, 10.47753249200008],
              [51.301595835000057, 10.476808822000066],
              [51.301960067000039, 10.476491840000051],
              [51.302638010000067, 10.476324769000087],
              [51.302932962000057, 10.47638037300004],
              [51.30517234000007, 10.476253894000081],
              [51.307194881000044, 10.476551327000038],
              [51.307582542000034, 10.476671933000091],
              [51.307662485000037, 10.476640284000041],
              [51.307726440000067, 10.476624460000039],
              [51.307926297000051, 10.476600723000047],
              [51.308030224000049, 10.476592811000046],
              [51.308062201000041, 10.476576986000055],
              [51.30816612700005, 10.47652951300006],
              [51.308230081000033, 10.476497864000066],
              [51.308381973000053, 10.476482039000075],
              [51.308453922000069, 10.476450390000082],
              [51.308493893000048, 10.47640291600004],
              [51.308533865000072, 10.476323794000052],
              [51.308557848000078, 10.476276320000068],
              [51.308605814000032, 10.476236759000074],
              [51.308701746000054, 10.47619719700009],
              [51.308869626000046, 10.476244671000075],
              [51.308933580000087, 10.476252583000075],
              [51.308989541000074, 10.476236759000074],
              [51.309141433000036, 10.476236759000074],
              [51.30928533000008, 10.476244671000075],
              [51.309437222000042, 10.47616554800004],
              [51.309477194000067, 10.476118075000045],
              [51.309525159000032, 10.475991478000083],
              [51.30955713700007, 10.475912355000048],
              [51.30962908500004, 10.475809496000068],
              [51.309677051000051, 10.475714548000042],
              [51.309685046000084, 10.47558795100008],
              [51.309741006000081, 10.475485092000042],
              [51.30982894300007, 10.475413881000065],
              [51.309964846000071, 10.475469267000051],
              [51.309988829000076, 10.475532565000037],
              [51.310124733000066, 10.475532565000037],
              [51.310220664000042, 10.47558795100008],
              [51.310292613000058, 10.475643338000054],
              [51.310412528000086, 10.475714548000042],
              [51.31047648200007, 10.47569872400004],
              [51.310692689000064, 10.475540056000057],
              [51.31107340300008, 10.475432980000051],
              [51.311323246000086, 10.475456774000065],
              [51.311499754000067, 10.475659162000056],
              [51.311595686000032, 10.475714548000042],
              [51.311747577000062, 10.475754110000082],
              [51.311907464000058, 10.475793671000076],
              [51.312083338000036, 10.475793671000076],
              [51.312267208000037, 10.475777846000085],
              [51.312371134000045, 10.475746197000092],
              [51.312810821000085, 10.475619601000062],
              [51.312986695000063, 10.475619601000062],
              [51.313122599000053, 10.475603776000071],
              [51.313186553000037, 10.47558795100008],
              [51.313354434000075, 10.475445530000059],
              [51.313442371000065, 10.475405969000064],
              [51.313522314000068, 10.475342670000089],
              [51.313642229000038, 10.475287284000046],
              [51.313754149000033, 10.475255635000053],
              [51.313890052000033, 10.475255635000053],
              [51.314001973000074, 10.475239810000062],
              [51.314137876000075, 10.475168600000075],
              [51.31424979600007, 10.475105301000042],
              [51.314417677000051, 10.475018266000063],
              [51.314561574000038, 10.474947055000086],
              [51.314665500000046, 10.474875844000053],
              [51.314737449000063, 10.474772984000083],
              [51.314817392000066, 10.474678037000047],
              [51.314897335000069, 10.474638475000063],
              [51.31492931300005, 10.47460682600007],
              [51.315049227000088, 10.474591001000078],
              [51.31521710800007, 10.474496053000053],
              [51.315281062000054, 10.474488141000052],
              [51.315472926000041, 10.474409018000074],
              [51.315648800000076, 10.474353632000089],
              [51.315752726000085, 10.474321982000049],
              [51.315888630000074, 10.474314070000048],
              [51.315943059000062, 10.474303296000073],
              [51.317497948000039, 10.473814946000061],
              [51.319413414000053, 10.473124903000041],
              [51.320811407000065, 10.47255705200007],
              [51.320909056000062, 10.472494230000052],
              [51.321004988000084, 10.472407194000084],
              [51.321108914000035, 10.472351807000052],
              [51.321172868000076, 10.472320158000059],
              [51.32128478900006, 10.472217297000043],
              [51.321420692000061, 10.472138173000076],
              [51.321524618000069, 10.472082786000044],
              [51.32164453300004, 10.472059049000052],
              [51.321812413000032, 10.472019487000068],
              [51.321924333000084, 10.471948276000091],
              [51.322108203000084, 10.471908714000051],
              [51.322276083000077, 10.471861240000067],
              [51.322499924000056, 10.471774203000052],
              [51.322707776000072, 10.471734641000069],
              [51.322827690000054, 10.471679255000083],
              [51.322987577000049, 10.47160804300006],
              [51.323219412000071, 10.471584306000068],
              [51.323371303000044, 10.471560569000076],
              [51.323499212000058, 10.471560569000076],
              [51.323651104000078, 10.471568481000077],
              [51.323712582000041, 10.471609046000083],
              [51.323862008000049, 10.471544744000084],
              [51.323994860000084, 10.471544744000084],
              [51.324154746000033, 10.471544744000084],
              [51.324306638000053, 10.471552656000085],
              [51.32445053500004, 10.471576394000067],
              [51.324610421000045, 10.471623868000052],
              [51.324770308000041, 10.471647605000044],
              [51.32492220000006, 10.471647605000044],
              [51.325074091000033, 10.471655518000091],
              [51.325233978000085, 10.471663430000092],
              [51.325409852000064, 10.471639693000043],
              [51.32555375000004, 10.471576394000067],
              [51.325633693000043, 10.471497270000043],
              [51.325713636000046, 10.471449795000069],
              [51.325945471000068, 10.471426058000077],
              [51.326185300000077, 10.471418146000076],
              [51.32633719200004, 10.471418146000076],
              [51.32653705000007, 10.471402321000085],
              [51.326656965000041, 10.471354846000054],
              [51.326715423000053, 10.47131924100006],
              [51.327610657000037, 10.470959593000089],
              [51.327765322000062, 10.47091200400007],
              [51.327991371000053, 10.470888209000066],
              [51.328253111000038, 10.470828723000068],
              [51.328538647000073, 10.471126155000093],
              [51.328776593000043, 10.471245128000078],
              [51.329823556000065, 10.471483075000037],
              [51.332468739000035, 10.472505663000049],
              [51.33324267900008, 10.472840791000067],
              [51.333372185000087, 10.472826549000047],
              [51.333516082000074, 10.472818637000046],
              [51.333739923000053, 10.472866111000087],
              [51.333835855000075, 10.472913585000072],
              [51.333915798000078, 10.472984797000038],
              [51.33408367800007, 10.473048095000081],
              [51.334235570000033, 10.473158868000041],
              [51.334387462000052, 10.473269641000059],
              [51.334499382000047, 10.473317115000043],
              [51.33464328000008, 10.473388326000077],
              [51.334803166000086, 10.47342788800006],
              [51.334907092000037, 10.473467450000044],
              [51.33498703500004, 10.473499099000037],
              [51.335042995000038, 10.473530749000076],
              [51.33512293800004, 10.473570310000071],
              [51.335210876000076, 10.473641522000037],
              [51.335234859000082, 10.473649434000038],
              [51.335362768000039, 10.47371273300007],
              [51.335434717000055, 10.473799768000049],
              [51.335435727000061, 10.473816770000042],
              [51.335650563000058, 10.473934278000058],
              [51.335930364000035, 10.474116262000052],
              [51.336513948000061, 10.474416930000075],
              [51.33718798700005, 10.474635860000092],
              [51.338068388000067, 10.474945190000085],
              [51.338413410000044, 10.475135547000093],
              [51.338639459000035, 10.475242623000042],
              [51.338853610000058, 10.475456774000065],
              [51.339246221000053, 10.475694721000082],
              [51.33972211300005, 10.47584938600005],
              [51.339912470000058, 10.475932667000052],
              [51.340090930000088, 10.47599215300005],
              [51.340198006000037, 10.476039742000069],
              [51.340447849000043, 10.47612302400006],
              [51.34068579500007, 10.476194407000037],
              [51.341125995000084, 10.476313380000079],
              [51.341697066000052, 10.476432353000064],
              [51.341958073000058, 10.476307969000061],
              [51.34205400500008, 10.476276320000068],
              [51.342181914000037, 10.476276320000068],
              [51.342286689000048, 10.476276320000068],
              [51.342309823000051, 10.476276320000068],
              [51.34239776000004, 10.476244671000075],
              [51.342589624000084, 10.476236758000084],
              [51.342661572000054, 10.476252583000075],
              [51.342773493000038, 10.476252583000075],
              [51.342885413000033, 10.476315881000062],
              [51.342989339000042, 10.47639500400004],
              [51.343101259000036, 10.476418741000089],
              [51.343181203000086, 10.476482039000075],
              [51.343261146000032, 10.476561161000063],
              [51.343325100000072, 10.47662445900005],
              [51.343476992000035, 10.476727319000076],
              [51.343612895000035, 10.476798529000064],
              [51.343708827000057, 10.476822266000056],
              [51.343796764000047, 10.476853915000049],
              [51.343844283000067, 10.476936219000038],
              [51.343860719000077, 10.476964687000077],
              [51.343972639000071, 10.477170405000038],
              [51.343996622000077, 10.477194142000087],
              [51.344052582000074, 10.477265352000074],
              [51.344020605000082, 10.477391948000047],
              [51.344001014000071, 10.477416509000079],
              [51.344112568000071, 10.477463671000066],
              [51.344116537000048, 10.477534368000079],
              [51.34407656500008, 10.477660964000052],
              [51.344068571000037, 10.477724262000038],
              [51.344092554000042, 10.477811297000073],
              [51.34414851400004, 10.47787459500006],
              [51.344236451000086, 10.477945805000047],
              [51.344324389000064, 10.478024927000092],
              [51.344460292000065, 10.478143610000075],
              [51.344580207000035, 10.478222733000052],
              [51.344676138000068, 10.478238557000054],
              [51.344772070000033, 10.478286030000049],
              [51.344836025000063, 10.478380977000086],
              [51.344875996000042, 10.478475924000065],
              [51.344915968000066, 10.478586695000047],
              [51.34511159300007, 10.478585766000037],
              [51.345301949000032, 10.478740431000062],
              [51.345468512000082, 10.478835609000043],
              [51.345682663000048, 10.479133042000058],
              [51.345968199000083, 10.479323399000066],
              [51.34617045300007, 10.479620831000091],
              [51.346384604000036, 10.479704112000093],
              [51.346598756000049, 10.479775496000059],
              [51.346801010000036, 10.479906367000069],
              [51.34696757200004, 10.480120518000092],
              [51.347217416000035, 10.480298978000064],
              [51.347610027000087, 10.480501232000051],
              [51.347847973000057, 10.480667794000055],
              [51.348085919000084, 10.480881946000068],
              [51.348281572000076, 10.481134417000078],
              [51.348526119000041, 10.481072302000086],
              [51.348942525000041, 10.481179378000093],
              [51.349120975000062, 10.481585409000047],
              [51.349288855000054, 10.481632882000042],
              [51.349464730000079, 10.481648706000044],
              [51.349656593000077, 10.481727827000043],
              [51.34972054800005, 10.481759476000093],
              [51.349768514000061, 10.481767388000037],
              [51.349920406000081, 10.481783212000039],
              [51.350080292000087, 10.481751563000046],
              [51.350245338000036, 10.481665999000086],
              [51.350495940000087, 10.481802014000039],
              [51.350527973000055, 10.48190980600009],
              [51.350551956000061, 10.481949367000084],
              [51.350647888000083, 10.482036400000084],
              [51.350743819000058, 10.48209178500008],
              [51.350935683000046, 10.482155082000077],
              [51.351103563000038, 10.482170906000079],
              [51.351295427000082, 10.482170906000079],
              [51.351519267000072, 10.482123433000083],
              [51.35160720500005, 10.482115521000082],
              [51.351711131000059, 10.482099697000081],
              [51.351751102000037, 10.482075961000078],
              [51.351871017000065, 10.482044312000085],
              [51.352046892000033, 10.482044312000085],
              [51.352350676000071, 10.482052224000086],
              [51.352638471000034, 10.482052224000086],
              [51.352814346000059, 10.482099697000081],
              [51.352942255000073, 10.482170906000079],
              [51.353046181000082, 10.482242115000076],
              [51.353174090000039, 10.482297500000072],
              [51.353278016000047, 10.482344973000068],
              [51.353397930000085, 10.482321236000075],
              [51.35349386200005, 10.482234203000075],
              [51.353533833000085, 10.482210467000073],
              [51.353605782000045, 10.482107609000082],
              [51.353653748000056, 10.482060136000086],
              [51.35378165700007, 10.482012664000081],
              [51.35391756000007, 10.481996839000089],
              [51.354125412000087, 10.481996839000089],
              [51.354365242000085, 10.481996839000089],
              [51.354565099000069, 10.481981015000088],
              [51.354661031000035, 10.481965191000086],
              [51.354772951000086, 10.481949367000084],
              [51.354852895000079, 10.481917718000091],
              [51.354940832000068, 10.481862333000038],
              [51.354955238000059, 10.481857581000043],
              [51.355479413000069, 10.481862667000087],
              [51.359435948000055, 10.482000292000066],
              [51.362790988000086, 10.482154957000091],
              [51.366098439000041, 10.482143060000055],
              [51.367961642000068, 10.481759475000047],
              [51.367995540000038, 10.481759475000047],
              [51.368235369000047, 10.481751563000046],
              [51.368483192000042, 10.481751563000046],
              [51.368707033000078, 10.481743651000045],
              [51.368930874000057, 10.481719915000042],
              [51.369138726000074, 10.481719915000042],
              [51.36929861200008, 10.481719915000042],
              [51.369346578000034, 10.481719915000042],
              [51.36941852700005, 10.481664530000046],
              [51.36946798200006, 10.481624221000061],
              [51.369698245000052, 10.481671576000053],
              [51.370433061000085, 10.481567681000058],
              [51.371238074000075, 10.481464913000082],
              [51.371630685000071, 10.481441119000067],
              [51.371844837000083, 10.48151250300009],
              [51.371963810000068, 10.481643373000054],
              [51.371999502000051, 10.481821833000083],
              [51.372132182000087, 10.482088591000092],
              [51.372296478000067, 10.482242115000076],
              [51.372301683000046, 10.482234756000082],
              [51.372677648000035, 10.48202408700007],
              [51.372951286000045, 10.481952703000047],
              [51.373224924000056, 10.482178752000038],
              [51.373608519000072, 10.482087155000045],
              [51.373792223000066, 10.481671576000053],
              [51.374028414000065, 10.481281970000055],
              [51.374652601000037, 10.480917637000061],
              [51.374985725000045, 10.481167481000057],
              [51.375675769000054, 10.481429222000088],
              [51.375996672000042, 10.481333918000075],
              [51.376088893000087, 10.481318706000081],
              [51.37619925100006, 10.481143686000053],
              [51.376532375000068, 10.481298351000078],
              [51.376675143000057, 10.481334043000061],
              [51.376829808000082, 10.481310249000046],
              [51.37698447300005, 10.481274557000063],
              [51.377281905000075, 10.481298351000078],
              [51.377579338000032, 10.48120317300004],
              [51.377964931000065, 10.480944312000076],
              [51.378150409000057, 10.480406053000081],
              [51.378621017000057, 10.480320941000059],
              [51.378762666000057, 10.480234669000083],
              [51.378803846000039, 10.480248255000049],
              [51.378979721000064, 10.480240342000059],
              [51.379099636000035, 10.480248255000049],
              [51.379137885000034, 10.480013442000086],
              [51.379352036000057, 10.480049134000069],
              [51.379507345000036, 10.480279903000053],
              [51.379651243000069, 10.480279903000053],
              [51.379742852000049, 10.480242125000075],
              [51.379749485000048, 10.480243020000046],
              [51.379969111000037, 10.480118809000089],
              [51.380059299000038, 10.480005919000064],
              [51.380082936000065, 10.480026714000076],
              [51.380122907000043, 10.480058362000079],
              [51.380162879000068, 10.480074187000071],
              [51.380202850000046, 10.480097923000073],
              [51.380266805000076, 10.480113748000065],
              [51.380314771000087, 10.480113748000065],
              [51.380362736000052, 10.480042538000077],
              [51.380426691000082, 10.479963416000089],
              [51.380490645000066, 10.479915943000037],
              [51.380554600000039, 10.47985264600004],
              [51.380658526000047, 10.47974978700006],
              [51.380682509000053, 10.479686490000063],
              [51.380682509000053, 10.479631105000067],
              [51.380690503000039, 10.479559895000079],
              [51.380698497000083, 10.479464949000089],
              [51.380770446000042, 10.479354178000051],
              [51.380850389000045, 10.479298793000055],
              [51.380914344000075, 10.479275056000063],
              [51.38101027600004, 10.479195934000074],
              [51.381066236000038, 10.479180110000073],
              [51.38116216700007, 10.479180110000073],
              [51.381242111000063, 10.479069339000091],
              [51.381282082000041, 10.478934831000061],
              [51.381346037000071, 10.478911095000058],
              [51.381401997000069, 10.478863622000063],
              [51.381545894000055, 10.478863622000063],
              [51.381633832000034, 10.47883988500007],
              [51.381785724000054, 10.478784500000074],
              [51.381857672000081, 10.47873702600009],
              [51.382009564000043, 10.478673729000093],
              [51.382193433000054, 10.478642080000043],
              [51.382265382000071, 10.478642080000043],
              [51.382401285000071, 10.478673729000093],
              [51.382529194000085, 10.478752851000081],
              [51.382665098000075, 10.478784499000085],
              [51.382785012000056, 10.478792412000075],
              [51.38291292100007, 10.478800324000076],
              [51.383096790000081, 10.478800324000076],
              [51.383240688000058, 10.478792412000075],
              [51.383448540000074, 10.478792412000075],
              [51.383600432000037, 10.478816148000078],
              [51.383800290000067, 10.478855709000072],
              [51.384048113000063, 10.478855709000072],
              [51.384335908000082, 10.478855709000072],
              [51.384559749000061, 10.478855709000072],
              [51.384687658000075, 10.478871534000064],
              [51.384775595000065, 10.478942744000051],
              [51.385086537000063, 10.478906993000066],
              [51.385143334000077, 10.479338354000049],
              [51.385231271000066, 10.479417475000048],
              [51.38543155900004, 10.479442372000051],
              [51.385776581000073, 10.479430474000083],
              [51.385955041000045, 10.479454269000087],
              [51.386078668000039, 10.479433300000039],
              [51.386115429000085, 10.479378725000061],
              [51.386154443000066, 10.479386448000071],
              [51.386231617000078, 10.479333935000056],
              [51.386237833000052, 10.479327783000087],
              [51.386254543000064, 10.479267144000062],
              [51.386278526000069, 10.479195934000074],
              [51.386350474000039, 10.479093075000037],
              [51.386382452000078, 10.479021866000039],
              [51.386446406000061, 10.478919007000059],
              [51.386502366000059, 10.47883988500007],
              [51.386590304000038, 10.478768675000083],
              [51.386646264000035, 10.478713290000087],
              [51.386774173000049, 10.478681641000037],
              [51.386862110000038, 10.47861043100005],
              [51.386894087000087, 10.478507572000069],
              [51.386942053000041, 10.478460099000074],
              [51.387014002000058, 10.47842053800008],
              [51.387061968000069, 10.47842053800008],
              [51.387125922000052, 10.478468011000075],
              [51.387149905000058, 10.478507572000069],
              [51.387680150000051, 10.478633355000056],
              [51.387846712000055, 10.478835609000043],
              [51.388179837000052, 10.479037863000087],
              [51.388596242000062, 10.479180631000077],
              [51.388750907000087, 10.479168734000041],
              [51.389024745000086, 10.479246845000091],
              [51.389143518000083, 10.478990274000068],
              [51.389250594000032, 10.478704739000079],
              [51.389310081000076, 10.478407306000065],
              [51.389164471000072, 10.478282074000049],
              [51.389244414000075, 10.478210864000062],
              [51.389292380000086, 10.478163390000077],
              [51.389364329000045, 10.478044707000038],
              [51.389452267000081, 10.477949760000058],
              [51.389456974000041, 10.477947219000043],
              [51.38948580400006, 10.477905369000041],
              [51.389643265000075, 10.477749525000092],
              [51.390273108000088, 10.477593680000041],
              [51.390743158000078, 10.477683724000087],
              [51.390763333000052, 10.477672832000053],
              [51.390963191000083, 10.477593710000065],
              [51.391035140000042, 10.477562061000071],
              [51.391099094000083, 10.477554149000071],
              [51.391282963000037, 10.477522500000077],
              [51.391404006000073, 10.477348446000065],
              [51.39146349300006, 10.477205678000075],
              [51.391665747000047, 10.477158089000056],
              [51.391868001000034, 10.477217576000044],
              [51.392248715000051, 10.477169986000092],
              [51.392509962000076, 10.477159802000074],
              [51.392530076000071, 10.476960730000087],
              [51.392530076000071, 10.476794573000063],
              [51.392554059000076, 10.476636327000051],
              [51.392689962000077, 10.476327749000063],
              [51.392745922000074, 10.476193240000043],
              [51.392785894000042, 10.476098293000064],
              [51.392794732000084, 10.476087159000087],
              [51.392871209000077, 10.475645622000059],
              [51.392844966000041, 10.475048215000072],
              [51.39295960000004, 10.474786388000041],
              [51.392953774000034, 10.474737378000043],
              [51.392953774000034, 10.474626606000072],
              [51.392937785000072, 10.474492096000063],
              [51.392961768000077, 10.474389236000093],
              [51.393065694000086, 10.474136042000055],
              [51.393121655000073, 10.474049006000087],
              [51.393185609000057, 10.473922409000068],
              [51.393225581000081, 10.473779987000057],
              [51.393264861000034, 10.473715191000053],
              [51.393264861000034, 10.473281961000055],
              [51.393815973000073, 10.472892345000048],
              [51.393878646000076, 10.472422962000053],
              [51.393807262000053, 10.471994659000075],
              [51.393997619000061, 10.471233231000042],
              [51.394164181000065, 10.470376625000085],
              [51.394259360000035, 10.469924528000092],
              [51.394132396000032, 10.469987821000075],
              [51.394039540000051, 10.469973682000045],
              [51.393982398000048, 10.469938334000062],
              [51.39394668500006, 10.469832290000056],
              [51.393918114000087, 10.469669689000057],
              [51.393860972000084, 10.469570715000089],
              [51.393832401000054, 10.469549506000078],
              [51.393782402000056, 10.469535367000049],
              [51.39369668900008, 10.469485879000047],
              [51.393703831000039, 10.469365696000068],
              [51.393632404000073, 10.46923137400006],
              [51.393518120000067, 10.469167747000085],
              [51.393489549000037, 10.469089982000071],
              [51.393460978000064, 10.469040494000069],
              [51.393410979000066, 10.469012216000067],
              [51.393375265000032, 10.468991007000056],
              [51.393360980000068, 10.468962729000054],
              [51.393375265000032, 10.46886375400004],
              [51.393382408000036, 10.468750640000053],
              [51.393397438000079, 10.468720887000075],
              [51.393361771000059, 10.46867381800007],
              [51.393410979000066, 10.468644596000047],
              [51.393510977000062, 10.468545621000089],
              [51.393553834000045, 10.468481995000047],
              [51.393532406000077, 10.468397159000062],
              [51.393475264000074, 10.468333532000088],
              [51.393460978000064, 10.468227488000082],
              [51.393700186000046, 10.468163726000057],
              [51.393712084000072, 10.46809234300008],
              [51.393735878000086, 10.467997164000053],
              [51.393854851000071, 10.467854397000053],
              [51.393819159000032, 10.467675937000081],
              [51.393938132000073, 10.467402299000071],
              [51.393882400000052, 10.467258947000062],
              [51.393953827000075, 10.467117554000083],
              [51.393982398000048, 10.46706099700009],
              [51.394018112000083, 10.466962022000075],
              [51.394118110000079, 10.466792350000048],
              [51.394168110000066, 10.466643887000089],
              [51.394166733000077, 10.466643011000087],
              [51.39422514000006, 10.466561716000058],
              [51.39430382200004, 10.466551981000066],
              [51.394453819000034, 10.466495424000072],
              [51.394503819000079, 10.466445936000071],
              [51.394497306000062, 10.466283952000083],
              [51.394782385000042, 10.466141940000057],
              [51.394818099000076, 10.466085383000063],
              [51.39498238200008, 10.466014686000051],
              [51.395075238000061, 10.465972268000087],
              [51.395246664000069, 10.465845013000092],
              [51.39532523400004, 10.465753107000069],
              [51.395418090000078, 10.465668271000084],
              [51.39558951500004, 10.465420832000063],
              [51.395682371000078, 10.46532185600006],
              [51.395746656000085, 10.465237020000075],
              [51.395760941000049, 10.465046138000048],
              [51.395760941000049, 10.464890604000061],
              [51.395732370000076, 10.464692653000043],
              [51.39573951300008, 10.464522979000037],
              [51.39573951300008, 10.464374515000088],
              [51.395775227000058, 10.464218982000091],
              [51.395839511000077, 10.464127075000079],
              [51.395903796000084, 10.464006890000064],
              [51.395946652000077, 10.463936193000052],
              [51.396032365000053, 10.463900844000079],
              [51.396053794000068, 10.463794798000038],
              [51.396060936000083, 10.463688752000053],
              [51.396051853000074, 10.463667175000069],
              [51.396365183000057, 10.463357215000087],
              [51.396507950000057, 10.463381010000091],
              [51.396662615000082, 10.463369113000056],
              [51.396689498000057, 10.463603915000078],
              [51.396753782000076, 10.463702891000082],
              [51.396753782000076, 10.463759449000065],
              [51.396775210000044, 10.463830147000067],
              [51.396825210000088, 10.46384428600004],
              [51.396868066000081, 10.46386549500005],
              [51.396903780000059, 10.463900844000079],
              [51.396946636000052, 10.463929123000071],
              [51.39699663500005, 10.463943262000043],
              [51.397075206000068, 10.463943262000043],
              [51.397168061000059, 10.46386549500005],
              [51.397239489000071, 10.463787728000057],
              [51.397289488000069, 10.463709961000063],
              [51.397339487000067, 10.46361805500004],
              [51.397432343000048, 10.463519078000047],
              [51.397525198000039, 10.463420102000043],
              [51.397618054000077, 10.46332112500005],
              [51.397725195000078, 10.463222149000046],
              [51.397753766000051, 10.463123173000042],
              [51.397796623000033, 10.463045405000059],
              [51.39791090600005, 10.462925220000045],
              [51.398003762000087, 10.462755546000039],
              [51.398089475000063, 10.462600011000063],
              [51.398175188000039, 10.462437406000049],
              [51.398303757000065, 10.462310151000054],
              [51.398389470000041, 10.46224652300009],
              [51.398453755000048, 10.462161685000069],
              [51.398460898000053, 10.462062709000065],
              [51.398453755000048, 10.461935453000081],
              [51.398410898000066, 10.461857685000041],
              [51.398268043000087, 10.461857685000041],
              [51.398175188000039, 10.461914243000081],
              [51.398096618000068, 10.461999081000044],
              [51.398010905000035, 10.462048569000046],
              [51.397721475000083, 10.462096101000043],
              [51.397792859000049, 10.461322776000088],
              [51.398256854000067, 10.46085878100007],
              [51.398375827000052, 10.460002175000056],
              [51.399172947000068, 10.458574499000065],
              [51.399660736000044, 10.457789276000085],
              [51.399668021000082, 10.457566300000053],
              [51.399760876000073, 10.457552161000081],
              [51.399796590000051, 10.457523881000043],
              [51.399839447000033, 10.45746732200007],
              [51.399889446000032, 10.457396623000079],
              [51.399989444000084, 10.457283505000078],
              [51.40007515700006, 10.457255226000086],
              [51.400096585000085, 10.457170387000076],
              [51.400068014000055, 10.457071409000037],
              [51.400039443000082, 10.457050199000037],
              [51.39998230100008, 10.457021920000045],
              [51.399946588000034, 10.457014850000064],
              [51.399943266000037, 10.457015507000051],
              [51.399957994000033, 10.45699269000005],
              [51.399939445000086, 10.456986570000083],
              [51.399910874000057, 10.456908801000054],
              [51.399932302000082, 10.456852242000082],
              [51.399960873000055, 10.456809823000071],
              [51.400018015000057, 10.456781543000091],
              [51.40006087200004, 10.45671791400008],
              [51.400103728000033, 10.456689635000089],
              [51.400168812000061, 10.456689635000089],
              [51.400191841000037, 10.456665804000068],
              [51.400232297000059, 10.456661355000051],
              [51.400310868000076, 10.456618936000041],
              [51.400396581000052, 10.45654823700005],
              [51.400560864000056, 10.456449258000077],
              [51.400689433000082, 10.456364419000067],
              [51.400832288000061, 10.456244231000085],
              [51.400968, 10.456138182000075],
              [51.401125140000033, 10.455982644000073],
              [51.401232281000034, 10.455883665000044],
              [51.401367993000065, 10.455777617000081],
              [51.40149656300008, 10.455692778000071],
              [51.401560847000042, 10.45560793900006],
              [51.401646560000074, 10.455466540000089],
              [51.401725130000045, 10.455360491000079],
              [51.401832272000036, 10.455212023000058],
              [51.401910842000063, 10.455113044000086],
              [51.402025126000069, 10.455006995000076],
              [51.402110839000045, 10.454936295000039],
              [51.402225122000061, 10.454823176000048],
              [51.402303693000079, 10.454752477000056],
              [51.402353692000077, 10.454681778000065],
              [51.402375120000045, 10.454582798000047],
              [51.402389405000065, 10.454505029000075],
              [51.402375120000045, 10.454427259000056],
              [51.402358737000043, 10.45440480600007],
              [51.402487901000086, 10.454245964000052],
              [51.402503689000071, 10.454130322000083],
              [51.402532260000044, 10.454024272000083],
              [51.402610830000071, 10.453918223000073],
              [51.402667972000074, 10.453854593000074],
              [51.402667972000074, 10.453713194000045],
              [51.402710829000057, 10.453628354000045],
              [51.402746543000035, 10.453550585000073],
              [51.402853684000036, 10.453359695000074],
              [51.402853684000036, 10.453126386000065],
              [51.402860826000051, 10.453041546000065],
              [51.402932254000063, 10.452964419000068],
              [51.402932254000063, 10.452942567000036],
              [51.402946539000084, 10.452914287000056],
              [51.403017967000039, 10.452914287000056],
              [51.403082251000058, 10.452914287000056],
              [51.403203678000068, 10.452914287000056],
              [51.403303676000064, 10.452900147000037],
              [51.403353676000052, 10.452900147000037],
              [51.403433894000045, 10.452900147000037],
              [51.40351081600005, 10.452829447000056],
              [51.403660814000034, 10.452680977000057],
              [51.403760812000087, 10.452574927000057],
              [51.403824870000051, 10.452486160000092],
              [51.403867953000088, 10.452454738000085],
              [51.403967951000084, 10.452355758000067],
              [51.404075093000074, 10.452299198000048],
              [51.404167948000065, 10.452193148000049],
              [51.404289375000076, 10.45204467800005],
              [51.404489372000057, 10.451860857000042],
              [51.404582227000049, 10.451783087000081],
              [51.404717939000079, 10.451648757000044],
              [51.404853651000053, 10.451549777000082],
              [51.404967935000059, 10.451464937000083],
              [51.405060791000039, 10.451337676000037],
              [51.40521078900008, 10.451217486000075],
              [51.405389357000047, 10.450998315000049],
              [51.405510784000057, 10.450878125000088],
              [51.405596497000033, 10.45080742500005],
              [51.405689352000081, 10.450722584000061],
              [51.405810779000035, 10.450630674000081],
              [51.405860778000033, 10.450602394000043],
              [51.406017918000032, 10.450510483000073],
              [51.40618934400004, 10.450418573000093],
              [51.406367913000054, 10.450298382000085],
              [51.406646480000063, 10.450050930000089],
              [51.406753621000064, 10.449959020000051],
              [51.406875047000085, 10.449881249000043],
              [51.406967903000066, 10.449782268000092],
              [51.407039330000032, 10.449697428000093],
              [51.407175043000052, 10.449612587000047],
              [51.407403610000074, 10.449471186000039],
              [51.407582179000087, 10.449358065000069],
              [51.407753605000039, 10.449280294000062],
              [51.40788931700007, 10.449181313000054],
              [51.408053600000073, 10.449025771000038],
              [51.408089313000062, 10.448972746000038],
              [51.408117884000035, 10.448962141000038],
              [51.408267882000075, 10.448948],
              [51.408489307000082, 10.448827809000079],
              [51.408653590000085, 10.448750038000071],
              [51.408732160000056, 10.448714688000052],
              [51.40891072900007, 10.448643987000082],
              [51.408960728000068, 10.448601567000082],
              [51.409103583000046, 10.448516726000037],
              [51.409125011000071, 10.448453095000048],
              [51.409440321000034, 10.448330919000057],
              [51.409809137000082, 10.448164357000053],
              [51.410451592000072, 10.447759848000089],
              [51.410927484000069, 10.447474313000043],
              [51.411058354000033, 10.447260162000077],
              [51.411236814000063, 10.447057907000044],
              [51.411355787000048, 10.446915140000044],
              [51.411617528000079, 10.446700988000089],
              [51.411724969000034, 10.446385088000056],
              [51.411824967000086, 10.446328527000048],
              [51.411882109000032, 10.44629317600004],
              [51.411924966000072, 10.446236615000089],
              [51.41198925100008, 10.446172984000043],
              [51.412110677000044, 10.446081072000084],
              [51.412132105000069, 10.446081072000084],
              [51.412217818000045, 10.446059861000037],
              [51.412310674000082, 10.446003300000086],
              [51.412424958000088, 10.445932599000059],
              [51.412496385000054, 10.445868967000081],
              [51.412532099000032, 10.445840687000043],
              [51.412589241000035, 10.445734635000065],
              [51.41261066900006, 10.445649793000086],
              [51.412660668000058, 10.445564951000051],
              [51.412710667000056, 10.445536670000081],
              [51.41291433400005, 10.445606436000048],
              [51.413068999000075, 10.445594539000069],
              [51.413223664000043, 10.445535052000082],
              [51.413437815000066, 10.445427977000065],
              [51.413628172000074, 10.445285209000076],
              [51.413682080000058, 10.445161952000092],
              [51.413832078000041, 10.445020549000049],
              [51.413982076000082, 10.444886216000043],
              [51.414039217000038, 10.444794303000037],
              [51.414110645000051, 10.44464583000007],
              [51.414150823000057, 10.444594129000052],
              [51.414203500000042, 10.444568058000073],
              [51.414289213000075, 10.444560988000092],
              [51.414346355000077, 10.444546847000083],
              [51.414482067000051, 10.444462005000048],
              [51.414567780000084, 10.444348882000043],
              [51.41465349300006, 10.444235759000037],
              [51.414732063000088, 10.44415798700004],
              [51.414832062000073, 10.444059005000042],
              [51.414867775000062, 10.443995373000064],
              [51.414932060000069, 10.443868110000039],
              [51.414974917000052, 10.44381861800008],
              [51.415074915000048, 10.443634793000058],
              [51.415124914000046, 10.443514600000071],
              [51.415139200000056, 10.443366126000058],
              [51.415153485000076, 10.443154020000065],
              [51.415124914000046, 10.442991405000043],
              [51.415089200000068, 10.442913632000057],
              [51.414982059000067, 10.44281464900007],
              [51.414882061000071, 10.442715666000083],
              [51.414839205000078, 10.442666175000056],
              [51.414810653000075, 10.442657479000047],
              [51.414777260000051, 10.442586964000043],
              [51.414696350000042, 10.442538911000042],
              [51.414610637000067, 10.442503560000091],
              [51.414524924000034, 10.442454068000075],
              [51.414439211000058, 10.442404577000048],
              [51.414324927000052, 10.44234094400008],
              [51.414232071000072, 10.442249032000063],
              [51.414217786000052, 10.442128838000087],
              [51.414146359000085, 10.441966222000076],
              [51.414046360000043, 10.441867239000089],
              [51.413982075000035, 10.441803607000054],
              [51.413917791000074, 10.441704623000078],
              [51.413810650000073, 10.441570289000083],
              [51.413682080000058, 10.44144302400008],
              [51.413574939000057, 10.441315760000066],
              [51.413496369000086, 10.441230917000041],
              [51.41338208500008, 10.441117793000046],
              [51.412938129000054, 10.440716644000076],
              [51.412857570000085, 10.440638431000082],
              [51.412410758000078, 10.440204625000092],
              [51.412364312000079, 10.440172442000062],
              [51.412367816000085, 10.440096139000048],
              [51.412339245000055, 10.440046647000088],
              [51.412274960000047, 10.439940593000074],
              [51.41221067500004, 10.439855750000049],
              [51.412124963000053, 10.439785047000044],
              [51.412003536000043, 10.439678993000086],
              [51.411924966000072, 10.439601219000053],
              [51.411839253000039, 10.439537587000075],
              [51.411724969000034, 10.43944567300008],
              [51.411644997000053, 10.439393370000062],
              [51.411314186000084, 10.439025205000064],
              [51.411308198000086, 10.438753588000054],
              [51.41120112200008, 10.438670307000052],
              [51.411153533000061, 10.438539437000088],
              [51.411082149000038, 10.438396669000042],
              [51.410986971000057, 10.438230107000038],
              [51.410820408000063, 10.437778009000056],
              [51.410879895000051, 10.437421090000043],
              [51.410570565000057, 10.436957095000082],
              [51.410475386000087, 10.436612074000038],
              [51.410344516000066, 10.436207565000075],
              [51.410177954000062, 10.435719776000042],
              [51.409775, 10.434984285000041],
              [51.409713959000044, 10.434803683000041],
              [51.409642575000078, 10.434589532000075],
              [51.409594986000059, 10.433994666000046],
              [51.409594986000059, 10.433768618000045],
              [51.409645546000036, 10.433462295000083],
              [51.40965109800004, 10.433428659000072],
              [51.410023289000037, 10.432543195000051],
              [51.409946426000033, 10.432636905000038],
              [51.410023289000037, 10.432483709000053],
              [51.410296927000047, 10.432162481000091],
              [51.410130365000043, 10.431888843000081],
              [51.409860713000057, 10.431597547000081],
              [51.409853571000042, 10.431576335000045],
              [51.409839285000032, 10.431540983000048],
              [51.409803571000054, 10.431491490000042],
              [51.409746429000052, 10.431491490000042],
              [51.409682145000033, 10.431484419000071],
              [51.409617860000083, 10.431441996000046],
              [51.409596432000058, 10.431371292000051],
              [51.409596432000058, 10.43127230500005],
              [51.409596432000058, 10.431180389000076],
              [51.409589289000053, 10.43103190800008],
              [51.409567861000085, 10.430812723000088],
              [51.40956071800008, 10.43057232600006],
              [51.40956071800008, 10.430473339000059],
              [51.40954643300006, 10.430430916000091],
              [51.409496433000072, 10.430430916000091],
              [51.409446434000074, 10.430409705000045],
              [51.409425006000049, 10.43033192900009],
              [51.409425006000049, 10.430232942000089],
              [51.409439292000059, 10.430077391000054],
              [51.409467862000042, 10.429935981000085],
              [51.409432149000054, 10.429808712000067],
              [51.409367864000046, 10.429596596000067],
              [51.409317865000048, 10.429433974000062],
              [51.409303579000039, 10.429363269000078],
              [51.40929643700008, 10.429221858000062],
              [51.409253580000041, 10.429207717000054],
              [51.409196438000038, 10.42914408200005],
              [51.409182153000074, 10.429080448000093],
              [51.40917501000007, 10.428967319000037],
              [51.40917501000007, 10.428847120000057],
              [51.409182153000074, 10.428726921000077],
              [51.409189296000079, 10.428592581000089],
              [51.409210724000047, 10.428543087000037],
              [51.409253580000041, 10.428486522000071],
              [51.409258714000032, 10.428483981000056],
              [51.409223470000086, 10.428285120000055],
              [51.409236370000087, 10.428262772000039],
              [51.409264164000035, 10.428147218000049],
              [51.409269723000079, 10.428048172000047],
              [51.409275281000077, 10.427998648000084],
              [51.409286399000052, 10.427905104000047],
              [51.409286399000052, 10.427861084000085],
              [51.409286399000052, 10.427767540000048],
              [51.409275281000077, 10.427673996000067],
              [51.409269723000079, 10.42763547800007],
              [51.409236370000087, 10.427563944000042],
              [51.409180782000078, 10.427519923000091],
              [51.409152989000063, 10.427497913000082],
              [51.409125195000058, 10.427459395000085],
              [51.409097401000054, 10.427404369000044],
              [51.409075166000036, 10.427382358000045],
              [51.409062937000044, 10.427379332000044],
              [51.409051663000071, 10.42731572100007],
              [51.409044085000062, 10.42731572100007],
              [51.409036636000053, 10.427314082000066],
              [51.408968476000041, 10.427299088000041],
              [51.409238067000047, 10.42695146200009],
              [51.409202375000064, 10.426761105000082],
              [51.40893619700006, 10.426402895000081],
              [51.408980667000037, 10.426397392000069],
              [51.409014020000086, 10.426292842000066],
              [51.408986226000081, 10.426226811000049],
              [51.408952873000032, 10.42611125600007],
              [51.408925079000085, 10.426045225000053],
              [51.408547084000077, 10.425814115000037],
              [51.408514530000048, 10.425812100000087],
              [51.408415029000082, 10.425620581000089],
              [51.408359110000049, 10.425620581000089],
              [51.408273489000067, 10.425588797000046],
              [51.40824138000005, 10.425557013000059],
              [51.408209272000079, 10.425493445000086],
              [51.408166461000064, 10.425419282000064],
              [51.408112948000053, 10.425345120000088],
              [51.408038028000078, 10.425323930000047],
              [51.408016623000037, 10.425292146000061],
              [51.40797381200008, 10.425260362000074],
              [51.407931001000065, 10.425228578000088],
              [51.40785608200008, 10.425196794000044],
              [51.40785608200008, 10.425059064000038],
              [51.407813271000066, 10.424963712000078],
              [51.407749055000068, 10.424868360000062],
              [51.407663433000039, 10.424614087000066],
              [51.407663433000039, 10.424582303000079],
              [51.407642027000065, 10.424486951000063],
              [51.407577811000067, 10.424402193000049],
              [51.407535, 10.424317436000081],
              [51.407502892000082, 10.424285652000037],
              [51.407470783000065, 10.424306841000089],
              [51.407310242000051, 10.424243273000059],
              [51.407320945000038, 10.424147921000042],
              [51.407320945000038, 10.424010189000057],
              [51.40727813400008, 10.42386186300007],
              [51.407224621000069, 10.423777106000045],
              [51.407160404000081, 10.423692348000088],
              [51.407064080000055, 10.42358640100008],
              [51.406978458000083, 10.423438074000046],
              [51.406892836000054, 10.423310938000043],
              [51.406828619000066, 10.423194396000042],
              [51.406692043000078, 10.423156222000046],
              [51.406620660000044, 10.423072940000054],
              [51.406489789000034, 10.422965865000037],
              [51.406323227000087, 10.422846892000052],
              [51.406144767000058, 10.422656535000044],
              [51.406025914000054, 10.422304436000047],
              [51.405940293000072, 10.422251463000066],
              [51.405779751000068, 10.42209254100004],
              [51.40571553500007, 10.42203956700007],
              [51.405608508000057, 10.421901835000085],
              [51.405565697000043, 10.421700534000081],
              [51.405501480000055, 10.421594586000083],
              [51.405394453000042, 10.421446259000049],
              [51.405340939000041, 10.421319121000067],
              [51.405319534000057, 10.421202579000067],
              [51.40530883100007, 10.421033062000049],
              [51.405244615000072, 10.420905924000067],
              [51.405158993000043, 10.420799976000069],
              [51.405158993000043, 10.420651648000046],
              [51.405169696000087, 10.420471536000036],
              [51.405175546000066, 10.420413618000055],
              [51.40513758700007, 10.42038677700009],
              [51.405073371000071, 10.420312614000068],
              [51.405041263000044, 10.420174881000037],
              [51.405030560000057, 10.420037148000063],
              [51.404987749000043, 10.419920604000083],
              [51.404966344000059, 10.419835846000069],
              [51.404966344000059, 10.41971930200009],
              [51.405009155000073, 10.41953919000008],
              [51.405335751000052, 10.41930149500007],
              [51.405692670000064, 10.41889698600005],
              [51.405704567000043, 10.41848058100004],
              [51.40581164300005, 10.417754845000047],
              [51.405680772000039, 10.416588909000041],
              [51.40507668500004, 10.41615846500008],
              [51.405094776000055, 10.41613822000005],
              [51.405084074000058, 10.415958105000072],
              [51.405084074000058, 10.415905130000056],
              [51.404998452000086, 10.415809775000071],
              [51.404923533000044, 10.415714420000086],
              [51.404795100000058, 10.415576685000076],
              [51.404634559000044, 10.415438950000066],
              [51.404559640000059, 10.415364785000065],
              [51.404463315000044, 10.415258835000088],
              [51.404345585000044, 10.415152885000055],
              [51.404259963000072, 10.41505753000007],
              [51.404195747000074, 10.414962174000038],
              [51.404163639000046, 10.414824439000085],
              [51.404131530000086, 10.414697299000068],
              [51.404099422000058, 10.414580753000052],
              [51.403938881000045, 10.414453613000092],
              [51.403831854000032, 10.414443018000043],
              [51.403714124000032, 10.414411233000067],
              [51.403598744000078, 10.414542573000062],
              [51.403479771000036, 10.41445929200006],
              [51.403301311000064, 10.414423600000077],
              [51.40299198200006, 10.414221345000044],
              [51.402275199000087, 10.413556301000085],
              [51.402214938000043, 10.413463505000038],
              [51.402205038000034, 10.413404702000037],
              [51.402172930000063, 10.413309347000052],
              [51.402162227000076, 10.413192801000037],
              [51.402119416000062, 10.413182206000045],
              [51.401990984000065, 10.413139825000087],
              [51.401841145000049, 10.413139825000087],
              [51.40177692900005, 10.413139825000087],
              [51.401744821000079, 10.413118635000046],
              [51.401434441000049, 10.412991494000039],
              [51.401088413000082, 10.413031615000079],
              [51.400636315000042, 10.413091101000077],
              [51.399708325000063, 10.412531928000078],
              [51.399446585000078, 10.412329674000091],
              [51.399497247000056, 10.41212269600004],
              [51.399293895000085, 10.412090910000074],
              [51.399186867000083, 10.412006149000092],
              [51.399101245000054, 10.411942579000083],
              [51.39905843400004, 10.411921388000053],
              [51.398972813000057, 10.411804842000038],
              [51.398855082000068, 10.411582344000067],
              [51.398748055000056, 10.411444607000078],
              [51.398608920000072, 10.411275085000057],
              [51.398544703000084, 10.411190324000074],
              [51.398480487000086, 10.411105563000092],
              [51.398416270000041, 10.411063182000078],
              [51.398309243000085, 10.411031397000045],
              [51.398266432000071, 10.411010206000071],
              [51.398202216000072, 10.410946635000073],
              [51.398180810000042, 10.410808898000084],
              [51.398215410000034, 10.41071299400005],
              [51.398129139000048, 10.410619826000072],
              [51.398435314000039, 10.409069812000041],
              [51.398197368000069, 10.408296488000076],
              [51.398078395000084, 10.407808698000053],
              [51.39801890800004, 10.407594547000087],
              [51.398007011000061, 10.407475574000046],
              [51.397995113000036, 10.407380395000075],
              [51.397995113000036, 10.40726142200009],
              [51.397971319000078, 10.407166244000052],
              [51.397971319000078, 10.407071065000082],
              [51.397947524000074, 10.406975887000044],
              [51.398030805000076, 10.406833119000055],
              [51.398102189000042, 10.406607070000064],
              [51.398280649000071, 10.406047897000064],
              [51.398423416000071, 10.405857540000056],
              [51.398399622000056, 10.40564338900009],
              [51.398287837000055, 10.405463592000046],
              [51.398287837000055, 10.405389424000077],
              [51.398223621000056, 10.405241089000071],
              [51.398180810000042, 10.405007991000048],
              [51.398105891000057, 10.404880847000072],
              [51.398020269000085, 10.404711321000093],
              [51.397956053000087, 10.404626558000075],
              [51.397827620000044, 10.404541795000057],
              [51.397741998000072, 10.404488818000061],
              [51.397639731000083, 10.404574718000049],
              [51.397437444000047, 10.404131290000066],
              [51.39709091800006, 10.403775512000038],
              [51.396900561000052, 10.403501874000085],
              [51.396793486000036, 10.403371003000075],
              [51.396805383000071, 10.403240133000054],
              [51.39682156300006, 10.403132606000042],
              [51.396735941000088, 10.403026652000051],
              [51.396682427000087, 10.40294188900009],
              [51.396650319000059, 10.402740575000053],
              [51.396639616000073, 10.402581644000065],
              [51.396646130000079, 10.402513936000048],
              [51.396162928000081, 10.401372256000059],
              [51.395375728000033, 10.400171839000052],
              [51.395365991000062, 10.400165872000059],
              [51.395226856000079, 10.40004932100004],
              [51.395109125000033, 10.399922175000086],
              [51.395044909000035, 10.39984800600007],
              [51.394959287000063, 10.399667882000074],
              [51.394873665000034, 10.399561926000047],
              [51.394755935000035, 10.399455971000066],
              [51.39468101600005, 10.39938180200005],
              [51.394657964000032, 10.399336160000075],
              [51.394606097000064, 10.399328824000065],
              [51.394509772000049, 10.399233464000076],
              [51.394445556000051, 10.399116913000057],
              [51.39441344800008, 10.398883810000086],
              [51.394402745000036, 10.398714281000082],
              [51.394338529000038, 10.398481178000054],
              [51.394306420000078, 10.398385818000065],
              [51.39427431200005, 10.398269266000057],
              [51.394252907000066, 10.398120928000083],
              [51.394242204000079, 10.398046759000067],
              [51.394220798000049, 10.397972590000052],
              [51.394156582000051, 10.397877229000073],
              [51.394135177000067, 10.397803060000058],
              [51.39412447400008, 10.397644126000046],
              [51.39412447400008, 10.397495787000082],
              [51.394145879000064, 10.397358044000043],
              [51.394177988000081, 10.397177918000068],
              [51.394193829000073, 10.397060297000053],
              [51.393930629000067, 10.395820677000074],
              [51.39386760800005, 10.395811079000055],
              [51.39371777000008, 10.395779292000043],
              [51.393653554000082, 10.395726313000068],
              [51.393610743000067, 10.395620356000052],
              [51.393535823000036, 10.395503804000043],
              [51.393418093000037, 10.395366060000072],
              [51.393343174000051, 10.39518593300005],
              [51.393278958000053, 10.395016402000067],
              [51.393193336000081, 10.394868062000057],
              [51.393107714000053, 10.394762105000041],
              [51.393064903000038, 10.394592573000068],
              [51.393054200000051, 10.394433637000077],
              [51.393022092000081, 10.394370063000054],
              [51.392957876000082, 10.394306488000041],
              [51.392904362000081, 10.394285297000067],
              [51.392829443000039, 10.394242914000074],
              [51.392808038000055, 10.394105169000056],
              [51.392840146000083, 10.393935638000073],
              [51.392848997000044, 10.39385677100006],
              [51.391919030000054, 10.392389076000086],
              [51.391192455000066, 10.392093972000055],
              [51.390931693000084, 10.391988062000053],
              [51.38971458900005, 10.391211958000042],
              [51.389500438000084, 10.391092984000068],
              [51.389361757000074, 10.390852261000077],
              [51.389158405000046, 10.390809877000038],
              [51.38902997200006, 10.390799281000056],
              [51.388890837000076, 10.390703919000089],
              [51.388858729000049, 10.390597960000036],
              [51.388762404000033, 10.390470810000068],
              [51.388687485000048, 10.39033306400006],
              [51.388666079000075, 10.390163530000052],
              [51.38851296100006, 10.390117405000069],
              [51.388132248000034, 10.389879459000042],
              [51.38833429500005, 10.389665523000076],
              [51.388453475000063, 10.389439259000085],
              [51.38823932300005, 10.389165621000075],
              [51.387938294000037, 10.388807255000074],
              [51.387820563000048, 10.388807255000074],
              [51.387692131000051, 10.388807255000074],
              [51.38753381500004, 10.388817704000076],
              [51.387527496000075, 10.388810440000043],
              [51.387461048000034, 10.388764390000063],
              [51.387360346000037, 10.388775467000073],
              [51.387274724000065, 10.388775467000073],
              [51.387146291000079, 10.388754275000053],
              [51.386633187000086, 10.388035377000051],
              [51.386157295000032, 10.387714150000079],
              [51.385047295000049, 10.387077177000037],
              [51.385007414000086, 10.387055555000074],
              [51.384920123000086, 10.38710130700008],
              [51.384748879000085, 10.387111903000061],
              [51.384524121000084, 10.38708011500006],
              [51.384417094000071, 10.387037731000078],
              [51.384277958000041, 10.387005943000077],
              [51.384224445000086, 10.386974155000075],
              [51.384031796000045, 10.386942367000074],
              [51.383860552000044, 10.386889387000053],
              [51.383742822000045, 10.386825811000051],
              [51.383646497000086, 10.386719851000066],
              [51.383592983000085, 10.386592700000051],
              [51.383518064000043, 10.386465548000047],
              [51.383336118000045, 10.38629601100007],
              [51.383207685000059, 10.386211243000048],
              [51.383089955000059, 10.386158263000084],
              [51.382918711000059, 10.386105283000063],
              [51.382736765000061, 10.386031111000079],
              [51.382619035000062, 10.385935747000076],
              [51.382556330000057, 10.385852974000045],
              [51.38208078100007, 10.385729077000065],
              [51.380101567000054, 10.385394175000044],
              [51.379514663000066, 10.385740805000069],
              [51.379451025000037, 10.38577680700007],
              [51.379311890000054, 10.385819191000053],
              [51.379183457000067, 10.385808595000071],
              [51.378980105000039, 10.385734423000088],
              [51.378755348000084, 10.385628462000057],
              [51.378541293000069, 10.385522502000072],
              [51.378380752000055, 10.385427138000068],
              [51.37816669700004, 10.385331773000075],
              [51.377974048000056, 10.385268197000073],
              [51.377824210000085, 10.385194025000089],
              [51.37772788500007, 10.385077468000077],
              [51.377710940000043, 10.385053981000056],
              [51.377460799000062, 10.384958732000086],
              [51.377146430000039, 10.384806910000066],
              [51.377160640000056, 10.384759587000076],
              [51.377010802000086, 10.384579454000061],
              [51.376796747000071, 10.384462897000049],
              [51.376679017000072, 10.384409917000085],
              [51.376614801000073, 10.384356936000074],
              [51.376507774000061, 10.384282764000091],
              [51.376379341000074, 10.384219187000042],
              [51.376218800000061, 10.384145015000058],
              [51.37604755600006, 10.384049650000065],
              [51.375929826000061, 10.38391190100009],
              [51.375812096000061, 10.383668191000083],
              [51.375751072000071, 10.383504204000076],
              [51.375737177000076, 10.383466865000059],
              [51.375598041000046, 10.383297327000037],
              [51.375448203000076, 10.383159578000061],
              [51.375319770000033, 10.383053617000087],
              [51.375127121000048, 10.382788713000082],
              [51.375030796000033, 10.382608579000077],
              [51.374934472000064, 10.382470829000056],
              [51.374848850000035, 10.382354272000043],
              [51.374763228000063, 10.382248311000069],
              [51.374677606000034, 10.382142349000048],
              [51.374639836000085, 10.382104955000045],
              [51.374635531000081, 10.382074651000039],
              [51.374582907000047, 10.381704162000062],
              [51.374463551000076, 10.381654926000067],
              [51.374420740000062, 10.381485387000055],
              [51.374335119000079, 10.381284060000041],
              [51.374238794000064, 10.381167502000039],
              [51.374217388000034, 10.381008559000065],
              [51.374217388000034, 10.380828424000072],
              [51.374270902000035, 10.380648288000089],
              [51.374345821000077, 10.380499942000085],
              [51.374356524000063, 10.380383383000037],
              [51.374356524000063, 10.380213844000082],
              [51.374356524000063, 10.380110350000052],
              [51.374354758000038, 10.380097915000079],
              [51.374292308000065, 10.380076093000071],
              [51.374249497000051, 10.380054901000051],
              [51.374153172000035, 10.37999132300007],
              [51.374088956000037, 10.379821784000058],
              [51.374088956000037, 10.379631051000047],
              [51.374131767000051, 10.379482704000054],
              [51.374121064000065, 10.37927077900008],
              [51.374121064000065, 10.37909064300004],
              [51.37407825300005, 10.378995277000058],
              [51.374014036000062, 10.378878718000067],
              [51.373939117000077, 10.378730370000085],
              [51.373917712000036, 10.378571426000065],
              [51.373896306000063, 10.378306519000091],
              [51.37390700900005, 10.37779789700005],
              [51.37392841500008, 10.377596568000058],
              [51.373949820000064, 10.377405834000058],
              [51.373971226000037, 10.377268082000057],
              [51.374024739000049, 10.377109137000048],
              [51.374065410000071, 10.377008472000057],
              [51.374176709000039, 10.37668534800008],
              [51.373960523000051, 10.376536935000047],
              [51.373914968000065, 10.376328429000068],
              [51.373855482000067, 10.37613807200006],
              [51.373885604000066, 10.375943540000037],
              [51.374022044000071, 10.375685974000078],
              [51.374105325000073, 10.375602693000076],
              [51.374141017000056, 10.375471823000055],
              [51.374248093000062, 10.375340953000091],
              [51.374295682000081, 10.375233877000085],
              [51.374438449000081, 10.375091109000039],
              [51.374557423000056, 10.374960239000075],
              [51.374735882000039, 10.374841266000089],
              [51.375199877000057, 10.374603320000062],
              [51.376104072000032, 10.374032249000038],
              [51.376342277000049, 10.373547766000058],
              [51.376496683000084, 10.37267595600008],
              [51.376001857000063, 10.371958864000078],
              [51.375735256000041, 10.371510020000073],
              [51.375663872000075, 10.371272074000046],
              [51.375544899000033, 10.371248280000088],
              [51.375378337000086, 10.371164999000086],
              [51.375373284000034, 10.370973806000052],
              [51.375287662000062, 10.370857244000092],
              [51.375287662000062, 10.370772472000056],
              [51.375287662000062, 10.370634717000087],
              [51.375298364000059, 10.370507558000043],
              [51.375842331000058, 10.369903884000053],
              [51.376389607000078, 10.368904511000039],
              [51.376651348000053, 10.368714154000088],
              [51.37685360200004, 10.368428618000053],
              [51.376877397000044, 10.368131186000085],
              [51.376877397000044, 10.367869445000053],
              [51.376794116000042, 10.367441142000075],
              [51.37688929400008, 10.367024736000076],
              [51.377055857000073, 10.36584690300009],
              [51.377020165000033, 10.365418600000055],
              [51.376796747000071, 10.365166852000073],
              [51.376764639000044, 10.36500790100007],
              [51.376700423000045, 10.36481715900004],
              [51.376636206000057, 10.364700595000045],
              [51.376582693000046, 10.364573434000079],
              [51.376539882000088, 10.364425079000057],
              [51.376464962000057, 10.364287322000052],
              [51.376347232000057, 10.364202548000037],
              [51.376208097000074, 10.364149564000058],
              [51.376036853000073, 10.364064790000043],
              [51.375887015000046, 10.364022402000046],
              [51.375705068000059, 10.363927032000049],
              [51.37546960800006, 10.363852854000072],
              [51.375330473000076, 10.363842257000044],
              [51.375127121000048, 10.363842257000044],
              [51.374945174000061, 10.363874048000071],
              [51.374759677000043, 10.363836259000038],
              [51.374719470000059, 10.363830515000075],
              [51.374593114000049, 10.363812464000091],
              [51.374426552000045, 10.36395523200008],
              [51.374259990000041, 10.364157486000067],
              [51.374248093000062, 10.36447871300004],
              [51.373879276000082, 10.364716659000067],
              [51.373665125000059, 10.365216346000068],
              [51.373724611000057, 10.365763622000088],
              [51.373736508000036, 10.366180028000088],
              [51.373748406000061, 10.366929558000038],
              [51.373772200000076, 10.367536321000046],
              [51.373581843000068, 10.367928932000041],
              [51.373382575000051, 10.368133922000084],
              [51.373264845000051, 10.368197502000044],
              [51.373157818000038, 10.36828227500007],
              [51.372808519000046, 10.368440516000078],
              [51.372701443000039, 10.368559489000063],
              [51.372697600000038, 10.36889687900009],
              [51.372462140000039, 10.368939265000051],
              [51.372344524000084, 10.369082970000079],
              [51.37217796200008, 10.369201943000064],
              [51.371868632000087, 10.369261430000051],
              [51.371630685000071, 10.36930901900007],
              [51.371404637000069, 10.369463684000038],
              [51.37135975800004, 10.369130004000056],
              [51.371130999000059, 10.369249532000083],
              [51.371012026000074, 10.369487479000043],
              [51.371202382000035, 10.369880090000038],
              [51.371000128000048, 10.370022857000038],
              [51.370299143000068, 10.370192973000087],
              [51.370289485000058, 10.370179065000059],
              [51.370182458000045, 10.370020117000081],
              [51.370118241000057, 10.369998923000082],
              [51.369989808000071, 10.369956537000064],
              [51.369925592000072, 10.369914151000046],
              [51.369501068000034, 10.369737322000049],
              [51.369275019000042, 10.369749219000084],
              [51.368989484000053, 10.369820603000051],
              [51.368668257000081, 10.36996337100004],
              [51.368263748000061, 10.370177522000063],
              [51.36789493200007, 10.370344085000056],
              [51.367002634000073, 10.370582031000083],
              [51.365991363000035, 10.370308393000073],
              [51.365348908000044, 10.370593928000062],
              [51.364884914000072, 10.370474955000077],
              [51.364330324000036, 10.370442177000086],
              [51.363064626000039, 10.37078428500007],
              [51.36185110100007, 10.370558236000079],
              [51.36064947300008, 10.370403571000054],
              [51.359904868000058, 10.370056986000066],
              [51.359293180000066, 10.369463684000038],
              [51.359126618000062, 10.369630246000042],
              [51.35890056900007, 10.370296495000048],
              [51.358329038000079, 10.370878875000074],
              [51.356663876000084, 10.371153101000061],
              [51.356009524000058, 10.370879463000051],
              [51.355438453000033, 10.370189420000088],
              [51.355382149000036, 10.368949558000054],
              [51.35420113400005, 10.369166251000081],
              [51.352880533000075, 10.37014183000008],
              [51.351833570000053, 10.37023700900005],
              [51.351524812000036, 10.370885176000058],
              [51.349894309000035, 10.373032875000092],
              [51.348526119000041, 10.374222606000046],
              [51.347520059000033, 10.375015647000055],
              [51.345658869000033, 10.375507515000038],
              [51.344182080000053, 10.376332145000049],
              [51.342220547000068, 10.376697245000059],
              [51.340614411000047, 10.377042267000093],
              [51.339653140000053, 10.377343049000046],
              [51.337616290000085, 10.376768629000082],
              [51.33533200800008, 10.376435505000074],
              [51.334570580000047, 10.376221353000062],
              [51.333737769000038, 10.376054791000058],
              [51.332709823000073, 10.375831324000046],
              [51.332369579000044, 10.375757358000044],
              [51.331037081000034, 10.375364747000049],
              [51.329224539000052, 10.375108990000058],
              [51.329097820000072, 10.375091109000039],
              [51.328324495000061, 10.374948342000039],
              [51.327741527000057, 10.375055417000056],
              [51.325754677000077, 10.374948342000039],
              [51.324886174000085, 10.374793677000071],
              [51.323755930000061, 10.374829369000054],
              [51.322007026000051, 10.374841266000089],
              [51.32043658200007, 10.374769882000066],
              [51.319995766000034, 10.375297256000067],
              [51.318866138000033, 10.375507515000038],
              [51.317747791000045, 10.375317158000087],
              [51.315249357000084, 10.374698498000043],
              [51.31453551900006, 10.375019725000072],
              [51.313369583000053, 10.375031623000041],
              [51.310930635000034, 10.374817471000085],
              [51.309205526000085, 10.374412963000054],
              [51.30821805000005, 10.374210709000067],
              [51.307492314000058, 10.374282092000044],
              [51.305705566000086, 10.37445720900007],
              [51.305434080000055, 10.374329682000052],
              [51.305184237000049, 10.374329682000052],
              [51.304886804000034, 10.374305887000048],
              [51.304506090000075, 10.374293990000069],
              [51.304145790000064, 10.374256966000075],
              [51.303933622000045, 10.374256966000075],
              [51.303566203000059, 10.374127428000065],
              [51.303066517000047, 10.374008454000091],
              [51.302531138000063, 10.373841892000087],
              [51.301936273000081, 10.373687227000062],
              [51.301722121000068, 10.373627741000064],
              [51.301496072000077, 10.373413589000052],
              [51.301103461000082, 10.373104259000058],
              [51.300829823000072, 10.373020978000056],
              [51.300330136000071, 10.373020978000056],
              [51.299881694000078, 10.373153998000078],
              [51.298224313000048, 10.372973389000038],
              [51.297391502000039, 10.372783032000086],
              [51.296939404000057, 10.372866313000088],
              [51.295380858000044, 10.372759237000082],
              [51.293667646000074, 10.372473702000093],
              [51.292957809000086, 10.372525022000048],
              [51.29253740200005, 10.372319037000068],
              [51.292275661000076, 10.372414216000038],
              [51.291978228000062, 10.372223859000087],
              [51.291680796000037, 10.372164372000043],
              [51.291466644000081, 10.372140578000085],
              [51.291240595000033, 10.372021605000043],
              [51.290967791000071, 10.372039652000069],
              [51.290920643000049, 10.372039652000069],
              [51.290755623000052, 10.372109673000068],
              [51.290590603000055, 10.372203034000052],
              [51.290590603000055, 10.372366415000045],
              [51.29049630600008, 10.372529797000084],
              [51.290895574000047, 10.372890108000092],
              [51.290776600000072, 10.373068567000075],
              [51.290732049000042, 10.373276682000039],
              [51.29051988100008, 10.373486743000058],
              [51.290354861000083, 10.373673464000092],
              [51.29000912500004, 10.373638483000093],
              [51.289826314000038, 10.37335405500005],
              [51.289515486000084, 10.373544460000062],
              [51.289253745000053, 10.373592049000081],
              [51.288849237000079, 10.373734816000081],
              [51.288551804000065, 10.373829995000051],
              [51.287992631000066, 10.374484347000077],
              [51.287266636000084, 10.374933828000053],
              [51.286842299000057, 10.375167228000066],
              [51.286441537000087, 10.375353948000054],
              [51.286158647000036, 10.375493988000073],
              [51.285875756000053, 10.375517328000058],
              [51.285663587000045, 10.375470648000089],
              [51.285522142000048, 10.375400628000079],
              [51.285363327000084, 10.375174390000041],
              [51.285137278000036, 10.375210082000081],
              [51.284958818000064, 10.375305261000051],
              [51.284744667000041, 10.375495618000059],
              [51.284649488000071, 10.375721666000061],
              [51.284494823000045, 10.376149969000039],
              [51.28424498000004, 10.376459299000089],
              [51.284209288000056, 10.376804321000066],
              [51.283848371000033, 10.377291160000084],
              [51.283447609000063, 10.377431199000057],
              [51.283046847000037, 10.377431199000057],
              [51.282528214000081, 10.377407859000073],
              [51.282198174000087, 10.377314500000068],
              [51.28179741200006, 10.377221141000064],
              [51.281538096000077, 10.377174461000038],
              [51.281160908000061, 10.377151121000054],
              [51.280689423000069, 10.377151121000054],
              [51.280414048000068, 10.377173138000046],
              [51.278367711000044, 10.376923294000051],
              [51.276154813000062, 10.376435505000074],
              [51.275262515000065, 10.376530683000055],
              [51.273751534000041, 10.37660731200009],
              [51.273664300000064, 10.376520945000038],
              [51.273570003000032, 10.376520945000038],
              [51.273428558000035, 10.376520945000038],
              [51.273216390000073, 10.376497605000054],
              [51.272980647000054, 10.376450925000086],
              [51.272768479000035, 10.376287546000071],
              [51.272485588000052, 10.376007467000079],
              [51.272344143000055, 10.37586742700006],
              [51.272222025000076, 10.375779497000053],
              [51.272084826000082, 10.375680707000072],
              [51.271966955000039, 10.375704047000056],
              [51.271896232000074, 10.375820747000091],
              [51.271895689000075, 10.375825314000053],
              [51.271849084000053, 10.376217526000062],
              [51.271707638000066, 10.376567625000064],
              [51.271519045000048, 10.37698774200004],
              [51.271401173000072, 10.377291160000084],
              [51.271110355000076, 10.377815592000047],
              [51.270432209000035, 10.37830338200007],
              [51.270075290000079, 10.37854132800004],
              [51.269944419000069, 10.378779274000067],
              [51.269765960000086, 10.379005323000058],
              [51.26965888400008, 10.37936224200007],
              [51.269528014000059, 10.379612085000076],
              [51.269339244000037, 10.379776935000052],
              [51.26869520200006, 10.379576393000093],
              [51.268623002000083, 10.379672289000041],
              [51.268595839000056, 10.38009192800007],
              [51.268454394000059, 10.380115268000054],
              [51.268312948000073, 10.38016194700009],
              [51.268124132000082, 10.380278334000081],
              [51.268100337000078, 10.380492486000037],
              [51.268218651000041, 10.380745437000087],
              [51.268360097000084, 10.38100217200008],
              [51.268383671000038, 10.381165549000059],
              [51.26827879700005, 10.381301502000042],
              [51.26827879700005, 10.381741703000046],
              [51.268159824000065, 10.38203913500007],
              [51.268017056000076, 10.382407952000051],
              [51.267701622000061, 10.38273294600009],
              [51.267393553000034, 10.382822652000073],
              [51.26711066200005, 10.382892671000093],
              [51.266992791000064, 10.382916010000088],
              [51.26670990000008, 10.383032707000041],
              [51.266615603000048, 10.383079386000077],
              [51.26642700900004, 10.383242762000066],
              [51.266285564000043, 10.383476155000039],
              [51.266042103000075, 10.383704758000079],
              [51.265839849000088, 10.383942704000049],
              [51.265875541000071, 10.384275829000046],
              [51.266172974000085, 10.384537569000088],
              [51.266458509000074, 10.384918283000047],
              [51.267067550000036, 10.385512419000065],
              [51.267172347000042, 10.385798684000065],
              [51.267267526000069, 10.386108014000058],
              [51.267445985000052, 10.386274576000062],
              [51.267731521000087, 10.38670287900004],
              [51.267814802000032, 10.386964620000072],
              [51.268028953000055, 10.387345333000042],
              [51.268136029000061, 10.387737944000037],
              [51.268124132000082, 10.388094864000038],
              [51.268312287000072, 10.38845884300008],
              [51.26814792600004, 10.388820599000042],
              [51.268052748000059, 10.389415464000081],
              [51.268219310000063, 10.38968910300008],
              [51.268017056000076, 10.390260173000058],
              [51.267838596000047, 10.390795552000043],
              [51.267993261000072, 10.391104882000093],
              [51.267993261000072, 10.391330931000084],
              [51.267791007000085, 10.391664055000092],
              [51.267600650000077, 10.392020974000047],
              [51.267410293000069, 10.39236599600008],
              [51.267160450000063, 10.392699121000078],
              [51.267172347000042, 10.392984656000067],
              [51.267291320000083, 10.393936440000061],
              [51.267582147000041, 10.394258756000056],
              [51.267719623000062, 10.394864430000041],
              [51.267755315000045, 10.395423604000086],
              [51.267747167000039, 10.395985804000077],
              [51.267817889000071, 10.396312542000089],
              [51.268006483000079, 10.396639279000055],
              [51.268147929000065, 10.397012693000079],
              [51.268336523000073, 10.397246077000091],
              [51.268407245000049, 10.39736276800005],
              [51.26847796800007, 10.397712843000079],
              [51.268619413000067, 10.398202947000073],
              [51.268588127000044, 10.398528800000065],
              [51.268492948000073, 10.399076076000085],
              [51.268766586000083, 10.399492482000085],
              [51.268973027000072, 10.399929973000042],
              [51.269114472000069, 10.40079348200004],
              [51.269232344000045, 10.401376933000051],
              [51.269279492000067, 10.401867031000052],
              [51.269279492000067, 10.402287114000046],
              [51.269303066000077, 10.402543832000049],
              [51.269350215000088, 10.402987252000059],
              [51.26962319200004, 10.403121160000069],
              [51.269703828000047, 10.402823887000068],
              [51.269774551000069, 10.402730535000046],
              [51.269891580000035, 10.402591508000057],
              [51.270967588000076, 10.40297839200008],
              [51.271776604000081, 10.40389448500008],
              [51.272216805000085, 10.404334685000038],
              [51.272371470000053, 10.404679707000071],
              [51.272391292000066, 10.405157668000072],
              [51.272311983000066, 10.405393545000038],
              [51.272109729000078, 10.405714772000067],
              [51.271990756000037, 10.406250151000052],
              [51.272026448000076, 10.406845016000091],
              [51.272359572000084, 10.407332806000056],
              [51.272442853000086, 10.40764213600005],
              [51.272395264000068, 10.408522536000078],
              [51.272204907000059, 10.409283964000053],
              [51.272002653000072, 10.409914521000076],
              [51.271424748000072, 10.410782010000048],
              [51.270051495000075, 10.412151214000062],
              [51.268759757000055, 10.413211367000088],
              [51.267605721000052, 10.414025878000075],
              [51.267252108000037, 10.41428258500008],
              [51.266804197000056, 10.414609304000066],
              [51.266521306000072, 10.414749326000049],
              [51.26604982200007, 10.414889348000088],
              [51.265790505000041, 10.415099381000061],
              [51.265649060000044, 10.415426098000069],
              [51.265435341000057, 10.415875071000073],
              [51.265102216000059, 10.41649373100006],
              [51.264352686000052, 10.417231364000088],
              [51.26355095200006, 10.418273193000061],
              [51.263352801000053, 10.418456094000078],
              [51.261523572000044, 10.419622369000081],
              [51.257876218000035, 10.420922179000058],
              [51.254049620000046, 10.422037875000058],
              [51.249469157000078, 10.423608319000039],
              [51.243139791000033, 10.424274568000044],
              [51.238773480000077, 10.424453028000073],
              [51.233740920000059, 10.424583898000037],
              [51.230052756000077, 10.424821844000064],
              [51.225722137000048, 10.424643385000081],
              [51.219678306000048, 10.424548206000054],
              [51.211290706000057, 10.424084211000093],
              [51.205591896000044, 10.423679703000062],
              [51.198858022000081, 10.423072940000054],
              [51.194372738000084, 10.422680329000059],
              [51.188745313000084, 10.422287718000064],
              [51.181511751000073, 10.421407318000092],
              [51.180155458000058, 10.421264550000046],
              [51.178085327000076, 10.421026604000076],
              [51.171410939000054, 10.420193793000067],
              [51.162714009000069, 10.419111138000062],
              [51.159680196000068, 10.418766116000086],
              [51.157621963000054, 10.418409197000074],
              [51.152006435000033, 10.417790537000087],
              [51.146704870000065, 10.416661670000053],
              [51.136194916000079, 10.415101746000062],
              [51.126712764000047, 10.413566994000064],
              [51.117915089000064, 10.412088176000054],
              [51.111884798000062, 10.410884613000064],
              [51.104905004000045, 10.409700370000053],
              [51.100479207000035, 10.408796174000088],
              [51.092480875000035, 10.407033182000077],
              [51.087285095000084, 10.405905129000075],
              [51.083046386000035, 10.404481582000074],
              [51.076303883000037, 10.403371003000075],
              [51.070735944000035, 10.402074197000047],
              [51.064469197000051, 10.400004195000065],
              [51.058244380000076, 10.398078417000079],
              [51.054645657000037, 10.397259958000063],
              [51.054363441000078, 10.39705220400009],
              [51.051190173000066, 10.396052510000061],
              [51.048748320000072, 10.395283238000047],
              [51.039612594000062, 10.392330304000041],
              [51.031775734000064, 10.389173157000073],
              [51.029557159000035, 10.388279385000089],
              [51.022658934000049, 10.385536943000091],
              [51.020752334000065, 10.384542184000054],
              [51.020461409000063, 10.384370740000065],
              [51.016484233000085, 10.382705384000076],
              [51.011940705000086, 10.38060386300009],
              [51.009251471000084, 10.379255011000055],
              [50.997091626000042, 10.373544460000062],
              [50.995429475000037, 10.37253725700009],
              [50.985384678000059, 10.367655294000087],
              [50.977127948000032, 10.363419853000039],
              [50.975829189000081, 10.36247211500006],
              [50.971072220000053, 10.360076710000044],
              [50.962446674000034, 10.355853167000078],
              [50.947360891000073, 10.348048535000089],
              [50.940769784000054, 10.344515035000086],
              [50.936060763000057, 10.341872308000063],
              [50.934523699000067, 10.340874460000066],
              [50.933871594000038, 10.340632901000049],
              [50.933748035000065, 10.340636571000061],
              [50.93082363700006, 10.338947097000073],
              [50.927242548000038, 10.336817479000047],
              [50.922412242000064, 10.333759872000087],
              [50.91813736000006, 10.33066862700008],
              [50.913477366000052, 10.327394813000069],
              [50.913263215000086, 10.327144970000063],
              [50.911507018000066, 10.32571054400006],
              [50.910116392000077, 10.324552090000054],
              [50.908416739000074, 10.323175054000046],
              [50.905149253000047, 10.32024453300005],
              [50.902662716000066, 10.318043532000047],
              [50.902270105000071, 10.317520050000041],
              [50.901651321000088, 10.316847808000091],
              [50.901449255000045, 10.316603246000057],
              [50.901151758000083, 10.316163757000083],
              [50.900104795000061, 10.314938335000079],
              [50.899307676000035, 10.313974653000059],
              [50.898593838000068, 10.312190058000056],
              [50.898403481000059, 10.311845036000079],
              [50.898332097000036, 10.31139293800004],
              [50.898130527000035, 10.310256850000087],
              [50.898338893000073, 10.308241066000051],
              [50.898417481000081, 10.307480786000042],
              [50.898784194000086, 10.304813728000056],
              [50.898581940000042, 10.301922683000043],
              [50.89826071300007, 10.30059018500009],
              [50.897755278000034, 10.299655215000087],
              [50.897689058000083, 10.299152448000086],
              [50.897622838000075, 10.298452944000076],
              [50.897578691000035, 10.297819018000041],
              [50.897556618000067, 10.297119512000052],
              [50.897512471000084, 10.293731257000047],
              [50.897556618000067, 10.293119181000066],
              [50.89760076400006, 10.292310365000048],
              [50.897622838000075, 10.29174200600005],
              [50.897628637000082, 10.291592692000052],
              [50.897489090000079, 10.289588817000038],
              [50.897582567000086, 10.28891892900009],
              [50.897380030000079, 10.287337191000063],
              [50.897332723000034, 10.283541347000039],
              [50.897237545000053, 10.280697891000045],
              [50.896894415000077, 10.279532974000062],
              [50.896828195000069, 10.278439931000037],
              [50.896761974000071, 10.277937130000055],
              [50.896761974000071, 10.277281301000073],
              [50.896761974000071, 10.276756637000062],
              [50.896761974000071, 10.276188250000075],
              [50.896761974000071, 10.275801998000077],
              [50.896308443000066, 10.272240709000073],
              [50.896165992000078, 10.271881591000067],
              [50.896011478000048, 10.271444364000047],
              [50.895923185000072, 10.271160165000083],
              [50.89583489100005, 10.270744798000067],
              [50.895481716000063, 10.269717309000043],
              [50.895260982000082, 10.268646093000086],
              [50.895238909000057, 10.266853437000066],
              [50.895062321000069, 10.265563594000071],
              [50.895040248000043, 10.264929601000063],
              [50.894974028000036, 10.264426778000086],
              [50.89488573400007, 10.263945816000046],
              [50.89488573400007, 10.263464853000073],
              [50.894965159000037, 10.262947112000063],
              [50.894929468000043, 10.262506912000049],
              [50.89489377600006, 10.262019122000083],
              [50.894953262000058, 10.261650306000092],
              [50.894953262000058, 10.261233900000093],
              [50.895072235000043, 10.260662829000069],
              [50.895119824000062, 10.26019883500004],
              [50.895226900000068, 10.259734840000078],
              [50.895405360000041, 10.259235153000077],
              [50.895523767000043, 10.258138603000077],
              [50.895572633000086, 10.257716839000068],
              [50.895305129000064, 10.25754020800008],
              [50.895084395000083, 10.257124823000083],
              [50.895084395000083, 10.256731300000069],
              [50.895150615000034, 10.256447088000073],
              [50.895238909000057, 10.256250326000043],
              [50.895415496000055, 10.255966114000046],
              [50.89543756900008, 10.255660039000077],
              [50.895525863000046, 10.255397689000063],
              [50.895768671000042, 10.254982301000041],
              [50.895923184000083, 10.254851126000062],
              [50.895969807000085, 10.254814184000054],
              [50.896496394000053, 10.252888431000088],
              [50.896557846000064, 10.251595043000066],
              [50.896519167000065, 10.251528],
              [50.896254286000044, 10.251112607000039],
              [50.896055625000088, 10.250872116000039],
              [50.89598940500008, 10.250675350000051],
              [50.89598940500008, 10.250347407000049],
              [50.89598940500008, 10.250063190000049],
              [50.896143919000053, 10.249691521000045],
              [50.896342579000077, 10.249079358000074],
              [50.89623817100005, 10.247778048000043],
              [50.896261966000054, 10.246076733000052],
              [50.896563314000048, 10.245165865000047],
              [50.896695754000064, 10.244663010000068],
              [50.896761974000071, 10.24418201800006],
              [50.896828195000069, 10.243613571000083],
              [50.896872341000062, 10.243023261000076],
              [50.896894415000077, 10.242739037000092],
              [50.896916488000045, 10.241580274000057],
              [50.896892523000076, 10.238533842000038],
              [50.896904420000055, 10.237201344000084],
              [50.896982708000053, 10.234999297000059],
              [50.896975804000078, 10.233489385000041],
              [50.897115149000058, 10.232156972000041],
              [50.897159296000041, 10.231741553000063],
              [50.897236432000057, 10.231554786000061],
              [50.897225516000049, 10.231347998000047],
              [50.897227280000038, 10.231341758000042],
              [50.897245092000048, 10.231278748000079],
              [50.897380030000079, 10.230801393000093],
              [50.897534544000052, 10.230211058000066],
              [50.897711131000051, 10.229839365000089],
              [50.897843572000056, 10.229423943000086],
              [50.897843572000056, 10.229074113000081],
              [50.897976012000072, 10.228571233000082],
              [50.898062893000088, 10.228270029000043],
              [50.898487398000043, 10.22718412200004],
              [50.899498033000043, 10.225672855000084],
              [50.899783568000032, 10.224673482000071],
              [50.899803328000075, 10.223601453000072],
              [50.899608803000035, 10.220759314000077],
              [50.899866849000034, 10.217773045000058],
              [50.900521201000061, 10.217499407000048],
              [50.900540300000046, 10.216875708000089],
              [50.900470308000081, 10.216633049000052],
              [50.900492382000039, 10.216086418000089],
              [50.900514455000064, 10.215824035000082],
              [50.900602749000086, 10.21457771200005],
              [50.900668969000037, 10.21427159600006],
              [50.900823483000067, 10.213878019000049],
              [50.900955924000073, 10.213528172000053],
              [50.901044217000049, 10.213265787000068],
              [50.901176658000054, 10.212981536000086],
              [50.90130909800007, 10.212653554000042],
              [50.901397392000035, 10.212216243000057],
              [50.90141946500006, 10.211778932000072],
              [50.901353245000053, 10.211035503000062],
              [50.901176658000054, 10.209701697000071],
              [50.901198731000079, 10.209373712000058],
              [50.901287025000045, 10.209023860000059],
              [50.901441539000075, 10.208717739000065],
              [50.901640199000042, 10.208477216000063],
              [50.901706420000039, 10.208411618000071],
              [50.902103741000076, 10.208236692000071],
              [50.902230014000054, 10.208145721000051],
              [50.902478054000085, 10.207752589000052],
              [50.902606759000037, 10.207280860000083],
              [50.902589356000078, 10.206815411000036],
              [50.902589356000078, 10.206443691000061],
              [50.902589356000078, 10.206050104000042],
              [50.902655577000075, 10.205722114000082],
              [50.902677650000044, 10.205459722000057],
              [50.902743870000052, 10.205262928000082],
              [50.902832164000074, 10.205088],
              [50.902964605000079, 10.204934938000065],
              [50.903295706000051, 10.204606947000059],
              [50.903560587000072, 10.204344554000045],
              [50.903702158000044, 10.204064071000062],
              [50.903734298000074, 10.203586501000075],
              [50.903648880000048, 10.203426178000086],
              [50.90362680700008, 10.203032587000052],
              [50.903781321000054, 10.202136073000077],
              [50.904377303000047, 10.200824096000076],
              [50.90468633100005, 10.200343037000039],
              [50.904840845000081, 10.200015041000086],
              [50.904862918000049, 10.199643312000092],
              [50.904862918000049, 10.198965453000085],
              [50.904862918000049, 10.198200127000064],
              [50.904862918000049, 10.197915862000059],
              [50.904840845000081, 10.197565997000083],
              [50.904818772000056, 10.197063066000055],
              [50.904673360000061, 10.196476869000037],
              [50.904601977000084, 10.195870106000086],
              [50.904487670000037, 10.19527000100004],
              [50.904399377000061, 10.194767067000043],
              [50.904443523000054, 10.193870529000037],
              [50.904752551000058, 10.193083323000053],
              [50.904796698000041, 10.192580385000042],
              [50.904840845000081, 10.192121180000072],
              [50.904840845000081, 10.191661974000056],
              [50.904951212000071, 10.191049698000086],
              [50.90497328500004, 10.190393688000086],
              [50.90497328500004, 10.18984701100004],
              [50.904929139000046, 10.188753655000085],
              [50.904941714000074, 10.188303273000088],
              [50.905006485000058, 10.18598344600008],
              [50.904958896000039, 10.185233916000072],
              [50.904443523000054, 10.18374603500007],
              [50.904421450000086, 10.182368379000081],
              [50.904421450000086, 10.181712350000055],
              [50.904613874000063, 10.181176935000053],
              [50.904732847000048, 10.180582069000081],
              [50.904661463000082, 10.179868231000057],
              [50.904423517000055, 10.17953510600006],
              [50.904200716000048, 10.179066353000053],
              [50.903979982000067, 10.178104167000072],
              [50.903737174000071, 10.176726486000064],
              [50.903604734000055, 10.176267258000053],
              [50.903383999000084, 10.175720557000091],
              [50.903119118000063, 10.174933306000071],
              [50.902545209000039, 10.17355561100004],
              [50.901794713000072, 10.171718675000079],
              [50.901022144000081, 10.170362835000049],
              [50.900382014000058, 10.168875778000086],
              [50.900227501000074, 10.168569618000049],
              [50.900095060000069, 10.168219721000071],
              [50.900006766000047, 10.167826086000048],
              [50.900006766000047, 10.16747618800008],
              [50.900028840000061, 10.167082553000057],
              [50.900117133000037, 10.166732654000043],
              [50.900359941000033, 10.16644836100005],
              [50.900492382000039, 10.166251542000055],
              [50.900668969000037, 10.166032855000083],
              [50.900779336000085, 10.165836036000087],
              [50.900955923000083, 10.16557361100007],
              [50.901154584000039, 10.165354923000052],
              [50.901264951000087, 10.165158104000056],
              [50.90130909800007, 10.164873810000074],
              [50.90130909800007, 10.16450203900007],
              [50.90130909800007, 10.164217744000041],
              [50.901287025000045, 10.16375849800005],
              [50.901220804000047, 10.162511968000047],
              [50.901154584000039, 10.162183933000051],
              [50.901066290000074, 10.161746553000057],
              [50.900955923000083, 10.161221696000041],
              [50.900889703000075, 10.160893660000056],
              [50.900624822000054, 10.160128240000063],
              [50.900028840000061, 10.158794220000061],
              [50.899477004000062, 10.157175892000055],
              [50.899022140000056, 10.156014134000088],
              [50.89905783200004, 10.155419269000049],
              [50.899069730000065, 10.155157528000075],
              [50.899212123000041, 10.155054557000085],
              [50.899212123000041, 10.154770254000084],
              [50.899234197000055, 10.154529689000071],
              [50.899336781000045, 10.154306089000045],
              [50.899317014000076, 10.154058023000061],
              [50.899365645000046, 10.151937996000072],
              [50.89867711900007, 10.148328475000085],
              [50.897772923000048, 10.145901425000091],
              [50.897218901000087, 10.14509051400006],
              [50.896606988000087, 10.143426786000077],
              [50.896885445000066, 10.140663069000084],
              [50.896975804000078, 10.138584583000068],
              [50.895912817000067, 10.135844601000088],
              [50.894786700000054, 10.134551396000063],
              [50.894513062000044, 10.133897044000037],
              [50.894846186000052, 10.133516331000067],
              [50.895262592000051, 10.13300474600004],
              [50.894643932000065, 10.131398610000076],
              [50.893894402000058, 10.130553902000088],
              [50.893140996000056, 10.130329068000037],
              [50.892921325000032, 10.130027695000081],
              [50.892769246000057, 10.129810037000084],
              [50.892617167000083, 10.129475178000064],
              [50.892532678000066, 10.128989632000071],
              [50.892431292000083, 10.128437113000075],
              [50.891941258000088, 10.126972096000088],
              [50.891978936000044, 10.12606861800009],
              [50.891752887000052, 10.124890784000058],
              [50.891693401000055, 10.124117460000093],
              [50.891633914000067, 10.123629670000071],
              [50.891598222000084, 10.123141881000038],
              [50.891467352000063, 10.122903935000068],
              [50.891351387000043, 10.122487108000087],
              [50.89125000100006, 10.122202471000037],
              [50.890979562000041, 10.121702307000078],
              [50.890717822000056, 10.121297798000057],
              [50.890539362000084, 10.120917085000087],
              [50.890301416000057, 10.120488782000052],
              [50.890051573000051, 10.120108068000093],
              [50.889885010000057, 10.119608381000091],
              [50.889563783000085, 10.11910869400009],
              [50.889456707000079, 10.118763672000057],
              [50.889397221000081, 10.118275883000081],
              [50.889278248000039, 10.117716710000082],
              [50.889111685000046, 10.117193228000076],
              [50.888899671000047, 10.117006081000056],
              [50.888638619000062, 10.11675028600007],
              [50.888587926000071, 10.116214489000072],
              [50.88835025800006, 10.116146265000054],
              [50.888255079000032, 10.115777449000063],
              [50.88820749000007, 10.115432427000087],
              [50.887981441000079, 10.114433053000084],
              [50.887910058000045, 10.113933367000072],
              [50.887835116000076, 10.112555969000084],
              [50.887615446000041, 10.10962578200008],
              [50.887529344000086, 10.107294670000044],
              [50.887136733000034, 10.104106193000064],
              [50.887041554000064, 10.103059230000042],
              [50.88719300300005, 10.102542989000085],
              [50.887159207000082, 10.102208101000087],
              [50.887159207000082, 10.102075080000077],
              [50.887179386000071, 10.101726838000047],
              [50.887077246000047, 10.101108072000045],
              [50.886803608000037, 10.100168185000086],
              [50.886482381000064, 10.099501936000081],
              [50.886012152000035, 10.098228622000079],
              [50.885962527000061, 10.097146762000079],
              [50.885838465000063, 10.095769844000074],
              [50.885888090000037, 10.094860091000044],
              [50.885962527000061, 10.093827396000052],
              [50.885912902000086, 10.093114343000082],
              [50.886054078000086, 10.092089915000088],
              [50.886173051000071, 10.091364179000038],
              [50.886589457000071, 10.09084069700009],
              [50.886791711000058, 10.090317216000074],
              [50.887255706000076, 10.089258356000073],
              [50.887476089000074, 10.08824586500009],
              [50.887749027000041, 10.08728691400006],
              [50.887848277000046, 10.08615584000006],
              [50.887823464000064, 10.084582164000039],
              [50.888005236000083, 10.083726109000054],
              [50.888052825000045, 10.083155038000086],
              [50.888112312000032, 10.082810017000043],
              [50.888088517000085, 10.082429303000083],
              [50.887969544000043, 10.082143767000048],
              [50.887838674000079, 10.081774951000057],
              [50.887779187000035, 10.081275264000055],
              [50.887898160000077, 10.080846961000077],
              [50.88779108500006, 10.080466247000061],
              [50.887547855000037, 10.080221948000087],
              [50.887530957000081, 10.079887037000049],
              [50.887530957000081, 10.079518634000067],
              [50.887315192000074, 10.079050468000048],
              [50.887374679000061, 10.07862216500007],
              [50.887463366000077, 10.077961290000076],
              [50.887547855000037, 10.077609631000087],
              [50.887636420000035, 10.07724207800004],
              [50.887600152000061, 10.076516955000045],
              [50.887873089000038, 10.07573009500004],
              [50.888326463000055, 10.075267125000039],
              [50.888802355000053, 10.074553287000072],
              [50.888964839000039, 10.074156368000047],
              [50.889183062000086, 10.073098221000066],
              [50.889158249000047, 10.072385121000082],
              [50.889237776000073, 10.071562160000042],
              [50.888945123000042, 10.070579587000054],
              [50.888778561000038, 10.069639700000039],
              [50.88886184200004, 10.067985975000056],
              [50.889262589000055, 10.067308106000041],
              [50.889411464000034, 10.06706220500007],
              [50.889595845000088, 10.066818567000041],
              [50.889742243000057, 10.066475017000073],
              [50.889789832000076, 10.06616568700008],
              [50.889766037000072, 10.06574928100008],
              [50.889706551000074, 10.065011648000052],
              [50.889600451000035, 10.064120948000038],
              [50.889766037000072, 10.062786852000045],
              [50.889777205000087, 10.061899842000059],
              [50.889960400000064, 10.061100757000077],
              [50.890241929000069, 10.059753039000043],
              [50.88995639400008, 10.05892022800009],
              [50.889676965000035, 10.058200782000085],
              [50.889539988000081, 10.05758773000008],
              [50.889575680000064, 10.057218913000042],
              [50.889587578000032, 10.056921481000074],
              [50.88968275600007, 10.056516972000054],
              [50.889861216000043, 10.056267129000048],
              [50.890075367000065, 10.056100567000044],
              [50.89037280000008, 10.055993491000038],
              [50.890775316000088, 10.055856234000089],
              [50.890898803000084, 10.055825638000044],
              [50.89102536300004, 10.055666601000041],
              [50.891752663000034, 10.055455633000065],
              [50.892062217000046, 10.055113090000077],
              [50.892264471000033, 10.054756171000065],
              [50.892454828000041, 10.054530123000063],
              [50.892561904000047, 10.053899565000052],
              [50.892645185000049, 10.053554544000065],
              [50.892716569000072, 10.053209522000088],
              [50.892752261000055, 10.052840705000051],
              [50.892764158000034, 10.052329121000071],
              [50.892718553000066, 10.051719739000077],
              [50.892883131000076, 10.051139391000049],
              [50.892835542000057, 10.050592115000086],
              [50.892799850000074, 10.049735509000072],
              [50.892573801000083, 10.048284037000087],
              [50.892583371000057, 10.047047277000047],
              [50.892787953000038, 10.045214533000092],
              [50.89278614300008, 10.043982507000067],
              [50.892847439000036, 10.04320388800005],
              [50.89290692600008, 10.042835072000059],
              [50.892938223000044, 10.042558970000073],
              [50.893191688000059, 10.041972806000047],
              [50.89324238100005, 10.041738340000052],
              [50.893276177000075, 10.041319650000048],
              [50.893360665000046, 10.041001445000063],
              [50.893442304000075, 10.04043181600008],
              [50.893629588000067, 10.040278223000087],
              [50.893668353000066, 10.039408648000062],
              [50.894101025000055, 10.039089747000048],
              [50.894290039000055, 10.038941482000041],
              [50.894370294000055, 10.038444966000043],
              [50.89462013800005, 10.038147533000085],
              [50.894977057000062, 10.037921485000084],
              [50.895393462000072, 10.037790614000073],
              [50.895810833000041, 10.037836130000073],
              [50.895991751000054, 10.037656818000073],
              [50.896358963000068, 10.037171545000092],
              [50.896940112000038, 10.036541397000065],
              [50.897425900000087, 10.035818961000075],
              [50.897773275000077, 10.035474665000038],
              [50.897723650000046, 10.035081185000081],
              [50.897178058000065, 10.034887672000082],
              [50.896678371000064, 10.034078655000087],
              [50.896654577000049, 10.033448098000065],
              [50.896654577000049, 10.031984729000044],
              [50.897028900000066, 10.030715976000067],
              [50.89702339300004, 10.029629063000073],
              [50.897128150000071, 10.028625573000056],
              [50.897149427000045, 10.028446316000043],
              [50.897145751000039, 10.028440487000069],
              [50.897078161000081, 10.028189263000058],
              [50.897130469000047, 10.028022927000052],
              [50.897285134000072, 10.02793964600005],
              [50.89747549100008, 10.027868262000084],
              [50.897772923000048, 10.027820673000065],
              [50.89827789800006, 10.027753807000067],
              [50.898396182000056, 10.027519331000065],
              [50.898446875000047, 10.027335099000084],
              [50.89846377300006, 10.027217861000054],
              [50.898514466000051, 10.026966636000054],
              [50.898565159000043, 10.026715411000055],
              [50.898565159000043, 10.026480933000073],
              [50.898700340000062, 10.025961733000088],
              [50.898700340000062, 10.025744004000046],
              [50.89864964700007, 10.02559326800008],
              [50.898531363000075, 10.025409036000042],
              [50.898379284000043, 10.025325293000037],
              [50.89827789800006, 10.025325293000037],
              [50.898024432000057, 10.025275048000083],
              [50.897754069000086, 10.025224803000071],
              [50.897585092000043, 10.025074067000048],
              [50.897365422000064, 10.024672104000047],
              [50.897247138000068, 10.024437625000076],
              [50.897247138000068, 10.024119404000089],
              [50.897094777000063, 10.023882665000087],
              [50.897070982000059, 10.023668513000075],
              [50.897070982000059, 10.023442464000084],
              [50.89702339300004, 10.023026059000074],
              [50.896952009000074, 10.022562064000056],
              [50.897078161000081, 10.022059332000083],
              [50.897213750000049, 10.021051106000073],
              [50.897273237000036, 10.020444344000055],
              [50.897225647000084, 10.02014691100004],
              [50.897027467000044, 10.019882005000056],
              [50.896926081000061, 10.019798262000052],
              [50.896841593000033, 10.019547031000059],
              [50.896757104000073, 10.01932929700007],
              [50.896655718000034, 10.019027820000076],
              [50.896537434000038, 10.018776588000037],
              [50.896469843000034, 10.018441612000061],
              [50.896271657000057, 10.017393931000072],
              [50.896267071000068, 10.017369688000088],
              [50.896267071000068, 10.016967715000078],
              [50.896267071000068, 10.016962815000056],
              [50.896267071000068, 10.016632737000066],
              [50.896283968000034, 10.01621401500006],
              [50.896334662000072, 10.015895785000055],
              [50.896436048000055, 10.015594304000047],
              [50.896554332000051, 10.015292823000038],
              [50.896723309000038, 10.014957844000037],
              [50.896858490000056, 10.014773605000073],
              [50.897145751000039, 10.013760289000061],
              [50.897196445000077, 10.013626297000087],
              [50.897466808000047, 10.01329131600005],
              [50.897618887000078, 10.01290608700009],
              [50.897618887000078, 10.012654851000093],
              [50.897618887000078, 10.01225287200009],
              [50.897585092000043, 10.011599656000044],
              [50.897618887000078, 10.011298171000078],
              [50.897635785000034, 10.010979937000059],
              [50.897703376000038, 10.010762197000076],
              [50.897720273000061, 10.010410464000074],
              [50.897804762000078, 10.010159225000052],
              [50.898024432000057, 10.00994148500007],
              [50.898379284000043, 10.00975724400007],
              [50.898446875000047, 10.009656748000054],
              [50.898497568000039, 10.009405509000089],
              [50.898548261000087, 10.009137521000071],
              [50.899241067000048, 10.008383802000083],
              [50.899342453000088, 10.008233058000087],
              [50.899443839000071, 10.008065565000038],
              [50.899511430000075, 10.007864573000063],
              [50.899443839000071, 10.007596583000065],
              [50.899308658000052, 10.007512836000046],
              [50.899173476000044, 10.007362092000051],
              [50.899038294000036, 10.007161099000086],
              [50.898869317000049, 10.006876360000092],
              [50.898801727000034, 10.006507873000089],
              [50.898666545000083, 10.00583789500007],
              [50.898480670000083, 10.005419158000052],
              [50.898396182000056, 10.005067419000056],
              [50.898379284000043, 10.004715680000061],
              [50.898379284000043, 10.004179695000062],
              [50.898362386000088, 10.003677208000056],
              [50.898244102000035, 10.003325467000082],
              [50.898108921000073, 10.003157971000064],
              [50.898041330000069, 10.002806229000043],
              [50.898024432000057, 10.002387489000057],
              [50.898108921000073, 10.000947017000044],
              [50.898108921000073, 10.000612023000087],
              [50.898142716000052, 10.000059281000063],
              [50.898311693000039, 9.999372540000081],
              [50.89827789800006, 9.998920296000051],
              [50.89827789800006, 9.998618799000042],
              [50.898396182000056, 9.998535050000044],
              [50.898987601000044, 9.998250302000088],
              [50.899241067000048, 9.998250302000088],
              [50.899562123000067, 9.99828380200006],
              [50.899866282000062, 9.998267052000074],
              [50.900390111000036, 9.997965555000064],
              [50.900508395000088, 9.997781306000093],
              [50.900575986000035, 9.997412808000092],
              [50.90055908800008, 9.997061060000078],
              [50.90055908800008, 9.996575313000051],
              [50.90055908800008, 9.995620565000081],
              [50.900449817000037, 9.995352927000056],
              [50.900426022000033, 9.994924624000078],
              [50.900508395000088, 9.994464813000093],
              [50.900440804000084, 9.993895311000074],
              [50.900390111000036, 9.993459809000058],
              [50.900288725000053, 9.993108057000086],
              [50.900136645000032, 9.99278980400004],
              [50.900052157000061, 9.99250505200007],
              [50.899900077000041, 9.992320800000073],
              [50.899831157000051, 9.992140655000071],
              [50.899593211000081, 9.991843222000057],
              [50.899283881000088, 9.991748044000076],
              [50.89883552200007, 9.991784794000068],
              [50.898261, 9.991684293000048],
              [50.898075125000048, 9.991567042000042],
              [50.897929695000073, 9.99147436800007],
              [50.897365421000075, 9.991114786000082],
              [50.896926081000061, 9.99071278100007],
              [50.896841593000033, 9.990578779000089],
              [50.896757104000073, 9.990444776000061],
              [50.896321452000052, 9.990189497000074],
              [50.895500538000078, 9.988607155000068],
              [50.895096030000047, 9.987084300000049],
              [50.894889399000078, 9.986547061000067],
              [50.894679624000048, 9.986001646000091],
              [50.893821667000054, 9.984206255000061],
              [50.893697191000058, 9.983771372000092],
              [50.893450561000066, 9.983371193000039],
              [50.893240050000088, 9.982813168000064],
              [50.893168666000065, 9.982480043000066],
              [50.893037796000044, 9.982158816000037],
              [50.892990207000082, 9.98182569100004],
              [50.89277605500007, 9.981456875000049],
              [50.892371547000039, 9.980540783000038],
              [50.892240677000075, 9.980231453000044],
              [50.891990833000079, 9.979886431000068],
              [50.891859963000059, 9.979553306000071],
              [50.891574428000069, 9.979125003000092],
              [50.891169919000049, 9.978423062000047],
              [50.890836795000041, 9.978089938000039],
              [50.890751778000038, 9.977927633000093],
              [50.890705924000088, 9.977840094000044],
              [50.890527465000048, 9.977649737000093],
              [50.890420389000042, 9.977364202000047],
              [50.890218135000055, 9.977102461000072],
              [50.890051573000051, 9.97674554200006],
              [50.889908805000061, 9.97647190400005],
              [50.889599475000068, 9.97623395800008],
              [50.889456707000079, 9.976043601000072],
              [50.889325837000058, 9.975734271000078],
              [50.889218761000052, 9.97554391400007],
              [50.889064096000084, 9.975401147000071],
              [50.888968918000046, 9.975151303000075],
              [50.888826150000057, 9.974877665000065],
              [50.888504923000085, 9.973985367000068],
              [50.888385950000043, 9.97365224300006],
              [50.888173069000061, 9.97313291800009],
              [50.888054785000065, 9.972563378000075],
              [50.887919604000047, 9.972211602000073],
              [50.887779187000035, 9.97180816100007],
              [50.887446063000084, 9.971225193000066],
              [50.887315192000074, 9.970784992000063],
              [50.887136101000067, 9.970476516000076],
              [50.887068510000063, 9.970225246000041],
              [50.886956434000069, 9.96991667900005],
              [50.886672738000073, 9.968179483000085],
              [50.886648943000068, 9.967882050000071],
              [50.886506176000069, 9.967465644000072],
              [50.886303921000035, 9.967084931000045],
              [50.886125462000052, 9.966525757000056],
              [50.885908777000054, 9.965938214000062],
              [50.885824288000038, 9.965251400000056],
              [50.885685262000038, 9.964883929000052],
              [50.885518699000045, 9.964253372000087],
              [50.885266664000085, 9.963257957000053],
              [50.885173677000068, 9.962575852000043],
              [50.885066602000052, 9.961790630000053],
              [50.884959526000046, 9.961231456000064],
              [50.884793528000046, 9.960259393000058],
              [50.884693349000088, 9.95974179600006],
              [50.884543120000046, 9.95904235200004],
              [50.884543120000046, 9.958185746000083],
              [50.884725937000042, 9.957227297000088],
              [50.884692142000063, 9.956473457000072],
              [50.884598633000053, 9.955427239000073],
              [50.884573858000067, 9.95515004400005],
              [50.884506267000063, 9.954714489000082],
              [50.88448936900005, 9.954111412000088],
              [50.884371085000055, 9.953709360000062],
              [50.884202108000068, 9.953089529000067],
              [50.884252801000059, 9.952854998000078],
              [50.884269699000072, 9.952419441000075],
              [50.884531223000067, 9.95221329900005],
              [50.884638299000073, 9.951808791000076],
              [50.884662093000088, 9.951392385000077],
              [50.884387983000067, 9.950978745000043],
              [50.884387983000067, 9.950727460000053],
              [50.884472471000038, 9.950509680000039],
              [50.884387983000067, 9.95034215600009],
              [50.884286597000084, 9.95027514700007],
              [50.884303494000051, 9.949956852000071],
              [50.88440488100008, 9.949755824000079],
              [50.884725937000042, 9.949454281000044],
              [50.884911812000041, 9.949236499000051],
              [50.885114584000064, 9.948985213000071],
              [50.885266664000085, 9.948700422000059],
              [50.88567336400007, 9.948025448000067],
              [50.885756645000072, 9.947716118000073],
              [50.885447315000079, 9.946895204000043],
              [50.885280753000075, 9.946597771000086],
              [50.884979403000045, 9.94638857800004],
              [50.884793528000046, 9.94620430000009],
              [50.884607653000046, 9.946070280000072],
              [50.884387983000067, 9.945919506000052],
              [50.884185210000055, 9.945684970000059],
              [50.884016233000068, 9.945550949000051],
              [50.883931745000041, 9.945450434000065],
              [50.883796563000033, 9.945282908000081],
              [50.88369517700005, 9.945098629000086],
              [50.883576893000054, 9.944880845000057],
              [50.883374121000088, 9.944612803000041],
              [50.883205144000044, 9.944344760000092],
              [50.883086860000049, 9.944026460000089],
              [50.882867189000081, 9.943708159000039],
              [50.882546133000062, 9.94335635200008],
              [50.882365491000087, 9.943191810000087],
              [50.882199351000054, 9.943040477000068],
              [50.881920918000048, 9.942535468000074],
              [50.881830535000063, 9.942302844000039],
              [50.881663972000069, 9.941719876000093],
              [50.881363294000039, 9.940977458000077],
              [50.881143623000071, 9.940206827000054],
              [50.880940851000048, 9.939804758000037],
              [50.880679306000047, 9.939237530000071],
              [50.880533728000046, 9.938626577000093],
              [50.880509934000088, 9.93800791700005],
              [50.880450447000044, 9.936687316000075],
              [50.880390961000046, 9.935759326000039],
              [50.880264943000043, 9.934728591000066],
              [50.880214249000062, 9.934041711000077],
              [50.880214249000062, 9.933354829000052],
              [50.880180454000083, 9.932785219000039],
              [50.880129761000035, 9.932332881000093],
              [50.880062170000087, 9.931981062000091],
              [50.87997768200006, 9.931511969000042],
              [50.879825602000039, 9.931277422000051],
              [50.879504546000078, 9.931076382000072],
              [50.879149694000034, 9.930573782000067],
              [50.879132796000079, 9.930423001000065],
              [50.879014512000083, 9.929803125000092],
              [50.878946921000079, 9.929200002000073],
              [50.87899761500006, 9.928580124000064],
              [50.879031410000039, 9.928228301000047],
              [50.879099001000043, 9.927826217000074],
              [50.879267978000087, 9.927474393000068],
              [50.879463642000076, 9.927292523000062],
              [50.879538341000057, 9.927223090000041],
              [50.879825602000039, 9.926821004000089],
              [50.880011477000039, 9.926016832000073],
              [50.880045272000075, 9.925329933000057],
              [50.879994579000083, 9.924777063000079],
              [50.879825602000039, 9.924291206000078],
              [50.87962953300007, 9.923921508000092],
              [50.879415382000047, 9.923588383000038],
              [50.879296409000062, 9.922969724000041],
              [50.879129846000069, 9.922339166000086],
              [50.87899761500006, 9.921660864000046],
              [50.878761047000069, 9.921225264000043],
              [50.878698506000035, 9.921140411000067],
              [50.87870154400008, 9.920673544000067],
              [50.878689646000055, 9.920352316000049],
              [50.878642057000036, 9.920126268000047],
              [50.878648790000057, 9.919416510000076],
              [50.878522730000043, 9.919141537000087],
              [50.878368419000083, 9.918781872000068],
              [50.878047192000054, 9.917984753000042],
              [50.877868732000081, 9.917366093000055],
              [50.877514065000071, 9.91668754300008],
              [50.877142997000078, 9.914617815000042],
              [50.876714694000043, 9.91254768400006],
              [50.876119828000071, 9.910120634000066],
              [50.875620142000059, 9.909133158000088],
              [50.875548758000036, 9.90866916300007],
              [50.875382195000043, 9.90826465400005],
              [50.875334606000081, 9.908062400000063],
              [50.875156147000041, 9.907776865000073],
              [50.875215633000039, 9.907396151000057],
              [50.875191839000081, 9.906718005000073],
              [50.87507286500005, 9.90626590700009],
              [50.874953892000065, 9.905968475000066],
              [50.874787330000061, 9.905647247000047],
              [50.87479922700004, 9.904957204000084],
              [50.874573179000038, 9.904112495000049],
              [50.87372847000006, 9.90207805600005],
              [50.873379477000071, 9.901392785000041],
              [50.87318119400004, 9.901209553000058],
              [50.872812378000049, 9.901007298000081],
              [50.872651161000078, 9.900862464000056],
              [50.872295360000066, 9.900542820000055],
              [50.871967604000076, 9.900317829000073],
              [50.871513787000083, 9.899917845000061],
              [50.871286879000081, 9.899617856000077],
              [50.871063474000039, 9.899056140000084],
              [50.870861219000062, 9.898425583000062],
              [50.870754144000045, 9.897711745000038],
              [50.870480094000072, 9.897242936000055],
              [50.870183073000078, 9.896712371000092],
              [50.869849794000061, 9.896117968000055],
              [50.869623900000079, 9.895165722000058],
              [50.869446401000062, 9.894168014000059],
              [50.869266981000067, 9.892500725000048],
              [50.868992585000058, 9.891393060000041],
              [50.868981445000088, 9.890145059000076],
              [50.868945753000048, 9.889312248000067],
              [50.868814883000084, 9.888622204000058],
              [50.868969548000052, 9.887801290000084],
              [50.868871335000051, 9.887206186000071],
              [50.868921959000033, 9.886682943000039],
              [50.868672115000038, 9.88630222900008],
              [50.868731602000082, 9.885743056000081],
              [50.868910061000065, 9.885279061000062],
              [50.869017797000083, 9.884968074000085],
              [50.869249317000083, 9.884614880000072],
              [50.869345553000073, 9.884468069000093],
              [50.869345553000073, 9.884168066000086],
              [50.869370765000042, 9.883743062000065],
              [50.869698522000078, 9.883418058000075],
              [50.869975854000074, 9.883343057000047],
              [50.870067491000043, 9.88329762300009],
              [50.870065169000043, 9.883278063000091],
              [50.870089551000035, 9.88326707300007],
              [50.870528095000054, 9.882364221000046],
              [50.870385327000065, 9.881769356000063],
              [50.869749132000038, 9.881048806000081],
              [50.868807304000086, 9.880612974000087],
              [50.868772970000066, 9.880610712000077],
              [50.868481758000087, 9.880591523000078],
              [50.868148634000079, 9.880508242000076],
              [50.867898791000073, 9.880341680000072],
              [50.867660844000056, 9.879877685000054],
              [50.867422898000086, 9.879413690000092],
              [50.867149260000076, 9.878771235000045],
              [50.866950411000062, 9.878267953000091],
              [50.867042185000059, 9.877676683000061],
              [50.866994595000051, 9.876927153000054],
              [50.866732855000066, 9.876534542000059],
              [50.865412478000053, 9.874617829000044],
              [50.865135145000067, 9.874317817000076],
              [50.864908237000066, 9.873942801000055],
              [50.864479633000087, 9.873167768000087],
              [50.863996474000032, 9.872180128000082],
              [50.86298520400004, 9.870740554000065],
              [50.86213491400008, 9.869867606000071],
              [50.861908006000078, 9.869517587000075],
              [50.861908006000078, 9.869192569000063],
              [50.862008854000067, 9.868792546000066],
              [50.86213491400008, 9.868467527000064],
              [50.862160126000049, 9.868192511000075],
              [50.862160126000049, 9.868011782000053],
              [50.860752326000068, 9.86677437600008],
              [50.856287021000071, 9.86231726200009],
              [50.855715950000047, 9.861710500000072],
              [50.854514322000057, 9.860437488000059],
              [50.853812381000068, 9.859533293000084],
              [50.853336489000071, 9.85895032500008],
              [50.852943878000076, 9.858450638000079],
              [50.852634548000083, 9.85789146500008],
              [50.852087272000063, 9.856892091000077],
              [50.851968299000077, 9.856761221000056],
              [50.851702164000073, 9.856620025000041],
              [50.851613279000048, 9.856492703000072],
              [50.851613279000048, 9.856238059000077],
              [50.851692288000038, 9.855895269000087],
              [50.851702164000073, 9.855660213000078],
              [50.851807707000034, 9.855475036000087],
              [50.85183055400006, 9.855434950000074],
              [50.851860182000053, 9.855082365000044],
              [50.851850306000074, 9.854915866000056],
              [50.851791049000042, 9.854671015000065],
              [50.851721917000077, 9.854455546000054],
              [50.851682412000059, 9.854308635000052],
              [50.851662660000045, 9.854161724000051],
              [50.851662660000045, 9.853975637000076],
              [50.851662660000045, 9.853828726000074],
              [50.851860182000053, 9.85334881600005],
              [50.851968820000081, 9.853231287000085],
              [50.852087333000043, 9.852996229000041],
              [50.852195970000082, 9.852859111000043],
              [50.852245351000079, 9.852741582000078],
              [50.852304607000065, 9.852555494000057],
              [50.85236386400004, 9.852437965000092],
              [50.85236386400004, 9.852281259000051],
              [50.852304607000065, 9.85216372900004],
              [50.852304607000065, 9.851958053000089],
              [50.852334236000047, 9.851791552000066],
              [50.852551510000069, 9.85148793400009],
              [50.852630519000058, 9.851389992000065],
              [50.852719404000084, 9.851272463000043],
              [50.852916926000034, 9.851027609000084],
              [50.852926803000059, 9.850792549000062],
              [50.852926803000059, 9.850645636000081],
              [50.85284779400007, 9.850567283000089],
              [50.852778661000059, 9.850381194000079],
              [50.852788537000038, 9.850195104000079],
              [50.852907050000056, 9.850038397000048],
              [50.852986059000045, 9.849744572000077],
              [50.853005812000049, 9.849548688000084],
              [50.853025564000063, 9.849450746000059],
              [50.853124325000067, 9.849323421000065],
              [50.853252714000064, 9.849176508000085],
              [50.853282343000046, 9.848980623000045],
              [50.853242838000085, 9.848892475000071],
              [50.853193458000078, 9.848804327000039],
              [50.853122338000048, 9.848421210000083],
              [50.853336489000071, 9.848052394000092],
              [50.853384078000033, 9.84783824200008],
              [50.853372181000054, 9.84742183700007],
              [50.853122338000048, 9.846886458000085],
              [50.85283680200007, 9.846386771000084],
              [50.85242039700006, 9.844875813000044],
              [50.852503678000062, 9.844280948000062],
              [50.852337116000058, 9.843947824000054],
              [50.852325218000033, 9.843745569000077],
              [50.852265732000035, 9.843448137000053],
              [50.852423121000072, 9.843108936000078],
              [50.852502130000062, 9.84306975800007],
              [50.852640395000037, 9.84295222500009],
              [50.852719404000084, 9.84273674800005],
              [50.852719404000084, 9.842570243000068],
              [50.852719404000084, 9.842315589000066],
              [50.852640395000037, 9.842129495000052],
              [50.852541634000033, 9.842021756000065],
              [50.852393492000033, 9.841865045000077],
              [50.852215722000039, 9.841737717000058],
              [50.852037952000046, 9.841649567000047],
              [50.85183055400006, 9.841512445000092],
              [50.851791049000042, 9.841375323000079],
              [50.851810802000045, 9.841199023000058],
              [50.851840430000038, 9.841003134000061],
              [50.851879934000067, 9.840856217000066],
              [50.851889811000035, 9.840768067000056],
              [50.852235475000043, 9.840474232000076],
              [50.852274979000072, 9.840337110000064],
              [50.852304607000065, 9.840033481000091],
              [50.852314483000043, 9.839631907000069],
              [50.85228485500005, 9.839318482000067],
              [50.852245351000079, 9.839005058000055],
              [50.852225598000075, 9.838799373000086],
              [50.852077457000064, 9.83850553700006],
              [50.851850306000074, 9.838103961000058],
              [50.851820678000081, 9.837702384000067],
              [50.851702164000073, 9.837271423000061],
              [50.851534270000059, 9.836850256000048],
              [50.851405881000062, 9.836282170000061],
              [50.851178730000072, 9.835919770000089],
              [50.851089845000047, 9.835704288000045],
              [50.851030589000061, 9.835292914000092],
              [50.850951580000071, 9.835067637000066],
              [50.850872571000082, 9.834930512000085],
              [50.850783686000057, 9.834754209000039],
              [50.850625668000077, 9.834528932000069],
              [50.850576287000081, 9.83435262800009],
              [50.850566411000045, 9.834156735000079],
              [50.850467650000041, 9.833941252000045],
              [50.850378765000073, 9.833764948000066],
              [50.850131862000069, 9.833294803000058],
              [50.849904712000068, 9.832922605000078],
              [50.849756570000068, 9.832746300000053],
              [50.849549171000035, 9.832403485000043],
              [50.849327097000071, 9.832062416000042],
              [50.849231919000033, 9.831812572000047],
              [50.848946384000044, 9.831277194000052],
              [50.848815513000034, 9.830563355000038],
              [50.848887472000058, 9.829827464000061],
              [50.848768958000051, 9.829435672000045],
              [50.848680073000082, 9.828994905000059],
              [50.84854180800005, 9.828730445000076],
              [50.84839366600005, 9.828583523000077],
              [50.848255400000085, 9.828446395000071],
              [50.848057878000077, 9.828221114000087],
              [50.847949241000038, 9.828015422000078],
              [50.847840604000055, 9.827701987000069],
              [50.847820851000051, 9.82743752500005],
              [50.847514692000061, 9.825997677000089],
              [50.847406055000079, 9.825566700000081],
              [50.847185582000066, 9.82532854100009],
              [50.847019020000062, 9.824840752000057],
              [50.847050514000045, 9.82393094400004],
              [50.846872744000052, 9.823402015000056],
              [50.846754231000034, 9.823000421000074],
              [50.84676410700007, 9.822520465000082],
              [50.846971505000056, 9.822109074000082],
              [50.847011010000074, 9.821795633000079],
              [50.847178904000032, 9.821550757000068],
              [50.847307293000085, 9.821403832000044],
              [50.847406055000079, 9.821109980000074],
              [50.84744555900005, 9.820904284000051],
              [50.847494940000047, 9.82061043200008],
              [50.847465311000065, 9.82041453000005],
              [50.847376426000039, 9.820257809000054],
              [50.847257913000078, 9.819993342000089],
              [50.847188780000067, 9.819728874000077],
              [50.847030762000088, 9.819386046000091],
              [50.846923842000081, 9.818844510000076],
              [50.846745382000051, 9.818416207000041],
              [50.846662101000049, 9.818059288000086],
              [50.846543128000064, 9.817297860000053],
              [50.846412258000043, 9.816381768000042],
              [50.846412258000043, 9.815703621000068],
              [50.846328977000042, 9.815120653000065],
              [50.846240673000068, 9.81470395000008],
              [50.846240673000068, 9.814419888000089],
              [50.846211045000075, 9.814086849000091],
              [50.846181416000036, 9.813851763000059],
              [50.846240673000068, 9.813616676000038],
              [50.846309806000079, 9.813489337000078],
              [50.846448071000054, 9.813381589000073],
              [50.846556709000083, 9.813283636000051],
              [50.846675222000044, 9.81320527400004],
              [50.846767624000051, 9.813184125000078],
              [50.847129523000035, 9.812645718000056],
              [50.847129523000035, 9.812588170000083],
              [50.847129523000035, 9.812460831000067],
              [50.847178904000032, 9.812235539000085],
              [50.847198656000046, 9.811873111000068],
              [50.847267789000057, 9.811011121000092],
              [50.847267789000057, 9.810883781000086],
              [50.847257913000078, 9.810570329000086],
              [50.847248037000043, 9.810227491000092],
              [50.847228284000039, 9.809678949000045],
              [50.847208532000082, 9.809159793000049],
              [50.847188780000067, 9.808807158000093],
              [50.847178904000032, 9.80853288600008],
              [50.847119647000056, 9.808062705000054],
              [50.847080143000085, 9.807592523000039],
              [50.847001134000038, 9.807269272000042],
              [50.846882620000088, 9.806955817000073],
              [50.846813488000066, 9.806759907000071],
              [50.846704850000037, 9.806573793000041],
              [50.846546832000058, 9.80632890600009],
              [50.846378939000033, 9.805976268000052],
              [50.846270301000061, 9.805692198000088],
              [50.846082655000032, 9.805261195000071],
              [50.846023398000057, 9.80498692000009],
              [50.845993770000064, 9.804693053000051],
              [50.845835752000085, 9.804330618000051],
              [50.845816, 9.804271845000073],
              [50.845727115000045, 9.804061240000067],
              [50.845618478000063, 9.803875124000058],
              [50.845460460000083, 9.803512688000069],
              [50.845163041000035, 9.803092478000053],
              [50.844972684000084, 9.802557099000069],
              [50.844937026000082, 9.80202375600004],
              [50.844865608000077, 9.801676699000041],
              [50.844794224000054, 9.801450650000049],
              [50.844730777000052, 9.801232374000051],
              [50.844513502000041, 9.800967892000074],
              [50.844127975000049, 9.800748709000061],
              [50.843937618000041, 9.800451276000047],
              [50.843751892000057, 9.800074420000044],
              [50.843613626000035, 9.799643409000055],
              [50.843534618000035, 9.799300560000063],
              [50.84347536100006, 9.798820570000089],
              [50.843425980000063, 9.798242621000043],
              [50.843406228000049, 9.79788017900006],
              [50.843376600000056, 9.797596102000057],
              [50.843356847000052, 9.797292433000052],
              [50.843346971000074, 9.796939785000063],
              [50.843366724000077, 9.79655775000009],
              [50.843465485000081, 9.796185509000054],
              [50.843692635000082, 9.795725106000077],
              [50.843722264000064, 9.795245111000042],
              [50.843961413000045, 9.794419343000072],
              [50.843890029000079, 9.793764991000046],
              [50.843818645000056, 9.793348585000047],
              [50.843854337000039, 9.792682336000041],
              [50.843782953000073, 9.792325417000086],
              [50.843794851000041, 9.791992292000089],
              [50.843723467000075, 9.791730552000047],
              [50.843675877000067, 9.791314146000047],
              [50.84371156900005, 9.790862048000065],
              [50.843533110000067, 9.790481335000038],
              [50.843616391000069, 9.790290978000087],
              [50.84324821000007, 9.789935727000056],
              [50.843040812000083, 9.788593674000083],
              [50.843040812000083, 9.788280201000077],
              [50.843030936000048, 9.787809991000074],
              [50.84291242200004, 9.787320188000081],
              [50.842774157000065, 9.786830384000041],
              [50.842705024000054, 9.786369968000088],
              [50.842665519000036, 9.786066289000075],
              [50.84260626300005, 9.785811590000037],
              [50.842593223000051, 9.785448775000077],
              [50.842390969000064, 9.785068061000061],
              [50.842248201000075, 9.78469924400008],
              [50.842188714000088, 9.784520785000041],
              [50.842164920000073, 9.784259044000066],
              [50.842161838000038, 9.783881750000091],
              [50.842373738000049, 9.783497598000054],
              [50.842452747000038, 9.783037178000086],
              [50.842418617000078, 9.782725800000037],
              [50.84264081200007, 9.78240306500004],
              [50.84264081200007, 9.781855789000076],
              [50.842581325000083, 9.780915901000071],
              [50.842545634000032, 9.780404317000091],
              [50.842617017000066, 9.77999980900006],
              [50.842533736000064, 9.779464430000075],
              [50.84236717400006, 9.779178895000086],
              [50.842221094000081, 9.778728924000063],
              [50.84215196100007, 9.778366460000086],
              [50.841944563000084, 9.777866846000052],
              [50.841648280000072, 9.777230083000063],
              [50.841421129000082, 9.776906803000088],
              [50.841193978000035, 9.776661893000039],
              [50.840917447000038, 9.776387594000084],
              [50.840680420000069, 9.776064313000063],
              [50.840463146000047, 9.77568225300007],
              [50.840315004000047, 9.77524141400005],
              [50.84018661500005, 9.774898539000048],
              [50.840156986000068, 9.774565460000076],
              [50.840068101000043, 9.773840522000057],
              [50.840035302000047, 9.773527675000082],
              [50.840035302000047, 9.773075577000043],
              [50.840097730000082, 9.772449420000044],
              [50.840097730000082, 9.771930205000046],
              [50.840087854000046, 9.77159712200006],
              [50.840008845000057, 9.771205261000091],
              [50.839989092000053, 9.770940754000037],
              [50.83988045500007, 9.77056848400008],
              [50.839850827000078, 9.770206011000084],
              [50.839821198000038, 9.769853334000061],
              [50.839801446000081, 9.769657402000064],
              [50.83973231300007, 9.769412487000068],
              [50.839692809000042, 9.769236149000051],
              [50.83965330400008, 9.769098996000082],
              [50.839464232000068, 9.76860219100007],
              [50.839333361000058, 9.768221477000054],
              [50.839250080000056, 9.767947839000044],
              [50.839208879000068, 9.767511941000066],
              [50.839050861000032, 9.767120074000047],
              [50.838981729000068, 9.76690454800007],
              [50.838833587000067, 9.766532274000042],
              [50.838754578000078, 9.766404917000045],
              [50.838616312000056, 9.766130609000072],
              [50.838478047000081, 9.765924878000078],
              [50.838512447000085, 9.765604070000052],
              [50.838322090000077, 9.765330432000042],
              [50.837929479000081, 9.764664183000093],
              [50.837834301000044, 9.764390545000083],
              [50.837632047000056, 9.76408121500009],
              [50.837132360000055, 9.763260301000059],
              [50.836846824000077, 9.762772511000037],
              [50.836591709000061, 9.762437233000071],
              [50.836305302000085, 9.761878814000056],
              [50.836037808000071, 9.761392424000064],
              [50.835847451000063, 9.760928429000046],
              [50.835554717000036, 9.760477865000041],
              [50.835454840000068, 9.760226488000058],
              [50.835312072000079, 9.759869569000045],
              [50.835100416000046, 9.759302238000089],
              [50.835011531000077, 9.758831987000065],
              [50.834636238000087, 9.757920872000057],
              [50.834030950000056, 9.756675754000071],
              [50.833698007000066, 9.755990868000083],
              [50.833332591000044, 9.754874008000058],
              [50.833158660000038, 9.753861430000086],
              [50.832694665000076, 9.752540829000054],
              [50.832443741000077, 9.750445718000037],
              [50.832123594000052, 9.748864562000051],
              [50.832087902000069, 9.748269696000079],
              [50.832099800000037, 9.747865188000048],
              [50.832196838000073, 9.747447771000054],
              [50.832186962000037, 9.746967706000078],
              [50.832177086000058, 9.746693382000046],
              [50.832177086000058, 9.746242707000079],
              [50.832196838000073, 9.745948788000078],
              [50.832285723000041, 9.745596085000045],
              [50.832335104000038, 9.745468720000076],
              [50.83247336900007, 9.745253179000088],
              [50.832601759000056, 9.744939664000071],
              [50.832740024000032, 9.744655542000089],
              [50.832847124000068, 9.744451906000052],
              [50.832858538000039, 9.744430203000093],
              [50.832907918000046, 9.744263648000071],
              [50.832986927000036, 9.744077498000081],
              [50.833135069000036, 9.743920740000078],
              [50.833214078000083, 9.743783577000045],
              [50.833586963000073, 9.743653542000061],
              [50.834169931000076, 9.743272828000045],
              [50.83447926100007, 9.743094369000062],
              [50.83471720700004, 9.742987293000056],
              [50.835038434000069, 9.742975396000077],
              [50.835240688000056, 9.742927807000058],
              [50.835645197000076, 9.742844525000066],
              [50.836299548000056, 9.742654168000058],
              [50.83646611100005, 9.742344839000054],
              [50.836410653000087, 9.742226300000084],
              [50.836922947000062, 9.742087696000056],
              [50.837274539000077, 9.741639252000084],
              [50.837409885000056, 9.741400554000052],
              [50.837409885000056, 9.741230550000068],
              [50.837360922000073, 9.740380530000039],
              [50.837336441000048, 9.739773372000059],
              [50.837336441000048, 9.739384790000088],
              [50.837287479000054, 9.738850489000072],
              [50.837189553000087, 9.738631911000084],
              [50.836871297000073, 9.738097609000079],
              [50.836871297000073, 9.737757598000087],
              [50.836797853000064, 9.73734472700005],
              [50.836504078000075, 9.737004715000069],
              [50.836455115000035, 9.736713276000046],
              [50.836406153000041, 9.736518984000043],
              [50.836259265000081, 9.736227544000087],
              [50.836210303000087, 9.735960391000049],
              [50.836210303000087, 9.735596092000037],
              [50.836112378000053, 9.735280365000051],
              [50.836014453000075, 9.734964638000065],
              [50.836014453000075, 9.734576050000044],
              [50.835941009000067, 9.734357469000088],
              [50.835957937000046, 9.734211749000053],
              [50.835835531000043, 9.73399316800004],
              [50.835823656000059, 9.733528936000084],
              [50.83550034600006, 9.732997408000074],
              [50.835526224000034, 9.732803200000092],
              [50.835550018000049, 9.732577151000044],
              [50.835407251000049, 9.732398692000061],
              [50.835335867000083, 9.732172643000069],
              [50.835108646000037, 9.732050219000087],
              [50.834931358000063, 9.731863313000076],
              [50.834800488000042, 9.731518291000043],
              [50.834716945000082, 9.731151602000068],
              [50.834692464000057, 9.730835871000068],
              [50.834671799000034, 9.730781200000081],
              [50.834610026000064, 9.730686490000039],
              [50.834526832000051, 9.730558939000048],
              [50.834181304000083, 9.730551178000042],
              [50.833909147000043, 9.730300465000084],
              [50.833753628000068, 9.730107609000072],
              [50.833801114000039, 9.72973369500005],
              [50.833520351000061, 9.729355471000076],
              [50.833462031000067, 9.729162615000064],
              [50.833325953000042, 9.728969758000062],
              [50.833336143000054, 9.728648040000053],
              [50.833297264000066, 9.728108041000041],
              [50.833313325000063, 9.727865818000055],
              [50.833527476000086, 9.727592180000045],
              [50.833753525000077, 9.727211467000075],
              [50.833741628000041, 9.726902137000081],
              [50.833883645000071, 9.726667748000068],
              [50.833922524000059, 9.726147032000085],
              [50.833908190000045, 9.725914660000058],
              [50.833955779000064, 9.72561722800009],
              [50.833870267000066, 9.725401894000072],
              [50.834064665000085, 9.725266893000082],
              [50.834181828000055, 9.725105644000053],
              [50.834193725000034, 9.724962876000063],
              [50.834134239000036, 9.724736827000072],
              [50.833986906000064, 9.72457260200008],
              [50.833948026000087, 9.724148311000079],
              [50.833908190000045, 9.723642275000088],
              [50.834027163000087, 9.722845156000062],
              [50.834066586000063, 9.722679581000079],
              [50.834086650000074, 9.722595312000067],
              [50.834058989000084, 9.722567651000077],
              [50.833943882000085, 9.722452545000067],
              [50.833836806000079, 9.722167009000088],
              [50.833777320000081, 9.72194096000004],
              [50.833682141000054, 9.721691117000091],
              [50.833634552000035, 9.721488863000047],
              [50.833634552000035, 9.721143841000071],
              [50.833634552000035, 9.72077502500008],
              [50.833444195000084, 9.720560873000068],
              [50.833384272000046, 9.72011752800006],
              [50.833575066000037, 9.720037392000052],
              [50.833729730000073, 9.719704267000054],
              [50.833622655000056, 9.719323553000038],
              [50.83351557900005, 9.719049915000085],
              [50.833408503000044, 9.718526434000069],
              [50.833134865000034, 9.717872082000042],
              [50.832980200000065, 9.717467574000068],
              [50.832873125000049, 9.717146347000039],
              [50.832754151000074, 9.716515789000084],
              [50.832509482000034, 9.716028836000078],
              [50.832353964000049, 9.715700967000089],
              [50.832334524000032, 9.715257379000093],
              [50.832397232000062, 9.715052421000053],
              [50.832409698000049, 9.714852963000055],
              [50.832421027000066, 9.714671707000093],
              [50.832397232000062, 9.714302891000045],
              [50.832397232000062, 9.714005458000088],
              [50.832337746000064, 9.713589052000088],
              [50.831946896000034, 9.713041057000055],
              [50.831814264000059, 9.712518295000052],
              [50.831727426000043, 9.712437659000045],
              [50.831481140000051, 9.712208965000059],
              [50.831231296000055, 9.711982916000068],
              [50.831029042000068, 9.711863943000083],
              [50.830526624000072, 9.71153507300005],
              [50.830390545000057, 9.711014333000037],
              [50.830293346000076, 9.710532165000075],
              [50.830410382000082, 9.710079347000089],
              [50.830410382000082, 9.709746223000081],
              [50.830434177000086, 9.70952017400009],
              [50.830684020000035, 9.709306022000078],
              [50.830743507000079, 9.709044282000093],
              [50.83075702800005, 9.709002367000039],
              [50.830862480000064, 9.708675465000056],
              [50.831124221000039, 9.708401827000046],
              [50.831231296000055, 9.708247162000077],
              [50.831278886000064, 9.708033011000055],
              [50.831409756000085, 9.707580913000072],
              [50.831433551000032, 9.707105021000075],
              [50.83138596100008, 9.706367388000047],
              [50.831243194000081, 9.705641653000043],
              [50.831219399000076, 9.705070582000076],
              [50.831012618000045, 9.704591801000049],
              [50.830993350000085, 9.704213976000062],
              [50.830898172000047, 9.703797570000063],
              [50.83056504700005, 9.703083732000039],
              [50.830446074000065, 9.702881478000052],
              [50.830267615000082, 9.70252455900004],
              [50.830112950000057, 9.701917796000089],
              [50.829898798000045, 9.701453801000071],
              [50.829875004000087, 9.700870833000067],
              [50.82977982500006, 9.700466325000093],
              [50.829672749000053, 9.699919049000073],
              [50.829655001000049, 9.699794810000071],
              [50.829613263000056, 9.699502643000073],
              [50.829672749000053, 9.698991059000093],
              [50.829709191000063, 9.69877241100005],
              [50.829720339000062, 9.698705524000047],
              [50.82977982500006, 9.698538961000054],
              [50.829922593000049, 9.698443783000073],
              [50.830362793000063, 9.698491372000092],
              [50.830743507000079, 9.698419988000069],
              [50.830791096000041, 9.698289118000048],
              [50.830826788000081, 9.698217734000082],
              [50.831017145000033, 9.698086864000061],
              [50.831266988000039, 9.697825123000086],
              [50.831481140000051, 9.697622869000043],
              [50.831516832000034, 9.697349231000089],
              [50.831481140000051, 9.696218987000066],
              [50.831516832000034, 9.695017359000076],
              [50.831409756000085, 9.694565262000083],
              [50.83138596100008, 9.69407747200006],
              [50.831219399000076, 9.693732450000084],
              [50.831219399000076, 9.693446915000038],
              [50.831017145000033, 9.693161380000049],
              [50.830957658000045, 9.692566514000077],
              [50.830791096000041, 9.692209595000065],
              [50.830743507000079, 9.691709908000064],
              [50.830588842000054, 9.691519551000056],
              [50.83032710100008, 9.69099607000004],
              [50.83018433400008, 9.690436897000041],
              [50.829815517000043, 9.689842031000069],
              [50.829970182000068, 9.689366139000072],
              [50.829767928000081, 9.68872368500007],
              [50.829648955000039, 9.688307279000071],
              [50.829458598000087, 9.687986052000042],
              [50.829452367000044, 9.687970919000065],
              [50.829208755000082, 9.687379289000091],
              [50.829042192000088, 9.686915294000073],
              [50.82885480200008, 9.68636501800006],
              [50.829101679000075, 9.68591592100006],
              [50.829134101000079, 9.685663027000089],
              [50.829161165000073, 9.685451926000042],
              [50.829006500000048, 9.68444065500006],
              [50.828919587000087, 9.684107486000073],
              [50.828863733000048, 9.68389337900004],
              [50.828831907000051, 9.683471680000082],
              [50.828816144000086, 9.683262822000074],
              [50.828797860000066, 9.683224791000043],
              [50.828518711000072, 9.682644162000088],
              [50.828213289000075, 9.682083025000054],
              [50.828229854000085, 9.681747731000087],
              [50.828054896000083, 9.681516270000088],
              [50.827757283000039, 9.681240280000054],
              [50.827531235000038, 9.680895258000078],
              [50.827543132000073, 9.680240906000051],
              [50.827180964000036, 9.679637293000042],
              [50.826912575000051, 9.679289122000057],
              [50.826912575000051, 9.678610976000073],
              [50.826519964000056, 9.678373029000056],
              [50.825818023000068, 9.677623499000049],
              [50.825330233000045, 9.677123812000048],
              [50.825116082000079, 9.676873969000042],
              [50.824782957000082, 9.676766893000092],
              [50.824305892000041, 9.676498992000063],
              [50.824111494000078, 9.67626752700005],
              [50.823783583000079, 9.675291627000092],
              [50.823414767000088, 9.674304151000058],
              [50.823057848000076, 9.672543350000069],
              [50.822941745000037, 9.671100352000053],
              [50.822891286000072, 9.670473219000087],
              [50.822692390000043, 9.669786454000075],
              [50.822692390000043, 9.669284937000043],
              [50.822692390000043, 9.668841287000078],
              [50.822692390000043, 9.668397636000066],
              [50.822692390000043, 9.667934696000088],
              [50.822692390000043, 9.667510333000052],
              [50.822731269000087, 9.667259573000081],
              [50.822789588000035, 9.667163126000048],
              [50.823158944000056, 9.666950945000053],
              [50.823581329000035, 9.666975411000067],
              [50.823807378000083, 9.666987309000092],
              [50.824296329000049, 9.666184230000056],
              [50.824724004000075, 9.665393367000092],
              [50.824830766000048, 9.664944855000044],
              [50.824947405000046, 9.664694093000037],
              [50.824986284000033, 9.664346884000054],
              [50.825044604000084, 9.664153990000045],
              [50.825020903000052, 9.663905907000071],
              [50.825044698000056, 9.663560885000038],
              [50.825082316000078, 9.663118483000062],
              [50.825199363000081, 9.662347360000069],
              [50.825104184000054, 9.661716802000058],
              [50.825187465000056, 9.661181424000063],
              [50.82500572400005, 9.660816903000068],
              [50.824947405000046, 9.660681876000069],
              [50.824913827000046, 9.660527072000093],
              [50.824747265000042, 9.660348612000064],
              [50.824711573000059, 9.659956001000069],
              [50.824806752000086, 9.659729952000077],
              [50.824782957000082, 9.659170779000078],
              [50.824578049000081, 9.658714328000087],
              [50.824675881000076, 9.658421249000071],
              [50.824578049000081, 9.658019897000088],
              [50.824539169000047, 9.657672681000065],
              [50.824473627000032, 9.657469464000087],
              [50.824461730000053, 9.657076853000092],
              [50.824509319000072, 9.656862702000069],
              [50.824711573000059, 9.65671993400008],
              [50.824592600000074, 9.656184556000085],
              [50.824556908000034, 9.655910918000075],
              [50.824628292000057, 9.655435025000088],
              [50.824556908000034, 9.655197079000061],
              [50.824390346000087, 9.654911544000072],
              [50.824130934000038, 9.654644168000061],
              [50.824176195000064, 9.654423754000049],
              [50.824164297000038, 9.654209603000083],
              [50.824242678000076, 9.654070318000038],
              [50.82410660000005, 9.653742388000069],
              [50.824045093000052, 9.653267873000061],
              [50.823843070000066, 9.652781926000046],
              [50.823426664000067, 9.652710542000079],
              [50.823139505000086, 9.652425814000082],
              [50.823355281000033, 9.652020499000059],
              [50.823331486000086, 9.651806347000047],
              [50.823283897000067, 9.651437531000056],
              [50.823100625000052, 9.651171955000052],
              [50.822962669000049, 9.650866460000088],
              [50.822962669000049, 9.650509541000076],
              [50.822974567000074, 9.650235903000066],
              [50.82271182900007, 9.649918091000075],
              [50.822634070000049, 9.64966731700008],
              [50.822575751000045, 9.649397254000064],
              [50.822497992000081, 9.649107899000057],
              [50.822400793000043, 9.648953577000043],
              [50.82222583500004, 9.648779964000084],
              [50.821725350000065, 9.648475102000077],
              [50.821701555000061, 9.64827284800009],
              [50.821798159000082, 9.647873318000052],
              [50.821739840000077, 9.64764183300008],
              [50.821584322000035, 9.64741034900004],
              [50.821389924000073, 9.647256026000093],
              [50.821253845000058, 9.647120993000044],
              [50.821098327000072, 9.646947379000039],
              [50.821023409000077, 9.646749993000071],
              [50.820940128000075, 9.646583430000078],
              [50.821070998000039, 9.646381176000091],
              [50.821047203000035, 9.646214614000087],
              [50.821078887000056, 9.646079309000072],
              [50.821078887000056, 9.645905695000067],
              [50.821040008000068, 9.645809242000041],
              [50.820962248000058, 9.645712790000061],
              [50.820826170000032, 9.645635628000093],
              [50.820709531000034, 9.645539175000067],
              [50.820495694000044, 9.645442723000087],
              [50.820262416000048, 9.645269108000093],
              [50.820184657000084, 9.645037622000075],
              [50.82012839500004, 9.644877111000085],
              [50.820029139000042, 9.64459393900006],
              [50.819912500000044, 9.644381743000054],
              [50.819815301000062, 9.644246709000072],
              [50.819504264000045, 9.643860897000081],
              [50.81934874600006, 9.643610119000073],
              [50.819226916000048, 9.643240288000072],
              [50.818921071000034, 9.642799912000044],
              [50.818746113000032, 9.642394808000063],
              [50.818668354000067, 9.642105448000052],
              [50.818532275000052, 9.641681052000081],
              [50.818532275000052, 9.64137240000008],
              [50.818370310000034, 9.64076564800007],
              [50.818532275000052, 9.640195663000043],
              [50.818512835000035, 9.639944882000066],
              [50.818454516000088, 9.639636229000075],
              [50.818403139000054, 9.639432297000042],
              [50.818240678000052, 9.639288993000036],
              [50.818046281000079, 9.63905750300006],
              [50.817890762000047, 9.638806721000037],
              [50.817735244000062, 9.638440194000054],
              [50.817560286000059, 9.638073667000072],
              [50.81736588800004, 9.637726430000043],
              [50.817190930000038, 9.637379192000083],
              [50.816883147000055, 9.63698230500006],
              [50.816895044000034, 9.636589694000065],
              [50.816841014000033, 9.636376060000089],
              [50.816264487000069, 9.635875856000041],
              [50.816109822000044, 9.635518937000086],
              [50.815681519000066, 9.635328580000078],
              [50.815074756000058, 9.634793201000093],
              [50.814408507000053, 9.633413114000064],
              [50.814022245000046, 9.632247753000058],
              [50.813866726000072, 9.632035549000079],
              [50.813730648000046, 9.630087123000067],
              [50.813652889000082, 9.629527672000052],
              [50.813618157000064, 9.629045138000038],
              [50.813614009000048, 9.628987512000037],
              [50.81349737000005, 9.628370185000051],
              [50.813264093000043, 9.627791439000077],
              [50.81299272800004, 9.627440667000087],
              [50.812802371000032, 9.627274104000037],
              [50.812557931000072, 9.627125315000058],
              [50.812528733000079, 9.62710754200009],
              [50.812248848000081, 9.626565637000056],
              [50.81196956000008, 9.626024887000085],
              [50.811288192000063, 9.623815997000065],
              [50.811225372000081, 9.623571423000044],
              [50.811067397000045, 9.623373649000087],
              [50.809780455000066, 9.620183311000062],
              [50.809042823000084, 9.617899028000068],
              [50.808715183000061, 9.616949422000062],
              [50.808281395000051, 9.616102535000039],
              [50.808079141000064, 9.615234032000046],
              [50.807829297000069, 9.614543988000037],
              [50.807531865000044, 9.613758766000046],
              [50.807432157000051, 9.613013799000043],
              [50.807276639000065, 9.612801583000078],
              [50.807179440000084, 9.612338565000073],
              [50.807043361000069, 9.611586159000069],
              [50.806926723000061, 9.611103847000038],
              [50.806907283000044, 9.610698705000061],
              [50.806887843000084, 9.610100636000084],
              [50.806806129000051, 9.609618504000082],
              [50.806818027000077, 9.609035536000079],
              [50.806829524000079, 9.608537935000072],
              [50.806946162000088, 9.607476838000082],
              [50.807436686000074, 9.606763150000063],
              [50.807704314000034, 9.60651220200009],
              [50.807859832000076, 9.606242103000056],
              [50.808015351000051, 9.605740490000073],
              [50.808034790000079, 9.60543180500008],
              [50.808034790000079, 9.605200291000074],
              [50.808114833000047, 9.604847684000049],
              [50.808209748000081, 9.604409284000042],
              [50.808248628000058, 9.604062012000043],
              [50.808170869000037, 9.603869083000063],
              [50.808112550000033, 9.603618275000088],
              [50.808073670000056, 9.60311665800009],
              [50.808054230000039, 9.602499283000043],
              [50.808034790000079, 9.602016958000092],
              [50.80791815200007, 9.601534632000039],
              [50.807840393000049, 9.601187357000072],
              [50.807820953000032, 9.600782202000062],
              [50.807820953000032, 9.600738709000041],
              [50.807646854000041, 9.600220353000054],
              [50.806939177000061, 9.59864009000006],
              [50.806627670000069, 9.59797104200004],
              [50.805937626000059, 9.596900285000061],
              [50.805483823000088, 9.596247006000056],
              [50.805192226000088, 9.595706792000044],
              [50.804973944000039, 9.595306046000076],
              [50.804691147000085, 9.594878464000089],
              [50.804545641000061, 9.59463979700007],
              [50.80431959200007, 9.594330467000077],
              [50.803784214000075, 9.593414374000076],
              [50.80339160300008, 9.59265294700009],
              [50.803108145000067, 9.592125955000085],
              [50.802546894000045, 9.591082502000063],
              [50.802035310000065, 9.589286009000091],
              [50.801761672000055, 9.588417506000042],
              [50.801619661000075, 9.587894169000037],
              [50.801511828000059, 9.587394338000081],
              [50.801488034000045, 9.587001727000086],
              [50.801425263000056, 9.586640067000076],
              [50.801425263000056, 9.58627348300007],
              [50.801523726000084, 9.585907175000045],
              [50.801589786000079, 9.585733016000063],
              [50.801654596000049, 9.585562153000069],
              [50.801761672000055, 9.585359899000082],
              [50.801833499000054, 9.585154433000071],
              [50.802066776000061, 9.584942199000068],
              [50.802202854000086, 9.584672083000044],
              [50.802368434000073, 9.583420638000064],
              [50.80242792100006, 9.581766913000081],
              [50.802463613000043, 9.581326712000077],
              [50.802572210000051, 9.58069749100008],
              [50.802404126000056, 9.580125084000088],
              [50.802380332000041, 9.579625398000076],
              [50.802546971000083, 9.579042429000083],
              [50.802534997000066, 9.578257208000082],
              [50.802455572000042, 9.577263097000071],
              [50.802436132000082, 9.576298373000043],
              [50.802436132000082, 9.575623065000059],
              [50.802213769000048, 9.574806989000081],
              [50.802416692000065, 9.574426801000072],
              [50.802618278000068, 9.573867102000065],
              [50.802487407000058, 9.573248442000079],
              [50.802475510000079, 9.571951636000051],
              [50.802249461000088, 9.571154516000092],
              [50.802344640000058, 9.570702419000042],
              [50.802380332000041, 9.570155143000079],
              [50.802130488000046, 9.56956027800004],
              [50.802344640000058, 9.56904869400006],
              [50.802166180000086, 9.568513315000075],
              [50.801950137000063, 9.567963045000056],
              [50.801854106000064, 9.567635030000076],
              [50.801718027000049, 9.567191244000071],
              [50.801618904000065, 9.566490773000055],
              [50.801523726000084, 9.565634167000042],
              [50.801511828000059, 9.564587204000077],
              [50.801428547000057, 9.563790085000051],
              [50.801309574000072, 9.563076246000037],
              [50.801333369000076, 9.561101294000082],
              [50.801309574000072, 9.560220893000064],
              [50.801214396000034, 9.557555897000043],
              [50.801230865000036, 9.556906838000089],
              [50.801308624000058, 9.556540221000091],
              [50.801405823000039, 9.556154308000089],
              [50.801444703000072, 9.556077125000058],
              [50.801658540000062, 9.555826281000066],
              [50.801794634000032, 9.555617450000057],
              [50.80215428300005, 9.555342998000071],
              [50.802285153000071, 9.555235922000065],
              [50.802475510000079, 9.555128847000049],
              [50.802630530000044, 9.554707130000054],
              [50.802868121000074, 9.554617263000068],
              [50.802963300000044, 9.554617263000068],
              [50.803116524000075, 9.553877412000077],
              [50.803155404000051, 9.553472200000044],
              [50.803238685000053, 9.553044792000037],
              [50.803219245000037, 9.552639579000072],
              [50.803117473000043, 9.552273521000075],
              [50.803120362000072, 9.551793599000064],
              [50.803022786000042, 9.55147637400006],
              [50.803046581000046, 9.54839497200004],
              [50.803106067000044, 9.548216512000067],
              [50.803320219000057, 9.547966669000061],
              [50.803653343000065, 9.547609750000049],
              [50.803855236000061, 9.54725889000008],
              [50.803991315000076, 9.547008040000037],
              [50.80406907400004, 9.546737893000056],
              [50.804088514000057, 9.546487042000081],
              [50.804295798000055, 9.546015511000064],
              [50.804533744000082, 9.545634797000048],
              [50.804581333000044, 9.545313570000076],
              [50.804593948000047, 9.544866156000069],
              [50.804613388000064, 9.544422341000086],
              [50.80483117700004, 9.54400486600008],
              [50.80483117700004, 9.543743126000038],
              [50.804749466000032, 9.543399634000082],
              [50.804710587000045, 9.543168077000075],
              [50.804613388000064, 9.542820741000071],
              [50.804613388000064, 9.542261145000055],
              [50.804676512000071, 9.541613508000069],
              [50.804676512000071, 9.539959783000086],
              [50.804652717000067, 9.539150766000091],
              [50.804581333000044, 9.53861538700005],
              [50.804557539000086, 9.53810380300007],
              [50.804541150000034, 9.537475589000053],
              [50.804541150000034, 9.536858093000092],
              [50.804605128000048, 9.536164542000051],
              [50.804724101000033, 9.535379320000061],
              [50.805057225000041, 9.533975438000084],
              [50.805057225000041, 9.533654211000055],
              [50.805283274000033, 9.531976691000068],
              [50.805459725000048, 9.53100328000005],
              [50.805640193000045, 9.530180198000039],
              [50.805723474000047, 9.529751895000061],
              [50.805533118000085, 9.528907186000083],
              [50.805501522000043, 9.528266839000082],
              [50.80537845300006, 9.527348639000081],
              [50.805199993000087, 9.526777569000046],
              [50.80467979000008, 9.525286320000077],
              [50.804664614000046, 9.525242816000059],
              [50.804534445000058, 9.523108968000088],
              [50.804542388000073, 9.522947368000075],
              [50.804700306000086, 9.519734364000044],
              [50.804973944000039, 9.518033050000042],
              [50.805318966000073, 9.517259725000088],
              [50.805390350000039, 9.51689090800005],
              [50.805687783000053, 9.515974816000039],
              [50.80589003700004, 9.515427540000076],
              [50.806471257000055, 9.514567019000083],
              [50.807081284000049, 9.513032882000061],
              [50.807487969000078, 9.512225439000076],
              [50.807853985000065, 9.511337249000064],
              [50.808091038000043, 9.510977948000061],
              [50.808233806000032, 9.510609131000081],
              [50.808483649000038, 9.510216520000085],
              [50.809030925000059, 9.508646076000048],
              [50.809697174000064, 9.507063734000042],
              [50.809863737000057, 9.506540253000082],
              [50.810054093000076, 9.506207128000085],
              [50.810351526000034, 9.505469495000057],
              [50.810553780000077, 9.504969809000045],
              [50.810767932000033, 9.504493916000058],
              [50.811410386000034, 9.503304186000037],
              [50.811558292000086, 9.502787051000041],
              [50.811826792000033, 9.502352402000042],
              [50.812124225000048, 9.501924099000064],
              [50.81220750600005, 9.501555282000083],
              [50.812255095000069, 9.501234055000054],
              [50.812338376000071, 9.50079385500004],
              [50.812409760000037, 9.500353654000037],
              [50.812635809000085, 9.49991345400008],
              [50.812683398000047, 9.499425664000057],
              [50.81316630300006, 9.498164966000047],
              [50.813338739000073, 9.497556302000078],
              [50.813568653000061, 9.497023719000083],
              [50.813683610000055, 9.49660526100007],
              [50.813968307000039, 9.496249084000056],
              [50.81409917700006, 9.495832678000056],
              [50.814194356000087, 9.495535246000088],
              [50.814349021000055, 9.495237813000074],
              [50.814515583000059, 9.494964175000064],
              [50.814679905000048, 9.494608068000048],
              [50.814792265000051, 9.494471732000079],
              [50.816026541000042, 9.492144514000074],
              [50.816935448000038, 9.490617944000064],
              [50.817285421000065, 9.490027232000045],
              [50.817411072000084, 9.489777745000083],
              [50.817525601000057, 9.489431928000045],
              [50.817715958000065, 9.489003625000066],
              [50.817858726000054, 9.488777576000075],
              [50.818132364000064, 9.488301684000078],
              [50.818874792000088, 9.487514065000084],
              [50.819500554000058, 9.486850213000082],
              [50.821047203000035, 9.48529166600008],
              [50.822082269000077, 9.484613520000039],
              [50.822641442000076, 9.484446957000046],
              [50.823176821000061, 9.483852092000063],
              [50.824045324000053, 9.483982963000074],
              [50.824330860000032, 9.483649838000076],
              [50.824854341000048, 9.483328611000047],
              [50.826115455000036, 9.482793232000063],
              [50.826876883000068, 9.482186469000055],
              [50.82719811000004, 9.481829550000043],
              [50.827745386000061, 9.481496426000092],
              [50.829113576000054, 9.480937252000047],
              [50.831231296000055, 9.480473258000075],
              [50.832016617000079, 9.480735032000041],
              [50.832302054000081, 9.480830177000087],
              [50.832992098000034, 9.480413771000087],
              [50.834015266000051, 9.480235311000058],
              [50.835193099000037, 9.480152030000056],
              [50.835895040000082, 9.479473884000072],
              [50.836513700000069, 9.479212143000041],
              [50.837147896000033, 9.479039349000061],
              [50.837390209000034, 9.478883685000085],
              [50.838138731000072, 9.478402828000071],
              [50.83954751300007, 9.477284780000048],
              [50.839559825000038, 9.477281493000078],
              [50.84199835700008, 9.476630428000078],
              [50.842248201000075, 9.476582839000059],
              [50.842890655000076, 9.476321098000085],
              [50.843402239000056, 9.476214022000079],
              [50.844056591000083, 9.47592848700009],
              [50.845287544000087, 9.474757521000072],
              [50.846031544000084, 9.47321590100006],
              [50.846352771000056, 9.471728738000081],
              [50.846447950000083, 9.47030106200009],
              [50.846659503000069, 9.469814536000058],
              [50.846757280000077, 9.468944769000075],
              [50.846709690000068, 9.468052471000078],
              [50.84672738900008, 9.466707198000051],
              [50.846662101000049, 9.465887162000058],
              [50.846614512000087, 9.464673636000043],
              [50.846543128000064, 9.464138258000048],
              [50.846459847000062, 9.463460111000074],
              [50.846600591000083, 9.463314380000043],
              [50.846795117000056, 9.461093304000087],
              [50.84637656600006, 9.45997420100008],
              [50.846043441000063, 9.45873688100005],
              [50.845876879000059, 9.458296681000093],
              [50.84559431100007, 9.457678187000056],
              [50.845638933000032, 9.457011772000044],
              [50.845555652000087, 9.456393112000057],
              [50.845431637000047, 9.455820761000041],
              [50.84539096900005, 9.455336213000066],
              [50.845146958000043, 9.454245979000063],
              [50.845032170000081, 9.454085035000048],
              [50.844877505000056, 9.453716219000057],
              [50.844782327000075, 9.453204634000087],
              [50.844699046000073, 9.452978586000086],
              [50.844484894000061, 9.453002380000044],
              [50.843925721000062, 9.453049969000062],
              [50.843782953000073, 9.451681779000069],
              [50.843642225000053, 9.451177151000081],
              [50.843033423000065, 9.449932876000048],
              [50.841938871000082, 9.448398123000061],
              [50.841700925000055, 9.447934128000043],
              [50.841415390000066, 9.447351160000039],
              [50.841177443000049, 9.446946652000065],
              [50.840606373000071, 9.445792613000037],
              [50.839494043000059, 9.443504962000077],
              [50.838884016000065, 9.441889743000047],
              [50.838452961000087, 9.441295432000061],
              [50.838250706000053, 9.440819540000064],
              [50.838262604000079, 9.440200880000077],
              [50.837846198000079, 9.439534631000072],
              [50.837572560000069, 9.43876130600006],
              [50.837257278000038, 9.438053565000075],
              [50.837053935000085, 9.437770897000064],
              [50.83660887800005, 9.437214656000037],
              [50.836204370000075, 9.436619791000055],
              [50.836061602000086, 9.436262872000043],
              [50.835847451000063, 9.435894056000052],
              [50.835597607000068, 9.435501445000057],
              [50.835466737000047, 9.435251601000061],
              [50.835193099000037, 9.43512073100004],
              [50.834931358000063, 9.434835195000062],
              [50.834848077000061, 9.434597249000092],
              [50.834746215000052, 9.434396889000084],
              [50.834324596000044, 9.433252854000045],
              [50.833539374000054, 9.432205891000081],
              [50.833301428000084, 9.431444464000037],
              [50.833068427000057, 9.430259934000048],
              [50.832255058000044, 9.428160065000043],
              [50.831088529000056, 9.424770075000083],
              [50.830587651000087, 9.423798757000043],
              [50.830279512000061, 9.422723739000048],
              [50.829613263000056, 9.42082017000007],
              [50.829315830000041, 9.419761310000069],
              [50.829161165000073, 9.41892849900006],
              [50.82885183500008, 9.418202763000068],
              [50.82831021700008, 9.417579759000091],
              [50.827618854000036, 9.416469212000038],
              [50.826805484000033, 9.414914440000075],
              [50.826358050000067, 9.413803680000058],
              [50.825806125000042, 9.41289656500004],
              [50.825199080000061, 9.411966410000048],
              [50.824806752000086, 9.411385607000057],
              [50.824271373000045, 9.410529001000043],
              [50.824263706000067, 9.410250080000083],
              [50.824181745000033, 9.410077129000058],
              [50.823831173000087, 9.409601012000053],
              [50.82346235600005, 9.409101325000051],
              [50.823129232000042, 9.408708714000056],
              [50.822697970000036, 9.408129896000048],
              [50.821975193000071, 9.406924118000063],
              [50.821376246000057, 9.405787581000084],
              [50.821003309000048, 9.405213897000067],
              [50.820840635000081, 9.40468889300007],
              [50.820809755000084, 9.404314273000068],
              [50.820462473000077, 9.403642457000046],
              [50.820379868000032, 9.403606792000062],
              [50.819964549000076, 9.403426310000043],
              [50.819744347000039, 9.403067307000072],
              [50.819643321000058, 9.402783856000042],
              [50.81945296400005, 9.402438834000066],
              [50.819203121000044, 9.402081915000053],
              [50.818941380000069, 9.401463255000067],
              [50.818739126000082, 9.400999260000049],
              [50.818548769000074, 9.400499573000047],
              [50.818387114000075, 9.400214424000069],
              [50.818244775000039, 9.399992304000079],
              [50.818143103000068, 9.399790376000055],
              [50.818143103000068, 9.399502341000073],
              [50.817567655000062, 9.398897280000085],
              [50.817309400000056, 9.398558614000081],
              [50.817264530000045, 9.398523957000066],
              [50.817126392000034, 9.398417263000056],
              [50.816902715000083, 9.398134563000042],
              [50.816780710000046, 9.397892248000062],
              [50.816550022000058, 9.39734678700006],
              [50.816272354000034, 9.396640286000093],
              [50.816170683000053, 9.396438357000079],
              [50.815845336000052, 9.395832566000081],
              [50.815479320000065, 9.395226775000083],
              [50.815235309000059, 9.394782528000064],
              [50.814920091000033, 9.394205899000042],
              [50.814729734000082, 9.393848980000087],
              [50.814515583000059, 9.393408779000083],
              [50.814325226000051, 9.393230320000043],
              [50.814076258000057, 9.39294495200005],
              [50.813933918000032, 9.392621861000066],
              [50.813608571000032, 9.392137223000077],
              [50.813486565000062, 9.391894904000083],
              [50.812815536000073, 9.391006400000038],
              [50.81232751400006, 9.390138087000082],
              [50.811898176000057, 9.389268517000062],
              [50.811565051000059, 9.388364322000086],
              [50.811310803000083, 9.387916810000092],
              [50.810898802000054, 9.387424435000071],
              [50.810637061000079, 9.386841467000067],
              [50.810456765000083, 9.386321520000081],
              [50.810314426000048, 9.385917649000078],
              [50.810089785000059, 9.385556558000076],
              [50.809709072000032, 9.385247228000082],
              [50.809364050000056, 9.384830822000083],
              [50.808947644000057, 9.384438211000088],
              [50.808531238000057, 9.38383144900007],
              [50.808342006000032, 9.383130919000052],
              [50.807793606000075, 9.382225313000049],
              [50.807377200000076, 9.381178350000084],
              [50.806722848000049, 9.380428820000077],
              [50.806365929000037, 9.379655495000065],
              [50.805535882000072, 9.378223797000089],
              [50.805116712000085, 9.377704337000068],
              [50.804426668000076, 9.376681168000061],
              [50.803522473000044, 9.375788871000054],
              [50.802998992000084, 9.37483708600007],
              [50.801276142000063, 9.372069331000091],
              [50.800845579000054, 9.37133927900004],
              [50.799739130000034, 9.369756937000091],
              [50.79882316000004, 9.369052540000041],
              [50.79707684300007, 9.366874547000066],
              [50.795389103000048, 9.36421892900006],
              [50.794977441000071, 9.36392100900008],
              [50.79434708000008, 9.363153601000079],
              [50.793897553000079, 9.362654246000091],
              [50.793742030000033, 9.362219630000084],
              [50.793498019000083, 9.362017679000076],
              [50.793152337000038, 9.361755144000085],
              [50.792838693000078, 9.361095699000089],
              [50.792446082000083, 9.360381861000064],
              [50.792386596000085, 9.360072531000071],
              [50.792277966000086, 9.359796220000078],
              [50.792115292000062, 9.35947309800008],
              [50.792013621000081, 9.359311537000053],
              [50.791749276000075, 9.359008609000057],
              [50.791545933000066, 9.358786462000069],
              [50.79110286100007, 9.358200870000076],
              [50.790304567000078, 9.357145794000076],
              [50.789590729000054, 9.356324880000045],
              [50.789091042000052, 9.355848987000059],
              [50.788924480000048, 9.355444479000084],
              [50.788722226000061, 9.355135149000091],
              [50.788460485000087, 9.354671154000073],
              [50.788150117000043, 9.353959773000042],
              [50.787662096000076, 9.353394299000058],
              [50.787413522000065, 9.353338656000062],
              [50.787175576000038, 9.352969840000071],
              [50.786997116000066, 9.352601023000091],
              [50.786437943000067, 9.351934774000085],
              [50.785521851000055, 9.350864017000049],
              [50.784427298000082, 9.349281675000043],
              [50.783094800000072, 9.347806409000043],
              [50.781238821000045, 9.346402527000066],
              [50.780096679000053, 9.346224068000083],
              [50.77991822000007, 9.345724381000082],
              [50.77937094400005, 9.34536746200007],
              [50.779180587000042, 9.345153310000057],
              [50.778837041000088, 9.345013054000049],
              [50.778633699000068, 9.344770702000062],
              [50.778552362000084, 9.344508153000049],
              [50.778369354000063, 9.344063840000047],
              [50.778514338000036, 9.343820812000047],
              [50.778787976000046, 9.343582866000077],
              [50.778799702000072, 9.343296791000057],
              [50.778759811000043, 9.343089889000055],
              [50.778573824000034, 9.342238470000041],
              [50.778563611000038, 9.342216983000071],
              [50.778244434000044, 9.341545476000078],
              [50.777231742000083, 9.340264184000091],
              [50.776206261000084, 9.338966712000058],
              [50.775837444000047, 9.338014927000074],
              [50.775361552000049, 9.337408165000056],
              [50.774968941000054, 9.336682429000064],
              [50.774709193000035, 9.335843940000075],
              [50.774231308000083, 9.335183369000049],
              [50.773398497000073, 9.333957946000055],
              [50.773267626000063, 9.333220313000083],
              [50.772803631000045, 9.332661140000084],
              [50.772601377000058, 9.332054377000077],
              [50.772589480000079, 9.331649869000046],
              [50.772351534000052, 9.331554691000065],
              [50.771756669000069, 9.330448241000056],
              [50.770959549000054, 9.329127640000081],
              [50.771054728000081, 9.328651748000084],
              [50.77079298700005, 9.328318624000076],
              [50.77013863500008, 9.327414428000054],
              [50.769706973000041, 9.326381724000044],
              [50.769270132000088, 9.32580829200009],
              [50.768758548000051, 9.324868405000075],
              [50.767890044000069, 9.323571599000047],
              [50.767184199000042, 9.322672372000056],
              [50.766880515000082, 9.322322081000038],
              [50.766595836000079, 9.321978726000054],
              [50.766311157000075, 9.321695962000092],
              [50.765915092000057, 9.321311111000057],
              [50.765510583000037, 9.320989884000085],
              [50.765225048000048, 9.320513991000041],
              [50.765058486000044, 9.320049997000069],
              [50.764903821000075, 9.319847742000093],
              [50.764826758000083, 9.319494440000085],
              [50.76466408400006, 9.319292465000046],
              [50.764481076000038, 9.318949107000037],
              [50.764257400000076, 9.318605749000085],
              [50.764082907000045, 9.318348682000078],
              [50.764094726000053, 9.317757450000045],
              [50.764094726000053, 9.317627603000062],
              [50.763821166000071, 9.317634844000054],
              [50.763131122000061, 9.317016184000067],
              [50.761733055000036, 9.315597082000068],
              [50.76114427300007, 9.314743799000041],
              [50.75966900700007, 9.312626078000051],
              [50.759181217000048, 9.312281056000074],
              [50.758909498000037, 9.311990982000054],
              [50.758726490000072, 9.311748607000084],
              [50.75821813400006, 9.310940687000084],
              [50.757896308000056, 9.310413179000079],
              [50.757087291000062, 9.309616060000053],
              [50.756879723000054, 9.309428016000084],
              [50.756632065000076, 9.309203653000054],
              [50.756225380000046, 9.308799691000047],
              [50.755961035000041, 9.308597709000082],
              [50.755554350000068, 9.308274538000092],
              [50.755188334000081, 9.307971566000049],
              [50.754923989000076, 9.307688791000089],
              [50.754801984000039, 9.307365620000041],
              [50.754801984000039, 9.307183836000092],
              [50.75500532600006, 9.306981853000082],
              [50.755007698000043, 9.30694180800009],
              [50.755025661000047, 9.306638483000086],
              [50.754814906000036, 9.306439480000051],
              [50.754910085000063, 9.30597548500009],
              [50.754944324000064, 9.305689163000068],
              [50.755076647000067, 9.305309236000085],
              [50.754434192000076, 9.303691202000039],
              [50.753958300000079, 9.302977364000071],
              [50.753724270000077, 9.302901783000038],
              [50.753520928000057, 9.302659401000085],
              [50.753030310000042, 9.302358704000085],
              [50.752911337000057, 9.302120758000058],
              [50.752411651000045, 9.301870914000062],
              [50.752410982000072, 9.301420367000048],
              [50.752136792000044, 9.300907072000086],
              [50.751882292000062, 9.300430635000055],
              [50.751495558000045, 9.299955448000048],
              [50.751245715000039, 9.29962232400004],
              [50.750995871000043, 9.299229713000045],
              [50.750775807000082, 9.298922658000038],
              [50.750511462000077, 9.298639876000038],
              [50.750369122000052, 9.298377293000044],
              [50.750165780000032, 9.29807431100005],
              [50.749913217000085, 9.297921009000049],
              [50.749663373000033, 9.297457014000088],
              [50.748949535000065, 9.296981122000091],
              [50.749128735000056, 9.296720991000086],
              [50.748823721000065, 9.296256417000052],
              [50.748579710000058, 9.295892837000054],
              [50.748259491000056, 9.295838981000088],
              [50.748173026000075, 9.29546866000004],
              [50.748091689000034, 9.295367666000061],
              [50.747868012000083, 9.295226273000083],
              [50.747746007000046, 9.295105079000052],
              [50.747545653000088, 9.294815813000071],
              [50.747486166000044, 9.294542175000061],
              [50.747236323000038, 9.294185255000059],
              [50.746784225000056, 9.294363715000088],
              [50.745999003000065, 9.293316752000067],
              [50.745273268000062, 9.292840860000069],
              [50.744892554000046, 9.292436352000038],
              [50.744190613000058, 9.29215081600006],
              [50.743917700000054, 9.291770143000065],
              [50.74334590400008, 9.291532156000073],
              [50.743000882000047, 9.291115751000063],
              [50.742227558000081, 9.29080642100007],
              [50.741466130000049, 9.290080685000078],
              [50.74066901100008, 9.289759458000049],
              [50.740389335000032, 9.289293321000059],
              [50.740062248000072, 9.289009928000041],
              [50.739324615000044, 9.288605419000078],
              [50.739223659000061, 9.288561608000066],
              [50.738694058000078, 9.288331781000068],
              [50.738253858000064, 9.288236603000087],
              [50.738006712000072, 9.287684062000039],
              [50.737159306000081, 9.287272921000067],
              [50.736684187000037, 9.28734859900004],
              [50.736399508000034, 9.287288001000093],
              [50.736302700000067, 9.286916002000055],
              [50.736043873000085, 9.287001613000086],
              [50.735933883000087, 9.286820824000074],
              [50.735606473000075, 9.286823414000082],
              [50.735339018000047, 9.286713748000068],
              [50.734970201000067, 9.286452007000037],
              [50.734675148000065, 9.286264246000087],
              [50.734577590000072, 9.286202164000088],
              [50.734387234000053, 9.286047499000063],
              [50.734184979000077, 9.285845245000075],
              [50.733840856000086, 9.285721858000045],
              [50.733390042000053, 9.285449850000077],
              [50.733064695000053, 9.285308454000074],
              [50.732800350000048, 9.285227656000075],
              [50.732617342000083, 9.285066060000077],
              [50.73183173700005, 9.28441358200007],
              [50.73149010700007, 9.284205805000056],
              [50.730068512000059, 9.283929778000072],
              [50.729229006000082, 9.284177233000037],
              [50.728548318000037, 9.283782423000048],
              [50.724905081000088, 9.281669291000071],
              [50.722478031000037, 9.280360587000075],
              [50.719283630000064, 9.278601875000049],
              [50.717826185000035, 9.27770748800009],
              [50.717409779000036, 9.277386261000061],
              [50.717052860000081, 9.27725539000005],
              [50.716588865000062, 9.276827087000072],
              [50.716136768000069, 9.27647016800006],
              [50.715494313000079, 9.275934790000065],
              [50.714994626000077, 9.275458897000078],
              [50.71475668000005, 9.275113875000045],
              [50.714435453000078, 9.274745059000054],
              [50.714233199000034, 9.274459524000065],
              [50.713889518000087, 9.274137942000038],
              [50.713816793000035, 9.273947940000085],
              [50.713317106000034, 9.273412561000043],
              [50.712972084000057, 9.273115128000086],
              [50.712662755000053, 9.272722517000091],
              [50.712246349000054, 9.272389393000083],
              [50.711754424000048, 9.271996718000082],
              [50.71140874200006, 9.271754314000077],
              [50.710798716000056, 9.271431109000048],
              [50.71022935700006, 9.271107904000075],
              [50.709741336000036, 9.270724097000084],
              [50.709334651000063, 9.270522094000057],
              [50.709185835000085, 9.270455567000056],
              [50.708292937000067, 9.269507372000078],
              [50.70809287600008, 9.269267708000086],
              [50.707928376000041, 9.269174327000087],
              [50.707434405000072, 9.26884717900009],
              [50.707106713000087, 9.26867743300005],
              [50.706785486000058, 9.268463282000084],
              [50.706404772000042, 9.268142055000055],
              [50.70609362600004, 9.267748895000068],
              [50.705858402000047, 9.267515217000039],
              [50.705717267000068, 9.267281539000066],
              [50.705774215000076, 9.266999913000063],
              [50.70566713900007, 9.266309870000043],
              [50.705464885000083, 9.265667415000053],
              [50.705322117000037, 9.265203420000091],
              [50.705072274000088, 9.264763220000077],
              [50.704870020000044, 9.26425163600004],
              [50.704352966000044, 9.263682877000065],
              [50.704297667000048, 9.263453976000051],
              [50.702894854000078, 9.261886164000089],
              [50.702609532000054, 9.261598537000054],
              [50.701290018000066, 9.261004460000038],
              [50.700468017000048, 9.260254141000075],
              [50.699956433000068, 9.259421330000066],
              [50.699754179000081, 9.259159589000092],
              [50.69949243800005, 9.258885951000082],
              [50.699195005000036, 9.258398162000049],
              [50.698909470000046, 9.257993654000074],
              [50.698683421000055, 9.257612940000058],
              [50.698421680000081, 9.257291713000086],
              [50.698219426000037, 9.256851512000082],
              [50.697957686000052, 9.256328031000066],
              [50.697649073000036, 9.255947964000086],
              [50.697469896000086, 9.255733166000084],
              [50.696621406000077, 9.254578826000056],
              [50.695732889000055, 9.253365602000088],
              [50.695344170000055, 9.253048963000083],
              [50.693936396000083, 9.251902233000067],
              [50.693412915000067, 9.250783887000068],
              [50.69344796300004, 9.250188073000061],
              [50.693460504000086, 9.249974870000074],
              [50.692699077000043, 9.248452015000055],
              [50.691878163000069, 9.247286079000048],
              [50.691033454000035, 9.246262911000088],
              [50.69022443700004, 9.245073180000077],
              [50.689860970000041, 9.244776221000052],
              [50.689278067000032, 9.244299979000061],
              [50.688987118000057, 9.243931039000074],
              [50.688201895000077, 9.243681196000068],
              [50.686417300000073, 9.241575373000046],
              [50.68570346100006, 9.240944815000091],
              [50.684989623000035, 9.239933544000053],
              [50.683871276000048, 9.238636738000082],
              [50.683431076000033, 9.238137051000081],
              [50.681801145000065, 9.236626094000087],
              [50.681131356000037, 9.235757460000059],
              [50.681039718000079, 9.235638617000063],
              [50.67979050100007, 9.234318017000078],
              [50.679029073000038, 9.233723151000049],
              [50.678565078000076, 9.233247259000052],
              [50.677892134000047, 9.232519135000075],
              [50.677106912000056, 9.231698221000045],
              [50.676602023000044, 9.231058155000085],
              [50.676245104000088, 9.23066554400009],
              [50.67601905500004, 9.230380009000044],
              [50.675857851000046, 9.230098791000046],
              [50.675222746000088, 9.229210716000068],
              [50.674270151000087, 9.22850023400008],
              [50.673716180000042, 9.227593651000063],
              [50.673287877000064, 9.227236731000062],
              [50.67257403900004, 9.226522893000038],
              [50.671538973000054, 9.225309368000069],
              [50.669718686000067, 9.223631848000082],
              [50.668785494000076, 9.222777631000042],
              [50.667375701000083, 9.221407488000068],
              [50.667024692000041, 9.220588527000075],
              [50.666620184000067, 9.22016022400004],
              [50.666168086000084, 9.21950587200007],
              [50.665763578000053, 9.218899109000063],
              [50.665335275000075, 9.218351833000042],
              [50.664978356000063, 9.217721276000077],
              [50.664482442000065, 9.217317556000069],
              [50.664153128000066, 9.216920246000086],
              [50.663353365000035, 9.215868540000088],
              [50.662294855000084, 9.214349404000075],
              [50.661659008000072, 9.213438246000067],
              [50.661313986000039, 9.212843381000084],
              [50.660826197000063, 9.212141440000039],
              [50.66033840700004, 9.211403807000067],
              [50.659966134000058, 9.210680080000088],
              [50.659660343000041, 9.210025674000065],
              [50.659153879000087, 9.209569233000082],
              [50.658511424000039, 9.208962471000063],
              [50.658011738000084, 9.208391400000039],
              [50.65766093600007, 9.207618384000057],
              [50.657378667000046, 9.207314550000092],
              [50.65707287500004, 9.206706882000049],
              [50.657002308000074, 9.206379675000051],
              [50.656649471000037, 9.205748633000042],
              [50.656412296000042, 9.204955468000037],
              [50.656233837000059, 9.204146451000042],
              [50.656031583000072, 9.203325537000069],
              [50.655548995000061, 9.202002547000063],
              [50.655436717000043, 9.201207817000068],
              [50.655270155000039, 9.200839],
              [50.654960825000046, 9.200303621000046],
              [50.654318371000045, 9.19888784200009],
              [50.653534001000082, 9.196452845000067],
              [50.653462366000042, 9.19636543300004],
              [50.652331521000065, 9.194985526000039],
              [50.652173477000076, 9.194310158000064],
              [50.652062597000054, 9.19417932600004],
              [50.651897940000083, 9.193852108000044],
              [50.651780328000086, 9.193548262000093],
              [50.651689066000074, 9.193093855000086],
              [50.651700964000042, 9.192391914000041],
              [50.651558196000053, 9.191713767000067],
              [50.651403531000085, 9.191130799000064],
              [50.651308352000058, 9.19069059900005],
              [50.651165585000058, 9.190405064000061],
              [50.651022817000069, 9.190083836000042],
              [50.650903844000084, 9.189833993000093],
              [50.650761076000038, 9.189596047000066],
              [50.650677795000036, 9.189369998000075],
              [50.650594514000034, 9.189132052000048],
              [50.650380363000068, 9.188882209000042],
              [50.649643490000074, 9.187899826000091],
              [50.649308002000055, 9.187316447000057],
              [50.64891059100006, 9.18700382600008],
              [50.647917621000033, 9.185003687000062],
              [50.647429831000068, 9.183909135000079],
              [50.647310858000083, 9.183480832000043],
              [50.647179988000062, 9.182731302000093],
              [50.647084809000035, 9.181803312000056],
              [50.646866316000057, 9.180891890000055],
              [50.646748704000061, 9.179886823000061],
              [50.646608917000037, 9.179519030000051],
              [50.646418560000086, 9.17905503500009],
              [50.646229033000054, 9.178285513000048],
              [50.646229033000054, 9.177724541000089],
              [50.646243815000048, 9.177627599000061],
              [50.646239293000065, 9.17760693200006],
              [50.646061641000074, 9.173867810000047],
              [50.646060352000063, 9.173578617000089],
              [50.646059580000042, 9.173405414000058],
              [50.645948695000072, 9.173274568000068],
              [50.645867293000038, 9.173178513000039],
              [50.645887949000041, 9.173109153000041],
              [50.646002154000087, 9.172725668000055],
              [50.645942668000032, 9.172356852000064],
              [50.646014052000055, 9.17209511100009],
              [50.645942668000032, 9.171809576000044],
              [50.646049744000038, 9.171524041000055],
              [50.646109230000036, 9.171119532000091],
              [50.646025949000034, 9.170334310000044],
              [50.645871284000066, 9.170001186000093],
              [50.645752311000081, 9.169108888000039],
              [50.645799900000043, 9.168383152000047],
              [50.645823695000047, 9.167895363000071],
              [50.645871284000066, 9.167407573000048],
              [50.645847489000062, 9.167026859000089],
              [50.645966463000036, 9.166527172000087],
              [50.645990257000051, 9.166003691000071],
              [50.646014052000055, 9.165575388000093],
              [50.64603784600007, 9.165289853000047],
              [50.646061641000074, 9.165051907000077],
              [50.645954565000068, 9.16481396100005],
              [50.645906976000049, 9.164492733000088],
              [50.645823695000047, 9.163993046000087],
              [50.645906976000049, 9.163421976000052],
              [50.645859387000087, 9.162862803000053],
              [50.645847489000062, 9.161887223000065],
              [50.645930771000053, 9.16135184500007],
              [50.645966463000036, 9.160709390000079],
              [50.645978360000072, 9.160233498000082],
              [50.646025949000034, 9.159852784000066],
              [50.646144922000076, 9.159567249000077],
              [50.646192511000038, 9.159364995000089],
              [50.646263895000061, 9.158901],
              [50.646370971000067, 9.15840131300007],
              [50.64654943000005, 9.157913524000037],
              [50.646573225000054, 9.157604194000044],
              [50.64668030100006, 9.15705691800008],
              [50.646632712000041, 9.156521539000039],
              [50.646965836000049, 9.156057544000078],
              [50.646934706000081, 9.155542080000089],
              [50.64727918400007, 9.15510942700007],
              [50.647514408000063, 9.153683529000091],
              [50.647774853000044, 9.153118910000046],
              [50.648036594000075, 9.152595428000041],
              [50.648012799000071, 9.151988666000079],
              [50.647993216000032, 9.151521505000062],
              [50.648087305000047, 9.150820237000062],
              [50.648181395000051, 9.150305973000059],
              [50.648465842000064, 9.149781487000041],
              [50.648583454000061, 9.149313973000062],
              [50.648821816000066, 9.148883469000054],
              [50.649047865000057, 9.14834809000007],
              [50.649202529000036, 9.147765122000067],
              [50.649369092000086, 9.147170257000084],
              [50.649392886000044, 9.146765749000053],
              [50.649535654000033, 9.14636124000009],
              [50.649618935000035, 9.145968629000038],
              [50.649678422000079, 9.145504634000076],
              [50.649892573000045, 9.144802693000088],
              [50.650032541000087, 9.144479157000092],
              [50.650127272000077, 9.144369954000069],
              [50.650308979000044, 9.144172136000066],
              [50.650439849000065, 9.143743833000087],
              [50.650523130000067, 9.143482093000046],
              [50.650856255000065, 9.143113276000065],
              [50.650999023000054, 9.142756357000053],
              [50.651272661000064, 9.142363746000058],
              [50.65124886600006, 9.141983032000041],
              [50.651415428000064, 9.141780778000054],
              [50.651903218000086, 9.141364372000055],
              [50.652260137000042, 9.140912275000062],
              [50.652628953000033, 9.140472074000058],
              [50.652747926000075, 9.13990100400008],
              [50.652795516000083, 9.13911578200009],
              [50.653069154000036, 9.138544711000065],
              [50.653021564000085, 9.137890359000039],
              [50.652855002000081, 9.137259802000074],
              [50.652521878000073, 9.137021856000047],
              [50.652391007000062, 9.136617348000073],
              [50.652295829000082, 9.136034380000069],
              [50.652227254000081, 9.135298484000089],
              [50.652156686000069, 9.134643937000078],
              [50.65201555200008, 9.134129650000091],
              [50.65176045000004, 9.133750097000075],
              [50.651629580000076, 9.133226616000059],
              [50.651486812000087, 9.132536572000049],
              [50.651593888000036, 9.131679966000092],
              [50.651615670000069, 9.131277677000071],
              [50.651615670000069, 9.130950400000074],
              [50.651703775000044, 9.130600163000054],
              [50.651962704000084, 9.129669321000051],
              [50.651787768000077, 9.128516116000071],
              [50.651474915000051, 9.126694995000037],
              [50.651748553000061, 9.125410086000045],
              [50.651991999000074, 9.123363968000092],
              [50.652022191000071, 9.122602322000091],
              [50.651891320000061, 9.122174019000056],
              [50.651808039000059, 9.12141259200007],
              [50.651593888000036, 9.120139580000057],
              [50.651451120000047, 9.11886656900009],
              [50.651391634000049, 9.118414471000051],
              [50.651177482000037, 9.117617351000092],
              [50.651177482000037, 9.117248535000044],
              [50.651241517000074, 9.116853982000066],
              [50.651121699000043, 9.116573289000087],
              [50.651004087000047, 9.116082353000081],
              [50.650903844000084, 9.115416350000089],
              [50.650815908000084, 9.114726431000065],
              [50.650674773000048, 9.114071846000058],
              [50.650416055000051, 9.113370014000054],
              [50.650535028000036, 9.112275462000071],
              [50.650535028000036, 9.111775775000069],
              [50.650570720000076, 9.111014347000037],
              [50.650451747000034, 9.110300509000069],
              [50.650273287000061, 9.109562876000041],
              [50.650063190000083, 9.108601338000085],
              [50.649969100000078, 9.108250661000056],
              [50.649686831000054, 9.107876606000048],
              [50.649451606000071, 9.107525928000086],
              [50.649262821000036, 9.106916691000038],
              [50.649190632000057, 9.106683728000064],
              [50.649216684000066, 9.10663289300004],
              [50.649347797000075, 9.106377052000084],
              [50.649416681000048, 9.106124555000065],
              [50.649321503000067, 9.10555348400004],
              [50.649404784000069, 9.105256052000072],
              [50.649238221000076, 9.104470829000093],
              [50.649464270000067, 9.104220986000087],
              [50.649527744000068, 9.103919008000048],
              [50.649511859000086, 9.103400072000056],
              [50.649380989000065, 9.102745720000087],
              [50.649297708000063, 9.102436390000037],
              [50.649216382000077, 9.102148834000047],
              [50.649166838000042, 9.101746347000073],
              [50.649095454000076, 9.101234762000047],
              [50.649083556000051, 9.100854049000077],
              [50.648928891000082, 9.100164005000067],
              [50.648881302000063, 9.099854675000074],
              [50.648702843000081, 9.099485859000083],
              [50.648607664000053, 9.098938583000063],
              [50.648524383000051, 9.098296128000072],
              [50.648275485000056, 9.097543171000041],
              [50.648063783000055, 9.096935312000085],
              [50.64796969300005, 9.096420969000064],
              [50.647917621000033, 9.095964256000059],
              [50.647734469000056, 9.09522862700004],
              [50.647774853000044, 9.094774526000037],
              [50.647655880000059, 9.094096379000064],
              [50.647632085000055, 9.093537206000065],
              [50.647531862000051, 9.093341756000086],
              [50.64742867700005, 9.09296082700007],
              [50.647287542000072, 9.092469858000072],
              [50.647169930000075, 9.092212683000071],
              [50.646958228000074, 9.091745093000043],
              [50.646770049000054, 9.091300881000052],
              [50.646628914000075, 9.090809910000075],
              [50.646417212000074, 9.090318938000053],
              [50.64627579200004, 9.08987283600004],
              [50.646121127000072, 9.089492122000081],
              [50.645835592000083, 9.088921052000046],
              [50.645466776000035, 9.088266700000077],
              [50.644943294000086, 9.087279224000042],
              [50.644336532000068, 9.086279850000039],
              [50.644245374000036, 9.086151089000055],
              [50.643384747000084, 9.08493545500005],
              [50.642885061000072, 9.083864697000081],
              [50.642218811000077, 9.081973026000071],
              [50.641909482000074, 9.081271085000083],
              [50.641481179000039, 9.080009970000049],
              [50.640755443000046, 9.078391937000049],
              [50.639875042000085, 9.077035644000091],
              [50.638291043000038, 9.075257945000089],
              [50.637150559000077, 9.073763885000062],
              [50.636484310000071, 9.072467079000091],
              [50.634985250000057, 9.070111412000074],
              [50.632279001000086, 9.066564954000057],
              [50.631332777000068, 9.065233517000081],
              [50.630690323000067, 9.064281733000087],
              [50.62925074900005, 9.062544726000056],
              [50.628893830000038, 9.062033142000075],
              [50.627273054000057, 9.06013544800004],
              [50.62547369300006, 9.058008470000061],
              [50.624479930000064, 9.056607971000062],
              [50.623730399000067, 9.055727571000091],
              [50.621791139000038, 9.053788310000073],
              [50.619399780000037, 9.051896638000073],
              [50.61762708200007, 9.050599832000046],
              [50.615640232000032, 9.04942199900006],
              [50.613558204000071, 9.048743853000076],
              [50.611166845000071, 9.047934836000081],
              [50.608930152000084, 9.047042538000085],
              [50.607764216000078, 9.045638656000051],
              [50.606950740000059, 9.044647829000041],
              [50.606410191000066, 9.043989431000057],
              [50.606396959000051, 9.043961810000042],
              [50.60572340300007, 9.043767030000083],
              [50.604758983000067, 9.043229228000087],
              [50.604029788000048, 9.04259789300005],
              [50.603647749000061, 9.042343103000064],
              [50.602648375000058, 9.041855313000042],
              [50.602216117000069, 9.041772675000061],
              [50.601030341000069, 9.041545983000049],
              [50.600578244000076, 9.040951118000066],
              [50.599245746000065, 9.039975539000068],
              [50.597782377000044, 9.038690630000076],
              [50.596461776000069, 9.037667462000059],
              [50.595962089000068, 9.037691256000073],
              [50.594408745000067, 9.036332575000074],
              [50.593094839000059, 9.035609228000055],
              [50.591869417000055, 9.034550368000055],
              [50.591024708000077, 9.033598583000071],
              [50.589882567000075, 9.03226608500006],
              [50.589144934000046, 9.031468966000091],
              [50.588883193000072, 9.030909792000045],
              [50.588550068000075, 9.030207851000057],
              [50.588228841000046, 9.029636781000079],
              [50.587865227000066, 9.029134705000047],
              [50.587684986000056, 9.028785338000091],
              [50.587657771000067, 9.028732586000046],
              [50.587164203000043, 9.028147041000068],
              [50.587130277000085, 9.028032375000066],
              [50.586194402000046, 9.025960514000076],
              [50.584605086000067, 9.023609450000038],
              [50.584435601000052, 9.023470264000082],
              [50.583918107000045, 9.022838895000064],
              [50.583377091000045, 9.022254292000071],
              [50.582836075000046, 9.021365695000043],
              [50.582760387000064, 9.021261190000075],
              [50.582412671000043, 9.020781090000071],
              [50.582012790000078, 9.020173100000079],
              [50.58149529700006, 9.019705414000043],
              [50.581024848000084, 9.01921434400009],
              [50.580340928000055, 9.018096395000043],
              [50.580003935000036, 9.017588515000057],
              [50.579839278000065, 9.017144211000073],
              [50.579686576000086, 9.016811486000051],
              [50.579412938000075, 9.016371286000037],
              [50.579282068000055, 9.016121442000042],
              [50.579163095000069, 9.015883496000072],
              [50.578913251000074, 9.015728831000047],
              [50.57874668900007, 9.015407604000075],
              [50.578615819000049, 9.015133966000064],
              [50.578592024000045, 9.014681868000082],
              [50.578296246000036, 9.014490681000041],
              [50.578270774000032, 9.014326084000061],
              [50.578139927000052, 9.014158387000066],
              [50.577731707000055, 9.014022988000079],
              [50.577496483000061, 9.013929449000045],
              [50.577237736000086, 9.013835911000058],
              [50.577009683000085, 9.013408857000059],
              [50.57699778500006, 9.01321850000005],
              [50.576579108000033, 9.012760214000082],
              [50.576498098000059, 9.012480867000079],
              [50.576343433000034, 9.012112050000042],
              [50.576085137000064, 9.01184820800006],
              [50.576200666000034, 9.011814618000074],
              [50.575986514000078, 9.011564774000078],
              [50.575486828000066, 9.011291136000068],
              [50.575296471000058, 9.011124574000064],
              [50.574999038000044, 9.010946115000081],
              [50.574744358000032, 9.010678966000057],
              [50.574891962000038, 9.010517812000046],
              [50.57501093500008, 9.010327455000038],
              [50.574725400000034, 9.009839665000072],
              [50.574320954000086, 9.009907264000049],
              [50.57363084800005, 9.009542233000047],
              [50.572833729000081, 9.009018751000042],
              [50.572486714000036, 9.008775072000049],
              [50.571784647000072, 9.008214206000048],
              [50.568915395000033, 9.006135265000069],
              [50.566828344000044, 9.004285917000061],
              [50.566456773000084, 9.004045678000068],
              [50.565921394000043, 9.003545991000067],
              [50.565029593000077, 9.002330467000093],
              [50.564465055000085, 9.001558748000093],
              [50.56418278600006, 9.000974110000072],
              [50.563806427000088, 9.000506400000063],
              [50.563477113000033, 9.000202387000058],
              [50.563124276000053, 8.999898375000043],
              [50.562936097000033, 8.999641134000058],
              [50.562842007000086, 8.999407277000046],
              [50.56228081900008, 8.998906042000044],
              [50.561716065000041, 8.998186238000073],
              [50.561124869000082, 8.99762996600009],
              [50.559690001000035, 8.996413906000043],
              [50.558461784000087, 8.995027520000065],
              [50.557878774000073, 8.994543036000039],
              [50.556114591000039, 8.993022947000043],
              [50.555197216000067, 8.992110891000038],
              [50.555162716000041, 8.992030858000078],
              [50.553645141000061, 8.99100214300006],
              [50.552137569000081, 8.990325247000044],
              [50.550726216000044, 8.989612658000055],
              [50.549461800000074, 8.988645660000088],
              [50.54831338300005, 8.987639294000076],
              [50.547872123000047, 8.986816297000075],
              [50.547804582000083, 8.986450671000057],
              [50.547778004000065, 8.986306796000065],
              [50.547492469000076, 8.986045055000091],
              [50.547135550000064, 8.985711931000083],
              [50.545065419000082, 8.984082],
              [50.544259285000066, 8.983217764000074],
              [50.543114261000085, 8.982202226000084],
              [50.542519395000056, 8.981916690000048],
              [50.541984017000061, 8.981595463000076],
              [50.54162709700006, 8.981393209000089],
              [50.54121069200005, 8.981179057000077],
              [50.540903293000042, 8.981086838000067],
              [50.540377880000051, 8.980929214000071],
              [50.540044756000043, 8.980643679000082],
              [50.539664042000084, 8.98028676000007],
              [50.539378507000038, 8.980060711000078],
              [50.539033485000061, 8.979846559000066],
              [50.538712258000032, 8.979691894000041],
              [50.538307749000069, 8.97935877000009],
              [50.537712884000086, 8.978894775000072],
              [50.537439246000076, 8.978680623000059],
              [50.53647335900007, 8.977891864000071],
              [50.535532462000049, 8.977237026000068],
              [50.534714763000068, 8.976955514000053],
              [50.534027026000047, 8.976558801000067],
              [50.533344876000058, 8.97618460700005],
              [50.530633988000034, 8.974290518000089],
              [50.530051020000087, 8.974123956000085],
              [50.528922658000056, 8.973518464000051],
              [50.527087909000045, 8.972068448000073],
              [50.526147011000035, 8.971132950000083],
              [50.52574713000007, 8.970758751000062],
              [50.525370771000041, 8.970431326000039],
              [50.525172819000034, 8.970234509000079],
              [50.525095949000047, 8.97018309300006],
              [50.52461805300004, 8.970103900000083],
              [50.524053515000048, 8.969823250000047],
              [50.522524557000054, 8.968653870000082],
              [50.52170127100004, 8.967952241000091],
              [50.521371957000042, 8.967671589000076],
              [50.520666284000072, 8.967297386000041],
              [50.520319024000059, 8.966842805000056],
              [50.519319650000057, 8.966009993000057],
              [50.516607065000073, 8.96354725100008],
              [50.51629773500008, 8.963237921000086],
              [50.516071686000032, 8.963095154000086],
              [50.515631486000075, 8.962678748000087],
              [50.514334680000047, 8.961679374000084],
              [50.513656533000074, 8.961084509000045],
              [50.512639621000062, 8.960434097000075],
              [50.51187193800007, 8.960192211000049],
              [50.51039667200007, 8.959454578000077],
              [50.510104712000043, 8.958760768000047],
              [50.508340530000055, 8.95728730400009],
              [50.507234975000074, 8.955930776000059],
              [50.506364645000076, 8.954621020000047],
              [50.504694552000046, 8.952609600000073],
              [50.503730133000033, 8.951697441000078],
              [50.502508758000033, 8.951126465000073],
              [50.501461796000058, 8.950662470000054],
              [50.501069185000063, 8.95022227000004],
              [50.500831238000046, 8.949793967000062],
              [50.500557600000036, 8.949460842000065],
              [50.50054453000007, 8.948817284000086],
              [50.499998427000037, 8.948806490000038],
              [50.49979617300005, 8.948592339000072],
              [50.499858833000076, 8.948161785000082],
              [50.498401485000045, 8.946899681000048],
              [50.49824940600007, 8.946551883000041],
              [50.497614300000066, 8.945943767000074],
              [50.496108864000064, 8.944680753000057],
              [50.495150847000048, 8.943943842000067],
              [50.493954596000037, 8.94288163300007],
              [50.491277703000037, 8.940740118000065],
              [50.489257192000082, 8.938152070000058],
              [50.486339309000073, 8.935734222000065],
              [50.484424855000043, 8.934874746000048],
              [50.482771129000071, 8.933351891000086],
              [50.481670696000037, 8.931817275000071],
              [50.47861619300005, 8.929268719000049],
              [50.478396472000043, 8.92922016700004],
              [50.478067158000044, 8.92903304500004],
              [50.477666733000035, 8.928858630000093],
              [50.476679709000052, 8.927557904000082],
              [50.475879299000042, 8.926854111000068],
              [50.475220944000057, 8.926413332000038],
              [50.474962197000082, 8.926156038000045],
              [50.474891630000059, 8.925968915000055],
              [50.474868107000077, 8.92580518200009],
              [50.474859386000048, 8.925783501000069],
              [50.472673891000056, 8.923354590000088],
              [50.472268558000053, 8.92259325200007],
              [50.471974848000059, 8.922483728000088],
              [50.471786669000039, 8.922296603000063],
              [50.471692579000035, 8.922109477000049],
              [50.471598489000087, 8.92180539900005],
              [50.471397783000043, 8.92127318200005],
              [50.471386787000085, 8.921244022000053],
              [50.471348257000045, 8.921210497000061],
              [50.471198608000066, 8.921080287000052],
              [50.470986906000064, 8.920893161000038],
              [50.470845771000086, 8.92075281700005],
              [50.470751682000071, 8.920635863000086],
              [50.470539980000069, 8.920401955000045],
              [50.47035180000006, 8.920121266000081],
              [50.470187143000032, 8.91988735800004],
              [50.470022486000062, 8.919653450000055],
              [50.46981078400006, 8.91930258800005],
              [50.469642406000048, 8.919051434000039],
              [50.46962260500004, 8.91902189800004],
              [50.469613091000042, 8.919014330000039],
              [50.469387380000057, 8.918834771000093],
              [50.469199201000038, 8.918671035000045],
              [50.469011022000075, 8.918390344000045],
              [50.468948693000073, 8.91817341300009],
              [50.46891693200007, 8.918062871000075],
              [50.468752275000043, 8.917782180000074],
              [50.468517050000059, 8.917478098000061],
              [50.468422961000044, 8.917361143000051],
              [50.468234781000035, 8.917244188000041],
              [50.468196457000033, 8.917221322000046],
              [50.467999557000041, 8.917103842000074],
              [50.467834900000071, 8.916940105000037],
              [50.467764333000048, 8.916752977000044],
              [50.467646720000062, 8.916565849000051],
              [50.467482063000034, 8.916448894000041],
              [50.467411496000068, 8.916355330000044],
              [50.467176272000074, 8.916121420000081],
              [50.466752868000071, 8.915583425000079],
              [50.466517644000078, 8.915302732000043],
              [50.466329464000069, 8.915022039000064],
              [50.46619529600008, 8.91468849000006],
              [50.466047195000044, 8.914320305000047],
              [50.465764926000077, 8.914016220000065],
              [50.465519556000061, 8.913833219000082],
              [50.465482657000052, 8.913805699000079],
              [50.465247432000069, 8.91357178700008],
              [50.46510629800008, 8.913478222000037],
              [50.464824028000066, 8.913314483000079],
              [50.464494714000068, 8.913103962000093],
              [50.463906654000084, 8.912659528000063],
              [50.463553817000047, 8.912261877000049],
              [50.463342115000046, 8.912051355000074],
              [50.463153936000083, 8.911794050000083],
              [50.462895189000051, 8.911513354000078],
              [50.462801099000046, 8.911302832000047],
              [50.462542352000071, 8.910928570000067],
              [50.462518360000047, 8.910901730000091],
              [50.461342779000063, 8.909958961000086],
              [50.458964622000053, 8.907963042000063],
              [50.457938582000054, 8.906815510000058],
              [50.45666643800007, 8.906124242000089],
              [50.456390127000077, 8.905345711000052],
              [50.455895117000068, 8.90510768900009],
              [50.454485754000075, 8.904445814000042],
              [50.453464495000048, 8.903575309000075],
              [50.452054185000065, 8.902849887000059],
              [50.450886352000055, 8.901728854000055],
              [50.449506265000082, 8.90040825400007],
              [50.448698620000073, 8.899512926000057],
              [50.44767408000007, 8.89879022000008],
              [50.447436134000043, 8.898135868000054],
              [50.44712680400005, 8.897576695000055],
              [50.447119938000071, 8.897538246000067],
              [50.447067317000062, 8.897243570000057],
              [50.447091112000066, 8.897005624000087],
              [50.447055420000083, 8.89676767800006],
              [50.446453008000049, 8.89647240700009],
              [50.446476531000087, 8.895887599000048],
              [50.446500053000079, 8.895489928000075],
              [50.446500053000079, 8.895209220000083],
              [50.446876412000051, 8.894975296000041],
              [50.446933113000057, 8.894962765000059],
              [50.446865063000075, 8.892805876000068],
              [50.446497780000072, 8.892048448000082],
              [50.446461576000047, 8.891436387000056],
              [50.446285656000043, 8.890911539000058],
              [50.446170739000081, 8.890858207000065],
              [50.445982560000061, 8.890600888000051],
              [50.445817903000034, 8.890273390000061],
              [50.44577085800006, 8.889875713000038],
              [50.445747335000078, 8.889618393000092],
              [50.445723813000086, 8.88933768000004],
              [50.445700290000048, 8.889033573000063],
              [50.445535633000077, 8.888682681000091],
              [50.445347454000057, 8.888355182000055],
              [50.445206319000079, 8.887980896000045],
              [50.445041662000051, 8.887676788000078],
              [50.444900528000062, 8.887349288000053],
              [50.44482996000005, 8.886998394000045],
              [50.444759393000083, 8.886717679000071],
              [50.444754551000074, 8.886685574000069],
              [50.444419058000051, 8.886116413000082],
              [50.443495062000068, 8.884762225000088],
              [50.442716960000041, 8.883601488000068],
              [50.441890226000055, 8.882876026000076],
              [50.44140088100005, 8.882719601000076],
              [50.440610947000039, 8.881663693000064],
              [50.440577179000059, 8.881618555000045],
              [50.439917037000043, 8.88094426300006],
              [50.439572015000067, 8.880563549000044],
              [50.439286480000078, 8.880373192000093],
              [50.438972875000047, 8.880120807000083],
              [50.438714128000072, 8.880074020000052],
              [50.438525948000063, 8.880027233000078],
              [50.438290724000069, 8.879886873000089],
              [50.438079022000068, 8.879652939000039],
              [50.437820275000036, 8.879208464000044],
              [50.437679141000046, 8.878740595000068],
              [50.437655618000065, 8.878342906000057],
              [50.437597062000066, 8.877981834000082],
              [50.437002197000083, 8.877541633000078],
              [50.43707358100005, 8.877267995000068],
              [50.436901271000067, 8.876751065000065],
              [50.436871327000063, 8.87666123300005],
              [50.436706966000088, 8.876517836000062],
              [50.435936550000065, 8.875845684000069],
              [50.435559233000049, 8.87548696500005],
              [50.435217601000033, 8.875162172000046],
              [50.434976694000056, 8.874631625000063],
              [50.434755107000058, 8.874449055000071],
              [50.434277714000075, 8.874055723000083],
              [50.43412304900005, 8.873579831000086],
              [50.433599568000034, 8.872889787000076],
              [50.433278341000062, 8.872711327000047],
              [50.433123676000037, 8.872473381000077],
              [50.433079423000038, 8.872163612000065],
              [50.43305229200007, 8.871973695000065],
              [50.433040394000045, 8.871854721000091],
              [50.432833520000088, 8.871582124000042],
              [50.432754859000056, 8.871319343000039],
              [50.43264778300005, 8.871105191000083],
              [50.43240983700008, 8.870712580000088],
              [50.432041021000032, 8.870403250000038],
              [50.431791177000036, 8.870093920000045],
              [50.431553231000066, 8.869772693000073],
              [50.431315285000039, 8.869641823000052],
              [50.430910777000065, 8.86928490400004],
              [50.430530063000049, 8.868690038000068],
              [50.430434885000068, 8.868309325000041],
              [50.430339706000041, 8.867892919000042],
              [50.430165765000083, 8.867336702000046],
              [50.429954063000082, 8.866962395000087],
              [50.429031003000034, 8.86660801000005],
              [50.429305155000065, 8.866388635000078],
              [50.429268949000061, 8.86582278800006],
              [50.428764139000066, 8.865242314000056],
              [50.428434825000068, 8.864797822000071],
              [50.428152556000043, 8.864400117000059],
              [50.427776197000071, 8.863838651000037],
              [50.427517450000039, 8.863323974000082],
              [50.427211658000033, 8.862832690000062],
              [50.426976434000039, 8.862505167000052],
              [50.426811777000069, 8.862060671000052],
              [50.426623597000059, 8.861569385000053],
              [50.426388373000066, 8.861195072000044],
              [50.426342212000066, 8.860873509000044],
              [50.426044779000051, 8.86074263900008],
              [50.425925806000066, 8.860635563000073],
              [50.425652168000056, 8.860350028000084],
              [50.425259557000061, 8.859493422000071],
              [50.424593308000055, 8.858981837000044],
              [50.423769764000042, 8.858223310000085],
              [50.42368911300008, 8.858149026000092],
              [50.422523177000073, 8.857054474000051],
              [50.421345344000088, 8.856019408000066],
              [50.420200630000068, 8.855404122000039],
              [50.420213966000063, 8.85516537400008],
              [50.419929564000086, 8.854710705000059],
              [50.419536953000033, 8.854401375000066],
              [50.419536953000033, 8.854379015000063],
              [50.419536953000033, 8.853865996000081],
              [50.419905770000071, 8.853687537000042],
              [50.419834386000048, 8.852902315000051],
              [50.41922762300004, 8.85240262800005],
              [50.419259775000057, 8.851270510000063],
              [50.41871603900006, 8.848845334000089],
              [50.417939152000088, 8.848657347000085],
              [50.417288363000068, 8.847679398000082],
              [50.417720430000088, 8.847181493000051],
              [50.416907649000052, 8.845038196000075],
              [50.416526935000036, 8.845085785000038],
              [50.416431757000055, 8.844990607000057],
              [50.416344294000055, 8.844818484000086],
              [50.416038503000038, 8.84442075700008],
              [50.415920890000052, 8.843952844000057],
              [50.415803278000055, 8.84353172100009],
              [50.415615099000036, 8.843110597000077],
              [50.415473964000057, 8.842759661000059],
              [50.415262262000056, 8.842478911000057],
              [50.41516817300004, 8.842291745000068],
              [50.414849415000049, 8.841742642000042],
              [50.414718545000085, 8.841183469000043],
              [50.414885903000084, 8.840537054000038],
              [50.415144650000059, 8.839975551000066],
              [50.415384794000033, 8.83939887300005],
              [50.414753915000063, 8.838233126000091],
              [50.413732656000036, 8.836491796000075],
              [50.413623993000044, 8.836281779000046],
              [50.413453563000076, 8.835946792000072],
              [50.41298058600006, 8.835062366000045],
              [50.412604227000088, 8.834337080000068],
              [50.412077343000078, 8.833616783000082],
              [50.411733897000033, 8.832933298000057],
              [50.411381061000043, 8.832441973000073],
              [50.411304018000067, 8.83218910700009],
              [50.411137456000063, 8.831808393000074],
              [50.410911407000071, 8.831594241000062],
              [50.410899510000036, 8.831332501000077],
              [50.410875715000088, 8.83095178700006],
              [50.410875715000088, 8.830666251000082],
              [50.410982791000038, 8.830059489000064],
              [50.411058203000039, 8.829844071000082],
              [50.411192881000034, 8.829634388000045],
              [50.411334016000069, 8.82947061200008],
              [50.411428106000074, 8.829306835000068],
              [50.41149867300004, 8.829119662000039],
              [50.411475151000047, 8.82881550500008],
              [50.411334016000069, 8.828277381000078],
              [50.411286971000038, 8.827832843000067],
              [50.411286971000038, 8.82743509800008],
              [50.411286971000038, 8.827107543000068],
              [50.411310494000077, 8.826733195000088],
              [50.411404583000035, 8.826429036000093],
              [50.411451628000066, 8.825890908000076],
              [50.411451628000066, 8.82544636700004],
              [50.411310494000077, 8.824908238000091],
              [50.411089867000044, 8.823813404000077],
              [50.411161250000077, 8.822016911000048],
              [50.410851921000074, 8.822028808000084],
              [50.410471207000057, 8.820934256000044],
              [50.410447412000053, 8.819899190000058],
              [50.41064966600004, 8.819673142000056],
              [50.410766142000057, 8.818739435000055],
              [50.41041172000007, 8.815925491000087],
              [50.410174915000084, 8.815803892000076],
              [50.409935828000073, 8.814616787000091],
              [50.409947725000052, 8.813450851000084],
              [50.409923931000037, 8.811856612000042],
              [50.409852547000071, 8.809893557000066],
              [50.409638395000059, 8.808239832000083],
              [50.409388552000053, 8.80752599300007],
              [50.40952278900005, 8.807009145000052],
              [50.409452221000038, 8.806658174000063],
              [50.409405176000064, 8.806190211000057],
              [50.409358132000079, 8.805769045000091],
              [50.409311087000049, 8.80539467400007],
              [50.408919020000042, 8.804903312000079],
              [50.408801408000045, 8.804388551000045],
              [50.408757995000087, 8.803695061000042],
              [50.409075862000066, 8.803265433000092],
              [50.409264042000075, 8.803101645000083],
              [50.409519422000074, 8.802993120000053],
              [50.409483730000034, 8.802671893000081],
              [50.409019736000062, 8.802267384000061],
              [50.408888865000051, 8.801113346000079],
              [50.408817481000085, 8.800375713000051],
              [50.408769892000066, 8.799887923000085],
              [50.408911205000038, 8.79933449400005],
              [50.408911205000038, 8.798796326000058],
              [50.408934728000077, 8.798375151000073],
              [50.40898177300005, 8.798024171000066],
              [50.409052340000073, 8.797790185000053],
              [50.409135927000079, 8.797644677000051],
              [50.409144943000058, 8.796855497000081],
              [50.40912290700004, 8.796830838000062],
              [50.408958250000069, 8.796386262000055],
              [50.408958250000069, 8.795871489000092],
              [50.408958250000069, 8.79542691100005],
              [50.408934728000077, 8.794982333000064],
              [50.408934728000077, 8.794701547000045],
              [50.408934728000077, 8.794641685000045],
              [50.408431836000034, 8.792907479000064],
              [50.408234513000082, 8.790072647000045],
              [50.407410577000064, 8.785796115000039],
              [50.407151859000066, 8.784219173000054],
              [50.407056680000039, 8.78377897200005],
              [50.40677114500005, 8.783088929000087],
              [50.406449918000078, 8.782672523000087],
              [50.406378534000055, 8.782386988000042],
              [50.406164382000043, 8.782101452000063],
              [50.405923856000072, 8.781902141000046],
              [50.405876812000088, 8.781434147000084],
              [50.405806244000075, 8.781129951000082],
              [50.405688632000079, 8.780849154000066],
              [50.405571020000082, 8.780404558000043],
              [50.405476930000077, 8.780264159000069],
              [50.405406363000054, 8.779936562000046],
              [50.405335796000088, 8.779632364000065],
              [50.405124094000087, 8.779023968000047],
              [50.404794780000088, 8.778485771000078],
              [50.404512510000075, 8.778111372000069],
              [50.404159674000084, 8.777245574000062],
              [50.40406558400008, 8.776800974000082],
              [50.403783315000055, 8.776122373000078],
              [50.403618658000084, 8.775677771000062],
              [50.403406956000083, 8.775303370000074],
              [50.403171732000033, 8.774975768000047],
              [50.402936507000049, 8.774624766000045],
              [50.402654238000082, 8.774203563000071],
              [50.40239549100005, 8.773946161000083],
              [50.40230506100005, 8.773856201000058],
              [50.402066991000083, 8.773283589000073],
              [50.401995610000085, 8.773267554000086],
              [50.401642774000038, 8.772986751000076],
              [50.401360504000081, 8.772659148000059],
              [50.401078235000057, 8.772331544000053],
              [50.400843011000063, 8.771863537000058],
              [50.400725399000066, 8.77144233100006],
              [50.400607787000069, 8.771091326000089],
              [50.400490174000083, 8.770599917000084],
              [50.40041960700006, 8.770131909000042],
              [50.400490174000083, 8.769897904000061],
              [50.400585862000071, 8.769721121000089],
              [50.399882605000073, 8.767610535000074],
              [50.399444401000039, 8.76761042600009],
              [50.399180664000085, 8.767122745000052],
              [50.399170841000057, 8.766297253000062],
              [50.397840073000054, 8.764277708000066],
              [50.397176927000032, 8.76273017300008],
              [50.396315709000078, 8.761187993000078],
              [50.396279659000072, 8.76086521700006],
              [50.395926822000035, 8.760022779000053],
              [50.395526941000071, 8.759227141000054],
              [50.395290246000059, 8.758699453000077],
              [50.394992813000044, 8.758378226000048],
              [50.394766764000053, 8.758235458000058],
              [50.394409845000041, 8.758009410000057],
              [50.393992304000051, 8.757027428000072],
              [50.393856848000041, 8.757027428000072],
              [50.393551057000082, 8.756887020000079],
              [50.393315832000042, 8.756676409000079],
              [50.393057086000056, 8.756418994000057],
              [50.392892429000085, 8.756301988000075],
              [50.392680727000084, 8.756255185000043],
              [50.392445502000044, 8.755974369000057],
              [50.392363509000063, 8.755606154000077],
              [50.392577660000086, 8.755296824000084],
              [50.392845384000054, 8.755342532000043],
              [50.392933083000059, 8.755130645000065],
              [50.392756120000058, 8.754428321000091],
              [50.392383480000035, 8.752656303000037],
              [50.391815201000043, 8.750758307000069],
              [50.391810397000086, 8.750638824000077],
              [50.391792438000039, 8.749431453000057],
              [50.391554492000068, 8.748586744000079],
              [50.391352238000081, 8.748015673000054],
              [50.391173778000052, 8.74757547300004],
              [50.390893022000057, 8.747292368000046],
              [50.390971524000065, 8.746373845000051],
              [50.39081685900004, 8.745957440000041],
              [50.390769270000078, 8.744910477000076],
              [50.390389593000066, 8.74370562200005],
              [50.389660123000056, 8.741431630000079],
              [50.389027915000042, 8.73896409200006],
              [50.388444338000056, 8.736061084000085],
              [50.387909393000086, 8.733158053000068],
              [50.387325816000043, 8.730351768000048],
              [50.387254269000039, 8.730297946000064],
              [50.387277185000073, 8.730109846000062],
              [50.387256069000045, 8.72931152600006],
              [50.387105910000059, 8.728944824000052],
              [50.387011820000055, 8.72840655300007],
              [50.386759878000078, 8.727944920000084],
              [50.386611939000034, 8.726557704000072],
              [50.386494327000037, 8.726113042000065],
              [50.386470804000055, 8.725598171000058],
              [50.386470804000055, 8.725270525000042],
              [50.386541372000067, 8.724872668000046],
              [50.386562603000073, 8.724845912000092],
              [50.386688768000056, 8.724110684000038],
              [50.386988298000063, 8.723796114000038],
              [50.387176477000082, 8.723445063000042],
              [50.387411702000065, 8.723000398000067],
              [50.387599881000085, 8.722579136000093],
              [50.387664073000053, 8.72202006200007],
              [50.387764538000056, 8.721549382000092],
              [50.387599881000085, 8.721104714000091],
              [50.387388179000084, 8.720730257000071],
              [50.387082659000043, 8.720402898000088],
              [50.387082659000043, 8.719561888000044],
              [50.38708110500005, 8.71832],
              [50.386255926000047, 8.716949044000046],
              [50.386057937000032, 8.715833463000081],
              [50.38640182000006, 8.714045862000091],
              [50.385964138000077, 8.710997497000051],
              [50.385623718000033, 8.707997495000086],
              [50.385234667000077, 8.706303934000061],
              [50.384505196000077, 8.703352281000093],
              [50.383678463000081, 8.701126429000055],
              [50.383143517000065, 8.699868332000051],
              [50.382024996000041, 8.697690848000093],
              [50.381052368000041, 8.696335962000092],
              [50.380031109000072, 8.694884293000086],
              [50.378836273000047, 8.693490324000038],
              [50.378396072000044, 8.693014432000041],
              [50.378193818000057, 8.692705102000048],
              [50.378120341000056, 8.692223590000083],
              [50.377885116000073, 8.691849103000038],
              [50.377673414000071, 8.69154483300008],
              [50.377367623000055, 8.691240562000075],
              [50.377108876000079, 8.691029913000079],
              [50.376873652000086, 8.690842669000062],
              [50.376614905000054, 8.69070223600005],
              [50.37637968100006, 8.690491587000054],
              [50.376144456000077, 8.690234126000064],
              [50.375909232000083, 8.68992985400007],
              [50.375674008000033, 8.689648988000044],
              [50.37548582800008, 8.689555366000093],
              [50.375274126000079, 8.689508555000089],
              [50.374968335000062, 8.689368121000086],
              [50.374803678000035, 8.689251093000053],
              [50.374521408000078, 8.689040443000067],
              [50.374262662000035, 8.688853198000061],
              [50.374050960000034, 8.688665953000054],
              [50.373862780000081, 8.688525520000042],
              [50.37357901200005, 8.688525520000042],
              [50.373481668000068, 8.688466816000073],
              [50.373161258000039, 8.688243613000054],
              [50.37287572300005, 8.68811274300009],
              [50.372257063000063, 8.687720132000038],
              [50.371626506000041, 8.687256137000077],
              [50.371067333000042, 8.686804039000037],
              [50.370448673000055, 8.686185379000051],
              [50.370020370000077, 8.68581656300006],
              [50.369376707000072, 8.685141214000055],
              [50.368687872000066, 8.684769600000038],
              [50.368003287000079, 8.684438005000061],
              [50.36751909700007, 8.68432682200006],
              [50.367253719000075, 8.684029389000045],
              [50.367176369000049, 8.684029389000045],
              [50.367067492000047, 8.684029389000045],
              [50.366701022000086, 8.683841610000059],
              [50.366237027000068, 8.68368694500009],
              [50.366248093000081, 8.683399353000084],
              [50.365761134000081, 8.683258642000055],
              [50.365837184000043, 8.682906260000038],
              [50.365708393000034, 8.682892330000072],
              [50.365546983000058, 8.682830339000077],
              [50.365261448000069, 8.682782750000058],
              [50.364666582000041, 8.68259239300005],
              [50.364416739000035, 8.68231875500004],
              [50.364095512000063, 8.682045117000087],
              [50.363869463000071, 8.681795274000081],
              [50.363524441000038, 8.681450252000047],
              [50.362655938000046, 8.680831592000061],
              [50.361394824000058, 8.679356326000061],
              [50.360819591000052, 8.67811572100004],
              [50.360466755000061, 8.677711963000093],
              [50.360095615000034, 8.677154233000067],
              [50.359890455000084, 8.676845928000091],
              [50.359455290000085, 8.676213956000083],
              [50.359236150000072, 8.676319723000063],
              [50.359205719000045, 8.676334411000084],
              [50.358920184000056, 8.676096465000057],
              [50.358800115000065, 8.675896349000084],
              [50.358777416000066, 8.675858518000041],
              [50.358622751000041, 8.675715751000041],
              [50.358613809000076, 8.675715453000066],
              [50.358265832000086, 8.675703853000073],
              [50.358247972000072, 8.675360238000053],
              [50.35823212400004, 8.67505533700006],
              [50.358055706000073, 8.674809569000047],
              [50.357611481000049, 8.674728274000074],
              [50.35746871300006, 8.674430842000049],
              [50.357597018000035, 8.67406056100009],
              [50.357420600000069, 8.673838198000055],
              [50.357279465000033, 8.673627539000051],
              [50.35709128600007, 8.673416880000048],
              [50.356914868000047, 8.67315940800006],
              [50.356785494000064, 8.673030671000049],
              [50.356656121000071, 8.672878528000069],
              [50.356573792000063, 8.672749792000047],
              [50.35650322500004, 8.672585946000083],
              [50.356409135000035, 8.672410396000089],
              [50.356291523000039, 8.672269956000093],
              [50.356173911000042, 8.672117813000057],
              [50.356057601000032, 8.671990502000085],
              [50.355958575000045, 8.671801993000088],
              [50.354499634000035, 8.670350228000075],
              [50.353429744000039, 8.668801673000075],
              [50.353171538000083, 8.668406385000083],
              [50.353068950000079, 8.668080142000065],
              [50.352998383000056, 8.667834369000047],
              [50.352959634000058, 8.667542302000072],
              [50.352704352000046, 8.667424747000041],
              [50.352574979000053, 8.667307712000081],
              [50.352422083000079, 8.667202381000038],
              [50.352358967000043, 8.667162414000074],
              [50.352165328000069, 8.666865969000071],
              [50.351727933000063, 8.66658138300005],
              [50.351669365000078, 8.666406543000051],
              [50.351587037000058, 8.666312915000049],
              [50.351528230000042, 8.666219287000047],
              [50.351469424000072, 8.666160769000044],
              [50.351293006000049, 8.666055438000058],
              [50.351163633000056, 8.666008624000085],
              [50.350989659000049, 8.665955784000062],
              [50.350746736000076, 8.665888577000089],
              [50.350330330000077, 8.665614939000079],
              [50.350052783000081, 8.665375286000085],
              [50.349706577000063, 8.66490725400007],
              [50.349533210000061, 8.664056392000077],
              [50.349444100000085, 8.663291249000054],
              [50.34942297300006, 8.663281693000044],
              [50.349305361000063, 8.663223175000041],
              [50.34912894200005, 8.663164656000049],
              [50.348964285000079, 8.663094435000062],
              [50.348764345000063, 8.662977399000056],
              [50.348634971000081, 8.66286036200006],
              [50.348482075000049, 8.66271991900004],
              [50.348352702000057, 8.662544364000041],
              [50.348117431000048, 8.662366975000054],
              [50.347976343000084, 8.662099626000042],
              [50.347846970000035, 8.661888960000056],
              [50.347752880000087, 8.661713406000047],
              [50.347617744000047, 8.661474677000058],
              [50.347399452000047, 8.661445945000082],
              [50.347248928000056, 8.660320638000087],
              [50.347034776000044, 8.660142178000058],
              [50.346665960000053, 8.659904232000088],
              [50.346201965000034, 8.659832849000054],
              [50.34590677500006, 8.659614164000061],
              [50.345619631000034, 8.659086645000059],
              [50.345199960000059, 8.658537144000093],
              [50.345045345000074, 8.658317344000068],
              [50.344890729000042, 8.658163484000056],
              [50.344647762000079, 8.658075563000068],
              [50.344448971000077, 8.657921703000056],
              [50.344227012000033, 8.656977495000092],
              [50.344084245000033, 8.656549192000057],
              [50.343881991000046, 8.656216068000049],
              [50.343499189000056, 8.655899531000045],
              [50.343322486000034, 8.655437947000053],
              [50.343101606000062, 8.655020322000041],
              [50.342991167000037, 8.654602698000076],
              [50.342615672000079, 8.653195958000083],
              [50.342284352000036, 8.652492586000051],
              [50.342133087000036, 8.651790270000049],
              [50.341643802000078, 8.651525448000086],
              [50.341312483000081, 8.651195741000038],
              [50.341288378000058, 8.650802794000072],
              [50.340943356000082, 8.650386388000072],
              [50.340729205000059, 8.649910496000075],
              [50.340479361000064, 8.649601166000082],
              [50.340372286000047, 8.649041993000083],
              [50.339777420000075, 8.648090209000088],
              [50.339170658000057, 8.647007554000083],
              [50.338444922000065, 8.645841618000077],
              [50.337802468000064, 8.644116509000071],
              [50.337137863000066, 8.643128822000051],
              [50.336933964000082, 8.642474681000067],
              [50.336517559000072, 8.641320642000039],
              [50.336136845000055, 8.640606804000072],
              [50.335768028000075, 8.640130911000085],
              [50.335518185000069, 8.63959553300009],
              [50.335411109000063, 8.639167230000055],
              [50.335244547000059, 8.638631851000071],
              [50.335268342000063, 8.638025088000063],
              [50.335030396000036, 8.637680067000076],
              [50.33501239900005, 8.637107808000053],
              [50.33501239900005, 8.636624219000055],
              [50.334804347000045, 8.635026968000091],
              [50.334554503000049, 8.634063286000071],
              [50.334328455000048, 8.633575496000049],
              [50.334089727000048, 8.633171321000077],
              [50.334023463000051, 8.632797635000088],
              [50.333913024000083, 8.632577819000062],
              [50.333780496000088, 8.632379985000057],
              [50.333581704000039, 8.63209422400007],
              [50.333382913000037, 8.631764500000088],
              [50.333139946000074, 8.631258923000075],
              [50.333007418000079, 8.630577491000054],
              [50.332852802000048, 8.629874076000078],
              [50.332654011000045, 8.629368496000041],
              [50.331925109000053, 8.627697879000038],
              [50.331880933000036, 8.627390133000063],
              [50.331571702000076, 8.626269056000069],
              [50.331461262000062, 8.62580743500007],
              [50.331063679000067, 8.624774281000043],
              [50.330820712000047, 8.624048873000049],
              [50.330644008000036, 8.623631214000056],
              [50.330449933000068, 8.622879819000048],
              [50.329771787000084, 8.622356338000088],
              [50.329893019000053, 8.62191660600007],
              [50.330091810000056, 8.621740749000082],
              [50.330290601000058, 8.620641637000062],
              [50.330497522000087, 8.619941185000073],
              [50.330342857000062, 8.619524779000074],
              [50.330223884000077, 8.619036990000041],
              [50.329771787000084, 8.618727660000047],
              [50.329186205000042, 8.618355474000055],
              [50.329097853000064, 8.617871860000037],
              [50.329438662000086, 8.617347572000085],
              [50.329355381000084, 8.616609939000057],
              [50.329236408000043, 8.615646258000083],
              [50.328941474000032, 8.614777730000071],
              [50.328938975000085, 8.613290591000066],
              [50.32905794800007, 8.611505996000062],
              [50.328938975000085, 8.610625595000045],
              [50.328843797000047, 8.60981657800005],
              [50.328748619000066, 8.609007561000055],
              [50.328593954000041, 8.608365107000054],
              [50.328498775000071, 8.607841626000038],
              [50.328415494000069, 8.607211068000083],
              [50.328296521000084, 8.606556717000046],
              [50.328070472000036, 8.605878570000073],
              [50.328010986000038, 8.605188527000053],
              [50.327918757000077, 8.604768288000059],
              [50.327830406000032, 8.604284657000051],
              [50.327642169000057, 8.603760850000072],
              [50.327380428000083, 8.60271388700005],
              [50.327071099000079, 8.602666298000088],
              [50.326911147000033, 8.602901523000071],
              [50.32655266900008, 8.60263591100005],
              [50.326464317000045, 8.602504011000065],
              [50.326510250000069, 8.60211658500009],
              [50.326488162000032, 8.601720885000077],
              [50.326452439000036, 8.601393286000075],
              [50.326476233000051, 8.601107751000086],
              [50.326559514000053, 8.600774626000089],
              [50.326571412000078, 8.600489091000043],
              [50.326607104000061, 8.600227350000068],
              [50.326666590000059, 8.600013199000045],
              [50.326737974000082, 8.599858534000077],
              [50.326987817000088, 8.599751458000071],
              [50.327130585000077, 8.599549204000084],
              [50.327183815000069, 8.598351697000055],
              [50.327154114000052, 8.598263014000054],
              [50.327130585000077, 8.597824095000078],
              [50.32730904500005, 8.597621841000091],
              [50.327487504000032, 8.597383894000075],
              [50.327511299000037, 8.597110256000065],
              [50.327356634000068, 8.596967489000065],
              [50.327511299000037, 8.596420213000044],
              [50.32743991500007, 8.595896731000039],
              [50.327378456000076, 8.595220213000061],
              [50.327290104000042, 8.594846489000076],
              [50.327285250000045, 8.594219211000052],
              [50.327261455000041, 8.593695730000093],
              [50.327380428000083, 8.592969994000043],
              [50.327463710000075, 8.59224425900004],
              [50.327665964000062, 8.591625599000054],
              [50.327820629000087, 8.590971247000084],
              [50.327892013000053, 8.590483458000051],
              [50.327951499000051, 8.590043257000048],
              [50.328170159000081, 8.589073762000055],
              [50.328498775000071, 8.58868696400009],
              [50.328617748000056, 8.588222970000061],
              [50.328772413000081, 8.587473439000064],
              [50.328962770000032, 8.58706893100009],
              [50.329010359000051, 8.586676320000038],
              [50.329200716000059, 8.586152839000079],
              [50.329260203000047, 8.585486589000084],
              [50.329179428000032, 8.585096424000085],
              [50.329378219000034, 8.584590783000067],
              [50.329561699000067, 8.58419325300008],
              [50.329826755000056, 8.583929440000077],
              [50.330135986000073, 8.583819518000041],
              [50.330577745000085, 8.583621658000084],
              [50.330909064000082, 8.583621658000084],
              [50.331385758000067, 8.583578525000064],
              [50.331611468000062, 8.583409596000081],
              [50.332317810000063, 8.582298112000046],
              [50.332165614000075, 8.582142124000086],
              [50.332210187000044, 8.581728055000042],
              [50.332403337000073, 8.581387927000037],
              [50.33258163000005, 8.58116610400009],
              [50.332655918000057, 8.58097385700006],
              [50.332774780000079, 8.580722458000082],
              [50.332967930000052, 8.580530211000053],
              [50.333309658000076, 8.580249235000053],
              [50.333383946000083, 8.580130929000063],
              [50.333443377000037, 8.579805587000067],
              [50.333473092000077, 8.579554187000042],
              [50.333532523000088, 8.579317575000061],
              [50.333888254000044, 8.579264299000045],
              [50.334042919000069, 8.579014456000039],
              [50.334019125000054, 8.578752715000064],
              [50.334221379000041, 8.577884212000072],
              [50.334721066000043, 8.577586779000058],
              [50.334756758000083, 8.577348833000087],
              [50.334376044000066, 8.577122784000039],
              [50.334542257000066, 8.574981219000051],
              [50.334554503000049, 8.573743949000061],
              [50.334840039000085, 8.572387657000093],
              [50.33454260600007, 8.572018840000055],
              [50.334928838000053, 8.571387574000084],
              [50.334789188000059, 8.570300098000075],
              [50.334665555000072, 8.57014627600006],
              [50.334434010000052, 8.569282017000091],
              [50.334202464000043, 8.568705842000043],
              [50.333935844000052, 8.568544827000039],
              [50.333763366000085, 8.568220163000092],
              [50.333733589000076, 8.56816411300008],
              [50.333650308000074, 8.567319405000092],
              [50.333669379000071, 8.56669006900006],
              [50.333674103000078, 8.566534183000044],
              [50.333768316000032, 8.565536869000084],
              [50.333768316000032, 8.564759025000058],
              [50.333884089000037, 8.564240462000043],
              [50.333987294000053, 8.564055554000049],
              [50.333956062000084, 8.563812342000062],
              [50.334173521000082, 8.563577853000083],
              [50.334202464000043, 8.563203334000093],
              [50.334202464000043, 8.562540723000041],
              [50.334318237000048, 8.56210858500009],
              [50.334756758000083, 8.561799055000051],
              [50.33468537400006, 8.560585530000083],
              [50.334434010000052, 8.559775029000093],
              [50.334347180000066, 8.559112412000047],
              [50.334086691000039, 8.558478603000083],
              [50.333999862000042, 8.557643126000073],
              [50.333884089000037, 8.557210982000072],
              [50.333681486000046, 8.556721219000053],
              [50.333565714000088, 8.556173835000038],
              [50.333420998000065, 8.555626450000091],
              [50.333305225000061, 8.554992636000065],
              [50.333160509000038, 8.554618108000057],
              [50.332826393000062, 8.554546842000093],
              [50.332813191000071, 8.554531679000092],
              [50.33261058800008, 8.554272390000051],
              [50.332407986000078, 8.554070721000073],
              [50.332263270000055, 8.553782623000075],
              [50.332321156000035, 8.553552144000037],
              [50.332552702000044, 8.553148805000092],
              [50.332726361000084, 8.553033565000078],
              [50.332755304000045, 8.552831896000043],
              [50.332726361000084, 8.552457366000056],
              [50.332697418000066, 8.551967596000054],
              [50.332668475000048, 8.551765926000087],
              [50.332494815000075, 8.551506636000056],
              [50.332407986000078, 8.551103295000075],
              [50.332407986000078, 8.550642333000042],
              [50.332032275000074, 8.550567999000066],
              [50.332118554000033, 8.550094941000054],
              [50.331973838000067, 8.549835649000045],
              [50.331944895000049, 8.549605168000085],
              [50.331858065000063, 8.549288255000079],
              [50.331742292000058, 8.549086584000065],
              [50.331510747000038, 8.548798481000063],
              [50.331337087000065, 8.548452758000053],
              [50.331337087000065, 8.548222276000047],
              [50.331192371000043, 8.547962983000048],
              [50.330989769000041, 8.547588448000056],
              [50.330902940000044, 8.547213914000054],
              [50.330845053000075, 8.546983431000058],
              [50.330584564000048, 8.546522464000077],
              [50.330104911000035, 8.546273072000076],
              [50.329974041000071, 8.545594926000092],
              [50.329866965000065, 8.544940574000066],
              [50.326975920000052, 8.537159736000092],
              [50.325843860000077, 8.535239218000072],
              [50.325429270000086, 8.534292486000083],
              [50.324001594000038, 8.531758360000083],
              [50.323132393000037, 8.529066456000066],
              [50.321753003000083, 8.526261805000047],
              [50.320741732000045, 8.523430246000089],
              [50.319864997000082, 8.522675201000084],
              [50.319436408000058, 8.52146592400004],
              [50.318457450000039, 8.518243021000046],
              [50.317493768000077, 8.516886729000078],
              [50.316178106000052, 8.515220786000043],
              [50.31335748500004, 8.511638257000072],
              [50.311319067000056, 8.509427118000076],
              [50.310141233000081, 8.507987544000059],
              [50.308963400000039, 8.506428998000047],
              [50.308511303000046, 8.505655673000092],
              [50.307155010000088, 8.504216099000075],
              [50.306376739000086, 8.503841930000078],
              [50.305382311000074, 8.502550476000067],
              [50.303359770000043, 8.499147847000074],
              [50.301801223000041, 8.496209213000043],
              [50.299421762000065, 8.493258681000043],
              [50.297952193000071, 8.491911186000038],
              [50.295374730000049, 8.490119815000071],
              [50.293613145000052, 8.488665694000076],
              [50.291862415000082, 8.487358467000092],
              [50.289868529000046, 8.485857571000054],
              [50.289262199000063, 8.484789935000038],
              [50.286490211000057, 8.481642867000062],
              [50.284762234000084, 8.479708679000055],
              [50.282476559000088, 8.477239411000085],
              [50.280628566000075, 8.47501221400006],
              [50.277467526000066, 8.471816648000072],
              [50.276155835000054, 8.470258802000046],
              [50.27463967500006, 8.469678222000084],
              [50.273577016000047, 8.469202074000066],
              [50.272307803000047, 8.468631259000063],
              [50.272188830000061, 8.46748911800006],
              [50.271356019000052, 8.466049544000043],
              [50.268130301000042, 8.46363393900009],
              [50.265504207000049, 8.461358243000063],
              [50.263607583000066, 8.45995408400006],
              [50.259911598000087, 8.45772678600008],
              [50.256924588000061, 8.45527058600004],
              [50.254951197000082, 8.453223730000047],
              [50.253521959000068, 8.452034519000051],
              [50.252308434000042, 8.450820994000082],
              [50.251071114000069, 8.449250550000045],
              [50.249417389000087, 8.447787181000081],
              [50.247501922000083, 8.445705153000063],
              [50.245443689000069, 8.44271892900008],
              [50.243635298000072, 8.440268084000081],
              [50.242100935000053, 8.43912626000008],
              [50.240556309000056, 8.437535249000064],
              [50.239173809000079, 8.435877979000054],
              [50.237881583000046, 8.433613027000092],
              [50.235993730000075, 8.430797604000077],
              [50.234388894000062, 8.427698516000078],
              [50.232963416000075, 8.424920561000079],
              [50.231802660000085, 8.421749273000046],
              [50.230798106000066, 8.419067087000087],
              [50.230440981000072, 8.416519420000043],
              [50.230417392000049, 8.414117808000071],
              [50.231000360000053, 8.413689505000093],
              [50.230810004000034, 8.412464082000042],
              [50.229013510000073, 8.40984667500004],
              [50.226990969000042, 8.406670095000038],
              [50.226181952000047, 8.405266213000061],
              [50.225294856000062, 8.402801082000053],
              [50.224563918000058, 8.402065838000055],
              [50.223535325000057, 8.401337912000088],
              [50.222303430000068, 8.400186064000081],
              [50.220352272000071, 8.398746490000065],
              [50.218650958000069, 8.397211737000077],
              [50.21754450800006, 8.395891136000046],
              [50.216711697000051, 8.395522320000055],
              [50.216050452000047, 8.395196153000086],
              [50.214974691000066, 8.394130335000057],
              [50.213630295000087, 8.392869221000069],
              [50.212797267000042, 8.392527206000068],
              [50.211988467000083, 8.391869847000066],
              [50.211346013000082, 8.390965652000091],
              [50.210679763000087, 8.390822885000091],
              [50.209954028000084, 8.39014473800006],
              [50.20965659500007, 8.389859203000071],
              [50.20937106000008, 8.38959746200004],
              [50.209204498000076, 8.389300030000072],
              [50.209009300000048, 8.388862345000064],
              [50.208744717000059, 8.388500061000059],
              [50.207800616000043, 8.38692056900004],
              [50.207110572000033, 8.385873606000075],
              [50.206218274000037, 8.38466008100005],
              [50.206027917000085, 8.384267469000065],
              [50.205730485000061, 8.383767783000053],
              [50.205433052000046, 8.383506042000079],
              [50.205171311000072, 8.383339480000075],
              [50.204445576000069, 8.382732717000067],
              [50.203940468000042, 8.382253337000066],
              [50.203593202000036, 8.381891047000067],
              [50.20327115200007, 8.381550737000055],
              [50.203023106000046, 8.38102376900008],
              [50.202758522000067, 8.38064501100007],
              [50.202527012000076, 8.380299187000048],
              [50.202363547000061, 8.379984440000044],
              [50.202315958000042, 8.379627521000089],
              [50.202292163000038, 8.379223012000068],
              [50.202149396000038, 8.378973169000062],
              [50.201899552000043, 8.378675736000048],
              [50.201816271000041, 8.378425893000042],
              [50.201768682000079, 8.378211741000086],
              [50.201625914000033, 8.377890514000057],
              [50.201530736000052, 8.37755738900006],
              [50.20135227600008, 8.377271854000071],
              [50.201161919000072, 8.376879243000076],
              [50.201054844000055, 8.376474735000045],
              [50.200923973000044, 8.376141610000047],
              [50.200626541000076, 8.375630026000067],
              [50.200397627000086, 8.375219746000084],
              [50.200079618000075, 8.374848308000082],
              [50.199848108000083, 8.37463422400009],
              [50.199649670000042, 8.374403671000039],
              [50.199517379000042, 8.374189586000057],
              [50.199056096000049, 8.373559895000085],
              [50.198988212000074, 8.373217970000042],
              [50.19892206600008, 8.373168566000061],
              [50.198822848000077, 8.373069757000053],
              [50.19877056100006, 8.37285795400004],
              [50.198520718000054, 8.372584316000086],
              [50.198342258000082, 8.372251191000089],
              [50.19822328500004, 8.371846683000058],
              [50.198104312000055, 8.371501661000082],
              [50.197866366000085, 8.371156639000048],
              [50.197797588000071, 8.370895961000087],
              [50.197731442000077, 8.37068187400007],
              [50.197499932000085, 8.370286637000049],
              [50.197152666000079, 8.370039614000063],
              [50.196987302000082, 8.369841995000058],
              [50.196871547000057, 8.369413820000091],
              [50.196755792000033, 8.369084455000063],
              [50.196557354000049, 8.368820962000086],
              [50.196309308000082, 8.368442192000089],
              [50.196143943000038, 8.368112825000082],
              [50.196028188000071, 8.36786580100005],
              [50.195879360000049, 8.367750522000051],
              [50.195867619000069, 8.367575551000073],
              [50.195736748000058, 8.367385194000065],
              [50.195598240000038, 8.367025915000056],
              [50.195439316000034, 8.366837918000044],
              [50.195284651000065, 8.366528588000051],
              [50.195153780000055, 8.366290642000081],
              [50.194887173000041, 8.365848425000081],
              [50.194655663000049, 8.365535525000041],
              [50.194506835000084, 8.365255562000073],
              [50.194291861000067, 8.364827382000044],
              [50.194126496000081, 8.364530950000074],
              [50.193944595000062, 8.364201580000042],
              [50.193812304000062, 8.36388867900007],
              [50.193514648000075, 8.363312281000049],
              [50.193183919000035, 8.362851162000084],
              [50.192702936000046, 8.362055201000089],
              [50.192536373000053, 8.361864844000081],
              [50.192405503000032, 8.361698282000077],
              [50.192119968000043, 8.361258082000063],
              [50.191827930000045, 8.360693777000051],
              [50.191546810000034, 8.360249123000074],
              [50.191331837000064, 8.359903281000072],
              [50.191100326000083, 8.359359815000062],
              [50.190951498000061, 8.359046910000075],
              [50.190769597000042, 8.358783410000058],
              [50.190571160000047, 8.358503442000085],
              [50.190442448000056, 8.358259961000044],
              [50.190393076000078, 8.357959974000039],
              [50.190145029000064, 8.357400036000058],
              [50.189954658000033, 8.35707023000009],
              [50.189752404000046, 8.356701414000042],
              [50.189636440000072, 8.356346033000079],
              [50.189520685000048, 8.356115469000088],
              [50.189454971000032, 8.355785321000042],
              [50.189347896000072, 8.355428402000086],
              [50.189074257000073, 8.355071483000074],
              [50.18886010600005, 8.35435764500005],
              [50.188118958000075, 8.353746666000063],
              [50.187682273000064, 8.352870482000071],
              [50.187337251000088, 8.352001978000089],
              [50.186980332000076, 8.350372047000064],
              [50.186659105000047, 8.348825398000088],
              [50.186540132000061, 8.348337608000065],
              [50.186325980000049, 8.347778435000066],
              [50.186088034000079, 8.347338235000052],
              [50.185885780000035, 8.347064597000042],
              [50.185759053000083, 8.346629306000068],
              [50.185692908000078, 8.346234044000084],
              [50.185600244000057, 8.345363282000051],
              [50.185576450000042, 8.344161654000061],
              [50.18552886100008, 8.343614378000041],
              [50.185516963000055, 8.342948129000092],
              [50.185615239000072, 8.342028904000074],
              [50.185598703000039, 8.341370126000072],
              [50.185683526000048, 8.340604360000043],
              [50.185695423000084, 8.340211749000048],
              [50.185808663000046, 8.339794512000083],
              [50.186147520000077, 8.339676370000063],
              [50.186266494000051, 8.339486013000055],
              [50.186302185000045, 8.339188581000087],
              [50.186362451000036, 8.338633518000051],
              [50.186448745000064, 8.338440143000071],
              [50.186525362000054, 8.338076474000047],
              [50.186706694000065, 8.337986953000041],
              [50.186468748000038, 8.337047066000082],
              [50.186030949000042, 8.336637016000054],
              [50.185957164000058, 8.336309433000054],
              [50.185457477000057, 8.335797849000073],
              [50.18494161700005, 8.334988129000067],
              [50.18468273600007, 8.334622860000081],
              [50.18456517900006, 8.334346378000077],
              [50.184362925000073, 8.333810999000093],
              [50.183887032000086, 8.333001982000042],
              [50.183444097000063, 8.332602471000087],
              [50.183280270000068, 8.332454706000078],
              [50.182649713000046, 8.331800354000052],
              [50.182399869000051, 8.331633792000048],
              [50.18203105300006, 8.33147912700008],
              [50.18183709300007, 8.331377043000089],
              [50.181578955000077, 8.331241181000053],
              [50.181302638000034, 8.331165866000049],
              [50.180821091000041, 8.331034611000064],
              [50.180324902000052, 8.330841232000068],
              [50.180255269000043, 8.330752023000059],
              [50.180079895000063, 8.330527343000085],
              [50.179734873000086, 8.330396472000075],
              [50.179330365000055, 8.330122834000065],
              [50.178771191000067, 8.329813504000072],
              [50.178295299000069, 8.329420893000076],
              [50.177887104000035, 8.329272705000051],
              [50.177628223000056, 8.32905783800004],
              [50.177498783000033, 8.328842970000039],
              [50.17726147500008, 8.328520669000056],
              [50.177179098000067, 8.32837298600009],
              [50.177151250000065, 8.328394382000056],
              [50.17685885800006, 8.328165568000088],
              [50.17582794100008, 8.327358815000082],
              [50.17396468000004, 8.326601232000087],
              [50.171942138000077, 8.325233042000093],
              [50.171525733000067, 8.324911815000064],
              [50.171418657000061, 8.32516165800007],
              [50.171097430000032, 8.325006993000045],
              [50.17077620200007, 8.324816636000037],
              [50.170454975000041, 8.32461438200005],
              [50.170098056000086, 8.324245566000059],
              [50.169764931000088, 8.323805365000055],
              [50.169324731000074, 8.32326998700006],
              [50.168825044000073, 8.322841684000082],
              [50.168503817000044, 8.322544251000068],
              [50.168170693000036, 8.322187332000055],
              [50.167801876000055, 8.321806618000039],
              [50.167218908000052, 8.32128313700008],
              [50.166743016000055, 8.320759655000074],
              [50.166493173000049, 8.320259968000073],
              [50.166041075000066, 8.319855460000042],
              [50.165707950000069, 8.319331979000083],
              [50.165303442000038, 8.319141622000075],
              [50.165077393000047, 8.318772805000037],
              [50.164798442000063, 8.318336202000069],
              [50.164577706000046, 8.318201735000059],
              [50.164078020000034, 8.317499794000071],
              [50.164232685000059, 8.316893031000063],
              [50.163981909000086, 8.316491289000055],
              [50.163882283000078, 8.316173758000048],
              [50.163459360000047, 8.31579847900008],
              [50.163364181000077, 8.315584328000057],
              [50.163090543000067, 8.315191716000072],
              [50.162805008000078, 8.314680132000092],
              [50.162697932000071, 8.31441839200005],
              [50.162519473000032, 8.314132856000072],
              [50.162269629000036, 8.313847321000083],
              [50.162305321000076, 8.313430915000083],
              [50.162103067000032, 8.31312158500009],
              [50.161722353000073, 8.31281225500004],
              [50.161591483000052, 8.312610001000053],
              [50.161496304000082, 8.312288774000081],
              [50.161317845000042, 8.311955650000073],
              [50.161246461000076, 8.311408373000063],
              [50.161008515000049, 8.310944379000091],
              [50.160901439000043, 8.310742124000058],
              [50.160420913000053, 8.310696052000083],
              [50.160281437000037, 8.310557130000063],
              [50.159913963000065, 8.310016389000054],
              [50.159735503000036, 8.309302550000041],
              [50.159485660000087, 8.30896942600009],
              [50.159319098000083, 8.308303177000084],
              [50.158926487000087, 8.30773210600006],
              [50.15855767000005, 8.30718483000004],
              [50.158176956000034, 8.306649451000055],
              [50.157915216000049, 8.306292532000043],
              [50.157653475000075, 8.30611407300006],
              [50.157534502000033, 8.305828537000082],
              [50.157475015000045, 8.305447824000055],
              [50.157546399000069, 8.304603115000077],
              [50.157296556000063, 8.304388964000054],
              [50.157155924000051, 8.303900494000061],
              [50.157094302000075, 8.303353898000069],
              [50.157129993000069, 8.30292559500009],
              [50.156903945000067, 8.302306935000047],
              [50.156654101000072, 8.30159309700008],
              [50.156535128000087, 8.301319459000069],
              [50.156392361000087, 8.300891156000091],
              [50.156356669000047, 8.300296291000052],
              [50.156320977000064, 8.300022653000042],
              [50.156225798000037, 8.299677631000066],
              [50.156130620000056, 8.29901138200006],
              [50.156094928000073, 8.298452208000072],
              [50.155940263000048, 8.297726473000068],
              [50.155916468000044, 8.29713160700004],
              [50.155860484000073, 8.296393182000088],
              [50.155780784000058, 8.295976401000075],
              [50.155404884000063, 8.295799109000086],
              [50.15536919200008, 8.29527562800007],
              [50.15519073300004, 8.294704557000045],
              [50.155047965000051, 8.294216768000069],
              [50.154750532000037, 8.293788465000091],
              [50.15467914900006, 8.293443443000058],
              [50.15440551100005, 8.29287237200009],
              [50.15375115900008, 8.292789091000088],
              [50.153644083000074, 8.292063356000085],
              [50.153763056000059, 8.29163505300005],
              [50.153525110000032, 8.29112346800008],
              [50.153084910000075, 8.29108777600004],
              [50.152789590000054, 8.291097644000047],
              [50.15269229900008, 8.290921214000093],
              [50.152549531000034, 8.290826036000055],
              [50.152609018000078, 8.290588090000085],
              [50.152668884000036, 8.29013309000004],
              [50.152609108000036, 8.289716302000045],
              [50.152454353000053, 8.289315078000072],
              [50.152394866000066, 8.28910092700005],
              [50.152049844000032, 8.289077132000045],
              [50.151706226000044, 8.288838999000063],
              [50.151585849000071, 8.287934991000043],
              [50.151336006000065, 8.287637558000085],
              [50.151026676000072, 8.287613763000081],
              [50.150705449000043, 8.287375817000054],
              [50.150788730000045, 8.287137871000084],
              [50.150681654000039, 8.286899925000057],
              [50.150336632000062, 8.286721466000074],
              [50.150003508000054, 8.286566801000049],
              [50.149880522000046, 8.286393879000059],
              [50.149765562000084, 8.28615039500005],
              [50.149515718000032, 8.286055216000079],
              [50.149277772000062, 8.285864860000061],
              [50.149075518000075, 8.285507940000059],
              [50.148908956000071, 8.285151021000047],
              [50.148736473000042, 8.28482594400009],
              [50.148736473000042, 8.284508387000074],
              [50.148789983000086, 8.284211134000088],
              [50.148885161000067, 8.283937496000078],
              [50.148944648000054, 8.283723345000055],
              [50.14892085300005, 8.283544885000083],
              [50.148671010000044, 8.282367052000041],
              [50.148456858000088, 8.281950646000041],
              [50.148189106000075, 8.281893493000041],
              [50.148004761000038, 8.281712700000071],
              [50.148052350000057, 8.281534241000088],
              [50.148052350000057, 8.281355781000059],
              [50.148111836000055, 8.281129732000068],
              [50.147814404000087, 8.280903683000076],
              [50.147802506000062, 8.280689532000054],
              [50.147695431000045, 8.280463483000062],
              [50.147540766000077, 8.280213640000056],
              [50.147493176000069, 8.279368931000079],
              [50.147754917000043, 8.278952525000079],
              [50.148099939000076, 8.278976320000083],
              [50.148135631000059, 8.278476633000082],
              [50.147754917000043, 8.278084022000087],
              [50.14771922500006, 8.277679514000056],
              [50.147694266000087, 8.277128281000046],
              [50.147838198000045, 8.276906189000044],
              [50.148052350000057, 8.276442194000083],
              [50.148135631000059, 8.27613286400009],
              [50.148337885000046, 8.275728356000059],
              [50.148325988000067, 8.275335745000064],
              [50.148290296000084, 8.274859852000077],
              [50.148171323000042, 8.274526728000069],
              [50.148076144000072, 8.27411032200007],
              [50.147980966000034, 8.273682019000091],
              [50.147957171000087, 8.273325100000079],
              [50.14785009600007, 8.272968181000067],
              [50.147790609000083, 8.272278137000058],
              [50.148147528000038, 8.272099678000075],
              [50.148099939000076, 8.271754656000041],
              [50.147790609000083, 8.271504812000046],
              [50.14778921900006, 8.271052388000044],
              [50.147909582000068, 8.270541131000073],
              [50.147945274000051, 8.269755909000082],
              [50.147933377000072, 8.268923097000084],
              [50.147945274000051, 8.268149772000072],
              [50.147921479000047, 8.267757161000077],
              [50.147505074000037, 8.26713850100009],
              [50.146934003000069, 8.267352653000046],
              [50.14679123500008, 8.267162296000038],
              [50.146577084000057, 8.266841069000066],
              [50.146696057000042, 8.266555534000076],
              [50.146779338000044, 8.266186717000039],
              [50.146993490000057, 8.266032052000071],
              [50.147088668000038, 8.265722722000078],
              [50.146945900000048, 8.265342008000061],
              [50.14699334900007, 8.264974149000068],
              [50.147010260000059, 8.264738306000083],
              [50.146815030000084, 8.264259354000046],
              [50.146755544000087, 8.263926229000049],
              [50.146767441000065, 8.263319467000088],
              [50.146719852000047, 8.262962547000086],
              [50.14661277600004, 8.262629423000078],
              [50.146636570000055, 8.262427169000091],
              [50.14661277600004, 8.262236812000083],
              [50.146588981000036, 8.261820406000083],
              [50.146553289000053, 8.26147538400005],
              [50.146553289000053, 8.261070876000076],
              [50.146553289000053, 8.260797238000066],
              [50.146434316000068, 8.260154783000075],
              [50.146351035000066, 8.259440945000051],
              [50.146196370000041, 8.259357664000049],
              [50.146041705000073, 8.25897695000009],
              [50.146032863000073, 8.258960150000064],
              [50.145922732000088, 8.258750901000042],
              [50.145803759000046, 8.258501058000093],
              [50.145660991000057, 8.25842967400007],
              [50.145550976000038, 8.258216882000056],
              [50.145635531000039, 8.258132651000039],
              [50.145791862000067, 8.25801326900006],
              [50.14582755400005, 8.257822912000051],
              [50.14582755400005, 8.257620657000075],
              [50.145815656000082, 8.257275636000088],
              [50.145898938000073, 8.256561797000074],
              [50.145863246000033, 8.256181084000048],
              [50.145577710000055, 8.256216775000041],
              [50.145304072000044, 8.255871754000054],
              [50.145314221000035, 8.255639405000068],
              [50.145212754000056, 8.255268787000091],
              [50.145229665000045, 8.254830782000056],
              [50.145314221000035, 8.254274852000037],
              [50.145398776000036, 8.253786307000041],
              [50.145451561000073, 8.253539655000054],
              [50.145517749000078, 8.25323037600009],
              [50.145500838000032, 8.252826062000054],
              [50.145482532000074, 8.252397741000038],
              [50.145327867000049, 8.251505443000042],
              [50.145101818000057, 8.250815399000089],
              [50.144923359000074, 8.250577453000062],
              [50.144617791000087, 8.250022905000037],
              [50.144602131000056, 8.249994485000059],
              [50.144590837000067, 8.249909216000049],
              [50.14455322200007, 8.249625227000081],
              [50.144504218000066, 8.249543867000057],
              [50.144502488000057, 8.249540994000085],
              [50.144502551000073, 8.249536834000082],
              [50.144506953000075, 8.249244955000052],
              [50.144459364000056, 8.249030803000039],
              [50.144523139000057, 8.248867130000065],
              [50.144552542000042, 8.248479658000065],
              [50.144530747000033, 8.248067122000066],
              [50.144570133000059, 8.24789002600005],
              [50.144603955000036, 8.247687866000092],
              [50.144570133000059, 8.247435167000049],
              [50.144519399000046, 8.247266700000068],
              [50.144451755000034, 8.247081386000048],
              [50.144411774000048, 8.246877391000055],
              [50.144209520000061, 8.246603753000045],
              [50.14407865000004, 8.246318218000056],
              [50.143947780000076, 8.246115963000079],
              [50.143876396000053, 8.245961299000044],
              [50.143757423000068, 8.24568766100009],
              [50.143572378000044, 8.245480949000068],
              [50.143403267000053, 8.245211401000063],
              [50.143257736000066, 8.24500951400006],
              [50.143245839000087, 8.244795363000037],
              [50.143067379000058, 8.244557417000067],
              [50.142984098000056, 8.244307573000071],
              [50.142777557000045, 8.244032126000093],
              [50.142710460000046, 8.243724605000068],
              [50.142627179000044, 8.243510454000045],
              [50.142477492000069, 8.243412698000043],
              [50.142257648000054, 8.243362157000092],
              [50.142046368000081, 8.243255789000045],
              [50.142032313000072, 8.243248713000071],
              [50.141813625000054, 8.243139244000076],
              [50.141711086000043, 8.242867999000055],
              [50.141702545000044, 8.242705712000088],
              [50.141699189000065, 8.242641950000063],
              [50.141627805000041, 8.242499183000064],
              [50.141483892000053, 8.242355893000081],
              [50.141475403000072, 8.242347441000049],
              [50.141390848000071, 8.242296900000042],
              [50.141238648000069, 8.242027350000058],
              [50.141137181000033, 8.241909422000049],
              [50.141131379000058, 8.241791914000089],
              [50.14112811800004, 8.241725858000052],
              [50.14098535100004, 8.241476015000046],
              [50.140866604000053, 8.241235545000052],
              [50.140699815000062, 8.241023917000064],
              [50.140818788000047, 8.240595614000085],
              [50.140723610000066, 8.240393360000041],
              [50.140628242000048, 8.24025795700004],
              [50.14041846300006, 8.239922289000049],
              [50.140128745000084, 8.239810392000038],
              [50.13993649400004, 8.239736165000068],
              [50.139850908000085, 8.239743310000051],
              [50.139759928000046, 8.239750905000051],
              [50.139746129000059, 8.239575675000083],
              [50.139733561000071, 8.23941607200004],
              [50.139771825000082, 8.239203629000087],
              [50.139843209000048, 8.239060862000088],
              [50.13992649000005, 8.238941889000046],
              [50.13992649000005, 8.238739634000069],
              [50.139938388000076, 8.238442202000044],
              [50.139867004000052, 8.238097180000068],
              [50.139581361000069, 8.237950380000086],
              [50.139446072000055, 8.237613438000039],
              [50.139412250000078, 8.237343885000087],
              [50.139293873000042, 8.237074331000088],
              [50.139192406000063, 8.236872166000069],
              [50.139165063000064, 8.236621914000068],
              [50.139081782000062, 8.236205508000069],
              [50.138915219000069, 8.235801],
              [50.13872486300005, 8.235313211000062],
              [50.138463122000076, 8.235301313000093],
              [50.138237073000084, 8.23522992900007],
              [50.138141895000047, 8.234980086000064],
              [50.138115706000065, 8.234821762000081],
              [50.138081884000087, 8.23467013700008],
              [50.138106203000063, 8.234480399000063],
              [50.138141895000047, 8.234206761000053],
              [50.138237073000084, 8.233861739000076],
              [50.138237073000084, 8.233707074000051],
              [50.138248970000063, 8.233552409000083],
              [50.138225176000049, 8.233421539000062],
              [50.138177587000087, 8.23324307900009],
              [50.137771875000055, 8.233031003000065],
              [50.137653497000088, 8.232879378000064],
              [50.137501297000085, 8.23269405800005],
              [50.137439954000058, 8.232350782000083],
              [50.137178213000084, 8.231934376000083],
              [50.137059240000042, 8.231636943000069],
              [50.136916472000053, 8.231125359000089],
              [50.136785602000032, 8.230673262000039],
              [50.136892678000038, 8.230233061000092],
              [50.13697595900004, 8.229995115000065],
              [50.136824854000054, 8.22949305800006],
              [50.136824853000064, 8.229105566000044],
              [50.136723387000075, 8.228751770000088],
              [50.13659524500008, 8.228496055000051],
              [50.136500067000043, 8.228162930000053],
              [50.136202634000085, 8.227913087000047],
              [50.136012277000077, 8.227746525000043],
              [50.135881407000056, 8.227627551000069],
              [50.135810023000033, 8.227318222000065],
              [50.135738639000067, 8.22692561000008],
              [50.135589931000084, 8.226045476000081],
              [50.135548282000059, 8.225117220000072],
              [50.135250850000034, 8.224498560000086],
              [50.13526274700007, 8.223939387000087],
              [50.13498910900006, 8.223154165000039],
              [50.134848712000064, 8.222202009000057],
              [50.134703574000071, 8.221274391000065],
              [50.134905828000058, 8.220810396000047],
              [50.134620292000079, 8.220286914000042],
              [50.134413952000045, 8.22004652600009],
              [50.134322860000054, 8.219311335000043],
              [50.134511371000087, 8.219144984000081],
              [50.134501319000037, 8.21849042100007],
              [50.134108708000042, 8.218014529000072],
              [50.134180092000065, 8.217443458000048],
              [50.134132503000046, 8.216682031000062],
              [50.134180092000065, 8.216253728000083],
              [50.134275271000035, 8.214980716000071],
              [50.134370449000073, 8.214243083000042],
              [50.134284197000056, 8.213815283000088],
              [50.134317684000052, 8.213220293000063],
              [50.134358552000037, 8.212494179000089],
              [50.134096811000063, 8.21217295200006],
              [50.133858865000036, 8.211018914000078],
              [50.133466254000041, 8.209876772000086],
              [50.133061745000077, 8.209139139000058],
              [50.13282379900005, 8.208663247000061],
              [50.132728621000069, 8.208556172000044],
              [50.132692929000086, 8.208425301000091],
              [50.132645340000067, 8.20816356000006],
              [50.132723914000053, 8.207868774000076],
              [50.132752415000084, 8.207556798000041],
              [50.132859491000033, 8.207033316000093],
              [50.133002259000079, 8.206605013000058],
              [50.13255016100004, 8.205962559000056],
              [50.131524753000065, 8.203318276000061],
              [50.130801257000087, 8.201727118000065],
              [50.13056331100006, 8.201132253000083],
              [50.130491928000083, 8.20091810200006],
              [50.130468133000079, 8.200787231000049],
              [50.130390184000078, 8.200598636000052],
              [50.130322539000076, 8.200345906000052],
              [50.130111151000051, 8.19968880600004],
              [50.13004350600005, 8.199587714000074],
              [50.130026595000061, 8.199486622000052],
              [50.129958951000049, 8.19938552900004],
              [50.129908218000082, 8.19929286100006],
              [50.129865940000059, 8.19914964700007],
              [50.129865940000059, 8.199082252000039],
              [50.129460074000065, 8.198113448000072],
              [50.129417796000041, 8.197953384000073],
              [50.129316330000051, 8.197742774000062],
              [50.129231774000061, 8.197624833000077],
              [50.12915567400006, 8.197532164000052],
              [50.129088030000048, 8.197447920000059],
              [50.129011930000047, 8.197262583000054],
              [50.128944286000035, 8.197110944000087],
              [50.128893552000079, 8.196976153000037],
              [50.128817452000078, 8.196849787000076],
              [50.128741353000066, 8.196706572000039],
              [50.128683537000086, 8.196504202000085],
              [50.128469386000063, 8.196266255000069],
              [50.12839800200004, 8.196111590000044],
              [50.128204749000076, 8.195771057000059],
              [50.127876654000033, 8.195372413000086],
              [50.127876654000033, 8.195363541000063],
              [50.127765065000062, 8.195155080000063],
              [50.127723219000075, 8.19507169600007],
              [50.127639527000042, 8.194918825000059],
              [50.127513989000079, 8.194779851000078],
              [50.127444245000049, 8.194626979000077],
              [50.127374502000066, 8.194501903000059],
              [50.127262912000049, 8.194376826000052],
              [50.127179220000073, 8.19430733900009],
              [50.126791866000076, 8.193863],
              [50.126649098000087, 8.193684540000049],
              [50.126363563000041, 8.193410902000039],
              [50.126244590000056, 8.193208648000052],
              [50.126030438000043, 8.192923113000063],
              [50.125649724000084, 8.192518604000043],
              [50.125100868000061, 8.191944771000067],
              [50.124710306000054, 8.191569538000067],
              [50.124333691000061, 8.191194305000067],
              [50.123971026000049, 8.190777379000053],
              [50.123746156000038, 8.190519857000083],
              [50.123294058000056, 8.190043965000086],
              [50.122996625000042, 8.18975843000004],
              [50.122711090000053, 8.189496689000066],
              [50.122422723000057, 8.189304237000044],
              [50.122211403000051, 8.18897320700006],
              [50.121949663000066, 8.188640083000053],
              [50.121497565000084, 8.188235575000078],
              [50.120890802000076, 8.187866758000041],
              [50.120567550000032, 8.187441955000054],
              [50.120246731000066, 8.18724738700007],
              [50.11996775700004, 8.187011127000062],
              [50.119641585000068, 8.186974460000044],
              [50.11916569300007, 8.186653233000072],
              [50.118856363000077, 8.18629631400006],
              [50.118568788000061, 8.185934687000042],
              [50.118122430000085, 8.185656733000087],
              [50.117845092000039, 8.185475400000087],
              [50.117630941000073, 8.185177967000072],
              [50.117404892000081, 8.184975713000085],
              [50.117164074000073, 8.184537333000037],
              [50.116954844000077, 8.18430107100005],
              [50.116815357000064, 8.184162093000054],
              [50.116703768000036, 8.184037013000079],
              [50.116617914000074, 8.183940780000057],
              [50.115584604000048, 8.182786609000061],
              [50.114619510000068, 8.181896173000041],
              [50.114604493000058, 8.181896749000089],
              [50.114537641000084, 8.181751543000075],
              [50.114382977000048, 8.181573084000092],
              [50.114275901000042, 8.18148980300009],
              [50.114180722000071, 8.181347035000044],
              [50.114097441000069, 8.181228062000059],
              [50.113847598000063, 8.181025808000072],
              [50.113562062000085, 8.180764067000041],
              [50.113209626000071, 8.180458318000092],
              [50.113145657000075, 8.180240586000082],
              [50.113026684000033, 8.180145407000055],
              [50.112943403000088, 8.180002639000065],
              [50.112741148000055, 8.179776591000063],
              [50.112419921000082, 8.179574336000087],
              [50.111979721000068, 8.179431569000087],
              [50.111389324000072, 8.179235300000073],
              [50.11096389000005, 8.17890175000008],
              [50.110691890000055, 8.178707178000082],
              [50.110231584000076, 8.178491759000053],
              [50.11001538000005, 8.178366678000089],
              [50.109854970000072, 8.178234647000068],
              [50.109792201000062, 8.178192953000064],
              [50.109582971000066, 8.178067871000053],
              [50.109380715000043, 8.177970585000082],
              [50.109227280000084, 8.177901095000038],
              [50.109136614000079, 8.177838554000061],
              [50.109045947000084, 8.177748217000044],
              [50.109076778000087, 8.177587487000039],
              [50.109017292000033, 8.177409027000067],
              [50.108767449000084, 8.177194875000055],
              [50.108588989000054, 8.177135389000057],
              [50.108315351000044, 8.17693313500007],
              [50.108036555000069, 8.176912516000073],
              [50.107958379000081, 8.176743749000082],
              [50.107867174000035, 8.17657498300008],
              [50.107788998000046, 8.176432180000063],
              [50.107658704000073, 8.176354288000084],
              [50.107515382000088, 8.176263414000061],
              [50.107424176000052, 8.17619850400007],
              [50.107411156000069, 8.176278783000043],
              [50.107304080000063, 8.176421551000089],
              [50.107101826000076, 8.17638585900005],
              [50.106968150000057, 8.176159558000052],
              [50.106837857000073, 8.176055701000053],
              [50.106707563000043, 8.175951845000043],
              [50.106564241000058, 8.175925881000069],
              [50.106460006000077, 8.175925881000069],
              [50.106251537000048, 8.175886934000062],
              [50.106199420000053, 8.17571816800006],
              [50.106121244000065, 8.175523437000038],
              [50.105983479000088, 8.175422177000087],
              [50.105912095000065, 8.175100950000058],
              [50.105543279000074, 8.174803517000043],
              [50.105293435000078, 8.174708339000063],
              [50.105019797000068, 8.17494628500009],
              [50.104793749000066, 8.174732133000077],
              [50.104661959000055, 8.174536798000076],
              [50.104427431000033, 8.17441995900009],
              [50.104297138000049, 8.17429013800006],
              [50.104166845000066, 8.17412137000008],
              [50.104062610000085, 8.173939621000045],
              [50.10389322900005, 8.17371892400007],
              [50.103723847000083, 8.173615067000071],
              [50.103580525000041, 8.173563139000066],
              [50.103411144000063, 8.17342033500006],
              [50.103293880000081, 8.173342442000092],
              [50.103151921000062, 8.173221176000084],
              [50.102889970000035, 8.173108764000062],
              [50.102642413000069, 8.172901049000075],
              [50.102460003000033, 8.172771228000045],
              [50.102316680000058, 8.172706317000063],
              [50.10214729900008, 8.172589477000088],
              [50.102003976000049, 8.172446674000071],
              [50.101860654000063, 8.172316852000051],
              [50.102021677000039, 8.172162315000037],
              [50.10215254700006, 8.17199575300009],
              [50.101821566000069, 8.171784582000043],
              [50.101652184000045, 8.171641778000037],
              [50.101482803000067, 8.171511956000074],
              [50.101352510000083, 8.171330205000061],
              [50.101131011000064, 8.170992667000064],
              [50.101013747000081, 8.170849863000058],
              [50.100867638000068, 8.170579974000077],
              [50.100583779000033, 8.17029162700004],
              [50.100531662000037, 8.170083911000063],
              [50.100304718000075, 8.170069669000043],
              [50.100211526000066, 8.169878156000038],
              [50.100023518000057, 8.169681461000039],
              [50.099725497000065, 8.169568703000039],
              [50.099424169000088, 8.169499709000092],
              [50.099161319000075, 8.169440222000048],
              [50.099004967000042, 8.169349346000047],
              [50.098772703000066, 8.169201117000057],
              [50.098616351000032, 8.169019365000054],
              [50.098564234000037, 8.168850595000038],
              [50.09849908700005, 8.168733755000062],
              [50.098464096000043, 8.16868394800008],
              [50.098472854000079, 8.16866576700005],
              [50.098486803000071, 8.168547631000081],
              [50.098472854000079, 8.168443394000064],
              [50.098417059000042, 8.168373902000042],
              [50.098416793000069, 8.168283794000047],
              [50.098535766000055, 8.167950669000049],
              [50.098047420000057, 8.168158478000066],
              [50.097984651000047, 8.168165427000076],
              [50.097900959000071, 8.168193224000049],
              [50.097878106000053, 8.168210934000058],
              [50.097856586000034, 8.168201404000058],
              [50.097665210000059, 8.168253409000044],
              [50.097456741000087, 8.168214462000037],
              [50.097326448000047, 8.168136569000069],
              [50.097222213000066, 8.168058675000054],
              [50.097117978000085, 8.168032710000091],
              [50.097026773000039, 8.167941834000089],
              [50.096948597000051, 8.167850957000041],
              [50.096934019000059, 8.167792854000083],
              [50.096920088000047, 8.167786685000067],
              [50.096926092000047, 8.167761261000066],
              [50.096922538000058, 8.167747099000053],
              [50.096931920000088, 8.167736583000078],
              [50.096935568000049, 8.167721134000089],
              [50.096935568000049, 8.167500435000079],
              [50.096831333000068, 8.167357629000037],
              [50.096805275000065, 8.167110964000074],
              [50.096727099000077, 8.166786405000039],
              [50.096810657000049, 8.166558685000041],
              [50.096492571000056, 8.166552723000052],
              [50.096322867000083, 8.166546787000073],
              [50.096144408000043, 8.166451609000092],
              [50.096101691000058, 8.166293075000056],
              [50.095958368000083, 8.166189216000078],
              [50.095788987000049, 8.166111322000063],
              [50.095644721000042, 8.165963820000059],
              [50.095463254000038, 8.165955534000091],
              [50.095265568000059, 8.165857779000078],
              [50.095216652000033, 8.165714636000075],
              [50.094902993000062, 8.165825710000092],
              [50.094746641000086, 8.165877639000087],
              [50.094616348000045, 8.165825710000092],
              [50.094486054000072, 8.165656939000087],
              [50.094355761000088, 8.165605009000046],
              [50.094193250000046, 8.165440338000053],
              [50.093899735000036, 8.165514132000055],
              [50.093769441000063, 8.165488167000092],
              [50.093836331000034, 8.165273776000049],
              [50.093456737000054, 8.165189572000088],
              [50.093404620000058, 8.165020801000082],
              [50.093396130000087, 8.16489306200009],
              [50.09288454600005, 8.164940651000052],
              [50.092694189000042, 8.164821678000067],
              [50.092539524000074, 8.16471460200006],
              [50.092593498000042, 8.164552101000083],
              [50.092492721000042, 8.164466031000075],
              [50.092435134000084, 8.164451686000064],
              [50.092309134000061, 8.164451686000064],
              [50.09177761400008, 8.163964448000058],
              [50.091816072000086, 8.163677057000086],
              [50.091729691000069, 8.163519262000079],
              [50.091686500000037, 8.163418847000059],
              [50.091542533000052, 8.163275396000074],
              [50.09141296100006, 8.163146291000089],
              [50.091355374000045, 8.16306022100008],
              [50.09111062900007, 8.162902426000073],
              [50.090880280000079, 8.16284504500004],
              [50.090750709000076, 8.162773320000042],
              [50.090549154000087, 8.162701595000044],
              [50.09036199600007, 8.162572490000059],
              [50.090232424000078, 8.162400349000052],
              [50.090160440000034, 8.162328624000054],
              [50.090045266000061, 8.162285589000078],
              [50.089858108000044, 8.162156483000047],
              [50.089742933000082, 8.162041722000083],
              [50.089446225000074, 8.161728379000067],
              [50.089029819000075, 8.161359562000086],
              [50.08885033200005, 8.16112363700006],
              [50.088735158000077, 8.160908461000076],
              [50.088706364000075, 8.160707629000058],
              [50.088591190000045, 8.160564178000072],
              [50.088504809000085, 8.160506797000039],
              [50.088375238000083, 8.160449417000052],
              [50.088274461000083, 8.160377691000065],
              [50.088144890000081, 8.160191204000057],
              [50.088058509000064, 8.160119479000059],
              [50.088000922000049, 8.160105134000048],
              [50.087828735000073, 8.160131529000068],
              [50.087329643000032, 8.159573948000059],
              [50.087180304000071, 8.159459602000084],
              [50.087036337000086, 8.159430912000062],
              [50.08683478100005, 8.159273115000076],
              [50.086769332000074, 8.159158561000083],
              [50.086662256000068, 8.158908718000077],
              [50.086531385000058, 8.15867077200005],
              [50.086271736000072, 8.158417255000074],
              [50.08625608400007, 8.158379825000054],
              [50.086215719000052, 8.158283297000082],
              [50.086215719000052, 8.158225916000049],
              [50.086172529000066, 8.15812550000004],
              [50.086100545000079, 8.157996393000076],
              [50.086014164000062, 8.157910321000088],
              [50.085927784000035, 8.157838595000044],
              [50.085841403000074, 8.157752524000045],
              [50.085711832000072, 8.157723833000091],
              [50.085579601000063, 8.15751673300008],
              [50.08527027100007, 8.157195506000051],
              [50.084352315000046, 8.156293211000047],
              [50.083843244000036, 8.155621851000092],
              [50.083811455000045, 8.155600735000064],
              [50.083451535000052, 8.155285138000067],
              [50.083163599000045, 8.154883469000083],
              [50.082544537000047, 8.154180547000067],
              [50.082083840000053, 8.153578042000049],
              [50.081738317000088, 8.153162026000075],
              [50.081176842000048, 8.152487792000045],
              [50.080761193000058, 8.152210535000052],
              [50.080523988000039, 8.151825454000061],
              [50.080380479000041, 8.151698951000071],
              [50.080213916000048, 8.151544286000046],
              [50.080059252000069, 8.151389621000078],
              [50.079845100000057, 8.151211161000049],
              [50.079702332000068, 8.151008907000062],
              [50.079559565000068, 8.150830448000079],
              [50.079381105000039, 8.150568707000048],
              [50.079155056000047, 8.150306966000073],
              [50.078845726000054, 8.15012850700009],
              [50.077941531000079, 8.149081544000069],
              [50.077263385000037, 8.148403397000038],
              [50.076751801000057, 8.14783232700006],
              [50.076353917000063, 8.147366445000046],
              [50.075835632000064, 8.146864349000055],
              [50.075432522000085, 8.146520054000064],
              [50.075259761000041, 8.146390943000085],
              [50.074943031000032, 8.146204450000084],
              [50.074741476000042, 8.146060994000038],
              [50.07452552400008, 8.145860154000047],
              [50.074336648000042, 8.145726504000038],
              [50.074063010000032, 8.145595633000084],
              [50.073801269000057, 8.145381482000062],
              [50.073848858000076, 8.145119741000087],
              [50.07386327200004, 8.144919974000061],
              [50.073847937000039, 8.144905566000091],
              [50.073503836000043, 8.144524876000048],
              [50.07346016200006, 8.144612080000059],
              [50.073330590000069, 8.144554698000093],
              [50.073071448000064, 8.144526006000092],
              [50.072855496000045, 8.144439932000068],
              [50.072682735000058, 8.144325166000044],
              [50.072481180000068, 8.144267783000089],
              [50.072409196000081, 8.144210401000066],
              [50.072265228000049, 8.144038252000087],
              [50.071320738000054, 8.143269893000081],
              [50.071029197000087, 8.143216172000052],
              [50.070910224000045, 8.142954432000067],
              [50.070811152000033, 8.142775826000047],
              [50.070753565000075, 8.142689752000081],
              [50.070681581000088, 8.142618023000068],
              [50.070695977000071, 8.142517602000055],
              [50.070715429000074, 8.142508421000059],
              [50.070715108000059, 8.142508169000052],
              [50.070767961000058, 8.142402836000088],
              [50.070753565000075, 8.142245033000052],
              [50.070695977000071, 8.142029846000071],
              [50.070580803000041, 8.141900734000046],
              [50.070422438000037, 8.141785967000089],
              [50.070321661000037, 8.141742930000078],
              [50.070004931000085, 8.141599472000053],
              [50.069875360000083, 8.14151339700004],
              [50.069745789000081, 8.141240826000057],
              [50.069659408000064, 8.141169097000045],
              [50.069529837000061, 8.14112606000009],
              [50.069299489000059, 8.141068676000089],
              [50.069126727000082, 8.140982601000076],
              [50.06898275900005, 8.140896526000063],
              [50.068853188000048, 8.140839143000051],
              [50.068709220000073, 8.140753068000038],
              [50.068579649000071, 8.140681339000082],
              [50.068421284000067, 8.140652647000081],
              [50.068291713000065, 8.140580918000069],
              [50.068075761000046, 8.140394422000043],
              [50.067859809000083, 8.140193580000073],
              [50.067643857000064, 8.139992738000046],
              [50.067370318000087, 8.139863625000089],
              [50.067111176000083, 8.139806241000088],
              [50.066793756000038, 8.139456624000047],
              [50.066853243000082, 8.139182986000037],
              [50.066698578000057, 8.138921245000063],
              [50.06642013000004, 8.138744645000088],
              [50.06633374900008, 8.138586840000073],
              [50.06609181500005, 8.138516737000089],
              [50.065925253000046, 8.138492942000084],
              [50.065687307000076, 8.138385866000078],
              [50.065092442000036, 8.138397764000047],
              [50.064911567000081, 8.138171337000074],
              [50.064793293000037, 8.138041695000084],
              [50.064706912000076, 8.137955619000081],
              [50.064462166000055, 8.137855197000079],
              [50.064289405000068, 8.137754776000065],
              [50.06410496500007, 8.137517363000086],
              [50.063926506000087, 8.137469774000067],
              [50.063598359000082, 8.137439165000046],
              [50.063367333000087, 8.137350801000082],
              [50.063141284000039, 8.137255622000055],
              [50.062820932000079, 8.137080516000083],
              [50.06270575800005, 8.13696574800008],
              [50.062518599000043, 8.136721866000073],
              [50.062272780000058, 8.136660757000072],
              [50.062153933000047, 8.13660133400009],
              [50.062011040000073, 8.136529887000052],
              [50.061630326000056, 8.13630383800006],
              [50.06128530400008, 8.136172968000039],
              [50.061047358000053, 8.136113481000052],
              [50.060857001000045, 8.136018303000071],
              [50.060690439000041, 8.135911227000065],
              [50.06032162200006, 8.13579225400008],
              [50.060085542000081, 8.135717646000046],
              [50.059905155000081, 8.135749366000084],
              [50.05945304800008, 8.135649835000038],
              [50.056990377000034, 8.134495448000052],
              [50.055039219000037, 8.133020182000053],
              [50.052409915000055, 8.131806657000084],
              [50.048257755000066, 8.130045856000038],
              [50.04611624000006, 8.129343915000049],
              [50.044010417000038, 8.128261260000045],
              [50.043320374000075, 8.127951930000052],
              [50.042689818000042, 8.127683080000054],
              [50.042113946000086, 8.127410500000053],
              [50.040774350000049, 8.126881172000083],
              [50.03994153900004, 8.126595637000037],
              [50.039354161000063, 8.126378274000047],
              [50.039095019000058, 8.126263503000075],
              [50.038735099000064, 8.126062654000066],
              [50.03847595700006, 8.125947883000038],
              [50.038202418000083, 8.125847458000067],
              [50.037813704000087, 8.125718341000038],
              [50.037295420000078, 8.125531838000086],
              [50.037065071000086, 8.125445759000058],
              [50.036834722000037, 8.125316642000087],
              [50.036517993000075, 8.125115792000088],
              [50.036114883000039, 8.124972328000069],
              [50.035697376000087, 8.124857557000041],
              [50.035423837000053, 8.12475713200007],
              [50.035099336000087, 8.124513609000076],
              [50.034825698000077, 8.124335149000046],
              [50.034575854000082, 8.124287560000084],
              [50.034183243000086, 8.124097203000076],
              [50.033731146000036, 8.123954435000087],
              [50.03153863600005, 8.123098522000078],
              [50.031384761000083, 8.123058058000083],
              [50.031162386000062, 8.122906439000076],
              [50.030989625000075, 8.122820360000048],
              [50.030456943000047, 8.12257647000007],
              [50.030212198000072, 8.122519084000089],
              [50.029912111000044, 8.122300710000047],
              [50.029269656000054, 8.122015175000058],
              [50.028877045000058, 8.121884304000048],
              [50.028484583000079, 8.121959570000058],
              [50.028383805000033, 8.12181610500005],
              [50.028211044000045, 8.121672639000053],
              [50.028009489000056, 8.121500481000055],
              [50.027822330000049, 8.121428748000085],
              [50.027649569000062, 8.121400055000038],
              [50.027448014000072, 8.121328322000068],
              [50.027289649000068, 8.121256590000087],
              [50.026972920000048, 8.12119920300006],
              [50.026788930000066, 8.121134493000056],
              [50.026529604000075, 8.120917752000082],
              [50.025736533000043, 8.120222284000079],
              [50.023644791000038, 8.119439330000091],
              [50.023514458000079, 8.119318513000053],
              [50.023202497000057, 8.119052975000045],
              [50.023042595000049, 8.11901852200009],
              [50.022812247000047, 8.118889402000093],
              [50.022193185000049, 8.118631163000089],
              [50.021516535000046, 8.118143377000081],
              [50.021113425000067, 8.11787079000004],
              [50.020960448000039, 8.117740124000079],
              [50.020911870000077, 8.117698630000064],
              [50.020595141000058, 8.11744039000007],
              [50.020393586000068, 8.117268229000047],
              [50.020206427000062, 8.116995642000063],
              [50.020033666000074, 8.116665668000053],
              [50.019889698000043, 8.116421774000059],
              [50.019775468000034, 8.116251025000054],
              [50.018408163000061, 8.115199230000087],
              [50.016560171000037, 8.113309192000088],
              [50.015274090000048, 8.112462020000066],
              [50.015282724000087, 8.112375977000056],
              [50.014980392000041, 8.112160774000074],
              [50.014217362000068, 8.111644286000057],
              [50.01338234800005, 8.111156491000088],
              [50.012547334000033, 8.110568267000076],
              [50.011611542000082, 8.109994390000054],
              [50.010834116000069, 8.109506593000049],
              [50.009999102000052, 8.108947061000038],
              [50.008876152000084, 8.10812928100006],
              [50.007514688000072, 8.107807404000084],
              [50.006562903000088, 8.107688431000042],
              [50.005551633000039, 8.107272025000043],
              [50.004974621000088, 8.106393288000049],
              [50.004096417000085, 8.105991569000082],
              [50.003362181000057, 8.105575503000068],
              [50.002757515000042, 8.10505900600009],
              [50.001821724000081, 8.104700328000092],
              [50.001504707000038, 8.104558707000081],
              [49.999296031000085, 8.10298651800008],
              [49.998575242000072, 8.102548229000092],
              [49.998553652000055, 8.102462167000056],
              [49.998380890000078, 8.102318694000076],
              [49.998222526000063, 8.102146528000048],
              [49.998020971000074, 8.101888277000057],
              [49.997862606000069, 8.101701763000051],
              [49.997589067000035, 8.101486554000076],
              [49.997257941000044, 8.101300040000069],
              [49.997041989000081, 8.101156567000089],
              [49.996480514000041, 8.100912663000088],
              [49.996278959000051, 8.100797885000077],
              [49.996077404000062, 8.100769190000051],
              [49.995717484000068, 8.100740496000071],
              [49.995429548000061, 8.100611370000081],
              [49.995112819000042, 8.100396161000049],
              [49.994839280000065, 8.100209646000053],
              [49.994580137000071, 8.100023131000057],
              [49.99423461400005, 8.099822268000082],
              [49.993687536000039, 8.099549669000055],
              [49.993255633000047, 8.099363154000059],
              [49.992996490000053, 8.099147943000048],
              [49.992679761000034, 8.098918386000037],
              [49.992463809000071, 8.098703175000082],
              [49.992262254000082, 8.098487965000061],
              [49.991945524000073, 8.098287102000086],
              [49.991860856000073, 8.098242693000088],
              [49.989962862000084, 8.095867338000062],
              [49.989922577000073, 8.095769838000081],
              [49.989783027000044, 8.095615314000042],
              [49.98934887300004, 8.095368076000057],
              [49.988930224000057, 8.095089932000064],
              [49.988635619000036, 8.094688169000051],
              [49.988123937000069, 8.094286406000037],
              [49.987410684000054, 8.094131881000067],
              [49.986697430000049, 8.093930999000065],
              [49.986216760000048, 8.093668308000076],
              [49.985627550000061, 8.093359258000078],
              [49.985255418000065, 8.09317382800009],
              [49.984642865000069, 8.09317610700009],
              [49.98434059300007, 8.092864778000092],
              [49.984030483000083, 8.092540276000079],
              [49.983766889000037, 8.092339393000088],
              [49.983619697000051, 8.092224323000039],
              [49.98339364800006, 8.092105350000054],
              [49.98312001000005, 8.091772225000057],
              [49.982656015000032, 8.091272539000045],
              [49.981823204000079, 8.090534906000073],
              [49.981037982000032, 8.089928143000066],
              [49.980133787000057, 8.089131024000039],
              [49.978932159000067, 8.088155445000041],
              [49.977135666000038, 8.086466027000085],
              [49.976659773000051, 8.086228081000058],
              [49.975993524000046, 8.085716497000078],
              [49.975528845000042, 8.08509102700009],
              [49.975517957000079, 8.085061188000054],
              [49.975254364000079, 8.084798491000072],
              [49.975021781000066, 8.084535793000043],
              [49.974773693000088, 8.084288547000085],
              [49.974446875000069, 8.084086566000053],
              [49.973887701000081, 8.083920004000049],
              [49.973447501000066, 8.083717750000062],
              [49.972959712000034, 8.083444112000052],
              [49.972483819000047, 8.08326565200008],
              [49.972043619000033, 8.083039603000088],
              [49.971341678000044, 8.082658890000062],
              [49.971219919000077, 8.082579747000068],
              [49.97038989400005, 8.082040230000075],
              [49.969568980000076, 8.081052754000041],
              [49.969527, 8.08087584000009],
              [49.969402417000083, 8.080350813000052],
              [49.967977423000036, 8.078923469000074],
              [49.967053426000064, 8.077614863000065],
              [49.966023583000037, 8.076472291000073],
              [49.964393652000069, 8.07454492800008],
              [49.963251511000067, 8.073022073000061],
              [49.962501981000059, 8.072296337000068],
              [49.961997448000034, 8.071527006000053],
              [49.960312876000046, 8.070023952000042],
              [49.95924666600007, 8.069026142000041],
              [49.958778124000048, 8.068679556000063],
              [49.957981005000079, 8.068251253000085],
              [49.957826340000054, 8.067870540000058],
              [49.957041118000063, 8.067370853000057],
              [49.956517636000058, 8.067335161000074],
              [49.955803798000034, 8.066811679000068],
              [49.955775556000049, 8.06656147800004],
              [49.955220830000087, 8.06634768400005],
              [49.954658864000066, 8.065911206000067],
              [49.953995407000036, 8.065395900000055],
              [49.952545195000084, 8.063813209000045],
              [49.952147419000084, 8.063412810000045],
              [49.952117678000036, 8.063353527000061],
              [49.951880436000067, 8.062880628000073],
              [49.950002057000063, 8.060251716000039],
              [49.949995887000057, 8.060104127000045],
              [49.949933865000048, 8.059918682000045],
              [49.949840832000064, 8.059733236000056],
              [49.94960824900005, 8.059331438000072],
              [49.949189600000068, 8.058960546000037],
              [49.948972523000066, 8.05872873900006],
              [49.948755446000064, 8.058512385000085],
              [49.948631402000046, 8.058388755000067],
              [49.948414325000044, 8.058172401000093],
              [49.948305786000049, 8.058048770000084],
              [49.948119720000079, 8.057863324000039],
              [49.948011181000084, 8.057786055000065],
              [49.947923340000045, 8.057727689000046],
              [49.946428721000075, 8.056199283000069],
              [49.945667294000032, 8.055354575000081],
              [49.945417450000036, 8.055116629000054],
              [49.945262786000058, 8.055057142000067],
              [49.944956596000054, 8.054818902000079],
              [49.944560845000069, 8.05440279000004],
              [49.943977877000066, 8.053831720000062],
              [49.943452561000072, 8.053412588000072],
              [49.943228346000069, 8.053260649000038],
              [49.943037989000061, 8.053117881000048],
              [49.942895222000061, 8.053010806000088],
              [49.942645378000066, 8.052772859000072],
              [49.942538303000049, 8.052534913000045],
              [49.94239553500006, 8.052451632000043],
              [49.942336048000072, 8.052380248000077],
              [49.942217075000087, 8.05227317300006],
              [49.942038616000048, 8.052189892000058],
              [49.941919643000062, 8.052023329000065],
              [49.941788772000052, 8.051916253000059],
              [49.941205805000038, 8.05217799400009],
              [49.940829982000082, 8.051990083000078],
              [49.940539555000043, 8.051844870000082],
              [49.939766231000078, 8.051131031000068],
              [49.939361722000058, 8.05066703600005],
              [49.93917136500005, 8.050357707000046],
              [49.93902859800005, 8.050238733000072],
              [49.938885830000061, 8.050084069000093],
              [49.938834273000055, 8.04998095500008],
              [49.938695473000053, 8.049703355000076],
              [49.937327283000059, 8.047918759000083],
              [49.93676811000006, 8.047204921000059],
              [49.93652159800007, 8.046860025000058],
              [49.93618047700005, 8.046489122000082],
              [49.935828223000044, 8.046181752000052],
              [49.935529245000055, 8.045747315000085],
              [49.93535868500004, 8.045546409000053],
              [49.935209563000058, 8.045277557000077],
              [49.934955542000068, 8.045113687000082],
              [49.934784981000064, 8.044943689000092],
              [49.934629926000071, 8.04482005400007],
              [49.934555211000088, 8.04465889700009],
              [49.934436238000046, 8.044397157000049],
              [49.93435082700006, 8.04420187900007],
              [49.934257794000075, 8.043985518000056],
              [49.934162600000036, 8.043778497000062],
              [49.934115011000074, 8.04360003700009],
              [49.933886825000059, 8.043201823000061],
              [49.932813059000068, 8.042034299000079],
              [49.932699231000072, 8.04185113300008],
              [49.932815781000045, 8.041605534000041],
              [49.93280027600008, 8.041404625000041],
              [49.932707243000038, 8.041265535000093],
              [49.932536682000034, 8.041095535000068],
              [49.932335110000054, 8.040987354000038],
              [49.932164550000039, 8.040848263000044],
              [49.932056011000043, 8.040724627000088],
              [49.931807923000065, 8.040554627000063],
              [49.93163736200006, 8.040430991000051],
              [49.931513318000043, 8.040369173000045],
              [49.931451296000034, 8.040369173000045],
              [49.931342758000085, 8.04044644600009],
              [49.931286449000083, 8.040544662000059],
              [49.931096957000079, 8.040408677000073],
              [49.930955120000078, 8.04053917300007],
              [49.93081557000005, 8.040523718000088],
              [49.930707032000043, 8.040415536000069],
              [49.93055197700005, 8.040291900000057],
              [49.930365910000035, 8.040152809000062],
              [49.930257372000085, 8.039998264000076],
              [49.930195350000076, 8.039781900000037],
              [49.930148833000032, 8.039611900000068],
              [49.930022338000072, 8.03944787800009],
              [49.930102317000035, 8.03913280800009],
              [49.930055800000048, 8.038962808000065],
              [49.929978273000074, 8.038900989000069],
              [49.929869734000079, 8.038823716000081],
              [49.929730185000039, 8.038607352000042],
              [49.929510754000034, 8.038662656000042],
              [49.929320397000083, 8.038555580000093],
              [49.929129011000043, 8.038436492000073],
              [49.928642250000053, 8.038805423000042],
              [49.928550890000054, 8.038778354000044],
              [49.92832102300008, 8.038710245000061],
              [49.927726158000041, 8.038246250000043],
              [49.927393033000044, 8.037877434000052],
              [49.927088697000045, 8.037275650000083],
              [49.926300330000061, 8.036706635000087],
              [49.926237350000065, 8.036629595000079],
              [49.926225939000062, 8.036629158000039],
              [49.926055379000047, 8.036567339000044],
              [49.925884818000043, 8.036412792000078],
              [49.925683246000062, 8.036304610000059],
              [49.925512686000047, 8.03619642700005],
              [49.925465670000051, 8.036092838000059],
              [49.92537049200007, 8.035985762000053],
              [49.925287210000079, 8.035819200000049],
              [49.925144443000079, 8.035688329000038],
              [49.925032015000056, 8.035361872000067],
              [49.925019859000088, 8.035168009000074],
              [49.924209181000037, 8.034283031000086],
              [49.922507082000038, 8.032731916000046],
              [49.921146948000057, 8.031845500000088],
              [49.920504494000056, 8.031131662000064],
              [49.919064920000039, 8.030025212000055],
              [49.918908360000046, 8.029338832000064],
              [49.917887101000076, 8.028078536000066],
              [49.916602178000062, 8.026693967000085],
              [49.914044257000057, 8.023969484000077],
              [49.913461646000087, 8.02279494600009],
              [49.911759975000052, 8.020673930000044],
              [49.91089147100007, 8.019734043000085],
              [49.909785022000051, 8.018687081000053],
              [49.908523908000063, 8.017306993000091],
              [49.907179512000084, 8.015355835000037],
              [49.905930295000076, 8.013975748000064],
              [49.905049895000047, 8.012631352000085],
              [49.903503245000081, 8.010989524000081],
              [49.902918403000058, 8.009825277000061],
              [49.902453518000073, 8.009309367000071],
              [49.902268301000049, 8.009103820000064],
              [49.902616848000036, 8.009270577000052],
              [49.901599676000046, 8.008205555000075],
              [49.901189836000071, 8.007404192000081],
              [49.900731173000054, 8.006694597000092],
              [49.900005437000061, 8.006111629000088],
              [49.899164020000057, 8.005441013000052],
              [49.898946081000076, 8.005315645000053],
              [49.898683118000065, 8.005217350000066],
              [49.898485897000057, 8.005168203000039],
              [49.898232739000036, 8.005338304000077],
              [49.897852025000077, 8.005064666000067],
              [49.897877797000035, 8.004775021000057],
              [49.897664140000074, 8.004578430000038],
              [49.897447517000046, 8.004600671000048],
              [49.896816959000034, 8.004100985000093],
              [49.896567116000085, 8.003410941000084],
              [49.896743772000036, 8.003079423000088],
              [49.896317273000079, 8.002780384000062],
              [49.896543321000081, 8.002340183000058],
              [49.895365488000039, 8.002411567000081],
              [49.894734931000073, 8.00218551800009],
              [49.894500844000049, 8.002175037000086],
              [49.893937812000047, 8.002149827000039],
              [49.893592790000071, 8.001531167000053],
              [49.893631012000071, 8.001394712000092],
              [49.893686836000086, 8.001195416000087],
              [49.893407439000043, 8.001129885000068],
              [49.893339393000076, 8.00113310100005],
              [49.893223973000033, 8.001138556000058],
              [49.892683187000046, 8.001138473000083],
              [49.892295984000043, 8.00080543100006],
              [49.891367994000063, 8.000151079000091],
              [49.890808820000075, 7.999580009000056],
              [49.889797549000036, 7.998735300000078],
              [49.888536435000049, 7.997950078000088],
              [49.887596548000033, 7.997390905000088],
              [49.886363017000065, 7.996101342000088],
              [49.88427293400008, 7.994255919000068],
              [49.883718027000043, 7.993738432000043],
              [49.882877052000083, 7.99282493700008],
              [49.882183955000073, 7.992074436000053],
              [49.880869144000087, 7.99104230100005],
              [49.879881319000049, 7.990251619000048],
              [49.879470440000034, 7.989923956000041],
              [49.87910886700007, 7.98964544200004],
              [49.878928081000083, 7.989416077000044],
              [49.878714424000066, 7.989153946000044],
              [49.878463359000079, 7.989061303000085],
              [49.878068915000085, 7.988782788000037],
              [49.877650401000039, 7.988432234000072],
              [49.87659429100006, 7.987728606000076],
              [49.87565165400008, 7.987028352000038],
              [49.874426231000086, 7.985850519000053],
              [49.873426858000073, 7.98485114500005],
              [49.872546457000055, 7.984006436000072],
              [49.872225230000083, 7.983637620000081],
              [49.87188020800005, 7.983209317000046],
              [49.871582775000036, 7.982888090000074],
              [49.871236435000071, 7.982625190000078],
              [49.87095221800007, 7.982388403000073],
              [49.870595299000058, 7.98193630500009],
              [49.870238427000061, 7.981469390000086],
              [49.870012331000055, 7.981174878000047],
              [49.869548336000037, 7.980913137000073],
              [49.869120033000058, 7.980687088000082],
              [49.868834498000069, 7.980223093000063],
              [49.868572757000038, 7.979889969000055],
              [49.868358606000072, 7.979533050000043],
              [49.867965995000077, 7.979199925000046],
              [49.867549589000078, 7.978688341000066],
              [49.867061800000045, 7.978117270000041],
              [49.866086221000046, 7.977427227000078],
              [49.865622226000085, 7.976987026000074],
              [49.864979771000037, 7.976392161000092],
              [49.864599057000078, 7.976130420000061],
              [49.864242138000066, 7.975880577000055],
              [49.863813835000087, 7.975571247000062],
              [49.863385532000052, 7.975190533000045],
              [49.862953125000047, 7.97494127300007],
              [49.862542247000079, 7.974679132000063],
              [49.86062841100005, 7.973226821000083],
              [49.859971005000034, 7.972768073000054],
              [49.858079334000081, 7.971240628000089],
              [49.857793799000035, 7.971002682000062],
              [49.857508264000046, 7.970729044000052],
              [49.857151344000044, 7.970419714000059],
              [49.85688960400006, 7.97013417900007],
              [49.85640740100007, 7.969620393000071],
              [49.855832172000078, 7.969128873000045],
              [49.855319160000079, 7.968682708000074],
              [49.853820099000075, 7.967088469000089],
              [49.852499498000043, 7.965886841000042],
              [49.851416843000038, 7.964958851000063],
              [49.851095616000066, 7.964494856000044],
              [49.850419082000087, 7.963494866000076],
              [49.850120037000067, 7.962305752000077],
              [49.849501377000081, 7.961496735000082],
              [49.84878191100006, 7.960477274000084],
              [49.848763727000062, 7.960465892000059],
              [49.848323544000039, 7.959402810000086],
              [49.847902888000078, 7.959123896000051],
              [49.847228992000055, 7.958677074000093],
              [49.846527051000066, 7.958117901000037],
              [49.84600357000005, 7.957606317000057],
              [49.845272425000076, 7.957167052000045],
              [49.844891401000041, 7.956938137000066],
              [49.843528930000048, 7.955738440000061],
              [49.841910897000048, 7.953965741000047],
              [49.840388042000086, 7.952204940000058],
              [49.839507641000068, 7.950943826000071],
              [49.837140077000072, 7.947755348000044],
              [49.836200190000056, 7.94638715800005],
              [49.835019231000047, 7.944866051000076],
              [49.833713654000064, 7.942996426000093],
              [49.833118788000036, 7.942342074000067],
              [49.832262182000079, 7.941057165000075],
              [49.830727430000081, 7.938296990000083],
              [49.830073078000055, 7.937476076000053],
              [49.829961567000055, 7.937496569000075],
              [49.827217725000082, 7.932955101000061],
              [49.823284919000059, 7.927622719000055],
              [49.820112798000082, 7.923113737000051],
              [49.820245904000046, 7.922390294000081],
              [49.819418295000048, 7.920186904000047],
              [49.819056174000082, 7.91921371300009],
              [49.81911566000008, 7.918678334000049],
              [49.819044276000056, 7.918226237000056],
              [49.818906951000088, 7.91737278800008],
              [49.818824776000042, 7.916930363000063],
              [49.81870973000008, 7.916258532000086],
              [49.818463202000032, 7.915570313000046],
              [49.818068697000058, 7.91506155400009],
              [49.817473832000076, 7.914454791000082],
              [49.81764039400008, 7.91409787200007],
              [49.817312743000059, 7.913620354000045],
              [49.816753948000041, 7.912604405000081],
              [49.816474551000056, 7.911948952000046],
              [49.816293764000079, 7.911735930000077],
              [49.815948626000079, 7.911408203000065],
              [49.815866450000044, 7.911342658000081],
              [49.815177628000072, 7.910344691000091],
              [49.813381159000073, 7.906269445000078],
              [49.813434978000032, 7.905578245000072],
              [49.813488235000079, 7.904758488000084],
              [49.813357365000059, 7.904092239000079],
              [49.81303613700004, 7.901867442000082],
              [49.812191429000052, 7.900415971000086],
              [49.812203326000088, 7.899833003000083],
              [49.811870694000049, 7.899628897000071],
              [49.811525180000046, 7.898881219000089],
              [49.811822612000071, 7.898381532000087],
              [49.811900120000075, 7.897608123000055],
              [49.811275336000051, 7.897286980000047],
              [49.811311028000034, 7.896882472000073],
              [49.811727434000034, 7.896692115000064],
              [49.811917791000042, 7.89639468200005],
              [49.811806971000067, 7.894284116000051],
              [49.811790536000046, 7.893989149000049],
              [49.811724795000032, 7.893579473000045],
              [49.811608461000048, 7.893408459000057],
              [49.811513282000078, 7.893182410000065],
              [49.811477590000038, 7.892980156000078],
              [49.811382412000057, 7.892647031000081],
              [49.811287234000076, 7.892313907000073],
              [49.811313917000064, 7.892071860000044],
              [49.811231741000086, 7.891957151000042],
              [49.811100260000046, 7.891826054000092],
              [49.810952344000043, 7.891645795000045],
              [49.810882725000056, 7.891528684000093],
              [49.810740348000081, 7.891373797000085],
              [49.81039493600008, 7.89076725700005],
              [49.810228373000086, 7.890517413000055],
              [49.810179892000065, 7.890220112000065],
              [49.809907146000057, 7.890136700000085],
              [49.809692995000034, 7.889958240000055],
              [49.809502638000083, 7.889625116000047],
              [49.809455049000064, 7.889268196000046],
              [49.809562124000081, 7.888911277000091],
              [49.809716789000049, 7.888661434000085],
              [49.809728687000074, 7.888375899000039],
              [49.809728749000044, 7.888093844000082],
              [49.809749657000054, 7.887656069000059],
              [49.809716789000049, 7.887257552000051],
              [49.809681097000066, 7.886745968000071],
              [49.809800070000051, 7.886484227000039],
              [49.810066934000076, 7.88628779600009],
              [49.810066934000076, 7.886097969000048],
              [49.809788173000072, 7.885984540000038],
              [49.809562124000081, 7.885758491000047],
              [49.809276589000035, 7.885032756000044],
              [49.809241949000068, 7.884748080000065],
              [49.809220796000034, 7.884241871000086],
              [49.809220796000034, 7.883988766000073],
              [49.809038643000065, 7.88384302500009],
              [49.808931567000059, 7.883616977000088],
              [49.808907773000044, 7.883367133000093],
              [49.808800697000038, 7.883117290000087],
              [49.808717416000036, 7.882950727000093],
              [49.808610340000087, 7.882807960000093],
              [49.80847250100004, 7.882589106000069],
              [49.808260966000034, 7.882399277000047],
              [49.808078508000051, 7.882048290000057],
              [49.80803620100005, 7.881689723000079],
              [49.808074961000045, 7.881427872000074],
              [49.808265318000053, 7.881094748000066],
              [49.808312907000072, 7.880761623000069],
              [49.808253421000074, 7.880190553000091],
              [49.808301010000037, 7.879750352000087],
              [49.80856503800004, 7.879517220000082],
              [49.808734266000044, 7.87922192800005],
              [49.808966954000084, 7.878905543000087],
              [49.808765005000055, 7.878501135000079],
              [49.808872081000061, 7.878275087000077],
              [49.808550853000042, 7.877311405000057],
              [49.808753108000076, 7.877311405000057],
              [49.808882340000082, 7.876943950000054],
              [49.808705518000068, 7.876787923000052],
              [49.808408086000043, 7.876657053000088],
              [49.808110653000085, 7.876442902000065],
              [49.807991680000043, 7.876109777000067],
              [49.80822962600007, 7.875800447000074],
              [49.808253421000074, 7.875419733000058],
              [49.808146345000068, 7.875003328000048],
              [49.808312907000072, 7.87481297100004],
              [49.808384291000039, 7.874610717000053],
              [49.808408086000043, 7.87443225700008],
              [49.808396188000074, 7.874182414000074],
              [49.808289113000058, 7.873742213000071],
              [49.808301010000037, 7.873040272000082],
              [49.808205832000056, 7.872933197000066],
              [49.807837015000075, 7.872742840000058],
              [49.808098756000049, 7.872504894000087],
              [49.808141969000076, 7.871945010000047],
              [49.807920296000077, 7.871802953000042],
              [49.807803513000067, 7.871396599000093],
              [49.807756976000064, 7.871388162000073],
              [49.807599069000048, 7.871565007000072],
              [49.807265944000051, 7.871279471000037],
              [49.807194561000074, 7.870684606000054],
              [49.807027998000081, 7.870125433000055],
              [49.807232370000065, 7.869688084000074],
              [49.80714310500008, 7.868472865000058],
              [49.807051793000085, 7.866936955000085],
              [49.807289739000055, 7.866960750000089],
              [49.807599069000048, 7.866496755000071],
              [49.807468199000084, 7.866282603000059],
              [49.807182663000049, 7.866389679000065],
              [49.807084295000038, 7.865785894000055],
              [49.80702083500006, 7.865638243000092],
              [49.806936221000058, 7.865279661000045],
              [49.806766993000053, 7.864878893000082],
              [49.806661226000074, 7.864330473000052],
              [49.806564003000062, 7.864010218000089],
              [49.806433133000041, 7.863427250000086],
              [49.806433133000041, 7.862915666000049],
              [49.806409339000083, 7.862677720000079],
              [49.806373647000044, 7.862451671000088],
              [49.806314160000056, 7.86208285400005],
              [49.806159495000088, 7.861535578000087],
              [49.806111906000069, 7.860797946000048],
              [49.806088111000065, 7.860405334000063],
              [49.805862062000074, 7.859489242000052],
              [49.805671705000066, 7.85875160900008],
              [49.805659808000087, 7.857371522000051],
              [49.805921549000061, 7.85658630000006],
              [49.806504517000064, 7.855931948000091],
              [49.806706771000052, 7.855301391000069],
              [49.806438336000042, 7.854573266000045],
              [49.80611710800008, 7.854002196000067],
              [49.806045724000057, 7.853645277000055],
              [49.806045724000057, 7.853145590000054],
              [49.805760189000068, 7.85246744300008],
              [49.805724497000085, 7.851896373000045],
              [49.806135701000073, 7.851601329000061],
              [49.806349852000039, 7.851232512000081],
              [49.806343924000032, 7.850936138000066],
              [49.806365077000066, 7.850598638000065],
              [49.806242776000033, 7.850280728000087],
              [49.806123803000048, 7.849983295000072],
              [49.80606431700005, 7.849792938000064],
              [49.806004550000068, 7.84956412400004],
              [49.805937666000034, 7.849003879000065],
              [49.80564875500005, 7.84723413800009],
              [49.805825651000077, 7.847054863000039],
              [49.805867958000078, 7.846590796000044],
              [49.805481349000047, 7.84625943900005],
              [49.805772780000041, 7.845831410000073],
              [49.805457554000043, 7.845283860000052],
              [49.805362376000062, 7.844843659000048],
              [49.805255300000056, 7.844343973000093],
              [49.805029251000065, 7.843987053000092],
              [49.804767510000033, 7.843261318000089],
              [49.804505770000048, 7.841809847000093],
              [49.804398694000042, 7.840679603000069],
              [49.804565256000046, 7.840049046000047],
              [49.804575987000078, 7.838722938000046],
              [49.804196440000055, 7.837050925000085],
              [49.804303516000061, 7.836372778000054],
              [49.804422489000046, 7.835980167000059],
              [49.804565256000046, 7.835599453000043],
              [49.804611591000082, 7.834941188000073],
              [49.804577154000071, 7.834742847000086],
              [49.804848642000081, 7.834408573000076],
              [49.804947795000032, 7.834208426000089],
              [49.805053562000069, 7.833807627000056],
              [49.80509586900007, 7.83330135500006],
              [49.80509586900007, 7.832900556000084],
              [49.80509586900007, 7.832478661000039],
              [49.804934073000084, 7.831114169000045],
              [49.804874586000039, 7.829555622000044],
              [49.805064943000048, 7.828449173000081],
              [49.805112532000066, 7.82739031300008],
              [49.805379475000052, 7.82673357300007],
              [49.806254674000058, 7.822250677000056],
              [49.806671079000068, 7.820739719000073],
              [49.806837642000062, 7.819764140000075],
              [49.807527685000082, 7.818288875000064],
              [49.807866974000035, 7.817965229000038],
              [49.808226583000078, 7.817395650000037],
              [49.808713112000078, 7.816868262000071],
              [49.809220796000034, 7.816404160000047],
              [49.809612122000033, 7.816080731000056],
              [49.809929424000075, 7.815996349000045],
              [49.810088088000043, 7.816108822000047],
              [49.810278469000082, 7.816087727000081],
              [49.810609753000051, 7.815927471000066],
              [49.810942212000043, 7.815766646000043],
              [49.811293836000061, 7.815729102000091],
              [49.811489488000063, 7.815445419000071],
              [49.811739331000069, 7.815219370000079],
              [49.812036764000084, 7.814826759000084],
              [49.812357991000056, 7.814648299000055],
              [49.812643526000045, 7.814541224000038],
              [49.812905267000076, 7.814493634000087],
              [49.813214597000069, 7.814279483000064],
              [49.813321673000075, 7.813553747000071],
              [49.813324569000088, 7.81292337900004],
              [49.813155341000083, 7.812585847000037],
              [49.813091881000048, 7.812163931000043],
              [49.813070727000081, 7.811847495000052],
              [49.813027240000054, 7.811607797000079],
              [49.812976651000042, 7.811328951000064],
              [49.81265542400007, 7.810627010000076],
              [49.812691116000053, 7.810079734000055],
              [49.812691116000053, 7.809615739000037],
              [49.812795732000041, 7.80921051200005],
              [49.812940959000059, 7.808509290000075],
              [49.812952856000038, 7.80821185700006],
              [49.813024240000061, 7.807843041000069],
              [49.81316700800005, 7.806903154000054],
              [49.813345467000033, 7.80649864500009],
              [49.813476338000044, 7.805523066000092],
              [49.813722511000037, 7.805066491000048],
              [49.814213971000072, 7.80392882700005],
              [49.814832630000069, 7.802965146000076],
              [49.815130063000083, 7.802608227000064],
              [49.815260933000047, 7.802429767000092],
              [49.815391804000058, 7.802215616000069],
              [49.815693757000076, 7.801763584000071],
              [49.81582067800008, 7.801637006000078],
              [49.815867696000055, 7.801466085000072],
              [49.815998566000076, 7.801204345000087],
              [49.816058053000063, 7.80096639900006],
              [49.81602236100008, 7.800764144000084],
              [49.815950977000057, 7.800395328000093],
              [49.816010464000044, 7.800062203000039],
              [49.816076331000033, 7.79983582400007],
              [49.816132594000067, 7.799673727000084],
              [49.816247518000068, 7.799342619000072],
              [49.816637818000061, 7.798316421000038],
              [49.816693247000046, 7.798170682000091],
              [49.816770380000037, 7.797967880000044],
              [49.816888928000083, 7.79787920800004],
              [49.817061379000052, 7.797776016000057],
              [49.817126905000066, 7.797736806000046],
              [49.817317286000048, 7.797531115000083],
              [49.817491802000063, 7.797309602000041],
              [49.817777374000059, 7.796961509000084],
              [49.818782536000072, 7.796647677000067],
              [49.819032379000078, 7.796290758000055],
              [49.819222736000086, 7.796243168000046],
              [49.819424990000073, 7.79617178500007],
              [49.819808107000085, 7.796054539000068],
              [49.820188869000049, 7.795919865000087],
              [49.82017452000008, 7.796005222000076],
              [49.820519542000056, 7.79599332500004],
              [49.820753533000072, 7.795981143000063],
              [49.821986021000043, 7.795933867000088],
              [49.824255296000047, 7.796350244000052],
              [49.827110649000076, 7.796623882000063],
              [49.827419979000069, 7.796457320000059],
              [49.828823861000046, 7.796540601000061],
              [49.830013592000057, 7.79640973100004],
              [49.830917787000033, 7.796255066000072],
              [49.831517771000051, 7.796044589000076],
              [49.833332940000048, 7.795338973000071],
              [49.833761243000083, 7.795338973000071],
              [49.834017542000083, 7.795221205000075],
              [49.834412495000038, 7.795142427000087],
              [49.834669215000076, 7.795142427000087],
              [49.834846944000049, 7.795083343000044],
              [49.83518265500004, 7.795004564000067],
              [49.835478870000088, 7.794945480000081],
              [49.835557861000041, 7.794512198000064],
              [49.834701130000042, 7.793590069000061],
              [49.834106265000059, 7.793173664000051],
              [49.833392426000046, 7.792995204000079],
              [49.83263099900006, 7.792602593000083],
              [49.831929058000071, 7.792091009000046],
              [49.831726804000084, 7.791734090000091],
              [49.831227117000083, 7.79147234900006],
              [49.831108144000041, 7.791638911000064],
              [49.830239247000065, 7.791067761000079],
              [49.829668570000081, 7.790413489000059],
              [49.829371137000066, 7.79012795400007],
              [49.829049910000037, 7.789866213000039],
              [49.828657299000042, 7.789295142000071],
              [49.828240893000043, 7.788854942000057],
              [49.828064975000075, 7.788606445000084],
              [49.827895761000036, 7.788367418000064],
              [49.827639041000054, 7.788111384000047],
              [49.827303331000053, 7.787796264000065],
              [49.827007115000072, 7.787619009000082],
              [49.826551476000077, 7.786820503000058],
              [49.826102506000041, 7.785926796000069],
              [49.825813843000049, 7.785285750000071],
              [49.825742459000082, 7.784714680000093],
              [49.825944713000069, 7.784345863000055],
              [49.826051789000076, 7.783905663000041],
              [49.826123173000042, 7.783548744000086],
              [49.82618265900004, 7.783156133000091],
              [49.82621835100008, 7.782715933000077],
              [49.826384914000073, 7.782382808000079],
              [49.826533171000051, 7.782045290000042],
              [49.826572666000061, 7.781848337000042],
              [49.82665165700007, 7.781651384000043],
              [49.826770143000033, 7.781493822000073],
              [49.826928125000052, 7.781316565000054],
              [49.827125601000034, 7.781296869000073],
              [49.827283583000053, 7.781277174000081],
              [49.827410532000044, 7.781277174000081],
              [49.827579798000045, 7.781040830000052],
              [49.827599546000044, 7.780745401000047],
              [49.827461312000082, 7.780489362000083],
              [49.827323078000063, 7.780312104000075],
              [49.827105854000081, 7.780193932000088],
              [49.826809639000032, 7.779996978000042],
              [49.826552919000051, 7.77976063400007],
              [49.826315947000069, 7.779406117000065],
              [49.826123173000042, 7.779170536000038],
              [49.826016097000036, 7.778813617000083],
              [49.825742459000082, 7.77805218900005],
              [49.825575897000078, 7.777350248000062],
              [49.825564, 7.776791075000062],
              [49.825528308000059, 7.77616051800004],
              [49.825456924000036, 7.775660831000039],
              [49.825552102000074, 7.775042171000052],
              [49.825861432000067, 7.77468525200004],
              [49.82618265900004, 7.774197462000075],
              [49.826375190000078, 7.773851982000053],
              [49.826527681000073, 7.773340856000061],
              [49.826928125000052, 7.773162634000073],
              [49.827324801000088, 7.772900656000047],
              [49.827646028000061, 7.772734094000043],
              [49.827915509000036, 7.77261115500005],
              [49.828132733000075, 7.772492981000084],
              [49.828645402000063, 7.772198715000059],
              [49.829049910000037, 7.77215112600004],
              [49.82951507100006, 7.772158154000067],
              [49.830037386000072, 7.772353380000084],
              [49.83006363800007, 7.772359862000087],
              [49.830443212000034, 7.772453589000065],
              [49.830680184000073, 7.772453589000065],
              [49.830977273000087, 7.772305791000065],
              [49.831048657000053, 7.771984563000046],
              [49.831179528000064, 7.771484877000091],
              [49.831274706000045, 7.771199341000056],
              [49.831529334000038, 7.770877929000051],
              [49.831465063000053, 7.77071155200008],
              [49.831298501000049, 7.770461708000084],
              [49.831181239000045, 7.770181576000084],
              [49.831056121000074, 7.770003304000056],
              [49.831043006000073, 7.769984617000091],
              [49.830870198000071, 7.769700281000041],
              [49.829870824000068, 7.768736599000079],
              [49.82940682900005, 7.76785619900005],
              [49.829121294000061, 7.767356512000049],
              [49.828776272000084, 7.766833030000043],
              [49.828455045000055, 7.766404727000065],
              [49.828121920000058, 7.765928835000068],
              [49.82709875200004, 7.76490566700005],
              [49.826420606000056, 7.764132342000039],
              [49.825671075000059, 7.763406607000093],
              [49.825207080000041, 7.762835536000068],
              [49.824766880000084, 7.762252568000065],
              [49.824518908000073, 7.761935946000051],
              [49.824267193000082, 7.761657703000083],
              [49.824064939000039, 7.761455449000039],
              [49.822351727000068, 7.759337728000048],
              [49.822185165000064, 7.759135474000061],
              [49.821576503000074, 7.758410309000055],
              [49.821221045000073, 7.75793759600009],
              [49.82051012900007, 7.757130043000075],
              [49.820162623000044, 7.756672732000084],
              [49.819984164000061, 7.756303915000046],
              [49.819865191000076, 7.755887510000093],
              [49.819829499000036, 7.755506796000077],
              [49.819878203000087, 7.755160394000086],
              [49.82003618400006, 7.754825553000046],
              [49.820095427000069, 7.754431622000084],
              [49.820095427000069, 7.753781635000053],
              [49.820016437000049, 7.753230130000077],
              [49.81991769800004, 7.752698320000093],
              [49.819662936000043, 7.75190191300004],
              [49.819722423000087, 7.751307047000068],
              [49.81975811500007, 7.750771669000073],
              [49.819865191000076, 7.750390955000057],
              [49.820043650000059, 7.749843679000037],
              [49.820234007000067, 7.749308300000052],
              [49.82068610500005, 7.74898707300008],
              [49.821142054000063, 7.748916547000078],
              [49.821359279000035, 7.748837759000082],
              [49.821694990000083, 7.748660488000041],
              [49.822050448000084, 7.748345338000092],
              [49.822405906000085, 7.74820746000006],
              [49.822958841000059, 7.748128673000053],
              [49.82333404700006, 7.748010491000059],
              [49.823571019000042, 7.747852916000056],
              [49.824076837000064, 7.747452320000093],
              [49.824730177000049, 7.747113131000049],
              [49.824953357000084, 7.747005950000073],
              [49.825135697000064, 7.746631406000063],
              [49.825635383000076, 7.746143617000087],
              [49.825873330000036, 7.745596341000066],
              [49.825778151000065, 7.745108551000044],
              [49.825599692000083, 7.744763530000057],
              [49.825409335000074, 7.744454200000064],
              [49.825218978000066, 7.74401399900006],
              [49.825016724000079, 7.743573799000046],
              [49.824707394000086, 7.743193085000087],
              [49.824505139000053, 7.742871858000058],
              [49.824314783000034, 7.742598220000048],
              [49.824124426000083, 7.742253198000071],
              [49.824004847000083, 7.741430156000092],
              [49.824025216000052, 7.741372593000051],
              [49.824025216000052, 7.741136226000037],
              [49.824025216000052, 7.740998346000083],
              [49.823850560000039, 7.740706986000077],
              [49.82383081200004, 7.740431225000066],
              [49.823791301000085, 7.740075991000083],
              [49.823729, 7.739599842000075],
              [49.823610514000052, 7.739166502000046],
              [49.823551271000042, 7.738634675000071],
              [49.82335379500006, 7.738142242000038],
              [49.823176065000041, 7.737767992000045],
              [49.823057579000078, 7.737413440000068],
              [49.822781112000087, 7.736960400000044],
              [49.822504644000048, 7.736645241000076],
              [49.822188681000057, 7.736349780000069],
              [49.821833223000056, 7.735995226000057],
              [49.821615999000073, 7.735581580000087],
              [49.821379027000035, 7.735207328000058],
              [49.821209586000066, 7.734900664000065],
              [49.820709899000065, 7.734519950000049],
              [49.81962724400006, 7.73303278700007],
              [49.818425617000059, 7.731581316000074],
              [49.817058088000067, 7.72994680700009],
              [49.815915285000074, 7.728737860000081],
              [49.814475711000057, 7.727048442000068],
              [49.813547722000067, 7.725989582000068],
              [49.812749291000046, 7.72515164400005],
              [49.812235851000082, 7.724639497000055],
              [49.811899897000046, 7.724392401000046],
              [49.811643177000064, 7.723978743000089],
              [49.811346720000074, 7.723705300000063],
              [49.810585293000088, 7.722848694000049],
              [49.809014848000061, 7.720933228000092],
              [49.80791441100007, 7.719248166000057],
              [49.807377922000057, 7.718523230000073],
              [49.807135074000087, 7.718137361000061],
              [49.806659182000033, 7.717661469000063],
              [49.805873960000042, 7.716864349000048],
              [49.805403154000032, 7.716573097000037],
              [49.804593499000077, 7.715745766000055],
              [49.804053672000066, 7.715067856000076],
              [49.803566620000083, 7.714465368000049],
              [49.803329648000044, 7.713992605000044],
              [49.803132171000073, 7.713598635000039],
              [49.802875451000034, 7.713204665000092],
              [49.802785061000066, 7.713096467000071],
              [49.802105291000032, 7.711609082000052],
              [49.802019233000067, 7.711379692000037],
              [49.801912157000061, 7.711046567000039],
              [49.801698006000038, 7.710689648000084],
              [49.801555238000049, 7.710416010000074],
              [49.801354880000076, 7.709855903000062],
              [49.801138832000049, 7.70942853400004],
              [49.800841400000081, 7.709214382000084],
              [49.800591556000086, 7.70901212800004],
              [49.80034171300008, 7.708738490000087],
              [49.80006807500007, 7.708464852000077],
              [49.799842026000078, 7.708143625000048],
              [49.799675464000074, 7.707941370000071],
              [49.799437518000047, 7.707762911000088],
              [49.799247161000039, 7.707513068000083],
              [49.798973523000086, 7.707227532000047],
              [49.798723679000034, 7.706834921000052],
              [49.798360065000054, 7.706392332000064],
              [49.797724306000077, 7.705490526000062],
              [49.796932389000062, 7.70460773700006],
              [49.796058683000069, 7.703396600000076],
              [49.795385739000039, 7.702287762000083],
              [49.795035622000057, 7.701720271000056],
              [49.794917136000038, 7.701562679000062],
              [49.794857893000085, 7.701306591000048],
              [49.794738082000038, 7.701088523000067],
              [49.794476342000053, 7.700874371000054],
              [49.794225967000045, 7.700538326000071],
              [49.793890257000044, 7.700242839000055],
              [49.793560249000052, 7.700017765000041],
              [49.793357995000065, 7.699756025000056],
              [49.792941589000066, 7.69913736500007],
              [49.792798822000066, 7.698875624000038],
              [49.792299135000064, 7.698316451000039],
              [49.792062416000078, 7.698034591000066],
              [49.791751859000044, 7.697602612000082],
              [49.791573400000061, 7.697340872000041],
              [49.791406837000068, 7.697055336000062],
              [49.791311658000041, 7.696805493000056],
              [49.791180788000077, 7.696353395000074],
              [49.791049918000056, 7.695853709000062],
              [49.790776280000046, 7.695532481000043],
              [49.79052643600005, 7.694973308000044],
              [49.789972465000062, 7.694197595000048],
              [49.789496573000065, 7.693067351000082],
              [49.788967889000048, 7.692177441000069],
              [49.788706149000063, 7.691570679000051],
              [49.78853958600007, 7.691094787000054],
              [49.788337332000083, 7.690606997000089],
              [49.788076863000072, 7.690181713000072],
              [49.787885235000033, 7.689916953000079],
              [49.786850169000047, 7.688977066000064],
              [49.785838898000065, 7.688596353000037],
              [49.785125060000041, 7.68797769300005],
              [49.783488434000049, 7.68735664400009],
              [49.782626626000081, 7.686859346000062],
              [49.781770020000067, 7.686585708000052],
              [49.780925311000033, 7.686062227000093],
              [49.780520803000059, 7.685538745000088],
              [49.779557121000039, 7.684705934000078],
              [49.778843283000072, 7.683111695000093],
              [49.778165136000041, 7.682005246000074],
              [49.777488153000036, 7.680830730000082],
              [49.777225249000082, 7.680065985000056],
              [49.776535206000062, 7.67875728100006],
              [49.776050778000069, 7.677523438000037],
              [49.775274091000085, 7.676116080000043],
              [49.774624942000059, 7.674745501000075],
              [49.773851617000048, 7.673615257000051],
              [49.773537085000044, 7.672998986000039],
              [49.772644787000047, 7.671987715000057],
              [49.772245481000084, 7.671652202000075],
              [49.771110035000049, 7.669965173000037],
              [49.770646040000088, 7.66925133500007],
              [49.770415777000039, 7.668438685000069],
              [49.770158250000065, 7.668216269000084],
              [49.769753742000034, 7.667799863000084],
              [49.769448474000058, 7.667131266000069],
              [49.769153594000045, 7.66685971600009],
              [49.768481171000076, 7.665170130000092],
              [49.768111914000087, 7.664004623000039],
              [49.768169138000076, 7.663489149000043],
              [49.768207092000068, 7.66280299500005],
              [49.767933454000058, 7.662636433000046],
              [49.767731200000071, 7.66227951400009],
              [49.767701088000081, 7.661714772000039],
              [49.767433767000057, 7.661268243000052],
              [49.767139428000064, 7.66022055500008],
              [49.766764988000034, 7.658383905000051],
              [49.766546564000066, 7.657636791000073],
              [49.766421751000053, 7.65704532500007],
              [49.766421751000053, 7.65651611800007],
              [49.766546564000066, 7.655924650000088],
              [49.766470086000083, 7.655735996000089],
              [49.766386804000035, 7.655533742000046],
              [49.766232139000067, 7.65514113100005],
              [49.766017988000044, 7.654248833000054],
              [49.765922810000063, 7.653653968000071],
              [49.765863323000076, 7.653035308000085],
              [49.76588711800008, 7.652464237000061],
              [49.765696761000072, 7.651714707000053],
              [49.765446917000077, 7.650298928000041],
              [49.765327944000035, 7.649596987000052],
              [49.765280355000073, 7.649049711000089],
              [49.765089998000064, 7.648264488000052],
              [49.76520897100005, 7.647693418000074],
              [49.765018614000041, 7.646610763000069],
              [49.765363636000075, 7.64564708100005],
              [49.76533984200006, 7.645337751000056],
              [49.765226071000086, 7.644240811000088],
              [49.76506620400005, 7.643029674000047],
              [49.764760868000053, 7.642230811000047],
              [49.764673592000065, 7.641435435000062],
              [49.765006717000063, 7.640507446000072],
              [49.764834928000084, 7.639177609000058],
              [49.764333773000033, 7.638815640000075],
              [49.764126316000045, 7.636141135000059],
              [49.763519554000084, 7.633226295000043],
              [49.763674219000052, 7.632690916000058],
              [49.763619935000065, 7.631796230000077],
              [49.762960381000084, 7.629764179000063],
              [49.762793818000034, 7.628764806000049],
              [49.762570585000049, 7.628481014000045],
              [49.762448796000058, 7.627015902000039],
              [49.761687369000072, 7.625742890000083],
              [49.761163888000056, 7.624600749000081],
              [49.760943041000075, 7.623706062000053],
              [49.760645608000061, 7.622159413000077],
              [49.76036676800004, 7.620793611000067],
              [49.759581546000049, 7.618782967000072],
              [49.759058065000033, 7.616974576000075],
              [49.758832016000042, 7.616344019000053],
              [49.757880231000058, 7.613952661000042],
              [49.75674461400007, 7.610968674000048],
              [49.756190814000036, 7.609812399000077],
              [49.755250927000077, 7.607813651000072],
              [49.754620370000055, 7.606683407000048],
              [49.753597202000037, 7.604625174000091],
              [49.753097515000036, 7.603506827000047],
              [49.751039281000033, 7.598664624000037],
              [49.750432518000082, 7.597344023000062],
              [49.748493258000053, 7.593263247000039],
              [49.746249869000053, 7.588965932000065],
              [49.743865206000066, 7.582627057000082],
              [49.743413108000084, 7.581425429000092],
              [49.742889627000068, 7.580152417000079],
              [49.742068713000037, 7.578296438000052],
              [49.740974161000054, 7.575655236000046],
              [49.739867716000049, 7.573188824000056],
              [49.736716179000041, 7.566868170000077],
              [49.736155752000059, 7.565744781000092],
              [49.734918433000075, 7.562937017000081],
              [49.73370490700006, 7.560854988000074],
              [49.732396204000054, 7.55855880900009],
              [49.730948079000086, 7.55565473300004],
              [49.729497504000051, 7.552852064000092],
              [49.727327952000053, 7.548660251000058],
              [49.726590698000052, 7.547096062000037],
              [49.724865210000075, 7.543972713000073],
              [49.724175166000066, 7.542687804000082],
              [49.722815095000044, 7.539716316000067],
              [49.72176978400006, 7.537637820000043],
              [49.720843921000039, 7.535965826000051],
              [49.719701779000047, 7.534062257000073],
              [49.719130709000069, 7.533027192000077],
              [49.718523946000062, 7.531766078000089],
              [49.718190822000054, 7.531016547000092],
              [49.716941605000045, 7.528589497000041],
              [49.714526452000086, 7.523735397000053],
              [49.712752021000085, 7.519935165000049],
              [49.71146884400008, 7.517608285000051],
              [49.710193348000075, 7.514828195000064],
              [49.708492766000063, 7.51163630800005],
              [49.707275836000065, 7.508895937000091],
              [49.707019252000066, 7.508494949000067],
              [49.706437864000065, 7.507220616000041],
              [49.704520818000049, 7.503438594000045],
              [49.703033667000057, 7.500328744000058],
              [49.699845177000043, 7.493896956000071],
              [49.696966030000056, 7.488174352000044],
              [49.694895898000084, 7.483938911000052],
              [49.694085493000046, 7.481982234000043],
              [49.692825767000045, 7.479751060000069],
              [49.690486771000053, 7.475284108000039],
              [49.687019883000062, 7.468662772000073],
              [49.685282876000088, 7.465319629000078],
              [49.683545869000056, 7.461928897000064],
              [49.682641674000081, 7.460156199000039],
              [49.68130917600007, 7.457681559000093],
              [49.680012370000043, 7.455325893000065],
              [49.678251569000054, 7.452006545000074],
              [49.676133848000063, 7.448829964000083],
              [49.671812322000051, 7.441791914000078],
              [49.668150757000035, 7.436159334000081],
              [49.666726246000053, 7.433783933000086],
              [49.660441303000084, 7.423821829000076],
              [49.65825219900006, 7.420097973000054],
              [49.65649456400007, 7.417425139000045],
              [49.654207115000077, 7.413744812000061],
              [49.650070019000054, 7.407966781000084],
              [49.647616008000057, 7.404238865000082],
              [49.647282884000049, 7.403679692000082],
              [49.646985451000035, 7.403287081000087],
              [49.646975173000044, 7.403273758000068],
              [49.646664224000062, 7.402870675000088],
              [49.646256933000075, 7.402306638000084],
              [49.643654206000065, 7.39807606100004],
              [49.639656711000043, 7.392305868000051],
              [49.637610375000065, 7.389164980000089],
              [49.63485247300008, 7.385140529000068],
              [49.629697547000035, 7.37805251900005],
              [49.626843314000041, 7.374340937000056],
              [49.625213383000073, 7.371223844000042],
              [49.62269462900008, 7.367177540000057],
              [49.616635426000073, 7.358243884000046],
              [49.614315452000085, 7.355459914000051],
              [49.613732484000082, 7.354781768000066],
              [49.610731309000073, 7.351155874000085],
              [49.606296668000084, 7.344740443000092],
              [49.601977661000035, 7.338775094000084],
              [49.597067525000057, 7.331764542000087],
              [49.593515801000081, 7.326054086000056],
              [49.589081268000086, 7.319173134000039],
              [49.584679265000034, 7.312998433000075],
              [49.583400474000086, 7.311390483000082],
              [49.577600368000049, 7.303409205000037],
              [49.575435059000085, 7.300101754000082],
              [49.573625643000071, 7.298115532000054],
              [49.572044327000071, 7.295533189000082],
              [49.570807007000042, 7.294117410000069],
              [49.565500809000071, 7.287098],
              [49.563930365000033, 7.285349096000061],
              [49.562788223000041, 7.284040392000065],
              [49.562466996000069, 7.283695370000089],
              [49.561393249000048, 7.282423952000045],
              [49.56043255700007, 7.280935196000087],
              [49.559611643000039, 7.279519416000085],
              [49.558953141000075, 7.278410265000048],
              [49.558467445000076, 7.277562620000083],
              [49.558041199000058, 7.276973393000048],
              [49.557329688000038, 7.276276858000074],
              [49.556970441000033, 7.275640895000038],
              [49.556720598000084, 7.275962122000067],
              [49.555911581000032, 7.275117414000078],
              [49.554721851000068, 7.273368510000068],
              [49.553076217000068, 7.27100491300007],
              [49.551937881000072, 7.269513783000093],
              [49.551303764000068, 7.268756446000054],
              [49.54755967300008, 7.264100509000059],
              [49.547036192000064, 7.263113033000081],
              [49.545474553000076, 7.261253589000091],
              [49.542396243000042, 7.257104894000065],
              [49.540599750000069, 7.254761125000073],
              [49.538957921000076, 7.252809967000076],
              [49.537030558000083, 7.250489992000041],
              [49.534817659000055, 7.247753612000054],
              [49.533654890000037, 7.246431122000047],
              [49.529535256000088, 7.240936456000043],
              [49.52783394100004, 7.238533201000052],
              [49.525680529000056, 7.235332826000047],
              [49.522515846000033, 7.231656558000054],
              [49.521994923000079, 7.230670057000054],
              [49.521338013000047, 7.229800579000084],
              [49.520552791000057, 7.22895587000005],
              [49.519767568000077, 7.22775424200006],
              [49.519327368000063, 7.227290247000042],
              [49.518375584000069, 7.226338463000047],
              [49.517923486000086, 7.225886365000065],
              [49.517768821000061, 7.225362884000049],
              [49.517352416000051, 7.224732327000083],
              [49.51582654200007, 7.22282226100009],
              [49.515187106000042, 7.221948357000088],
              [49.513521483000034, 7.219438026000091],
              [49.510863998000048, 7.21522411400008],
              [49.510202135000043, 7.214012855000078],
              [49.509500194000054, 7.212918303000038],
              [49.508560307000039, 7.211466832000042],
              [49.507977339000035, 7.21072919900007],
              [49.505264754000052, 7.207064829000046],
              [49.505062499000076, 7.206743602000074],
              [49.504491038000083, 7.20587256400006],
              [49.503622926000048, 7.204911417000062],
              [49.502207146000046, 7.20322199900005],
              [49.501533737000045, 7.202206208000064],
              [49.500672394000048, 7.201056690000087],
              [49.498126371000069, 7.197439909000082],
              [49.496773440000084, 7.196024069000089],
              [49.495199634000073, 7.193965896000066],
              [49.493751735000046, 7.192068298000038],
              [49.491880285000036, 7.189551996000091],
              [49.490755830000069, 7.188177434000067],
              [49.489179597000089, 7.186434902000087],
              [49.487552833000052, 7.184684109000045],
              [49.486478909000084, 7.183258321000039],
              [49.485622303000071, 7.182235153000079],
              [49.484908465000046, 7.180855066000049],
              [49.484182729000054, 7.179831898000089],
              [49.483480788000065, 7.179010984000058],
              [49.482318257000088, 7.177713041000061],
              [49.481419912000035, 7.176530343000081],
              [49.479673651000041, 7.174442418000069],
              [49.479233450000038, 7.174144986000044],
              [49.476314178000052, 7.170410641000046],
              [49.474986112000067, 7.169219501000043],
              [49.474644366000064, 7.168281137000065],
              [49.474890934000086, 7.167910798000094],
              [49.474559825000085, 7.166701596000053],
              [49.474314822000053, 7.166171408000082],
              [49.474022431000037, 7.165840667000055],
              [49.473629820000042, 7.165103034000083],
              [49.473379976000047, 7.164210736000086],
              [49.47303495400007, 7.163449309000043],
              [49.47276131600006, 7.162818751000088],
              [49.472273130000076, 7.162215374000084],
              [49.471916608000072, 7.161688507000065],
              [49.471024310000075, 7.16060585300005],
              [49.470512726000038, 7.160201344000086],
              [49.469656120000082, 7.159416122000039],
              [49.46912074100004, 7.15871418100005],
              [49.468067243000064, 7.156994987000076],
              [49.467014918000075, 7.154728584000054],
              [49.465739713000062, 7.152590239000062],
              [49.464409408000051, 7.150362273000042],
              [49.462758842000085, 7.147981520000087],
              [49.460352427000032, 7.144473107000067],
              [49.459959816000037, 7.143961523000087],
              [49.458912853000072, 7.142652819000091],
              [49.458068145000084, 7.14155826700005],
              [49.455688684000052, 7.137322827000048],
              [49.453725628000086, 7.134062965000055],
              [49.451988622000044, 7.131612120000057],
              [49.449136999000075, 7.127392948000079],
              [49.447659841000075, 7.124905721000061],
              [49.445845047000034, 7.121954755000047],
              [49.444072458000051, 7.119256713000084],
              [49.443204797000078, 7.117746999000076],
              [49.442764118000071, 7.116980227000056],
              [49.441756939000072, 7.11517004500007],
              [49.440611688000047, 7.113354689000062],
              [49.439841473000058, 7.112183821000087],
              [49.439044354000032, 7.111077372000068],
              [49.438604154000075, 7.110220766000054],
              [49.438211542000033, 7.109673490000091],
              [49.437438218000068, 7.108614630000091],
              [49.436070028000074, 7.106794342000057],
              [49.434927886000082, 7.105033541000068],
              [49.434047486000054, 7.10392709100006],
              [49.433024317000047, 7.102642183000057],
              [49.43189407400007, 7.101440555000067],
              [49.431632333000039, 7.101012252000089],
              [49.43107315900005, 7.100310311000044],
              [49.42940709700008, 7.097402029000079],
              [49.428467650000073, 7.095634670000038],
              [49.426778232000061, 7.091423024000051],
              [49.42640941600007, 7.09063780200006],
              [49.426004908000039, 7.089757401000043],
              [49.425695578000045, 7.089031666000039],
              [49.425505221000037, 7.087247070000046],
              [49.425326761000065, 7.086771178000049],
              [49.424767588000066, 7.085878880000053],
              [49.424041852000073, 7.084974685000077],
              [49.423470782000038, 7.084367922000069],
              [49.42331611700007, 7.084153771000047],
              [49.423054376000039, 7.083880132000047],
              [49.422840224000083, 7.083618392000062],
              [49.422637970000039, 7.083190089000084],
              [49.422373953000033, 7.082625155000073],
              [49.422007413000074, 7.082036050000056],
              [49.421616430000086, 7.081055995000042],
              [49.421198396000079, 7.080477503000054],
              [49.420912861000033, 7.079846946000089],
              [49.420544045000042, 7.078954648000092],
              [49.419972974000075, 7.078169426000045],
              [49.419616055000063, 7.077562664000084],
              [49.419259136000051, 7.076836928000091],
              [49.418914114000074, 7.076051706000044],
              [49.418747551000081, 7.075659095000049],
              [49.418569092000041, 7.074778694000088],
              [49.418366838000054, 7.074219521000089],
              [49.417990068000051, 7.073640503000092],
              [49.41765299900004, 7.073160661000088],
              [49.417343669000047, 7.072672871000066],
              [49.417019364000055, 7.072164856000086],
              [49.416892751000034, 7.071785404000082],
              [49.416597319000061, 7.071237305000068],
              [49.416217479000068, 7.070604882000055],
              [49.415795434000074, 7.070141104000072],
              [49.415542206000055, 7.069930296000052],
              [49.415540791000069, 7.069929825000088],
              [49.415489248000085, 7.069781073000058],
              [49.414941378000037, 7.069249857000045],
              [49.41451713500004, 7.068898403000048],
              [49.414487094000037, 7.068834093000078],
              [49.413373136000075, 7.067674114000056],
              [49.413120126000081, 7.067152522000072],
              [49.412798913000074, 7.066557355000043],
              [49.412489569000059, 7.066343505000077],
              [49.412049369000044, 7.066057970000088],
              [49.411716244000047, 7.065796229000057],
              [49.411287941000069, 7.065106185000047],
              [49.410847369000066, 7.064615193000066],
              [49.410110108000083, 7.063904557000058],
              [49.408942754000066, 7.062920425000073],
              [49.408451848000084, 7.062383305000083],
              [49.406679259000043, 7.060485996000068],
              [49.405505851000044, 7.059133738000071],
              [49.404851499000074, 7.058348516000081],
              [49.404423196000039, 7.058003494000047],
              [49.403840228000035, 7.05725396400004],
              [49.403245363000053, 7.056528228000047],
              [49.402769471000056, 7.055873876000078],
              [49.402115119000086, 7.055064860000073],
              [49.401544048000062, 7.054469994000044],
              [49.401008670000067, 7.053851335000047],
              [49.400068783000052, 7.052982831000065],
              [49.399377878000053, 7.052432890000091],
              [49.398260392000054, 7.051555155000074],
              [49.396972220000066, 7.050324724000063],
              [49.396237850000034, 7.049389845000064],
              [49.395495062000066, 7.048849001000065],
              [49.393929773000082, 7.047272125000063],
              [49.393001783000045, 7.046225162000042],
              [49.391835847000038, 7.045130610000058],
              [49.39113390600005, 7.044119339000076],
              [49.390384376000043, 7.043000992000088],
              [49.389622949000056, 7.041359164000085],
              [49.389492078000046, 7.040300304000084],
              [49.389634846000035, 7.038872628000092],
              [49.389456386000063, 7.038503811000055],
              [49.388932905000047, 7.038218276000066],
              [49.388671164000073, 7.037968432000071],
              [49.388528397000073, 7.037659102000077],
              [49.388147683000057, 7.037290286000086],
              [49.387885942000082, 7.037028545000055],
              [49.387564715000053, 7.03663593400006],
              [49.387088823000056, 7.03598158200009],
              [49.386672417000057, 7.035612766000042],
              [49.386125141000036, 7.034815647000073],
              [49.385958579000032, 7.033994733000043],
              [49.385161459000074, 7.033019153000055],
              [49.384840232000045, 7.032448083000077],
              [49.384804540000061, 7.031960293000054],
              [49.385101973000076, 7.031484401000057],
              [49.385280432000059, 7.031508196000061],
              [49.385585060000039, 7.031760899000062],
              [49.385720633000062, 7.031686655000044],
              [49.385794116000056, 7.031642565000084],
              [49.385696838000058, 7.031210763000047],
              [49.385673043000054, 7.030841947000056],
              [49.385423200000048, 7.030247081000084],
              [49.385264890000087, 7.029857307000043],
              [49.384804540000061, 7.029438065000079],
              [49.38416208600006, 7.028664740000067],
              [49.383602912000072, 7.027855723000073],
              [49.382865279000043, 7.027022912000064],
              [49.382032468000034, 7.026261484000088],
              [49.381502223000041, 7.025284920000047],
              [49.381044992000056, 7.024976575000039],
              [49.380748973000038, 7.024563718000081],
              [49.379617315000075, 7.02325146600009],
              [49.37888973400004, 7.022044541000071],
              [49.378641736000077, 7.021573946000046],
              [49.377523390000079, 7.020051091000084],
              [49.375322388000086, 7.01731471100004],
              [49.373287949000087, 7.014066747000072],
              [49.372062527000082, 7.012353535000045],
              [49.371420072000035, 7.011330366000038],
              [49.370658645000049, 7.010640323000075],
              [49.370413556000074, 7.009887395000078],
              [49.369540298000061, 7.008665370000074],
              [49.368814562000068, 7.00798722400009],
              [49.368184005000046, 7.007320975000084],
              [49.367815189000055, 7.006559547000052],
              [49.367363091000072, 7.006107450000059],
              [49.367077556000083, 7.005738633000078],
              [49.366601664000086, 7.005298433000064],
              [49.366030593000062, 7.004596492000076],
              [49.365733160000048, 7.004334751000044],
              [49.36543572800008, 7.003870756000083],
              [49.365043117000084, 7.003168815000038],
              [49.364995527000076, 7.002633436000053],
              [49.365281063000054, 7.002288415000066],
              [49.365550892000044, 7.002582100000041],
              [49.36581644100005, 7.002312209000081],
              [49.365078808000078, 7.00046812700009],
              [49.364817068000036, 7.000206386000059],
              [49.361057519000042, 6.993520101000058],
              [49.360165221000045, 6.99227088400005],
              [49.35949897200004, 6.991009769000073],
              [49.359094464000066, 6.989939012000093],
              [49.358713750000049, 6.989498812000079],
              [49.358192071000076, 6.988581694000061],
              [49.357886570000062, 6.988044621000085],
              [49.357286074000058, 6.987071761000038],
              [49.357071922000046, 6.986357923000071],
              [49.356798284000035, 6.986215156000071],
              [49.356548441000086, 6.986215156000071],
              [49.356715003000033, 6.98585823600007],
              [49.356548441000086, 6.985358550000058],
              [49.356013062000045, 6.984466252000061],
              [49.355140535000032, 6.983069822000061],
              [49.35513680400004, 6.983073923000063],
              [49.353495877000057, 6.980917018000071],
              [49.351611059000049, 6.978374831000053],
              [49.350665654000068, 6.977008022000064],
              [49.350023620000059, 6.97582368400009],
              [49.348398787000065, 6.973247093000055],
              [49.346566602000053, 6.969927745000064],
              [49.345305488000065, 6.967917100000079],
              [49.344044373000088, 6.965490050000085],
              [49.342807053000058, 6.963491303000069],
              [49.342386013000066, 6.962821250000047],
              [49.341468318000068, 6.961496727000053],
              [49.34099866300005, 6.960505079000086],
              [49.340225338000039, 6.959327246000043],
              [49.339547192000055, 6.957982851000054],
              [49.338833354000087, 6.95634102300005],
              [49.338333667000086, 6.955543903000091],
              [49.337975976000052, 6.954772165000065],
              [49.336501482000074, 6.952855112000066],
              [49.334681194000041, 6.950749289000044],
              [49.333788896000044, 6.949428688000069],
              [49.33264824400004, 6.947690891000093],
              [49.331445127000052, 6.945966573000078],
              [49.330814570000086, 6.945050480000077],
              [49.330303022000066, 6.944226632000039],
              [49.330028345000073, 6.94399549700006],
              [49.329350152000075, 6.942724828000053],
              [49.328732542000068, 6.941921489000038],
              [49.328595089000032, 6.941679366000074],
              [49.328391157000056, 6.941297275000068],
              [49.328136241000038, 6.940991602000054],
              [49.327911628000038, 6.94066037500005],
              [49.327649887000064, 6.940362942000093],
              [49.327400043000068, 6.940041715000064],
              [49.32706691900006, 6.939696693000087],
              [49.326757589000067, 6.939339774000075],
              [49.326388773000076, 6.93945874700006],
              [49.326388773000076, 6.938923368000076],
              [49.326210313000047, 6.938923368000076],
              [49.326071427000045, 6.939412288000085],
              [49.325948572000073, 6.939696693000087],
              [49.325603550000039, 6.939819853000074],
              [49.325139555000078, 6.939661001000047],
              [49.324901609000051, 6.939434952000056],
              [49.324675561000049, 6.939137520000088],
              [49.32425915500005, 6.938709217000053],
              [49.323438241000076, 6.938150043000064],
              [49.322641121000061, 6.937305335000076],
              [49.32178884800004, 6.93661026500007],
              [49.321427596000035, 6.936294064000037],
              [49.320590745000061, 6.935336613000061],
              [49.320261660000085, 6.934973463000063],
              [49.319750076000048, 6.934438084000078],
              [49.319000546000041, 6.93346250500008],
              [49.31863173000005, 6.932724872000051],
              [49.318310502000088, 6.932284672000037],
              [49.317953583000076, 6.931558936000044],
              [49.317787021000072, 6.930809406000037],
              [49.31770374000007, 6.929905211000062],
              [49.317684709000048, 6.929019247000042],
              [49.317668048000087, 6.928322869000056],
              [49.317822713000055, 6.927704209000069],
              [49.317929789000061, 6.927406777000044],
              [49.318024967000042, 6.927204523000057],
              [49.318286708000073, 6.926907090000043],
              [49.318560346000083, 6.926883295000039],
              [49.318881573000056, 6.927156933000049],
              [49.31904813500006, 6.927109344000087],
              [49.319071930000064, 6.926918987000079],
              [49.318941060000043, 6.926681041000052],
              [49.318857778000051, 6.926478787000065],
              [49.318762600000071, 6.926193252000076],
              [49.31827101500005, 6.925325586000042],
              [49.318118066000068, 6.925045376000071],
              [49.317786676000082, 6.924459482000088],
              [49.317204053000069, 6.923897072000045],
              [49.316454523000061, 6.923242720000076],
              [49.316097604000049, 6.92271923900006],
              [49.315597917000048, 6.921612789000051],
              [49.31534210500007, 6.920807078000053],
              [49.314979257000061, 6.920054242000049],
              [49.314467673000081, 6.918923998000082],
              [49.314075062000086, 6.918043598000054],
              [49.313453114000083, 6.916970162000041],
              [49.313218456000072, 6.916461256000048],
              [49.312873434000039, 6.915997261000086],
              [49.312683077000088, 6.915580856000076],
              [49.312409439000078, 6.915354807000085],
              [49.312112006000064, 6.914878915000088],
              [49.311969239000064, 6.914914606000082],
              [49.31216435500005, 6.915321494000068],
              [49.311827836000077, 6.915690666000046],
              [49.310850892000076, 6.915545164000093],
              [49.309958594000079, 6.91492650400005],
              [49.309197167000036, 6.914010411000049],
              [49.308983015000081, 6.913617800000054],
              [49.308320829000081, 6.912974073000044],
              [49.307472058000087, 6.912285302000043],
              [49.306365608000078, 6.911024188000056],
              [49.305532797000069, 6.910179479000078],
              [49.304676191000055, 6.909144414000082],
              [49.304021243000079, 6.908283419000043],
              [49.303831482000078, 6.908168835000083],
              [49.303593536000051, 6.907847607000065],
              [49.303212822000035, 6.907252742000082],
              [49.302427600000044, 6.905682298000045],
              [49.302272935000076, 6.904611540000076],
              [49.30216585900007, 6.903992880000089],
              [49.301999297000066, 6.903005404000055],
              [49.301689967000073, 6.902160695000077],
              [49.301547200000073, 6.901399268000091],
              [49.30161858300005, 6.900792505000084],
              [49.301797043000079, 6.900328511000055],
              [49.302045645000078, 6.900208554000073],
              [49.302487087000088, 6.900471278000055],
              [49.302725033000058, 6.900423689000093],
              [49.302752325000085, 6.900052982000091],
              [49.302510881000046, 6.899412418000054],
              [49.302192197000068, 6.899016678000066],
              [49.301988264000045, 6.898838353000087],
              [49.301547200000073, 6.898032331000081],
              [49.30133304800006, 6.897818179000069],
              [49.300940437000065, 6.897187622000047],
              [49.300714388000074, 6.896854497000049],
              [49.300226599000041, 6.89633101600009],
              [49.299985493000065, 6.896003359000076],
              [49.29976260400008, 6.895700459000068],
              [49.299274814000057, 6.895474410000077],
              [49.298870306000083, 6.895046107000042],
              [49.298418208000044, 6.894594009000059],
              [49.298323030000063, 6.894427447000055],
              [49.298158189000048, 6.894425054000067],
              [49.297928765000051, 6.894068400000037],
              [49.297704370000076, 6.893701712000052],
              [49.29745452700007, 6.89338048400009],
              [49.296859661000042, 6.892749927000068],
              [49.296978634000084, 6.892642851000062],
              [49.296990532000052, 6.89241680300006],
              [49.29696008600007, 6.891826565000088],
              [49.296628696000084, 6.891266105000057],
              [49.296271815000068, 6.890705644000093],
              [49.295884082000043, 6.890263390000086],
              [49.295134552000036, 6.889335401000039],
              [49.294563881000045, 6.888718550000078],
              [49.294283474000054, 6.888412842000093],
              [49.293885335000084, 6.888026697000043],
              [49.293421340000066, 6.887408037000057],
              [49.293212830000073, 6.887113583000087],
              [49.293088216000058, 6.886944042000039],
              [49.292909756000086, 6.886801275000039],
              [49.292790783000044, 6.886456253000063],
              [49.292397100000073, 6.885788844000047],
              [49.292269643000054, 6.885534086000064],
              [49.292100739000034, 6.885302214000092],
              [49.291755718000047, 6.884707349000053],
              [49.291428422000081, 6.883776253000065],
              [49.291148015000033, 6.883317686000055],
              [49.290842116000078, 6.882961023000064],
              [49.290102862000083, 6.882018413000083],
              [49.28992442100008, 6.881738177000045],
              [49.289771472000041, 6.881483417000084],
              [49.289618522000069, 6.881024848000038],
              [49.289389099000061, 6.880540803000088],
              [49.288793289000068, 6.879448740000043],
              [49.288496895000037, 6.879037713000059],
              [49.28826747100004, 6.878604618000054],
              [49.288089030000037, 6.878247952000038],
              [49.287885098000061, 6.877993190000041],
              [49.287706657000058, 6.877814856000043],
              [49.287567866000074, 6.877545171000065],
              [49.287353715000052, 6.877307225000038],
              [49.287091974000077, 6.876867025000081],
              [49.286806439000088, 6.876510106000069],
              [49.286534046000043, 6.876107946000047],
              [49.286432080000054, 6.875955089000058],
              [49.286330114000066, 6.875904136000088],
              [49.286235368000064, 6.875724884000078],
              [49.286056908000035, 6.875558321000085],
              [49.285997422000037, 6.875367965000066],
              [49.285866552000073, 6.87513001800005],
              [49.285699989000079, 6.874784997000063],
              [49.285604811000042, 6.874416180000082],
              [49.285473941000078, 6.873964083000089],
              [49.285235994000061, 6.873333525000078],
              [49.285057535000078, 6.872845736000045],
              [49.284843383000066, 6.87248881700009],
              [49.284712513000045, 6.872322254000039],
              [49.284629232000043, 6.87212],
              [49.284486464000054, 6.871882054000082],
              [49.28431990200005, 6.871548930000074],
              [49.284236621000048, 6.871156319000079],
              [49.284105750000037, 6.870882681000069],
              [49.283986777000052, 6.870739913000079],
              [49.283784523000065, 6.870525761000067],
              [49.28352278300008, 6.870121253000093],
              [49.283368118000055, 6.869811923000043],
              [49.283225350000066, 6.869478799000092],
              [49.283201555000062, 6.869157571000073],
              [49.283296620000044, 6.86856689800004],
              [49.28324563700005, 6.868031887000086],
              [49.283082582000077, 6.867694203000042],
              [49.282452025000055, 6.866867340000056],
              [49.282071311000038, 6.866105913000069],
              [49.280594517000054, 6.864108452000039],
              [49.280405689000077, 6.863839476000066],
              [49.280288618000043, 6.863522481000075],
              [49.280033703000072, 6.86319128000008],
              [49.279804279000075, 6.862834602000078],
              [49.279523872000084, 6.862426969000069],
              [49.278759126000068, 6.861127638000085],
              [49.278453227000057, 6.860363323000058],
              [49.27824929500008, 6.860006643000077],
              [49.278147329000035, 6.859700917000055],
              [49.277943396000069, 6.859369713000092],
              [49.277484549000064, 6.858554441000081],
              [49.277025701000071, 6.857611782000049],
              [49.276515870000082, 6.856643643000041],
              [49.27618448000004, 6.856083141000056],
              [49.276031531000058, 6.855751934000068],
              [49.275674649000052, 6.855344296000055],
              [49.275598175000084, 6.855293341000049],
              [49.275394242000061, 6.855038566000076],
              [49.275317768000036, 6.854605449000076],
              [49.275292276000073, 6.854299720000085],
              [49.275037361000045, 6.85368825900008],
              [49.274960886000088, 6.853382529000044],
              [49.274807937000048, 6.853127753000081],
              [49.274654988000066, 6.852898455000059],
              [49.274076322000042, 6.85199570900005],
              [49.273862171000076, 6.851686379000057],
              [49.273711801000047, 6.851267888000052],
              [49.273743198000034, 6.850710800000058],
              [49.27357663500004, 6.850425265000069],
              [49.273600430000045, 6.849901783000064],
              [49.273529046000078, 6.849235534000059],
              [49.273362484000074, 6.848902410000051],
              [49.273148333000051, 6.84825995500006],
              [49.272815208000054, 6.847879241000044],
              [49.272577262000084, 6.847284376000061],
              [49.272315521000053, 6.84678468900006],
              [49.271982397000045, 6.846166029000074],
              [49.271392070000047, 6.845102255000086],
              [49.269403730000079, 6.842325146000064],
              [49.269148815000051, 6.841560801000071],
              [49.26886840800006, 6.841102194000086],
              [49.268588001000069, 6.840745499000093],
              [49.268333085000052, 6.840465238000093],
              [49.268056286000046, 6.840336350000086],
              [49.267309738000051, 6.839453294000066],
              [49.266628609000065, 6.838527960000079],
              [49.266108904000077, 6.837722350000092],
              [49.264830935000077, 6.835934113000064],
              [49.264002471000083, 6.834367962000044],
              [49.263249775000077, 6.832864842000049],
              [49.262297990000036, 6.831246809000049],
              [49.26153656300005, 6.830057078000038],
              [49.260989287000086, 6.828629402000047],
              [49.260679957000036, 6.82746346600004],
              [49.25918185900008, 6.825630821000061],
              [49.258415078000041, 6.824660040000083],
              [49.258057247000067, 6.824098008000078],
              [49.257705631000078, 6.823703917000046],
              [49.256825230000061, 6.822847311000089],
              [49.256158981000056, 6.822038295000084],
              [49.255278580000038, 6.820896153000092],
              [49.253969877000088, 6.818754639000076],
              [49.252470816000084, 6.81689865900006],
              [49.251257291000059, 6.814923706000059],
              [49.25069695500008, 6.81361118500007],
              [49.249425106000047, 6.812020764000067],
              [49.247926904000053, 6.809677273000091],
              [49.247450154000035, 6.808665724000093],
              [49.247378770000068, 6.807785323000076],
              [49.247526787000083, 6.806572498000037],
              [49.24747566800005, 6.805806061000055],
              [49.24722007400004, 6.805397295000091],
              [49.246712521000063, 6.804287516000045],
              [49.245213460000059, 6.802574304000075],
              [49.244618595000077, 6.801931849000084],
              [49.244166497000037, 6.801432162000083],
              [49.24354783800004, 6.800789708000082],
              [49.242500875000076, 6.799599977000071],
              [49.242072572000041, 6.799124085000074],
              [49.241501501000073, 6.798624398000072],
              [49.24104940400008, 6.797767792000059],
              [49.239907262000088, 6.79600699100007],
              [49.239288602000045, 6.794198601000062],
              [49.238860299000066, 6.793508557000052],
              [49.238289229000088, 6.792818514000089],
              [49.237908515000072, 6.79198570200009],
              [49.237527801000056, 6.791390837000051],
              [49.236480838000034, 6.789867982000089],
              [49.235862179000037, 6.788773430000049],
              [49.235410081000055, 6.787369548000072],
              [49.234886600000038, 6.785513568000056],
              [49.233980322000036, 6.783732157000088],
              [49.233060184000067, 6.782199209000055],
              [49.232242285000041, 6.780461862000038],
              [49.231526622000047, 6.778775607000057],
              [49.230964316000041, 6.777753632000042],
              [49.23072254300007, 6.776995098000043],
              [49.230294240000035, 6.776328849000038],
              [49.229737466000074, 6.775505278000082],
              [49.229584110000076, 6.774943188000066],
              [49.229072923000047, 6.774329998000042],
              [49.228561735000085, 6.773767906000046],
              [49.227986163000082, 6.773259344000053],
              [49.227462681000077, 6.771760284000038],
              [49.227034378000042, 6.770689526000069],
              [49.226344335000078, 6.76916667100005],
              [49.225892566000084, 6.768147912000074],
              [49.22560670200005, 6.767334486000038],
              [49.224488355000062, 6.766049577000047],
              [49.223179651000066, 6.764526722000085],
              [49.222069656000087, 6.763266893000093],
              [49.221728180000071, 6.762551770000073],
              [49.221098400000074, 6.761580577000075],
              [49.220587213000044, 6.760711867000055],
              [49.220382738000069, 6.760456364000049],
              [49.219729433000055, 6.759577443000069],
              [49.21895141300007, 6.758361231000038],
              [49.218420729000059, 6.757221777000041],
              [49.217683096000087, 6.755936868000049],
              [49.216874080000082, 6.754818521000061],
              [49.21582711700006, 6.753367050000065],
              [49.214657439000064, 6.75171806700007],
              [49.213992895000047, 6.750338321000072],
              [49.213542834000066, 6.748941253000055],
              [49.213090737000073, 6.747775317000048],
              [49.211692552000045, 6.74533032100004],
              [49.210465703000068, 6.743286225000077],
              [49.209759491000057, 6.741802870000072],
              [49.209164626000074, 6.740137247000064],
              [49.208760118000043, 6.739161668000065],
              [49.208318716000065, 6.737767120000058],
              [49.20785864700008, 6.736693954000089],
              [49.207617976000051, 6.735473503000037],
              [49.207332441000062, 6.734735870000065],
              [49.206927933000088, 6.73337957800004],
              [49.20619030000006, 6.731713955000089],
              [49.205353829000046, 6.729335033000041],
              [49.204920864000087, 6.728407517000051],
              [49.204905391000068, 6.72778784400009],
              [49.204638167000041, 6.726626513000042],
              [49.204310526000086, 6.724456599000064],
              [49.203922504000047, 6.722282627000084],
              [49.203406330000064, 6.720007007000049],
              [49.202597314000059, 6.717175448000091],
              [49.201835886000083, 6.714986344000067],
              [49.201407583000048, 6.71358246200009],
              [49.20105985500004, 6.712368201000061],
              [49.200650905000032, 6.711806037000088],
              [49.200446430000056, 6.711192766000067],
              [49.200088599000082, 6.710170646000051],
              [49.199577412000053, 6.708688568000071],
              [49.199270699000067, 6.707002060000093],
              [49.199270699000067, 6.705877718000067],
              [49.199117343000069, 6.704906694000044],
              [49.199066224000035, 6.704497841000091],
              [49.198912868000036, 6.703884560000063],
              [49.198606156000039, 6.703475706000063],
              [49.198480846000052, 6.70325560100008],
              [49.198076338000078, 6.702779709000083],
              [49.197790802000043, 6.702541763000056],
              [49.197433883000087, 6.702065871000059],
              [49.196934196000086, 6.701589979000062],
              [49.19641071500007, 6.700804757000071],
              [49.195181200000036, 6.699029397000061],
              [49.193800994000071, 6.696780674000081],
              [49.193365432000064, 6.696177720000037],
              [49.192598715000088, 6.694944575000079],
              [49.191318669000054, 6.691572448000045],
              [49.19046731800006, 6.68921540100007],
              [49.188556058000074, 6.686436884000045],
              [49.187732824000079, 6.685239996000064],
              [49.187107023000067, 6.683791610000071],
              [49.186179373000073, 6.681281923000085],
              [49.186088669000071, 6.681087593000086],
              [49.186056506000057, 6.680873210000073],
              [49.185852031000081, 6.680208790000052],
              [49.185264165000035, 6.678803284000082],
              [49.184778537000057, 6.677653322000083],
              [49.184275464000052, 6.676248719000057],
              [49.183894750000036, 6.675249345000054],
              [49.183537831000081, 6.674583096000049],
              [49.183109528000045, 6.673940642000048],
              [49.182861585000069, 6.673411215000044],
              [49.182708228000081, 6.672951225000077],
              [49.182681225000067, 6.672441581000044],
              [49.182467074000044, 6.671608770000091],
              [49.182229127000085, 6.671013905000052],
              [49.181872208000073, 6.670228683000062],
              [49.181800825000039, 6.669443460000082],
              [49.181396316000075, 6.668325114000083],
              [49.180825245000051, 6.667111589000058],
              [49.180206586000054, 6.665564939000092],
              [49.179825872000038, 6.664779717000044],
              [49.179564131000063, 6.663946906000092],
              [49.179207212000051, 6.66328065600004],
              [49.178778909000073, 6.662447845000088],
              [49.178517168000042, 6.661615034000079],
              [49.178326811000034, 6.661091552000073],
              [49.178065071000049, 6.660568071000057],
              [49.177827125000078, 6.66009217900006],
              [49.177565384000047, 6.659521108000092],
              [49.176647623000065, 6.656970198000067],
              [49.175852172000077, 6.654310088000045],
              [49.174495879000062, 6.65124058400005],
              [49.173820899000077, 6.649781275000066],
              [49.172711283000069, 6.646743402000084],
              [49.172465325000076, 6.645105633000071],
              [49.17240614800005, 6.645007774000078],
              [49.172510040000077, 6.644737684000063],
              [49.172407803000056, 6.644379893000064],
              [49.172356684000079, 6.644200997000041],
              [49.172228887000074, 6.644022101000076],
              [49.171973294000054, 6.643664310000077],
              [49.171845497000049, 6.643562084000052],
              [49.17161546300008, 6.643357631000072],
              [49.171666581000068, 6.643127622000065],
              [49.171768819000079, 6.642897613000059],
              [49.171743259000039, 6.642667604000053],
              [49.171641022000074, 6.642463151000072],
              [49.171538784000063, 6.642437594000057],
              [49.171359869000071, 6.64228425500005],
              [49.171283191000043, 6.642079802000069],
              [49.171180953000032, 6.641619782000078],
              [49.171053156000085, 6.641389772000082],
              [49.170874241000035, 6.64121087500007],
              [49.170720885000037, 6.641057535000073],
              [49.170678987000088, 6.641027612000073],
              [49.170590230000073, 6.640689688000066],
              [49.17066976600006, 6.640495288000068],
              [49.17066976600006, 6.640188607000084],
              [49.170746444000088, 6.639984154000047],
              [49.171027597000034, 6.639728586000047],
              [49.171027597000034, 6.639498575000061],
              [49.170950919000063, 6.639319678000049],
              [49.170117671000071, 6.638534262000064],
              [49.170022493000033, 6.638224932000071],
              [49.16985593000004, 6.638058370000067],
              [49.169672951000052, 6.637760712000045],
              [49.169284860000062, 6.63703520200005],
              [49.168880351000041, 6.63624997900007],
              [49.169212882000068, 6.635613932000069],
              [49.169161763000034, 6.635460590000093],
              [49.168906170000071, 6.635179463000043],
              [49.168599457000084, 6.634923893000064],
              [49.168394982000052, 6.63459165200004],
              [49.168139388000043, 6.634233854000058],
              [49.16799995100007, 6.633918108000046],
              [49.167762005000043, 6.63370395600009],
              [49.167595442000049, 6.633442215000059],
              [49.167372607000061, 6.63262375700009],
              [49.167244811000046, 6.632368186000065],
              [49.167040336000071, 6.632138172000055],
              [49.166861420000032, 6.63188260000004],
              [49.166708064000034, 6.631448128000045],
              [49.166580267000086, 6.63124367100005],
              [49.166324673000076, 6.630988099000092],
              [49.166171317000078, 6.630809198000065],
              [49.166043520000073, 6.63017026700004],
              [49.165839045000041, 6.62976135100007],
              [49.165685689000043, 6.629454664000093],
              [49.16540453600004, 6.628994632000058],
              [49.165276739000035, 6.628764616000069],
              [49.165148942000087, 6.628509043000065],
              [49.164944467000055, 6.628202355000042],
              [49.164331043000061, 6.626886149000086],
              [49.164254364000044, 6.626783919000047],
              [49.164024330000075, 6.626553902000069],
              [49.163973211000041, 6.626349443000038],
              [49.163947652000047, 6.62606831100004],
              [49.163819855000042, 6.625608276000094],
              [49.163743177000072, 6.625403816000073],
              [49.163666499000044, 6.625224913000068],
              [49.163615380000067, 6.624892666000051],
              [49.163589821000073, 6.62453486000004],
              [49.163359787000047, 6.624304842000072],
              [49.163129752000089, 6.624125939000066],
              [49.162950837000039, 6.623486999000079],
              [49.162823040000035, 6.623129193000068],
              [49.162644124000053, 6.622822501000087],
              [49.162567446000082, 6.62266915500004],
              [49.162465209000061, 6.622285790000092],
              [49.162388531000033, 6.622081329000082],
              [49.162286293000079, 6.621902425000087],
              [49.162158496000075, 6.621723521000092],
              [49.162005140000076, 6.621570175000045],
              [49.161698428000079, 6.620777886000042],
              [49.161570631000075, 6.620573424000042],
              [49.161289478000072, 6.620266730000083],
              [49.161059443000056, 6.620113384000092],
              [49.160854968000081, 6.61978113300006],
              [49.160727172000065, 6.619346650000068],
              [49.160471578000056, 6.618861051000067],
              [49.160164866000059, 6.617940968000084],
              [49.159960391000084, 6.616995324000072],
              [49.159807034000039, 6.616560839000044],
              [49.159449203000065, 6.616254143000049],
              [49.159168050000062, 6.615973006000047],
              [49.158963575000087, 6.615564077000045],
              [49.158886897000059, 6.615155149000088],
              [49.158733541000061, 6.614618430000064],
              [49.158529066000085, 6.614158384000064],
              [49.158273472000076, 6.613826129000074],
              [49.157736726000053, 6.613238292000062],
              [49.157532251000077, 6.61300826900009],
              [49.157302216000062, 6.612497105000045],
              [49.15712330100007, 6.612113732000068],
              [49.15686770700006, 6.611730359000092],
              [49.156791029000033, 6.611398102000067],
              [49.156586554000057, 6.610810262000086],
              [49.156254282000077, 6.610324655000056],
              [49.155973129000074, 6.609992397000042],
              [49.155688025000075, 6.609681746000092],
              [49.15526983400008, 6.60917171300008],
              [49.154674969000041, 6.607744037000089],
              [49.154762062000088, 6.607163591000074],
              [49.154742556000087, 6.606851501000051],
              [49.154562487000078, 6.606131251000079],
              [49.154337402000067, 6.605771126000093],
              [49.154157333000057, 6.605275953000046],
              [49.154022281000039, 6.604780780000056],
              [49.153889747000051, 6.604341407000049],
              [49.15379456800008, 6.60381792600009],
              [49.153628006000076, 6.603246855000066],
              [49.153256990000045, 6.60243995400009],
              [49.153100649000066, 6.601877142000092],
              [48.671037674000047, 6.631235123000067],
              [48.491037369000082, 6.741228104000072],
              [47.891023637000046, 6.771226883000054],
              [47.691019058000052, 6.611242295000068],
              [47.688884205000079, 6.603415526000049],
              [47.430611, 6.76924],
              [47.240026440000065, 6.872517538000068],
              [47.111009598000066, 6.731229782000071],
              [46.898634811000079, 6.88412268400009],
              [47.362309871000036, 7.359877187000052],
              [47.559156542000039, 7.561852013000077],
              [47.949378878000061, 7.962240238000049],
              [47.986179389000085, 7.999998480000045],
              [47.934078250000084, 7.999998480000045],
              [47.919901429000049, 7.999998480000045],
              [48.131032944000083, 8.211095811000064],
              [48.051031114000068, 8.241090776000078],
              [48.561044693000042, 8.781042099000047],
              [48.731046677000052, 8.781038283000044],
              [49.081113815000037, 9.14053154000004],
              [49.061102039000048, 9.570964751000076],
              [49.041059494000081, 10.350896836000061],
              [49.031057358000055, 10.780858994000084],
              [48.951559067000062, 11.106061935000071],
              [48.918664024000066, 11.238826655000082],
              [48.918042899000056, 11.241333523000037],
              [48.918120227000088, 11.241333523000037],
              [48.918373964000068, 11.241333523000037],
              [48.918424712000046, 11.241358572000081],
              [48.918443635000074, 11.241297861000078],
              [48.918450086000064, 11.241308475000039],
              [48.918551581000088, 11.241433717000064],
              [48.918881440000064, 11.241483813000059],
              [48.919161454000061, 11.241762595000068],
              [48.919803909000052, 11.241655519000062],
              [48.920113239000045, 11.241524648000052],
              [48.920315493000032, 11.241465162000054],
              [48.920743796000068, 11.241310497000086],
              [48.921041228000036, 11.241227216000084],
              [48.921457634000035, 11.241084448000038],
              [48.921942192000074, 11.240864487000067],
              [48.92227205100005, 11.240714197000045],
              [48.922639180000033, 11.240699792000044],
              [48.922766049000074, 11.240674744000046],
              [48.923325511000087, 11.240656145000059],
              [48.923730019000061, 11.240596659000062],
              [48.924209932000053, 11.240531976000057],
              [48.924742781000077, 11.240582073000041],
              [48.925148761000059, 11.240557024000054],
              [48.925443231000088, 11.240608556000041],
              [48.925788253000064, 11.240584761000036],
              [48.926085686000079, 11.240501480000091],
              [48.926502091000089, 11.240608556000041],
              [48.926751935000084, 11.240965475000053],
              [48.927180238000062, 11.241429470000071],
              [48.927280158000087, 11.241533910000044],
              [48.927572849000057, 11.241596032000075],
              [48.927941665000048, 11.241429470000071],
              [48.928191509000044, 11.241346189000069],
              [48.928512736000073, 11.241274805000046],
              [48.928954827000041, 11.241133137000077],
              [48.929405034000069, 11.241013064000072],
              [48.929738158000077, 11.240870297000072],
              [48.930297332000066, 11.241001167000093],
              [48.930856505000065, 11.24116772900004],
              [48.931344294000041, 11.241274805000046],
              [48.931927262000045, 11.241405675000067],
              [48.93272438200006, 11.241524648000052],
              [48.933497707000072, 11.241643621000037],
              [48.93447328600007, 11.241726903000085],
              [48.935460762000048, 11.24171500500006],
              [48.936662390000038, 11.241726903000085],
              [48.938018683000053, 11.241679313000077],
              [48.939256002000036, 11.241619827000079],
              [48.940219684000056, 11.241655519000062],
              [48.941183366000075, 11.241560340000092],
              [48.942289815000038, 11.241322394000065],
              [48.942979859000047, 11.241096345000074],
              [48.943360350000034, 11.241101352000044],
              [48.94388405400008, 11.241108243000042],
              [48.945335525000075, 11.241096345000074],
              [48.946441975000084, 11.240858399000047],
              [48.947096326000064, 11.240822707000063],
              [48.948833333000039, 11.24092978300007],
              [48.949963577000062, 11.240977372000089],
              [48.951093821000086, 11.240953578000074],
              [48.951867146000041, 11.240894091000087],
              [48.952580984000065, 11.240870297000072],
              [48.952997390000064, 11.240882194000051],
              [48.953532768000059, 11.240882194000051],
              [48.95393727700008, 11.24092978300007],
              [48.954353973000082, 11.240957798000068],
              [48.954912196000066, 11.240982847000055],
              [48.955368924000084, 11.240957798000068],
              [48.955622662000053, 11.240957798000068],
              [48.956155511000077, 11.24083255700009],
              [48.956590376000065, 11.240894091000087],
              [48.957042473000058, 11.240894091000087],
              [48.957339906000072, 11.240858399000047],
              [48.957779433000042, 11.24093275000007],
              [48.958540646000074, 11.241057992000037],
              [48.959073495000041, 11.241133137000077],
              [48.959530223000058, 11.241183233000072],
              [48.960266062000073, 11.241258378000055],
              [48.960873406000076, 11.241429470000071],
              [48.961503963000041, 11.241417573000092],
              [48.962067600000069, 11.241483813000059],
              [48.962574720000077, 11.241596032000075],
              [48.962967331000073, 11.241810184000087],
              [48.963502710000057, 11.241881568000053],
              [48.963869138000064, 11.242009827000061],
              [48.964478108000037, 11.242335455000045],
              [48.964909463000083, 11.242535840000073],
              [48.965518433000057, 11.242661081000051],
              [48.966076656000041, 11.242786322000086],
              [48.966798263000044, 11.242940428000054],
              [48.967500204000032, 11.243011812000077],
              [48.96814265900008, 11.243249758000047],
              [48.968987368000057, 11.243511498000089],
              [48.969498952000038, 11.24359478000008],
              [48.970117612000081, 11.243725650000044],
              [48.970617298000036, 11.24373754700008],
              [48.971224061000044, 11.243749444000059],
              [48.971557391000033, 11.243738152000049],
              [48.972140988000035, 11.243825821000087],
              [48.972461381000073, 11.244261029000086],
              [48.972889684000052, 11.244142056000044],
              [48.973460754000087, 11.244058774000052],
              [48.973721813000054, 11.243846893000068],
              [48.974043722000033, 11.243820828000082],
              [48.974376847000087, 11.243856520000065],
              [48.976696821000075, 11.244058774000052],
              [48.977946038000084, 11.244403796000086],
              [48.980396883000083, 11.244927278000091],
              [48.985524622000071, 11.245938549000073],
              [48.986214665000034, 11.246224084000062],
              [48.986958246000086, 11.246498324000072],
              [48.989593500000069, 11.246842744000048],
              [48.990511553000033, 11.247343544000046],
              [48.991746912000053, 11.247818323000047],
              [48.992853361000073, 11.248187139000038],
              [48.995209028000033, 11.248805799000081],
              [48.996208401000047, 11.249388767000084],
              [48.996767575000035, 11.249591021000072],
              [48.998303141000065, 11.250344055000085],
              [49.000372458000072, 11.251137671000038],
              [49.001919108000038, 11.251887201000045],
              [49.003489552000076, 11.252577245000055],
              [49.004488926000079, 11.253053137000052],
              [49.005535889000043, 11.253386262000049],
              [49.006380597000089, 11.253885948000061],
              [49.00780827400007, 11.254468916000064],
              [49.008664880000083, 11.254813938000041],
              [49.01006876200006, 11.255218447000061],
              [49.013780721000046, 11.256015566000087],
              [49.017326118000085, 11.256717507000076],
              [49.020931001000065, 11.257467037000083],
              [49.021811491000051, 11.257703003000074],
              [49.022790992000068, 11.257845192000048],
              [49.025535258000048, 11.258133286000088],
              [49.026510837000046, 11.258502103000069],
              [49.02773626000004, 11.258502103000069],
              [49.028473893000069, 11.258573487000092],
              [49.029080655000087, 11.258680562000052],
              [49.029675521000058, 11.258668665000073],
              [49.030758175000074, 11.258704357000056],
              [49.030472640000085, 11.259215941000036],
              [49.030567818000065, 11.259549066000091],
              [49.031138889000033, 11.25965614100005],
              [49.03171707000007, 11.259746857000039],
              [49.032626052000069, 11.259453887000063],
              [49.033613529000036, 11.259489579000046],
              [49.033684912000069, 11.259810806000075],
              [49.034184599000071, 11.259775114000092],
              [49.035183973000073, 11.259715628000038],
              [49.036135757000068, 11.260036855000067],
              [49.037039952000043, 11.260453261000066],
              [49.039937946000066, 11.261526580000066],
              [49.041484595000043, 11.26206195900005],
              [49.043535881000082, 11.262594776000071],
              [49.045796369000072, 11.263249127000051],
              [49.047949781000057, 11.263760712000078],
              [49.048971807000044, 11.264038868000057],
              [49.049201134000043, 11.264051444000074],
              [49.049886226000069, 11.264201722000053],
              [49.050368328000047, 11.264352],
              [49.051078793000045, 11.264527324000085],
              [49.051637016000086, 11.264627509000093],
              [49.052144492000082, 11.264852925000071],
              [49.052499725000075, 11.265003203000049],
              [49.053137006000043, 11.264986134000083],
              [49.053898434000075, 11.265247875000057],
              [49.054992986000059, 11.265652383000088],
              [49.055409391000069, 11.265878432000079],
              [49.056063743000038, 11.266068789000087],
              [49.056787892000045, 11.266305604000081],
              [49.057610393000061, 11.266568476000089],
              [49.058086285000059, 11.26666365400007],
              [49.058645458000058, 11.266782627000055],
              [49.059604381000042, 11.267006895000065],
              [49.060560924000072, 11.267508363000047],
              [49.061250968000081, 11.267579746000081],
              [49.061905320000051, 11.267782001000057],
              [49.062535877000073, 11.26796046000004],
              [49.063035564000074, 11.268043741000042],
              [49.063689916000044, 11.268138920000069],
              [49.06421339700006, 11.268245996000076],
              [49.064843954000082, 11.268329277000078],
              [49.065491096000073, 11.268409471000041],
              [49.066188350000061, 11.268567223000048],
              [49.066580961000057, 11.268662401000086],
              [49.067056853000054, 11.268757580000056],
              [49.067627924000078, 11.268900347000056],
              [49.068341762000045, 11.269043115000045],
              [49.069198368000059, 11.26919778000007],
              [49.070159870000055, 11.269411306000052],
              [49.070756915000061, 11.269507110000063],
              [49.071958543000051, 11.269697467000071],
              [49.072553408000033, 11.269816440000056],
              [49.073600371000055, 11.269994899000039],
              [49.074242825000056, 11.270090078000067],
              [49.075336120000088, 11.270287910000093],
              [49.076198828000088, 11.270488276000037],
              [49.076909294000075, 11.270613504000039],
              [49.077478892000045, 11.270792019000055],
              [49.078335498000058, 11.27094668400008],
              [49.079091438000034, 11.271039282000061],
              [49.080703062000055, 11.271482062000075],
              [49.082261609000057, 11.271838982000077],
              [49.083451339000078, 11.272053133000043],
              [49.083912455000075, 11.272208922000061],
              [49.084369183000035, 11.272259013000053],
              [49.085033681000084, 11.272374360000072],
              [49.085587124000085, 11.272416792000058],
              [49.086221468000076, 11.272592111000051],
              [49.087033429000087, 11.272792476000063],
              [49.087464783000087, 11.272867612000084],
              [49.088124501000038, 11.272992840000086],
              [49.088961835000077, 11.273067977000039],
              [49.089443937000055, 11.273118068000088],
              [49.09000216000004, 11.273243295000043],
              [49.090636504000088, 11.273318432000053],
              [49.091448465000042, 11.273418614000093],
              [49.092767901000059, 11.273568887000067],
              [49.094442570000069, 11.27366906900005],
              [49.095432147000054, 11.273819341000092],
              [49.096041118000073, 11.273994659000039],
              [49.096929200000034, 11.27411988700004],
              [49.097276008000051, 11.274575362000064],
              [49.097918777000075, 11.274445477000086],
              [49.098375505000035, 11.27452061300005],
              [49.099136718000068, 11.274620795000089],
              [49.09972031500007, 11.274746022000045],
              [49.10015166900007, 11.274846203000038],
              [49.102232318000063, 11.275246929000048],
              [49.102689282000085, 11.275443865000057],
              [49.103034303000072, 11.275610427000061],
              [49.10364106600008, 11.27578888700009],
              [49.104533364000076, 11.276062525000043],
              [49.105201050000062, 11.276123514000062],
              [49.105683151000051, 11.276248740000085],
              [49.106444365000073, 11.276424057000042],
              [49.106758160000084, 11.276609801000063],
              [49.107293539000068, 11.276776363000067],
              [49.108307704000083, 11.27696504700009],
              [49.109271908000039, 11.277040183000054],
              [49.110077508000074, 11.277133282000079],
              [49.110803244000067, 11.277311742000052],
              [49.111291033000043, 11.277335537000056],
              [49.111683644000038, 11.277513996000039],
              [49.112742504000039, 11.277823326000089],
              [49.113622905000057, 11.278025580000076],
              [49.114836430000082, 11.278108861000078],
              [49.115562166000075, 11.278299218000086],
              [49.11757281000007, 11.278334910000069],
              [49.118714951000072, 11.278501472000073],
              [49.120249704000059, 11.278715624000085],
              [49.121130104000088, 11.278787008000052],
              [49.121891532000063, 11.278905981000037],
              [49.122771932000035, 11.279001159000074],
              [49.123497668000084, 11.27912013200006],
              [49.124437555000043, 11.279465154000093],
              [49.125686772000051, 11.279774484000086],
              [49.127150141000072, 11.279929149000054],
              [49.127852082000061, 11.280083814000079],
              [49.128554023000049, 11.280143301000066],
              [49.129232169000034, 11.28003622500006],
              [49.130529441000078, 11.280363066000064],
              [49.131163785000069, 11.280538380000053],
              [49.132396852000056, 11.280535912000062],
              [49.13256341400006, 11.280000533000077],
              [49.132729977000054, 11.279691203000084],
              [49.132932231000041, 11.279584127000078],
              [49.133086826000067, 11.279479559000038],
              [49.133289816000058, 11.279629828000054],
              [49.133241561000034, 11.280060019000075],
              [49.13297982000006, 11.280642987000078],
              [49.134800108000036, 11.281368723000071],
              [49.136156401000051, 11.28178512900007],
              [49.137512693000076, 11.28178512900007],
              [49.138095661000079, 11.281856512000047],
              [49.138809500000036, 11.282189637000045],
              [49.139725592000048, 11.282320507000065],
              [49.142247821000069, 11.283058140000037],
              [49.143853957000033, 11.283331778000047],
              [49.146566542000073, 11.28405751400004],
              [49.147399354000072, 11.284128898000063],
              [49.148458214000073, 11.284140795000042],
              [49.149279128000046, 11.284307357000046],
              [49.149981069000034, 11.284462022000071],
              [49.150968545000069, 11.284699968000041],
              [49.151777562000063, 11.284771352000064],
              [49.152681757000039, 11.284890325000049],
              [49.153407493000088, 11.285021196000059],
              [49.154573429000038, 11.285044990000074],
              [49.156203360000063, 11.285282936000044],
              [49.158225901000037, 11.285473293000052],
              [49.159695664000083, 11.285202966000043],
              [49.161378687000081, 11.285544677000075],
              [49.162699288000056, 11.285687445000065],
              [49.164031786000066, 11.28553278000004],
              [49.165935355000045, 11.286068158000091],
              [49.166934729000047, 11.286532153000053],
              [49.167518191000056, 11.286884021000049],
              [49.167974919000073, 11.287184553000088],
              [49.168528968000032, 11.287769473000083],
              [49.168778811000038, 11.288292954000042],
              [49.169076244000053, 11.289078177000079],
              [49.169718698000054, 11.28930422500008],
              [49.170206488000076, 11.289411301000086],
              [49.17102740200005, 11.289589761000059],
              [49.171527088000062, 11.289696836000076],
              [49.171979186000044, 11.289827707000086],
              [49.172431284000083, 11.289899091000052],
              [49.173145122000051, 11.289792015000046],
              [49.173837104000086, 11.289522409000085],
              [49.173913225000035, 11.289372145000073],
              [49.173838838000052, 11.289200831000073],
              [49.173906126000077, 11.289200831000073],
              [49.173847063000039, 11.288435722000088],
              [49.174085009000066, 11.288173981000057],
              [49.174239674000034, 11.288043111000093],
              [49.174477620000062, 11.287876549000089],
              [49.175798221000036, 11.28782896000007],
              [49.176375194000059, 11.287720995000086],
              [49.176878222000084, 11.287879451000038],
              [49.177498981000042, 11.287847760000091],
              [49.177813872000058, 11.287847760000091],
              [49.177873576000081, 11.287847760000091],
              [49.178087631000039, 11.287879451000038],
              [49.178537146000053, 11.287953397000081],
              [49.178794011000036, 11.288027343000067],
              [49.179029471000035, 11.28817523500004],
              [49.179275634000078, 11.288302],
              [49.179575311000065, 11.288460456000053],
              [49.179654901000049, 11.288583902000084],
              [49.179937750000079, 11.288685052000062],
              [49.180110218000038, 11.288798537000048],
              [49.180387265000036, 11.288980835000075],
              [49.180708347000063, 11.28942451000006],
              [49.180729752000047, 11.289910438000049],
              [49.180804671000033, 11.29038580200006],
              [49.180851418000088, 11.290576951000048],
              [49.18092385500006, 11.290573188000053],
              [49.180998774000045, 11.290488679000077],
              [49.18104158500006, 11.290192898000043],
              [49.180988071000058, 11.289928807000081],
              [49.180934558000047, 11.289728097000079],
              [49.180934558000047, 11.289548515000092],
              [49.181137909000086, 11.289421751000077],
              [49.181426883000086, 11.289379496000038],
              [49.181630235000057, 11.289516824000088],
              [49.18173726200007, 11.289706970000054],
              [49.181865695000056, 11.290002752000078],
              [49.181876398000043, 11.29026684300004],
              [49.181683749000058, 11.290615443000092],
              [49.181555316000072, 11.290847842000062],
              [49.181148612000072, 11.291069678000042],
              [49.180977368000072, 11.291048551000074],
              [49.180784719000087, 11.290900660000091],
              [49.180303096000046, 11.290921788000048],
              [49.180260285000088, 11.291027424000049],
              [49.180249583000034, 11.291217569000082],
              [49.180067636000047, 11.291376023000055],
              [49.179864284000075, 11.291365459000076],
              [49.179736229000071, 11.291374356000063],
              [49.179510180000079, 11.291350562000048],
              [49.179248439000048, 11.291326767000044],
              [49.178890336000052, 11.291323205000083],
              [49.178868930000078, 11.291576731000077],
              [49.178879623000057, 11.291790762000062],
              [49.178419416000054, 11.291640112000039],
              [49.177862874000084, 11.291576731000077],
              [49.177573900000084, 11.291439404000073],
              [49.177428152000061, 11.29146953500009],
              [49.177225898000074, 11.291683686000056],
              [49.177028060000055, 11.291703494000046],
              [49.177261589000068, 11.292076297000051],
              [49.177737482000055, 11.292135784000038],
              [49.178237168000067, 11.292230962000076],
              [49.179141364000088, 11.292183373000057],
              [49.17997723600007, 11.292171278000069],
              [49.180747833000055, 11.292129023000086],
              [49.181223392000049, 11.292076297000051],
              [49.181782565000049, 11.29170748100006],
              [49.182175176000044, 11.291540919000056],
              [49.182496404000062, 11.291505227000073],
              [49.18282952800007, 11.291457638000054],
              [49.183269728000084, 11.29143384300005],
              [49.183935978000079, 11.29146953500009],
              [49.184411870000076, 11.291457638000054],
              [49.18486585900007, 11.291307706000055],
              [49.186136979000082, 11.29143384300005],
              [49.188076240000044, 11.291576611000039],
              [49.189087511000082, 11.291731276000064],
              [49.190574674000061, 11.292100092000055],
              [49.191359896000051, 11.292314244000067],
              [49.192454448000035, 11.292480806000071],
              [49.193608487000063, 11.292849622000062],
              [49.194393709000053, 11.293158952000056],
              [49.194976677000056, 11.293325515000049],
              [49.195666720000077, 11.293527769000093],
              [49.196606607000035, 11.293563461000076],
              [49.197141986000076, 11.293527769000093],
              [49.19772495400008, 11.293587255000091],
              [49.198355511000045, 11.293670536000093],
              [49.199533344000088, 11.293884688000048],
              [49.200770664000061, 11.294348683000067],
              [49.202126957000075, 11.294467656000052],
              [49.203471352000065, 11.294693705000043],
              [49.205077489000075, 11.294681807000075],
              [49.205969786000082, 11.29480078000006],
              [49.20681449500006, 11.294765088000077],
              [49.207492642000034, 11.294753191000041],
              [49.208301658000039, 11.294872164000083],
              [49.208718064000038, 11.294943548000049],
              [49.208920318000082, 11.29516959700004],
              [49.209574670000052, 11.29531236400004],
              [49.211454444000083, 11.296109484000056],
              [49.211632904000055, 11.296478300000047],
              [49.211989823000067, 11.297180241000092],
              [49.21244192000006, 11.297275420000062],
              [49.213060580000047, 11.297894080000049],
              [49.213322321000078, 11.298072539000088],
              [49.213810110000054, 11.298227204000057],
              [49.214274105000072, 11.298358075000067],
              [49.214738100000034, 11.298358075000067],
              [49.215166403000069, 11.29826289600004],
              [49.215559014000064, 11.29812012800005],
              [49.215832652000074, 11.298096334000093],
              [49.216023009000082, 11.298048745000074],
              [49.21632044200004, 11.297917874000063],
              [49.216808231000073, 11.297977361000051],
              [49.217212740000036, 11.298167718000059],
              [49.217438789000084, 11.298322383000084],
              [49.217676735000055, 11.298596021000037],
              [49.218676108000068, 11.298679302000039],
              [49.219175795000069, 11.298596021000037],
              [49.219806352000035, 11.298429458000044],
              [49.219809128000065, 11.29800449600009],
              [49.220349092000049, 11.297912611000072],
              [49.22088472300004, 11.297842124000056],
              [49.221164335000083, 11.297863352000093],
              [49.221537494000074, 11.297863352000093],
              [49.222154643000067, 11.297934179000038],
              [49.222487153000088, 11.297973248000062],
              [49.22360489600004, 11.298266130000059],
              [49.225210306000065, 11.298477396000067],
              [49.226815715000043, 11.298456270000088],
              [49.228442531000042, 11.298667536000039],
              [49.229776294000033, 11.298929145000045],
              [49.231909837000046, 11.299372718000086],
              [49.231985964000046, 11.299393210000062],
              [49.232445238000082, 11.299492367000084],
              [49.232689227000037, 11.299534863000076],
              [49.233148500000084, 11.299577359000068],
              [49.233435546000067, 11.29959152400005],
              [49.233708240000055, 11.299563194000086],
              [49.233952229000067, 11.299549028000058],
              [49.234095752000087, 11.299577359000068],
              [49.234569377000071, 11.299818169000048],
              [49.234956889000046, 11.29986066500004],
              [49.23517217400007, 11.299931491000052],
              [49.23548792400004, 11.300073144000066],
              [49.23591530300007, 11.300202157000058],
              [49.236212736000084, 11.300392514000066],
              [49.236474477000058, 11.300523384000087],
              [49.236712423000085, 11.300689946000091],
              [49.237045547000037, 11.300713741000038],
              [49.237521439000034, 11.30083271400008],
              [49.237997332000077, 11.300868406000063],
              [49.238270970000087, 11.30079702200004],
              [49.238520813000036, 11.300880303000042],
              [49.238770656000042, 11.300939790000086],
              [49.239018588000079, 11.300979720000043],
              [49.239291282000067, 11.300979720000043],
              [49.239506566000045, 11.301050547000045],
              [49.239698646000079, 11.301058763000071],
              [49.239936592000049, 11.30107066000005],
              [49.24057904700004, 11.301308606000077],
              [49.241515888000038, 11.301433007000071],
              [49.241716820000079, 11.301418842000089],
              [49.241975161000084, 11.301418842000089],
              [49.242176093000069, 11.30140467700005],
              [49.242707128000063, 11.301390512000069],
              [49.242908060000047, 11.30140467700005],
              [49.243381686000077, 11.301489668000045],
              [49.243955777000053, 11.30165965100008],
              [49.244285880000064, 11.301815468000086],
              [49.244788211000071, 11.301985450000075],
              [49.245089609000047, 11.302141267000081],
              [49.245548882000037, 11.302367910000044],
              [49.245606291000058, 11.302367910000044],
              [49.246237792000045, 11.30249539600004],
              [49.246654009000054, 11.302509561000079],
              [49.247336716000063, 11.302700591000075],
              [49.247895889000063, 11.302760077000073],
              [49.248490755000034, 11.302890948000083],
              [49.249311669000065, 11.303057510000087],
              [49.249632896000037, 11.303128894000054],
              [49.250132583000038, 11.303140791000089],
              [49.250370529000065, 11.303200278000077],
              [49.250514421000048, 11.303187197000057],
              [49.25063227000004, 11.303176483000072],
              [49.250830526000072, 11.303246148000085],
              [49.251074515000084, 11.303401964000045],
              [49.251318504000039, 11.303444460000037],
              [49.251505084000087, 11.303501120000078],
              [49.251806482000063, 11.303600276000054],
              [49.252007414000047, 11.303727762000051],
              [49.252380574000085, 11.303798587000074],
              [49.252610211000047, 11.303841083000066],
              [49.252897257000086, 11.303968568000073],
              [49.253313473000048, 11.304067724000049],
              [49.253787099000078, 11.304237705000048],
              [49.254275077000045, 11.304407685000058],
              [49.254763055000069, 11.304591831000039],
              [49.255207976000065, 11.304662656000062],
              [49.255695954000032, 11.304719317000092],
              [49.256284398000048, 11.304860967000081],
              [49.256686262000073, 11.304960122000068],
              [49.256872842000064, 11.305016782000052],
              [49.257073774000048, 11.305186763000052],
              [49.257449425000061, 11.30525851100009],
              [49.258044291000033, 11.305425074000084],
              [49.258523356000069, 11.305555053000091],
              [49.259103151000033, 11.305710609000073],
              [49.259436275000041, 11.305758198000092],
              [49.259745605000035, 11.305889069000045],
              [49.260090627000068, 11.306031836000045],
              [49.260446563000073, 11.306079158000045],
              [49.260661848000041, 11.306249137000066],
              [49.260862780000082, 11.306390787000055],
              [49.261185179000051, 11.306555318000051],
              [49.261423125000078, 11.306852750000076],
              [49.261553996000032, 11.307209669000088],
              [49.261696763000032, 11.307495205000066],
              [49.261827634000042, 11.307709356000089],
              [49.26192281200008, 11.307852124000078],
              [49.262427180000088, 11.308048082000084],
              [49.262742930000059, 11.308062247000066],
              [49.262943862000043, 11.308062247000066],
              [49.263187851000055, 11.308076412000048],
              [49.263474897000037, 11.308090577000087],
              [49.263891114000046, 11.308090577000087],
              [49.264192512000079, 11.308118907000051],
              [49.264536967000083, 11.308218061000048],
              [49.264953183000046, 11.308303050000063],
              [49.265326343000083, 11.308359709000058],
              [49.265728207000052, 11.308444699000063],
              [49.266115719000084, 11.308600512000055],
              [49.266589345000057, 11.308812985000088],
              [49.266976857000088, 11.308954633000042],
              [49.267579653000041, 11.309237929000062],
              [49.268555609000032, 11.309592050000049],
              [49.269989185000043, 11.310410045000083],
              [49.271416861000034, 11.311016807000044],
              [49.272142597000084, 11.311468905000083],
              [49.272531195000056, 11.311674268000047],
              [49.272818241000039, 11.311900903000037],
              [49.273177048000036, 11.312099208000063],
              [49.273435389000042, 11.312269184000058],
              [49.273779844000046, 11.312410831000079],
              [49.274167356000078, 11.312665794000054],
              [49.274468754000054, 11.312849935000088],
              [49.274856266000086, 11.312991581000063],
              [49.275258131000044, 11.313232380000045],
              [49.276162325000087, 11.313883951000037],
              [49.276520805000075, 11.314038723000067],
              [49.27691341600007, 11.31434805300006],
              [49.277080872000056, 11.314648838000039],
              [49.277353565000055, 11.314832977000037],
              [49.277525793000052, 11.315002951000054],
              [49.277827191000085, 11.31528624200007],
              [49.277985066000042, 11.315456216000086],
              [49.278099885000074, 11.31556953200004],
              [49.278099885000074, 11.315640354000038],
              [49.278357367000069, 11.315925135000043],
              [49.278932318000045, 11.316561045000071],
              [49.279391591000035, 11.316985978000048],
              [49.279649932000041, 11.31721260900008],
              [49.280152263000048, 11.317595048000044],
              [49.280697650000036, 11.317963322000082],
              [49.281128219000038, 11.318232445000092],
              [49.281530083000064, 11.31847324000006],
              [49.282262050000043, 11.318898170000068],
              [49.282563448000076, 11.31909647000009],
              [49.282936608000057, 11.319351428000061],
              [49.283410233000041, 11.319648879000056],
              [49.284435109000071, 11.320171772000037],
              [49.284563383000034, 11.320237218000045],
              [49.284920453000041, 11.320567339000092],
              [49.285193941000045, 11.320820186000049],
              [49.285360503000049, 11.321165208000082],
              [49.285693627000057, 11.321498333000079],
              [49.285812601000032, 11.321855252000091],
              [49.286079760000064, 11.322191358000055],
              [49.286409863000074, 11.322432149000065],
              [49.286639500000035, 11.322545462000051],
              [49.286869136000064, 11.322587955000074],
              [49.287213591000068, 11.322715432000052],
              [49.287572399000055, 11.32285707300008],
              [49.287868784000068, 11.322970299000076],
              [49.288017320000051, 11.323027043000081],
              [49.288318718000085, 11.323182848000044],
              [49.288591412000073, 11.323352818000046],
              [49.288864105000073, 11.323508623000066],
              [49.289165503000049, 11.323692756000071],
              [49.289596072000052, 11.324004366000054],
              [49.290485914000044, 11.324599257000045],
              [49.290830369000048, 11.324769225000068],
              [49.291303995000078, 11.325109162000047],
              [49.291648450000082, 11.325392442000066],
              [49.291964201000042, 11.325675722000085],
              [49.292279951000069, 11.325930674000062],
              [49.292624406000073, 11.326228117000085],
              [49.29283969100004, 11.326596380000069],
              [49.292925804000049, 11.326851331000057],
              [49.293081854000036, 11.327030580000041],
              [49.293260313000076, 11.32749457500006],
              [49.293641027000035, 11.328065645000038],
              [49.294592812000076, 11.32896984000007],
              [49.294878347000065, 11.329314862000047],
              [49.295163882000054, 11.329600398000082],
              [49.295449418000032, 11.32975506300005],
              [49.295687364000059, 11.330004906000056],
              [49.295853672000078, 11.330236491000051],
              [49.296183775000088, 11.330406456000048],
              [49.296427764000043, 11.33049143900007],
              [49.296772219000047, 11.330661404000068],
              [49.296930094000061, 11.330831369000066],
              [49.297131026000045, 11.331001334000064],
              [49.297288902000048, 11.331142971000077],
              [49.297446777000062, 11.33128460800009],
              [49.297461129000055, 11.331681192000076],
              [49.297461129000055, 11.332049447000088],
              [49.297602830000073, 11.332265394000046],
              [49.297924057000046, 11.332824567000046],
              [49.298376155000085, 11.333252870000081],
              [49.298828252000078, 11.333776352000086],
              [49.299023477000048, 11.334015195000063],
              [49.299326927000038, 11.334386444000074],
              [49.299744345000079, 11.334656752000058],
              [49.300136956000074, 11.334942288000093],
              [49.300339210000061, 11.335132644000055],
              [49.300632986000039, 11.335377891000064],
              [49.300819566000087, 11.335434545000055],
              [49.300991794000083, 11.335491199000046],
              [49.301409967000041, 11.33552525500005],
              [49.301921552000067, 11.335668023000039],
              [49.301886924000087, 11.336246962000075],
              [49.302087856000071, 11.336445251000043],
              [49.302195190000077, 11.336964829000067],
              [49.302456930000062, 11.336929137000084],
              [49.303302513000062, 11.33659595000006],
              [49.30358018000004, 11.336390931000039],
              [49.304286852000075, 11.335972506000076],
              [49.304955364000079, 11.335632331000056],
              [49.305359873000043, 11.33552525500005],
              [49.305669203000036, 11.335489564000056],
              [49.305907149000063, 11.335465769000052],
              [49.306168889000048, 11.335430077000069],
              [49.306490103000044, 11.335446971000067],
              [49.307572771000082, 11.335120747000076],
              [49.307941588000062, 11.335037466000074],
              [49.308307419000073, 11.334943037000073],
              [49.308953242000086, 11.334661427000071],
              [49.309313701000065, 11.334498389000089],
              [49.309584046000055, 11.334483567000063],
              [49.309839371000066, 11.334468746000084],
              [49.310079677000033, 11.334424281000054],
              [49.310582790000069, 11.334287936000067],
              [49.310875691000035, 11.334142670000062],
              [49.311191093000048, 11.334113026000068],
              [49.311476457000083, 11.334009275000085],
              [49.31198710700005, 11.333890702000076],
              [49.312242433000051, 11.333861058000082],
              [49.312632930000063, 11.333742485000073],
              [49.312993389000042, 11.333698020000043],
              [49.313173619000054, 11.333653555000069],
              [49.313489021000066, 11.333623912000064],
              [49.313684270000067, 11.33359426800007],
              [49.314014691000068, 11.333564625000065],
              [49.314223643000048, 11.33355173700005],
              [49.318201521000049, 11.33284141200005],
              [49.31886817600008, 11.332660571000076],
              [49.319731817000047, 11.332717491000039],
              [49.324225717000047, 11.331989176000093],
              [49.326900443000056, 11.331653230000086],
              [49.327865494000037, 11.331565048000073],
              [49.329526538000039, 11.331413268000063],
              [49.331974144000071, 11.331551556000079],
              [49.332485728000051, 11.331765707000045],
              [49.332937826000034, 11.331813296000064],
              [49.333378026000048, 11.331979859000057],
              [49.333639767000079, 11.332146421000061],
              [49.334187043000043, 11.33219401000008],
              [49.33469862700008, 11.332229702000063],
              [49.336237668000081, 11.332133153000086],
              [49.339350473000081, 11.331908475000091],
              [49.339790673000039, 11.332086934000074],
              [49.339981030000047, 11.332122626000057],
              [49.34018328500008, 11.332241599000042],
              [49.340433351000058, 11.332275137000067],
              [49.340628600000059, 11.332408532000045],
              [49.340808830000071, 11.332571571000074],
              [49.341019097000071, 11.332719788000077],
              [49.34109419300006, 11.332704967000041],
              [49.341319480000038, 11.332630858000073],
              [49.341724997000085, 11.332467819000044],
              [49.341860169000086, 11.332467819000044],
              [49.342490973000054, 11.332438176000039],
              [49.342896489000054, 11.332393711000066],
              [49.343196872000078, 11.332364067000071],
              [49.343737561000069, 11.332230671000048],
              [49.344811336000078, 11.332027448000076],
              [49.346095565000041, 11.331934236000052],
              [49.346741388000055, 11.331919415000073],
              [49.347342154000046, 11.331919415000073],
              [49.347822766000036, 11.331963880000046],
              [49.348808831000042, 11.331872783000051],
              [49.349617847000047, 11.331872783000051],
              [49.350474453000061, 11.331872783000051],
              [49.351069319000032, 11.331979859000057],
              [49.351757780000071, 11.332112097000049],
              [49.352330433000077, 11.332086934000074],
              [49.353817596000056, 11.332170215000076],
              [49.354536320000079, 11.332230671000048],
              [49.354956856000058, 11.332230671000048],
              [49.355602679000071, 11.332230671000048],
              [49.356023214000061, 11.332260315000042],
              [49.356699076000041, 11.332260315000042],
              [49.357164669000042, 11.332260315000042],
              [49.357600224000066, 11.332245493000073],
              [49.358050798000079, 11.332245493000073],
              [49.358696621000036, 11.332230671000048],
              [49.359177233000082, 11.332171384000048],
              [49.359853094000073, 11.332023167000045],
              [49.361084663000042, 11.331963880000046],
              [49.361430103000032, 11.331815662000054],
              [49.361824482000088, 11.331777604000081],
              [49.362839032000068, 11.331653230000086],
              [49.366202691000069, 11.331611042000077],
              [49.368715700000052, 11.331367292000039],
              [49.37219893200006, 11.33137309600005],
              [49.375672945000076, 11.331396891000054],
              [49.379146958000035, 11.331515864000039],
              [49.382989788000032, 11.331432583000037],
              [49.384479996000039, 11.331245295000087],
              [49.386142574000075, 11.33086151200007],
              [49.386662252000065, 11.330785548000051],
              [49.388359415000082, 11.330918944000075],
              [49.390402017000042, 11.331052340000042],
              [49.390782523000041, 11.331194636000077],
              [49.391317902000083, 11.331242226000086],
              [49.391817588000038, 11.331242226000086],
              [49.392222097000058, 11.331242226000086],
              [49.392650400000036, 11.331277918000069],
              [49.393180557000051, 11.331422885000052],
              [49.394042384000045, 11.331670529000064],
              [49.394232741000053, 11.331706221000047],
              [49.394382088000043, 11.331778608000093],
              [49.394517260000043, 11.331734143000062],
              [49.394592356000032, 11.331704499000068],
              [49.394847681000044, 11.331719321000037],
              [49.395089347000066, 11.331741912000041],
              [49.395470061000083, 11.331777604000081],
              [49.396064926000065, 11.331932269000049],
              [49.396493229000043, 11.332027448000076],
              [49.396790662000058, 11.332086934000074],
              [49.397302246000038, 11.33215831800004],
              [49.397968495000043, 11.332217805000084],
              [49.398813204000078, 11.332372470000053],
              [49.399717399000053, 11.332503340000073],
              [49.400600011000051, 11.332697556000085],
              [49.401606293000043, 11.332742021000058],
              [49.402162001000079, 11.332771664000063],
              [49.403155720000086, 11.332907848000048],
              [49.404214580000087, 11.33302682100009],
              [49.406165738000084, 11.333490816000051],
              [49.407336744000077, 11.333596912000075],
              [49.409671050000043, 11.334100828000089],
              [49.412734827000065, 11.334436771000071],
              [49.415604078000058, 11.334916689000067],
              [49.418086838000079, 11.33541818000009],
              [49.421180137000079, 11.336060634000091],
              [49.423071809000078, 11.336572218000072],
              [49.425213324000083, 11.337297954000064],
              [49.426997919000087, 11.337833333000049],
              [49.429234613000062, 11.338618555000039],
              [49.431457907000038, 11.33913993300007],
              [49.431804431000046, 11.339272907000066],
              [49.432149452000033, 11.339356188000068],
              [49.432363604000045, 11.339415674000065],
              [49.432780010000045, 11.339487058000088],
              [49.433398669000042, 11.339748799000063],
              [49.433696102000056, 11.339843977000044],
              [49.434100610000087, 11.340058129000056],
              [49.435052395000071, 11.340522124000074],
              [49.435409314000083, 11.34073627500004],
              [49.436087460000067, 11.341105092000078],
              [49.43681319600006, 11.34156908600005],
              [49.437384267000084, 11.341830827000081],
              [49.437632007000047, 11.341979471000059],
              [49.437979132000066, 11.342187746000093],
              [49.438324154000043, 11.342413795000084],
              [49.438788149000061, 11.342568460000052],
              [49.439171340000087, 11.342793020000045],
              [49.439583215000084, 11.342992258000038],
              [49.439930290000063, 11.343282298000076],
              [49.440406182000061, 11.343460758000049],
              [49.441108123000049, 11.34396044500005],
              [49.441738680000071, 11.344317364000062],
              [49.442190778000054, 11.344662386000039],
              [49.44271425900007, 11.344959818000063],
              [49.443035486000042, 11.345209662000059],
              [49.443344816000035, 11.345423813000082],
              [49.443701736000037, 11.345637965000037],
              [49.444011065000041, 11.345875911000064],
              [49.444249012000057, 11.346078165000051],
              [49.444903363000037, 11.346661133000055],
              [49.445041280000055, 11.346810213000083],
              [49.445194723000043, 11.346977571000082],
              [49.445355461000077, 11.347148922000088],
              [49.445486331000041, 11.347279793000041],
              [49.445581510000068, 11.347482047000085],
              [49.445807559000059, 11.347755685000038],
              [49.445950326000059, 11.348041220000084],
              [49.446116888000063, 11.348374345000082],
              [49.446259656000052, 11.348552804000064],
              [49.446428182000034, 11.348711427000069],
              [49.446484714000064, 11.348894723000058],
              [49.446541246000038, 11.349093957000036],
              [49.44660585400004, 11.349221467000064],
              [49.446702766000044, 11.349325068000041],
              [49.446726994000073, 11.349372885000037],
              [49.446759297000085, 11.349412731000086],
              [49.446823905000088, 11.349711582000054],
              [49.446823905000088, 11.349870969000051],
              [49.446823905000088, 11.350030356000048],
              [49.446840057000088, 11.350181773000088],
              [49.446888513000033, 11.350285375000055],
              [49.446920817000034, 11.350341160000085],
              [49.446920817000034, 11.350436792000039],
              [49.446920817000034, 11.350604148000059],
              [49.446920817000034, 11.350739626000063],
              [49.446928893000063, 11.350843227000041],
              [49.446928893000063, 11.350954798000089],
              [49.446937803000083, 11.351086930000065],
              [49.446806932000072, 11.351372466000043],
              [49.446557089000066, 11.351979228000062],
              [49.446592781000049, 11.352276661000076],
              [49.446485705000043, 11.352514607000046],
              [49.446355498000059, 11.352982981000082],
              [49.446428182000034, 11.35315830400009],
              [49.446541246000038, 11.353341597000053],
              [49.446678538000072, 11.353493012000058],
              [49.446767373000057, 11.353612551000083],
              [49.446896589000062, 11.353867567000066],
              [49.446969273000036, 11.354011013000047],
              [49.447196188000078, 11.354452058000049],
              [49.447235780000085, 11.354529012000057],
              [49.447356920000061, 11.354776057000038],
              [49.447502288000067, 11.355039041000055],
              [49.44759920000007, 11.355238271000076],
              [49.447696112000074, 11.35550922300007],
              [49.447849555000062, 11.355780175000064],
              [49.448051455000041, 11.356051127000057],
              [49.448334114000033, 11.356425678000051],
              [49.448495634000039, 11.356696629000055],
              [49.448616774000072, 11.356887889000063],
              [49.448729837000087, 11.357095086000072],
              [49.448802521000061, 11.357246500000088],
              [49.448907509000037, 11.357374006000043],
              [49.449020573000041, 11.357509481000079],
              [49.449149789000046, 11.357605111000055],
              [49.449257777000071, 11.357785113000091],
              [49.449460031000058, 11.35792788100008],
              [49.449697977000085, 11.358142032000046],
              [49.44994782100008, 11.358213416000069],
              [49.450233356000069, 11.358451362000039],
              [49.450518891000058, 11.358415670000056],
              [49.450852016000056, 11.358475157000044],
              [49.45180380000005, 11.358701206000092],
              [49.452608822000059, 11.358812267000076],
              [49.453315251000049, 11.358812267000076],
              [49.455306097000062, 11.358791143000076],
              [49.456622209000045, 11.359022433000064],
              [49.457810709000057, 11.359255862000055],
              [49.458371113000055, 11.359593504000088],
              [49.459215821000043, 11.35964109300005],
              [49.460615019000045, 11.35959383900007],
              [49.46170677300006, 11.359403727000085],
              [49.462798527000075, 11.359403727000085],
              [49.463214185000083, 11.359398185000089],
              [49.464019455000084, 11.359621035000089],
              [49.464498225000057, 11.359831450000058],
              [49.465212063000081, 11.360069396000085],
              [49.46603648200005, 11.360473591000073],
              [49.46606812300007, 11.360489104000067],
              [49.466661851000083, 11.36070344500007],
              [49.467342466000048, 11.361017811000067],
              [49.468052044000046, 11.361489361000054],
              [49.468327186000067, 11.361646544000052],
              [49.468732658000079, 11.361818016000086],
              [49.469022282000083, 11.362018067000065],
              [49.469364223000071, 11.362294192000093],
              [49.469905633000053, 11.362746822000076],
              [49.470774503000086, 11.363346972000045],
              [49.471151014000043, 11.363561311000069],
              [49.471686817000034, 11.363861385000064],
              [49.472121252000079, 11.364047145000086],
              [49.472410875000037, 11.364361508000059],
              [49.472612187000038, 11.364542782000058],
              [49.472897722000084, 11.365006777000076],
              [49.473048047000077, 11.365333173000067],
              [49.473236302000032, 11.36540461900006],
              [49.473503196000081, 11.365487784000038],
              [49.473510930000032, 11.365504220000048],
              [49.473706739000079, 11.365601643000048],
              [49.473563971000033, 11.366279789000089],
              [49.473730533000037, 11.367065011000079],
              [49.473849507000068, 11.367410033000056],
              [49.474027966000051, 11.368028693000042],
              [49.474111247000053, 11.368445098000052],
              [49.47420642600008, 11.368837710000037],
              [49.474456269000086, 11.368992375000062],
              [49.474646626000037, 11.368754428000045],
              [49.474741804000075, 11.368599763000077],
              [49.474944059000052, 11.368718736000062],
              [49.474979750000045, 11.368849607000072],
              [49.474944059000052, 11.369016169000076],
              [49.474825086000067, 11.369289807000087],
              [49.474753702000044, 11.369622932000084],
              [49.474765599000079, 11.369765699000084],
              [49.475146313000039, 11.369706213000086],
              [49.475193902000058, 11.369932262000077],
              [49.475122518000035, 11.370170208000047],
              [49.474924828000042, 11.370526364000057],
              [49.474924828000042, 11.37058589500009],
              [49.474985160000074, 11.370621615000061],
              [49.475057560000039, 11.370752584000059],
              [49.474997227000074, 11.37085974200005],
              [49.474924828000042, 11.371014524000088],
              [49.474888628000087, 11.371097869000039],
              [49.474888628000087, 11.37124074500008],
              [49.474924828000042, 11.371371714000077],
              [49.474924828000042, 11.371574122000084],
              [49.474912761000041, 11.371847967000065],
              [49.474864495000077, 11.371967030000064],
              [49.474828295000066, 11.372193250000066],
              [49.474792095000055, 11.372395657000084],
              [49.474659363000058, 11.37257425100006],
              [49.474671430000058, 11.372764751000091],
              [49.474707629000079, 11.372824282000067],
              [49.474551448000057, 11.373073150000039],
              [49.474622831000033, 11.373322994000091],
              [49.474628124000049, 11.373351576000061],
              [49.474682318000077, 11.373644221000063],
              [49.474741804000075, 11.373965448000092],
              [49.474948961000052, 11.374122062000083],
              [49.475009294000074, 11.374229218000039],
              [49.475154092000082, 11.374431624000067],
              [49.475347158000034, 11.37465784200009],
              [49.475431848000085, 11.374881540000047],
              [49.475657897000076, 11.375107589000038],
              [49.475943432000065, 11.375333638000086],
              [49.476217070000075, 11.37550020000009],
              [49.476538297000047, 11.375619173000075],
              [49.476800038000079, 11.37573814600006],
              [49.477347314000042, 11.375880914000049],
              [49.477680439000039, 11.375845222000066],
              [49.478096844000049, 11.375916606000089],
              [49.478632223000034, 11.37601178400007],
              [49.479310370000064, 11.376023682000039],
              [49.479691083000034, 11.376047476000053],
              [49.480083694000086, 11.376106963000041],
              [49.480928403000064, 11.376249731000087],
              [49.481523268000046, 11.376237833000062],
              [49.482320388000062, 11.376178347000064],
              [49.483058021000033, 11.376166449000038],
              [49.483795654000062, 11.376142655000081],
              [49.484200162000036, 11.376059374000079],
              [49.484592773000088, 11.376047476000053],
              [49.484914, 11.375976093000077],
              [49.485128152000073, 11.375904709000054],
              [49.485354200000074, 11.375845222000066],
              [49.485699222000051, 11.375773838000043],
              [49.486068039000088, 11.375702455000066],
              [49.486484444000041, 11.375654865000058],
              [49.48693654200008, 11.375631071000043],
              [49.48749571500008, 11.375702455000066],
              [49.488114375000066, 11.375761941000064],
              [49.488649754000051, 11.375809530000083],
              [49.492992270000059, 11.376344909000068],
              [49.493313498000077, 11.376404396000055],
              [49.493670417000033, 11.376535266000076],
              [49.494205795000084, 11.376689931000044],
              [49.494634098000063, 11.376820801000065],
              [49.495098093000081, 11.376927877000071],
              [49.495550191000063, 11.376999261000037],
              [49.495919007000055, 11.377106337000043],
              [49.496573359000081, 11.377272899000047],
              [49.497596527000042, 11.377641715000038],
              [49.49884574400005, 11.37817709400008],
              [49.499488199000041, 11.37845073200009],
              [49.499952194000059, 11.378641089000041],
              [49.500261524000052, 11.378783857000087],
              [49.500642237000079, 11.378974213000049],
              [49.501189513000043, 11.379164570000057],
              [49.501879557000052, 11.379450106000093],
              [49.502438730000051, 11.379699949000042],
              [49.503081185000042, 11.379985484000088],
              [49.50409245600008, 11.380532760000051],
              [49.505068035000079, 11.381020550000073],
              [49.505734284000084, 11.381341777000046],
              [49.506507609000039, 11.38178197700006],
              [49.507623961000036, 11.38254376000009],
              [49.50939307200008, 11.383782882000048],
              [49.511599655000055, 11.385458245000052],
              [49.513253381000084, 11.387052484000037],
              [49.514954695000085, 11.388622928000075],
              [49.516108734000056, 11.389753172000042],
              [49.51698375400008, 11.390518039000085],
              [49.518283286000042, 11.39187025800004],
              [49.51968110200005, 11.393335604000072],
              [49.520259886000076, 11.394035951000092],
              [49.520816828000079, 11.394833266000091],
              [49.521341009000082, 11.39542586400006],
              [49.522575019000044, 11.396858868000038],
              [49.523142882000059, 11.397613078000063],
              [49.52392915300004, 11.398475029000053],
              [49.524191244000065, 11.398852132000059],
              [49.525150686000075, 11.400460746000078],
              [49.526768468000057, 11.402418424000075],
              [49.52822019000007, 11.404636700000083],
              [49.529409921000081, 11.406706831000065],
              [49.530110122000053, 11.407439169000043],
              [49.531617143000062, 11.40998182900006],
              [49.532895831000076, 11.412833944000056],
              [49.533990383000059, 11.414773204000085],
              [49.535358574000043, 11.417176460000064],
              [49.53692901800008, 11.419996121000054],
              [49.537964083000077, 11.42209004700004],
              [49.53845874700005, 11.423425170000087],
              [49.539528950000033, 11.425267421000058],
              [49.540248366000071, 11.426575331000038],
              [49.541045485000041, 11.427967316000093],
              [49.541426199000057, 11.429180841000061],
              [49.542308253000044, 11.430553904000078],
              [49.542701389000058, 11.431582737000042],
              [49.543542263000063, 11.433101741000087],
              [49.544864520000033, 11.435914715000081],
              [49.545935278000059, 11.437937257000044],
              [49.546656762000055, 11.438961973000062],
              [49.546756192000032, 11.439103193000051],
              [49.546958446000076, 11.439448215000084],
              [49.547033323000051, 11.439596205000043],
              [49.547069565000072, 11.439667836000069],
              [49.547102326000072, 11.439861746000076],
              [49.547266133000051, 11.440098747000093],
              [49.54739717800004, 11.440281884000058],
              [49.547803155000054, 11.440530870000089],
              [49.548148176000041, 11.440971070000046],
              [49.548358176000079, 11.441466886000057],
              [49.548980988000039, 11.442303568000057],
              [49.549397393000049, 11.442708076000088],
              [49.549599648000083, 11.442922228000043],
              [49.549833293000063, 11.44301622100005],
              [49.549863593000055, 11.443195556000092],
              [49.549984789000064, 11.443374890000086],
              [49.550105985000073, 11.443554225000071],
              [49.550257481000074, 11.44371861400009],
              [49.550454425000055, 11.443897948000085],
              [49.550590771000088, 11.444032449000076],
              [49.550696818000063, 11.444107171000041],
              [49.550818014000072, 11.444196838000039],
              [49.550954360000048, 11.444301450000069],
              [49.551039222000043, 11.444730618000051],
              [49.551146297000059, 11.444968564000078],
              [49.551651240000069, 11.445302728000058],
              [49.551817885000048, 11.445541839000043],
              [49.551943417000075, 11.446134500000085],
              [49.552252747000068, 11.446312960000057],
              [49.552514765000069, 11.446378725000045],
              [49.55266626000008, 11.446453447000067],
              [49.552817756000081, 11.446528169000089],
              [49.55299038000004, 11.446693674000073],
              [49.553287812000065, 11.447014901000045],
              [49.553711579000037, 11.44724549700004],
              [49.553878224000073, 11.447394940000038],
              [49.554135767000048, 11.447619105000058],
              [49.554347861000053, 11.44781338100006],
              [49.554544805000035, 11.448037545000091],
              [49.554787198000042, 11.448291598000083],
              [49.55498414200008, 11.448530706000042],
              [49.555059890000052, 11.448575539000046],
              [49.555179484000064, 11.44878759900007],
              [49.555369841000072, 11.448966059000043],
              [49.555691068000044, 11.449168313000087],
              [49.555929014000071, 11.449215902000049],
              [49.556262138000079, 11.449263492000057],
              [49.55658229800008, 11.449224836000042],
              [49.55714253900004, 11.449346773000059],
              [49.557499458000052, 11.449382465000042],
              [49.557695912000042, 11.449472193000076],
              [49.557862557000078, 11.449576802000081],
              [49.558059501000059, 11.449666467000043],
              [49.558141913000043, 11.449834562000092],
              [49.558356064000066, 11.449953535000077],
              [49.558820059000084, 11.45012009800007],
              [49.559224567000058, 11.45012009800007],
              [49.559748049000063, 11.45012009800007],
              [49.560200146000057, 11.450048714000047],
              [49.560747422000077, 11.450072508000062],
              [49.561199520000059, 11.450048714000047],
              [49.56184197400006, 11.450108200000045],
              [49.562377353000045, 11.450215276000051],
              [49.562686683000038, 11.450334249000036],
              [49.562936527000033, 11.450381838000055],
              [49.563222062000079, 11.450369941000076],
              [49.563543289000052, 11.45039373600008],
              [49.564054873000032, 11.450524606000045],
              [49.564345825000032, 11.450621432000048],
              [49.564361716000064, 11.450652782000077],
              [49.564482912000074, 11.450772335000067],
              [49.564588959000048, 11.450847056000043],
              [49.564785903000086, 11.450951665000048],
              [49.564952548000065, 11.451041330000066],
              [49.565119194000033, 11.451160883000057],
              [49.565316138000071, 11.451310324000076],
              [49.565376736000076, 11.451549429000067],
              [49.565376736000076, 11.451743703000091],
              [49.56548278300005, 11.451982808000082],
              [49.565543381000055, 11.45202764000004],
              [49.565637215000038, 11.452214023000067],
              [49.565815674000078, 11.452190229000053],
              [49.566148799000075, 11.452368688000092],
              [49.566291567000064, 11.452380585000071],
              [49.566910226000061, 11.452214023000067],
              [49.56843308100008, 11.452547148000065],
              [49.569206406000035, 11.452594737000084],
              [49.569384866000064, 11.452737505000073],
              [49.569648910000069, 11.452730010000039],
              [49.569739808000065, 11.452834619000043],
              [49.569891303000077, 11.452939227000059],
              [49.570042799000078, 11.453028891000088],
              [49.570039218000034, 11.453260986000089],
              [49.570603332000076, 11.45332777100009],
              [49.570830576000048, 11.453536987000064],
              [49.570907721000083, 11.453713084000071],
              [49.571121872000049, 11.45390344100008],
              [49.571490689000086, 11.453974824000056],
              [49.571764327000039, 11.453879646000075],
              [49.572027390000073, 11.453731259000051],
              [49.572430576000045, 11.454093797000041],
              [49.573132517000033, 11.453974824000056],
              [49.573691690000032, 11.453927235000037],
              [49.574143788000072, 11.454034311000044],
              [49.574560194000071, 11.454058105000058],
              [49.574750550000033, 11.45417707900009],
              [49.575202648000072, 11.45428415400005],
              [49.575571465000053, 11.454319846000089],
              [49.576178227000071, 11.454307949000054],
              [49.576558941000087, 11.454248462000066],
              [49.577296574000059, 11.454212770000083],
              [49.577820055000075, 11.454319846000089],
              [49.578236461000074, 11.454260360000092],
              [49.578533893000042, 11.454296052000075],
              [49.578873826000063, 11.454330045000063],
              [49.579009786000086, 11.454343641000037],
              [49.579223937000052, 11.454391230000056],
              [49.579521370000066, 11.454450717000043],
              [49.579723624000053, 11.454498306000062],
              [49.57989815600007, 11.454513492000046],
              [49.579920308000055, 11.45456812000009],
              [49.579996056000084, 11.454687671000045],
              [49.580162701000063, 11.454807223000046],
              [49.58029904700004, 11.454926774000057],
              [49.580435393000073, 11.455031382000072],
              [49.58052629000008, 11.455121045000055],
              [49.580677786000081, 11.455270484000039],
              [49.580753533000063, 11.455375091000064],
              [49.580783833000055, 11.455419923000079],
              [49.580818176000037, 11.455628550000085],
              [49.58116319800007, 11.455676139000047],
              [49.58140114400004, 11.455688036000083],
              [49.581571609000036, 11.455748688000085],
              [49.581632208000087, 11.455763632000071],
              [49.581829152000068, 11.455853295000054],
              [49.58198064700008, 11.455883183000083],
              [49.582150674000047, 11.456247210000072],
              [49.582388620000074, 11.456366183000057],
              [49.58278123100007, 11.45616392800008],
              [49.583173842000065, 11.456009263000055],
              [49.583530762000066, 11.455925982000053],
              [49.584101832000044, 11.455735625000045],
              [49.584498194000048, 11.455618851000054],
              [49.584753016000036, 11.455539474000091],
              [49.584946541000079, 11.455509577000043],
              [49.585208282000053, 11.455497679000075],
              [49.585624687000063, 11.455438193000077],
              [49.586052990000042, 11.455319220000092],
              [49.586766829000055, 11.455188349000082],
              [49.587242721000052, 11.45506937600004],
              [49.588004148000039, 11.45520024700005],
              [49.588551424000059, 11.455259733000048],
              [49.588848857000073, 11.455497679000075],
              [49.589217673000064, 11.455664242000068],
              [49.58974115500007, 11.455450090000056],
              [49.590205150000088, 11.45547388500006],
              [49.590538274000039, 11.455497679000075],
              [49.59094278300006, 11.455307322000067],
              [49.59172800500005, 11.45523593900009],
              [49.592156308000085, 11.455128863000084],
              [49.592703584000049, 11.455009890000042],
              [49.593405525000037, 11.455081274000065],
              [49.59389331400007, 11.45510506800008],
              [49.594327534000058, 11.455061269000055],
              [49.594403282000087, 11.455180821000056],
              [49.594539628000064, 11.455315316000053],
              [49.594637578000061, 11.455424014000073],
              [49.594704085000046, 11.455428064000046],
              [49.594706273000043, 11.455464755000037],
              [49.594721422000077, 11.455614193000088],
              [49.594812320000074, 11.455763632000071],
              [49.59494866600005, 11.455898127000069],
              [49.594916482000087, 11.456330491000074],
              [49.595320991000051, 11.456389977000072],
              [49.595713602000046, 11.456021161000081],
              [49.595975343000077, 11.455925982000053],
              [49.596281826000052, 11.455748688000085],
              [49.596539369000084, 11.455688913000074],
              [49.596569668000086, 11.455673969000088],
              [49.596827210000072, 11.455644081000059],
              [49.596963556000048, 11.455584306000048],
              [49.597391122000033, 11.45561665200006],
              [49.598102896000057, 11.45549918200004],
              [49.598145222000085, 11.45547969800009],
              [49.598545161000061, 11.455426296000041],
              [49.599032950000037, 11.455366809000054],
              [49.599401766000085, 11.45537870600009],
              [49.599830069000063, 11.455414398000073],
              [49.600401140000088, 11.455426296000041],
              [49.601055492000057, 11.455414398000073],
              [49.601757433000046, 11.455426296000041],
              [49.602376093000032, 11.455521474000079],
              [49.602637833000074, 11.455509577000043],
              [49.602906695000058, 11.455531935000067],
              [49.603385920000051, 11.455871323000054],
              [49.603417266000065, 11.45595790200008],
              [49.603327877000083, 11.45630669600007],
              [49.603423056000054, 11.456473258000074],
              [49.603841454000076, 11.456794757000068],
              [49.603917202000048, 11.457003970000073],
              [49.603982229000053, 11.457270378000089],
              [49.604243970000084, 11.457436940000036],
              [49.604588991000071, 11.457710578000047],
              [49.604910219000033, 11.457829551000088],
              [49.605250363000039, 11.45785576600008],
              [49.605750298000032, 11.458169585000064],
              [49.605916943000068, 11.458244303000072],
              [49.606568374000062, 11.458348909000051],
              [49.607051734000038, 11.458543389000056],
              [49.607480037000073, 11.458579081000039],
              [49.608356021000077, 11.458573065000053],
              [49.608962003000045, 11.45854317800007],
              [49.609033791000058, 11.45849415400005],
              [49.609169992000034, 11.458524688000068],
              [49.609477088000062, 11.458498347000045],
              [49.609825528000044, 11.458468459000073],
              [49.610128519000057, 11.458483403000059],
              [49.610492108000074, 11.458513290000042],
              [49.610916296000084, 11.458513290000042],
              [49.611385932000076, 11.458498347000045],
              [49.611779820000038, 11.458498347000045],
              [49.612204008000049, 11.458468459000073],
              [49.612560186000053, 11.458472006000079],
              [49.613357305000079, 11.458210265000048],
              [49.613785608000057, 11.458008011000061],
              [49.614213911000036, 11.457793859000049],
              [49.614606522000088, 11.457686784000089],
              [49.614999133000083, 11.457555913000078],
              [49.615468695000061, 11.45734710000005],
              [49.616089827000053, 11.457227550000084],
              [49.616415584000038, 11.457063746000074],
              [49.616794323000079, 11.456944195000062],
              [49.617319108000061, 11.457008637000058],
              [49.618044843000064, 11.45685397200009],
              [49.618794374000061, 11.456699307000065],
              [49.619460623000066, 11.456616026000063],
              [49.620233272000064, 11.45649588100008],
              [49.621733077000044, 11.456406218000041],
              [49.622475406000035, 11.456391274000055],
              [49.623493809000081, 11.456520848000082],
              [49.624362312000073, 11.456616026000063],
              [49.625171329000068, 11.456794486000092],
              [49.625730503000057, 11.456877767000037],
              [49.626230189000069, 11.456996740000079],
              [49.626658492000047, 11.457139507000079],
              [49.627134384000044, 11.457234686000049],
              [49.627598379000062, 11.457484529000055],
              [49.627943401000039, 11.457579708000083],
              [49.628431191000061, 11.457793859000049],
              [49.628823069000077, 11.45785576600008],
              [49.628868272000034, 11.457916569000076],
              [49.628994598000077, 11.457950001000086],
              [49.629035163000083, 11.458050035000042],
              [49.629156359000035, 11.458184528000061],
              [49.629307855000036, 11.45828913400004],
              [49.629610846000048, 11.458363853000037],
              [49.629883538000058, 11.458483403000059],
              [49.630247127000075, 11.458588009000039],
              [49.630534968000063, 11.458602953000081],
              [49.630883408000045, 11.458632840000064],
              [49.630904458000032, 11.458617541000081],
              [49.632362397000065, 11.458977073000085],
              [49.632428663000042, 11.459066207000092],
              [49.63262560700008, 11.459275419000051],
              [49.632807402000083, 11.459439799000052],
              [49.633035448000044, 11.459733120000067],
              [49.633380470000077, 11.45994727100009],
              [49.633725491000064, 11.460066245000064],
              [49.634320357000036, 11.460232807000068],
              [49.634755558000052, 11.46018868200008],
              [49.635403011000051, 11.460399369000072],
              [49.63623582300005, 11.460256601000083],
              [49.637187607000044, 11.460054347000039],
              [49.637580218000039, 11.460030553000081],
              [49.638234570000066, 11.459863990000088],
              [49.638793743000065, 11.459685531000048],
              [49.639388609000036, 11.459542763000059],
              [49.639840706000086, 11.459447585000078],
              [49.64106612900008, 11.459292920000053],
              [49.642053605000058, 11.459185844000046],
              [49.642458113000032, 11.459138255000084],
              [49.642886416000067, 11.45911446000008],
              [49.643516974000079, 11.45907876800004],
              [49.644111839000061, 11.459043076000057],
              [49.645729872000061, 11.459090665000076],
              [49.646431813000049, 11.459150152000063],
              [49.647098062000055, 11.459138255000084],
              [49.647942771000032, 11.459138255000084],
              [49.64909681000006, 11.459173947000068],
              [49.65002479900005, 11.459304817000088],
              [49.651012276000074, 11.459328612000093],
              [49.651547654000069, 11.459399995000069],
              [49.651892676000045, 11.459459482000057],
              [49.652118725000037, 11.459566558000063],
              [49.652380466000068, 11.459590352000077],
              [49.652856358000065, 11.459745017000046],
              [49.653189483000062, 11.459816401000069],
              [49.653677272000039, 11.459935374000054],
              [49.654117472000053, 11.460066245000064],
              [49.654640954000058, 11.460149526000066],
              [49.65541427900007, 11.460375574000068],
              [49.656021041000088, 11.460530239000093],
              [49.656722982000076, 11.460756288000084],
              [49.657579588000033, 11.461018029000059],
              [49.65809117200007, 11.461220283000046],
              [49.658531372000084, 11.461351153000066],
              [49.659046429000057, 11.461531906000062],
              [49.659319121000067, 11.461651454000048],
              [49.659785184000043, 11.461719061000053],
              [49.66011347500006, 11.46193702700009],
              [49.660232073000088, 11.461952192000069],
              [49.660402660000045, 11.462042345000043],
              [49.662719224000057, 11.46326662000007],
              [49.665372323000042, 11.464932242000089],
              [49.665907702000084, 11.46515829100008],
              [49.666657232000034, 11.465646081000045],
              [49.668203881000068, 11.466514584000038],
              [49.669548277000047, 11.467252217000066],
              [49.670564789000082, 11.467880606000051],
              [49.670856980000053, 11.468061233000071],
              [49.671820662000073, 11.468834558000083],
              [49.672510706000082, 11.469500807000088],
              [49.673688539000068, 11.470297927000047],
              [49.674640323000062, 11.470833306000088],
              [49.675913335000075, 11.471749398000043],
              [49.676460611000039, 11.472201496000082],
              [49.677043579000042, 11.472677388000079],
              [49.677424293000058, 11.473058102000039],
              [49.677634932000046, 11.473277296000049],
              [49.677786428000047, 11.473635926000043],
              [49.677847026000052, 11.473860070000057],
              [49.678028821000055, 11.474099156000079],
              [49.678150017000064, 11.474218699000062],
              [49.67834038500007, 11.474461984000072],
              [49.678566103000037, 11.474615997000058],
              [49.678875764000054, 11.475437563000071],
              [49.679043841000066, 11.475563555000065],
              [49.679104439000071, 11.475817583000037],
              [49.679210486000045, 11.476146324000069],
              [49.679301383000052, 11.476519893000045],
              [49.679327862000036, 11.476793855000039],
              [49.679565808000063, 11.477043699000092],
              [49.679827548000048, 11.477174569000056],
              [49.680077392000044, 11.477317337000045],
              [49.680336621000038, 11.477359911000065],
              [49.680707949000066, 11.477579078000076],
              [49.681041074000063, 11.477686153000093],
              [49.681505068000035, 11.477852716000086],
              [49.681873885000073, 11.477971689000071],
              [49.682207009000081, 11.478043072000048],
              [49.682492545000059, 11.478173943000058],
              [49.682789977000084, 11.478340505000062],
              [49.682992232000061, 11.478471375000083],
              [49.683396740000035, 11.478649835000056],
              [49.683717967000064, 11.478899678000062],
              [49.684170065000046, 11.478935370000045],
              [49.684562676000041, 11.478863986000079],
              [49.684800622000068, 11.478792603000045],
              [49.685252720000051, 11.478661732000091],
              [49.68581189300005, 11.47854275900005],
              [49.686347272000035, 11.478435683000043],
              [49.686692293000078, 11.478411889000085],
              [49.687120596000057, 11.478411889000085],
              [49.687572694000039, 11.478388094000081],
              [49.687953408000055, 11.478471375000083],
              [49.688262738000049, 11.478459478000048],
              [49.688714835000042, 11.478554657000075],
              [49.688857603000088, 11.478697424000075],
              [49.689024165000035, 11.47881639700006],
              [49.689190728000085, 11.479018651000047],
              [49.689381084000047, 11.479173316000072],
              [49.689535749000072, 11.479351776000044],
              [49.689678517000061, 11.479554030000088],
              [49.689952155000071, 11.479649209000058],
              [49.690201998000077, 11.479613517000075],
              [49.690332869000088, 11.479351776000044],
              [49.69038045800005, 11.479149522000057],
              [49.690796864000049, 11.478911576000087],
              [49.691213269000059, 11.47881639700006],
              [49.691534497000077, 11.47867363000006],
              [49.691998492000039, 11.478495170000087],
              [49.692319719000068, 11.478447581000069],
              [49.692688535000059, 11.478507067000066],
              [49.692938379000054, 11.478530862000071],
              [49.693152530000077, 11.478602246000037],
              [49.693449963000035, 11.478649835000056],
              [49.693557038000051, 11.478745013000037],
              [49.693723601000045, 11.479006754000068],
              [49.693557114000043, 11.479329117000077],
              [49.693572263000078, 11.479493486000081],
              [49.693557038000051, 11.479696798000077],
              [49.693568936000077, 11.480077512000037],
              [49.693913958000053, 11.480160793000039],
              [49.694187596000063, 11.480160793000039],
              [49.694604001000073, 11.479934744000047],
              [49.694889537000051, 11.479780079000079],
              [49.695294045000082, 11.47960161900005],
              [49.69559147800004, 11.479387468000084],
              [49.69600788300005, 11.479066241000055],
              [49.696709824000038, 11.478590348000068],
              [49.697078641000076, 11.478411889000085],
              [49.697364176000065, 11.478388094000081],
              [49.697697301000062, 11.47825722400006],
              [49.697899555000049, 11.478150148000054],
              [49.698220782000078, 11.47812635400004],
              [49.698768058000041, 11.477995483000086],
              [49.699196361000077, 11.477781332000063],
              [49.700100556000052, 11.477008007000052],
              [49.700588346000075, 11.47668678000008],
              [49.700885778000043, 11.476520217000086],
              [49.701171314000078, 11.476401244000044],
              [49.701516336000054, 11.476175196000042],
              [49.701897049000081, 11.476020531000074],
              [49.702349147000064, 11.475746893000064],
              [49.703372315000081, 11.475425665000046],
              [49.703693542000053, 11.475282898000046],
              [49.704228921000038, 11.474973568000053],
              [49.704669121000052, 11.474807005000059],
              [49.705061732000047, 11.474640443000055],
              [49.70550193300005, 11.474366805000045],
              [49.705906441000081, 11.474224037000056],
              [49.706251463000058, 11.474069373000077],
              [49.706679766000036, 11.473879016000069],
              [49.707012890000044, 11.473688659000061],
              [49.707464988000083, 11.473486405000074],
              [49.707940880000081, 11.473272253000061],
              [49.70832159400004, 11.473058102000039],
              [49.708690410000088, 11.472879642000066],
              [49.70901163800005, 11.472736874000077],
              [49.710106190000033, 11.472344263000082],
              [49.710379828000043, 11.472177701000078],
              [49.710546390000047, 11.472094420000076],
              [49.710824353000078, 11.471938759000068],
              [49.711248541000032, 11.471789328000057],
              [49.711617148000073, 11.471594733000074],
              [49.712057348000087, 11.471487657000068],
              [49.712319089000061, 11.471332992000043],
              [49.712818775000073, 11.471118841000077],
              [49.713675381000087, 11.47078571600008],
              [49.714163171000052, 11.470654846000059],
              [49.714924598000039, 11.470286030000068],
              [49.715842108000061, 11.469922588000088],
              [49.716364172000056, 11.469655472000056],
              [49.716709194000032, 11.469500807000088],
              [49.717054216000065, 11.469393732000071],
              [49.717399238000041, 11.469203375000063],
              [49.717827541000077, 11.469143888000076],
              [49.718351022000036, 11.468870250000066],
              [49.718659925000054, 11.468712194000091],
              [49.718872019000059, 11.46860759100008],
              [49.719279012000072, 11.46848953600005],
              [49.71946936900008, 11.468418153000073],
              [49.719695418000072, 11.468370563000065],
              [49.720052337000084, 11.468311077000067],
              [49.72067099700007, 11.468120720000059],
              [49.721280798000066, 11.467890318000059],
              [49.722372311000072, 11.467954158000055],
              [49.72334789000007, 11.467858979000084],
              [49.723621528000081, 11.467977952000069],
              [49.724347264000073, 11.467632931000082],
              [49.724871243000052, 11.467262702000085],
              [49.725507524000079, 11.46715062700008],
              [49.725749917000087, 11.467060968000055],
              [49.726189254000076, 11.466971308000041],
              [49.726537693000068, 11.466881649000072],
              [49.726855834000048, 11.466866705000086],
              [49.727274001000069, 11.466919092000069],
              [49.727547639000079, 11.466978579000056],
              [49.727904558000034, 11.467049963000079],
              [49.728404245000036, 11.467002373000071],
              [49.728892034000069, 11.466859606000071],
              [49.729356029000087, 11.466621660000044],
              [49.729808127000069, 11.466466995000076],
              [49.730284019000067, 11.466229049000049],
              [49.730764419000081, 11.465925277000053],
              [49.731355252000071, 11.465671241000052],
              [49.731946084000072, 11.465521807000073],
              [49.732582366000088, 11.465178110000068],
              [49.733188348000056, 11.464879243000041],
              [49.733686648000059, 11.464753783000049],
              [49.734519460000058, 11.464373069000089],
              [49.735935239000071, 11.463742512000067],
              [49.737248428000044, 11.463071088000049],
              [49.73788471000006, 11.46292165300008],
              [49.738687636000066, 11.462727388000076],
              [49.739325971000085, 11.462386219000052],
              [49.740087398000071, 11.462148273000082],
              [49.740694161000079, 11.461922224000091],
              [49.741581201000088, 11.461696285000073],
              [49.742626520000044, 11.461292808000053],
              [49.743626391000078, 11.461038768000037],
              [49.744550513000036, 11.460784727000089],
              [49.745191342000055, 11.460542137000061],
              [49.746012256000085, 11.46031608800007],
              [49.746583327000053, 11.460149526000066],
              [49.747630290000075, 11.459863990000088],
              [49.74829653900008, 11.45976881200005],
              [49.749188837000077, 11.459566558000063],
              [49.749985956000046, 11.459411893000038],
              [49.750687897000034, 11.459292920000053],
              [49.751937114000043, 11.459102563000044],
              [49.752924591000067, 11.458781336000072],
              [49.753413003000048, 11.458640312000057],
              [49.754231079000078, 11.458610424000085],
              [49.755185501000085, 11.458610424000085],
              [49.755922711000039, 11.458686157000045],
              [49.757457464000083, 11.458828925000091],
              [49.758154813000033, 11.458685143000082],
              [49.758988039000087, 11.458744918000036],
              [49.759955898000044, 11.458912206000036],
              [49.760324714000035, 11.458864617000074],
              [49.762359153000034, 11.459054974000082],
              [49.763640244000044, 11.45931835600004],
              [49.765197880000073, 11.45957988300006],
              [49.765608394000083, 11.459566820000077],
              [49.766032581000047, 11.459536933000038],
              [49.766305273000057, 11.459536933000038],
              [49.766850657000077, 11.459536933000038],
              [49.767699032000053, 11.459581764000063],
              [49.768547407000085, 11.459581764000063],
              [49.769532128000037, 11.459581764000063],
              [49.770425952000039, 11.459581764000063],
              [49.770986486000083, 11.459641539000074],
              [49.772487357000045, 11.459612212000081],
              [49.773107423000056, 11.459536933000038],
              [49.773501312000064, 11.45949210200007],
              [49.773986097000034, 11.459432327000059],
              [49.774470883000049, 11.45938749600009],
              [49.774955669000065, 11.459372553000037],
              [49.775516202000063, 11.459372553000037],
              [49.775925240000049, 11.45938749600009],
              [49.776334278000036, 11.459447271000045],
              [49.776743316000079, 11.459566820000077],
              [49.776774136000085, 11.459587548000059],
              [49.777336434000063, 11.459601650000081],
              [49.779913897000085, 11.45964962000005],
              [49.781240177000086, 11.459518968000054],
              [49.782637255000054, 11.459697591000065],
              [49.783998933000078, 11.459745561000091],
              [49.78686818500006, 11.459937443000058],
              [49.789044809000075, 11.460173320000081],
              [49.791222015000073, 11.46031608800007],
              [49.792185697000036, 11.460387472000036],
              [49.792983638000067, 11.46043355200004],
              [49.794028958000069, 11.460448495000037],
              [49.795647813000073, 11.460613521000084],
              [49.796528214000034, 11.46055403400004],
              [49.797634552000034, 11.460478382000076],
              [49.798361730000067, 11.460478382000076],
              [49.80078744900004, 11.460542137000061],
              [49.803773672000034, 11.460637315000042],
              [49.804368538000062, 11.460649212000078],
              [49.804927711000062, 11.46082767200005],
              [49.805558268000084, 11.460637315000042],
              [49.806936378000046, 11.460343890000047],
              [49.807739304000052, 11.460179510000046],
              [49.808239240000034, 11.460119735000092],
              [49.808708876000082, 11.460089848000052],
              [49.809027016000073, 11.460045017000084],
              [49.810042037000073, 11.459962827000084],
              [49.810738916000048, 11.460037545000091],
              [49.81104190700006, 11.460097320000045],
              [49.811753936000059, 11.460127207000085],
              [49.812056927000071, 11.460157095000056],
              [49.812632610000037, 11.46024675600006],
              [49.81304164800008, 11.460321474000068],
              [49.813783977000071, 11.460381249000079],
              [49.814995941000063, 11.460500798000055],
              [49.815920064000068, 11.460500798000055],
              [49.817147178000084, 11.460485854000069],
              [49.817813758000057, 11.460530685000037],
              [49.818389441000079, 11.46054562900008],
              [49.819434760000036, 11.460612875000038],
              [49.820298285000035, 11.460612875000038],
              [49.820701067000073, 11.460779437000042],
              [49.821246451000036, 11.460779437000042],
              [49.821774295000068, 11.46055403400004],
              [49.822479821000059, 11.46056804400007],
              [49.823267598000086, 11.460538157000087],
              [49.823737234000077, 11.460508270000048],
              [49.824615908000055, 11.460448495000037],
              [49.825985941000056, 11.460446958000091],
              [49.826925828000071, 11.46055403400004],
              [49.827342828000042, 11.460538157000087],
              [49.827494323000053, 11.460538157000087],
              [49.82790336100004, 11.46056804400007],
              [49.828206352000052, 11.460582988000056],
              [49.828479044000062, 11.460627819000081],
              [49.828978980000045, 11.460777255000039],
              [49.829327419000037, 11.460866916000043],
              [49.829781906000051, 11.461016352000058],
              [49.830145495000068, 11.461135901000091],
              [49.830599982000081, 11.461180732000059],
              [49.830993870000043, 11.461195675000056],
              [49.831175665000046, 11.461195675000056],
              [49.831674134000082, 11.461333663000062],
              [49.832386691000067, 11.46151771600006],
              [49.83376677800004, 11.461827046000053],
              [49.835349120000046, 11.462124478000078],
              [49.837104400000044, 11.46264775700007],
              [49.83939007500004, 11.463271365000082],
              [49.841773665000062, 11.463754409000046],
              [49.844253213000059, 11.464710456000091],
              [49.846441625000068, 11.465238121000084],
              [49.848775931000034, 11.465861723000046],
              [49.850351621000073, 11.466538378000052],
              [49.85216768500004, 11.466927850000047],
              [49.852551686000083, 11.466948893000051],
              [49.852945574000046, 11.466993723000087],
              [49.853263715000082, 11.467113269000038],
              [49.853672753000069, 11.467232815000045],
              [49.854253938000056, 11.467466368000089],
              [49.854789316000051, 11.467585341000074],
              [49.855336592000072, 11.467811390000065],
              [49.856264582000051, 11.468168309000077],
              [49.856871345000059, 11.468334872000071],
              [49.857739848000051, 11.468679893000058],
              [49.85877815300006, 11.468966227000067],
              [49.858927561000087, 11.469067546000076],
              [49.861404218000075, 11.470226543000081],
              [49.861511294000081, 11.470654846000059],
              [49.861892007000051, 11.470940381000048],
              [49.862510667000038, 11.470999868000092],
              [49.862843792000035, 11.471011765000071],
              [49.863498144000062, 11.47115453300006],
              [49.864021625000078, 11.471166430000039],
              [49.865318431000048, 11.471808885000087],
              [49.865603967000084, 11.47198734400007],
              [49.866186934000041, 11.472415647000048],
              [49.866686220000076, 11.472447962000047],
              [49.866686875000084, 11.472453777000055],
              [49.867401749000067, 11.472769228000061],
              [49.869395636000036, 11.47396843000007],
              [49.869666187000064, 11.47421208500009],
              [49.869744229000048, 11.474414394000064],
              [49.869756126000084, 11.474688032000074],
              [49.869958380000071, 11.474925978000044],
              [49.870350991000066, 11.474890287000051],
              [49.870838781000032, 11.474854595000068],
              [49.871170488000075, 11.474704342000052],
              [49.871564377000084, 11.474689399000056],
              [49.871867368000039, 11.474719285000049],
              [49.87209461100008, 11.474808942000038],
              [49.872503649000066, 11.475003199000071],
              [49.872776341000076, 11.47513768400006],
              [49.873033884000051, 11.475287113000093],
              [49.873237515000085, 11.475359498000046],
              [49.87383013200008, 11.475684237000053],
              [49.873851959000035, 11.475690569000051],
              [49.874124651000045, 11.475810111000044],
              [49.87456398900008, 11.475914711000087],
              [49.874897279000038, 11.47607908100008],
              [49.875245718000087, 11.476213566000069],
              [49.875558466000086, 11.476449036000076],
              [49.87598265400004, 11.47647892100008],
              [49.876275849000081, 11.476591601000052],
              [49.876912169000036, 11.476781391000088],
              [49.877124263000042, 11.476945761000081],
              [49.877381806000074, 11.477065303000074],
              [49.877639348000059, 11.477199787000075],
              [49.877866591000043, 11.477304386000071],
              [49.878245330000084, 11.477498642000057],
              [49.878502873000059, 11.477618183000061],
              [49.878905154000051, 11.477828921000082],
              [49.879143100000078, 11.477971689000071],
              [49.879336098000067, 11.478156120000051],
              [49.87951789300007, 11.478275661000055],
              [49.879745136000054, 11.478395202000058],
              [49.880002679000086, 11.478559572000052],
              [49.880260221000071, 11.478753826000059],
              [49.880499392000047, 11.479006754000068],
              [49.880820620000065, 11.47918521400004],
              [49.881189436000057, 11.479482646000065],
              [49.882034145000034, 11.479863360000081],
              [49.882533832000036, 11.480125101000056],
              [49.88537728700004, 11.481433804000062],
              [49.88631046900008, 11.482104810000067],
              [49.88767346700007, 11.483242195000059],
              [49.891773882000052, 11.485941141000069],
              [49.894379606000086, 11.487823859000059],
              [49.898012225000059, 11.490499551000084],
              [49.901621093000074, 11.492963911000061],
              [49.903454189000058, 11.494039724000061],
              [49.904817649000051, 11.494861522000065],
              [49.905590276000055, 11.495578726000076],
              [49.906332604000056, 11.496191337000084],
              [49.906970896000075, 11.49716204200007],
              [49.907768016000034, 11.49757844700008],
              [49.90816570000004, 11.497655619000057],
              [49.908453542000075, 11.497805036000045],
              [49.908618046000072, 11.49794808300004],
              [49.909117982000055, 11.498112441000046],
              [49.909483711000064, 11.498268225000061],
              [49.90967158400008, 11.498423156000058],
              [49.910029095000084, 11.498626823000052],
              [49.910271488000035, 11.498910713000043],
              [49.910392685000033, 11.499134836000053],
              [49.910529031000067, 11.499523316000079],
              [49.910529031000067, 11.499747439000089],
              [49.910302141000045, 11.500005497000075],
              [49.910433012000055, 11.500148265000064],
              [49.910718547000045, 11.500421903000074],
              [49.911004082000034, 11.500564671000063],
              [49.911420488000033, 11.500683644000048],
              [49.911979662000078, 11.500850206000052],
              [49.912491246000059, 11.500969179000037],
              [49.913098008000077, 11.501195228000086],
              [49.913692873000059, 11.501575942000045],
              [49.913954614000033, 11.502230294000071],
              [49.914585171000056, 11.502670494000085],
              [49.915061064000042, 11.503063105000081],
              [49.915655929000081, 11.503301051000051],
              [49.915998020000075, 11.503475320000064],
              [49.916238897000085, 11.503872122000075],
              [49.916548227000078, 11.503955403000077],
              [49.916988427000035, 11.504098170000077],
              [49.917404833000035, 11.504240938000066],
              [49.917904519000047, 11.504443192000053],
              [49.918190055000082, 11.50464544600004],
              [49.918737331000045, 11.504728728000089],
              [49.919225120000078, 11.504395603000091],
              [49.919915164000088, 11.503931608000073],
              [49.920215428000063, 11.50362904900004],
              [49.920500559000061, 11.503447622000067],
              [49.921426122000071, 11.502480137000077],
              [49.922627750000061, 11.501909066000053],
              [49.923305896000045, 11.501230920000069],
              [49.923484396000049, 11.500660068000059],
              [49.924281475000043, 11.499850833000039],
              [49.924722185000064, 11.499254142000041],
              [49.925175372000069, 11.498640558000091],
              [49.92614941100004, 11.498161535000065],
              [49.926815601000044, 11.497792599000093],
              [49.928552607000086, 11.496626663000086],
              [49.93028961400006, 11.495698673000049],
              [49.930706020000059, 11.495603495000069],
              [49.930075462000048, 11.498875254000041],
              [49.928861937000079, 11.49919648100007],
              [49.928029126000069, 11.499624784000048],
              [49.927192166000054, 11.499586332000092],
              [49.925899508000043, 11.500088779000066],
              [49.925302301000045, 11.500525580000044],
              [49.925064235000036, 11.500699704000056],
              [49.924368831000038, 11.501208327000086],
              [49.923924556000088, 11.502016142000059],
              [49.923555739000051, 11.50224219100005],
              [49.923103642000058, 11.501932861000057],
              [49.923222615000043, 11.503312948000087],
              [49.923424869000087, 11.503693662000046],
              [49.923746096000059, 11.504121965000081],
              [49.924269578000064, 11.504478884000036],
              [49.925031005000051, 11.505537744000037],
              [49.925454359000071, 11.506135172000086],
              [49.92610871100004, 11.506789523000066],
              [49.926161249000074, 11.507512697000038],
              [49.926744217000078, 11.507810130000053],
              [49.927696001000072, 11.50801238400004],
              [49.928159943000082, 11.508224734000066],
              [49.928533168000058, 11.508440510000071],
              [49.928992808000032, 11.50883329800007],
              [49.929755804000081, 11.508808599000076],
              [49.930373556000063, 11.508808599000076],
              [49.930875480000054, 11.508859370000039],
              [49.931467493000071, 11.508910140000069],
              [49.931871956000066, 11.50907124400004],
              [49.932264567000061, 11.509285395000063],
              [49.932538205000071, 11.509654212000044],
              [49.933025994000047, 11.509832671000083],
              [49.933846908000078, 11.510046823000039],
              [49.934334698000043, 11.510070618000043],
              [49.934870076000038, 11.510296666000045],
              [49.935637323000037, 11.51083942200006],
              [49.93577427200006, 11.511141375000079],
              [49.935583915000052, 11.511760035000066],
              [49.935809963000054, 11.512319208000065],
              [49.936319425000079, 11.512616381000043],
              [49.936641171000076, 11.512641766000058],
              [49.936999694000065, 11.512735614000064],
              [49.937190051000073, 11.512795100000062],
              [49.937487483000041, 11.512961663000056],
              [49.938010965000046, 11.512818895000066],
              [49.939367258000061, 11.512854587000049],
              [49.939862826000081, 11.512791126000081],
              [49.939880051000046, 11.512801580000087],
              [49.940161872000033, 11.512954446000037],
              [49.940415512000072, 11.512996137000073],
              [49.940626878000046, 11.513051725000082],
              [49.940922790000059, 11.51319069300007],
              [49.941275067000049, 11.513399147000086],
              [49.941514615000074, 11.513565909000079],
              [49.941615848000083, 11.513925344000086],
              [49.941913281000041, 11.514198982000039],
              [49.94223450800007, 11.514425031000087],
              [49.942627119000065, 11.514544004000072],
              [49.942881449000083, 11.514441411000064],
              [49.943162498000049, 11.514567799000076],
              [49.943495623000047, 11.51469866900004],
              [49.943840644000034, 11.51469866900004],
              [49.944304639000052, 11.514936615000067],
              [49.944673456000032, 11.515043691000074],
              [49.944970888000057, 11.515162664000059],
              [49.945387294000056, 11.515376816000071],
              [49.945803700000056, 11.515543378000075],
              [49.946386668000059, 11.515686146000064],
              [49.94675548400005, 11.515709940000079],
              [49.947290863000035, 11.51566235100006],
              [49.947778652000068, 11.515590967000037],
              [49.947980907000044, 11.515471994000052],
              [49.948373518000039, 11.51538871300005],
              [49.950134319000085, 11.515043691000074],
              [49.950657800000045, 11.514781950000042],
              [49.950836944000059, 11.514660851000087],
              [49.951312152000071, 11.514615388000038],
              [49.951669071000083, 11.514472620000049],
              [49.952037888000064, 11.514294161000066],
              [49.95252567700004, 11.514068112000075],
              [49.953001569000037, 11.513853961000052],
              [49.953429872000072, 11.51363980900004],
              [49.953834381000036, 11.513497041000051],
              [49.954203197000083, 11.513354274000051],
              [49.954643397000041, 11.51322340300004],
              [49.95519704600008, 11.512857168000039],
              [49.955605687000059, 11.512759890000041],
              [49.955747255000063, 11.512753819000068],
              [49.95649443700006, 11.512549999000043],
              [49.959878212000035, 11.511629165000045],
              [49.961555732000079, 11.511141375000079],
              [49.963740513000062, 11.510535616000084],
              [49.96727833500006, 11.509606623000082],
              [49.971323419000043, 11.508690530000081],
              [49.974499999000045, 11.508095665000042],
              [49.977593299000034, 11.507393724000053],
              [49.980924544000061, 11.506763167000088],
              [49.984457481000049, 11.505907276000073],
              [49.988347991000069, 11.505139875000054],
              [49.991460400000051, 11.504564323000068],
              [49.995498743000041, 11.503955403000077],
              [49.99785440900007, 11.503931608000073],
              [49.999829362000071, 11.503657970000063],
              [50.00062648100004, 11.503491408000059],
              [50.001887596000074, 11.503408127000057],
              [50.007860043000051, 11.503372435000074],
              [50.010251401000062, 11.503336743000091],
              [50.010596423000038, 11.503836430000092],
              [50.011119904000054, 11.504002992000039],
              [50.011928921000049, 11.50371745700005],
              [50.014208254000039, 11.503874684000039],
              [50.018508131000033, 11.504621652000083],
              [50.023309693000044, 11.504707495000048],
              [50.026169995000032, 11.504633549000062],
              [50.029317832000061, 11.504945441000075],
              [50.030686022000054, 11.505004928000062],
              [50.031518833000064, 11.505123901000047],
              [50.032118648000051, 11.505264106000084],
              [50.033546324000042, 11.505632923000064],
              [50.034510006000062, 11.505692409000062],
              [50.035414201000037, 11.505573436000077],
              [50.036377883000057, 11.505525847000058],
              [50.037305873000037, 11.505621025000039],
              [50.038317144000075, 11.505680512000083],
              [50.038995290000059, 11.505621025000039],
              [50.039982766000037, 11.505751896000049],
              [50.041077318000077, 11.505751896000049],
              [50.041648389000045, 11.505775690000064],
              [50.042231357000048, 11.505847074000087],
              [50.042564482000046, 11.505942253000057],
              [50.042968990000077, 11.50612071200004],
              [50.043325909000032, 11.506251583000051],
              [50.04406354200006, 11.506418145000055],
              [50.04489635300007, 11.506703680000044],
              [50.045241375000046, 11.50681075600005],
              [50.045455527000058, 11.506953524000039],
              [50.045491219000041, 11.507120086000043],
              [50.045689909000032, 11.507242761000043],
              [50.045760365000035, 11.507423424000081],
              [50.045895727000072, 11.507643567000059],
              [50.046097981000059, 11.507845822000093],
              [50.046443003000036, 11.507822027000088],
              [50.046811819000084, 11.507762540000044],
              [50.047061663000079, 11.507691157000068],
              [50.047240122000062, 11.507607875000076],
              [50.047454274000074, 11.507524594000074],
              [50.047692220000044, 11.507417519000057],
              [50.048346572000071, 11.507274751000068],
              [50.048620210000081, 11.507203367000045],
              [50.048929540000074, 11.507131983000079],
              [50.04941732900005, 11.507024907000073],
              [50.050119270000039, 11.506798859000071],
              [50.050285832000043, 11.506703680000044],
              [50.05065464900008, 11.506489529000078],
              [50.050880698000071, 11.506418145000055],
              [50.051237617000083, 11.50629917200007],
              [50.051689714000076, 11.506227788000047],
              [50.052534423000054, 11.506358658000067],
              [50.053129288000036, 11.506334864000053],
              [50.05371225600004, 11.506358658000067],
              [50.05439040300007, 11.506346761000088],
              [50.05504475400005, 11.506346761000088],
              [50.055675312000062, 11.506334864000053],
              [50.056412945000034, 11.506239685000082],
              [50.057079194000039, 11.506096918000082],
              [50.058102362000056, 11.50615640400008],
              [50.05868533000006, 11.506346761000088],
              [50.059042249000072, 11.506525221000061],
              [50.059414614000048, 11.506256058000076],
              [50.059482449000086, 11.506786961000046],
              [50.059710271000085, 11.507023792000041],
              [50.059935728000085, 11.507134970000038],
              [50.06030336300006, 11.507143881000047],
              [50.060791153000082, 11.507262854000089],
              [50.061481197000035, 11.507441313000072],
              [50.063456149000046, 11.507679259000042],
              [50.065193156000078, 11.507453210000051],
              [50.066049762000034, 11.507227162000049],
              [50.066317973000082, 11.507137378000039],
              [50.066515248000087, 11.507053995000092],
              [50.066680319000056, 11.507013010000037],
              [50.066953957000067, 11.506989216000079],
              [50.067203800000073, 11.507001113000058],
              [50.067548822000049, 11.506953524000039],
              [50.068334044000039, 11.506953524000039],
              [50.068917012000043, 11.506941626000071],
              [50.069535672000086, 11.506965421000075],
              [50.069735399000081, 11.506969185000059],
              [50.071974620000049, 11.507524594000074],
              [50.072557587000063, 11.507643567000059],
              [50.073176247000049, 11.507881513000086],
              [50.075479623000035, 11.508574865000071],
              [50.077221331000032, 11.509475752000071],
              [50.078946440000038, 11.510439434000091],
              [50.079255644000057, 11.510665699000072],
              [50.080885701000057, 11.511617267000076],
              [50.081302107000056, 11.511474500000077],
              [50.079833080000071, 11.510441972000081],
              [50.080171863000032, 11.510237180000047],
              [50.080219452000051, 11.510534612000072],
              [50.080647755000086, 11.51082014800005],
              [50.081064161000086, 11.51096291500005],
              [50.081230723000033, 11.51085584000009],
              [50.081587642000045, 11.510974813000075],
              [50.081813691000036, 11.511081888000092],
              [50.082456145000037, 11.511141375000079],
              [50.082765475000087, 11.511533986000074],
              [50.083169984000051, 11.511997981000093],
              [50.083645876000048, 11.512188338000044],
              [50.084324022000033, 11.512521462000052],
              [50.084736270000064, 11.512745992000077],
              [50.085159002000069, 11.512940549000064],
              [50.086655894000046, 11.513544631000059],
              [50.086572613000044, 11.513782577000086],
              [50.086751072000084, 11.513996728000052],
              [50.087060402000077, 11.513734987000078],
              [50.088035981000075, 11.514210880000064],
              [50.088607052000043, 11.514544004000072],
              [50.089463658000057, 11.515245945000061],
              [50.089879511000049, 11.515553154000088],
              [50.090682702000038, 11.515803296000058],
              [50.090964524000071, 11.515872780000052],
              [50.091147707000061, 11.515942264000046],
              [50.091302709000047, 11.515983954000092],
              [50.091908625000087, 11.516331373000071],
              [50.091954228000077, 11.516373133000059],
              [50.092432751000047, 11.516954298000087],
              [50.092442571000049, 11.516965115000062],
              [50.092442790000064, 11.516965355000082],
              [50.094080972000086, 11.518769770000063],
              [50.095729193000068, 11.519635281000092],
              [50.097270386000048, 11.52054300900005],
              [50.098554714000045, 11.521070757000075],
              [50.098581411000055, 11.521069321000084],
              [50.098686431000033, 11.521139601000073],
              [50.099109163000037, 11.52138973700005],
              [50.099264165000079, 11.52140363400008],
              [50.099695340000039, 11.521503928000072],
              [50.100147438000079, 11.521694285000081],
              [50.100715545000071, 11.52189001000005],
              [50.102550693000069, 11.522265355000059],
              [50.102764845000081, 11.522205869000061],
              [50.103597656000034, 11.522182074000057],
              [50.102622077000035, 11.522455712000067],
              [50.101527525000051, 11.522538993000069],
              [50.101670293000041, 11.522741247000056],
              [50.102407926000069, 11.522753145000081],
              [50.102824331000079, 11.522574685000052],
              [50.103395402000046, 11.522515199000054],
              [50.103728527000044, 11.522562788000073],
              [50.104263905000039, 11.522646069000075],
              [50.104632863000063, 11.522779382000067],
              [50.104956958000059, 11.52296003500004],
              [50.105506510000055, 11.523210170000084],
              [50.105774240000073, 11.523404720000087],
              [50.105971515000078, 11.523557580000045],
              [50.106143680000059, 11.52383579900004],
              [50.106536291000054, 11.52410943700005],
              [50.106988388000048, 11.524478254000087],
              [50.107452383000066, 11.525013633000071],
              [50.107785508000063, 11.525394346000041],
              [50.108308989000079, 11.526108185000055],
              [50.108844368000064, 11.526465104000067],
              [50.109339281000075, 11.526698142000043],
              [50.109874742000045, 11.526989962000073],
              [50.110353839000084, 11.527309575000061],
              [50.110762480000062, 11.527615291000075],
              [50.110889299000064, 11.527684771000054],
              [50.111452942000085, 11.527934902000084],
              [50.111805219000075, 11.528157241000088],
              [50.112199769000085, 11.528407371000071],
              [50.112495681000041, 11.528574124000045],
              [50.112777503000075, 11.528782566000075],
              [50.113087507000046, 11.529004904000089],
              [50.113424830000042, 11.529249073000074],
              [50.113792060000037, 11.529310618000068],
              [50.114087973000039, 11.529380098000047],
              [50.11429933900007, 11.529435683000088],
              [50.114595251000082, 11.529574643000046],
              [50.114778435000062, 11.529658020000056],
              [50.115144803000078, 11.52990814900005],
              [50.115652082000054, 11.53018607000007],
              [50.115933903000041, 11.530325030000085],
              [50.116117087000077, 11.53061684700009],
              [50.116398908000065, 11.530936455000074],
              [50.11694846000006, 11.531256063000058],
              [50.117242119000082, 11.531421544000068],
              [50.11734301000007, 11.531478398000047],
              [50.117667105000066, 11.531756318000077],
              [50.118017190000046, 11.532140118000086],
              [50.118504980000068, 11.532711189000054],
              [50.118933282000057, 11.53296103200006],
              [50.119266407000055, 11.533127595000053],
              [50.119492456000046, 11.533258465000074],
              [50.119801786000039, 11.533389335000038],
              [50.120598905000065, 11.53378194600009],
              [50.12086064600004, 11.533972303000041],
              [50.121105326000077, 11.534299267000051],
              [50.121241360000056, 11.534900293000078],
              [50.121705355000074, 11.535376185000075],
              [50.121781698000063, 11.535563785000079],
              [50.121964882000043, 11.535758325000074],
              [50.122162157000048, 11.536022345000049],
              [50.122359432000053, 11.536258572000065],
              [50.122486252000044, 11.536480904000086],
              [50.122669435000034, 11.536828297000056],
              [50.122799907000058, 11.537172678000047],
              [50.123147492000044, 11.53750578100005],
              [50.123370977000036, 11.537719954000067],
              [50.123754448000057, 11.53791910800004],
              [50.12400808700005, 11.538141438000082],
              [50.124233544000049, 11.538308186000052],
              [50.124585821000039, 11.538655576000053],
              [50.124839461000079, 11.538905697000075],
              [50.125036736000084, 11.539100236000081],
              [50.125248102000057, 11.539433730000042],
              [50.125487650000082, 11.539614372000074],
              [50.125619568000047, 11.539861469000073],
              [50.125976487000059, 11.540158902000087],
              [50.12648807100004, 11.540408745000093],
              [50.126904477000039, 11.540444437000076],
              [50.127416061000076, 11.540575308000086],
              [50.127856261000034, 11.540551513000082],
              [50.128141797000069, 11.540563410000061],
              [50.128938916000038, 11.540444437000076],
              [50.129521884000042, 11.540503924000063],
              [50.130473668000036, 11.540242183000089],
              [50.131068534000065, 11.539932853000039],
              [50.132317751000073, 11.539885264000077],
              [50.134375984000087, 11.539801983000075],
              [50.135506228000054, 11.539885264000077],
              [50.13612488800004, 11.540004237000062],
              [50.13649685200005, 11.54008490800004],
              [50.136840933000087, 11.54029371200005],
              [50.137171780000074, 11.540502515000071],
              [50.137529095000048, 11.540698269000075],
              [50.137833474000047, 11.540776570000048],
              [50.138071684000067, 11.540880971000092],
              [50.138362829000073, 11.541128925000066],
              [50.138495168000077, 11.541259427000057],
              [50.138746612000034, 11.541546531000051],
              [50.138892184000042, 11.541820585000039],
              [50.138998055000059, 11.54206853800008],
              [50.139130394000063, 11.542316490000076],
              [50.139249499000073, 11.542538343000047],
              [50.139444236000088, 11.542800104000037],
              [50.140098588000058, 11.542823898000051],
              [50.140396021000072, 11.542835796000077],
              [50.140657761000057, 11.542752514000085],
              [50.140955194000071, 11.542716823000092],
              [50.141335908000087, 11.542585952000081],
              [50.141692827000043, 11.542443185000081],
              [50.141918876000034, 11.542371801000058],
              [50.142228206000084, 11.542383698000037],
              [50.143037222000032, 11.542716823000092],
              [50.143358450000051, 11.542907179000053],
              [50.143762958000082, 11.543109434000087],
              [50.144143672000041, 11.543394969000076],
              [50.144512488000032, 11.543573429000048],
              [50.145165042000087, 11.543986903000075],
              [50.145575293000036, 11.544319679000068],
              [50.145760567000082, 11.544437130000063],
              [50.14594016500007, 11.544596597000066],
              [50.146297084000082, 11.544786954000074],
              [50.146463646000086, 11.544870235000076],
              [50.146642106000058, 11.544989208000061],
              [50.146767347000036, 11.545026780000057],
              [50.146880052000085, 11.545060592000084],
              [50.147095137000065, 11.545310090000044],
              [50.147177485000043, 11.545405613000071],
              [50.147533906000035, 11.545859580000069],
              [50.147772116000056, 11.546329379000042],
              [50.147957391000034, 11.546746977000055],
              [50.148063262000051, 11.547321173000057],
              [50.148222068000052, 11.547764869000048],
              [50.148248242000079, 11.548605989000066],
              [50.148450496000066, 11.548974805000057],
              [50.14967591900006, 11.548962908000078],
              [50.149116745000072, 11.548213377000081],
              [50.14903346400007, 11.547868356000038],
              [50.149604535000037, 11.547820766000086],
              [50.150080427000034, 11.548130096000079],
              [50.150580114000036, 11.54849891300006],
              [50.151056006000033, 11.548617886000045],
              [50.151636409000048, 11.548678360000054],
              [50.152245737000044, 11.548760653000045],
              [50.153423570000086, 11.548855832000072],
              [50.153454414000066, 11.548815174000083],
              [50.153685310000071, 11.548510810000039],
              [50.154280176000043, 11.548903421000091],
              [50.155315241000039, 11.549355519000073],
              [50.156112361000055, 11.549331724000069],
              [50.156495520000078, 11.549138933000052],
              [50.157368956000084, 11.549373829000046],
              [50.157565186000056, 11.549565747000088],
              [50.158438622000062, 11.550009440000053],
              [50.159788628000058, 11.550259714000049],
              [50.160645234000071, 11.550224022000066],
              [50.161692197000036, 11.551318574000049],
              [50.162453624000079, 11.551782569000068],
              [50.163536279000084, 11.552222769000082],
              [50.165142415000048, 11.552770045000045],
              [50.165891945000055, 11.552948505000074],
              [50.166391632000057, 11.553103170000043],
              [50.166855627000075, 11.55316265600004],
              [50.167795514000034, 11.553222143000085],
              [50.169853748000037, 11.553353013000049],
              [50.170317743000055, 11.553483884000059],
              [50.170757943000069, 11.554078749000041],
              [50.169842790000075, 11.554293290000089],
              [50.169078659000036, 11.554472427000064],
              [50.169899160000057, 11.554733418000069],
              [50.170507918000055, 11.554837815000042],
              [50.171010805000037, 11.554837815000042],
              [50.171519371000045, 11.555078122000054],
              [50.17205474900004, 11.555280377000088],
              [50.172375977000058, 11.555791961000068],
              [50.17342294000008, 11.556232161000082],
              [50.174136778000047, 11.556303545000048],
              [50.174493697000059, 11.556053701000053],
              [50.174898205000034, 11.556327339000063],
              [50.175564454000039, 11.556410621000055],
              [50.176135525000063, 11.556374929000071],
              [50.176694698000063, 11.556267853000065],
              [50.177182488000085, 11.556517696000071],
              [50.177753559000053, 11.556684259000065],
              [50.178336526000066, 11.556755642000041],
              [50.17891949400007, 11.556541491000075],
              [50.180240095000045, 11.55680323200005],
              [50.180715988000088, 11.556743745000063],
              [50.181333230000064, 11.556964883000092],
              [50.18210797200004, 11.557362405000049],
              [50.183071654000059, 11.557255329000043],
              [50.183635925000033, 11.557147575000045],
              [50.184475536000036, 11.557421892000036],
              [50.185177477000082, 11.557517070000074],
              [50.185831829000051, 11.557755016000044],
              [50.186605153000073, 11.557933476000073],
              [50.187307094000062, 11.55789778400009],
              [50.187539537000077, 11.557933148000075],
              [50.187903589000086, 11.557933148000075],
              [50.187984435000033, 11.557916419000037],
              [50.188246982000067, 11.55786209200005],
              [50.188499636000074, 11.55796636000008],
              [50.188701887000036, 11.558185735000052],
              [50.189308640000036, 11.558325337000042],
              [50.189591791000055, 11.558544711000081],
              [50.189834492000045, 11.558724199000039],
              [50.190279444000055, 11.558963516000063],
              [50.190582821000078, 11.559083175000069],
              [50.190690698000083, 11.559208888000057],
              [50.190805297000054, 11.559342434000087],
              [50.190947565000045, 11.559594947000051],
              [50.194612040000038, 11.561240926000039],
              [50.19565900300006, 11.561419386000068],
              [50.196158689000072, 11.561478873000056],
              [50.196646479000037, 11.561645435000059],
              [50.197336523000047, 11.561859586000082],
              [50.198442972000066, 11.562204608000059],
              [50.199400962000084, 11.562254106000069],
              [50.199704086000054, 11.562621014000058],
              [50.20032274600004, 11.562716192000039],
              [50.200988995000046, 11.562835165000081],
              [50.202131137000038, 11.563061214000072],
              [50.202928256000064, 11.563049317000036],
              [50.203677786000071, 11.563239674000044],
              [50.204712852000057, 11.563596593000057],
              [50.205521868000062, 11.563834539000084],
              [50.206152426000074, 11.563822642000048],
              [50.207080415000064, 11.563727463000077],
              [50.207937021000077, 11.563715566000042],
              [50.209079163000069, 11.563739361000046],
              [50.209757309000054, 11.563727463000077],
              [50.211934516000042, 11.564191458000039],
              [50.212565073000064, 11.564286637000066],
              [50.213148041000068, 11.564250945000083],
              [50.213564447000067, 11.564417507000087],
              [50.214302080000039, 11.564548377000051],
              [50.21498022600008, 11.564679248000061],
              [50.215408529000058, 11.564655453000057],
              [50.216050983000059, 11.564810118000082],
              [50.216514978000077, 11.564929091000067],
              [50.216978973000039, 11.565036167000073],
              [50.217583321000063, 11.565285403000075],
              [50.218008048000058, 11.565624427000046],
              [50.21844234200006, 11.565785697000081],
              [50.218894439000053, 11.56607123200007],
              [50.219287050000048, 11.566321076000065],
              [50.219774840000071, 11.566689892000056],
              [50.220250732000068, 11.566999222000049],
              [50.220512473000042, 11.567260963000081],
              [50.220726624000065, 11.567522704000055],
              [50.221107338000081, 11.567760650000082],
              [50.221380976000034, 11.567974801000048],
              [50.221654614000045, 11.568165158000056],
              [50.221975841000074, 11.568426899000087],
              [50.222297069000035, 11.56884330400004],
              [50.222677782000062, 11.56956904000009],
              [50.222639594000043, 11.570111478000058],
              [50.222841845000062, 11.570430554000041],
              [50.22310608500004, 11.570818257000042],
              [50.223581978000084, 11.571127587000092],
              [50.223934, 11.571108590000051],
              [50.224176701000033, 11.571288070000037],
              [50.22450030300007, 11.571587203000092],
              [50.224823904000061, 11.571886335000045],
              [50.225167731000056, 11.572245294000084],
              [50.225531783000065, 11.572684020000054],
              [50.225734034000084, 11.572963210000069],
              [50.225976735000074, 11.573282283000083],
              [50.226118310000061, 11.573481703000084],
              [50.226340787000083, 11.574060022000083],
              [50.226421687000084, 11.574299326000073],
              [50.226927314000079, 11.574897584000041],
              [50.227210466000088, 11.575196713000082],
              [50.227392491000046, 11.575515784000061],
              [50.227675643000055, 11.575775028000066],
              [50.227958794000074, 11.576133982000044],
              [50.228282396000054, 11.576552760000084],
              [50.228565547000073, 11.576911713000072],
              [50.22870712200006, 11.577190898000083],
              [50.228889148000064, 11.577390315000059],
              [50.22903072400004, 11.577450141000043],
              [50.229394776000049, 11.577450141000043],
              [50.22947567600005, 11.577410257000054],
              [50.229779052000083, 11.577270665000071],
              [50.229961078000088, 11.577111131000038],
              [50.230082429000049, 11.576891771000078],
              [50.230077084000072, 11.576794285000062],
              [50.230272202000037, 11.57669122100009],
              [50.23032803500007, 11.576598058000059],
              [50.230353063000052, 11.576536366000084],
              [50.230440658000077, 11.576462336000077],
              [50.230590821000078, 11.576437660000067],
              [50.230653389000054, 11.576437660000067],
              [50.230828579000047, 11.576437660000067],
              [50.230928688000063, 11.576449998000044],
              [50.231153933000087, 11.576462336000077],
              [50.231366664000063, 11.576524028000051],
              [50.231541854000056, 11.576561043000083],
              [50.231692017000057, 11.576585720000082],
              [50.23186720800004, 11.576622735000058],
              [50.231913687000088, 11.576648922000061],
              [50.231947196000078, 11.576700841000047],
              [50.231942822000065, 11.576717373000065],
              [50.23195921100006, 11.576719458000071],
              [50.231992344000048, 11.576770794000083],
              [50.232104966000065, 11.576968208000039],
              [50.232255129000066, 11.57726432700008],
              [50.232405292000067, 11.577696167000056],
              [50.232480374000033, 11.578053977000081],
              [50.232492888000081, 11.578337757000043],
              [50.232542942000066, 11.578522830000054],
              [50.232768187000033, 11.578707904000055],
              [50.232980918000067, 11.578868301000057],
              [50.23315610800006, 11.579016360000082],
              [50.233331299000042, 11.57900402100006],
              [50.233431407000069, 11.579016360000082],
              [50.233569057000068, 11.579324815000064],
              [50.233669166000084, 11.579522226000051],
              [50.233756761000052, 11.579620932000068],
              [50.234094628000037, 11.579670284000088],
              [50.234507577000045, 11.579670284000088],
              [50.23488298500007, 11.579657946000054],
              [50.235245879000047, 11.579485212000066],
              [50.235433583000088, 11.579287800000088],
              [50.235722972000076, 11.579082360000086],
              [50.235808771000052, 11.579082360000086],
              [50.236897674000033, 11.578097161000073],
              [50.237172973000042, 11.578220543000043],
              [50.237410731000068, 11.578269896000052],
              [50.23772357100006, 11.578257558000075],
              [50.238072895000073, 11.578313559000037],
              [50.238349251000045, 11.578368602000069],
              [50.238612036000063, 11.578491985000085],
              [50.238987444000088, 11.578578352000079],
              [50.239412906000041, 11.578578352000079],
              [50.239809902000047, 11.578741862000072],
              [50.240476151000053, 11.578610992000051],
              [50.240651752000076, 11.578528999000071],
              [50.241077215000075, 11.578467308000086],
              [50.241427595000062, 11.578294573000051],
              [50.241865571000062, 11.578220543000043],
              [50.242428683000071, 11.578220543000043],
              [50.242966768000088, 11.578220543000043],
              [50.243236325000055, 11.578254073000039],
              [50.243545655000048, 11.578218381000056],
              [50.243730097000082, 11.578368602000069],
              [50.243892774000074, 11.578528999000071],
              [50.244143046000033, 11.578640044000053],
              [50.24468113000006, 11.578701735000038],
              [50.245094079000069, 11.578714073000071],
              [50.245507028000077, 11.57876342600008],
              [50.245757300000037, 11.57878810200009],
              [50.245844895000062, 11.578738749000081],
              [50.245869922000054, 11.578726411000048],
              [50.247162436000053, 11.578765657000076],
              [50.247702500000059, 11.579004926000039],
              [50.248102323000069, 11.579182062000086],
              [50.248732880000034, 11.57934862500008],
              [50.248970826000061, 11.579646057000048],
              [50.249399129000039, 11.579955387000041],
              [50.249671788000057, 11.580004558000041],
              [50.249894400000073, 11.580105862000039],
              [50.250836223000078, 11.580561729000067],
              [50.25121295200006, 11.58089940800005],
              [50.251726673000064, 11.581253970000091],
              [50.252308890000052, 11.581642300000055],
              [50.252754115000073, 11.581946210000069],
              [50.253233589000047, 11.582317655000054],
              [50.253764434000061, 11.582621564000078],
              [50.254158287000052, 11.582739751000076],
              [50.254637760000037, 11.582773518000067],
              [50.255082985000058, 11.582874821000075],
              [50.255579583000042, 11.583077427000092],
              [50.256024808000063, 11.583263149000061],
              [50.256521405000058, 11.58341510300005],
              [50.257206367000038, 11.583482638000078],
              [50.257942701000047, 11.583516405000069],
              [50.258845590000078, 11.583393708000074],
              [50.25958322300005, 11.58363165500009],
              [50.260562679000088, 11.583803429000056],
              [50.260870912000087, 11.583803429000056],
              [50.261179145000085, 11.583786546000056],
              [50.263057236000066, 11.584274109000091],
              [50.264607338000076, 11.585228336000057],
              [50.264638201000082, 11.585289196000076],
              [50.264775194000038, 11.585474916000067],
              [50.264843690000077, 11.585677520000047],
              [50.264980682000044, 11.585880124000084],
              [50.265237543000069, 11.586082727000075],
              [50.265477280000084, 11.586200913000084],
              [50.265819760000056, 11.586217796000085],
              [50.266179365000085, 11.586386632000085],
              [50.26636773000007, 11.586521701000038],
              [50.266675963000068, 11.586639886000057],
              [50.266847203000054, 11.586673653000048],
              [50.267209395000066, 11.586701159000086],
              [50.267602006000061, 11.586701159000086],
              [50.269898186000034, 11.587533971000084],
              [50.270647716000042, 11.587902787000075],
              [50.270897560000037, 11.588045555000065],
              [50.271135506000064, 11.588152630000081],
              [50.271796051000081, 11.588513958000078],
              [50.272087160000069, 11.58869967600009],
              [50.272361145000048, 11.588851627000054],
              [50.272566633000054, 11.588936044000093],
              [50.272874866000052, 11.589104879000047],
              [50.273114603000067, 11.589206179000087],
              [50.273422836000066, 11.589391897000041],
              [50.273662572000035, 11.589662031000046],
              [50.273850937000077, 11.589830865000067],
              [50.274124921000066, 11.589965932000041],
              [50.274450278000074, 11.590033466000079],
              [50.274827007000056, 11.590185416000054],
              [50.275100992000034, 11.590421783000068],
              [50.275477721000073, 11.590708800000073],
              [50.275888698000074, 11.591029583000079],
              [50.276145558000053, 11.591249066000046],
              [50.276830520000033, 11.591468549000069],
              [50.277209151000079, 11.591615205000039],
              [50.277309993000074, 11.591654265000045],
              [50.277669598000045, 11.591806215000076],
              [50.278059737000035, 11.592126330000042],
              [50.278382908000083, 11.592579178000051],
              [50.278637246000073, 11.592684968000071],
              [50.27864566900007, 11.592734793000091],
              [50.278765537000083, 11.592937392000067],
              [50.27890252900005, 11.593072457000062],
              [50.279159390000075, 11.593173756000056],
              [50.27934775500006, 11.593376354000043],
              [50.279587491000086, 11.59366336800008],
              [50.279947096000058, 11.59395038200006],
              [50.280495065000082, 11.594338694000044],
              [50.280854670000053, 11.594676356000036],
              [50.281180027000062, 11.595064667000088],
              [50.281659500000046, 11.595588041000042],
              [50.281916361000071, 11.596010117000048],
              [50.28217322200004, 11.596398426000064],
              [50.282447206000086, 11.596651671000075],
              [50.282532827000068, 11.596888032000038],
              [50.282584199000041, 11.59717504200006],
              [50.282686943000044, 11.597445169000082],
              [50.282858183000087, 11.597698413000046],
              [50.283046548000073, 11.597833476000062],
              [50.283252036000079, 11.59800230500008],
              [50.283389029000034, 11.598323080000057],
              [50.283423277000054, 11.598508791000086],
              [50.283526021000057, 11.598694503000047],
              [50.283714386000042, 11.598778917000061],
              [50.283782882000082, 11.598812682000073],
              [50.283937006000087, 11.598979178000093],
              [50.284127363000039, 11.599086254000042],
              [50.284436693000032, 11.599229021000042],
              [50.284707580000088, 11.599487995000061],
              [50.285015813000086, 11.599910065000074],
              [50.285324046000085, 11.60031525100004],
              [50.285666527000046, 11.600787968000077],
              [50.285906263000072, 11.601041208000083],
              [50.286423543000069, 11.601882120000084],
              [50.286967954000033, 11.602239878000091],
              [50.287156318000086, 11.602594413000077],
              [50.287533047000068, 11.603337246000081],
              [50.287721412000053, 11.603809958000056],
              [50.287824156000056, 11.604215138000086],
              [50.287875528000086, 11.604485258000068],
              [50.287944024000069, 11.604738496000039],
              [50.288081017000081, 11.605093027000066],
              [50.288150924000036, 11.605253842000081],
              [50.288183761000084, 11.605329382000036],
              [50.28832075300005, 11.605818972000066],
              [50.288423498000043, 11.606038443000045],
              [50.288611862000039, 11.606392973000084],
              [50.288851599000054, 11.606730621000054],
              [50.290230680000036, 11.60748575100007],
              [50.290957856000034, 11.607684472000074],
              [50.291351709000082, 11.607937706000087],
              [50.291779810000037, 11.608241587000066],
              [50.292156539000075, 11.608663643000057],
              [50.292704508000043, 11.609457107000082],
              [50.292812395000055, 11.609865212000045],
              [50.293252596000059, 11.610388694000051],
              [50.293663455000058, 11.610723269000061],
              [50.293800447000081, 11.611094675000061],
              [50.294040184000039, 11.61136478800006],
              [50.29445116100004, 11.61165178300007],
              [50.295101874000068, 11.61202318800008],
              [50.295429803000047, 11.612268468000082],
              [50.295798619000038, 11.612435030000086],
              [50.296048462000044, 11.61260159200009],
              [50.296226922000074, 11.612708668000039],
              [50.296557418000077, 11.612901052000041],
              [50.296745783000063, 11.613204927000083],
              [50.297000247000085, 11.613803220000079],
              [50.297225256000047, 11.614623008000081],
              [50.297333371000036, 11.615004848000069],
              [50.297880647000056, 11.615064334000067],
              [50.298235574000046, 11.615121023000086],
              [50.298509559000081, 11.615374250000059],
              [50.298886288000062, 11.615593714000056],
              [50.298903816000063, 11.615885248000041],
              [50.299228769000081, 11.616032641000061],
              [50.299417133000077, 11.61635339500009],
              [50.299622622000072, 11.616488449000087],
              [50.300093546000085, 11.61670616300006],
              [50.30048615700008, 11.616741854000054],
              [50.301092920000087, 11.616658573000052],
              [50.301247585000056, 11.616563395000071],
              [50.301509767000084, 11.616576559000066],
              [50.301527755000052, 11.616578621000087],
              [50.301534890000084, 11.616583434000063],
              [50.301675888000034, 11.616789444000062],
              [50.302127564000045, 11.617017951000037],
              [50.302389633000075, 11.61705594600005],
              [50.30260387800007, 11.617039287000068],
              [50.303353408000078, 11.617586563000089],
              [50.303793608000035, 11.617193952000036],
              [50.304198116000066, 11.616908417000047],
              [50.304864365000071, 11.616396833000067],
              [50.304900057000054, 11.615909043000045],
              [50.305328360000033, 11.616004222000072],
              [50.305161798000086, 11.616265962000057],
              [50.30514990100005, 11.61657529200005],
              [50.305673382000066, 11.616718060000039],
              [50.306006507000063, 11.616218373000038],
              [50.306398577000039, 11.616241486000092],
              [50.306452532000037, 11.616401062000079],
              [50.306506487000036, 11.616545440000039],
              [50.306606690000081, 11.616712614000051],
              [50.306706893000069, 11.616841795000084],
              [50.306861051000055, 11.616986172000054],
              [50.306984377000049, 11.617024167000068],
              [50.307184783000082, 11.617031765000092],
              [50.307439143000067, 11.617016568000054],
              [50.30765496500004, 11.617016568000054],
              [50.307893910000075, 11.617024167000068],
              [50.308032652000065, 11.617024167000068],
              [50.308248473000049, 11.61703936400005],
              [50.308610745000067, 11.617077358000074],
              [50.308780318000061, 11.617031765000092],
              [50.308797436000077, 11.617014891000053],
              [50.309730363000085, 11.617158260000053],
              [50.309908823000058, 11.61739620600008],
              [50.310015899000064, 11.617443795000042],
              [50.310313331000032, 11.617574666000053],
              [50.310491791000061, 11.617741228000057],
              [50.310622661000082, 11.617895893000082],
              [50.310741634000067, 11.617681742000059],
              [50.310931991000075, 11.617693639000038],
              [50.311015272000077, 11.617443795000042],
              [50.311225749000073, 11.617513237000082],
              [50.311386771000059, 11.617663161000053],
              [50.311512010000058, 11.617804266000064],
              [50.311547793000045, 11.61789245600005],
              [50.311431678000076, 11.618300401000056],
              [50.311622035000084, 11.618383683000047],
              [50.311883775000069, 11.618395580000083],
              [50.31201464600008, 11.618478861000085],
              [50.312312078000048, 11.618562142000087],
              [50.312454846000037, 11.618562142000087],
              [50.312540762000083, 11.618218761000037],
              [50.312847457000032, 11.618312299000081],
              [50.313095394000072, 11.618456875000049],
              [50.31314012200005, 11.618765541000073],
              [50.31314012200005, 11.61904775000005],
              [50.313238524000042, 11.619162397000082],
              [50.313372709000078, 11.619197673000087],
              [50.313578460000087, 11.619162397000082],
              [50.313823036000088, 11.619430645000079],
              [50.314025290000075, 11.619537721000086],
              [50.314285168000083, 11.619153578000066],
              [50.314429799000038, 11.619383056000061],
              [50.314429799000038, 11.619609105000052],
              [50.314667745000065, 11.619728078000037],
              [50.315126060000068, 11.619770908000078],
              [50.315072253000039, 11.620096894000085],
              [50.31547676200006, 11.620239662000074],
              [50.315743311000062, 11.620326504000047],
              [50.315869373000055, 11.620441916000061],
              [50.31626198400005, 11.620525197000063],
              [50.316515600000059, 11.62007630800008],
              [50.316772063000087, 11.620255952000036],
              [50.316879411000059, 11.620414693000043],
              [50.316816792000054, 11.620608711000045],
              [50.316781009000067, 11.620732176000047],
              [50.316933085000073, 11.620846823000079],
              [50.317129890000047, 11.62097028800008],
              [50.317285152000068, 11.621084371000052],
              [50.317594482000061, 11.621286625000039],
              [50.317903812000054, 11.620965398000067],
              [50.318260731000066, 11.62141749500006],
              [50.318558164000081, 11.621584058000053],
              [50.318867494000074, 11.621607852000068],
              [50.319581332000041, 11.62178631200004],
              [50.319985840000072, 11.621845798000038],
              [50.32016247200005, 11.621922733000076],
              [50.320378451000067, 11.621917182000061],
              [50.320406684000034, 11.62237358200008],
              [50.320657163000078, 11.622320669000089],
              [50.320771062000063, 11.62192907900004],
              [50.321235952000052, 11.62180808700009],
              [50.321449209000036, 11.622083744000065],
              [50.321913204000055, 11.622321690000092],
              [50.322222534000048, 11.622262204000037],
              [50.322543761000077, 11.622274101000073],
              [50.322841193000045, 11.62223840900009],
              [50.323245702000065, 11.622345485000039],
              [50.323483648000035, 11.622440664000067],
              [50.323709697000083, 11.622523945000069],
              [50.323995232000073, 11.622654815000089],
              [50.324518713000032, 11.622987940000087],
              [50.324649584000042, 11.623118810000051],
              [50.324957350000034, 11.623192655000082],
              [50.325136263000047, 11.623333757000069],
              [50.325327730000083, 11.623642291000067],
              [50.325696547000064, 11.623773162000077],
              [50.325756033000062, 11.624011108000047],
              [50.326148644000057, 11.624213362000091],
              [50.32645797400005, 11.624379924000038],
              [50.326674918000037, 11.624453752000079],
              [50.326921969000068, 11.624605973000087],
              [50.327183710000043, 11.624784433000059],
              [50.327707191000059, 11.62491530300008],
              [50.328111700000079, 11.625010481000061],
              [50.328669802000036, 11.62527390300005],
              [50.329036574000042, 11.625520830000085],
              [50.329331781000064, 11.625697206000041],
              [50.329230046000077, 11.62593847100004],
              [50.330062858000076, 11.626378672000044],
              [50.330503058000033, 11.626580926000088],
              [50.331883145000063, 11.627485121000063],
              [50.332596984000077, 11.628079986000046],
              [50.333156157000076, 11.628079986000046],
              [50.333370308000042, 11.628151370000069],
              [50.333667741000056, 11.628294138000058],
              [50.333988968000085, 11.62837741900006],
              [50.334191222000072, 11.628413111000043],
              [50.33452434700007, 11.62852018600006],
              [50.334964547000084, 11.628639159000045],
              [50.335571310000034, 11.628877106000061],
              [50.335892537000063, 11.628996079000046],
              [50.336332737000077, 11.629150744000071],
              [50.336939500000085, 11.629317306000075],
              [50.337284522000061, 11.629412484000056],
              [50.337641441000073, 11.629543355000067],
              [50.338153025000054, 11.629793198000073],
              [50.339480574000049, 11.630699955000068],
              [50.341400989000078, 11.631363642000053],
              [50.342055341000048, 11.632053686000063],
              [50.343680963000054, 11.632758226000078],
              [50.344803618000071, 11.633160135000082],
              [50.345291408000037, 11.633564644000046],
              [50.346742879000033, 11.634100022000041],
              [50.348265734000051, 11.634920937000061],
              [50.348253837000073, 11.635587186000066],
              [50.349283837000087, 11.636073800000077],
              [50.349764795000056, 11.63630102400009],
              [50.35071657900005, 11.637014862000058],
              [50.352287023000088, 11.637443165000093],
              [50.354190592000066, 11.637954749000073],
              [50.355475501000058, 11.638549615000045],
              [50.356260723000048, 11.639096891000065],
              [50.356425923000074, 11.639155891000087],
              [50.356427285000052, 11.639156377000063],
              [50.356784205000054, 11.639441912000052],
              [50.358901925000055, 11.640453183000091],
              [50.361715417000084, 11.641793570000061],
              [50.364876457000037, 11.643471426000076],
              [50.366373432000046, 11.644284116000051],
              [50.367503676000069, 11.644807597000067],
              [50.367955774000052, 11.64502174800009],
              [50.368812380000065, 11.645604716000037],
              [50.369371553000065, 11.645866457000068],
              [50.369466732000035, 11.646235274000048],
              [50.370121084000061, 11.64635424700009],
              [50.371612182000035, 11.646946600000092],
              [50.371858090000046, 11.647044290000053],
              [50.373690275000058, 11.648186432000045],
              [50.376367169000048, 11.649947233000091],
              [50.379228698000077, 11.651407559000063],
              [50.380341750000071, 11.652087339000047],
              [50.381256961000076, 11.652659818000075],
              [50.383220016000053, 11.653552116000071],
              [50.384909434000065, 11.654492003000087],
              [50.386003406000043, 11.655469062000066],
              [50.386467981000067, 11.655883988000085],
              [50.386908181000081, 11.655955372000051],
              [50.386931292000043, 11.655944935000036],
              [50.387276997000072, 11.655788810000047],
              [50.388374181000074, 11.656499281000038],
              [50.388728469000057, 11.656728697000062],
              [50.389868463000084, 11.656896459000052],
              [50.39231054000004, 11.657255836000047],
              [50.394401689000063, 11.657879005000041],
              [50.396871542000042, 11.658757867000077],
              [50.39788939400006, 11.659120055000074],
              [50.399947627000074, 11.65960784400005],
              [50.40260072600006, 11.660333580000042],
              [50.405051571000058, 11.66111880200009],
              [50.406455453000035, 11.66186833200004],
              [50.407930719000035, 11.662320430000079],
              [50.414141112000038, 11.664759378000042],
              [50.41569965900004, 11.665687367000089],
              [50.415949503000036, 11.666127568000093],
              [50.41813860700006, 11.66711504400007],
              [50.423813621000079, 11.670541468000067],
              [50.429916939000066, 11.674443784000061],
              [50.431570664000049, 11.675347979000037],
              [50.433486130000063, 11.676264071000048],
              [50.437019630000066, 11.678453176000062],
              [50.440457951000042, 11.680713664000052],
              [50.442603195000061, 11.682207347000087],
              [50.443646429000069, 11.683366763000038],
              [50.444312678000074, 11.683616606000044],
              [50.44576414900007, 11.684366136000051],
              [50.447453566000036, 11.685758121000049],
              [50.449309546000052, 11.687280976000068],
              [50.450784812000052, 11.688768139000047],
              [50.451046552000037, 11.689029880000078],
              [50.451724699000067, 11.689660437000043],
              [50.452117310000062, 11.690041151000059],
              [50.454072395000082, 11.691935469000043],
              [50.455823124000062, 11.693565088000071],
              [50.457697146000044, 11.695573398000079],
              [50.459859529000084, 11.697495306000064],
              [50.462087251000071, 11.699202076000063],
              [50.465585059000034, 11.702795062000064],
              [50.469285121000041, 11.706554610000069],
              [50.473038634000034, 11.710196232000044],
              [50.476506785000083, 11.713966631000062],
              [50.477684618000069, 11.71535861600006],
              [50.481634524000071, 11.71936800800006],
              [50.484763515000054, 11.722877713000059],
              [50.486786057000074, 11.72528096800005],
              [50.488761009000086, 11.727731813000048],
              [50.490351406000059, 11.729917441000055],
              [50.49280609300007, 11.733383033000052],
              [50.494495510000036, 11.73588146700007],
              [50.496430328000088, 11.738543524000079],
              [50.498326443000053, 11.741378022000049],
              [50.499087075000034, 11.742514185000061],
              [50.499708540000086, 11.743442474000062],
              [50.500479855000037, 11.744328554000049],
              [50.501122309000039, 11.745316030000083],
              [50.501585255000066, 11.746067162000088],
              [50.503513668000039, 11.748956605000046],
              [50.504762885000048, 11.750907763000043],
              [50.506319123000083, 11.754093469000054],
              [50.506761632000064, 11.754857669000046],
              [50.507779970000058, 11.75766522300006],
              [50.50800216600004, 11.758208216000071],
              [50.509260066000081, 11.761282213000072],
              [50.511353992000068, 11.766160108000065],
              [50.513147371000059, 11.770463641000049],
              [50.514363982000077, 11.773425172000088],
              [50.515330114000051, 11.776034114000083],
              [50.517671461000077, 11.781864551000069],
              [50.519027753000046, 11.785826354000051],
              [50.519979538000086, 11.789228983000044],
              [50.520419738000044, 11.790918400000066],
              [50.521038398000087, 11.792869558000064],
              [50.521234255000081, 11.794084480000038],
              [50.521602290000033, 11.794283328000063],
              [50.52238751200008, 11.794711631000041],
              [50.522737128000074, 11.794719041000064],
              [50.522737128000074, 11.794330058000071],
              [50.523398783000061, 11.794057279000072],
              [50.524088827000071, 11.793783641000061],
              [50.524214395000058, 11.793843348000053],
              [50.524230967000051, 11.794186206000063],
              [50.524363540000081, 11.794512736000058],
              [50.524380111000085, 11.794610695000074],
              [50.524346968000032, 11.794757634000064],
              [50.524346968000032, 11.79492089900009],
              [50.524380111000085, 11.79506783700009],
              [50.524446398000066, 11.795329061000075],
              [50.524562399000047, 11.795573958000091],
              [50.524694973000067, 11.79575354900004],
              [50.524910404000082, 11.795884160000071],
              [50.525158978000036, 11.79604742500004],
              [50.525407553000036, 11.796194362000051],
              [50.525705842000036, 11.796292321000067],
              [50.525954417000037, 11.796308647000046],
              [50.526252706000037, 11.796324974000072],
              [50.526481356000033, 11.796324974000072],
              [50.526679048000062, 11.796450999000058],
              [50.526932282000075, 11.796436740000047],
              [50.527776991000053, 11.796460535000051],
              [50.528490829000077, 11.796960221000063],
              [50.529109489000064, 11.797638368000037],
              [50.529204668000034, 11.798459282000067],
              [50.530666822000057, 11.799613154000042],
              [50.530958610000084, 11.803733481000052],
              [50.532514814000081, 11.805937351000068],
              [50.534362807000036, 11.80862029900004],
              [50.535309760000075, 11.811275456000089],
              [50.535284249000085, 11.811295875000042],
              [50.535184819000051, 11.811393828000064],
              [50.535068817000081, 11.811557083000082],
              [50.534969387000046, 11.811883593000061],
              [50.53493624400005, 11.812846794000052],
              [50.53493624400005, 11.81305902400004],
              [50.53493624400005, 11.813352881000071],
              [50.53493624400005, 11.81358143600005],
              [50.53511853200007, 11.813891618000071],
              [50.535267677000036, 11.814120173000049],
              [50.53536710700007, 11.814348728000084],
              [50.535383678000073, 11.814822161000052],
              [50.535400250000066, 11.815018065000061],
              [50.535416822000059, 11.815671075000068],
              [50.535400250000066, 11.816128182000057],
              [50.535400250000066, 11.816340409000077],
              [50.535383678000073, 11.816634263000083],
              [50.535300820000032, 11.816830165000056],
              [50.535135104000062, 11.816993416000059],
              [50.535019102000035, 11.817124018000072],
              [50.534919673000047, 11.817368895000072],
              [50.534919673000047, 11.817777023000076],
              [50.534803671000077, 11.818103525000083],
              [50.534787099000084, 11.818658578000054],
              [50.534770528000081, 11.819017729000052],
              [50.534787099000084, 11.819246279000083],
              [50.534952816000043, 11.819442180000067],
              [50.535068817000081, 11.819556455000054],
              [50.535201390000054, 11.819736030000058],
              [50.535400250000066, 11.820021717000088],
              [50.535565966000036, 11.820299241000043],
              [50.535599110000078, 11.820413516000087],
              [50.535715111000059, 11.820446166000067],
              [50.53591397100007, 11.820446166000067],
              [50.536112830000036, 11.820413516000087],
              [50.536295118000055, 11.82038086600005],
              [50.53651055000006, 11.82038086600005],
              [50.536593408000044, 11.82036454100006],
              [50.536841982000055, 11.820201292000093],
              [50.536957984000082, 11.820070692000058],
              [50.537189987000033, 11.819825817000037],
              [50.537324602000069, 11.819734009000058],
              [50.537361277000059, 11.819866483000055],
              [50.537372275000052, 11.819833980000055],
              [50.537537991000079, 11.819736030000058],
              [50.537869424000064, 11.819866629000046],
              [50.538068284000076, 11.82006252900004],
              [50.538200857000049, 11.820258429000091],
              [50.538267143000041, 11.820389029000069],
              [50.538466003000053, 11.820813477000058],
              [50.538532289000045, 11.821139975000051],
              [50.538565433000088, 11.821531773000061],
              [50.538565433000088, 11.821792971000036],
              [50.538664863000065, 11.822152118000076],
              [50.538830579000035, 11.822609213000078],
              [50.538896865000083, 11.822870410000064],
              [50.538896865000083, 11.823327505000066],
              [50.538896865000083, 11.823751949000041],
              [50.538930009000069, 11.824306990000082],
              [50.538996295000061, 11.824796731000049],
              [50.539029439000046, 11.825351770000054],
              [50.539095725000038, 11.825547665000045],
              [50.53929458500005, 11.825710912000091],
              [50.539460301000076, 11.825841509000043],
              [50.539498653000066, 11.825992630000087],
              [50.539491057000077, 11.826179709000087],
              [50.539458798000055, 11.826418044000093],
              [50.539539445000059, 11.826767603000064],
              [50.53963622200007, 11.827021827000067],
              [50.539668481000035, 11.827323718000059],
              [50.539684611000041, 11.827482608000082],
              [50.53973299900008, 11.828054610000038],
              [50.539765258000045, 11.828404167000087],
              [50.539926553000043, 11.828817278000088],
              [50.540103977000058, 11.829166834000091],
              [50.540087848000042, 11.829579944000045],
              [50.540087848000042, 11.830151942000043],
              [50.540087848000042, 11.830723939000052],
              [50.540087848000042, 11.831470711000065],
              [50.540055589000076, 11.831947373000048],
              [50.539942683000049, 11.833504462000064],
              [50.539958812000066, 11.834775548000039],
              [50.540071719000082, 11.835641473000067],
              [50.540233013000034, 11.836213458000088],
              [50.54034592000005, 11.83675366500006],
              [50.540491085000042, 11.837341536000054],
              [50.540507215000048, 11.837802299000089],
              [50.54053947400007, 11.838151843000048],
              [50.540571733000036, 11.838485498000068],
              [50.54084593400006, 11.839407020000067],
              [50.540942711000071, 11.839820114000077],
              [50.541087876000063, 11.840312650000044],
              [50.541442725000081, 11.841019674000052],
              [50.541539502000035, 11.841273885000078],
              [50.541620150000085, 11.841559872000062],
              [50.541636279000045, 11.842068292000079],
              [50.541636279000045, 11.842417830000045],
              [50.541652409000051, 11.842751480000061],
              [50.541797574000043, 11.843244010000092],
              [50.541878222000037, 11.843482331000075],
              [50.541894351000053, 11.843895419000091],
              [50.54191048000007, 11.844435611000051],
              [50.541991128000063, 11.844769258000042],
              [50.542071775000068, 11.845214121000083],
              [50.542071775000068, 11.845579543000042],
              [50.542087905000074, 11.845770198000082],
              [50.542249200000072, 11.846238890000052],
              [50.542297588000054, 11.846826741000086],
              [50.542362106000041, 11.847128609000038],
              [50.542458883000052, 11.847366927000053],
              [50.542458883000052, 11.847875336000072],
              [50.542458883000052, 11.848304305000056],
              [50.542539531000045, 11.848685611000064],
              [50.542733085000066, 11.849257569000088],
              [50.542749214000082, 11.849750087000075],
              [50.542829861000087, 11.850306154000066],
              [50.543007286000034, 11.850639794000074],
              [50.54312019200006, 11.850687457000049],
              [50.543265358000042, 11.850703345000056],
              [50.543378264000069, 11.850798670000074],
              [50.543507300000044, 11.851052872000082],
              [50.543636336000077, 11.851307073000044],
              [50.543652466000083, 11.851338848000069],
              [50.543942796000067, 11.85230004400006],
              [50.544152480000037, 11.852633682000089],
              [50.544378293000079, 11.853126194000083],
              [50.544458940000084, 11.853475718000084],
              [50.544604105000076, 11.853777579000052],
              [50.544797659000039, 11.854079440000078],
              [50.544910566000055, 11.854571949000047],
              [50.545023472000082, 11.85488969700009],
              [50.545200897000086, 11.855286880000051],
              [50.545297674000039, 11.855620514000066],
              [50.545346062000078, 11.855985922000059],
              [50.545458969000038, 11.856287781000049],
              [50.545668652000074, 11.856637300000045],
              [50.545829947000072, 11.856875609000042],
              [50.546152537000069, 11.857463437000092],
              [50.546346091000032, 11.857908278000082],
              [50.547039659000063, 11.858988604000047],
              [50.547539673000074, 11.859838564000086],
              [50.547717097000032, 11.860156305000089],
              [50.547846133000064, 11.860458159000075],
              [50.548023558000068, 11.860807673000068],
              [50.548200982000083, 11.861188961000039],
              [50.548394536000046, 11.861554362000049],
              [50.548604219000083, 11.861887988000092],
              [50.548652608000054, 11.862015083000074],
              [50.549217140000053, 11.863222488000076],
              [50.549265528000035, 11.863556112000083],
              [50.549346176000086, 11.863714981000044],
              [50.549475212000061, 11.863857962000054],
              [50.549620377000053, 11.864016830000082],
              [50.549765543000035, 11.864143925000064],
              [50.54994296700005, 11.864271019000057],
              [50.550152650000086, 11.864445774000046],
              [50.550330075000034, 11.864652302000081],
              [50.550555888000076, 11.864922378000074],
              [50.550846218000061, 11.865176566000059],
              [50.551107003000084, 11.865282332000049],
              [50.551481053000032, 11.866009846000054],
              [50.555820696000069, 11.87314980900004],
              [50.555806803000053, 11.873423469000045],
              [50.555790836000085, 11.873690814000042],
              [50.555790836000085, 11.874005336000039],
              [50.555870671000037, 11.87436703700007],
              [50.555982439000047, 11.874602928000058],
              [50.556174042000066, 11.874996080000074],
              [50.556876587000033, 11.876096901000039],
              [50.556972389000066, 11.87631706500008],
              [50.557195925000087, 11.876678763000086],
              [50.557658966000076, 11.877370704000043],
              [50.558026205000033, 11.877811029000043],
              [50.558345544000076, 11.87817272500007],
              [50.558537147000038, 11.878455790000089],
              [50.558680849000041, 11.878754581000067],
              [50.558728750000057, 11.878927565000083],
              [50.55898422100006, 11.879635227000051],
              [50.558985035000035, 11.879654074000086],
              [50.559016155000052, 11.880374338000081],
              [50.559064055000078, 11.88067312700008],
              [50.559287592000032, 11.881019092000088],
              [50.559606931000076, 11.881396509000069],
              [50.560165773000051, 11.881915456000058],
              [50.560341409000046, 11.882072713000071],
              [50.560596880000048, 11.882182792000037],
              [50.560964119000062, 11.882615247000047],
              [50.561299425000072, 11.883087015000058],
              [50.561666664000086, 11.883527331000039],
              [50.561714109000036, 11.883554032000063],
              [50.562429299000087, 11.88503322400004],
              [50.562364812000055, 11.88505968700008],
              [50.562306738000075, 11.88505968700008],
              [50.562268022000069, 11.885040622000076],
              [50.562074442000039, 11.884983426000076],
              [50.561861505000081, 11.884869035000065],
              [50.561629209000046, 11.884621186000061],
              [50.561396913000067, 11.884220815000049],
              [50.561261407000075, 11.883953901000041],
              [50.561125901000082, 11.883801378000044],
              [50.560990396000079, 11.883763248000037],
              [50.560854890000087, 11.883896705000041],
              [50.56079681600005, 11.884163619000049],
              [50.560719384000038, 11.884316142000046],
              [50.560703023000087, 11.884345683000049],
              [50.558148789000086, 11.882328201000064],
              [50.558106057000032, 11.882238017000077],
              [50.557912477000059, 11.88200923100004],
              [50.557660824000038, 11.881742315000054],
              [50.557467244000065, 11.881532594000078],
              [50.557273664000036, 11.881322874000091],
              [50.557138158000043, 11.881113153000058],
              [50.556983294000077, 11.880827170000089],
              [50.556809073000068, 11.880598383000063],
              [50.556654209000044, 11.880388662000087],
              [50.556421913000065, 11.88021707200005],
              [50.556228333000035, 11.880121744000064],
              [50.55595732200004, 11.879912022000042],
              [50.555783100000042, 11.879759497000066],
              [50.555628236000075, 11.87954977600009],
              [50.555512088000057, 11.879435382000054],
              [50.555356835000055, 11.879384412000093],
              [50.555350957000087, 11.87936833200007],
              [50.55527165400008, 11.879358568000043],
              [50.555217133000042, 11.879339042000083],
              [50.555187394000086, 11.879348805000063],
              [50.555162611000071, 11.879387858000086],
              [50.555122960000062, 11.879387858000086],
              [50.555068438000035, 11.879431793000037],
              [50.555058525000049, 11.879485491000082],
              [50.555008961000055, 11.879509899000084],
              [50.55496435200007, 11.879519662000064],
              [50.554929657000059, 11.879519662000064],
              [50.554885049000063, 11.879519662000064],
              [50.554776006000054, 11.879529425000044],
              [50.554771050000056, 11.879578242000036],
              [50.554752759000053, 11.87964579700008],
              [50.554744399000072, 11.879639194000049],
              [50.554741311000043, 11.879680756000084],
              [50.554662007000047, 11.87979791500004],
              [50.55458270400004, 11.879895547000046],
              [50.554503400000044, 11.879895547000046],
              [50.554324967000071, 11.879836967000074],
              [50.554106882000042, 11.879739335000068],
              [50.553829319000045, 11.879544070000065],
              [50.55345262700007, 11.879192593000084],
              [50.553155238000045, 11.87882158900004],
              [50.553016457000069, 11.878587270000082],
              [50.552679416000046, 11.878196738000042],
              [50.552461331000075, 11.877981946000091],
              [50.551807077000035, 11.877054430000044],
              [50.551648469000042, 11.876742003000061],
              [50.551291603000038, 11.876351469000042],
              [50.550914911000064, 11.875980460000051],
              [50.55055804400007, 11.875668032000078],
              [50.550320133000071, 11.875355604000049],
              [50.550082223000061, 11.875023648000081],
              [50.549963267000066, 11.874633111000037],
              [50.54970553000004, 11.874086358000056],
              [50.549289186000067, 11.87334433500007],
              [50.548991798000088, 11.872934269000041],
              [50.548634931000038, 11.872348459000079],
              [50.548377195000057, 11.871801702000084],
              [50.546336787000087, 11.866178742000045],
              [50.543718106000085, 11.85809335600004],
              [50.543222459000049, 11.857390345000056],
              [50.54327918000007, 11.856791768000051],
              [50.542791390000048, 11.856292082000039],
              [50.542422574000057, 11.855613935000065],
              [50.542113244000063, 11.854888200000062],
              [50.541958579000038, 11.854507486000045],
              [50.541661146000081, 11.854055388000063],
              [50.541494584000077, 11.853591393000045],
              [50.541589762000058, 11.852722890000052],
              [50.541696838000064, 11.852306484000053],
              [50.541457953000076, 11.852000534000069],
              [50.54137864900008, 11.851590435000048],
              [50.541319171000055, 11.851277979000088],
              [50.541319171000055, 11.851060819000054],
              [50.540791293000041, 11.849287858000082],
              [50.541092984000045, 11.849122922000049],
              [50.541101973000082, 11.849118007000072],
              [50.541220946000067, 11.848820574000058],
              [50.541114078000078, 11.848545104000038],
              [50.541268535000086, 11.848166222000089],
              [50.54112576700004, 11.847690330000091],
              [50.540951578000033, 11.847408641000072],
              [50.540854077000063, 11.847296595000046],
              [50.540756577000082, 11.847200556000075],
              [50.540594076000048, 11.847088510000049],
              [50.540352443000074, 11.846524394000085],
              [50.540233469000043, 11.846441113000083],
              [50.540126394000083, 11.846322140000041],
              [50.539971729000058, 11.846238859000039],
              [50.539850178000052, 11.846226285000057],
              [50.539626707000082, 11.846203167000056],
              [50.539436350000074, 11.846072297000092],
              [50.539281685000049, 11.845905734000041],
              [50.539030170000046, 11.845484329000044],
              [50.539008047000038, 11.84526328000004],
              [50.538889074000053, 11.844787388000043],
              [50.538746306000064, 11.844466160000081],
              [50.538627333000079, 11.84406165200005],
              [50.538555949000056, 11.843811809000044],
              [50.538460776000079, 11.843398602000093],
              [50.53840146400006, 11.843036374000064],
              [50.538385178000055, 11.842811776000076],
              [50.538365877000047, 11.842545611000048],
              [50.538330290000033, 11.842335284000058],
              [50.538294703000076, 11.842089903000044],
              [50.538211667000041, 11.841844521000041],
              [50.53809304300006, 11.841622509000047],
              [50.53806816000008, 11.841349066000078],
              [50.537972982000042, 11.840908866000063],
              [50.538151441000082, 11.840528152000047],
              [50.537903245000052, 11.840009993000081],
              [50.537879520000047, 11.839869773000089],
              [50.537784621000071, 11.839706184000079],
              [50.537689723000085, 11.839601020000089],
              [50.537604165000062, 11.839064784000072],
              [50.537416888000052, 11.838806442000077],
              [50.537405026000044, 11.838526002000037],
              [50.537369439000088, 11.838140397000075],
              [50.537238953000042, 11.83770805100005],
              [50.537140170000043, 11.837113626000075],
              [50.536847495000075, 11.836083826000049],
              [50.536847495000075, 11.835674847000064],
              [50.536883082000088, 11.835324293000042],
              [50.537056889000041, 11.834638986000073],
              [50.537021197000058, 11.834282067000061],
              [50.537009300000079, 11.834020327000076],
              [50.536906807000037, 11.833781851000083],
              [50.536735662000069, 11.833461153000087],
              [50.536669559000075, 11.833232646000056],
              [50.536539073000085, 11.83304568200009],
              [50.536361138000075, 11.83283534800006],
              [50.536289964000048, 11.832730181000045],
              [50.536195065000072, 11.832461420000072],
              [50.536147615000061, 11.83233288200006],
              [50.536076441000034, 11.832087491000038],
              [50.536081310000043, 11.83136722800009],
              [50.535969680000051, 11.830918961000066],
              [50.535945955000045, 11.830603457000052],
              [50.535910368000032, 11.830334694000044],
              [50.535874781000075, 11.830241211000043],
              [50.535803607000048, 11.830136043000039],
              [50.535708708000072, 11.829914021000093],
              [50.535661258000061, 11.82969199900009],
              [50.535661258000061, 11.829493347000039],
              [50.535649396000053, 11.82928301000004],
              [50.535530772000072, 11.828616942000053],
              [50.535459598000045, 11.828196266000077],
              [50.535343677000071, 11.827750447000085],
              [50.535105731000044, 11.827179376000061],
              [50.534927271000072, 11.826786765000065],
              [50.534830893000048, 11.826455129000067],
              [50.534676682000054, 11.826221419000092],
              [50.534593646000076, 11.82585916700009],
              [50.534581783000078, 11.82556702800008],
              [50.534486884000046, 11.825122976000046],
              [50.534380123000062, 11.824042058000089],
              [50.534237775000065, 11.823831717000076],
              [50.534119151000084, 11.823738232000039],
              [50.534058768000079, 11.823479314000053],
              [50.533975487000077, 11.822955833000037],
              [50.533654260000048, 11.821540054000081],
              [50.533535287000063, 11.820516885000075],
              [50.53096546900008, 11.812117388000047],
              [50.530570540000042, 11.811011767000082],
              [50.53008058000006, 11.80964010200006],
              [50.530108863000066, 11.809047883000062],
              [50.529751944000054, 11.807834358000036],
              [50.52950910900006, 11.807231873000092],
              [50.528919132000055, 11.806085454000083],
              [50.528026835000048, 11.803658404000089],
              [50.527336791000039, 11.801481197000044],
              [50.527277304000052, 11.800731667000036],
              [50.527170229000035, 11.800101110000071],
              [50.527086947000043, 11.799684704000072],
              [50.526943062000043, 11.799200696000071],
              [50.526884693000056, 11.798899482000081],
              [50.526325520000057, 11.798566358000073],
              [50.526222781000058, 11.798440270000071],
              [50.525802038000052, 11.797923903000083],
              [50.525463179000042, 11.797482372000047],
              [50.525225341000066, 11.79730012400006],
              [50.52515958400005, 11.797079194000048],
              [50.524802665000038, 11.79705540000009],
              [50.524302978000037, 11.796841248000078],
              [50.524029340000084, 11.796531918000085],
              [50.523991933000048, 11.796344882000085],
              [50.523934162000046, 11.796056026000088],
              [50.523815189000061, 11.795758594000063],
              [50.523243355000034, 11.795894213000054],
              [50.522922891000064, 11.795556339000086],
              [50.522744431000035, 11.795437366000044],
              [50.522565972000052, 11.795163728000091],
              [50.522318428000062, 11.795711965000066],
              [50.522458896000046, 11.795972745000086],
              [50.522491978000062, 11.796052141000075],
              [50.522577869000088, 11.796258280000075],
              [50.522470793000082, 11.796853146000046],
              [50.522762415000045, 11.797481678000054],
              [50.523279810000076, 11.797793033000062],
              [50.523586899000065, 11.798601887000075],
              [50.523672421000072, 11.799173120000091],
              [50.523983297000086, 11.79966932800005],
              [50.524300414000038, 11.800372275000086],
              [50.524617532000036, 11.80123142900004],
              [50.525146062000033, 11.802064546000054],
              [50.525223264000033, 11.802178634000086],
              [50.52546508000006, 11.803017860000068],
              [50.525568885000041, 11.803184043000044],
              [50.525806724000063, 11.803548529000068],
              [50.526097415000038, 11.804147327000067],
              [50.526282400000071, 11.804902331000051],
              [50.526705224000068, 11.805865610000069],
              [50.527709430000073, 11.808911629000079],
              [50.529002414000047, 11.812521896000078],
              [50.531001161000063, 11.818696598000088],
              [50.533311843000035, 11.827108925000061],
              [50.53470122300007, 11.832128655000076],
              [50.535584520000043, 11.836298225000064],
              [50.537170109000044, 11.843092378000051],
              [50.537234197000032, 11.843859634000069],
              [50.53853145100004, 11.84836105100004],
              [50.538575140000034, 11.84930780500008],
              [50.541093130000036, 11.85840187000008],
              [50.543425495000065, 11.865118650000056],
              [50.543969223000033, 11.866012180000041],
              [50.544131384000082, 11.867151481000064],
              [50.546467657000051, 11.873317125000085],
              [50.546727513000064, 11.873809093000091],
              [50.55103622200005, 11.881966466000051],
              [50.551494274000049, 11.882609301000059],
              [50.554543882000075, 11.88688914700009],
              [50.55522407400008, 11.887843735000047],
              [50.558406356000035, 11.891503669000087],
              [50.558870683000066, 11.892037690000052],
              [50.561327391000077, 11.894863144000055],
              [50.565122632000055, 11.898753563000071],
              [50.571154565000086, 11.90401217200008],
              [50.571440101000064, 11.904511859000081],
              [50.580226880000055, 11.911616657000081],
              [50.585839898000074, 11.915646085000049],
              [50.58802494400004, 11.916670905000046],
              [50.590613586000075, 11.918693945000086],
              [50.593813530000034, 11.921276851000073],
              [50.597197766000079, 11.923809288000086],
              [50.599374090000083, 11.925254477000067],
              [50.601825818000066, 11.92652187300007],
              [50.604812041000059, 11.927402274000087],
              [50.609344914000076, 11.928009036000049],
              [50.613984863000042, 11.928258880000044],
              [50.619517110000061, 11.928556312000069],
              [50.620028694000041, 11.928758566000056],
              [50.621218456000065, 11.928942244000041],
              [50.621578105000083, 11.929218363000075],
              [50.621585369000059, 11.929235847000086],
              [50.621908469000061, 11.929543789000093],
              [50.621967955000059, 11.930126757000039],
              [50.621908469000061, 11.930376600000045],
              [50.621915458000046, 11.930613640000047],
              [50.621999211000059, 11.930642748000082],
              [50.622063258000082, 11.930628194000064],
              [50.622176572000058, 11.930637897000054],
              [50.62226525300008, 11.930691262000039],
              [50.622329300000047, 11.93076403200007],
              [50.622457394000037, 11.930822249000073],
              [50.622729383000035, 11.930900081000061],
              [50.622955431000037, 11.93122130900008],
              [50.623893182000074, 11.931456360000084],
              [50.624282233000088, 11.931312697000067],
              [50.625394379000056, 11.931518741000048],
              [50.625551359000042, 11.931506291000062],
              [50.625620333000086, 11.931632426000078],
              [50.625668017000066, 11.931792379000058],
              [50.625727503000064, 11.931923250000068],
              [50.625955348000048, 11.931933209000078],
              [50.626063735000059, 11.932039939000049],
              [50.626152416000082, 11.932117560000052],
              [50.626381855000034, 11.932161196000038],
              [50.62639375200007, 11.932351553000046],
              [50.62632977700008, 11.932757937000076],
              [50.626405650000038, 11.93307728800005],
              [50.626619801000061, 11.933172467000077],
              [50.626750672000071, 11.933255748000079],
              [50.62676256900005, 11.933410413000047],
              [50.626607904000082, 11.934148046000075],
              [50.626798261000033, 11.934445478000043],
              [50.626774466000086, 11.934838089000039],
              [50.626792886000032, 11.935067160000074],
              [50.626891420000049, 11.935300022000092],
              [50.626999807000061, 11.935435858000062],
              [50.627127902000041, 11.935659017000091],
              [50.627295409000055, 11.935911283000053],
              [50.627474221000057, 11.936146049000058],
              [50.627512099000057, 11.936456123000085],
              [50.627797634000046, 11.93684873400008],
              [50.628333013000088, 11.937062885000046],
              [50.62851147300006, 11.937253242000054],
              [50.628356808000035, 11.937467394000066],
              [50.628192068000033, 11.937745058000075],
              [50.62829060200005, 11.93794881000008],
              [50.628642343000081, 11.938038464000044],
              [50.628618549000066, 11.938502459000063],
              [50.628880289000051, 11.938799892000077],
              [50.628844597000068, 11.939025941000068],
              [50.62920151600008, 11.940025314000081],
              [50.62949317500005, 11.940508220000083],
              [50.629748904000053, 11.940813042000059],
              [50.629748904000053, 11.940956560000075],
              [50.629758757000047, 11.941082691000076],
              [50.629886851000037, 11.941208822000078],
              [50.629955825000081, 11.941267036000056],
              [50.630083919000072, 11.94134465500008],
              [50.630212013000062, 11.941461083000092],
              [50.630438641000069, 11.941616320000037],
              [50.630753949000052, 11.941761855000038],
              [50.630998010000042, 11.941857499000037],
              [50.631259750000083, 11.94192888300006],
              [50.631521491000058, 11.941988370000047],
              [50.631997383000055, 11.942047856000045],
              [50.632342405000088, 11.942071651000049],
              [50.632627940000077, 11.942166829000087],
              [50.63335367600007, 11.942250110000089],
              [50.636316105000049, 11.942309597000076],
              [50.637303581000083, 11.942273905000093],
              [50.637910344000034, 11.942523748000042],
              [50.639278534000084, 11.94247615900008],
              [50.640813286000082, 11.942273905000093],
              [50.643199841000069, 11.941847787000086],
              [50.645607900000073, 11.94136971000006],
              [50.647356804000083, 11.941226942000071],
              [50.648403767000048, 11.940917612000078],
              [50.65036682200008, 11.940774845000078],
              [50.651723115000038, 11.940501207000068],
              [50.653031818000045, 11.940489309000043],
              [50.653828938000061, 11.940798639000093],
              [50.655958555000041, 11.940275158000077],
              [50.658615990000044, 11.939166167000053],
              [50.661383727000043, 11.93828830800004],
              [50.663916810000046, 11.937729574000059],
              [50.666883325000072, 11.937154934000091],
              [50.670056862000081, 11.936539404000087],
              [50.672567193000077, 11.936325252000074],
              [50.676647969000044, 11.936075409000068],
              [50.679229684000063, 11.936134896000056],
              [50.681347404000064, 11.936622685000088],
              [50.682822670000064, 11.937015296000084],
              [50.686165813000059, 11.938240719000078],
              [50.688164560000075, 11.938871276000043],
              [50.689568442000052, 11.939085427000066],
              [50.690246589000083, 11.939335271000061],
              [50.690877146000048, 11.939834957000073],
              [50.691662368000038, 11.940251363000073],
              [50.691900314000065, 11.940382234000083],
              [50.692138260000036, 11.940477412000064],
              [50.692388103000042, 11.940643974000068],
              [50.692792612000062, 11.940715358000091],
              [50.693244709000055, 11.94100089300008],
              [50.693601629000057, 11.941226942000071],
              [50.694018034000067, 11.941476786000067],
              [50.694719975000055, 11.941762321000056],
              [50.695433814000069, 11.942107343000089],
              [50.696085690000075, 11.942312959000049],
              [50.696409393000067, 11.942392878000078],
              [50.697428871000056, 11.942915376000087],
              [50.697558887000071, 11.943141877000073],
              [50.697799408000037, 11.943408314000067],
              [50.698039323000046, 11.943749171000093],
              [50.698301064000077, 11.943903836000061],
              [50.698550907000083, 11.944034706000082],
              [50.698824546000083, 11.944284550000077],
              [50.699157670000034, 11.944522496000047],
              [50.699467, 11.94473664700007],
              [50.699764433000041, 11.944867518000081],
              [50.699990481000043, 11.944986491000066],
              [50.700287914000057, 11.945260129000076],
              [50.700595475000057, 11.94559901100007],
              [50.700906574000044, 11.945831199000054],
              [50.70143005500006, 11.946069145000081],
              [50.702453224000067, 11.94686626500004],
              [50.70297062800006, 11.947197617000086],
              [50.703241215000048, 11.947582465000039],
              [50.703511802000037, 11.947819294000055],
              [50.703872585000056, 11.948026520000042],
              [50.704203303000043, 11.948204141000076],
              [50.704744477000077, 11.948352159000081],
              [50.705284782000035, 11.948734142000092],
              [50.705748777000053, 11.949079163000079],
              [50.706157543000074, 11.949329076000083],
              [50.706518325000047, 11.949477093000041],
              [50.706788912000036, 11.949684317000049],
              [50.707119630000079, 11.949921145000076],
              [50.707420282000044, 11.950098765000064],
              [50.707660804000056, 11.950217179000049],
              [50.707931391000045, 11.950454006000086],
              [50.708141848000082, 11.950779643000089],
              [50.708953609000048, 11.951282899000091],
              [50.709434653000073, 11.951490121000063],
              [50.709705240000062, 11.951667741000051],
              [50.709948851000036, 11.951733159000071],
              [50.710165256000039, 11.951903622000088],
              [50.711971068000082, 11.952695944000084],
              [50.713820732000045, 11.95312748300006],
              [50.714121993000049, 11.953368494000074],
              [50.714485612000033, 11.953473531000043],
              [50.714816329000087, 11.953562340000076],
              [50.715327438000088, 11.953710355000055],
              [50.715849589000072, 11.953957059000061],
              [50.716718092000065, 11.95427828600009],
              [50.717943113000047, 11.954746458000045],
              [50.718514352000057, 11.954953678000038],
              [50.719356179000044, 11.955249707000064],
              [50.719747027000039, 11.955456927000057],
              [50.720258136000041, 11.955693749000091],
              [50.720558788000062, 11.95596017400004],
              [50.721009767000055, 11.956285804000061],
              [50.721581006000065, 11.956670639000038],
              [50.721911724000051, 11.956966665000039],
              [50.722212376000073, 11.957144281000069],
              [50.722362702000055, 11.957233089000056],
              [50.722783615000083, 11.957351499000083],
              [50.723058090000052, 11.957396541000037],
              [50.723927859000071, 11.957704710000087],
              [50.725771941000062, 11.958466137000073],
              [50.729210263000084, 11.959429819000093],
              [50.733702845000039, 11.960283196000091],
              [50.738760509000087, 11.961240845000077],
              [50.743623647000049, 11.962198490000048],
              [50.748852713000076, 11.96364146500008],
              [50.753286280000054, 11.964850154000089],
              [50.754565709000076, 11.965598104000037],
              [50.755467615000043, 11.966473023000049],
              [50.756240940000055, 11.967198759000041],
              [50.75664280400008, 11.967807174000086],
              [50.757002367000041, 11.968305208000061],
              [50.757208911000077, 11.968687854000052],
              [50.757306373000063, 11.968882010000073],
              [50.757606050000049, 11.969071686000063],
              [50.758013638000079, 11.969459247000088],
              [50.758287276000033, 11.969756680000046],
              [50.75852522200006, 11.969970831000069],
              [50.75903680600004, 11.970292058000041],
              [50.759679261000088, 11.97074415600008],
              [50.760464483000078, 11.971089178000057],
              [50.761023656000077, 11.971553173000075],
              [50.761166424000066, 11.971267637000039],
              [50.761178321000045, 11.97101779400009],
              [50.761023656000077, 11.970660875000078],
              [50.760440688000074, 11.970387237000068],
              [50.759964796000077, 11.970315853000045],
              [50.759619774000043, 11.970042215000092],
              [50.759382703000085, 11.969682865000038],
              [50.759774439000068, 11.969173712000043],
              [50.760595353000042, 11.969875653000088],
              [50.761237808000033, 11.97047051800007],
              [50.761499548000074, 11.970601388000091],
              [50.761927851000053, 11.970993999000086],
              [50.762296668000033, 11.971243843000082],
              [50.762522717000081, 11.971410405000086],
              [50.763607092000086, 11.971612765000089],
              [50.763772637000045, 11.971643658000062],
              [50.763844574000075, 11.971609068000078],
              [50.76424174400006, 11.971832518000042],
              [50.764498609000043, 11.972169716000053],
              [50.764691258000084, 11.972759813000039],
              [50.764990935000071, 11.973328834000085],
              [50.765290611000069, 11.973392059000048],
              [50.765504666000083, 11.973054861000037],
              [50.765697315000068, 11.972780888000045],
              [50.765808137000079, 11.972796475000052],
              [50.766042715000083, 11.973163286000045],
              [50.767161237000039, 11.974408168000082],
              [50.767337395000084, 11.974411441000086],
              [50.767460548000088, 11.974431649000053],
              [50.768001723000054, 11.974638854000091],
              [50.768272310000043, 11.974757257000078],
              [50.768572962000064, 11.974934861000065],
              [50.768903680000051, 11.974994062000064],
              [50.769114136000042, 11.974948017000088],
              [50.769114136000042, 11.974786857000083],
              [50.769084071000066, 11.974550052000041],
              [50.769054006000033, 11.974372448000054],
              [50.769066296000062, 11.974324046000049],
              [50.769114136000042, 11.974135642000078],
              [50.769234397000048, 11.973898836000046],
              [50.769264462000081, 11.973691631000065],
              [50.769324593000078, 11.973602828000082],
              [50.769354658000054, 11.973573228000078],
              [50.769444854000085, 11.973573228000078],
              [50.769535049000069, 11.973602828000082],
              [50.769595180000067, 11.973602828000082],
              [50.769805636000058, 11.974017239000091],
              [50.769852618000073, 11.974063495000053],
              [50.769921370000077, 11.973826582000072],
              [50.769986028000062, 11.973928437000041],
              [50.770076224000036, 11.974224444000072],
              [50.770106289000069, 11.974342847000059],
              [50.770106289000069, 11.974550052000041],
              [50.770076224000036, 11.974727656000084],
              [50.770166419000077, 11.975082864000058],
              [50.770346811000081, 11.975319669000044],
              [50.770437006000066, 11.975378870000043],
              [50.77061739800007, 11.975408471000037],
              [50.770918050000034, 11.975408471000037],
              [50.771038311000041, 11.975290068000049],
              [50.771218702000056, 11.975112464000063],
              [50.771282088000078, 11.974972050000076],
              [50.771294905000047, 11.974982728000043],
              [50.771635324000044, 11.974168768000084],
              [50.77197033300007, 11.974548076000076],
              [50.77197033300007, 11.974550052000041],
              [50.77197033300007, 11.974727656000084],
              [50.77197033300007, 11.975171666000051],
              [50.77197033300007, 11.975408471000037],
              [50.771880137000039, 11.975704476000089],
              [50.771759877000079, 11.975941281000075],
              [50.771308898000086, 11.975852479000082],
              [50.770887985000059, 11.976059683000074],
              [50.77074351400006, 11.97620192100004],
              [50.770737659000076, 11.976207685000077],
              [50.770827854000061, 11.976355688000069],
              [50.771038311000041, 11.976562891000071],
              [50.771114881000074, 11.976617718000057],
              [50.771369029000084, 11.976799695000068],
              [50.771669681000049, 11.97706609800008],
              [50.772000398000046, 11.977302902000076],
              [50.772210855000083, 11.977362102000086],
              [50.772361181000065, 11.977362102000086],
              [50.772571638000045, 11.977362102000086],
              [50.773022616000048, 11.977243701000077],
              [50.773112812000079, 11.977184500000078],
              [50.773271636000061, 11.976856127000076],
              [50.773510568000063, 11.976817832000052],
              [50.773593856000048, 11.977036498000075],
              [50.77362392100008, 11.977184500000078],
              [50.773714117000054, 11.977273301000082],
              [50.773804312000038, 11.977273301000082],
              [50.774044834000051, 11.977125299000079],
              [50.774255291000088, 11.976858896000067],
              [50.774452128000064, 11.976703860000043],
              [50.774482558000045, 11.976598999000089],
              [50.774623216000066, 11.976480299000059],
              [50.774814109000033, 11.976371491000066],
              [50.774904531000061, 11.976282466000043],
              [50.77507533000005, 11.97616376600007],
              [50.775276270000063, 11.976114307000046],
              [50.775316458000077, 11.976104415000066],
              [50.775597773000072, 11.976045065000051],
              [50.775624031000063, 11.976054114000078],
              [50.77640910100007, 11.974888990000068],
              [50.778193697000063, 11.975305395000078],
              [50.779204968000045, 11.975543342000037],
              [50.780216239000083, 11.976019234000091],
              [50.780573158000038, 11.976554613000076],
              [50.78110853700008, 11.977327937000041],
              [50.781941348000032, 11.978339208000079],
              [50.781897228000048, 11.979220277000081],
              [50.782078074000083, 11.979220277000081],
              [50.782119664000049, 11.979477918000043],
              [50.782188335000058, 11.97948339900006],
              [50.782754027000067, 11.97948339900006],
              [50.782761269000048, 11.979477458000076],
              [50.78298230200005, 11.979279627000039],
              [50.783062678000078, 11.979180711000083],
              [50.783484651000038, 11.979180711000083],
              [50.783625309000058, 11.979259844000069],
              [50.783786060000068, 11.979358759000093],
              [50.784067376000053, 11.979477458000076],
              [50.784248221000041, 11.979497241000047],
              [50.784589819000075, 11.979536807000045],
              [50.784911322000085, 11.979635723000058],
              [50.785051980000048, 11.979714855000054],
              [50.785353389000079, 11.979853337000065],
              [50.785614610000039, 11.979991818000087],
              [50.785916020000059, 11.980130300000042],
              [50.786213757000041, 11.980267094000055],
              [50.786217429000033, 11.980268781000063],
              [50.786418369000046, 11.980525961000069],
              [50.786398275000067, 11.980743574000087],
              [50.786384220000059, 11.980785086000083],
              [50.78640646100007, 11.980802192000056],
              [50.786378181000032, 11.980941404000077],
              [50.786358087000053, 11.981178800000066],
              [50.786277711000082, 11.98127771500009],
              [50.78615714700004, 11.981356847000086],
              [50.786036584000044, 11.981515111000078],
              [50.786036584000044, 11.981693158000041],
              [50.786056677000033, 11.981851421000044],
              [50.786177241000075, 11.981989902000066],
              [50.786317899000039, 11.982108599000071],
              [50.786518838000063, 11.982266862000074],
              [50.786639402000048, 11.98234599400007],
              [50.786840342000062, 11.982385560000068],
              [50.786843691000058, 11.982385560000068],
              [50.786960906000047, 11.982286645000045],
              [50.787242221000042, 11.982247080000093],
              [50.787804852000079, 11.982247080000093],
              [50.788106261000053, 11.982227297000065],
              [50.788407670000083, 11.982128382000042],
              [50.78860861000004, 11.981970119000039],
              [50.788749268000061, 11.981772289000048],
              [50.789030583000056, 11.981534894000049],
              [50.789191335000055, 11.981337064000058],
              [50.789452556000072, 11.98109966800007],
              [50.789653496000085, 11.980882055000052],
              [50.789565745000061, 11.980061142000068],
              [50.789496137000071, 11.979409966000048],
              [50.790209975000039, 11.978815100000077],
              [50.792054057000087, 11.978101262000052],
              [50.793189649000055, 11.977688320000084],
              [50.794017113000052, 11.977387424000085],
              [50.794195572000035, 11.978517668000052],
              [50.792886869000085, 11.979469452000046],
              [50.79229887300005, 11.979440510000074],
              [50.791843737000079, 11.979418108000061],
              [50.791783455000086, 11.979576373000043],
              [50.791622703000087, 11.97979398700005],
              [50.791569502000073, 11.979938028000049],
              [50.791542328000048, 11.980011601000058],
              [50.791441858000042, 11.980248998000093],
              [50.791160542000057, 11.980407263000075],
              [50.790577818000088, 11.980783140000085],
              [50.790135751000037, 11.98111945100004],
              [50.789874529000087, 11.981337064000058],
              [50.789733872000056, 11.981554677000076],
              [50.789613308000071, 11.981811855000046],
              [50.789432462000036, 11.982088816000044],
              [50.789110959000084, 11.982405343000039],
              [50.789110959000084, 11.982642737000049],
              [50.789251617000048, 11.98284056600005],
              [50.789452556000072, 11.98301861200008],
              [50.789653496000085, 11.983176875000083],
              [50.789794153000059, 11.983295572000088],
              [50.789814247000038, 11.983434051000074],
              [50.789814247000038, 11.983750576000091],
              [50.789834341000073, 11.98404731800008],
              [50.789834341000073, 11.984344060000069],
              [50.789713778000078, 11.984522104000064],
              [50.789553026000078, 11.984482539000055],
              [50.789392274000079, 11.984403408000048],
              [50.789251617000048, 11.984344060000069],
              [50.789090865000048, 11.984264929000062],
              [50.788930113000049, 11.984205580000037],
              [50.788749268000061, 11.984067101000051],
              [50.788628704000075, 11.983928622000064],
              [50.788588516000061, 11.983730794000053],
              [50.788588516000061, 11.983631880000075],
              [50.788508140000033, 11.983453834000045],
              [50.788488046000055, 11.983275789000061],
              [50.78834738900008, 11.983236223000063],
              [50.788226825000038, 11.983236223000063],
              [50.787503442000059, 11.98325600600009],
              [50.787342691000049, 11.983354920000068],
              [50.787101563000078, 11.983453834000045],
              [50.78686043600004, 11.983374703000038],
              [50.786559026000077, 11.98325600600009],
              [50.786358087000053, 11.98325600600009],
              [50.786116959000083, 11.98325600600009],
              [50.785875832000045, 11.983236223000063],
              [50.785674892000088, 11.983236223000063],
              [50.785554329000036, 11.983315355000059],
              [50.785554329000036, 11.983473617000072],
              [50.785514141000078, 11.983612097000048],
              [50.785574423000071, 11.983750576000091],
              [50.785755268000059, 11.983750576000091],
              [50.785936114000037, 11.983730794000053],
              [50.786076771000069, 11.983750576000091],
              [50.786257617000047, 11.983849490000068],
              [50.786317899000039, 11.98404731800008],
              [50.786197335000054, 11.984205580000037],
              [50.785996396000087, 11.98436384200005],
              [50.785982043000047, 11.984377973000051],
              [50.786056677000033, 11.984561670000062],
              [50.786197335000054, 11.984621018000041],
              [50.786478651000039, 11.984779280000055],
              [50.786729267000055, 11.984976668000058],
              [50.786780060000069, 11.985016673000075],
              [50.786980999000036, 11.985234282000079],
              [50.787242221000042, 11.985392544000092],
              [50.787423067000077, 11.98549145700008],
              [50.787824946000057, 11.985649718000047],
              [50.787954709000076, 11.985724241000071],
              [50.788867387000039, 11.985766885000089],
              [50.789264330000037, 11.985785431000068],
              [50.789423204000059, 11.985792854000067],
              [50.789432462000036, 11.98584754500007],
              [50.789331992000086, 11.986282763000077],
              [50.788950207000084, 11.986441023000054],
              [50.788427764000062, 11.986500371000091],
              [50.787945509000053, 11.986638849000087],
              [50.787583818000087, 11.986717980000037],
              [50.787141751000036, 11.986777327000084],
              [50.786759966000034, 11.986856458000091],
              [50.786438463000081, 11.986955370000089],
              [50.786177241000075, 11.98697515300006],
              [50.786016490000065, 11.987034501000039],
              [50.785936114000037, 11.987153196000065],
              [50.785927336000043, 11.98716702300004],
              [50.78573842600008, 11.987191820000078],
              [50.784497039000087, 11.987317102000077],
              [50.783093157000053, 11.987126745000069],
              [50.781225280000058, 11.98684121000008],
              [50.780844566000042, 11.987019669000063],
              [50.778584078000051, 11.986508085000082],
              [50.776276001000042, 11.985960809000062],
              [50.77411552500007, 11.984989437000081],
              [50.773377882000034, 11.984145641000055],
              [50.772135739000078, 11.982724742000073],
              [50.770176383000035, 11.980776132000074],
              [50.768766073000052, 11.978813092000053],
              [50.768471369000054, 11.977359058000047],
              [50.766484519000073, 11.976502452000091],
              [50.765972935000036, 11.975848100000064],
              [50.764867839000033, 11.97488751700007],
              [50.76442628500007, 11.974503704000085],
              [50.76365313000008, 11.973908427000083],
              [50.763620985000045, 11.973876779000079],
              [50.763150065000048, 11.973539582000058],
              [50.762487025000041, 11.97314741200006],
              [50.762296668000033, 11.973575715000038],
              [50.762807578000036, 11.974087527000052],
              [50.76346260400004, 11.974563191000072],
              [50.763867112000071, 11.975003391000087],
              [50.765306686000088, 11.976383479000049],
              [50.766966712000055, 11.978477937000036],
              [50.76843567700007, 11.980476151000062],
              [50.770176383000035, 11.983026428000073],
              [50.772135739000078, 11.985389738000038],
              [50.774408124000047, 11.987602637000066],
              [50.775468631000081, 11.988023627000075],
              [50.775708290000068, 11.988023627000075],
              [50.776157028000057, 11.987947659000042],
              [50.776753176000057, 11.987944497000058],
              [50.777358656000047, 11.98786437800004],
              [50.777798856000061, 11.987828686000057],
              [50.778381824000064, 11.987804891000053],
              [50.779024279000055, 11.987721610000051],
              [50.779607247000058, 11.987757302000091],
              [50.780154523000078, 11.987804891000053],
              [50.780939745000069, 11.987781097000038],
              [50.781617891000053, 11.988030940000044],
              [50.782331730000067, 11.988066632000084],
              [50.782942114000036, 11.988241234000043],
              [50.783464557000059, 11.98830058100009],
              [50.78384268700006, 11.988364065000042],
              [50.785413132000087, 11.98828078400004],
              [50.786019894000049, 11.988197502000048],
              [50.786436300000048, 11.988078529000063],
              [50.786947884000085, 11.988019043000065],
              [50.787197727000034, 11.987923864000038],
              [50.787590338000086, 11.98790007000008],
              [50.787959155000067, 11.987828686000057],
            ],
          ],
          [
            [
              [51.190063079000083, 10.536078038000085],
              [51.190057726000077, 10.53607155800006],
              [51.190056552000044, 10.536071677000052],
              [51.19004013700004, 10.536073345000091],
              [51.189701361000061, 10.536107765000054],
              [51.189229626000042, 10.536422255000048],
              [51.188954448000061, 10.536618811000039],
              [51.188757892000069, 10.537208479000071],
              [51.188993759000084, 10.537916081000049],
              [51.189386871000067, 10.538034015000051],
              [51.189819295000063, 10.538387816000068],
              [51.189544116000036, 10.538859551000087],
              [51.189701361000061, 10.539567153000064],
              [51.18950480500007, 10.540235444000075],
              [51.189111693000086, 10.541060979000065],
              [51.188915136000048, 10.541689959000053],
              [51.188482713000042, 10.542436872000053],
              [51.187775111000064, 10.543812765000041],
              [51.187381999000081, 10.545031413000061],
              [51.187185442000043, 10.546328684000059],
              [51.186753019000037, 10.547272153000051],
              [51.186399218000076, 10.548019067000041],
              [51.186202662000085, 10.548923225000067],
              [51.185495060000051, 10.549630827000044],
              [51.184787458000073, 10.550377740000044],
              [51.184748146000061, 10.551124654000091],
              [51.18400123300006, 10.552264679000075],
              [51.18325432000006, 10.553011593000065],
              [51.182939830000066, 10.554072996000059],
              [51.182546718000083, 10.554623353000068],
              [51.181917738000038, 10.555409577000091],
              [51.181603248000044, 10.556431669000062],
              [51.181996360000085, 10.557414450000067],
              [51.18176049300007, 10.55820067500008],
              [51.181367381000086, 10.558711721000066],
              [51.181444173000045, 10.558911379000051],
              [51.181563937000078, 10.559222766000062],
              [51.181210136000061, 10.559851746000049],
              [51.180384600000082, 10.560362792000092],
              [51.180934957000034, 10.560952460000067],
              [51.18054184500005, 10.56166006300009],
              [51.180227355000056, 10.562210420000042],
              [51.180345289000059, 10.562878711000053],
              [51.180345289000059, 10.563547001000074],
              [51.180699090000076, 10.563271823000036],
              [51.181131513000082, 10.562210420000042],
              [51.181406692000053, 10.561109705000092],
              [51.181839116000049, 10.560441415000071],
              [51.18250740600007, 10.559891057000073],
              [51.182310850000079, 10.559340700000064],
              [51.182625340000072, 10.558790343000055],
              [51.182821896000064, 10.557886185000086],
              [51.182861207000087, 10.557021338000084],
              [51.183175697000081, 10.555684756000062],
              [51.18400123300006, 10.554544730000089],
              [51.184590901000036, 10.553011593000065],
              [51.185377126000049, 10.551871567000092],
              [51.185927483000057, 10.55081016400004],
              [51.186399218000076, 10.549906006000072],
              [51.186910264000062, 10.548726669000075],
              [51.187499932000037, 10.548176312000066],
              [51.187853733000054, 10.547350776000087],
              [51.187853733000054, 10.546485929000085],
              [51.187860361000048, 10.54642958900007],
              [51.188010978000079, 10.545149347000063],
              [51.188836514000059, 10.543498276000037],
              [51.18903307000005, 10.542397561000087],
              [51.189583427000059, 10.541414780000082],
              [51.190133784000068, 10.540392689000043],
              [51.190684142000066, 10.539488530000085],
              [51.190802075000079, 10.53811263700004],
              [51.190802075000079, 10.536972612000056],
              [51.190063079000083, 10.536078038000085],
            ],
          ],
          [
            [
              [50.161656505000053, 11.551770672000089],
              [50.161406661000058, 11.551711185000045],
              [50.161406661000058, 11.551984823000055],
              [50.161593057000061, 11.552152579000051],
              [50.161644608000074, 11.552198975000067],
              [50.161930143000063, 11.552389332000075],
              [50.162298959000054, 11.55250830500006],
              [50.162655878000066, 11.552627278000045],
              [50.163036592000083, 11.552746251000087],
              [50.163429203000078, 11.552793840000049],
              [50.163643355000033, 11.55292471000007],
              [50.163714739000056, 11.552996094000036],
              [50.163702841000088, 11.553103170000043],
              [50.163905095000075, 11.553245937000042],
              [50.164214425000068, 11.553364911000074],
              [50.164333398000053, 11.553519576000042],
              [50.164630831000068, 11.553590959000076],
              [50.165011545000084, 11.553662343000042],
              [50.165392259000043, 11.553721830000086],
              [50.165737280000087, 11.553828905000046],
              [50.166236967000032, 11.553935981000052],
              [50.166712859000086, 11.554031160000079],
              [50.167141162000064, 11.554066852000062],
              [50.16775982200005, 11.554066852000062],
              [50.168140536000067, 11.554114441000081],
              [50.168378482000037, 11.554162030000043],
              [50.168985245000044, 11.554221517000087],
              [50.169206501000076, 11.554216371000052],
              [50.169496829000082, 11.554209619000062],
              [50.169818056000054, 11.554173927000079],
              [50.170079797000085, 11.554114441000081],
              [50.170341538000059, 11.554090646000077],
              [50.170531894000078, 11.554007365000075],
              [50.170418362000078, 11.553831907000074],
              [50.170407315000034, 11.553814834000093],
              [50.17038369900007, 11.553805677000071],
              [50.169829953000033, 11.553590959000076],
              [50.169092320000061, 11.553531473000078],
              [50.168366585000058, 11.553507678000074],
              [50.167795514000034, 11.553471986000091],
              [50.166879422000079, 11.553448192000076],
              [50.166141789000051, 11.553281629000082],
              [50.165546924000068, 11.553210246000049],
              [50.165071031000082, 11.553103170000043],
              [50.164535653000087, 11.552865224000072],
              [50.164012171000081, 11.552805737000085],
              [50.163560074000088, 11.552674867000064],
              [50.16333402500004, 11.552460715000052],
              [50.162786749000077, 11.552282256000069],
              [50.16231085700008, 11.552127591000044],
              [50.162049116000048, 11.551913439000089],
              [50.161656505000053, 11.551770672000089],
            ],
          ],
          [
            [
              [50.171733522000068, 11.555494528000054],
              [50.171499279000045, 11.555420909000077],
              [50.171320381000044, 11.55536468400004],
              [50.171098801000085, 11.555260562000058],
              [50.170865019000075, 11.555208993000065],
              [50.170508100000063, 11.555125712000063],
              [50.170056002000081, 11.555137609000042],
              [50.169829953000033, 11.555137609000042],
              [50.169592007000062, 11.555137609000042],
              [50.169389753000075, 11.555125712000063],
              [50.16923508800005, 11.555113814000038],
              [50.16899714200008, 11.555042431000061],
              [50.168699709000066, 11.55494725200009],
              [50.168426071000056, 11.554923458000076],
              [50.168104844000084, 11.554875868000067],
              [50.167866898000057, 11.55491156000005],
              [50.167498082000066, 11.555018636000057],
              [50.167593260000046, 11.555149506000078],
              [50.167807412000059, 11.55536365800009],
              [50.168081050000069, 11.55532796600005],
              [50.168402277000041, 11.555351760000065],
              [50.168842477000055, 11.555304171000046],
              [50.169175602000053, 11.555304171000046],
              [50.169449240000063, 11.555339863000086],
              [50.169568213000048, 11.555435042000056],
              [50.169818056000054, 11.555542117000073],
              [50.170067900000049, 11.555554015000041],
              [50.170222564000085, 11.555458836000071],
              [50.170282051000072, 11.55550642500009],
              [50.170377229000053, 11.555613501000039],
              [50.170460511000044, 11.555565912000077],
              [50.170460511000044, 11.555446939000092],
              [50.170519997000042, 11.555423144000088],
              [50.17085312200004, 11.555494528000054],
              [50.171257630000071, 11.555672988000083],
              [50.171519371000045, 11.555720577000045],
              [50.171793009000055, 11.555720577000045],
              [50.171923879000076, 11.55560160400006],
              [50.171733522000068, 11.555494528000054],
            ],
          ],
          [
            [
              [50.161263894000058, 11.551223396000069],
              [50.161323380000056, 11.551104423000083],
              [50.161323380000056, 11.550973552000073],
              [50.161180613000056, 11.55093786000009],
              [50.161085434000086, 11.550997347000077],
              [50.160871283000063, 11.550925963000054],
              [50.160859385000037, 11.550747503000082],
              [50.160704720000069, 11.550533352000059],
              [50.160514364000051, 11.550426276000053],
              [50.160216931000036, 11.550366790000055],
              [50.159788628000058, 11.550366790000055],
              [50.159538785000052, 11.550497660000076],
              [50.159326558000032, 11.550635607000061],
              [50.159314582000036, 11.550643391000051],
              [50.15932463300004, 11.550652325000044],
              [50.159538785000052, 11.550842682000052],
              [50.159657758000037, 11.551092525000058],
              [50.159836217000077, 11.551211498000043],
              [50.15987190900006, 11.551044936000039],
              [50.159883806000039, 11.55093786000009],
              [50.159978985000066, 11.550961655000037],
              [50.160133650000034, 11.551021141000092],
              [50.160300212000038, 11.550973552000073],
              [50.160395391000066, 11.550818887000048],
              [50.160573850000048, 11.55079509300009],
              [50.160573850000048, 11.550925963000054],
              [50.160466774000042, 11.551163909000081],
              [50.160442980000084, 11.551163909000081],
              [50.160252623000076, 11.551401855000051],
              [50.160300212000038, 11.551508931000058],
              [50.160502466000082, 11.551520828000037],
              [50.160550055000044, 11.551556520000076],
              [50.160502466000082, 11.551651699000047],
              [50.160514364000051, 11.551734980000049],
              [50.160645234000071, 11.551770672000089],
              [50.160966461000044, 11.551830158000087],
              [50.161097332000054, 11.55172308200008],
              [50.160918872000082, 11.551580315000081],
              [50.161144921000073, 11.551497034000079],
              [50.161263894000058, 11.551378061000037],
              [50.161263894000058, 11.551223396000069],
            ],
          ],
          [
            [
              [50.16062183400004, 11.552614936000055],
              [50.160478672000067, 11.552603483000041],
              [50.160431082000059, 11.552698661000079],
              [50.160431082000059, 11.552805737000085],
              [50.160550055000044, 11.552900916000056],
              [50.160550055000044, 11.553043683000055],
              [50.160669029000076, 11.55306747800006],
              [50.160823693000054, 11.553103170000043],
              [50.160978358000079, 11.553186451000045],
              [50.161144921000073, 11.553174554000066],
              [50.161156818000052, 11.553055581000081],
              [50.160990256000048, 11.552889018000087],
              [50.160788002000061, 11.552722456000083],
              [50.160743604000061, 11.552693728000065],
              [50.16062183400004, 11.552614936000055],
            ],
          ],
        ],
      },
    },
  ],
};
