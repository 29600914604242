export const somaliland = {
  type: "FeatureCollection",
  name: "somaliland",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84",
    },
  },
  features: [
    {
      type: "Feature",
      properties: {
        OBJECTID_1: 1,
        Name: "Somaliland",
        Shape_Leng: 15.4333574572,
        Shape_Area: 7.8645550265599997,
        "area-km2": 95347.643905499994,
        style: {
          color: "black",
          fill: false,
          opacity: 1,
          clickable: false,
        },
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [43.312893004000045, 11.461963427000057],
              [43.315224341000089, 11.457307229000037],
              [43.316164228000048, 11.453393015000074],
              [43.31692565600008, 11.449276548000057],
              [43.317168790000039, 11.447285655000087],
              [43.318829225000059, 11.442316624000057],
              [43.320625718000088, 11.43847379500005],
              [43.32375470900007, 11.433060521000073],
              [43.325705867000067, 11.43016947600006],
              [43.327347695000071, 11.428848875000085],
              [43.327692717000048, 11.428515750000088],
              [43.32790686900006, 11.428194523000059],
              [43.328216198000064, 11.428039858000091],
              [43.328715885000065, 11.427730528000041],
              [43.329322648000073, 11.42721894400006],
              [43.330107870000063, 11.426409927000066],
              [43.330322021000086, 11.426207673000079],
              [43.330524276000062, 11.426088700000037],
              [43.330786016000047, 11.425862651000045],
              [43.330840025000043, 11.425569928000073],
              [43.330940316000067, 11.425515961000087],
              [43.331101455000066, 11.424840343000085],
              [43.331101455000066, 11.424607068000057],
              [43.331118884000034, 11.424417934000076],
              [43.331293171000084, 11.424245994000046],
              [43.331484886000055, 11.424091248000082],
              [43.331728887000054, 11.423953696000069],
              [43.332042604000037, 11.423798950000048],
              [43.332216890000041, 11.423747368000079],
              [43.332617750000054, 11.423712980000062],
              [43.332757179000055, 11.423712980000062],
              [43.333001181000043, 11.423764562000088],
              [43.333436898000059, 11.42373017400007],
              [43.333750614000053, 11.423609816000067],
              [43.333907472000078, 11.423455070000045],
              [43.334151474000066, 11.423403488000076],
              [43.334343189000037, 11.423420682000085],
              [43.334517476000087, 11.423541040000089],
              [43.334622048000085, 11.423627010000075],
              [43.334761477000086, 11.423781756000039],
              [43.334918336000044, 11.42393650200006],
              [43.335045252000043, 11.424256515000081],
              [43.335318890000053, 11.424232721000067],
              [43.335604425000042, 11.424351694000052],
              [43.335949447000075, 11.424470667000037],
              [43.336330160000045, 11.42468481800006],
              [43.336806053000089, 11.424887072000047],
              [43.337445494000065, 11.425312019000046],
              [43.337898640000049, 11.425741867000056],
              [43.338072926000052, 11.425948194000057],
              [43.338194927000075, 11.426154520000068],
              [43.33840407100007, 11.426360847000069],
              [43.338473786000066, 11.426601561000041],
              [43.338543501000061, 11.426825081000061],
              [43.338630644000034, 11.426962631000038],
              [43.338717787000064, 11.427134569000089],
              [43.338770073000035, 11.427272120000055],
              [43.338857217000054, 11.427512833000037],
              [43.338892074000057, 11.427615996000043],
              [43.338944360000085, 11.427770740000085],
              [43.339031504000047, 11.428011452000078],
              [43.33906636100005, 11.428234971000052],
              [43.339101218000053, 11.42854445800009],
              [43.339118647000078, 11.428699202000075],
              [43.339147335000064, 11.429312139000046],
              [43.339185514000064, 11.430127835000064],
              [43.339542433000076, 11.430913057000055],
              [43.340684574000079, 11.431722074000049],
              [43.342163958000071, 11.431803136000042],
              [43.344158587000038, 11.431912431000057],
              [43.345231651000063, 11.431604210000046],
              [43.346395281000071, 11.431269976000067],
              [43.347275681000042, 11.430603727000062],
              [43.348118835000037, 11.429615901000091],
              [43.348394028000087, 11.428271856000038],
              [43.348298849000059, 11.426986947000046],
              [43.348441617000049, 11.42567824300005],
              [43.348432979000052, 11.425652515000081],
              [43.347881578000056, 11.424010211000052],
              [43.347710334000055, 11.423355576000063],
              [43.347924388000081, 11.42265870500006],
              [43.348159849000069, 11.421961832000079],
              [43.348480930000051, 11.421476131000077],
              [43.348322644000064, 11.419729591000078],
              [43.348339267000085, 11.418053665000059],
              [43.348510299000054, 11.417125637000083],
              [43.348681330000034, 11.416155423000077],
              [43.349155455000073, 11.415279999000063],
              [43.349365456000044, 11.414151077000042],
              [43.350220614000079, 11.411788785000056],
              [43.351415943000063, 11.409902417000069],
              [43.354223707000074, 11.407737107000059],
              [43.355950166000071, 11.406641698000044],
              [43.358673299000088, 11.405405235000046],
              [43.361765235000064, 11.403140342000086],
              [43.365668915000072, 11.400289394000083],
              [43.37107029100008, 11.394769045000089],
              [43.373045244000082, 11.393008244000043],
              [43.373949439000057, 11.392294405000086],
              [43.374806793000062, 11.391483343000061],
              [43.375438, 11.391311196000061],
              [43.376011825000035, 11.390622606000079],
              [43.376700415000073, 11.390220928000076],
              [43.377618535000067, 11.389934016000041],
              [43.378077595000036, 11.389532338000038],
              [43.378364508000061, 11.389188043000047],
              [43.378995715000087, 11.388843748000056],
              [43.379340010000078, 11.388384688000087],
              [43.38006465400008, 11.387916197000038],
              [43.380730903000085, 11.387511689000064],
              [43.381692693000048, 11.386548448000042],
              [43.382151753000073, 11.386146771000085],
              [43.382668195000065, 11.385745093000082],
              [43.383242020000068, 11.385113886000056],
              [43.383815845000072, 11.384769591000065],
              [43.384274905000041, 11.384195766000062],
              [43.384504435000053, 11.383621941000058],
              [43.384906113000056, 11.382703821000064],
              [43.385193025000035, 11.38195784800007],
              [43.385365173000082, 11.381498788000044],
              [43.38530779000007, 11.38058066800005],
              [43.385652085000061, 11.380006843000047],
              [43.386168528000042, 11.379719931000068],
              [43.387545708000061, 11.379892078000069],
              [43.388463828000056, 11.379719931000068],
              [43.389324565000038, 11.379318253000065],
              [43.390242685000032, 11.379203488000087],
              [43.391046040000049, 11.378744428000061],
              [43.392365838000046, 11.378629663000083],
              [43.394087313000057, 11.37822798600007],
              [43.395980935000068, 11.377883691000079],
              [43.397013820000041, 11.377711543000089],
              [43.398276235000083, 11.377941073000045],
              [43.399710798000058, 11.378859193000039],
              [43.400504224000088, 11.380801609000059],
              [43.400268967000045, 11.381529784000065],
              [43.400004537000086, 11.382348258000093],
              [43.401547038000047, 11.382416908000039],
              [43.402178245000073, 11.382646438000052],
              [43.402465158000041, 11.382474291000051],
              [43.402646854000068, 11.382590693000054],
              [43.403203150000081, 11.382822483000041],
              [43.409140940000043, 11.383353622000072],
              [43.409623990000057, 11.383347589000039],
              [43.409652508000079, 11.38333851200008],
              [43.409682062000059, 11.38332601500008],
              [43.410018510000043, 11.383183743000075],
              [43.410210225000071, 11.38306336800008],
              [43.410384512000064, 11.383011778000082],
              [43.410767943000053, 11.382977385000061],
              [43.411133945000074, 11.382925796000052],
              [43.411360518000038, 11.382925796000052],
              [43.411684134000041, 11.382953562000068],
              [43.411761378000051, 11.382960189000073],
              [43.41223195200007, 11.382960189000073],
              [43.412423668000088, 11.382960189000073],
              [43.412650240000062, 11.382994582000038],
              [43.413103386000046, 11.383183743000075],
              [43.413329959000066, 11.38325252900006],
              [43.413417065000033, 11.383365148000053],
              [43.413929820000078, 11.383395810000081],
              [43.414913251000087, 11.383859880000045],
              [43.415811166000083, 11.383691127000077],
              [43.416024955000069, 11.383142680000049],
              [43.417307691000076, 11.38250985600007],
              [43.418547669000077, 11.382594232000088],
              [43.419231794000041, 11.383016116000078],
              [43.419663842000034, 11.38306370500004],
              [43.423786193000069, 11.379890648000071],
              [43.423939628000085, 11.379772546000083],
              [43.426586146000034, 11.377109240000038],
              [43.428558069000076, 11.374496037000085],
              [43.429842978000067, 11.372663852000073],
              [43.431032708000089, 11.371045818000084],
              [43.431002639000042, 11.370114095000076],
              [43.431011946000069, 11.36999011000006],
              [43.43160570100008, 11.369140593000054],
              [43.432230183000058, 11.368247111000073],
              [43.433427403000053, 11.367234537000058],
              [43.433598434000089, 11.367698521000079],
              [43.434111529000063, 11.36845795000005],
              [43.434752896000077, 11.369048616000043],
              [43.435351506000075, 11.368584522000049],
              [43.435423514000036, 11.368016107000074],
              [43.435351506000075, 11.367317430000071],
              [43.434756791000041, 11.365836389000037],
              [43.434453591000079, 11.36508131700009],
              [43.433940497000037, 11.363836680000077],
              [43.434004634000075, 11.363541342000076],
              [43.434453591000079, 11.363794489000043],
              [43.434774275000052, 11.364785980000079],
              [43.435092820000079, 11.365645126000061],
              [43.435094959000082, 11.365650895000044],
              [43.435586675000081, 11.366262662000054],
              [43.435971495000047, 11.366325948000053],
              [43.436484590000077, 11.366515807000042],
              [43.436984821000067, 11.366969916000073],
              [43.43710457800006, 11.366939089000084],
              [43.437532157000078, 11.367065661000083],
              [43.438088009000069, 11.366685945000086],
              [43.438729377000072, 11.365968701000043],
              [43.439156956000033, 11.365504601000055],
              [43.440268660000072, 11.365420219000043],
              [43.440696238000044, 11.365251456000067],
              [43.440995543000042, 11.365378029000055],
              [43.441936216000045, 11.364956119000055],
              [43.442705858000068, 11.364238871000055],
              [43.443518257000051, 11.363690386000087],
              [43.44437341400004, 11.363521621000075],
              [43.444629961000089, 11.364027915000065],
              [43.444629961000089, 11.364407635000077],
              [43.443732046000036, 11.364702973000078],
              [43.443133436000039, 11.365124883000078],
              [43.442834131000041, 11.365420219000043],
              [43.442406553000069, 11.365504601000055],
              [43.442064490000064, 11.365673365000077],
              [43.441893458000038, 11.366095274000088],
              [43.441722427000059, 11.366348418000086],
              [43.441209333000074, 11.36655937200004],
              [43.440781754000056, 11.366812517000085],
              [43.440225902000066, 11.366939089000084],
              [43.439883839000061, 11.36723442400006],
              [43.440439691000051, 11.367529758000046],
              [43.441294848000041, 11.367529758000046],
              [43.442363795000063, 11.36723442400006],
              [43.443860320000056, 11.365799938000066],
              [43.445869939000033, 11.364196680000077],
              [43.447323706000077, 11.36339504700004],
              [43.447794043000044, 11.362973135000061],
              [43.447794043000044, 11.362002733000054],
              [43.447794043000044, 11.361369861000071],
              [43.447494738000046, 11.359893153000087],
              [43.447395136000068, 11.359760471000072],
              [43.446735595000064, 11.358881877000044],
              [43.446783440000047, 11.358834665000074],
              [43.447168765000072, 11.358792418000064],
              [43.44755409000004, 11.359130396000069],
              [43.447813534000034, 11.359329514000081],
              [43.447939415000064, 11.359426126000074],
              [43.448431775000074, 11.359616238000058],
              [43.448624437000035, 11.359679608000079],
              [43.449245239000049, 11.359827473000053],
              [43.44948071500005, 11.359764102000042],
              [43.449694785000077, 11.359489496000037],
              [43.449994482000079, 11.359109272000069],
              [43.450122923000038, 11.358686800000044],
              [43.450336993000064, 11.358222080000075],
              [43.450429760000077, 11.357962717000078],
              [43.451513976000058, 11.357741365000038],
              [43.451941555000076, 11.357572597000058],
              [43.452240860000074, 11.357277252000074],
              [43.451898797000069, 11.356728753000084],
              [43.451214671000059, 11.356391215000087],
              [43.450637079000046, 11.35642121300009],
              [43.450402272000076, 11.356433408000044],
              [43.450158865000049, 11.356913782000049],
              [43.450060209000071, 11.357108483000047],
              [43.449846420000085, 11.357614789000081],
              [43.449499084000081, 11.357593368000039],
              [43.449162294000075, 11.357572597000058],
              [43.44869195800004, 11.357192868000084],
              [43.448307137000086, 11.356475600000067],
              [43.448349895000035, 11.355800523000084],
              [43.448649200000034, 11.355209829000046],
              [43.449333326000044, 11.35495667400005],
              [43.450017451000065, 11.354702954000061],
              [43.44967538800006, 11.354281029000049],
              [43.449290568000038, 11.353732524000065],
              [43.450188483000034, 11.353184019000082],
              [43.450915366000061, 11.353141826000069],
              [43.45177052300005, 11.352973055000064],
              [43.451856039000063, 11.352719898000089],
              [43.452198102000068, 11.35250893400007],
              [43.452112586000055, 11.35217139100007],
              [43.452411891000054, 11.351749461000054],
              [43.452753954000059, 11.35225577600005],
              [43.453651869000055, 11.35259331900005],
              [43.454202655000074, 11.35274860800007],
              [43.454250479000052, 11.352762091000045],
              [43.454241452000076, 11.352708646000053],
              [43.454164963000039, 11.35225577600005],
              [43.454250479000052, 11.351665075000085],
              [43.454635300000064, 11.351158759000043],
              [43.455062878000035, 11.350736828000038],
              [43.455490457000053, 11.350441476000071],
              [43.455362183000034, 11.350188317000061],
              [43.455020120000086, 11.349766384000077],
              [43.455447699000047, 11.349555418000079],
              [43.455960793000088, 11.349639804000049],
              [43.456644919000041, 11.350272703000087],
              [43.457286287000045, 11.350103930000046],
              [43.457799381000086, 11.349639804000049],
              [43.458440749000033, 11.349344451000093],
              [43.459253148000073, 11.349049097000091],
              [43.45916763300005, 11.348795937000091],
              [43.458953843000074, 11.348289616000045],
              [43.459595211000078, 11.348163035000084],
              [43.460920705000035, 11.348078648000069],
              [43.461946893000061, 11.348163035000084],
              [43.463272387000075, 11.34820522800004],
              [43.464854428000081, 11.348458389000086],
              [43.466650257000083, 11.34862716300006],
              [43.467462657000056, 11.348795937000091],
              [43.468745392000073, 11.349091291000093],
              [43.470370191000086, 11.349724191000064],
              [43.471310864000088, 11.350610248000066],
              [43.471310864000088, 11.351791654000067],
              [43.470968801000083, 11.35259331900005],
              [43.470070886000087, 11.35259331900005],
              [43.469771581000089, 11.352551127000083],
              [43.469643307000069, 11.354281029000049],
              [43.469615958000077, 11.354780299000083],
              [43.469557792000046, 11.355842150000058],
              [43.468916424000042, 11.356770381000047],
              [43.468446087000075, 11.357698608000078],
              [43.468403330000058, 11.357740801000091],
              [43.468256906000079, 11.358270584000081],
              [43.468830908000086, 11.35892195100007],
              [43.469686065000076, 11.35883756700008],
              [43.470498465000048, 11.358542223000086],
              [43.470669496000085, 11.358120303000078],
              [43.470455707000042, 11.357951535000041],
              [43.470433742000068, 11.35793564100004],
              [43.469771581000089, 11.358204687000068],
              [43.469301245000054, 11.358289071000058],
              [43.469001940000055, 11.358204687000068],
              [43.468959182000049, 11.358035919000088],
              [43.469472276000033, 11.357487422000077],
              [43.469833247000054, 11.357300844000065],
              [43.469857097000045, 11.357065501000079],
              [43.470028128000081, 11.356517002000089],
              [43.470583980000072, 11.355799732000037],
              [43.472807389000081, 11.356348233000062],
              [43.47588266300005, 11.356939387000068],
              [43.476099744000066, 11.356981116000043],
              [43.478579699000079, 11.35786715100005],
              [43.479392099000052, 11.357445230000053],
              [43.479563130000088, 11.356770155000049],
              [43.479178309000076, 11.355884117000073],
              [43.479050036000046, 11.354744921000076],
              [43.479135552000059, 11.354280803000051],
              [43.479605888000037, 11.353816684000037],
              [43.479776919000074, 11.353394757000046],
              [43.479734162000057, 11.35288844300004],
              [43.479734162000057, 11.352382129000091],
              [43.480161740000085, 11.352002393000078],
              [43.48054656100004, 11.352002393000078],
              [43.480931382000051, 11.352593094000042],
              [43.481529992000048, 11.354322995000075],
              [43.481743781000034, 11.355124653000075],
              [43.482000328000083, 11.356095079000056],
              [43.482855485000073, 11.356854540000086],
              [43.484352010000066, 11.357993727000064],
              [43.48567750400008, 11.359343869000043],
              [43.487002997000047, 11.36136907000008],
              [43.487815397000077, 11.362592622000079],
              [43.488328491000061, 11.363689596000086],
              [43.489055374000088, 11.364449036000053],
              [43.489183648000051, 11.365124092000087],
              [43.488713312000073, 11.365335047000087],
              [43.487858154000037, 11.365081901000053],
              [43.487045755000054, 11.364786564000042],
              [43.486423148000085, 11.364678149000042],
              [43.486318872000084, 11.364659991000053],
              [43.485591988000067, 11.364364654000042],
              [43.484907862000057, 11.363647404000062],
              [43.484223737000036, 11.363014536000037],
              [43.48322694400008, 11.362304168000037],
              [43.482621906000077, 11.362386516000072],
              [43.479282654000087, 11.362217541000064],
              [43.477056486000038, 11.36171061500005],
              [43.475857780000069, 11.360865736000051],
              [43.475857780000069, 11.360590903000059],
              [43.47560091400004, 11.360442307000085],
              [43.474487830000044, 11.360442307000085],
              [43.473760044000073, 11.360400063000043],
              [43.473203502000047, 11.360738015000038],
              [43.472432906000051, 11.361413919000086],
              [43.47110576700004, 11.361202699000046],
              [43.469499075000044, 11.360426284000084],
              [43.46818561300006, 11.359726722000062],
              [43.467628819000083, 11.359626785000046],
              [43.467029195000066, 11.360212132000072],
              [43.466800766000063, 11.360611882000057],
              [43.46677221300007, 11.36133761800005],
              [43.467381216000035, 11.361625138000079],
              [43.469013665000034, 11.361696916000085],
              [43.469684673000074, 11.361768300000051],
              [43.471183734000078, 11.361996728000065],
              [43.471954679000078, 11.362225156000079],
              [43.472811285000034, 11.362396478000051],
              [43.474573452000072, 11.36255450200008],
              [43.476243078000039, 11.362850207000065],
              [43.476928053000051, 11.362723476000042],
              [43.478836080000065, 11.362781950000056],
              [43.479192999000077, 11.363281637000057],
              [43.479710763000071, 11.364370976000089],
              [43.481037902000082, 11.36496238400008],
              [43.482835961000035, 11.365258087000086],
              [43.484505587000058, 11.36551154700004],
              [43.484508842000082, 11.365514558000086],
              [43.485062129000084, 11.36538457000006],
              [43.485747104000041, 11.365807002000054],
              [43.486646133000079, 11.366187191000051],
              [43.487245486000063, 11.366778595000085],
              [43.487673596000036, 11.36711654000004],
              [43.487713503000066, 11.367267487000049],
              [43.487930461000076, 11.367370245000075],
              [43.488401381000074, 11.367919404000077],
              [43.489728520000085, 11.367961647000072],
              [43.491398146000051, 11.368172861000062],
              [43.491510413000071, 11.367868224000063],
              [43.493817903000036, 11.369115760000057],
              [43.494095758000071, 11.368951260000074],
              [43.494594625000047, 11.368655911000076],
              [43.495579277000047, 11.368867125000065],
              [43.496307063000074, 11.369247310000048],
              [43.496777983000072, 11.369585251000046],
              [43.497548579000068, 11.369711979000044],
              [43.498319176000052, 11.36975422200004],
              [43.499132584000051, 11.36996543500004],
              [43.499860369000089, 11.37017664800004],
              [43.501273130000072, 11.370261133000042],
              [43.502214970000068, 11.370134405000044],
              [43.503028378000067, 11.370134405000044],
              [43.503437223000049, 11.370235259000083],
              [43.503884597000081, 11.370345618000044],
              [43.504907834000051, 11.370726971000067],
              [43.506858992000048, 11.37104819800004],
              [43.507025555000041, 11.371845317000066],
              [43.505752543000085, 11.372642437000081],
              [43.504729375000068, 11.372951767000075],
              [43.504158304000043, 11.372404491000054],
              [43.503182725000045, 11.37292797200007],
              [43.502290427000048, 11.373094534000074],
              [43.501183978000086, 11.372892280000087],
              [43.500160810000068, 11.372356901000046],
              [43.499827685000071, 11.371773933000043],
              [43.499301203000073, 11.371143312000072],
              [43.498745030000066, 11.370477127000072],
              [43.497933878000083, 11.370725801000049],
              [43.497569192000071, 11.370828613000072],
              [43.497334525000042, 11.370894771000053],
              [43.497145701000079, 11.370914913000092],
              [43.497183804000088, 11.370935513000063],
              [43.49696356700008, 11.371002378000071],
              [43.495850483000083, 11.37159377200004],
              [43.496282288000089, 11.371952393000072],
              [43.49674628300005, 11.372356901000046],
              [43.497234073000072, 11.372487772000056],
              [43.497391676000063, 11.372438618000047],
              [43.497991029000048, 11.372607587000061],
              [43.49850476000006, 11.372903282000038],
              [43.498932870000033, 11.373156735000066],
              [43.497948218000033, 11.373325704000081],
              [43.49713481100008, 11.373030009000047],
              [43.496758180000086, 11.373177815000076],
              [43.497174586000085, 11.373677502000078],
              [43.496817667000073, 11.373986832000071],
              [43.496627310000065, 11.374462724000068],
              [43.495679239000083, 11.373072251000053],
              [43.494737399000087, 11.371973953000065],
              [43.493676778000065, 11.371190966000086],
              [43.493469765000043, 11.371390840000061],
              [43.493605395000088, 11.371952393000072],
              [43.494152671000052, 11.372856588000047],
              [43.494830817000036, 11.373927346000073],
              [43.495465185000057, 11.375015383000061],
              [43.497067510000079, 11.375283638000042],
              [43.498411906000058, 11.37520035700004],
              [43.499498966000033, 11.375186713000062],
              [43.500876146000053, 11.37467027100007],
              [43.50185164800007, 11.374440741000058],
              [43.502712386000042, 11.374268593000068],
              [43.503630506000036, 11.374039063000055],
              [43.504433861000052, 11.373580003000086],
              [43.506499631000054, 11.37277664800007],
              [43.50724415500008, 11.372181029000046],
              [43.50793419300004, 11.371628998000062],
              [43.507532516000083, 11.370079671000042],
              [43.506499631000054, 11.369161551000047],
              [43.505237216000069, 11.368530343000089],
              [43.504326655000057, 11.367938479000088],
              [43.504089566000061, 11.367784371000084],
              [43.502310708000039, 11.367210546000081],
              [43.500696188000063, 11.367371931000037],
              [43.499244717000067, 11.366729476000046],
              [43.497263243000077, 11.366524147000064],
              [43.495616039000083, 11.366289276000089],
              [43.494664255000089, 11.366194097000061],
              [43.493403140000055, 11.36569441100005],
              [43.491475777000062, 11.364528475000043],
              [43.490714349000086, 11.363909815000056],
              [43.489429441000084, 11.36274387900005],
              [43.488144532000035, 11.36085220700005],
              [43.486603323000054, 11.357990905000065],
              [43.484753800000078, 11.355712572000073],
              [43.483820612000045, 11.353724187000068],
              [43.482969204000085, 11.351786461000074],
              [43.482529004000071, 11.349906687000043],
              [43.482150986000079, 11.347556341000086],
              [43.481755679000059, 11.345742630000075],
              [43.481458246000045, 11.344374440000081],
              [43.481294768000055, 11.342486284000074],
              [43.480866658000082, 11.340247198000043],
              [43.480481360000056, 11.33885304100005],
              [43.479804521000062, 11.337604873000089],
              [43.47959036900005, 11.336308067000061],
              [43.479598420000059, 11.335565077000069],
              [43.479376218000084, 11.33473762300008],
              [43.478971709000064, 11.330775820000042],
              [43.478947915000049, 11.327147142000058],
              [43.479233450000038, 11.323934870000073],
              [43.479530883000052, 11.322269247000065],
              [43.479899699000043, 11.320234808000066],
              [43.480625435000036, 11.317653093000047],
              [43.481351170000039, 11.315725729000064],
              [43.481791371000043, 11.315261735000092],
              [43.48244572200008, 11.31477394500007],
              [43.483183355000051, 11.313774571000067],
              [43.483777801000087, 11.312721642000042],
              [43.485194, 11.311418905000039],
              [43.48557586000004, 11.310440068000048],
              [43.485689308000076, 11.310294512000041],
              [43.486432079000053, 11.309341526000082],
              [43.487252234000039, 11.307361924000077],
              [43.487870894000082, 11.303923603000044],
              [43.487882791000061, 11.302721975000054],
              [43.487858996000057, 11.301294298000073],
              [43.488011645000086, 11.300469994000082],
              [43.488215915000069, 11.29936693500008],
              [43.488679910000087, 11.298415151000086],
              [43.489391678000061, 11.297098283000082],
              [43.490250478000064, 11.296211734000053],
              [43.490547787000082, 11.295904819000043],
              [43.491202139000052, 11.294679397000039],
              [43.491334513000083, 11.294149900000036],
              [43.491511469000045, 11.293442077000066],
              [43.491897537000057, 11.290536650000092],
              [43.491892183000061, 11.288885409000045],
              [43.491773210000076, 11.287219787000083],
              [43.491856491000078, 11.285054477000074],
              [43.491344907000041, 11.283472136000057],
              [43.490999885000065, 11.281711334000079],
              [43.490955697000061, 11.278789164000045],
              [43.490999885000065, 11.275964936000037],
              [43.491344907000041, 11.273335632000055],
              [43.491547161000085, 11.270753916000047],
              [43.491832696000074, 11.268719477000047],
              [43.492227745000037, 11.26563301300007],
              [43.49259412400005, 11.262211651000086],
              [43.49301515600007, 11.259050522000052],
              [43.493165194000085, 11.256929248000063],
              [43.493453674000079, 11.252909681000062],
              [43.494271644000037, 11.248946156000045],
              [43.495616039000083, 11.242474022000067],
              [43.496563928000057, 11.239234065000062],
              [43.497234073000072, 11.236751419000086],
              [43.49833331800005, 11.232806413000048],
              [43.499018292000073, 11.230397404000087],
              [43.500029939000058, 11.227447726000037],
              [43.500708086000088, 11.225794001000054],
              [43.501564692000045, 11.223295567000036],
              [43.50250457900006, 11.221451484000056],
              [43.503789488000052, 11.218453364000084],
              [43.504940438000062, 11.215705658000047],
              [43.506026181000038, 11.213337522000074],
              [43.506909741000072, 11.212409851000075],
              [43.507132630000058, 11.21160051600009],
              [43.508215285000063, 11.20994679000006],
              [43.508774458000062, 11.208673779000037],
              [43.509963748000075, 11.207387033000089],
              [43.510991211000089, 11.206288092000079],
              [43.51270056900006, 11.20459300300007],
              [43.513818916000048, 11.202522872000088],
              [43.515805766000085, 11.198977475000049],
              [43.516504103000045, 11.197761961000083],
              [43.516542917000038, 11.197694402000081],
              [43.517090675000077, 11.19666939800004],
              [43.517388107000045, 11.196217300000058],
              [43.517721232000042, 11.195455873000071],
              [43.517709335000063, 11.194040094000059],
              [43.517982567000047, 11.191999106000083],
              [43.517887794000046, 11.191660633000083],
              [43.517653721000045, 11.191762404000087],
              [43.517614156000036, 11.191779606000068],
              [43.517114469000035, 11.192767082000046],
              [43.517150161000075, 11.19361179100008],
              [43.517225300000064, 11.193998221000072],
              [43.517233442000077, 11.194040094000059],
              [43.517219686000033, 11.194027517000052],
              [43.516817037000067, 11.193659380000042],
              [43.516770686000086, 11.192740295000078],
              [43.517078777000052, 11.19227929300007],
              [43.517459491000068, 11.191624941000043],
              [43.517654621000077, 11.191066429000045],
              [43.517854666000062, 11.19071372600007],
              [43.518253231000074, 11.190011006000077],
              [43.518980115000033, 11.189377750000062],
              [43.519450451000068, 11.188702275000082],
              [43.519450451000068, 11.188153450000073],
              [43.51902287200005, 11.188069016000043],
              [43.518773102000068, 11.188414273000092],
              [43.518595294000079, 11.188660058000039],
              [43.518253231000074, 11.189293315000043],
              [43.517868410000062, 11.189588835000052],
              [43.517355316000078, 11.189631052000038],
              [43.517056011000079, 11.189968789000091],
              [43.516884980000043, 11.190222091000066],
              [43.516243612000039, 11.190686477000042],
              [43.515901549000034, 11.191277514000092],
              [43.515431212000067, 11.191150863000075],
              [43.515627306000056, 11.19066125900008],
              [43.516115338000077, 11.189968789000091],
              [43.517066880000073, 11.189495323000074],
              [43.517875897000067, 11.188448360000052],
              [43.518624282000076, 11.187802661000092],
              [43.519335607000073, 11.187253007000038],
              [43.519607712000038, 11.187042747000078],
              [43.520433817000082, 11.18637822900007],
              [43.521326115000079, 11.185747672000048],
              [43.521802007000076, 11.185343164000074],
              [43.522230310000054, 11.184736401000066],
              [43.522315227000036, 11.184438081000053],
              [43.523255900000038, 11.183931466000047],
              [43.524966215000063, 11.182707144000062],
              [43.525351035000085, 11.182960452000088],
              [43.525051730000087, 11.183340415000089],
              [43.524282089000053, 11.184058120000088],
              [43.523512447000087, 11.184649170000057],
              [43.523341416000051, 11.185366872000088],
              [43.523341416000051, 11.18578904900005],
              [43.52338846300006, 11.185816921000082],
              [43.523576584000068, 11.185767389000091],
              [43.523918647000073, 11.185218559000077],
              [43.524560015000077, 11.184500857000046],
              [43.52537241400006, 11.184331985000085],
              [43.526441361000082, 11.18420533200009],
              [43.527382033000038, 11.183952024000064],
              [43.528237191000073, 11.183360973000049],
              [43.528964074000044, 11.182727702000079],
              [43.529434411000068, 11.182221084000048],
              [43.530375083000081, 11.181545592000077],
              [43.530973693000078, 11.181503374000044],
              [43.531444030000046, 11.181461156000069],
              [43.532085398000049, 11.181250064000039],
              [43.532812281000076, 11.181038973000057],
              [43.533667438000066, 11.180321260000085],
              [43.534223291000046, 11.17994129300007],
              [43.534608111000068, 11.179772419000074],
              [43.534907416000067, 11.180025730000068],
              [43.534608111000068, 11.180447915000059],
              [43.533752954000079, 11.180785662000062],
              [43.533197102000088, 11.181334501000038],
              [43.533195926000076, 11.181335662000038],
              [43.532897797000089, 11.181630029000075],
              [43.532512976000078, 11.181798902000082],
              [43.532164222000063, 11.181798902000082],
              [43.531999882000036, 11.181798902000082],
              [43.531783426000061, 11.181969881000043],
              [43.531144725000047, 11.182474393000064],
              [43.530246810000051, 11.182727702000079],
              [43.529648200000054, 11.183149882000066],
              [43.529306137000049, 11.18374093400007],
              [43.528707527000051, 11.184247549000077],
              [43.528108917000054, 11.184585292000065],
              [43.527253760000065, 11.184627510000041],
              [43.526612392000061, 11.18496525200004],
              [43.526488702000051, 11.185026316000062],
              [43.526099298000076, 11.185218559000077],
              [43.525628961000052, 11.185809607000067],
              [43.525115867000068, 11.186274],
              [43.524731046000056, 11.186358436000091],
              [43.524132436000059, 11.18656952300006],
              [43.523747616000037, 11.187033916000075],
              [43.523215353000069, 11.187215135000088],
              [43.522935459000053, 11.187498400000038],
              [43.521693943000059, 11.189104648000068],
              [43.521223022000072, 11.189992307000068],
              [43.52070929100006, 11.191091310000047],
              [43.51986975300008, 11.191218418000062],
              [43.519181163000042, 11.191849625000089],
              [43.519754988000045, 11.192021773000079],
              [43.520960021000064, 11.191907008000044],
              [43.521419081000033, 11.191562713000053],
              [43.522148032000075, 11.191315943000063],
              [43.524624152000058, 11.188871144000075],
              [43.526100460000066, 11.187413499000058],
              [43.52641998200005, 11.18709801600005],
              [43.528131374000054, 11.185711980000065],
              [43.529237823000074, 11.184962450000057],
              [43.529956452000079, 11.184496985000067],
              [43.532378712000082, 11.183225443000083],
              [43.535424422000062, 11.181928637000055],
              [43.538647070000081, 11.181242157000042],
              [43.54189655600004, 11.18129808000009],
              [43.544049968000081, 11.181357566000088],
              [43.54622717500007, 11.181226696000067],
              [43.548071257000061, 11.18098875000004],
              [43.549344269000073, 11.180703215000051],
              [43.550664869000059, 11.180346296000039],
              [43.551961268000071, 11.17988949100004],
              [43.553091920000043, 11.178787749000037],
              [43.554126985000039, 11.177050742000063],
              [43.554745645000082, 11.175278044000038],
              [43.55531671600005, 11.174278670000092],
              [43.556244706000086, 11.172482177000063],
              [43.557055769000044, 11.17092791500005],
              [43.558076891000042, 11.169662516000074],
              [43.558600372000058, 11.168234839000093],
              [43.558874010000068, 11.167104595000069],
              [43.559040572000072, 11.166307476000043],
              [43.559088161000034, 11.16386852800008],
              [43.559290416000067, 11.162238597000055],
              [43.559837692000087, 11.160454002000051],
              [43.560218405000057, 11.159407039000087],
              [43.561848336000082, 11.15432688900006],
              [43.563038067000036, 11.151186001000042],
              [43.563406883000084, 11.150091449000058],
              [43.564061235000054, 11.148378237000088],
              [43.564751279000063, 11.146546052000076],
              [43.565239068000039, 11.145463397000071],
              [43.565857728000083, 11.143952439000088],
              [43.567047459000037, 11.141120881000063],
              [43.568284778000077, 11.138360706000071],
              [43.569331741000042, 11.136135910000064],
              [43.570259731000078, 11.134172855000088],
              [43.57223468400008, 11.130258641000069],
              [43.575720594000074, 11.123286821000079],
              [43.576886530000081, 11.12163309500005],
              [43.579277888000036, 11.118289953000044],
              [43.580836435000037, 11.115898594000043],
              [43.582537750000085, 11.114328150000063],
              [43.583512922000068, 11.113177001000054],
              [43.584405627000081, 11.111698846000081],
              [43.585739090000061, 11.108906614000091],
              [43.586832677000075, 11.105785885000046],
              [43.587962921000042, 11.102847251000071],
              [43.588712451000049, 11.101336293000088],
              [43.588997986000038, 11.100681941000062],
              [43.589390597000033, 11.099920514000075],
              [43.590055896000081, 11.098261876000038],
              [43.591032425000037, 11.096220452000068],
              [43.592805124000051, 11.092710747000069],
              [43.595006125000054, 11.088748944000088],
              [43.595762358000059, 11.087464540000042],
              [43.59755214900008, 11.084323147000077],
              [43.597955536000086, 11.083636958000056],
              [43.599327208000034, 11.081303645000048],
              [43.59948139800008, 11.081041357000061],
              [43.603286650000086, 11.075459654000042],
              [43.60462592500005, 11.073403226000039],
              [43.606252740000059, 11.071331181000062],
              [43.606736868000041, 11.070129662000056],
              [43.606713073000037, 11.069356337000045],
              [43.606344257000046, 11.069106494000039],
              [43.605880262000085, 11.06854732000005],
              [43.604918385000076, 11.068164907000039],
              [43.603964796000071, 11.068011941000066],
              [43.603036806000034, 11.067583639000077],
              [43.602013638000074, 11.067000671000073],
              [43.602135675000056, 11.066473408000093],
              [43.602929730000085, 11.06692928700005],
              [43.603810131000046, 11.06752415200009],
              [43.60465484000008, 11.067702612000062],
              [43.605678008000041, 11.067845379000062],
              [43.606558409000058, 11.068071428000053],
              [43.607177068000055, 11.068523526000092],
              [43.607545885000036, 11.068999418000089],
              [43.608201467000072, 11.069080105000069],
              [43.608319210000047, 11.06909459600007],
              [43.609473248000086, 11.067476563000071],
              [43.610020524000049, 11.066679443000055],
              [43.610770054000056, 11.065858529000081],
              [43.611468457000058, 11.064866475000088],
              [43.612368749000041, 11.063340027000038],
              [43.613030542000047, 11.062217954000062],
              [43.614279759000055, 11.060254899000086],
              [43.616064355000049, 11.057863540000085],
              [43.617205122000087, 11.056408786000077],
              [43.618800735000036, 11.054437116000088],
              [43.619345005000071, 11.05372619700006],
              [43.619816588000049, 11.053110221000054],
              [43.620672807000062, 11.052031354000064],
              [43.621828702000073, 11.050551211000084],
              [43.623119457000087, 11.049178508000068],
              [43.624237804000074, 11.047857907000036],
              [43.625205987000072, 11.046746393000092],
              [43.626272243000074, 11.045502240000076],
              [43.62730730800007, 11.044407688000092],
              [43.628779233000046, 11.042026351000061],
              [43.631293784000036, 11.037548652000055],
              [43.633480810000037, 11.033654182000078],
              [43.634421897000038, 11.031606188000069],
              [43.635123838000084, 11.030142820000037],
              [43.635968547000061, 11.028429608000067],
              [43.636641283000074, 11.026782340000068],
              [43.637668745000042, 11.024667664000049],
              [43.63856777400008, 11.022933617000092],
              [43.639359278000086, 11.021196046000057],
              [43.640144501000066, 11.019494732000055],
              [43.641216356000086, 11.01734145100005],
              [43.641476999000076, 11.016817838000065],
              [43.642916573000036, 11.014140944000076],
              [43.643833518000065, 11.012613711000085],
              [43.645417523000049, 11.009568423000076],
              [43.646298114000047, 11.008292424000047],
              [43.646432388000051, 11.008097857000052],
              [43.647057413000084, 11.006958400000087],
              [43.648129112000049, 11.005004632000066],
              [43.649837122000065, 11.002146219000053],
              [43.651363659000083, 10.999340697000036],
              [43.652279752000084, 10.997460923000062],
              [43.652910309000049, 10.996509138000079],
              [43.653267228000061, 10.99604514300006],
              [43.653647942000077, 10.995402689000059],
              [43.65396916900005, 10.99498628300006],
              [43.654266602000064, 10.994546083000046],
              [43.654599726000072, 10.994117780000067],
              [43.655337359000043, 10.993023228000084],
              [43.656586576000052, 10.990953097000045],
              [43.657495080000047, 10.989375349000056],
              [43.658342317000063, 10.987784850000082],
              [43.660036795000053, 10.98506393100007],
              [43.661165763000042, 10.983445304000043],
              [43.662380564000046, 10.981387664000067],
              [43.663312490000067, 10.979895624000051],
              [43.664831409000044, 10.977747088000058],
              [43.666095201000076, 10.975877077000064],
              [43.667507961000069, 10.97363512700008],
              [43.668535423000037, 10.972366091000083],
              [43.668674238000051, 10.972072074000039],
              [43.669828277000079, 10.970525424000073],
              [43.671936686000038, 10.967739742000049],
              [43.671961526000075, 10.967726454000058],
              [43.672345596000071, 10.967119246000038],
              [43.673754387000088, 10.965219226000045],
              [43.675848313000074, 10.962506640000072],
              [43.67739728500004, 10.960477748000073],
              [43.678233774000034, 10.959533100000044],
              [43.678895668000052, 10.95878562200005],
              [43.680265618000078, 10.956924273000084],
              [43.682308550000073, 10.954547343000058],
              [43.684081248000041, 10.952715158000046],
              [43.68638932500005, 10.949859805000074],
              [43.688185818000079, 10.947872955000037],
              [43.689780057000064, 10.946028873000046],
              [43.69071994400008, 10.944910526000058],
              [43.690899499000068, 10.944684876000053],
              [43.693301659000042, 10.942197941000074],
              [43.693777552000086, 10.941567384000052],
              [43.693814176000046, 10.94152437300005],
              [43.695823888000064, 10.939164128000073],
              [43.696245684000075, 10.938210622000042],
              [43.697013619000074, 10.937391430000048],
              [43.697596587000078, 10.936665694000055],
              [43.698214987000085, 10.93592607700009],
              [43.698494895000067, 10.935491402000082],
              [43.698643549000053, 10.935297504000062],
              [43.699048058000074, 10.934821612000064],
              [43.70021399400008, 10.933393935000083],
              [43.70179633500004, 10.931442777000086],
              [43.703051882000068, 10.929882772000042],
              [43.703908840000054, 10.928818010000043],
              [43.705664088000049, 10.926744931000087],
              [43.708917719000056, 10.923064121000039],
              [43.712685080000085, 10.918833248000055],
              [43.714811987000076, 10.916690119000066],
              [43.71603740900008, 10.915095880000081],
              [43.718807043000083, 10.911809864000077],
              [43.722509543000058, 10.908243032000087],
              [43.725038734000066, 10.905236603000048],
              [43.725947864000034, 10.904055181000047],
              [43.727464455000074, 10.902359716000092],
              [43.73279763000005, 10.896055181000065],
              [43.73607422200007, 10.892265504000079],
              [43.736206381000045, 10.892203135000045],
              [43.738371637000057, 10.889573901000063],
              [43.741521437000074, 10.885412104000068],
              [43.745530829000074, 10.88065318200006],
              [43.746375537000063, 10.879653808000057],
              [43.747263433000057, 10.878656718000059],
              [43.751620603000049, 10.873798746000091],
              [43.755857689000038, 10.869112796000081],
              [43.758557217000089, 10.866080951000072],
              [43.761033017000045, 10.86349726800006],
              [43.761382404000074, 10.863069610000082],
              [43.76225843900005, 10.86215287300007],
              [43.766089372000067, 10.858083994000083],
              [43.766446291000079, 10.857715178000092],
              [43.77084917500008, 10.852686099000039],
              [43.773037398000042, 10.850564897000083],
              [43.774512664000042, 10.849030145000086],
              [43.779225461000067, 10.843922519000046],
              [43.783199612000033, 10.839691732000063],
              [43.786612223000077, 10.836252440000067],
              [43.789550857000052, 10.833182935000082],
              [43.790885612000068, 10.831763206000062],
              [43.793205429000068, 10.829315292000047],
              [43.796998570000085, 10.825259330000051],
              [43.802547201000039, 10.819563182000081],
              [43.804739041000062, 10.817288793000046],
              [43.805326684000079, 10.816609989000085],
              [43.806184146000078, 10.815810303000092],
              [43.811073082000064, 10.810780309000052],
              [43.816886878000048, 10.804678926000065],
              [43.824195809000059, 10.797598095000069],
              [43.830632251000054, 10.791459086000089],
              [43.835723686000051, 10.786393780000083],
              [43.837877710000043, 10.784475368000074],
              [43.839531436000073, 10.782809745000066],
              [43.842244021000056, 10.780132852000065],
              [43.843943384000056, 10.778139228000043],
              [43.847002943000064, 10.775231162000068],
              [43.85071490200005, 10.771923711000056],
              [43.853617845000088, 10.76886610400004],
              [43.855140700000049, 10.76739083800004],
              [43.858713154000043, 10.763936734000083],
              [43.861238998000033, 10.761650839000083],
              [43.863385532000052, 10.759455336000087],
              [43.86532479300007, 10.757896789000085],
              [43.867490102000033, 10.755826658000046],
              [43.869715562000067, 10.75365006800007],
              [43.87160657000004, 10.751733985000044],
              [43.873996655000042, 10.749628435000091],
              [43.878491802000042, 10.745564414000057],
              [43.881921533000082, 10.74233511400007],
              [43.88382510200006, 10.740562415000056],
              [43.888488846000087, 10.736517331000073],
              [43.895329796000055, 10.73040211700004],
              [43.901823758000035, 10.724861977000046],
              [43.905668554000044, 10.72182416000004],
              [43.910086267000054, 10.717918197000074],
              [43.915994175000037, 10.713261006000039],
              [43.917449746000045, 10.712160205000089],
              [43.919547481000052, 10.710381980000079],
              [43.922265295000045, 10.708380205000083],
              [43.924470738000082, 10.706444443000066],
              [43.92665409500006, 10.704771331000074],
              [43.929308499000058, 10.702836061000085],
              [43.932485080000049, 10.700349524000046],
              [43.935649763000072, 10.697958166000092],
              [43.937956180000072, 10.696006900000043],
              [43.940480069000046, 10.694400872000074],
              [43.944049260000043, 10.692199870000081],
              [43.947439992000056, 10.689546771000039],
              [43.951556688000039, 10.686081304000083],
              [43.951651638000044, 10.686001374000057],
              [43.954173867000065, 10.684240573000068],
              [43.956481944000075, 10.682396491000077],
              [43.957991694000043, 10.681144188000076],
              [43.959198038000068, 10.68024141300009],
              [43.960419952000052, 10.679326986000092],
              [43.963453765000054, 10.67707839600007],
              [43.966261529000064, 10.674901189000082],
              [43.96946190400007, 10.672616906000087],
              [43.972638484000072, 10.67048728900005],
              [43.976969103000044, 10.66781039500006],
              [43.979782456000066, 10.666154404000054],
              [43.982736410000086, 10.664333560000046],
              [43.987153196000065, 10.661718975000042],
              [43.991212974000064, 10.65920973100009],
              [43.995421823000072, 10.656829182000081],
              [43.998717377000048, 10.654830435000065],
              [44.003357326000071, 10.65212974700006],
              [44.007737992000045, 10.649342472000058],
              [44.011376921000078, 10.64688631000007],
              [44.013688711000043, 10.645065349000049],
              [44.016342988000076, 10.642990287000089],
              [44.01891164400007, 10.640999909000072],
              [44.022036842000034, 10.638416632000087],
              [44.024348632000056, 10.636700796000071],
              [44.027473829000087, 10.634075132000078],
              [44.030556216000036, 10.63170354600004],
              [44.033467359000042, 10.629247240000041],
              [44.036121637000065, 10.626917966000065],
              [44.038347805000058, 10.625308639000082],
              [44.040536404000079, 10.623968826000066],
              [44.043022941000061, 10.622648225000091],
              [44.045259634000047, 10.621565570000087],
              [44.045864952000045, 10.621289103000038],
              [44.047734274000049, 10.620435326000063],
              [44.049733021000065, 10.619554926000092],
              [44.051029827000036, 10.619067136000069],
              [44.052719244000059, 10.618317606000062],
              [44.054289689000086, 10.617377719000046],
              [44.055860133000067, 10.616592497000056],
              [44.057296270000052, 10.616075627000043],
              [44.060270596000066, 10.61405308500008],
              [44.064497576000065, 10.61100076300005],
              [44.066040789000056, 10.60935365000006],
              [44.066055854000069, 10.609337698000047],
              [44.067052060000037, 10.608282892000091],
              [44.067646925000076, 10.607688027000052],
              [44.068420250000088, 10.606736243000057],
              [44.06943152100007, 10.605249079000089],
              [44.070442792000051, 10.604178322000053],
              [44.072873279000078, 10.601030822000041],
              [44.073384863000058, 10.600162319000049],
              [44.073880875000043, 10.598900068000091],
              [44.074086804000046, 10.598461004000058],
              [44.075201714000059, 10.595850209000048],
              [44.075796579000041, 10.593887153000082],
              [44.076561444000049, 10.592964449000078],
              [44.077358563000075, 10.591643848000047],
              [44.078453115000059, 10.590299453000057],
              [44.078833829000075, 10.589561820000085],
              [44.07922644000007, 10.58903833800008],
              [44.079440592000083, 10.588681419000068],
              [44.079583359000083, 10.588336397000091],
              [44.080226237000034, 10.587190755000051],
              [44.080511349000062, 10.586682672000052],
              [44.081447799000046, 10.584964174000049],
              [44.082640967000088, 10.583280043000059],
              [44.084508843000037, 10.581007657000043],
              [44.086167611000064, 10.579055574000051],
              [44.087837237000087, 10.577191846000062],
              [44.089624685000047, 10.575511102000064],
              [44.091433075000054, 10.573904966000043],
              [44.093622179000079, 10.57197760300005],
              [44.09504985600006, 10.570704591000037],
              [44.097238960000084, 10.569086558000038],
              [44.099773086000084, 10.567230578000078],
              [44.101807525000083, 10.56598136100007],
              [44.102312528000084, 10.565462696000054],
              [44.102372436000053, 10.565415709000092],
              [44.103386982000075, 10.564788307000072],
              [44.104945035000071, 10.563927869000054],
              [44.107327874000077, 10.562578732000077],
              [44.107696691000058, 10.562364580000065],
              [44.107898945000045, 10.562055251000061],
              [44.108224881000069, 10.561836994000089],
              [44.109992871000088, 10.560734650000086],
              [44.113443089000043, 10.557938783000054],
              [44.113847598000063, 10.557605658000057],
              [44.114299695000057, 10.557248739000045],
              [44.114490052000065, 10.557022690000053],
              [44.114965944000062, 10.556725258000085],
              [44.116014459000041, 10.556112107000047],
              [44.116413031000036, 10.55593284400004],
              [44.116576083000041, 10.555879065000056],
              [44.116810027000042, 10.555690192000043],
              [44.117036075000044, 10.555547425000043],
              [44.117481928000075, 10.555395055000076],
              [44.117826149000052, 10.555233717000078],
              [44.118439957000078, 10.554940662000092],
              [44.119094309000047, 10.554595640000059],
              [44.120605267000087, 10.554095953000058],
              [44.120906021000053, 10.553907165000055],
              [44.121235824000053, 10.55379852100009],
              [44.121557051000082, 10.553703342000063],
              [44.122223300000087, 10.553536780000059],
              [44.122615911000082, 10.553596267000046],
              [44.123234571000069, 10.553703342000063],
              [44.12424584200005, 10.553560575000063],
              [44.12451948000006, 10.553441602000078],
              [44.125530751000042, 10.553156066000042],
              [44.126558493000061, 10.552992916000051],
              [44.127017914000078, 10.552799147000087],
              [44.127493807000064, 10.552680174000045],
              [44.128076774000078, 10.552513612000041],
              [44.128516975000082, 10.552394639000056],
              [44.128933380000035, 10.552299460000086],
              [44.129373581000038, 10.552204282000048],
              [44.129825678000088, 10.552121001000046],
              [44.13028967300005, 10.551978233000057],
              [44.130765565000047, 10.552085309000063],
              [44.131431814000052, 10.551823568000088],
              [44.132752415000084, 10.551549930000078],
              [44.134263373000067, 10.551097833000085],
              [44.134977211000034, 10.550883681000073],
              [44.135215157000061, 10.550752811000052],
              [44.13567915200008, 10.550586248000059],
              [44.13604796900006, 10.550407789000076],
              [44.13673801300007, 10.550169843000049],
              [44.137844462000089, 10.549812924000037],
              [44.138653479000084, 10.549551183000062],
              [44.139462495000089, 10.549313237000092],
              [44.140188231000081, 10.548944420000055],
              [44.141092426000057, 10.548492323000062],
              [44.142103697000039, 10.547778484000048],
              [44.143412401000035, 10.547028954000041],
              [44.145280278000087, 10.545827326000051],
              [44.146541392000074, 10.54522056400009],
              [44.150027302000069, 10.543233714000053],
              [44.15256142800007, 10.541437221000081],
              [44.154453100000069, 10.539866777000043],
              [44.156420343000036, 10.538261356000078],
              [44.15855767000005, 10.536702093000088],
              [44.160841953000045, 10.535024573000044],
              [44.161853223000037, 10.534239351000053],
              [44.16229342400004, 10.533906227000045],
              [44.163661614000034, 10.532954442000062],
              [44.165588977000084, 10.531574355000089],
              [44.167067875000043, 10.530315080000037],
              [44.168253974000038, 10.529551813000069],
              [44.168801250000058, 10.52912351000009],
              [44.171275735000052, 10.527246776000084],
              [44.174952156000074, 10.524709610000059],
              [44.17760525500006, 10.522901220000051],
              [44.180389225000056, 10.52096195900009],
              [44.184351027000048, 10.517844865000086],
              [44.186361672000089, 10.516274421000048],
              [44.188528538000071, 10.51470661400009],
              [44.190597112000034, 10.513181121000059],
              [44.192726730000061, 10.511860521000074],
              [44.194265203000043, 10.510787019000077],
              [44.196700430000078, 10.509326395000073],
              [44.199413015000061, 10.507518004000076],
              [44.200837255000067, 10.506798880000076],
              [44.202708569000038, 10.505376489000071],
              [44.204207629000052, 10.504222451000089],
              [44.205596177000075, 10.50281328300008],
              [44.207321286000081, 10.501207147000059],
              [44.208740503000058, 10.499546810000083],
              [44.210584585000049, 10.497571857000082],
              [44.212916457000063, 10.494966347000059],
              [44.214129982000088, 10.493669541000088],
              [44.214831923000077, 10.492812935000075],
              [44.215831296000033, 10.491789767000057],
              [44.216720157000054, 10.490975465000076],
              [44.217318459000069, 10.490207425000051],
              [44.218115579000084, 10.489481690000048],
              [44.219210131000068, 10.488422830000047],
              [44.220352272000071, 10.487256894000041],
              [44.221625284000083, 10.486031471000047],
              [44.222446198000057, 10.485186763000058],
              [44.22399284800008, 10.483735292000063],
              [44.225551395000082, 10.482212436000054],
              [44.225789341000052, 10.48204587400005],
              [44.225907207000034, 10.481847805000086],
              [44.226022979000049, 10.481703091000043],
              [44.226240050000058, 10.481457076000083],
              [44.226486064000085, 10.481225534000089],
              [44.226674193000065, 10.481037405000052],
              [44.226949150000053, 10.480834805000086],
              [44.227137278000043, 10.480632205000063],
              [44.228026034000038, 10.479856770000083],
              [44.228497592000053, 10.479402134000054],
              [44.229033035000043, 10.478808805000085],
              [44.230322214000068, 10.477643871000055],
              [44.231250204000048, 10.476763471000083],
              [44.232303577000039, 10.475755335000088],
              [44.233582538000064, 10.474634343000048],
              [44.233997004000059, 10.474244888000044],
              [44.234644715000059, 10.47363626300006],
              [44.235542071000054, 10.472793847000048],
              [44.235961536000048, 10.472337673000084],
              [44.236318456000049, 10.472004549000076],
              [44.236603991000038, 10.471742808000045],
              [44.236888106000038, 10.471502752000049],
              [44.236943046000079, 10.471383715000059],
              [44.237052927000036, 10.471264678000068],
              [44.237181120000059, 10.471118171000057],
              [44.237364254000056, 10.471008291000089],
              [44.237648112000045, 10.470733590000066],
              [44.238554625000063, 10.469909487000052],
              [44.24011570600004, 10.468161641000052],
              [44.241528466000034, 10.466975202000071],
              [44.243583391000072, 10.464856549000046],
              [44.245467072000054, 10.463288737000084],
              [44.247093887000062, 10.46189041100007],
              [44.247950106000076, 10.461381927000048],
              [44.248849135000057, 10.459983593000061],
              [44.250433139000052, 10.458797122000078],
              [44.252145576000089, 10.457610647000081],
              [44.253986446000056, 10.456042798000055],
              [44.255313585000067, 10.455025810000052],
              [44.256940400000076, 10.453754571000047],
              [44.258395972000073, 10.45252570100007],
              [44.259466245000056, 10.451762952000081],
              [44.260622140000066, 10.450703575000091],
              [44.260750573000053, 10.450237448000053],
              [44.261820846000035, 10.449517068000091],
              [44.262420199000076, 10.449008564000053],
              [44.263490472000058, 10.448500059000082],
              [44.263620562000085, 10.448471444000063],
              [44.264175447000071, 10.447777665000046],
              [44.264817611000069, 10.447353909000071],
              [44.265930695000065, 10.446591147000049],
              [44.267086590000076, 10.445701256000063],
              [44.268542162000074, 10.444811362000053],
              [44.269826490000071, 10.444048593000048],
              [44.270896763000053, 10.443243447000043],
              [44.27218109100005, 10.442268793000039],
              [44.27316574200006, 10.441463642000087],
              [44.274364448000085, 10.440700865000053],
              [44.27569158700004, 10.439768580000077],
              [44.277618078000046, 10.438836292000076],
              [44.278431486000045, 10.437946378000049],
              [44.279587381000056, 10.437225970000043],
              [44.281000142000039, 10.436420805000068],
              [44.282284469000047, 10.435318998000071],
              [44.283226310000089, 10.434937602000048],
              [44.283825663000073, 10.434386697000093],
              [44.28468188100004, 10.434047677000081],
              [44.284742049000045, 10.434008835000043],
              [44.28566653300004, 10.433412015000044],
              [44.286736806000079, 10.432776351000086],
              [44.287678646000074, 10.432183064000071],
              [44.288449243000059, 10.431632153000066],
              [44.289862004000042, 10.430954108000037],
              [44.291189142000064, 10.430148927000062],
              [44.291912592000074, 10.429790859000093],
              [44.292815957000073, 10.42934374400005],
              [44.29465682700004, 10.428453802000092],
              [44.295727101000068, 10.42777575000008],
              [44.297696403000089, 10.426673912000069],
              [44.298852298000043, 10.426080613000067],
              [44.30013662600004, 10.425275419000059],
              [44.30056473500008, 10.425021147000052],
              [44.301378143000079, 10.424809254000081],
              [44.302191551000078, 10.424173572000086],
              [44.30309058000006, 10.423961678000069],
              [44.304332097000042, 10.423388423000063],
              [44.305530803000067, 10.422879876000081],
              [44.307114807000062, 10.422201811000093],
              [44.308099459000061, 10.421566124000037],
              [44.309383786000069, 10.421142332000045],
              [44.311481522000065, 10.420252367000046],
              [44.31323677000006, 10.419531917000086],
              [44.314906396000083, 10.418726707000076],
              [44.315805426000054, 10.418091012000048],
              [44.31688630900004, 10.417531761000077],
              [44.317897580000079, 10.416996383000082],
              [44.318849364000073, 10.416461004000041],
              [44.320277041000054, 10.415925625000057],
              [44.321370846000036, 10.416014402000087],
              [44.323340149000046, 10.415209183000059],
              [44.32475290900004, 10.414615861000073],
              [44.326593779000063, 10.413937778000047],
              [44.328049351000061, 10.413090173000057],
              [44.32941930000004, 10.412454467000089],
              [44.330746439000052, 10.412073043000078],
              [44.332587309000075, 10.411649238000052],
              [44.335113154000055, 10.410886387000062],
              [44.337638998000045, 10.410250677000079],
              [44.338889975000086, 10.409762174000093],
              [44.341158954000036, 10.409295985000085],
              [44.34271605400005, 10.408698989000072],
              [44.344821877000072, 10.407913767000082],
              [44.346832522000057, 10.406997674000081],
              [44.348521939000079, 10.40622434900007],
              [44.35035384400004, 10.405419235000068],
              [44.352494390000061, 10.404613988000051],
              [44.354220748000046, 10.403761607000092],
              [44.356314674000089, 10.402786028000037],
              [44.358102622000047, 10.402027795000038],
              [44.361099387000081, 10.400798719000079],
              [44.363068689000045, 10.400247752000041],
              [44.365428010000073, 10.399597550000067],
              [44.367435404000048, 10.399145815000054],
              [44.369147841000085, 10.398721992000048],
              [44.370603413000083, 10.39838293300005],
              [44.372090501000059, 10.397836749000078],
              [44.373886994000088, 10.397325165000041],
              [44.375933330000066, 10.397063424000066],
              [44.377324728000076, 10.396899547000089],
              [44.378437812000072, 10.397069077000083],
              [44.379609597000069, 10.397122911000054],
              [44.38103727400005, 10.397075322000092],
              [44.382547661000046, 10.39728099000007],
              [44.383928319000063, 10.397241884000039],
              [44.385831888000041, 10.396896862000062],
              [44.387699765000036, 10.396290100000044],
              [44.389555744000063, 10.395719029000077],
              [44.39158076700005, 10.395077091000076],
              [44.394748776000085, 10.394314199000064],
              [44.39647997600008, 10.393672692000052],
              [44.397990934000063, 10.39331577300004],
              [44.400314196000068, 10.392746027000044],
              [44.401762379000047, 10.392352092000067],
              [44.403297132000034, 10.392042762000074],
              [44.405069830000059, 10.391531178000037],
              [44.40703551200005, 10.391093080000076],
              [44.409233887000084, 10.390615085000093],
              [44.411541964000037, 10.390496112000051],
              [44.414052295000033, 10.390531804000091],
              [44.414609690000077, 10.390483244000052],
              [44.41737164400007, 10.390615085000093],
              [44.419608337000057, 10.390900620000082],
              [44.421297754000079, 10.391471691000049],
              [44.422297128000082, 10.392054659000053],
              [44.421868825000047, 10.392209324000078],
              [44.420940835000067, 10.391804816000047],
              [44.420643402000053, 10.39132892300006],
              [44.420393559000047, 10.391483588000085],
              [44.420500635000053, 10.391911891000063],
              [44.421654673000035, 10.392423475000044],
              [44.423451166000063, 10.393280081000057],
              [44.425616476000073, 10.394089098000052],
              [44.426794309000059, 10.394386531000066],
              [44.427377277000062, 10.394457915000089],
              [44.42825767800008, 10.394719655000074],
              [44.429001973000084, 10.394912185000067],
              [44.430709955000054, 10.395267587000092],
              [44.432183788000088, 10.395397802000048],
              [44.432850037000037, 10.395742824000081],
              [44.435812466000073, 10.396647019000056],
              [44.43665717500005, 10.39695634900005],
              [44.437156862000052, 10.397206192000056],
              [44.437835008000036, 10.397337062000076],
              [44.437965879000046, 10.397848646000057],
              [44.438762998000072, 10.398253155000077],
              [44.439428243000066, 10.398230794000085],
              [44.440749848000053, 10.398717150000039],
              [44.441447438000068, 10.398897287000068],
              [44.442879466000079, 10.399323912000057],
              [44.443819353000038, 10.399585653000088],
              [44.445246157000042, 10.399885307000091],
              [44.44593117900007, 10.399885307000091],
              [44.446412965000036, 10.399918778000085],
              [44.446841268000071, 10.400002059000087],
              [44.447307291000072, 10.400128506000044],
              [44.447904700000038, 10.400424218000069],
              [44.448530685000037, 10.40087056200008],
              [44.448591560000068, 10.400910365000072],
              [44.44914934500008, 10.401275070000054],
              [44.449756108000088, 10.401346454000077],
              [44.450991311000053, 10.401262068000051],
              [44.451588720000075, 10.401163497000084],
              [44.452086560000055, 10.400917071000038],
              [44.452833321000071, 10.400719930000037],
              [44.45321970100008, 10.400648209000053],
              [44.453629866000085, 10.400572074000081],
              [44.454227274000061, 10.400276362000056],
              [44.454774899000085, 10.400128506000044],
              [44.455521660000045, 10.399980649000042],
              [44.456156858000043, 10.399704626000073],
              [44.457227615000079, 10.399454783000067],
              [44.459178773000076, 10.398955096000066],
              [44.460868191000088, 10.39870525200007],
              [44.462561285000049, 10.398317768000084],
              [44.465044145000036, 10.398633869000037],
              [44.466816843000061, 10.398610074000089],
              [44.467423752000059, 10.398613752000074],
              [44.469050679000077, 10.398613752000074],
              [44.470206654000037, 10.398401825000064],
              [44.471540073000085, 10.397967620000088],
              [44.472498021000035, 10.397565654000061],
              [44.473147170000061, 10.397519750000072],
              [44.473478071000045, 10.39738535400005],
              [44.474502502000064, 10.397099116000049],
              [44.475335314000063, 10.396944451000081],
              [44.476027807000037, 10.396875254000065],
              [44.477057417000083, 10.396751829000038],
              [44.477655288000051, 10.396706505000054],
              [44.478702251000072, 10.396456662000048],
              [44.479356603000042, 10.396313894000059],
              [44.479987160000064, 10.39602835900007],
              [44.480652712000051, 10.395759693000059],
              [44.481379145000062, 10.395504877000064],
              [44.481961416000047, 10.395283801000062],
              [44.482687848000069, 10.395028985000067],
              [44.483603941000069, 10.394517401000087],
              [44.485697866000066, 10.393922536000048],
              [44.487648488000048, 10.393528523000043],
              [44.489838128000088, 10.393018341000072],
              [44.491123037000079, 10.393101622000074],
              [44.491932335000058, 10.392931680000061],
              [44.493407320000074, 10.393054033000055],
              [44.494478077000053, 10.393054033000055],
              [44.49495397000004, 10.393184903000076],
              [44.495608321000077, 10.393244389000074],
              [44.496714771000086, 10.393291979000082],
              [44.497211007000033, 10.393620629000054],
              [44.498323627000048, 10.39381504000005],
              [44.498570858000051, 10.393656681000039],
              [44.499153718000059, 10.393648898000038],
              [44.499567288000037, 10.39347732300007],
              [44.499748457000067, 10.393423515000052],
              [44.500074561000076, 10.393297964000055],
              [44.500346314000069, 10.393208285000071],
              [44.500898382000059, 10.392890098000066],
              [44.501176260000079, 10.392697113000054],
              [44.501485590000073, 10.392494859000067],
              [44.501760540000078, 10.392428989000052],
              [44.502015637000056, 10.392291629000056],
              [44.502282710000088, 10.392221221000057],
              [44.502651526000079, 10.392030864000048],
              [44.503091726000036, 10.391864302000045],
              [44.503972127000054, 10.391590664000091],
              [44.50491201400007, 10.391233745000079],
              [44.505947079000066, 10.390960107000069],
              [44.506303999000068, 10.390829237000048],
              [44.506732302000046, 10.390710263000074],
              [44.507136810000077, 10.390662674000055],
              [44.507445752000081, 10.390484475000051],
              [44.507759717000056, 10.390386360000036],
              [44.508093306000035, 10.390386360000036],
              [44.508387648000053, 10.390288246000068],
              [44.508957098000053, 10.390139193000039],
              [44.509361606000084, 10.390162987000053],
              [44.509754217000079, 10.390091604000077],
              [44.510051650000037, 10.390055912000037],
              [44.510408569000049, 10.390020220000054],
              [44.510979640000073, 10.38998452800007],
              [44.511461948000033, 10.389943102000075],
              [44.512869, 10.38976041300009],
              [44.51506041500005, 10.389806068000041],
              [44.515607691000071, 10.390008322000085],
              [44.517697450000071, 10.390098690000059],
              [44.517808693000063, 10.390103501000056],
              [44.518903245000047, 10.389889349000043],
              [44.520099171000084, 10.389586421000047],
              [44.521640364000064, 10.388993050000067],
              [44.523162480000053, 10.388461673000052],
              [44.523588109000059, 10.388236475000042],
              [44.524434795000047, 10.387788499000067],
              [44.525827476000075, 10.387581272000091],
              [44.527100488000087, 10.387426607000066],
              [44.528968365000082, 10.387367121000068],
              [44.530217582000034, 10.387379018000047],
              [44.530860036000036, 10.387414710000087],
              [44.531264545000056, 10.38745040200007],
              [44.531680950000066, 10.387569375000055],
              [44.531886710000038, 10.387656562000075],
              [44.532382891000054, 10.387866808000069],
              [44.53292900200006, 10.388186800000085],
              [44.534000925000043, 10.388687722000043],
              [44.535677748000069, 10.388799770000048],
              [44.537581317000047, 10.388799770000048],
              [44.539723528000081, 10.387593170000059],
              [44.540776849000054, 10.387127254000063],
              [44.541074729000059, 10.387014495000074],
              [44.541897021000068, 10.386703225000076],
              [44.542232420000062, 10.386576263000052],
              [44.542491278000057, 10.386437141000044],
              [44.543730803000074, 10.385770966000052],
              [44.545100753000042, 10.385050436000085],
              [44.546770379000066, 10.384202751000089],
              [44.547797841000033, 10.383566985000073],
              [44.549039118000053, 10.383095988000036],
              [44.550371616000064, 10.382727172000045],
              [44.551013325000042, 10.38262132300008],
              [44.551525655000034, 10.382536815000037],
              [44.552721098000063, 10.382168297000078],
              [44.55383418200006, 10.381786835000071],
              [44.554749825000044, 10.381299495000064],
              [44.555623512000068, 10.38087844100005],
              [44.555737301000079, 10.380823603000067],
              [44.55751592200005, 10.380557678000059],
              [44.55850057300006, 10.380345754000075],
              [44.55997274200007, 10.379943202000049],
              [44.561650262000057, 10.379621975000077],
              [44.563815571000077, 10.379193672000042],
              [44.565264699000068, 10.37898943600004],
              [44.566611438000052, 10.378670191000083],
              [44.568561141000089, 10.378565585000047],
              [44.570953954000061, 10.378444142000092],
              [44.572857523000039, 10.378491731000054],
              [44.573761718000071, 10.378693985000041],
              [44.574880065000059, 10.378777267000089],
              [44.576521893000063, 10.378896240000074],
              [44.577485575000082, 10.379027110000038],
              [44.578865662000055, 10.378634499000043],
              [44.580448004000061, 10.378527423000037],
              [44.582137421000084, 10.378812958000083],
              [44.583291459000066, 10.379122288000076],
              [44.584144318000085, 10.379476183000065],
              [44.585385835000068, 10.379221873000063],
              [44.58688421800008, 10.378882793000059],
              [44.588276430000064, 10.378717780000045],
              [44.590784984000038, 10.378855267000063],
              [44.592112123000049, 10.37872811200009],
              [44.592476179000073, 10.379157980000059],
              [44.592726022000079, 10.379669564000039],
              [44.595360781000068, 10.379942417000052],
              [44.597501328000078, 10.380027187000053],
              [44.599697843000058, 10.380169251000041],
              [44.600455282000041, 10.380747729000063],
              [44.602467395000076, 10.380959653000048],
              [44.604008589000046, 10.380535805000079],
              [44.605932031000066, 10.38049047800007],
              [44.607764216000078, 10.380395300000089],
              [44.610060396000051, 10.380264430000068],
              [44.610334034000061, 10.380787911000084],
              [44.611238229000037, 10.381347084000083],
              [44.612485152000033, 10.381383500000084],
              [44.613808047000077, 10.381632620000062],
              [44.614925375000041, 10.381807347000063],
              [44.616423758000053, 10.382104039000069],
              [44.617543801000068, 10.382382150000069],
              [44.618452547000061, 10.38303798700008],
              [44.620276741000055, 10.383672265000087],
              [44.621989178000035, 10.383926572000064],
              [44.623915670000088, 10.383968956000047],
              [44.625027206000084, 10.384202438000045],
              [44.626288320000072, 10.384261924000043],
              [44.627083679000066, 10.384350415000085],
              [44.628548808000062, 10.38463074100008],
              [44.629866389000085, 10.384582847000047],
              [44.630893851000053, 10.384921921000057],
              [44.632320254000035, 10.385082838000073],
              [44.633486189000052, 10.385261298000046],
              [44.634747304000086, 10.385677703000056],
              [44.636127391000059, 10.385963239000091],
              [44.637650246000078, 10.386260671000059],
              [44.638685440000074, 10.386574901000074],
              [44.639456037000059, 10.386702053000079],
              [44.640433519000055, 10.387193634000084],
              [44.641504973000053, 10.38768834800004],
              [44.641552562000072, 10.387985781000054],
              [44.64170722700004, 10.388307008000083],
              [44.642623320000041, 10.388901873000066],
              [44.643146801000057, 10.389425355000071],
              [44.643741666000039, 10.389865555000085],
              [44.644514991000051, 10.390448523000089],
              [44.645442981000087, 10.391150464000077],
              [44.646134541000038, 10.391660938000086],
              [44.647215679000055, 10.392578140000069],
              [44.647953312000084, 10.392994546000068],
              [44.648833713000045, 10.393565617000093],
              [44.650010849000068, 10.394034584000053],
              [44.650868152000044, 10.394707758000038],
              [44.652509980000048, 10.395933180000043],
              [44.653735403000042, 10.396873067000058],
              [44.655472409000083, 10.397586906000072],
              [44.657094139000037, 10.398060748000091],
              [44.658549710000045, 10.398484572000086],
              [44.660090904000072, 10.398654101000091],
              [44.661147424000035, 10.399145453000074],
              [44.661944543000061, 10.39949047500005],
              [44.663169965000066, 10.399894983000081],
              [44.664895075000061, 10.400251902000036],
              [44.667964579000056, 10.401762860000076],
              [44.668904466000072, 10.402964488000066],
              [44.67108167300006, 10.403904375000081],
              [44.672521247000077, 10.404201807000049],
              [44.674132888000088, 10.403950937000047],
              [44.675716892000082, 10.404332371000066],
              [44.677814628000078, 10.404756186000043],
              [44.679398632000073, 10.40501047500004],
              [44.680768582000042, 10.405434290000073],
              [44.682052909000049, 10.405773341000042],
              [44.683765346000087, 10.406070010000064],
              [44.685520595000071, 10.406324298000072],
              [44.687059754000074, 10.406961982000041],
              [44.688431738000077, 10.40725668400006],
              [44.690355307000061, 10.407723410000074],
              [44.692432170000075, 10.407850020000069],
              [44.693698450000056, 10.408044637000046],
              [44.69482869400008, 10.408175507000067],
              [44.696411036000086, 10.408294480000052],
              [44.698706360000074, 10.408316211000056],
              [44.700675663000084, 10.408443354000042],
              [44.703201508000063, 10.40852811600007],
              [44.704913945000044, 10.408485735000056],
              [44.706678410000052, 10.408806064000089],
              [44.709355304000042, 10.409317649000059],
              [44.711354051000058, 10.409829233000039],
              [44.713079160000063, 10.410174255000072],
              [44.714209404000087, 10.410459790000061],
              [44.715292059000035, 10.410662044000048],
              [44.716398508000054, 10.410388406000038],
              [44.71719562800007, 10.410638249000044],
              [44.718492434000041, 10.411459163000075],
              [44.719277656000088, 10.411780391000093],
              [44.720039083000074, 10.412184899000067],
              [44.721481372000085, 10.412558189000038],
              [44.723000816000081, 10.413129759000071],
              [44.724190546000045, 10.413427191000039],
              [44.725558736000039, 10.413843597000039],
              [44.728248224000083, 10.414266927000085],
              [44.73016369000004, 10.414683333000085],
              [44.730960810000056, 10.414742820000072],
              [44.731853107000063, 10.414754717000051],
              [44.732816789000083, 10.414945074000059],
              [44.733435449000069, 10.415016458000082],
              [44.734071773000039, 10.415104265000082],
              [44.735866245000068, 10.415499023000052],
              [44.737194997000074, 10.415635118000068],
              [44.738170576000073, 10.415873064000039],
              [44.739586356000075, 10.41613480400008],
              [44.741204389000075, 10.416575005000084],
              [44.742453606000083, 10.417288843000051],
              [44.744571326000084, 10.417966989000092],
              [44.746379020000063, 10.418305086000089],
              [44.748354669000037, 10.418478573000073],
              [44.750807259000055, 10.418338471000084],
              [44.752262830000063, 10.418126573000052],
              [44.753846835000047, 10.417872295000052],
              [44.754703053000071, 10.417787535000059],
              [44.756325864000075, 10.417550584000082],
              [44.757634567000082, 10.417312638000055],
              [44.758883784000034, 10.417146075000062],
              [44.759740390000047, 10.417027102000077],
              [44.76128704000007, 10.416920026000071],
              [44.762751508000065, 10.416600902000084],
              [44.763868755000033, 10.416610697000067],
              [44.765201253000043, 10.416479826000057],
              [44.766462368000077, 10.416241880000086],
              [44.767937634000077, 10.41609911200004],
              [44.768877521000036, 10.416003934000059],
              [44.770126738000044, 10.415777885000068],
              [44.771935128000052, 10.415516144000037],
              [44.773154563000048, 10.415456188000064],
              [44.774738567000043, 10.415456188000064],
              [44.776322572000083, 10.415244288000054],
              [44.778035009000064, 10.415074767000078],
              [44.779228176000061, 10.415159225000082],
              [44.780929491000052, 10.41493317700008],
              [44.781274512000039, 10.414861793000057],
              [44.781857480000042, 10.414742820000072],
              [44.782202502000075, 10.414552463000064],
              [44.782618908000074, 10.414469182000062],
              [44.782916341000089, 10.414374003000091],
              [44.783392233000086, 10.414290722000089],
              [44.783915714000045, 10.414255030000049],
              [44.784439196000051, 10.414195544000052],
              [44.785034061000033, 10.414136057000064],
              [44.785617029000036, 10.413993289000075],
              [44.786249067000085, 10.413690331000055],
              [44.786743901000079, 10.41352874200004],
              [44.787233058000083, 10.413492873000052],
              [44.787390432000052, 10.413457181000069],
              [44.787795900000049, 10.413421940000092],
              [44.78912303900006, 10.413337180000042],
              [44.791244454000037, 10.41345791100008],
              [44.793148023000072, 10.413636370000063],
              [44.793742888000054, 10.41345791100008],
              [44.794968311000048, 10.413386527000057],
              [44.795610765000049, 10.413434116000076],
              [44.796074760000067, 10.413529295000046],
              [44.796824130000061, 10.41388833700006],
              [44.797181209000087, 10.414159852000068],
              [44.797657102000073, 10.414492976000076],
              [44.798192480000068, 10.415016458000082],
              [44.798359043000062, 10.415432863000092],
              [44.799465492000081, 10.415837372000055],
              [44.800107946000082, 10.416586902000063],
              [44.801107320000085, 10.416467929000078],
              [44.80202341200004, 10.415801680000072],
              [44.803935620000061, 10.415074767000078],
              [44.806282648000035, 10.414540565000038],
              [44.808174319000045, 10.414278825000054],
              [44.810173066000061, 10.414504874000045],
              [44.812112327000079, 10.414362106000056],
              [44.813587593000079, 10.414909382000076],
              [44.814167431000044, 10.415752848000068],
              [44.815451759000041, 10.415879988000086],
              [44.81610216200005, 10.416003279000051],
              [44.817466114000069, 10.415825474000087],
              [44.819619527000043, 10.415337685000054],
              [44.821880015000033, 10.414766614000087],
              [44.822296420000043, 10.41465953900007],
              [44.822888766000062, 10.414567162000083],
              [44.823973940000087, 10.414374003000091],
              [44.825509654000086, 10.414301858000044],
              [44.826323118000062, 10.41392040900007],
              [44.826853088000064, 10.413791035000088],
              [44.827221905000044, 10.413921906000041],
              [44.827566926000088, 10.413898111000037],
              [44.82820941600005, 10.413526296000043],
              [44.829946387000064, 10.413660165000067],
              [44.831861853000078, 10.413124786000083],
              [44.833860458000061, 10.412509168000042],
              [44.836085397000033, 10.411661418000051],
              [44.837927496000077, 10.411068230000069],
              [44.839725555000086, 10.410347758000057],
              [44.84113831600007, 10.409966332000067],
              [44.842700298000068, 10.409674568000071],
              [44.844175564000068, 10.409400930000061],
              [44.845353397000054, 10.409020216000044],
              [44.846947636000039, 10.408698989000072],
              [44.848303929000053, 10.408258788000069],
              [44.849220022000054, 10.40806843200005],
              [44.849838681000051, 10.408449145000077],
              [44.852741624000089, 10.408020842000042],
              [44.853931354000053, 10.407652026000051],
              [44.855918204000034, 10.407473566000078],
              [44.857449279000036, 10.407169188000069],
              [44.858535611000036, 10.406902496000043],
              [44.859701547000043, 10.406676447000052],
              [44.861133456000061, 10.40650523100004],
              [44.86276027100007, 10.406335251000087],
              [44.864662723000038, 10.406176760000051],
              [44.86649490800005, 10.406105376000085],
              [44.869493029000068, 10.405772252000077],
              [44.87251404400007, 10.405367169000044],
              [44.874397724000062, 10.405197643000065],
              [44.877142306000053, 10.40475299700006],
              [44.880426653000086, 10.404499240000064],
              [44.882603859000085, 10.404070937000085],
              [44.885566288000064, 10.403856786000063],
              [44.887362781000036, 10.40378540200004],
              [44.889361529000041, 10.40367832600009],
              [44.891288892000034, 10.403809196000054],
              [44.893240050000088, 10.404011451000088],
              [44.895619511000064, 10.404070937000085],
              [44.89798707500006, 10.404130424000073],
              [44.899446799000089, 10.404265382000062],
              [44.901887022000039, 10.404392527000084],
              [44.903781062000064, 10.404998927000065],
              [44.906065345000059, 10.405569997000043],
              [44.907516816000054, 10.405974506000064],
              [44.908682752000061, 10.406640755000069],
              [44.910610115000054, 10.40677162500009],
              [44.912596965000034, 10.406819214000052],
              [44.913917566000066, 10.40686680400006],
              [44.916225643000075, 10.406878701000039],
              [44.918081623000035, 10.406890598000075],
              [44.919033407000086, 10.406688344000088],
              [44.92049677600005, 10.406712139000092],
              [44.922911929000065, 10.40673593300005],
              [44.926838040000064, 10.406854906000092],
              [44.929479241000081, 10.406854906000092],
              [44.93133522100004, 10.406807317000073],
              [44.932762897000089, 10.40714044200007],
              [44.933010566000064, 10.407485909000059],
              [44.934294894000061, 10.407740195000088],
              [44.936345040000049, 10.408179631000053],
              [44.937971855000058, 10.408094869000081],
              [44.939901280000072, 10.408187405000092],
              [44.940888757000039, 10.408318275000056],
              [44.941650184000082, 10.40848483700006],
              [44.942149871000083, 10.408698989000072],
              [44.945064711000043, 10.408770373000038],
              [44.946742231000087, 10.409020216000044],
              [44.947372788000052, 10.409377135000057],
              [44.949621379000064, 10.410043384000062],
              [44.95120372000008, 10.410709633000067],
              [44.952048429000058, 10.410947579000037],
              [44.95360697600006, 10.411506753000083],
              [44.954844296000033, 10.411958850000076],
              [44.956081615000073, 10.412482332000081],
              [44.95748549700005, 10.413065300000085],
              [44.958365898000068, 10.413350835000074],
              [44.958972660000086, 10.413612576000048],
              [44.960614488000033, 10.414350209000077],
              [44.961351400000069, 10.414690159000088],
              [44.96245857100007, 10.415278198000067],
              [44.963826761000064, 10.415849269000091],
              [44.965337719000047, 10.416622594000046],
              [44.96688436800008, 10.417395919000057],
              [44.968074099000034, 10.418181141000048],
              [44.969314232000045, 10.418504346000077],
              [44.971048425000049, 10.419132925000042],
              [44.972547485000064, 10.419751585000085],
              [44.973534962000087, 10.420405937000055],
              [44.974926946000039, 10.421012699000073],
              [44.975962012000082, 10.421524284000043],
              [44.976891766000051, 10.422276107000073],
              [44.977437278000082, 10.423047139000062],
              [44.978972030000079, 10.424153588000081],
              [44.97999519800004, 10.424926913000093],
              [44.981472536000069, 10.425708793000069],
              [44.982933833000061, 10.426402179000092],
              [44.98442099600004, 10.427032736000058],
              [44.986086619000048, 10.427734677000046],
              [44.988151040000048, 10.428675281000039],
              [44.989429761000054, 10.429209943000046],
              [44.990916924000032, 10.430304495000087],
              [44.992201833000081, 10.431839247000084],
              [44.993022747000055, 10.432255653000084],
              [44.993161967000049, 10.432334188000084],
              [44.993950737000034, 10.432779134000043],
              [44.994481065000059, 10.43318094600005],
              [44.995091286000047, 10.433587709000051],
              [44.995091286000047, 10.434689521000053],
              [44.995461695000074, 10.435848639000085],
              [44.996377787000085, 10.435920023000051],
              [44.996437274000073, 10.43514669800004],
              [44.996437274000073, 10.434504243000049],
              [44.996603836000077, 10.434349578000081],
              [44.996607274000041, 10.43433238700004],
              [44.996817988000089, 10.433278821000044],
              [44.99580671700005, 10.43329071800008],
              [44.995300465000071, 10.43299714300008],
              [44.994529868000086, 10.432488611000053],
              [44.99414457000006, 10.432107212000062],
              [44.995086410000056, 10.432064834000073],
              [44.995985440000084, 10.432403856000064],
              [44.996756037000068, 10.432361478000075],
              [44.99765506600005, 10.43295476500009],
              [44.998364637000066, 10.433635740000057],
              [44.998138589000064, 10.434337681000045],
              [44.997795236000059, 10.435322955000061],
              [44.997317675000033, 10.436693347000073],
              [44.996071062000055, 10.436471630000085],
              [44.995033392000039, 10.435705871000039],
              [44.994486116000076, 10.43556310300005],
              [44.993802083000048, 10.436344498000039],
              [44.994164889000047, 10.436848012000041],
              [44.995045289000075, 10.437252521000062],
              [44.995728574000054, 10.437700564000068],
              [44.996342095000045, 10.437894975000063],
              [44.997329572000069, 10.438287586000058],
              [44.99811479400006, 10.438834862000078],
              [44.998554994000074, 10.439227473000074],
              [44.999453125000059, 10.439353260000075],
              [45.000266533000058, 10.439183753000066],
              [45.001037129000053, 10.438675232000037],
              [45.000696509000079, 10.43795446200005],
              [44.999966856000071, 10.437870071000077],
              [44.999581558000045, 10.437531056000068],
              [44.999506779000058, 10.436836115000062],
              [44.998483610000051, 10.436586272000056],
              [44.998587870000051, 10.435983883000063],
              [44.998590686000057, 10.43596761200007],
              [44.998810961000061, 10.435157936000053],
              [44.999239070000044, 10.434310389000075],
              [44.999881234000043, 10.434352766000075],
              [45.000908697000057, 10.435030804000064],
              [45.001981418000071, 10.435717768000075],
              [45.002528694000034, 10.43647919600005],
              [45.00305217600004, 10.437038369000049],
              [45.003385300000048, 10.437454775000049],
              [45.003991083000074, 10.437658187000068],
              [45.004333571000075, 10.438293840000085],
              [45.003908781000064, 10.438965733000089],
              [45.003766014000064, 10.43970336600006],
              [45.003123559000073, 10.440500485000086],
              [45.003004586000088, 10.440928788000065],
              [45.004325187000063, 10.440036490000068],
              [45.004456058000073, 10.440976377000084],
              [45.00600270700005, 10.441035864000071],
              [45.004836771000043, 10.439834236000081],
              [45.005514918000074, 10.439655776000052],
              [45.006121680000035, 10.439548701000092],
              [45.006490497000073, 10.43929885700004],
              [45.005943221000052, 10.440310128000078],
              [45.006645162000041, 10.440322025000057],
              [45.007121054000038, 10.440107874000091],
              [45.007544390000078, 10.44049743000005],
              [45.007929689000036, 10.442022982000083],
              [45.007775406000064, 10.443046508000066],
              [45.007073465000076, 10.443748449000054],
              [45.006345684000053, 10.444692681000049],
              [45.006062194000037, 10.445628223000085],
              [45.006559739000068, 10.446091085000091],
              [45.006516928000053, 10.446641970000087],
              [45.006347729000083, 10.446984516000043],
              [45.006062194000037, 10.447864917000061],
              [45.005919426000048, 10.44879290700004],
              [45.00514602700008, 10.449459076000039],
              [45.004800944000067, 10.449548504000063],
              [45.004633247000072, 10.44990419800007],
              [45.003015795000067, 10.451251389000049],
              [45.002073954000082, 10.451844640000047],
              [45.001508050000041, 10.451133078000055],
              [45.00114975200006, 10.450494707000075],
              [45.000976562000062, 10.450539589000073],
              [44.997746714000073, 10.450131475000092],
              [44.996534503000078, 10.449978304000069],
              [44.99553307900004, 10.449851767000041],
              [44.995391597000037, 10.449004263000063],
              [44.995386087000043, 10.44897125500006],
              [44.994957978000059, 10.448081371000058],
              [44.994230192000089, 10.447911869000052],
              [44.993459595000047, 10.448505126000043],
              [44.993190815000048, 10.448699134000037],
              [44.992793802000051, 10.448985703000062],
              [44.992774620000034, 10.449140757000066],
              [44.992696118000083, 10.449896829000068],
              [44.991987682000058, 10.450351454000042],
              [44.991035897000074, 10.449982637000062],
              [44.99046283000007, 10.449649261000047],
              [44.989869962000057, 10.449161723000088],
              [44.989334583000073, 10.448626344000047],
              [44.988751615000069, 10.448233733000052],
              [44.987883112000077, 10.447960095000042],
              [44.986562511000045, 10.447246257000074],
              [44.986224549000042, 10.44655584800006],
              [44.985453952000057, 10.445665957000074],
              [44.984896888000037, 10.445021461000067],
              [44.98445668800008, 10.444331417000058],
              [44.984614975000056, 10.443423904000042],
              [44.984635147000063, 10.44330824900004],
              [44.984206844000084, 10.441678318000072],
              [44.983350238000071, 10.440203052000072],
              [44.981767200000036, 10.440553047000037],
              [44.980815416000041, 10.441147912000076],
              [44.980054685000084, 10.44224938900004],
              [44.979845720000071, 10.443250524000064],
              [44.979845720000071, 10.444521807000058],
              [44.981494259000044, 10.444795412000076],
              [44.982196200000033, 10.445568737000087],
              [44.981482362000065, 10.445759094000039],
              [44.981600968000066, 10.446174466000059],
              [44.982371565000051, 10.446378859000049],
              [44.982836422000048, 10.446414867000044],
              [44.983278855000037, 10.446449138000048],
              [44.983819608000033, 10.447321013000078],
              [44.984180176000052, 10.447904217000087],
              [44.984255246000089, 10.447911183000087],
              [44.985301396000068, 10.448293220000039],
              [44.986776662000068, 10.44882859900008],
              [44.987835522000069, 10.44935208000004],
              [44.98870402600005, 10.449792280000054],
              [44.989777856000046, 10.450411330000065],
              [44.991511790000061, 10.450922524000077],
              [44.992817431000049, 10.451597833000051],
              [44.994985803000077, 10.452171741000086],
              [44.997508031000052, 10.453349574000072],
              [44.999494881000032, 10.45405151500006],
              [45.001374656000053, 10.454789148000089],
              [45.003349608000065, 10.455574370000079],
              [45.005018546000088, 10.456386174000045],
              [45.007037773000036, 10.457811064000055],
              [45.008132325000076, 10.458156086000088],
              [45.009322055000041, 10.458655773000089],
              [45.010547478000035, 10.459119767000061],
              [45.013109811000049, 10.460199843000055],
              [45.014318923000076, 10.460666417000084],
              [45.016534685000067, 10.461513430000082],
              [45.018675232000078, 10.462318528000083],
              [45.020457933000046, 10.46276034300007],
              [45.022228539000082, 10.463335491000066],
              [45.023360875000037, 10.463640743000042],
              [45.026168639000048, 10.464533041000038],
              [45.030249415000071, 10.466055896000057],
              [45.032866822000074, 10.466900605000092],
              [45.038197014000048, 10.468779740000059],
              [45.041750321000052, 10.47034752400009],
              [45.044799819000048, 10.471576246000041],
              [45.047405329000071, 10.47322997100008],
              [45.049970020000046, 10.474838969000075],
              [45.052878089000046, 10.47641844900005],
              [45.054679222000061, 10.477381267000055],
              [45.05703382300004, 10.479330348000076],
              [45.058779152000056, 10.480725273000076],
              [45.061170511000057, 10.482569356000056],
              [45.062967004000086, 10.484044621000066],
              [45.064354491000074, 10.485050406000084],
              [45.064911033000044, 10.486193946000071],
              [45.065895685000044, 10.486871869000083],
              [45.066631374000053, 10.487911246000067],
              [45.068832375000056, 10.49104023700005],
              [45.069919912000046, 10.492040296000084],
              [45.071161429000085, 10.493523222000078],
              [45.071760782000069, 10.494285867000087],
              [45.072675205000053, 10.495418445000041],
              [45.073876832000053, 10.496501100000046],
              [45.075340201000074, 10.497952571000042],
              [45.076137320000043, 10.498904355000093],
              [45.077112899000042, 10.50016547000007],
              [45.078017095000064, 10.501331406000077],
              [45.079414381000049, 10.502456322000057],
              [45.080337069000052, 10.503508612000076],
              [45.081776643000069, 10.504270040000051],
              [45.083037757000056, 10.504983878000075],
              [45.083276921000049, 10.505428742000049],
              [45.084096617000057, 10.505947560000038],
              [45.085160602000087, 10.50661503200007],
              [45.08560757500004, 10.50689934400009],
              [45.085706038000069, 10.50700923100004],
              [45.085858562000055, 10.507179450000081],
              [45.086595918000057, 10.507594648000065],
              [45.08830288300004, 10.508555822000062],
              [45.088593798000034, 10.508719633000055],
              [45.090616341000043, 10.509373984000092],
              [45.091413460000069, 10.509445368000058],
              [45.092436628000087, 10.50980228700007],
              [45.095088462000035, 10.509714623000093],
              [45.095315776000064, 10.509707108000043],
              [45.09675535000008, 10.509861773000068],
              [45.097897491000083, 10.509956952000039],
              [45.099075324000069, 10.510147309000047],
              [45.099884341000063, 10.510337666000055],
              [45.100859920000062, 10.510504228000059],
              [45.101597553000033, 10.510682687000042],
              [45.102242162000039, 10.510851054000057],
              [45.102798704000065, 10.510978155000089],
              [45.102999568000087, 10.51106563400009],
              [45.103786657000057, 10.511408423000091],
              [45.10478603100006, 10.511646369000061],
              [45.105285718000061, 10.511848623000049],
              [45.105558659000053, 10.512293796000051],
              [45.10574971300008, 10.512705229000062],
              [45.106856162000042, 10.513002662000076],
              [45.108057790000089, 10.513193019000084],
              [45.109246824000081, 10.513126607000061],
              [45.110217679000073, 10.513119858000039],
              [45.110283324000079, 10.513036864000071],
              [45.111912517000064, 10.513181121000059],
              [45.112566868000044, 10.512966970000093],
              [45.113530550000064, 10.512907483000049],
              [45.113740882000059, 10.512899971000081],
              [45.115196173000072, 10.512847997000051],
              [45.116635747000089, 10.512681435000047],
              [45.117920656000081, 10.512800408000089],
              [45.118860543000039, 10.513157327000044],
              [45.119366533000061, 10.513604195000084],
              [45.119679769000072, 10.513914179000039],
              [45.120051508000074, 10.514282058000049],
              [45.121250214000042, 10.514409157000046],
              [45.122417837000057, 10.514489825000055],
              [45.122869193000042, 10.51445885000004],
              [45.123631362000083, 10.514406544000053],
              [45.124928168000054, 10.514442236000093],
              [45.126403434000053, 10.514406544000053],
              [45.128295106000053, 10.514680182000063],
              [45.130769745000066, 10.515215561000048],
              [45.132466677000082, 10.51542594700004],
              [45.133874942000034, 10.515417815000092],
              [45.135683332000042, 10.515703350000081],
              [45.139157345000058, 10.516333907000046],
              [45.141275065000059, 10.517000156000051],
              [45.142741299000079, 10.517459517000077],
              [45.145962604000033, 10.519320131000086],
              [45.146462290000045, 10.519462899000075],
              [45.147354588000042, 10.519974483000055],
              [45.148675189000073, 10.520783499000061],
              [45.150198044000035, 10.521652003000042],
              [45.152159703000052, 10.522628113000053],
              [45.15357687900007, 10.523377112000048],
              [45.155171118000055, 10.524888070000088],
              [45.157705244000056, 10.527041482000072],
              [45.159144818000073, 10.52764824400009],
              [45.159966745000077, 10.527938758000062],
              [45.161988273000077, 10.529242483000075],
              [45.162940058000061, 10.529944424000064],
              [45.164629475000083, 10.531086566000056],
              [45.166640120000068, 10.532835469000077],
              [45.168222461000084, 10.533311362000063],
              [45.17018551700005, 10.534465400000045],
              [45.171327658000052, 10.535072163000052],
              [45.172065291000081, 10.535512363000066],
              [45.173266919000071, 10.536297585000057],
              [45.173975857000073, 10.536810632000083],
              [45.174171114000046, 10.536951937000083],
              [45.174813568000047, 10.537177986000074],
              [45.175670174000061, 10.537487316000067],
              [45.176443499000072, 10.537903721000077],
              [45.177740305000043, 10.538641354000049],
              [45.178370862000065, 10.539117246000046],
              [45.179049009000039, 10.539628831000073],
              [45.179703361000065, 10.540080928000066],
              [45.180488583000056, 10.54024749000007],
              [45.181487956000069, 10.540402155000038],
              [45.182094719000077, 10.54061630700005],
              [45.182939428000054, 10.540949431000058],
              [45.18411726100004, 10.541294453000091],
              [45.18655620800007, 10.54172275600007],
              [45.18681575100004, 10.541727845000082],
              [45.188376496000046, 10.541758448000053],
              [45.189685200000042, 10.541925010000057],
              [45.191529282000033, 10.542151059000048],
              [45.193159213000058, 10.542341416000056],
              [45.194812938000041, 10.542567465000047],
              [45.196050258000071, 10.542841103000058],
              [45.196954453000046, 10.543031460000066],
              [45.198643870000069, 10.543114741000068],
              [45.199214941000037, 10.543364584000074],
              [45.199851076000073, 10.543998985000087],
              [45.200595028000066, 10.544637596000086],
              [45.201915629000041, 10.545101591000048],
              [45.202915003000044, 10.545696456000087],
              [45.204354577000061, 10.546434089000059],
              [45.206686448000085, 10.547469155000044],
              [45.20855432500008, 10.547956944000077],
              [45.210660148000045, 10.548754063000047],
              [45.213158582000062, 10.549693951000052],
              [45.21565701600008, 10.55084798900009],
              [45.216889825000067, 10.551454641000078],
              [45.218602262000047, 10.552428946000077],
              [45.220015023000087, 10.552979178000044],
              [45.22137962000005, 10.553620061000061],
              [45.222369624000066, 10.554419447000043],
              [45.223306764000085, 10.554879738000068],
              [45.22447291900005, 10.55551173300006],
              [45.22580541800005, 10.555987625000057],
              [45.227494835000073, 10.556915615000037],
              [45.228375235000044, 10.557569967000063],
              [45.229886193000084, 10.558438470000056],
              [45.231658892000041, 10.559616303000041],
              [45.232658265000055, 10.560080298000059],
              [45.233414843000048, 10.560519334000048],
              [45.234168526000076, 10.561251206000065],
              [45.234822878000045, 10.561905558000092],
              [45.235512579000044, 10.562594962000048],
              [45.23628317500004, 10.563272715000039],
              [45.236882528000081, 10.564077545000089],
              [45.237440285000048, 10.564582452000082],
              [45.237856691000047, 10.565415263000091],
              [45.238127249000058, 10.565724472000056],
              [45.238273097000047, 10.565891155000088],
              [45.238748989000044, 10.566604994000045],
              [45.239108697000063, 10.567422996000062],
              [45.239700773000038, 10.568032670000036],
              [45.24047409800005, 10.568687022000063],
              [45.240949990000047, 10.569281887000045],
              [45.241402784000059, 10.569574347000071],
              [45.242021444000045, 10.570169213000042],
              [45.242782872000078, 10.571168586000056],
              [45.243166275000078, 10.571897317000037],
              [45.243851250000034, 10.572405616000083],
              [45.244519878000062, 10.57308405200007],
              [45.245566841000084, 10.573940658000083],
              [45.246554318000051, 10.574904340000046],
              [45.247542449000036, 10.575852307000048],
              [45.248707730000035, 10.576617552000073],
              [45.249707103000048, 10.577593131000071],
              [45.250825450000036, 10.578782861000093],
              [45.251717748000033, 10.57963946700005],
              [45.252636950000067, 10.580850481000084],
              [45.253664412000035, 10.582036476000042],
              [45.254430333000073, 10.582780356000058],
              [45.255382118000057, 10.583993881000083],
              [45.256310108000037, 10.585385866000081],
              [45.256918042000052, 10.58652627400005],
              [45.257688639000037, 10.588008739000088],
              [45.25854485800005, 10.589194707000047],
              [45.259010796000041, 10.589799766000056],
              [45.259308229000055, 10.590442220000057],
              [45.259974478000061, 10.591465389000064],
              [45.260996949000059, 10.592838551000057],
              [45.26132756800007, 10.59366252600006],
              [45.262226597000051, 10.595017891000055],
              [45.263254060000065, 10.596415605000061],
              [45.264030762000061, 10.597597473000064],
              [45.264733400000068, 10.598746539000047],
              [45.265601903000061, 10.599864886000091],
              [45.266291250000052, 10.601047692000066],
              [45.266898709000088, 10.602125374000082],
              [45.267492341000036, 10.603192310000054],
              [45.268266899000082, 10.603707715000041],
              [45.269539911000038, 10.604647603000046],
              [45.270348928000033, 10.605694565000078],
              [45.270670155000062, 10.606479787000069],
              [45.271395890000065, 10.60739588000007],
              [45.272145421000062, 10.608514227000057],
              [45.272501220000038, 10.609333568000068],
              [45.27377535100004, 10.609989492000068],
              [45.274170846000061, 10.610688863000064],
              [45.276416553000047, 10.612702078000041],
              [45.276910746000055, 10.613441787000056],
              [45.278580372000079, 10.614839416000052],
              [45.279736267000033, 10.615855870000075],
              [45.28144870400007, 10.617295840000054],
              [45.282775843000081, 10.618396988000086],
              [45.284554310000033, 10.620161688000053],
              [45.285315737000076, 10.620340148000082],
              [45.286410289000059, 10.621232446000079],
              [45.288127209000038, 10.622906725000064],
              [45.289240293000034, 10.62400785300008],
              [45.29096695700008, 10.625360811000064],
              [45.292620683000052, 10.626847974000043],
              [45.29470271100007, 10.628775337000093],
              [45.29645161500008, 10.630500446000042],
              [45.298390876000042, 10.632463502000064],
              [45.299497325000061, 10.633712719000073],
              [45.301162948000069, 10.635497314000077],
              [45.302554932000078, 10.63713914300007],
              [45.303958814000055, 10.639149787000065],
              [45.305374594000057, 10.641089048000083],
              [45.306878395000069, 10.643149901000072],
              [45.308075282000061, 10.644884288000071],
              [45.309443472000055, 10.646823549000089],
              [45.310966327000074, 10.64908403700008],
              [45.312144139000054, 10.651153627000042],
              [45.313631323000038, 10.653450348000092],
              [45.315344535000065, 10.655365814000049],
              [45.316616850000059, 10.656905539000093],
              [45.31762881800006, 10.657911837000086],
              [45.318521116000056, 10.658899313000063],
              [45.320043971000075, 10.660053352000091],
              [45.322221178000063, 10.661302569000043],
              [45.323970214000042, 10.661853014000087],
              [45.324612536000075, 10.661826050000059],
              [45.326075904000049, 10.662575581000056],
              [45.328050857000051, 10.663146651000091],
              [45.330724081000085, 10.663814706000039],
              [45.332774087000075, 10.663729619000037],
              [45.334653861000049, 10.663753414000041],
              [45.337216021000074, 10.663404351000054],
              [45.338651356000071, 10.663158549000059],
              [45.339029353000058, 10.663093657000047],
              [45.341423428000041, 10.662682656000072],
              [45.341219549000073, 10.662915661000056],
              [45.341173584000046, 10.662968192000051],
              [45.341555246000041, 10.663056569000048],
              [45.343037086000038, 10.662764778000053],
              [45.343695813000068, 10.662635067000053],
              [45.344894498000087, 10.662082620000092],
              [45.346949423000069, 10.661447435000071],
              [45.349358930000051, 10.660874266000064],
              [45.350310715000035, 10.660457860000065],
              [45.351548034000075, 10.660445963000086],
              [45.354046468000035, 10.660208017000059],
              [45.356116600000064, 10.660243709000042],
              [45.357913093000036, 10.660231812000063],
              [45.359186104000059, 10.660422168000082],
              [45.360049567000033, 10.660473481000054],
              [45.361315722000086, 10.660969445000092],
              [45.363600004000034, 10.661362056000087],
              [45.363732364000043, 10.661389270000086],
              [45.364873016000047, 10.661623796000072],
              [45.365919979000068, 10.661504823000087],
              [45.367048581000063, 10.661437872000079],
              [45.367323861000045, 10.661421542000085],
              [45.369084662000034, 10.661207391000062],
              [45.369948645000079, 10.661134650000065],
              [45.370229128000062, 10.661106091000079],
              [45.372493740000039, 10.66081937000007],
              [45.37327276700006, 10.660676009000042],
              [45.374251079000032, 10.660443048000047],
              [45.374432248000062, 10.66038928800009],
              [45.374975755000037, 10.660353447000091],
              [45.375682314000073, 10.660317607000081],
              [45.377186017000042, 10.660245927000062],
              [45.378471636000086, 10.660231812000063],
              [45.380428941000048, 10.660407208000038],
              [45.380918097000063, 10.66038928800009],
              [45.381461604000037, 10.660317607000081],
              [45.382168163000074, 10.660263847000067],
              [45.382639203000053, 10.660174246000054],
              [45.38316459300006, 10.660120486000039],
              [45.38477720700007, 10.65964884400006],
              [45.385003256000061, 10.659732125000062],
              [45.385650501000043, 10.659478119000084],
              [45.388037069000063, 10.658851724000044],
              [45.388647266000078, 10.659097004000046],
              [45.39133262200005, 10.658756546000063],
              [45.39241462800004, 10.658377120000068],
              [45.394461614000079, 10.658363935000068],
              [45.396781342000054, 10.658123043000046],
              [45.398851719000049, 10.658221167000079],
              [45.401266872000065, 10.65844721600007],
              [45.402504192000038, 10.65831634500006],
              [45.403658230000076, 10.658411524000087],
              [45.405430232000072, 10.658452188000069],
              [45.407084654000073, 10.658352037000043],
              [45.409107196000036, 10.65804270700005],
              [45.410309595000058, 10.658249616000091],
              [45.411629425000058, 10.65831634500006],
              [45.412831053000048, 10.658625675000053],
              [45.414761932000033, 10.658969501000058],
              [45.416364552000061, 10.659399],
              [45.418486483000038, 10.659774075000087],
              [45.420284542000047, 10.65985876700006],
              [45.422596332000069, 10.660112843000093],
              [45.424009092000063, 10.660451610000052],
              [45.424198397000055, 10.660496808000062],
              [45.424271832000045, 10.660496808000062],
              [45.424489235000067, 10.660514728000066],
              [45.424851573000069, 10.660550569000065],
              [45.425177677000079, 10.660586409000075],
              [45.425540015000081, 10.660676009000042],
              [45.426346391000038, 10.660909958000047],
              [45.426798489000078, 10.661052726000037],
              [45.427202997000052, 10.661100315000056],
              [45.427464738000083, 10.661219288000041],
              [45.42775027600004, 10.661267371000065],
              [45.428058264000072, 10.661356972000078],
              [45.42825754900008, 10.661410732000093],
              [45.428873524000039, 10.661446572000045],
              [45.429090927000061, 10.661446572000045],
              [45.429815603000065, 10.661482412000055],
              [45.430413460000068, 10.661572012000079],
              [45.430993201000035, 10.661697452000055],
              [45.431616897000083, 10.661659488000055],
              [45.432187968000051, 10.661718975000042],
              [45.43250919500008, 10.661754667000082],
              [45.432973190000041, 10.661742769000057],
              [45.43342528800008, 10.66177846100004],
              [45.433936872000061, 10.661837948000084],
              [45.434472250000056, 10.661885537000046],
              [45.434960040000078, 10.661873640000067],
              [45.435614392000048, 10.661861742000042],
              [45.436589971000046, 10.661790359000065],
              [45.437791599000036, 10.661742769000057],
              [45.43892184300006, 10.661600002000057],
              [45.440373314000055, 10.661600002000057],
              [45.441134741000042, 10.661802256000044],
              [45.441741504000049, 10.661826050000059],
              [45.442836056000033, 10.661837948000084],
              [45.444239938000067, 10.661861742000042],
              [45.445298798000067, 10.661861742000042],
              [45.446286274000045, 10.662111586000037],
              [45.448308816000065, 10.662230559000079],
              [45.450521715000036, 10.662527991000047],
              [45.451390218000085, 10.662575581000056],
              [45.45260679200004, 10.662780159000079],
              [45.454147985000077, 10.662907196000049],
              [45.455221150000057, 10.66325372700004],
              [45.45637415300007, 10.663457687000061],
              [45.457487237000066, 10.663669414000083],
              [45.457656755000073, 10.663702949000083],
              [45.458985620000078, 10.663965832000088],
              [45.459414258000038, 10.664092553000046],
              [45.460158532000037, 10.66431258700004],
              [45.460912112000074, 10.664499907000049],
              [45.461811141000055, 10.664711634000071],
              [45.462514198000065, 10.665085912000052],
              [45.463609200000064, 10.665558538000084],
              [45.464195725000081, 10.665719689000071],
              [45.464379797000049, 10.665770264000059],
              [45.465878180000061, 10.666447785000059],
              [45.466905642000086, 10.666955925000082],
              [45.467847482000082, 10.667379375000053],
              [45.468575268000052, 10.667802823000045],
              [45.469057716000066, 10.668345774000045],
              [45.470021398000085, 10.669273763000092],
              [45.471127847000048, 10.670201753000072],
              [45.47226998900004, 10.671046462000049],
              [45.47296003200006, 10.671712711000055],
              [45.473578692000046, 10.67292623600008],
              [45.473841012000037, 10.673900419000063],
              [45.47374525400005, 10.674627551000071],
              [45.473983200000077, 10.675293800000077],
              [45.474352017000058, 10.676067125000088],
              [45.474732731000074, 10.676614401000052],
              [45.474969980000083, 10.676893011000061],
              [45.475124579000067, 10.67751140900009],
              [45.475207926000053, 10.677844796000045],
              [45.475624332000052, 10.678439661000084],
              [45.476219197000034, 10.679094013000054],
              [45.47669578600005, 10.679779084000074],
              [45.477302549000058, 10.680385846000092],
              [45.47770636000007, 10.680819122000059],
              [45.477773304000038, 10.680861637000078],
              [45.47835030300007, 10.681228078000061],
              [45.478956274000041, 10.68174213900005],
              [45.480561713000043, 10.682306285000038],
              [45.482311314000071, 10.682824794000055],
              [45.483358277000036, 10.683229302000086],
              [45.484310061000087, 10.683574324000062],
              [45.485951889000034, 10.684335752000038],
              [45.487688896000066, 10.68510907600006],
              [45.488747756000066, 10.685692044000064],
              [45.489259340000046, 10.686405883000077],
              [45.490282508000064, 10.686572445000081],
              [45.491821602000073, 10.686560072000077],
              [45.492293129000075, 10.686482344000069],
              [45.49230078100004, 10.686479822000081],
              [45.492590651000057, 10.686300636000055],
              [45.492681235000077, 10.686282718000086],
              [45.493007340000077, 10.686211043000071],
              [45.493460262000042, 10.686211043000071],
              [45.49385170000005, 10.686358293000069],
              [45.494458462000068, 10.686548650000077],
              [45.495481631000075, 10.686739007000085],
              [45.495803018000061, 10.687025836000089],
              [45.49652859400004, 10.68751233200004],
              [45.49762314600008, 10.687595613000042],
              [45.498682006000081, 10.687440948000074],
              [45.499788455000044, 10.687405256000091],
              [45.500335731000064, 10.687607510000078],
              [45.500752137000063, 10.687881148000088],
              [45.501679430000081, 10.688135964000082],
              [45.503785950000065, 10.687655100000086],
              [45.504357020000043, 10.687571819000084],
              [45.504702042000076, 10.68751233200004],
              [45.506591372000059, 10.687279888000091],
              [45.508128466000073, 10.687393359000055],
              [45.509782191000056, 10.68778597000005],
              [45.511269354000035, 10.688238068000089],
              [45.512103185000058, 10.68845099300006],
              [45.514410243000043, 10.689641950000066],
              [45.515350130000058, 10.690272507000088],
              [45.51673756100007, 10.690836603000037],
              [45.518538608000085, 10.691747773000088],
              [45.519905570000049, 10.692911334000087],
              [45.520547734000047, 10.69358879400005],
              [45.521275520000074, 10.694308594000063],
              [45.522310054000059, 10.69570957500008],
              [45.523009313000045, 10.696896639000045],
              [45.523132729000054, 10.69827999000006],
              [45.523134259000074, 10.698297134000086],
              [45.523261838000053, 10.698767183000086],
              [45.523428400000057, 10.699481021000054],
              [45.524101041000051, 10.700489631000039],
              [45.524046363000082, 10.701877352000054],
              [45.524529150000035, 10.703368753000063],
              [45.525403353000058, 10.704323224000063],
              [45.526370020000059, 10.705020002000083],
              [45.527140617000043, 10.705909132000045],
              [45.528449063000039, 10.706762172000083],
              [45.529567410000084, 10.707702059000042],
              [45.530852319000076, 10.708249335000062],
              [45.532679055000074, 10.708145653000088],
              [45.532941865000055, 10.708130736000044],
              [45.532918725000059, 10.708243546000062],
              [45.533814747000065, 10.708130362000077],
              [45.537312555000085, 10.70830882100006],
              [45.538407107000069, 10.708415897000066],
              [45.540298779000068, 10.708749022000063],
              [45.542523575000075, 10.709236811000039],
              [45.544569911000053, 10.709831676000078],
              [45.546901783000067, 10.710462233000044],
              [45.547936849000052, 10.710783461000062],
              [45.551628467000057, 10.712259750000044],
              [45.553814117000059, 10.713067743000067],
              [45.555563021000069, 10.713876760000062],
              [45.556466102000059, 10.714376671000082],
              [45.558335093000039, 10.715007004000086],
              [45.559676921000062, 10.715519802000074],
              [45.562106539000069, 10.716506064000043],
              [45.564355129000035, 10.717517335000082],
              [45.566639412000086, 10.718576195000082],
              [45.568769030000055, 10.719646953000051],
              [45.571219875000054, 10.721098424000047],
              [45.572563011000057, 10.722081667000054],
              [45.57397577100005, 10.723351780000087],
              [45.575134088000084, 10.724727102000088],
              [45.575559776000034, 10.725595632000079],
              [45.576418997000076, 10.727403996000078],
              [45.576587238000059, 10.728008110000076],
              [45.576775916000088, 10.728677007000044],
              [45.576715671000045, 10.729447542000059],
              [45.576633148000042, 10.730663857000081],
              [45.576264332000051, 10.731044571000041],
              [45.575966899000036, 10.73187738200005],
              [45.575907991000065, 10.732602063000058],
              [45.575871721000055, 10.733400238000058],
              [45.575474043000042, 10.733728323000037],
              [45.575395829000058, 10.733792849000054],
              [45.575169780000067, 10.733923719000074],
              [45.574705785000049, 10.734661352000046],
              [45.574539223000045, 10.735517958000059],
              [45.575217369000086, 10.736434050000071],
              [45.575985350000053, 10.737338022000074],
              [45.577525446000038, 10.737790343000086],
              [45.578599351000037, 10.739227028000073],
              [45.579883679000034, 10.740497067000092],
              [45.581253629000059, 10.74176710100005],
              [45.58207021700008, 10.74275151900008],
              [45.582879233000085, 10.743489152000052],
              [45.583485996000036, 10.744250580000084],
              [45.584293205000051, 10.745153832000085],
              [45.584329626000056, 10.745150558000091],
              [45.584393393000084, 10.745271627000079],
              [45.584734899000068, 10.745615608000037],
              [45.58512782400004, 10.746011381000073],
              [45.585377667000046, 10.746332608000046],
              [45.585865457000068, 10.746832295000047],
              [45.586647806000087, 10.747733745000062],
              [45.586829139000088, 10.747950642000092],
              [45.586900523000054, 10.748319458000083],
              [45.58715036600006, 10.74889052900005],
              [45.58742400400007, 10.74930693400006],
              [45.587602464000042, 10.749568675000091],
              [45.588387686000033, 10.750651330000039],
              [45.588887372000045, 10.75129378400004],
              [45.589494135000052, 10.75225746600006],
              [45.589565519000075, 10.752697666000074],
              [45.590307464000034, 10.753354976000082],
              [45.59061248200004, 10.754137240000091],
              [45.591266833000077, 10.75497005200009],
              [45.591921185000047, 10.755564917000072],
              [45.592980045000047, 10.757218642000055],
              [45.593206094000038, 10.758194221000053],
              [45.592884867000066, 10.758705805000091],
              [45.59223051500004, 10.759074622000071],
              [45.592206721000082, 10.759455336000087],
              [45.592818019000049, 10.760051844000088],
              [45.593491630000074, 10.760407120000082],
              [45.593455938000034, 10.760847320000039],
              [45.593634397000073, 10.761216137000076],
              [45.594443414000068, 10.762013256000046],
              [45.595097766000038, 10.762132229000088],
              [45.595573658000035, 10.762703300000055],
              [45.596579941000073, 10.763184379000052],
              [45.59775086500008, 10.763988209000047],
              [45.598488498000052, 10.764226155000074],
              [45.599475974000086, 10.76447599800008],
              [45.600451553000084, 10.764939993000041],
              [45.600835568000036, 10.76502290500008],
              [45.601498516000049, 10.765166042000089],
              [45.603057063000051, 10.765177939000068],
              [45.603382026000077, 10.765085720000059],
              [45.60481786400004, 10.764678252000067],
              [45.606459692000044, 10.764523587000042],
              [45.608279980000077, 10.764380820000042],
              [45.610279438000077, 10.764242661000083],
              [45.612479728000039, 10.764226155000074],
              [45.614347605000034, 10.764428409000061],
              [45.614109659000064, 10.76460686900009],
              [45.614323811000077, 10.764761534000058],
              [45.614774585000077, 10.764538979000065],
              [45.615608720000068, 10.764797226000042],
              [45.61782161800005, 10.765011377000064],
              [45.619499138000037, 10.765344502000062],
              [45.621723934000045, 10.765749010000093],
              [45.623132104000035, 10.76591286300004],
              [45.624364233000051, 10.766274551000038],
              [45.625352612000086, 10.76673648600007],
              [45.628029506000075, 10.767676373000086],
              [45.630111534000036, 10.768521082000063],
              [45.631729568000083, 10.769234920000088],
              [45.63364503400004, 10.769841683000038],
              [45.63515258700005, 10.770592271000055],
              [45.636650969000073, 10.771226754000054],
              [45.638620272000082, 10.77190403600008],
              [45.639700762000075, 10.772435295000037],
              [45.641497255000047, 10.773470361000079],
              [45.643841024000039, 10.774505426000076],
              [45.64637515000004, 10.775516697000057],
              [45.647826622000082, 10.775849822000055],
              [45.648385795000081, 10.776492276000056],
              [45.651109581000071, 10.777246779000052],
              [45.651657554000053, 10.777289396000072],
              [45.65218033900004, 10.777425239000081],
              [45.653299382000057, 10.777682007000067],
              [45.654211749000069, 10.777738713000076],
              [45.654797175000056, 10.777839381000092],
              [45.65533169400004, 10.777864547000092],
              [45.656960704000085, 10.777990382000041],
              [45.658411541000078, 10.778191717000084],
              [45.659640645000081, 10.778550510000059],
              [45.662000454000065, 10.779047389000084],
              [45.664291249000087, 10.779928226000038],
              [45.66500633000004, 10.780477874000042],
              [45.666315034000036, 10.781037047000041],
              [45.667905616000041, 10.781639558000052],
              [45.668908646000034, 10.782179188000043],
              [45.669812841000066, 10.78255990200006],
              [45.670626384000059, 10.783051987000078],
              [45.671157237000045, 10.783309432000067],
              [45.673125879000054, 10.784390681000048],
              [45.675535445000037, 10.785665099000084],
              [45.676368256000046, 10.786212375000048],
              [45.677641268000059, 10.787544873000058],
              [45.67897376600007, 10.788294403000066],
              [45.680710773000044, 10.789626901000076],
              [45.683387666000044, 10.791328216000068],
              [45.683559869000078, 10.792081514000074],
              [45.684299531000079, 10.792729242000064],
              [45.685455426000033, 10.793914400000062],
              [45.686659425000073, 10.79490930500009],
              [45.688955605000046, 10.796527338000089],
              [45.688360740000064, 10.796420262000083],
              [45.688253664000058, 10.796586825000077],
              [45.689074578000088, 10.797348252000063],
              [45.689883595000083, 10.798276242000043],
              [45.690347590000044, 10.798490393000066],
              [45.691001942000071, 10.799287513000081],
              [45.691002980000064, 10.799302571000055],
              [45.691049531000033, 10.799977556000044],
              [45.691477834000068, 10.80067949700009],
              [45.691953726000065, 10.800905546000081],
              [45.69290551000006, 10.801583693000055],
              [45.694309392000036, 10.802630656000076],
              [45.695273074000056, 10.803296905000082],
              [45.696665059000054, 10.803927462000047],
              [45.697676330000036, 10.804676992000054],
              [45.698140809000051, 10.805281749000073],
              [45.698485346000041, 10.805462214000045],
              [45.699425233000056, 10.805973798000082],
              [45.700103380000087, 10.806556766000085],
              [45.700696116000074, 10.807246385000042],
              [45.701281213000073, 10.807449064000082],
              [45.701723579000088, 10.807373360000042],
              [45.701894822000042, 10.807838935000063],
              [45.702387662000035, 10.808341362000078],
              [45.703264772000068, 10.808685433000051],
              [45.704088977000083, 10.809150379000073],
              [45.704755226000088, 10.809769039000059],
              [45.70600444300004, 10.810447185000044],
              [45.708012129000053, 10.811499289000039],
              [45.708479083000043, 10.811743991000071],
              [45.709240510000086, 10.812148500000092],
              [45.710216089000085, 10.812767159000089],
              [45.711715150000089, 10.813480998000045],
              [45.712918975000036, 10.814132715000085],
              [45.713166621000084, 10.814516063000042],
              [45.713702, 10.814718317000086],
              [45.714652479000051, 10.815245240000081],
              [45.715855412000053, 10.815539231000059],
              [45.716176639000082, 10.815789075000055],
              [45.716271817000063, 10.816360146000079],
              [45.716842888000087, 10.817288135000069],
              [45.717985029000033, 10.817764028000056],
              [45.718591084000082, 10.817911634000041],
              [45.719794472000046, 10.818296445000044],
              [45.721224033000055, 10.81898398800007],
              [45.721378476000041, 10.819058267000059],
              [45.721588348000068, 10.819103808000079],
              [45.722541697000054, 10.81931067700009],
              [45.723053281000034, 10.819465342000058],
              [45.723453189000054, 10.819717136000065],
              [45.723695736000082, 10.819869850000089],
              [45.724528547000034, 10.820179180000082],
              [45.725694483000041, 10.820512305000079],
              [45.726193074000037, 10.820641815000045],
              [45.726297051000074, 10.820693212000037],
              [45.726409768000053, 10.820743357000083],
              [45.726884214000052, 10.821095273000083],
              [45.727383900000063, 10.821249938000051],
              [45.728204814000037, 10.821559268000044],
              [45.729147418000082, 10.821782272000064],
              [45.729822848000083, 10.821904290000077],
              [45.730244021000033, 10.821950573000038],
              [45.730905503000088, 10.822023263000062],
              [45.731153495000058, 10.822249690000092],
              [45.731179141000041, 10.822273106000068],
              [45.731640637000055, 10.822482106000052],
              [45.731939806000071, 10.822508300000038],
              [45.732107130000088, 10.822522950000064],
              [45.732594920000054, 10.822689512000068],
              [45.73264910100005, 10.82274158000007],
              [45.732773470000041, 10.822861098000089],
              [45.732856661000085, 10.823034534000044],
              [45.733332553000082, 10.823343864000037],
              [45.733856034000041, 10.823629399000083],
              [45.734462797000049, 10.823843550000049],
              [45.735343197000077, 10.823807858000066],
              [45.735795295000059, 10.823986318000038],
              [45.736532928000088, 10.824414621000074],
              [45.737532302000034, 10.824569286000042],
              [45.738260088000061, 10.824586977000081],
              [45.738712220000082, 10.824597968000091],
              [45.739420309000081, 10.824968562000038],
              [45.739440006000052, 10.824978871000042],
              [45.73947185600008, 10.824985515000037],
              [45.740328168000076, 10.825164151000081],
              [45.740951780000046, 10.82547224700005],
              [45.741152443000033, 10.825571385000046],
              [45.741344241000036, 10.825601324000047],
              [45.741965850000042, 10.825698353000064],
              [45.742600553000045, 10.825770914000088],
              [45.743124035000051, 10.826151628000048],
              [45.744777760000034, 10.826258703000065],
              [45.745384523000041, 10.826568033000058],
              [45.746289754000088, 10.826925700000061],
              [45.746526664000044, 10.827281872000071],
              [45.747102818000087, 10.82749526200007],
              [45.747811573000035, 10.827757764000069],
              [45.748442130000058, 10.827948121000077],
              [45.748846639000078, 10.828281245000085],
              [45.750167239000064, 10.828971289000037],
              [45.751356970000074, 10.829566154000076],
              [45.752320652000037, 10.830339479000088],
              [45.753717499000061, 10.83149340600005],
              [45.754959015000054, 10.83238215700004],
              [45.755937432000053, 10.833670724000058],
              [45.756864053000072, 10.834744158000092],
              [45.75816222800006, 10.83601449300005],
              [45.758792786000072, 10.837109045000091],
              [45.759347087000037, 10.838172150000048],
              [45.760246116000076, 10.839864970000065],
              [45.760631415000034, 10.841261540000062],
              [45.761059524000075, 10.842107942000041],
              [45.761199757000043, 10.844013698000083],
              [45.761285379000071, 10.845537206000074],
              [45.761273579000033, 10.846763113000065],
              [45.761190491000036, 10.847531648000086],
              [45.761160349000079, 10.848351999000045],
              [45.761088965000056, 10.84919670700009],
              [45.761790906000044, 10.851207352000074],
              [45.762005058000057, 10.852230520000091],
              [45.763777756000081, 10.853563018000045],
              [45.764347938000071, 10.853571829000089],
              [45.765038871000058, 10.853812862000041],
              [45.765395790000071, 10.854312549000042],
              [45.765685780000069, 10.854703845000074],
              [45.76600686200004, 10.854926016000093],
              [45.766402863000053, 10.855137607000074],
              [45.766691837000053, 10.855592527000056],
              [45.767061413000079, 10.856001966000065],
              [45.767652407000071, 10.856559892000064],
              [45.76876272700008, 10.857156004000046],
              [45.769150789000037, 10.858041018000051],
              [45.770023842000057, 10.858726449000073],
              [45.770904242000086, 10.859202341000071],
              [45.771641875000057, 10.85934510900006],
              [45.772772119000081, 10.859392698000079],
              [45.77356923800005, 10.859535465000079],
              [45.774759021000079, 10.860029947000044],
              [45.776128970000059, 10.860241534000068],
              [45.777413298000056, 10.860326169000075],
              [45.778066420000073, 10.860511044000077],
              [45.778685080000059, 10.860558634000085],
              [45.779696351000041, 10.860701401000085],
              [45.780379090000054, 10.860736171000042],
              [45.780854941000086, 10.860760405000065],
              [45.782909865000079, 10.860929675000079],
              [45.784062661000064, 10.860808477000091],
              [45.785288084000058, 10.860951245000081],
              [45.786108998000088, 10.861022629000047],
              [45.787417701000038, 10.861355753000055],
              [45.788631227000053, 10.861629391000065],
              [45.788644955000052, 10.861631515000056],
              [45.790784639000037, 10.861962516000062],
              [45.791462785000078, 10.862367024000037],
              [45.792569235000087, 10.862521689000062],
              [45.794020706000083, 10.862795327000072],
              [45.79491300400008, 10.862831019000055],
              [45.795912377000036, 10.863413987000058],
              [45.797090210000079, 10.863818495000089],
              [45.797499028000061, 10.863229551000074],
              [45.797970611000039, 10.862795327000072],
              [45.799660028000062, 10.862926197000093],
              [45.800980629000037, 10.863759009000091],
              [45.801634981000063, 10.864651307000088],
              [45.801944311000057, 10.865424631000053],
              [45.802053663000038, 10.866208898000082],
              [45.802765225000087, 10.86644780000006],
              [45.80353302900005, 10.866987767000069],
              [45.804383259000076, 10.867316303000052],
              [45.805085199000075, 10.868422752000072],
              [45.805430221000051, 10.86843465000004],
              [45.805606969000053, 10.867986190000067],
              [45.806035079000083, 10.867647659000056],
              [45.80653667100006, 10.866971281000076],
              [45.806661112000086, 10.866914551000093],
              [45.807345687000065, 10.866602465000085],
              [45.808646545000045, 10.865701098000045],
              [45.810022581000055, 10.86473458800009],
              [45.811069544000077, 10.86400885200004],
              [45.81244963100005, 10.862914300000057],
              [45.813698848000058, 10.861819748000073],
              [45.815578622000032, 10.860618120000083],
              [45.817232348000061, 10.859190444000092],
              [45.818814689000078, 10.857965021000041],
              [45.820028215000036, 10.85694185300008],
              [45.821729529000038, 10.855621252000049],
              [45.82251728600005, 10.854994783000052],
              [45.823716379000075, 10.853919937000057],
              [45.824775239000076, 10.85306333200009],
              [45.825988764000044, 10.852194828000052],
              [45.827107111000089, 10.85118355700007],
              [45.828142177000075, 10.85067197300009],
              [45.829534161000083, 10.849827265000044],
              [45.830462151000063, 10.849042042000065],
              [45.831616190000034, 10.848399588000063],
              [45.832889201000057, 10.847792825000056],
              [45.834935538000082, 10.846876733000045],
              [45.836547469000038, 10.845807512000079],
              [45.838147810000066, 10.844949369000062],
              [45.839460953000071, 10.844433399000081],
              [45.840634347000048, 10.843581179000068],
              [45.841396905000067, 10.843230083000037],
              [45.842852476000076, 10.84242600400006],
              [45.844286820000036, 10.842105914000058],
              [45.845072042000083, 10.841630021000071],
              [45.845248403000085, 10.84152651800008],
              [45.845904853000036, 10.841154129000074],
              [45.84696232500005, 10.840775518000044],
              [45.84811822000006, 10.84022535400004],
              [45.849676299000066, 10.839547993000053],
              [45.850882108000064, 10.838543073000039],
              [45.851952382000036, 10.837527377000072],
              [45.852852879000068, 10.836359515000083],
              [45.853555208000046, 10.835062228000083],
              [45.854154561000087, 10.834512053000083],
              [45.855351313000085, 10.833670724000058],
              [45.855695754000067, 10.832734559000073],
              [45.857421444000067, 10.831457826000076],
              [45.857956823000052, 10.830779679000045],
              [45.85809316600006, 10.830316596000046],
              [45.857065704000036, 10.828623717000085],
              [45.856279303000065, 10.827174796000065],
              [45.855815308000047, 10.824854821000088],
              [45.854887318000067, 10.823498529000062],
              [45.853940506000072, 10.822407955000074],
              [45.852174733000084, 10.821928084000092],
              [45.847392016000072, 10.821785317000092],
              [45.846963713000036, 10.820893019000039],
              [45.847177865000049, 10.819358266000052],
              [45.847998779000079, 10.818929963000073],
              [45.850068910000061, 10.819144115000086],
              [45.851183891000062, 10.818642983000075],
              [45.853531026000041, 10.817216751000046],
              [45.855279929000062, 10.816502913000079],
              [45.857171601000061, 10.815931843000044],
              [45.858170974000075, 10.814361398000074],
              [45.859313116000067, 10.813647560000049],
              [45.861062020000077, 10.813718944000072],
              [45.862061393000033, 10.813540484000043],
              [45.863025075000053, 10.813147873000048],
              [45.864095832000032, 10.812041424000085],
              [45.865701969000042, 10.810863591000043],
              [45.868236095000043, 10.810685131000071],
              [45.873197271000038, 10.813112181000065],
              [45.87433941200004, 10.811541737000084],
              [45.877658760000088, 10.811470353000061],
              [45.87892645800008, 10.813996115000066],
              [45.878984899000045, 10.81411255200004],
              [45.878728685000056, 10.815590135000036],
              [45.878739733000089, 10.815583391000075],
              [45.87849391900005, 10.816318838000086],
              [45.878428357000075, 10.816440367000041],
              [45.877315273000079, 10.81690592800004],
              [45.87667310900008, 10.817540783000084],
              [45.87650186500008, 10.818683517000068],
              [45.876202189000082, 10.819360691000043],
              [45.875752723000062, 10.820484891000092],
              [45.874128830000075, 10.823552661000065],
              [45.873379300000067, 10.823588353000048],
              [45.872918909000077, 10.824237875000051],
              [45.873019353000075, 10.824524801000052],
              [45.873224635000042, 10.825111208000067],
              [45.871951623000086, 10.825242079000077],
              [45.871865893000063, 10.825245481000081],
              [45.868953502000068, 10.825361052000062],
              [45.866121943000053, 10.824623419000091],
              [45.864129507000087, 10.823973898000077],
              [45.863278488000049, 10.823731121000037],
              [45.862909671000068, 10.824647213000048],
              [45.863135720000059, 10.826550782000083],
              [45.863028644000053, 10.828287789000058],
              [45.862790698000083, 10.829560800000081],
              [45.861410611000053, 10.831488164000064],
              [45.858949385000074, 10.833496344000082],
              [45.857969878000063, 10.834174156000074],
              [45.857665057000077, 10.834385090000069],
              [45.856594784000038, 10.83501990700006],
              [45.855695754000067, 10.83518919100004],
              [45.855267645000083, 10.835612401000049],
              [45.856252297000083, 10.835908648000043],
              [45.857181872000069, 10.835777371000063],
              [45.857451002000062, 10.835739364000062],
              [45.858778141000073, 10.835570080000082],
              [45.859377494000057, 10.835273833000088],
              [45.860276524000085, 10.835062228000083],
              [45.861732095000036, 10.83485062200009],
              [45.863187667000034, 10.834765980000043],
              [45.864099402000079, 10.834843204000038],
              [45.865051186000073, 10.834866998000052],
              [45.865381771000045, 10.834854754000048],
              [45.866014868000036, 10.834831306000069],
              [45.866419376000067, 10.83481940900009],
              [45.866883371000085, 10.834712333000084],
              [45.868037410000056, 10.834403003000091],
              [45.869465086000048, 10.834379209000076],
              [45.870916557000044, 10.83427213300007],
              [45.87157090900007, 10.83492648500004],
              [45.872320439000077, 10.835271507000073],
              [45.873593451000033, 10.835033561000046],
              [45.874830771000063, 10.834533874000044],
              [45.876758134000056, 10.833415527000056],
              [45.878085869000074, 10.832734559000073],
              [45.880910294000046, 10.831690418000051],
              [45.882647300000087, 10.830917093000039],
              [45.884622253000089, 10.830048590000047],
              [45.885336091000056, 10.829929617000062],
              [45.886347362000038, 10.829453725000064],
              [45.888072471000044, 10.828668502000085],
              [45.890951619000077, 10.82768102600005],
              [45.892384719000063, 10.82706343600006],
              [45.894044918000077, 10.826741139000092],
              [45.895222752000052, 10.826812523000058],
              [45.896840785000052, 10.82647939800006],
              [45.898553997000079, 10.825622792000047],
              [45.900445668000089, 10.824813776000042],
              [45.901932832000057, 10.82421891000007],
              [45.902848924000068, 10.824088040000049],
              [45.903741222000065, 10.823576456000069],
              [45.904978542000038, 10.82316005000007],
              [45.906370526000046, 10.822588980000091],
              [45.907917176000069, 10.821696682000038],
              [45.909451928000067, 10.820804384000041],
              [45.910915297000088, 10.819852599000058],
              [45.912759379000079, 10.819114966000086],
              [45.914089859000057, 10.818683517000068],
              [45.915364889000045, 10.818032312000071],
              [45.916661695000073, 10.81738985700008],
              [45.918172653000056, 10.816485662000048],
              [45.919600330000037, 10.815557672000068],
              [45.920616234000079, 10.814949667000064],
              [45.922328671000059, 10.813764593000087],
              [45.924014230000068, 10.812928368000087],
              [45.925144474000035, 10.812143146000039],
              [45.926524561000065, 10.811119978000079],
              [45.929046790000086, 10.810073015000057],
              [45.930807591000075, 10.808728619000078],
              [45.933194683000067, 10.80681158200008],
              [45.936406956000042, 10.80437263500005],
              [45.938667444000089, 10.802707012000042],
              [45.941277219000085, 10.801078652000058],
              [45.942788177000068, 10.799984100000074],
              [45.943478221000078, 10.799353543000052],
              [45.944430005000072, 10.798865753000086],
              [45.947095001000037, 10.796807520000073],
              [45.94771366100008, 10.796141270000078],
              [45.949712409000085, 10.794927745000052],
              [45.952748127000064, 10.793246049000061],
              [45.955899007000085, 10.790906456000073],
              [45.957933446000084, 10.789990364000062],
              [45.960002383000074, 10.788441772000056],
              [45.962870715000065, 10.786790984000049],
              [45.964548348000051, 10.785743026000091],
              [45.965916538000045, 10.784910215000082],
              [45.966915912000047, 10.784208274000036],
              [45.967731951000076, 10.783637652000039],
              [45.968459737000046, 10.78304505400007],
              [45.969315956000059, 10.78262176800007],
              [45.97030060700007, 10.781859853000071],
              [45.971686731000034, 10.781198255000049],
              [45.972781283000074, 10.780520109000065],
              [45.973887732000037, 10.779960936000066],
              [45.974661057000048, 10.779639708000047],
              [45.975351101000058, 10.779401762000077],
              [45.976100631000065, 10.779104330000052],
              [45.97745692400008, 10.778913973000044],
              [45.978777525000055, 10.778557054000089],
              [45.979753104000054, 10.778366697000081],
              [45.981133191000083, 10.778414286000043],
              [45.981513327000073, 10.778275521000069],
              [45.982227743000067, 10.77837859400006],
              [45.983905263000054, 10.77865223200007],
              [45.985261556000069, 10.778604643000051],
              [45.98627282700005, 10.778604643000051],
              [45.986668024000039, 10.77858083600006],
              [45.987260303000085, 10.778545156000064],
              [45.988414342000056, 10.778533259000085],
              [45.990008581000041, 10.778509464000081],
              [45.991376771000034, 10.778426183000079],
              [45.991710887000067, 10.778576733000079],
              [45.991713424000068, 10.778579361000084],
              [45.995921541000087, 10.778557054000089],
              [45.999264684000082, 10.778319108000062],
              [46.000204971000073, 10.778200309000056],
              [46.001132561000077, 10.77786701000008],
              [46.002191421000077, 10.777545783000051],
              [46.002718531000085, 10.777372676000084],
              [46.012756228000057, 10.776831944000037],
              [46.013910267000085, 10.776724869000077],
              [46.014969127000086, 10.776427436000063],
              [46.016420598000082, 10.776165695000088],
              [46.017300998000053, 10.776106209000091],
              [46.018383653000058, 10.775927749000061],
              [46.019442513000058, 10.775737392000053],
              [46.019853201000046, 10.775695862000077],
              [46.020501374000048, 10.775630317000036],
              [46.02169110400007, 10.775106835000088],
              [46.023118781000051, 10.774833197000078],
              [46.02446317600004, 10.774678532000053],
              [46.025652907000051, 10.774440586000082],
              [46.026699869000083, 10.774000386000068],
              [46.027461297000059, 10.773679159000039],
              [46.028246519000049, 10.773476904000063],
              [46.02888897400004, 10.773286548000044],
              [46.029590915000085, 10.773084293000068],
              [46.03026906100007, 10.772858245000066],
              [46.030875823000088, 10.772691682000072],
              [46.031051338000054, 10.772608544000093],
              [46.031101872000079, 10.772584607000056],
              [46.031387408000057, 10.772548915000073],
              [46.031780019000053, 10.772525120000068],
              [46.032279705000064, 10.772418044000062],
              [46.033279079000067, 10.772215790000075],
              [46.03455209100008, 10.771965947000069],
              [46.035563362000062, 10.771644719000051],
              [46.03653894100006, 10.771382979000066],
              [46.037550212000042, 10.771133135000071],
              [46.038454407000074, 10.770895189000044],
              [46.039263424000069, 10.770681038000077],
              [46.039270381000051, 10.770679099000063],
              [46.039989159000072, 10.77047878400009],
              [46.041036122000037, 10.770264632000078],
              [46.041916523000054, 10.770228940000038],
              [46.042808820000062, 10.770145659000093],
              [46.043534556000054, 10.770121864000089],
              [46.044188908000081, 10.769955302000085],
              [46.044616744000052, 10.769824859000039],
              [46.045307254000079, 10.769729253000037],
              [46.046247141000038, 10.769408026000065],
              [46.046770623000043, 10.76928905300008],
              [46.047448769000084, 10.769122491000076],
              [46.049034119000055, 10.768907547000083],
              [46.051172626000039, 10.768408652000062],
              [46.052015964000077, 10.768523375000086],
              [46.053897109000047, 10.768063631000075],
              [46.055895856000063, 10.767718609000042],
              [46.057537684000067, 10.767337895000082],
              [46.060048015000064, 10.766778722000083],
              [46.061158676000048, 10.766538706000063],
              [46.062855779000074, 10.766171959000076],
              [46.064402142000063, 10.765853104000087],
              [46.065270932000033, 10.765826937000043],
              [46.06629410100004, 10.765624683000055],
              [46.067448139000078, 10.765315353000062],
              [46.068364232000079, 10.76523207200006],
              [46.070148827000082, 10.764898947000063],
              [46.071261781000032, 10.764800527000091],
              [46.071909629000061, 10.764779974000078],
              [46.072563980000041, 10.76499412600009],
              [46.073004181000044, 10.765124996000054],
              [46.073057101000074, 10.765173105000088],
              [46.07339679200004, 10.765481915000066],
              [46.073825095000075, 10.765791245000059],
              [46.074289090000036, 10.765945910000084],
              [46.074919647000058, 10.765993500000093],
              [46.075490717000037, 10.765862629000083],
              [46.075895226000057, 10.765672272000074],
              [46.076252145000069, 10.765553299000089],
              [46.084389902000055, 10.764637207000078],
              [46.092008049000071, 10.763980308000043],
              [46.092044181000063, 10.763993968000079],
              [46.093312880000042, 10.764018547000092],
              [46.095683110000039, 10.764036300000043],
              [46.098166144000061, 10.764163293000081],
              [46.100927156000068, 10.764304082000081],
              [46.103945619000058, 10.764332618000083],
              [46.105829300000039, 10.76467126700004],
              [46.107791901000041, 10.764851358000044],
              [46.110397410000076, 10.764863256000069],
              [46.112705487000085, 10.765636580000091],
              [46.115560841000047, 10.766029191000086],
              [46.116762468000047, 10.765969705000089],
              [46.118225837000068, 10.766290932000061],
              [46.12167605500008, 10.766731132000075],
              [46.121997283000042, 10.766647851000073],
              [46.12462658700008, 10.767076154000051],
              [46.126732410000045, 10.767766198000061],
              [46.127779373000067, 10.768027939000092],
              [46.129996328000061, 10.767972483000051],
              [46.137908354000047, 10.76959643400005],
              [46.137937495000074, 10.769581637000044],
              [46.139022326000088, 10.769741151000062],
              [46.140759333000062, 10.769729253000037],
              [46.142520134000051, 10.770050481000055],
              [46.143617961000075, 10.770102758000064],
              [46.144019194000066, 10.770121864000089],
              [46.145815687000038, 10.770300324000061],
              [46.147945305000064, 10.77072862700004],
              [46.149551441000085, 10.771133135000071],
              [46.150431842000046, 10.771335389000058],
              [46.150506277000034, 10.771516159000043],
              [46.150681685000052, 10.771942152000065],
              [46.151074296000047, 10.771989741000084],
              [46.151483804000065, 10.771716736000087],
              [46.151502599000082, 10.771704206000038],
              [46.153727395000033, 10.772025433000067],
              [46.155761834000089, 10.772358558000064],
              [46.157271359000049, 10.772855461000063],
              [46.157778405000045, 10.773008314000037],
              [46.158069912000087, 10.773096191000093],
              [46.158926518000044, 10.773274650000076],
              [46.159599825000043, 10.773560671000041],
              [46.15999727500008, 10.773774337000077],
              [46.160437475000037, 10.773917105000066],
              [46.160758702000066, 10.774036078000051],
              [46.161163211000087, 10.774262127000043],
              [46.161543925000046, 10.774488175000045],
              [46.16198412500006, 10.774607148000086],
              [46.162497500000086, 10.774792837000064],
              [46.163302568000063, 10.774830743000052],
              [46.163576213000056, 10.775035210000055],
              [46.164496291000034, 10.775073116000044],
              [46.164591643000051, 10.775073116000044],
              [46.169063021000056, 10.776082414000086],
              [46.170359828000073, 10.776153798000053],
              [46.171478174000072, 10.776141901000074],
              [46.172822570000051, 10.776379847000044],
              [46.173917122000034, 10.776510717000065],
              [46.175309107000032, 10.776689177000037],
              [46.176118123000037, 10.776986609000062],
              [46.177069908000078, 10.777200761000074],
              [46.178282116000048, 10.777378175000081],
              [46.180739902000084, 10.777739243000042],
              [46.181971597000086, 10.778009778000069],
              [46.183244609000042, 10.778128751000054],
              [46.184351058000061, 10.778200134000087],
              [46.186195141000042, 10.778557054000089],
              [46.188562704000049, 10.778830692000042],
              [46.189788127000043, 10.779009151000082],
              [46.191310982000061, 10.779187611000054],
              [46.193107475000033, 10.779425557000081],
              [46.194975352000085, 10.77971109200007],
              [46.196486309000079, 10.779996628000049],
              [46.198175727000034, 10.780603390000067],
              [46.199456370000064, 10.781232376000048],
              [46.202539185000035, 10.782534466000072],
              [46.205150651000054, 10.782576794000079],
              [46.207762118000062, 10.782407480000074],
              [46.209490064000079, 10.782197629000052],
              [46.210543354000038, 10.781894415000068],
              [46.213267805000044, 10.781863643000065],
              [46.217240951000065, 10.781481476000067],
              [46.221006655000053, 10.780996001000062],
              [46.225599015000057, 10.78067477400009],
              [46.227347919000067, 10.781174461000091],
              [46.227788119000081, 10.78132912600006],
              [46.228573341000072, 10.781959683000082],
              [46.228954055000088, 10.782709213000089],
              [46.229727380000043, 10.783375462000038],
              [46.231564994000053, 10.783254051000085],
              [46.233619918000045, 10.783084737000081],
              [46.235246733000054, 10.782619123000075],
              [46.236916359000077, 10.782153508000079],
              [46.238233953000076, 10.781686045000072],
              [46.239554554000051, 10.78118635800007],
              [46.240555288000053, 10.780798989000061],
              [46.241325885000037, 10.780375700000093],
              [46.241925238000078, 10.780079398000055],
              [46.242438969000034, 10.779867753000076],
              [46.24333789700006, 10.779270892000056],
              [46.244515730000046, 10.778604643000051],
              [46.246062380000069, 10.777819421000061],
              [46.247080522000033, 10.777342632000057],
              [46.247252993000075, 10.777297158000067],
              [46.247533950000047, 10.777191330000051],
              [46.247788150000076, 10.777072274000091],
              [46.248363444000063, 10.776728335000087],
              [46.248684538000077, 10.776503451000053],
              [46.249019011000087, 10.776265339000076],
              [46.249219695000079, 10.77611982600007],
              [46.249540789000037, 10.775908170000037],
              [46.249794989000065, 10.77573620000004],
              [46.249942157000078, 10.77564360000008],
              [46.25022311500004, 10.775511315000074],
              [46.250570967000044, 10.775445173000037],
              [46.250905440000054, 10.775379030000067],
              [46.251226534000068, 10.775259974000051],
              [46.251694797000084, 10.775048317000085],
              [46.252056028000084, 10.774836661000052],
              [46.252163059000054, 10.774625004000086],
              [46.252444016000084, 10.774373662000073],
              [46.252684837000061, 10.774228148000077],
              [46.25307282600005, 10.774095862000081],
              [46.253340405000074, 10.773963577000075],
              [46.253500952000081, 10.773831291000079],
              [46.253795288000049, 10.773606405000066],
              [46.253888941000071, 10.77348734800006],
              [46.254263550000076, 10.773288919000038],
              [46.254544508000038, 10.773196319000078],
              [46.255012770000064, 10.773064033000082],
              [46.255347244000063, 10.77290528900005],
              [46.255574685000056, 10.772746546000064],
              [46.25594929500005, 10.772481973000083],
              [46.256109842000058, 10.772349687000087],
              [46.256297147000055, 10.772151257000075],
              [46.256484452000052, 10.77201897100008],
              [46.256979473000058, 10.771608882000066],
              [46.257367462000047, 10.771436909000045],
              [46.257862482000064, 10.771198793000053],
              [46.258130060000042, 10.771000362000052],
              [46.258223713000064, 10.770841618000077],
              [46.258330744000034, 10.770762246000061],
              [46.258709215000067, 10.770454989000086],
              [46.258959058000073, 10.770264632000078],
              [46.259164727000041, 10.770127139000067],
              [46.25939216900008, 10.770008080000082],
              [46.259732383000085, 10.76980063700006],
              [46.260041713000078, 10.76952699900005],
              [46.260243967000065, 10.769396129000086],
              [46.260422427000037, 10.76925336100004],
              [46.260755551000045, 10.769027312000048],
              [46.260993497000072, 10.768896442000084],
              [46.261302827000065, 10.768622804000074],
              [46.261488171000053, 10.768334767000056],
              [46.261876160000043, 10.767990818000044],
              [46.262558485000056, 10.767554266000047],
              [46.263535147000084, 10.766892822000045],
              [46.263990030000059, 10.766515799000047],
              [46.264645598000072, 10.766079245000071],
              [46.265408197000056, 10.76545748500007],
              [46.266505269000049, 10.764425625000058],
              [46.267254488000049, 10.763909694000063],
              [46.268338182000036, 10.762970432000088],
              [46.269756348000044, 10.761806273000047],
              [46.270625978000055, 10.76111835800009],
              [46.271040725000034, 10.760800859000085],
              [46.272097660000043, 10.759874816000092],
              [46.273069262000035, 10.75909306300008],
              [46.274865755000064, 10.75776056400008],
              [46.276233945000058, 10.75679688300005],
              [46.276888297000085, 10.756190120000042],
              [46.277459368000052, 10.755726125000081],
              [46.277908392000086, 10.755277101000047],
              [46.278387357000042, 10.754798135000044],
              [46.27954139600007, 10.75370358300006],
              [46.280671640000037, 10.752656621000085],
              [46.281920857000046, 10.751550171000076],
              [46.282563311000047, 10.751062382000043],
              [46.28290833300008, 10.751288431000091],
              [46.28327715000006, 10.75116945700006],
              [46.283372328000041, 10.750860128000056],
              [46.283538890000045, 10.750408030000074],
              [46.28383632300006, 10.750110597000059],
              [46.284193242000072, 10.749753678000047],
              [46.284669134000069, 10.749170710000044],
              [46.285561432000065, 10.748135645000048],
              [46.286655984000049, 10.746969709000041],
              [46.287095437000062, 10.746400575000052],
              [46.288143147000085, 10.74539926500006],
              [46.289011651000067, 10.744542659000047],
              [46.290332252000042, 10.743269647000091],
              [46.292104950000066, 10.741342284000041],
              [46.294103697000082, 10.739188871000067],
              [46.295055482000066, 10.737951552000084],
              [46.296114342000067, 10.736714232000054],
              [46.297196997000071, 10.735821934000057],
              [46.297458737000056, 10.735369836000075],
              [46.298267754000051, 10.734620306000068],
              [46.300190910000083, 10.732201524000061],
              [46.302883908000069, 10.729337903000044],
              [46.304900112000041, 10.727227034000066],
              [46.306691046000083, 10.725376100000062],
              [46.309522605000041, 10.722544542000037],
              [46.312496931000055, 10.719356064000067],
              [46.314590857000042, 10.717095576000077],
              [46.317434312000046, 10.713954687000069],
              [46.31945582700007, 10.711689155000045],
              [46.320164334000083, 10.711046134000071],
              [46.321967186000052, 10.709409917000073],
              [46.322718165000083, 10.708671249000076],
              [46.324144392000051, 10.707268402000068],
              [46.326669711000079, 10.704704595000067],
              [46.328082472000062, 10.703053345000058],
              [46.329569564000053, 10.701640977000068],
              [46.330937754000047, 10.700391760000059],
              [46.332151279000072, 10.699356694000073],
              [46.333424290000039, 10.698416807000058],
              [46.33451884200008, 10.69781004400005],
              [46.33539924300004, 10.69739363900004],
              [46.336624665000045, 10.696596519000082],
              [46.33678221200006, 10.696536907000052],
              [46.33838546700008, 10.695930270000076],
              [46.339682273000051, 10.694990383000061],
              [46.340883901000041, 10.694240853000053],
              [46.341692917000046, 10.693776858000092],
              [46.343267552000043, 10.692673323000065],
              [46.343295153000042, 10.692673323000065],
              [46.343494759000066, 10.692623968000078],
              [46.34376921900008, 10.692500582000037],
              [46.344068629000049, 10.692327842000054],
              [46.344442891000085, 10.692105747000085],
              [46.344892007000055, 10.69183429800006],
              [46.344966859000067, 10.691760266000074],
              [46.345761796000033, 10.691575857000089],
              [46.346630299000083, 10.690969094000081],
              [46.346888074000049, 10.690723821000063],
              [46.347237386000074, 10.690600434000089],
              [46.347736748000045, 10.690481305000048],
              [46.34803418100006, 10.690350434000038],
              [46.348310272000049, 10.690254951000043],
              [46.348709486000075, 10.690082210000071],
              [46.349081144000081, 10.690112488000068],
              [46.349497549000034, 10.689981618000047],
              [46.349757422000039, 10.689835436000067],
              [46.350056832000064, 10.689736726000092],
              [46.350544512000056, 10.689565212000048],
              [46.351175069000078, 10.689315369000042],
              [46.353424072000053, 10.688574791000065],
              [46.354042320000076, 10.688423071000045],
              [46.356017273000077, 10.688089946000048],
              [46.357980376000057, 10.687634863000085],
              [46.35863468000008, 10.687483184000087],
              [46.361121216000072, 10.687471286000061],
              [46.361728065000079, 10.687589570000057],
              [46.362525098000049, 10.687744924000071],
              [46.364749895000045, 10.688232714000037],
              [46.366439312000068, 10.688113741000052],
              [46.368104935000076, 10.687959076000084],
              [46.369699173000072, 10.68779251400008],
              [46.372447451000085, 10.687673541000038],
              [46.375052961000051, 10.687483184000087],
              [46.375473194000051, 10.687423956000089],
              [46.376825659000076, 10.687233340000091],
              [46.378669741000067, 10.687054881000051],
              [46.380846948000055, 10.686697962000039],
              [46.381513197000061, 10.68659088600009],
              [46.38244118700004, 10.686912113000062],
              [46.383453674000066, 10.686957721000056],
              [46.383761788000072, 10.686971600000049],
              [46.386926471000038, 10.685912739000059],
              [46.388354148000076, 10.685567718000073],
              [46.389829414000076, 10.685103723000054],
              [46.391459344000054, 10.684639728000093],
              [46.393243940000048, 10.684009171000071],
              [46.395789963000084, 10.683128770000053],
              [46.397003488000053, 10.682890824000083],
              [46.397384202000069, 10.682926516000066],
              [46.39815752700008, 10.682522007000046],
              [46.401393594000069, 10.682498213000088],
              [46.405533856000034, 10.68254580200005],
              [46.407068609000078, 10.682426829000065],
              [46.407711063000079, 10.682343548000063],
              [46.408441810000056, 10.682382770000061],
              [46.408865975000083, 10.682308736000039],
              [46.409215286000062, 10.68228405800005],
              [46.409489746000077, 10.682234702000073],
              [46.409814107000045, 10.682160668000051],
              [46.409988763000058, 10.682135990000063],
              [46.410437878000039, 10.682012600000064],
              [46.410562632000051, 10.682037278000053],
              [46.411161452000044, 10.681938566000042],
              [46.411549594000064, 10.682023876000073],
              [46.412969672000088, 10.68189145000008],
              [46.415352304000066, 10.681796579000093],
              [46.417835338000089, 10.681838921000065],
              [46.420232750000082, 10.681669550000038],
              [46.42322951500006, 10.681584864000058],
              [46.425521329000048, 10.681546428000047],
              [46.427508179000085, 10.681486942000049],
              [46.428900163000037, 10.681558326000072],
              [46.431148754000048, 10.681629710000038],
              [46.43239105400005, 10.681669550000038],
              [46.433632570000043, 10.681711893000056],
              [46.435407989000055, 10.681879553000044],
              [46.436286848000066, 10.682177664000051],
              [46.438427394000087, 10.682474064000075],
              [46.440268264000053, 10.682728120000093],
              [46.441980701000034, 10.683024519000071],
              [46.443307840000045, 10.68315154700008],
              [46.444121248000044, 10.683532631000048],
              [46.445448386000066, 10.683998400000064],
              [46.446448688000032, 10.683890198000086],
              [46.449172937000071, 10.684379483000043],
              [46.451099429000067, 10.684675880000043],
              [46.453239975000088, 10.684972277000043],
              [46.454652736000071, 10.685353359000089],
              [46.45602268500005, 10.68573444000009],
              [46.457649501000049, 10.686157863000062],
              [46.459618803000069, 10.686750654000036],
              [46.46094594200008, 10.687258759000088],
              [46.462950250000063, 10.687959076000084],
              [46.465312657000084, 10.688613703000044],
              [46.467667258000063, 10.689418199000045],
              [46.469061706000048, 10.689772841000092],
              [46.470064839000088, 10.69005300200007],
              [46.471006510000052, 10.690603766000038],
              [46.47246208200005, 10.691154207000068],
              [46.474217330000045, 10.691831671000045],
              [46.475758523000081, 10.692339768000068],
              [46.477299716000061, 10.692890206000072],
              [46.479606477000061, 10.693717371000048],
              [46.48145237600005, 10.694287465000059],
              [46.482993570000076, 10.695007263000036],
              [46.484534763000056, 10.695515355000055],
              [46.485876357000052, 10.69609683200008],
              [46.487017797000078, 10.696658558000081],
              [46.488612737000039, 10.697500714000057],
              [46.490052311000056, 10.698583369000062],
              [46.491087377000042, 10.69955894800006],
              [46.492348491000087, 10.700463143000093],
              [46.493139759000087, 10.700850265000042],
              [46.494561390000058, 10.701462517000039],
              [46.496179423000058, 10.702176355000063],
              [46.497381051000048, 10.702652248000049],
              [46.498535090000075, 10.70305675600008],
              [46.500075130000084, 10.703433005000079],
              [46.501701945000036, 10.704406819000042],
              [46.503243138000073, 10.705168932000049],
              [46.504912764000039, 10.706058062000068],
              [46.506220749000079, 10.706495077000056],
              [46.507866718000059, 10.707031867000069],
              [46.509302151000043, 10.707696705000046],
              [46.511181925000074, 10.708767462000083],
              [46.513260895000087, 10.709699232000048],
              [46.516024128000083, 10.71099225800009],
              [46.518226963000075, 10.712112541000067],
              [46.519596912000054, 10.712874634000059],
              [46.52070999700004, 10.713298019000092],
              [46.522122757000034, 10.713806079000051],
              [46.523971528000061, 10.714335401000085],
              [46.525839405000056, 10.714954061000071],
              [46.527260068000032, 10.715711299000077],
              [46.528211963000047, 10.716195437000067],
              [46.528314288000047, 10.716190248000089],
              [46.528458774000057, 10.716190248000089],
              [46.528603261000058, 10.716237878000072],
              [46.528806613000086, 10.716253755000082],
              [46.528918992000058, 10.716349016000038],
              [46.528945772000043, 10.716377438000052],
              [46.529785913000069, 10.716558059000079],
              [46.531883649000065, 10.717193128000076],
              [46.534595821000039, 10.717666647000044],
              [46.537696752000045, 10.718117173000053],
              [46.541067955000074, 10.718832582000061],
              [46.546695380000074, 10.719986621000089],
              [46.548922398000059, 10.720876500000088],
              [46.551942092000047, 10.721485681000047],
              [46.554964007000081, 10.722282801000063],
              [46.557919909000077, 10.72283204900009],
              [46.560274510000056, 10.723128408000036],
              [46.561979731000065, 10.723670751000043],
              [46.563970267000059, 10.724043602000052],
              [46.565850041000033, 10.724650365000059],
              [46.566266447000032, 10.724876413000061],
              [46.566992183000082, 10.725138154000092],
              [46.567337205000058, 10.725280922000081],
              [46.567741713000032, 10.725423689000081],
              [46.568788676000054, 10.725483176000068],
              [46.569359746000089, 10.72556645700007],
              [46.569776152000088, 10.725697327000091],
              [46.570537580000064, 10.725744917000043],
              [46.571251418000088, 10.725816300000076],
              [46.573024116000056, 10.726137528000038],
              [46.574186088000033, 10.726693943000043],
              [46.574775711000086, 10.72683577500004],
              [46.575508756000033, 10.726898812000059],
              [46.57581153600006, 10.726961848000087],
              [46.576289608000081, 10.727024885000048],
              [46.576592388000051, 10.727087922000067],
              [46.576831424000034, 10.727103681000074],
              [46.576990782000053, 10.727103681000074],
              [46.577153391000081, 10.72700579800005],
              [46.578151558000059, 10.727203219000046],
              [46.579221831000041, 10.727330229000074],
              [46.580650289000062, 10.727232080000078],
              [46.582261407000033, 10.727499574000092],
              [46.58361271800004, 10.727707972000076],
              [46.584790551000083, 10.728136275000054],
              [46.586360995000064, 10.728719243000057],
              [46.587681596000039, 10.72917134000005],
              [46.58924014300004, 10.729385492000063],
              [46.590786793000063, 10.729694822000056],
              [46.592821232000063, 10.729956563000087],
              [46.594094244000075, 10.72979],
              [46.595498126000052, 10.729575849000071],
              [46.597128056000088, 10.729230827000038],
              [46.599364750000063, 10.728743037000072],
              [46.601375394000058, 10.728612167000051],
              [46.603493115000049, 10.728778729000055],
              [46.605678985000054, 10.728896672000076],
              [46.608323420000033, 10.728814421000038],
              [46.610215092000033, 10.728885805000061],
              [46.611595179000062, 10.728885805000061],
              [46.612749218000033, 10.72879062700008],
              [46.61408945900007, 10.729056343000082],
              [46.616069616000061, 10.729732050000052],
              [46.617494801000078, 10.729912740000088],
              [46.617798040000082, 10.730041258000085],
              [46.618281465000052, 10.730111228000055],
              [46.618685973000083, 10.730384866000065],
              [46.618771987000059, 10.730879582000057],
              [46.619007448000048, 10.730943086000082],
              [46.619360638000046, 10.731144182000037],
              [46.619553287000087, 10.731175933000088],
              [46.619767342000046, 10.731175933000088],
              [46.620152640000072, 10.731048926000085],
              [46.620291775000055, 10.730900750000046],
              [46.620386082000039, 10.730721403000075],
              [46.620398050000063, 10.730698644000086],
              [46.620398943000055, 10.730690029000073],
              [46.620441754000069, 10.730478349000066],
              [46.620505971000057, 10.730361925000068],
              [46.620730728000069, 10.73024550100007],
              [46.621008999000082, 10.730404261000047],
              [46.62105181000004, 10.730700613000067],
              [46.620966241000076, 10.730767099000047],
              [46.621016207000082, 10.730788276000055],
              [46.621019561000082, 10.730794910000043],
              [46.620904604000089, 10.730949193000072],
              [46.620869723000055, 10.730996006000055],
              [46.62050583000007, 10.731186517000083],
              [46.620355992000043, 10.731313525000076],
              [46.620281073000058, 10.731482868000057],
              [46.620174045000056, 10.731641628000091],
              [46.619874369000058, 10.731726299000059],
              [46.619649611000057, 10.731546372000082],
              [46.61923220500006, 10.731482868000057],
              [46.619007448000048, 10.731641628000091],
              [46.619296421000058, 10.731842723000057],
              [46.619660314000043, 10.731916810000087],
              [46.619970693000084, 10.732033234000085],
              [46.620281073000058, 10.731916810000087],
              [46.620612857000083, 10.73160987600005],
              [46.620901831000083, 10.731556956000077],
              [46.62120150800007, 10.731705131000069],
              [46.621244319000084, 10.732001482000044],
              [46.62123361600004, 10.73225549600005],
              [46.621447670000066, 10.732361336000054],
              [46.62164032000004, 10.732223745000056],
              [46.621586806000039, 10.731948562000071],
              [46.621436968000069, 10.731736883000053],
              [46.621533292000038, 10.731641628000091],
              [46.62177945500008, 10.73166279600008],
              [46.622036321000053, 10.731694548000064],
              [46.622457419000057, 10.731634083000074],
              [46.622462105000068, 10.731633414000044],
              [46.623123668000062, 10.731538904000047],
              [46.623992171000054, 10.731515110000089],
              [46.625265183000067, 10.731812542000057],
              [46.62640659200008, 10.732270262000043],
              [46.627648109000063, 10.732693619000088],
              [46.628239509000082, 10.732895197000062],
              [46.629000937000058, 10.733252116000074],
              [46.629476829000055, 10.733430576000046],
              [46.629893234000065, 10.733525754000084],
              [46.630440510000085, 10.733728008000071],
              [46.631201938000061, 10.733965954000041],
              [46.631615406000037, 10.734087470000077],
              [46.631893677000051, 10.734225060000085],
              [46.632344079000063, 10.734394257000076],
              [46.632855663000043, 10.734596512000053],
              [46.633295864000047, 10.734739279000053],
              [46.633452164000062, 10.734811640000089],
              [46.633938318000048, 10.735036712000067],
              [46.634521286000052, 10.735203274000071],
              [46.635104254000055, 10.735381734000043],
              [46.636031932000037, 10.735585718000038],
              [46.636568404000059, 10.735898583000051],
              [46.637223517000052, 10.735966744000052],
              [46.638768624000079, 10.736047983000049],
              [46.640548954000053, 10.736617482000042],
              [46.642393036000044, 10.737271834000069],
              [46.644237118000035, 10.73804515900008],
              [46.645605308000086, 10.73846156500008],
              [46.647449391000066, 10.738937457000077],
              [46.64866115600006, 10.739480536000087],
              [46.649880707000079, 10.739819428000089],
              [46.651316015000077, 10.740246160000083],
              [46.652803178000056, 10.740841026000055],
              [46.654211326000052, 10.741556435000064],
              [46.655115521000084, 10.741925251000055],
              [46.656519403000061, 10.742591501000049],
              [46.658315896000033, 10.74328154400007],
              [46.660536426000078, 10.744172271000082],
              [46.661849396000036, 10.744447480000076],
              [46.662380509000059, 10.744945596000036],
              [46.663748699000053, 10.745362002000093],
              [46.665057741000055, 10.746592682000085],
              [46.666370372000074, 10.746791249000069],
              [46.667912755000089, 10.747681976000081],
              [46.66902347100006, 10.748444975000041],
              [46.669963358000075, 10.748920867000038],
              [46.670837216000052, 10.749471360000086],
              [46.671693434000076, 10.749979359000065],
              [46.673294603000045, 10.750396133000038],
              [46.674396787000035, 10.751370141000052],
              [46.675614578000079, 10.752394880000054],
              [46.677135394000061, 10.753195562000087],
              [46.678419722000058, 10.754465541000059],
              [46.679453141000067, 10.755355738000048],
              [46.680821331000061, 10.75678341400004],
              [46.683141306000039, 10.758270578000065],
              [46.684681822000073, 10.759689552000054],
              [46.686595790000069, 10.761020426000073],
              [46.688285207000035, 10.762126875000092],
              [46.68964150000005, 10.762745535000079],
              [46.691386461000036, 10.763271691000057],
              [46.692782389000058, 10.76375680600006],
              [46.692971236000062, 10.76383598700005],
              [46.693050915000072, 10.763962045000085],
              [46.694257655000058, 10.764518234000093],
              [46.694578882000087, 10.764756180000063],
              [46.695078569000088, 10.765017921000037],
              [46.695506872000067, 10.76523207200006],
              [46.69701782900006, 10.765422429000068],
              [46.697779257000036, 10.76606488300007],
              [46.699218831000053, 10.767195127000036],
              [46.700491842000076, 10.767932760000065],
              [46.702228849000051, 10.768337269000085],
              [46.703870677000054, 10.768741777000059],
              [46.705952705000072, 10.769300950000058],
              [46.707570739000062, 10.770026686000051],
              [46.708796161000066, 10.770597757000075],
              [46.709843124000088, 10.771490054000083],
              [46.711020957000073, 10.771894563000046],
              [46.712531915000056, 10.772739271000091],
              [46.71356292300004, 10.773408033000067],
              [46.713852516000088, 10.773595877000048],
              [46.714387895000073, 10.773869515000058],
              [46.715125528000044, 10.774178845000051],
              [46.715958339000053, 10.774452483000061],
              [46.716933918000052, 10.77491647800008],
              [46.718373492000069, 10.775773084000036],
              [46.71874230800006, 10.776712971000052],
              [46.718896973000085, 10.777081788000089],
              [46.719027844000038, 10.77741491200004],
              [46.719071607000046, 10.777492712000083],
              [46.719241995000061, 10.777795626000056],
              [46.719313379000084, 10.778152545000069],
              [46.719860655000048, 10.778783102000091],
              [46.720598288000076, 10.779901449000079],
              [46.720824337000067, 10.780234574000076],
              [46.721121769000035, 10.780282163000038],
              [46.721359715000062, 10.780294060000074],
              [46.721645251000041, 10.780305957000053],
              [46.722918262000064, 10.780781850000039],
              [46.723334668000064, 10.781126872000073],
              [46.723596409000038, 10.781376715000079],
              [46.723977123000054, 10.781614661000049],
              [46.725285826000061, 10.78215004000009],
              [46.725678437000056, 10.782245218000071],
              [46.726713919000076, 10.782551904000059],
              [46.728402920000065, 10.783423051000057],
              [46.72995910700007, 10.784224958000038],
              [46.732174366000038, 10.785029188000067],
              [46.734111767000059, 10.785791103000065],
              [46.736166691000051, 10.786637664000068],
              [46.738682192000056, 10.787456238000061],
              [46.740157457000066, 10.788515098000062],
              [46.742465535000065, 10.789121861000069],
              [46.743940800000075, 10.789454985000077],
              [46.745285196000054, 10.789871391000077],
              [46.74567780700005, 10.790097440000068],
              [46.746153699000047, 10.79018072100007],
              [46.746522516000084, 10.790430564000076],
              [46.746974613000077, 10.79069230500005],
              [46.747605170000043, 10.791215786000066],
              [46.748699722000083, 10.792096187000084],
              [46.749758583000073, 10.792536387000041],
              [46.750091707000081, 10.792893306000053],
              [46.751555076000045, 10.793630939000082],
              [46.753220698000064, 10.794689799000082],
              [46.755005294000057, 10.796069887000044],
              [46.757253885000068, 10.796997876000091],
              [46.759205043000065, 10.79783068800009],
              [46.761120509000079, 10.798473142000091],
              [46.763047872000072, 10.798937137000053],
              [46.76499903000007, 10.799436824000054],
              [46.767818692000048, 10.799936511000055],
              [46.769211949000066, 10.800706236000053],
              [46.771126142000071, 10.801542647000076],
              [46.772577614000056, 10.802827556000068],
              [46.774683437000078, 10.803850724000085],
              [46.775950603000069, 10.804969055000072],
              [46.777788633000057, 10.805873266000049],
              [46.777736101000073, 10.806615806000082],
              [46.778978364000068, 10.808276522000085],
              [46.779608921000033, 10.808835695000084],
              [46.781060392000086, 10.809965939000051],
              [46.783570723000082, 10.811179464000077],
              [46.784629584000072, 10.812095557000077],
              [46.786021568000081, 10.812583346000054],
              [46.786308369000039, 10.812745686000085],
              [46.786652125000046, 10.812940265000066],
              [46.787306477000072, 10.813320979000082],
              [46.788329645000033, 10.813915844000064],
              [46.790054755000085, 10.814701066000055],
              [46.792249006000077, 10.81563092500005],
              [46.794183120000071, 10.816366689000063],
              [46.796358855000051, 10.817620139000041],
              [46.798723624000047, 10.818411454000056],
              [46.801341031000049, 10.819482212000082],
              [46.80332025000007, 10.820459362000065],
              [46.804581364000057, 10.821220789000051],
              [46.807734150000044, 10.822719850000055],
              [46.808602780000058, 10.822995184000092],
              [46.810494325000036, 10.823409894000065],
              [46.812881417000085, 10.824835999000072],
              [46.81402782400005, 10.826300939000078],
              [46.815205658000082, 10.827978459000065],
              [46.815945096000064, 10.829334365000079],
              [46.816985987000066, 10.831379517000073],
              [46.817478043000051, 10.832237694000071],
              [46.818215676000079, 10.833022916000061],
              [46.818977103000066, 10.833558295000046],
              [46.820226320000074, 10.834165057000064],
              [46.821308975000079, 10.834878896000077],
              [46.822399261000044, 10.835162859000093],
              [46.823351046000084, 10.83546029200005],
              [46.824481290000051, 10.835781520000069],
              [46.825611534000075, 10.836257412000066],
              [46.826622804000067, 10.837090223000075],
              [46.827396129000078, 10.837566116000062],
              [46.827872022000065, 10.837863548000087],
              [46.828304590000073, 10.838091168000062],
              [46.829137402000072, 10.838686033000045],
              [46.830660257000034, 10.839126234000048],
              [46.83265900400005, 10.840280272000086],
              [46.835017122000068, 10.842166793000047],
              [46.837310850000051, 10.843504442000039],
              [46.839773592000086, 10.845217654000066],
              [46.840903836000052, 10.846681022000041],
              [46.842080925000062, 10.848810968000066],
              [46.842688432000045, 10.849595862000058],
              [46.842368977000035, 10.850094139000078],
              [46.842305234000037, 10.850330428000063],
              [46.842289298000082, 10.850598223000077],
              [46.842368977000035, 10.850803006000092],
              [46.842416784000079, 10.850992037000083],
              [46.842464591000066, 10.851212573000055],
              [46.842560206000087, 10.851322841000069],
              [46.84271956300006, 10.851433109000084],
              [46.842894857000033, 10.851480366000089],
              [46.843038279000041, 10.851448861000051],
              [46.843146126000079, 10.851342253000041],
              [46.843493686000045, 10.851604016000067],
              [46.845127380000065, 10.852284653000083],
              [46.845710348000068, 10.852962799000068],
              [46.846352802000069, 10.853284027000086],
              [46.846990347000087, 10.853606952000064],
              [46.847372805000077, 10.853559695000058],
              [46.847755263000067, 10.853543942000044],
              [46.847799428000087, 10.853518372000053],
              [46.848160077000045, 10.853592989000049],
              [46.850100453000039, 10.853724227000043],
              [46.851964064000072, 10.853924910000046],
              [46.85345122700005, 10.854460289000087],
              [46.855176337000046, 10.854757721000055],
              [46.857578481000075, 10.855328039000085],
              [46.859518853000054, 10.855768992000037],
              [46.862076773000069, 10.856899236000061],
              [46.864396748000047, 10.858029480000084],
              [46.866300317000082, 10.858743319000041],
              [46.868084913000075, 10.859516643000063],
              [46.869869508000079, 10.860825347000059],
              [46.871832564000044, 10.862490970000067],
              [46.872336516000075, 10.863860731000045],
              [46.872861982000074, 10.865695809000044],
              [46.873504146000073, 10.866711407000082],
              [46.874501826000085, 10.867156284000089],
              [46.87609606500007, 10.867596484000046],
              [46.878166196000052, 10.868869496000059],
              [46.879827593000073, 10.870039495000071],
              [46.879855613000075, 10.87005922700007],
              [46.88090257600004, 10.870868243000075],
              [46.881652106000047, 10.871510698000066],
              [46.881743239000059, 10.87159575600009],
              [46.882544404000043, 10.872343509000075],
              [46.883662751000088, 10.873366677000092],
              [46.88481678900007, 10.874425538000082],
              [46.885685293000051, 10.875543884000081],
              [46.886648974000082, 10.876543258000083],
              [46.886851228000069, 10.877019150000081],
              [46.887005893000037, 10.877257096000051],
              [46.887148661000083, 10.87757832300008],
              [46.887231942000085, 10.877756783000052],
              [46.88771973200005, 10.87822077800007],
              [46.888076651000063, 10.87887513000004],
              [46.888267008000071, 10.879124973000046],
              [46.888885668000057, 10.880516958000044],
              [46.889004641000042, 10.882836932000089],
              [46.889563814000041, 10.883229543000084],
              [46.890015912000081, 10.883384208000052],
              [46.890337139000053, 10.883681641000067],
              [46.890717853000069, 10.883824409000056],
              [46.890700825000067, 10.884273990000054],
              [46.890987669000083, 10.884589006000056],
              [46.890909299000043, 10.884999873000083],
              [46.89191948000007, 10.885430545000077],
              [46.892181221000044, 10.885632799000064],
              [46.892669011000066, 10.886096794000082],
              [46.893192492000082, 10.886608378000062],
              [46.894275147000087, 10.887441189000072],
              [46.894333833000076, 10.888350754000044],
              [46.894319551000081, 10.888520145000086],
              [46.894319551000081, 10.888756404000048],
              [46.894319551000081, 10.889008412000067],
              [46.894319551000081, 10.889244670000039],
              [46.894319551000081, 10.889512429000092],
              [46.894351423000046, 10.889780188000088],
              [46.894415166000044, 10.890000694000037],
              [46.894415166000044, 10.890158199000041],
              [46.894478909000043, 10.890394456000081],
              [46.894526716000087, 10.890520460000062],
              [46.894526716000087, 10.890803968000057],
              [46.894558587000063, 10.890992973000039],
              [46.89459224400008, 10.891114945000083],
              [46.894564795000065, 10.891205377000063],
              [46.89450275300004, 10.891328018000081],
              [46.89454928400005, 10.89141999900005],
              [46.894626837000033, 10.891465990000086],
              [46.894735411000056, 10.891542641000058],
              [46.894890517000078, 10.89164995200008],
              [46.895138686000053, 10.891741933000048],
              [46.895355835000032, 10.891772593000042],
              [46.895666046000088, 10.891818584000077],
              [46.895914215000062, 10.891803254000081],
              [46.896024351000051, 10.891779590000056],
              [46.896321483000065, 10.891712322000046],
              [46.898260744000083, 10.89215252200006],
              [46.898380397000039, 10.89296446700007],
              [46.898380397000039, 10.893060323000043],
              [46.89841141900007, 10.893167634000065],
              [46.898442440000053, 10.893290275000084],
              [46.898473461000037, 10.893382255000063],
              [46.898535503000062, 10.893489566000085],
              [46.898597546000076, 10.893612206000057],
              [46.898690609000084, 10.893704186000036],
              [46.898768162000067, 10.893750176000083],
              [46.898938778000058, 10.893842157000051],
              [46.89906286300004, 10.893857487000048],
              [46.899282248000077, 10.893816831000038],
              [46.900260975000037, 10.894300498000064],
              [46.901160005000065, 10.894723624000051],
              [46.902701198000045, 10.895273686000053],
              [46.90445644600004, 10.895231373000058],
              [46.906468560000064, 10.895315998000058],
              [46.90839505200006, 10.895442935000062],
              [46.909722190000082, 10.895569873000056],
              [46.909837370000048, 10.895489084000076],
              [46.910395995000044, 10.895281513000043],
              [46.911669007000057, 10.895448076000037],
              [46.913156170000036, 10.895709816000078],
              [46.914643333000072, 10.896114325000042],
              [46.916713464000054, 10.896697293000045],
              [46.919057233000046, 10.897839434000048],
              [46.920627677000084, 10.898541375000093],
              [46.92193638100008, 10.899112446000061],
              [46.923352160000036, 10.899873873000047],
              [46.925148653000065, 10.900956528000052],
              [46.926171822000072, 10.902039183000056],
              [46.927325860000053, 10.903930854000066],
              [46.927754163000088, 10.905263352000077],
              [46.928277645000037, 10.906655337000075],
              [46.928789229000074, 10.907856965000065],
              [46.929395991000035, 10.90972484200006],
              [46.930407262000074, 10.912199481000073],
              [46.930099211000083, 10.913834202000089],
              [46.930140719000065, 10.913932654000064],
              [46.930312084000036, 10.914543250000065],
              [46.930502441000044, 10.915066732000071],
              [46.930799873000069, 10.915721083000051],
              [46.932215653000071, 10.915697289000093],
              [46.933667124000067, 10.915471240000045],
              [46.934509717000083, 10.915244475000065],
              [46.935939509000036, 10.915042937000067],
              [46.936974575000079, 10.915102423000064],
              [46.938152408000064, 10.914995348000048],
              [46.938890753000067, 10.914848248000055],
              [46.941840572000046, 10.914329099000042],
              [46.942780459000062, 10.914317201000074],
              [46.944624542000042, 10.914459969000063],
              [46.947039695000058, 10.914555147000044],
              [46.947253846000081, 10.915518829000064],
              [46.948955161000072, 10.915435548000062],
              [46.95178671900004, 10.915280883000037],
              [46.952857477000066, 10.915935235000063],
              [46.954459609000082, 10.916682996000077],
              [46.956164928000078, 10.916434922000064],
              [46.958009010000069, 10.916660970000066],
              [46.960257600000034, 10.917005992000043],
              [46.964528733000066, 10.918041058000085],
              [46.964306123000085, 10.918671528000061],
              [46.966015896000044, 10.919492529000081],
              [46.967586340000082, 10.920991589000039],
              [46.967730997000047, 10.922140851000051],
              [46.967782540000087, 10.922367241000075],
              [46.967774726000073, 10.922392980000041],
              [46.967907568000044, 10.922847569000055],
              [46.968062233000069, 10.92338294800004],
              [46.968752276000032, 10.924465603000044],
              [46.969002120000084, 10.924822522000056],
              [46.969394731000079, 10.925096160000066],
              [46.969942007000043, 10.925191338000047],
              [46.970810510000035, 10.925203235000083],
              [46.971714705000068, 10.925072365000062],
              [46.972833052000055, 10.924882008000054],
              [46.974379702000078, 10.92475113800009],
              [46.975652713000045, 10.92475113800009],
              [46.975979823000046, 10.924864744000047],
              [46.976212481000061, 10.924895401000072],
              [46.976390346000073, 10.924870111000075],
              [46.976652087000048, 10.924941495000041],
              [46.976949519000073, 10.925012879000064],
              [46.977389720000076, 10.925072365000062],
              [46.97806786600006, 10.925167544000089],
              [46.978317709000066, 10.925250825000091],
              [46.978710321000051, 10.925346003000072],
              [46.979138624000086, 10.925500668000041],
              [46.979792975000066, 10.925691025000049],
              [46.980601992000061, 10.925798101000055],
              [46.983302680000065, 10.92622640400009],
              [46.984206875000041, 10.926761782000085],
              [46.986193725000078, 10.927880129000073],
              [46.987644183000043, 10.928432684000086],
              [46.987692786000082, 10.92845120000004],
              [46.988977695000074, 10.929129346000082],
              [46.990092128000072, 10.929983093000089],
              [46.991012134000073, 10.930687893000083],
              [46.992344632000083, 10.932008494000058],
              [46.992868114000032, 10.933317197000065],
              [46.994212509000079, 10.93491143600005],
              [46.995771056000081, 10.936862594000047],
              [46.996532483000067, 10.938004736000039],
              [46.997412884000084, 10.939004109000052],
              [46.99859071700007, 10.940836294000064],
              [46.999530604000086, 10.942573301000039],
              [47.000506183000084, 10.944512561000067],
              [47.00154124900007, 10.946344746000079],
              [47.002433547000066, 10.947665347000054],
              [47.002909439000064, 10.948105548000058],
              [47.003206872000078, 10.94860523400007],
              [47.003706558000033, 10.94915251000009],
              [47.004027786000051, 10.949473738000052],
              [47.004503678000049, 10.950389830000063],
              [47.005288900000039, 10.951484382000046],
              [47.005479257000047, 10.951972172000069],
              [47.005752895000057, 10.952352886000085],
              [47.006050328000072, 10.952828778000082],
              [47.006442939000067, 10.953411746000086],
              [47.006657090000033, 10.953828151000039],
              [47.00689503600006, 10.954113687000074],
              [47.007144880000055, 10.954482503000065],
              [47.007394723000061, 10.954934601000048],
              [47.007561285000065, 10.955481877000068],
              [47.007573183000034, 10.956064845000071],
              [47.007763539000052, 10.957837543000039],
              [47.008084767000071, 10.95917004100005],
              [47.008429789000047, 10.960050442000068],
              [47.009119832000067, 10.960478745000046],
              [47.009952644000066, 10.960776177000071],
              [47.011606369000049, 10.961823140000092],
              [47.012570051000068, 10.962382314000081],
              [47.013176813000086, 10.96266784900007],
              [47.014045316000079, 10.963084255000069],
              [47.014818641000033, 10.962917692000076],
              [47.015770426000074, 10.963060460000065],
              [47.016186831000084, 10.963334098000075],
              [47.017186205000087, 10.963441174000081],
              [47.017796729000054, 10.963430459000051],
              [47.018887520000078, 10.963024768000082],
              [47.020255710000072, 10.963096152000048],
              [47.021730976000072, 10.963274612000077],
              [47.02294450100004, 10.962929590000044],
              [47.023444187000052, 10.962846309000042],
              [47.023765415000071, 10.962822514000038],
              [47.024181820000081, 10.962774925000076],
              [47.024919453000052, 10.962727335000068],
              [47.025442935000058, 10.962620260000051],
              [47.025764162000087, 10.962489389000041],
              [47.026275746000067, 10.962275238000075],
              [47.026834919000066, 10.962358519000077],
              [47.027215633000083, 10.962334724000073],
              [47.028143623000062, 10.962239546000092],
              [47.029238175000046, 10.962037292000048],
              [47.036424147000048, 10.962453697000058],
              [47.036923834000049, 10.962441800000079],
              [47.037221267000064, 10.962382314000081],
              [47.037780440000063, 10.962429903000043],
              [47.041159275000041, 10.962977179000063],
              [47.041843498000048, 10.962997947000076],
              [47.042721122000046, 10.963188309000088],
              [47.043060018000062, 10.96323792000004],
              [47.043299069000057, 10.963272915000061],
              [47.043705298000077, 10.963369790000058],
              [47.043859963000045, 10.96359583900005],
              [47.044925884000065, 10.964140117000056],
              [47.045739292000064, 10.964393932000064],
              [47.046659727000076, 10.964943863000087],
              [47.047473134000086, 10.965324584000086],
              [47.048179515000072, 10.965747607000083],
              [47.048487690000059, 10.96610987300005],
              [47.049421032000055, 10.967207031000044],
              [47.050148817000036, 10.968201127000043],
              [47.050705360000052, 10.968835655000078],
              [47.051582984000049, 10.969216371000073],
              [47.052374986000075, 10.969427880000069],
              [47.053081366000072, 10.969533634000072],
              [47.053402448000043, 10.969829746000073],
              [47.053637908000042, 10.969998952000083],
              [47.053873368000041, 10.970147008000083],
              [47.054515532000039, 10.970464270000036],
              [47.054858019000051, 10.970675778000043],
              [47.055243318000066, 10.970971888000065],
              [47.055564400000037, 10.971183396000072],
              [47.055757049000078, 10.971352277000051],
              [47.056013915000051, 10.971606086000065],
              [47.056345699000076, 10.971902195000041],
              [47.056538348000061, 10.97197622300007],
              [47.056859430000088, 10.971955072000071],
              [47.057180512000059, 10.971912771000063],
              [47.057385510000074, 10.971907830000077],
              [47.057617824000033, 10.972049610000056],
              [47.05817311800007, 10.972097322000081],
              [47.058357813000043, 10.972378085000059],
              [47.058743111000069, 10.972610742000086],
              [47.059096301000068, 10.972864550000054],
              [47.05947089700004, 10.973012604000075],
              [47.059845493000068, 10.973181809000039],
              [47.060102358000051, 10.973403890000043],
              [47.060209385000064, 10.973551944000064],
              [47.060316413000066, 10.973562519000041],
              [47.06045554800005, 10.973594245000072],
              [47.060466251000037, 10.97375287400007],
              [47.060530467000035, 10.973879777000093],
              [47.060701711000036, 10.973932654000066],
              [47.060979982000049, 10.974017256000081],
              [47.061172631000034, 10.974059557000089],
              [47.061429497000063, 10.974112433000073],
              [47.061515119000035, 10.974048981000067],
              [47.061697065000033, 10.973900928000091],
              [47.061811358000057, 10.973875261000046],
              [47.06235644700007, 10.97414456000007],
              [47.062510473000088, 10.974355664000086],
              [47.062681716000043, 10.97464119600005],
              [47.063045609000085, 10.974852700000042],
              [47.063345286000072, 10.974926727000081],
              [47.06355934000004, 10.974863276000065],
              [47.063933936000069, 10.974683497000058],
              [47.064115883000056, 10.974694072000091],
              [47.064169396000068, 10.974662346000059],
              [47.064351343000055, 10.974556594000092],
              [47.064380304000053, 10.974512079000078],
              [47.064692928000056, 10.974718012000039],
              [47.065639588000067, 10.974471859000062],
              [47.067472204000069, 10.974512098000048],
              [47.067566079000073, 10.974556461000077],
              [47.068764785000042, 10.974852567000084],
              [47.069749437000041, 10.975275576000058],
              [47.072446525000032, 10.975825487000066],
              [47.073730853000086, 10.976290796000058],
              [47.075015181000083, 10.976967606000073],
              [47.077112916000033, 10.977136809000058],
              [47.078868164000085, 10.977940519000072],
              [47.079767194000055, 10.978109721000067],
              [47.082078984000077, 10.978659626000081],
              [47.08332050100006, 10.97997093500004],
              [47.083962665000058, 10.981197638000083],
              [47.085204181000051, 10.982212837000077],
              [47.086745375000078, 10.983058833000086],
              [47.087858459000074, 10.983524130000092],
              [47.090170249000039, 10.983947126000089],
              [47.09134576200006, 10.984196013000087],
              [47.091556603000072, 10.984185049000075],
              [47.091951499000061, 10.984185049000075],
              [47.093270124000071, 10.984802758000058],
              [47.09489693900008, 10.985500699000056],
              [47.096266889000049, 10.986240937000048],
              [47.097230135000075, 10.986833126000079],
              [47.097701055000073, 10.987192669000081],
              [47.098193381000044, 10.987277267000081],
              [47.098436756000069, 10.987286886000049],
              [47.098563114000058, 10.987384423000037],
              [47.099484289000088, 10.98807393900006],
              [47.100070491000054, 10.98854280900008],
              [47.101354552000032, 10.989508126000089],
              [47.10161590000007, 10.989711407000073],
              [47.101618255000062, 10.98973060700007],
              [47.10266712300006, 10.991063015000066],
              [47.10478626400004, 10.994933309000089],
              [47.105578266000066, 10.997048202000087],
              [47.105877942000063, 10.997640370000056],
              [47.10617761900005, 10.998021048000055],
              [47.106498701000078, 10.998232536000046],
              [47.106769301000043, 10.998296192000055],
              [47.106937429000084, 10.998499218000063],
              [47.107244488000049, 10.998912912000037],
              [47.107439888000044, 10.999243867000075],
              [47.107607375000043, 10.99946450300007],
              [47.108054005000042, 11.000815896000063],
              [47.108165662000033, 11.001560539000081],
              [47.108209781000085, 11.002018220000082],
              [47.108236460000057, 11.002114243000051],
              [47.108243904000062, 11.002217209000037],
              [47.10838897800005, 11.002718868000045],
              [47.10855646400006, 11.003546243000073],
              [47.108807693000074, 11.004539090000037],
              [47.109058923000077, 11.005504355000085],
              [47.109114752000039, 11.006083512000089],
              [47.109035488000075, 11.006357599000069],
              [47.109606410000083, 11.008501016000082],
              [47.109614843000088, 11.008534937000093],
              [47.10968811500004, 11.010477416000072],
              [47.110287468000081, 11.012063502000046],
              [47.110629955000036, 11.01295170700007],
              [47.110779794000052, 11.013861057000042],
              [47.110779794000052, 11.014558928000042],
              [47.110993848000078, 11.015066469000089],
              [47.111336336000079, 11.015299092000078],
              [47.111507579000033, 11.015954665000038],
              [47.111743039000032, 11.016504499000064],
              [47.111935689000063, 11.016758268000046],
              [47.112149743000089, 11.017012037000086],
              [47.112363798000047, 11.017434985000079],
              [47.112492231000033, 11.017879079000068],
              [47.112749096000073, 11.018407762000038],
              [47.112963151000088, 11.01874611900007],
              [47.113070178000044, 11.019147917000055],
              [47.113112989000058, 11.019528567000066],
              [47.113348449000057, 11.019930364000061],
              [47.113412666000045, 11.020437896000089],
              [47.113562504000072, 11.020860839000079],
              [47.113648126000044, 11.020945427000072],
              [47.113797964000071, 11.021072310000079],
              [47.113883586000043, 11.02141066300004],
              [47.114076235000084, 11.021685575000049],
              [47.114354506000041, 11.02183360500004],
              [47.114953859000082, 11.02231998700006],
              [47.115553212000066, 11.022869808000053],
              [47.116045538000037, 11.023229307000065],
              [47.116375269000059, 11.023392181000077],
              [47.116732533000061, 11.023833368000055],
              [47.117783297000074, 11.024996006000038],
              [47.119795411000041, 11.026264812000079],
              [47.121507848000078, 11.027322147000064],
              [47.123049041000058, 11.028125719000059],
              [47.124633046000042, 11.028675530000044],
              [47.125746130000039, 11.029140753000092],
              [47.127262785000084, 11.029307232000065],
              [47.127993265000043, 11.029883294000058],
              [47.128039991000037, 11.029882245000067],
              [47.128100731000075, 11.029880881000054],
              [47.129342248000057, 11.030388395000045],
              [47.130755008000051, 11.031445715000075],
              [47.131996525000034, 11.032206983000037],
              [47.133152420000044, 11.033010541000067],
              [47.134436748000041, 11.033560342000044],
              [47.135036101000082, 11.034025558000053],
              [47.135592643000052, 11.03478681900009],
              [47.135763887000053, 11.035378910000077],
              [47.136577294000062, 11.035928707000039],
              [47.13704821400006, 11.03677454700005],
              [47.137092271000085, 11.037018273000058],
              [47.137100801000088, 11.037065461000054],
              [47.138562547000049, 11.03821817100004],
              [47.138632219000044, 11.038381636000054],
              [47.139160122000078, 11.039016506000053],
              [47.139484520000053, 11.039681168000072],
              [47.14019090100004, 11.040569289000075],
              [47.140811659000065, 11.041182513000081],
              [47.141190651000045, 11.041358009000078],
              [47.141971471000033, 11.042399319000083],
              [47.142009891000043, 11.042452799000046],
              [47.142193298000052, 11.042568095000092],
              [47.142324646000077, 11.042684873000042],
              [47.142331447000061, 11.04272614100006],
              [47.142545502000075, 11.043423942000061],
              [47.142845178000073, 11.043825706000064],
              [47.143123449000086, 11.044206324000072],
              [47.143251882000072, 11.044819540000049],
              [47.143444531000057, 11.045179012000062],
              [47.143637180000042, 11.045348174000083],
              [47.14372280200007, 11.045601918000045],
              [47.143765613000085, 11.046151696000038],
              [47.143894046000071, 11.046574601000088],
              [47.144108100000039, 11.046743763000052],
              [47.144172317000084, 11.047251249000055],
              [47.144364966000069, 11.04767415200007],
              [47.144493399000055, 11.047779878000085],
              [47.14468604800004, 11.047949039000059],
              [47.144750264000038, 11.048287362000053],
              [47.144793075000052, 11.048689119000073],
              [47.144902480000042, 11.048977315000059],
              [47.144921508000039, 11.049027440000089],
              [47.144985724000037, 11.049344616000042],
              [47.145049941000082, 11.050021257000083],
              [47.145242590000066, 11.050423011000078],
              [47.145306806000065, 11.051015070000062],
              [47.145445037000059, 11.051190631000054],
              [47.145439156000066, 11.051364895000063],
              [47.145867265000049, 11.05292961300006],
              [47.146252564000065, 11.05479034800004],
              [47.146252564000065, 11.055803012000069],
              [47.146149534000074, 11.05593504300009],
              [47.146119180000085, 11.056005007000067],
              [47.146017998000048, 11.056194908000066],
              [47.146048353000083, 11.056444778000071],
              [47.14613941600004, 11.056584705000091],
              [47.14613941600004, 11.056764611000062],
              [47.146098944000073, 11.057214375000058],
              [47.146129298000062, 11.057484234000071],
              [47.146210243000041, 11.057744097000068],
              [47.146200125000064, 11.058043939000072],
              [47.146119180000085, 11.058303802000069],
              [47.145997762000036, 11.058653617000061],
              [47.146008319000089, 11.058781367000051],
              [47.146038235000049, 11.059143358000085],
              [47.146149534000074, 11.059443199000043],
              [47.146338186000037, 11.059561785000085],
              [47.146338186000037, 11.059560318000081],
              [47.146338635000063, 11.059562067000059],
              [47.146362015000079, 11.059653087000072],
              [47.146442960000059, 11.059922943000061],
              [47.146351897000045, 11.060182804000078],
              [47.146098944000073, 11.060342719000062],
              [47.145876345000033, 11.060582590000081],
              [47.145754927000041, 11.060662547000049],
              [47.145421029000033, 11.060732510000037],
              [47.145309729000076, 11.060752499000046],
              [47.145249021000041, 11.060892424000087],
              [47.145319848000042, 11.061152285000048],
              [47.145431147000068, 11.061292209000044],
              [47.145541038000033, 11.061517062000064],
              [47.145862209000086, 11.061776978000069],
              [47.146663774000046, 11.061963279000054],
              [47.146688732000086, 11.062024912000084],
              [47.146894728000063, 11.062106304000054],
              [47.147793757000045, 11.062571474000038],
              [47.148478732000058, 11.062952068000072],
              [47.149206518000085, 11.063501812000084],
              [47.150276791000067, 11.063966980000089],
              [47.150876144000051, 11.064178420000076],
              [47.151817984000047, 11.064432147000048],
              [47.152973879000058, 11.064685875000066],
              [47.154129774000069, 11.064897314000063],
              [47.155242859000055, 11.065193328000078],
              [47.156141888000036, 11.065573918000041],
              [47.156527186000062, 11.066165946000069],
              [47.157297783000047, 11.066546534000054],
              [47.158410867000043, 11.067138560000046],
              [47.159780817000069, 11.067349997000065],
              [47.160851090000051, 11.067519147000041],
              [47.161835741000061, 11.067561434000083],
              [47.162734771000032, 11.06773058400006],
              [47.163505368000074, 11.068195745000082],
              [47.164490019000084, 11.068576331000088],
              [47.165389048000065, 11.069083778000049],
              [47.16598840100005, 11.069210639000062],
              [47.167486784000062, 11.06971808500009],
              [47.168771112000059, 11.070225530000073],
              [47.169755763000069, 11.070690687000081],
              [47.170783225000037, 11.071113557000047],
              [47.172024742000076, 11.071747860000073],
              [47.173009393000086, 11.072424448000049],
              [47.173651557000085, 11.072847315000047],
              [47.175107129000082, 11.072931888000085],
              [47.176733944000034, 11.073016462000055],
              [47.17810389400006, 11.073270181000055],
              [47.179431032000082, 11.073946766000063],
              [47.180758171000036, 11.074158198000077],
              [47.181664509000086, 11.074222144000089],
              [47.181719055000087, 11.074264049000078],
              [47.181780406000087, 11.074309499000037],
              [47.181841758000076, 11.074324649000062],
              [47.182117839000057, 11.074354949000053],
              [47.182225204000076, 11.074370098000088],
              [47.182485948000078, 11.074445848000039],
              [47.182623989000035, 11.074506448000079],
              [47.182716016000086, 11.074536748000071],
              [47.182930746000068, 11.074612498000079],
              [47.183053449000056, 11.074627648000046],
              [47.183222165000075, 11.074627648000046],
              [47.18337934200008, 11.074579878000065],
              [47.183498247000045, 11.074621738000076],
              [47.183498247000045, 11.074657947000048],
              [47.183605612000065, 11.074794297000039],
              [47.183758990000058, 11.074915496000074],
              [47.183973721000086, 11.075036696000041],
              [47.184157775000074, 11.075157895000075],
              [47.18451054600007, 11.075309394000044],
              [47.184801965000077, 11.075385144000052],
              [47.185032033000084, 11.07546089300007],
              [47.185262101000035, 11.075582092000047],
              [47.185568858000067, 11.075718441000049],
              [47.185768250000081, 11.075763891000065],
              [47.186044332000051, 11.075839640000083],
              [47.186274400000059, 11.075960839000061],
              [47.186366427000053, 11.076006289000077],
              [47.186581157000035, 11.076036588000079],
              [47.186887914000067, 11.076127488000054],
              [47.18708730600008, 11.076142637000089],
              [47.187302037000052, 11.076157787000056],
              [47.187578118000033, 11.076203237000072],
              [47.187808186000041, 11.07621838700004],
              [47.187992240000085, 11.07621838700004],
              [47.187998009000069, 11.076205852000044],
              [47.189800777000073, 11.076840503000085],
              [47.192788042000075, 11.077414238000074],
              [47.192807910000056, 11.077418856000065],
              [47.19569918500008, 11.078090813000074],
              [47.198096597000074, 11.078851958000087],
              [47.199959568000054, 11.079861054000048],
              [47.200170503000038, 11.079907353000067],
              [47.200507936000065, 11.080013401000087],
              [47.200738004000073, 11.080104299000084],
              [47.201228815000036, 11.080255795000085],
              [47.201535572000068, 11.080346693000081],
              [47.20179631600007, 11.080437591000077],
              [47.201919019000059, 11.080498189000082],
              [47.202057060000072, 11.080649686000072],
              [47.202057060000072, 11.080669129000057],
              [47.203574332000073, 11.081109908000087],
              [47.203762176000055, 11.081273181000086],
              [47.204002246000073, 11.081481849000056],
              [47.204035644000044, 11.081482914000048],
              [47.204211294000061, 11.081553888000087],
              [47.204342401000076, 11.081573811000055],
              [47.20443442800007, 11.08158896100008],
              [47.204511118000084, 11.081619260000082],
              [47.204587807000053, 11.081664708000062],
              [47.204741185000046, 11.081785905000061],
              [47.204833213000086, 11.08186165300009],
              [47.204986591000079, 11.082013148000044],
              [47.205155308000087, 11.082210092000082],
              [47.205247335000081, 11.082331289000081],
              [47.205315084000063, 11.082512921000045],
              [47.205446727000037, 11.082634279000047],
              [47.205615444000045, 11.082785774000058],
              [47.205722809000065, 11.082861522000087],
              [47.205968214000052, 11.083058466000068],
              [47.20609091700004, 11.083164512000053],
              [47.206182944000034, 11.083240259000092],
              [47.206214472000056, 11.08328178000005],
              [47.206312641000068, 11.083321707000039],
              [47.206843015000061, 11.083597422000082],
              [47.207261731000074, 11.083762851000074],
              [47.207708361000073, 11.083928280000066],
              [47.208154991000072, 11.084010994000039],
              [47.208601621000071, 11.084231566000085],
              [47.208936594000079, 11.084369423000055],
              [47.209578625000063, 11.084810566000044],
              [47.210192741000071, 11.085196565000047],
              [47.21080685700008, 11.085582564000049],
              [47.211365145000059, 11.085858277000057],
              [47.212202577000085, 11.086133990000064],
              [47.212614540000061, 11.086463385000059],
              [47.213166043000058, 11.086632437000048],
              [47.216933405000077, 11.088154680000059],
              [47.22112887600008, 11.089592347000064],
              [47.223011687000053, 11.090556606000064],
              [47.223228759000051, 11.090628073000062],
              [47.223982447000083, 11.090793498000039],
              [47.224090616000069, 11.090849460000072],
              [47.224094105000063, 11.090848639000058],
              [47.224540735000062, 11.091069206000043],
              [47.225043194000079, 11.091289772000039],
              [47.225629396000045, 11.091565480000042],
              [47.226355170000033, 11.092061753000053],
              [47.227443831000073, 11.092613167000081],
              [47.227709953000044, 11.092686763000074],
              [47.227863543000069, 11.093053367000039],
              [47.228672993000032, 11.093692952000083],
              [47.230049059000066, 11.094252587000085],
              [47.230898981000053, 11.095171986000082],
              [47.231667959000049, 11.095371855000053],
              [47.232598827000061, 11.096051408000051],
              [47.233367805000057, 11.096611039000038],
              [47.234177255000077, 11.097250616000053],
              [47.235108123000089, 11.09789019100009],
              [47.235877101000085, 11.098409845000049],
              [47.236686551000048, 11.099169337000092],
              [47.237050804000035, 11.099489123000069],
              [47.237293639000086, 11.100368532000061],
              [47.237900726000078, 11.10096812800009],
              [47.238669704000074, 11.101407830000085],
              [47.23943868200007, 11.101767586000051],
              [47.240126715000088, 11.102447124000037],
              [47.241057583000043, 11.10296677000008],
              [47.241502780000076, 11.103286552000043],
              [47.241907506000075, 11.103926114000046],
              [47.242595538000046, 11.104525702000046],
              [47.243283571000063, 11.105205233000049],
              [47.244619164000085, 11.105884763000063],
              [47.245064362000051, 11.106244514000082],
              [47.245752394000078, 11.106684208000047],
              [47.246723735000046, 11.107483651000052],
              [47.247109082000065, 11.107459865000067],
              [47.247821333000047, 11.108024751000073],
              [47.249942826000051, 11.109568621000051],
              [47.251087316000053, 11.110450829000058],
              [47.251533946000052, 11.110754087000089],
              [47.252036405000069, 11.111112483000056],
              [47.252594692000059, 11.111443309000038],
              [47.252874623000082, 11.111616100000049],
              [47.252978761000065, 11.111644150000075],
              [47.253254842000047, 11.11168959400004],
              [47.253484910000054, 11.111750186000052],
              [47.253653627000062, 11.111886518000063],
              [47.253714978000062, 11.111901666000051],
              [47.253834280000035, 11.111901666000051],
              [47.254017662000081, 11.111901666000051],
              [47.254648411000062, 11.112526199000058],
              [47.255077941000081, 11.112699740000039],
              [47.255189293000058, 11.112778292000087],
              [47.255264102000069, 11.112901431000068],
              [47.255417481000052, 11.113052910000079],
              [47.255555522000066, 11.113204389000089],
              [47.255754914000079, 11.113355868000042],
              [47.255984982000086, 11.113522495000041],
              [47.256245725000042, 11.113689122000039],
              [47.256491131000075, 11.113795157000084],
              [47.25690525400006, 11.11391634000006],
              [47.257181335000041, 11.11399207900007],
              [47.257503430000042, 11.11406781900007],
              [47.257549444000063, 11.114082967000058],
              [47.257554546000051, 11.114081455000075],
              [47.257903462000058, 11.114221845000088],
              [47.258930925000072, 11.114602370000057],
              [47.259658710000053, 11.11494061500008],
              [47.26029025400004, 11.115190102000042],
              [47.260728983000035, 11.115363419000062],
              [47.261842068000078, 11.11591306400004],
              [47.262484232000077, 11.116124466000088],
              [47.262796903000037, 11.116330330000039],
              [47.263446354000052, 11.116463809000038],
              [47.263500534000059, 11.116506615000048],
              [47.263745940000035, 11.116612649000047],
              [47.263945332000048, 11.116673240000068],
              [47.264175400000056, 11.116703536000045],
              [47.264328779000039, 11.11673383100009],
              [47.264788914000064, 11.116900456000053],
              [47.265126347000034, 11.117006490000051],
              [47.265498309000066, 11.117042040000058],
              [47.266297377000058, 11.117436621000081],
              [47.266322701000036, 11.117536660000042],
              [47.266414728000086, 11.117672989000084],
              [47.266568106000079, 11.117779022000093],
              [47.266706147000036, 11.117915351000079],
              [47.26689020200007, 11.118021385000077],
              [47.267120269000088, 11.118157714000063],
              [47.267350337000039, 11.118354633000081],
              [47.267565067000078, 11.118536405000043],
              [47.267733784000086, 11.118642439000041],
              [47.267933176000042, 11.11865758600004],
              [47.267947208000066, 11.11865296700006],
              [47.269162736000055, 11.119253192000087],
              [47.270275820000052, 11.119887389000041],
              [47.271731392000049, 11.120352466000043],
              [47.273101341000086, 11.120817543000044],
              [47.274642535000055, 11.121367177000081],
              [47.275884052000038, 11.121705413000086],
              [47.277254001000074, 11.122043649000091],
              [47.278838006000058, 11.122931516000051],
              [47.280105851000087, 11.123948847000065],
              [47.280142114000057, 11.124065238000071],
              [47.280188128000077, 11.124247006000076],
              [47.280249479000076, 11.12432274300005],
              [47.280372182000065, 11.124474216000067],
              [47.280494885000053, 11.124655984000071],
              [47.280648264000035, 11.124777162000044],
              [47.280801642000085, 11.124883193000073],
              [47.281077724000056, 11.125064961000078],
              [47.281231102000049, 11.125125550000064],
              [47.281476508000083, 11.125307317000079],
              [47.281813941000053, 11.12544364300004],
              [47.282059347000086, 11.125473937000038],
              [47.282258058000082, 11.12549772400007],
              [47.282648178000045, 11.125679658000081],
              [47.28384688400007, 11.126440678000051],
              [47.285259645000053, 11.127032580000048],
              [47.28637272900005, 11.127835875000073],
              [47.287828300000058, 11.12880828100009],
              [47.289112628000055, 11.129569292000042],
              [47.290439767000066, 11.129992075000075],
              [47.291638473000035, 11.130161188000045],
              [47.292751557000088, 11.130583970000089],
              [47.294635238000069, 11.130837639000049],
              [47.29651891900005, 11.130922196000085],
              [47.298017301000073, 11.13113358600009],
              [47.299943793000068, 11.13138725500005],
              [47.302084339000032, 11.132105981000052],
              [47.304610184000069, 11.132740150000075],
              [47.30478038800004, 11.132852206000052],
              [47.304805396000063, 11.132926296000051],
              [47.304866747000062, 11.133062618000054],
              [47.304897423000057, 11.133123205000061],
              [47.305004788000076, 11.133198940000057],
              [47.305127491000064, 11.133274674000063],
              [47.305296208000073, 11.13333526200006],
              [47.30543424800004, 11.133350408000069],
              [47.305499480000037, 11.133325632000037],
              [47.30550921300005, 11.133332040000084],
              [47.307093218000034, 11.133712539000044],
              [47.308120680000059, 11.134135316000084],
              [47.309362197000041, 11.134684925000045],
              [47.311288689000037, 11.135234534000062],
              [47.312872693000088, 11.136080082000092],
              [47.314071399000056, 11.136545133000084],
              [47.315355727000053, 11.137602064000077],
              [47.316896920000033, 11.138405329000079],
              [47.318266870000059, 11.138828099000079],
              [47.319679630000053, 11.139377699000079],
              [47.321049580000079, 11.140941940000062],
              [47.322239401000047, 11.142200837000075],
              [47.322244545000046, 11.14224147300007],
              [47.322290558000077, 11.142302059000087],
              [47.322397924000086, 11.142438376000086],
              [47.32244393700006, 11.142589840000085],
              [47.322474613000054, 11.142726157000084],
              [47.322566640000048, 11.142907913000045],
              [47.322674005000067, 11.143013938000081],
              [47.322796708000055, 11.143119962000071],
              [47.322888735000049, 11.14324113300006],
              [47.323026776000063, 11.14337745000006],
              [47.323103465000088, 11.143392596000069],
              [47.323151897000059, 11.143396012000039],
              [47.324003534000042, 11.143774463000057],
              [47.324293887000067, 11.143971587000067],
              [47.324315156000068, 11.144013595000047],
              [47.324345832000063, 11.144089326000085],
              [47.324422521000088, 11.144195350000075],
              [47.324514548000082, 11.144255935000047],
              [47.32457590000007, 11.144286228000055],
              [47.324990022000065, 11.144422544000065],
              [47.325312117000067, 11.144437690000075],
              [47.325496172000044, 11.144452837000074],
              [47.325587265000081, 11.144347889000073],
              [47.326700623000079, 11.14447202000008],
              [47.328455871000074, 11.144429744000092],
              [47.329740198000081, 11.145148438000092],
              [47.33136701400008, 11.14578257800008],
              [47.332736963000059, 11.146374441000091],
              [47.33462064400004, 11.147135405000085],
              [47.336461514000064, 11.14789636800009],
              [47.337788653000075, 11.148615053000071],
              [47.33954390100007, 11.14941828700006],
              [47.34052855200008, 11.149967867000043],
              [47.341984124000078, 11.150348344000065],
              [47.34386780400007, 11.151236124000093],
              [47.345194943000081, 11.151616600000068],
              [47.346436460000064, 11.151954801000045],
              [47.348106086000087, 11.152588926000078],
              [47.349561658000084, 11.152884850000078],
              [47.351188473000036, 11.153307599000073],
              [47.352686855000059, 11.153857171000084],
              [47.353928372000041, 11.154110820000085],
              [47.355812053000079, 11.15436446800004],
              [47.357010759000048, 11.154575841000053],
              [47.358166654000058, 11.155209961000082],
              [47.359365360000083, 11.155252235000091],
              [47.36103498600005, 11.155632706000063],
              [47.362576180000076, 11.15614],
              [47.364245806000042, 11.15660501800005],
              [47.36612948700008, 11.157070035000061],
              [47.367627869000046, 11.157365955000046],
              [47.368740953000042, 11.157873246000065],
              [47.370324957000037, 11.158591906000083],
              [47.371780529000034, 11.159056920000069],
              [47.373236101000089, 11.159902399000089],
              [47.374734483000054, 11.160536506000085],
              [47.375890378000065, 11.160959244000082],
              [47.376960651000047, 11.161466528000062],
              [47.377945303000047, 11.161846991000061],
              [47.378793577000067, 11.162324289000082],
              [47.379700551000042, 11.162882692000039],
              [47.381125449000081, 11.163299868000081],
              [47.382269206000046, 11.164024072000075],
              [47.383421629000054, 11.16454908500009],
              [47.384920689000069, 11.165441383000086],
              [47.386134215000084, 11.166607319000093],
              [47.387204972000063, 11.167618590000075],
              [47.388144859000079, 11.16815396800007],
              [47.389477357000032, 11.168986780000068],
              [47.390857445000051, 11.16948646600008],
              [47.39252306700007, 11.170045640000069],
              [47.393629517000079, 11.170616710000047],
              [47.394984052000041, 11.171294981000074],
              [47.396867732000032, 11.171886792000066],
              [47.399179522000054, 11.172520872000064],
              [47.401020392000078, 11.172985864000054],
              [47.402775640000073, 11.173408583000082],
              [47.404384681000067, 11.173626729000091],
              [47.406145482000056, 11.173828983000078],
              [47.407573158000048, 11.17429297800004],
              [47.408774786000038, 11.174911638000083],
              [47.409571906000053, 11.17553029700008],
              [47.411511166000082, 11.176720028000091],
              [47.412177415000087, 11.17727920100009],
              [47.413236275000088, 11.17751714700006],
              [47.414973282000062, 11.177564737000068],
              [47.416543726000043, 11.177588531000083],
              [47.417930709000075, 11.177635740000085],
              [47.419744101000049, 11.177053152000042],
              [47.421655259000033, 11.176705771000059],
              [47.422599455000068, 11.176303622000091],
              [47.42295729500006, 11.176170746000082],
              [47.424205591000089, 11.175863422000077],
              [47.426193218000037, 11.175416491000078],
              [47.427563167000073, 11.175162861000047],
              [47.429104361000043, 11.174866959000042],
              [47.430427881000071, 11.174649897000052],
              [47.431962634000058, 11.174316772000054],
              [47.432236792000083, 11.174275658000056],
              [47.433085777000088, 11.174148340000045],
              [47.435183512000037, 11.173894709000081],
              [47.435824772000046, 11.173881237000046],
              [47.437195626000062, 11.17385243800004],
              [47.438736819000042, 11.17385243800004],
              [47.440183671000057, 11.173686215000089],
              [47.441690773000062, 11.173641079000049],
              [47.443231967000088, 11.173641079000049],
              [47.444181166000078, 11.173650523000049],
              [47.445025918000056, 11.173809690000041],
              [47.445347102000085, 11.173793291000038],
              [47.446215605000077, 11.174102621000088],
              [47.447060314000055, 11.174281080000071],
              [47.448178660000053, 11.17429297800004],
              [47.44860714400005, 11.174333786000091],
              [47.448678347000055, 11.174340567000058],
              [47.451045911000051, 11.175458914000046],
              [47.451258505000055, 11.175695129000076],
              [47.451581290000036, 11.176053779000085],
              [47.451973901000088, 11.176934179000057],
              [47.452497382000047, 11.177897861000076],
              [47.453508653000085, 11.17857600800005],
              [47.454615102000048, 11.178492726000059],
              [47.455433081000081, 11.178333215000066],
              [47.455546943000058, 11.17819146000005],
              [47.455936475000044, 11.178097036000054],
              [47.456316417000039, 11.178004937000082],
              [47.457702060000088, 11.177995046000092],
              [47.459314538000058, 11.178195294000091],
              [47.460698825000065, 11.178290944000082],
              [47.461897531000034, 11.178544571000089],
              [47.46340721100006, 11.178683083000067],
              [47.463716791000081, 11.178749422000067],
              [47.46424002200007, 11.178861543000039],
              [47.464775401000054, 11.178980516000081],
              [47.465667699000051, 11.179170873000089],
              [47.466512407000039, 11.17926605100007],
              [47.467000926000082, 11.179272946000083],
              [47.467436684000063, 11.179214512000044],
              [47.468035262000058, 11.179135181000049],
              [47.469296377000035, 11.179218462000051],
              [47.470890616000077, 11.17950399700004],
              [47.472484854000072, 11.180003684000042],
              [47.474888110000052, 11.180396295000037],
              [47.477600696000081, 11.180610447000049],
              [47.48043225400005, 11.180955469000082],
              [47.483025867000038, 11.181157723000069],
              [47.48513169000006, 11.181383772000061],
              [47.488201194000055, 11.181752588000052],
              [47.491199315000074, 11.182180891000087],
              [47.493192319000059, 11.182348946000047],
              [47.496831248000035, 11.182306676000053],
              [47.500598609000065, 11.182306676000053],
              [47.50307282600005, 11.182466426000076],
              [47.503929432000064, 11.182656783000084],
              [47.504974496000045, 11.182741944000043],
              [47.507807953000054, 11.18300180500006],
              [47.509997057000078, 11.183108881000067],
              [47.512755516000084, 11.18322211800006],
              [47.51557613600005, 11.183174101000077],
              [47.517849279000075, 11.183204059000047],
              [47.520419096000069, 11.182942318000073],
              [47.522919474000048, 11.182934014000068],
              [47.526010830000075, 11.182787653000048],
              [47.528803871000036, 11.18269392600007],
              [47.531543077000038, 11.182561605000046],
              [47.534743452000043, 11.181931048000081],
              [47.538092464000044, 11.180821238000078],
              [47.540815821000081, 11.180148988000042],
              [47.543606944000032, 11.179004310000039],
              [47.545236875000057, 11.178159602000051],
              [47.547235163000039, 11.17717186200008],
              [47.548508634000086, 11.176470185000085],
              [47.549198677000049, 11.175982395000062],
              [47.549693797000089, 11.175651720000076],
              [47.558216835000053, 11.170045640000069],
              [47.560706510000045, 11.168090321000079],
              [47.561036496000042, 11.167666179000037],
              [47.561952588000054, 11.166940443000044],
              [47.562874789000034, 11.166336525000077],
              [47.563201805000062, 11.165952967000067],
              [47.564022719000036, 11.165215334000038],
              [47.564379639000038, 11.164929799000049],
              [47.564807942000073, 11.164489598000046],
              [47.56515296300006, 11.164263550000044],
              [47.565718649000075, 11.16372025700008],
              [47.565981720000082, 11.163420525000049],
              [47.566223721000085, 11.163204689000054],
              [47.566456606000088, 11.163008065000042],
              [47.566820859000075, 11.162768278000044],
              [47.567400594000048, 11.162553207000087],
              [47.567829857000049, 11.161812705000045],
              [47.56819867300004, 11.161574759000075],
              [47.56865077100008, 11.161253531000057],
              [47.569067177000079, 11.160789536000038],
              [47.569552754000085, 11.160461772000076],
              [47.570530545000054, 11.159552217000055],
              [47.571794009000087, 11.158203961000083],
              [47.573052774000075, 11.157434496000064],
              [47.574468735000039, 11.156283091000091],
              [47.576645760000076, 11.154519657000037],
              [47.578310614000088, 11.15320967200006],
              [47.580036492000033, 11.151878455000087],
              [47.582249755000078, 11.150232266000046],
              [47.583805959000074, 11.149031690000072],
              [47.586342064000064, 11.147226609000086],
              [47.588959471000067, 11.145168375000083],
              [47.591003403000059, 11.143749098000058],
              [47.59369459800007, 11.141718157000071],
              [47.595817910000051, 11.140291348000062],
              [47.597714533000044, 11.138946656000087],
              [47.599319369000057, 11.137794058000054],
              [47.599523004000048, 11.137227333000055],
              [47.601536847000034, 11.136182587000064],
              [47.60165367500008, 11.135921077000091],
              [47.602723565000076, 11.135152671000071],
              [47.604863346000059, 11.133615853000038],
              [47.607475030000046, 11.131740096000044],
              [47.607538072000068, 11.131694818000085],
              [47.609815446000084, 11.130046900000082],
              [47.612397162000036, 11.12821471500007],
              [47.615264412000045, 11.126192173000049],
              [47.617529630000035, 11.124674697000046],
              [47.617715257000043, 11.124550345000046],
              [47.620308869000041, 11.122634879000088],
              [47.62183172500005, 11.121504635000065],
              [47.623537795000061, 11.120216369000048],
              [47.625365224000063, 11.118756358000041],
              [47.627622831000053, 11.117142564000062],
              [47.627626799000041, 11.117140049000056],
              [47.630088454000088, 11.11557977700005],
              [47.631902392000086, 11.11483718900007],
              [47.632604567000044, 11.114574147000042],
              [47.633119201000056, 11.114381359000049],
              [47.634050257000069, 11.114449533000084],
              [47.634276306000061, 11.11479455500006],
              [47.634538046000046, 11.114842144000079],
              [47.634753188000047, 11.114842144000079],
              [46.901418474000081, 9.429616032000069],
              [46.656406251000078, 8.866570281000065],
              [46.198355446000051, 8.865191180000068],
              [46.163790241000072, 8.865087111000037],
              [46.036501917000066, 8.325309734000086],
              [46.030418350000048, 8.327239877000068],
              [45.926944666000054, 8.360060610000062],
              [45.911640074000047, 8.366759230000071],
              [45.910285969000086, 8.367093071000056],
              [45.894756294000047, 8.370910749000075],
              [45.828948854000089, 8.395333383000093],
              [45.790310011000088, 8.40746680400008],
              [45.765731856000059, 8.415183976000037],
              [45.670326214000056, 8.448129685000083],
              [45.66516875800005, 8.449907375000066],
              [45.656154608000065, 8.451168041000074],
              [45.611640980000061, 8.467787632000068],
              [45.550178516000074, 8.487815913000077],
              [45.529140513000073, 8.494671767000057],
              [45.513404856000079, 8.498128843000075],
              [45.494152023000083, 8.505666795000081],
              [45.481658902000049, 8.508017647000088],
              [45.42934430300005, 8.525361055000076],
              [45.309020899000075, 8.56519803000009],
              [45.290992712000048, 8.571160961000089],
              [45.257675205000055, 8.582180926000092],
              [45.238914528000066, 8.586725215000058],
              [45.188282018000052, 8.60364620900009],
              [45.123153703000071, 8.625396675000047],
              [45.10109327400005, 8.634014181000055],
              [45.068244969000034, 8.644348195000077],
              [45.020759663000035, 8.659276868000063],
              [45.020584138000061, 8.659331291000058],
              [45.001567831000045, 8.665308888000084],
              [45.000000087000046, 8.666071810000062],
              [44.991100370000083, 8.670426337000038],
              [44.948066725000047, 8.684393944000078],
              [44.866928021000035, 8.710699151000085],
              [44.830776180000043, 8.719886845000076],
              [44.804908705000059, 8.732884377000062],
              [44.792869625000037, 8.734983383000042],
              [44.78100201500007, 8.740301227000089],
              [44.707344047000049, 8.766075173000047],
              [44.692028101000062, 8.771428068000091],
              [44.690964909000058, 8.771776770000088],
              [44.690560216000051, 8.771909500000049],
              [44.586906389000035, 8.805905479000046],
              [44.556839009000043, 8.815748239000072],
              [44.525691985000037, 8.826693529000067],
              [44.520080577000044, 8.830497782000066],
              [44.502452886000071, 8.834557573000041],
              [44.466911239000069, 8.846017906000043],
              [44.444366493000075, 8.853285722000066],
              [44.379028232000053, 8.876470668000081],
              [44.346561433000034, 8.886116006000066],
              [44.320716845000049, 8.893793982000091],
              [44.270710028000053, 8.911488532000078],
              [44.252838151000049, 8.915754395000079],
              [44.234458890000042, 8.922534920000089],
              [44.225872111000058, 8.925004080000065],
              [44.179622601000062, 8.938287697000078],
              [44.104953830000056, 8.963492446000089],
              [44.038421672000084, 8.985926543000062],
              [44.033794486000033, 8.986064044000045],
              [44.005855487000076, 8.996558176000065],
              [43.988792397000054, 9.010767897000051],
              [43.985641499000053, 9.013393480000047],
              [43.897308415000055, 9.100441029000081],
              [43.885616331000051, 9.113656165000066],
              [43.884277274000056, 9.115260042000045],
              [43.871868221000057, 9.130150780000065],
              [43.809276645000068, 9.194377050000071],
              [43.796977970000057, 9.20694730200006],
              [43.76981739200005, 9.234705009000038],
              [43.765602079000075, 9.237148218000073],
              [43.707557765000047, 9.296465813000054],
              [43.667491832000053, 9.337389999000038],
              [43.665561593000064, 9.341028278000067],
              [43.663238494000041, 9.34167000900004],
              [43.656814606000069, 9.350038463000089],
              [43.646686482000064, 9.358331679000059],
              [43.60727309300006, 9.358924753000053],
              [43.601539683000055, 9.359012516000064],
              [43.593864500000052, 9.360689108000088],
              [43.565605176000076, 9.372741705000067],
              [43.556108494000057, 9.37531243400008],
              [43.555436788000065, 9.375494264000054],
              [43.534870204000072, 9.381061592000037],
              [43.519924142000036, 9.387184078000075],
              [43.491375038000058, 9.401518775000056],
              [43.489147091000063, 9.403246817000081],
              [43.468303716000037, 9.419411556000057],
              [43.445522263000043, 9.444892028000083],
              [43.432224319000056, 9.470800501000042],
              [43.417293574000041, 9.504579505000038],
              [43.413707738000085, 9.51267998600008],
              [43.410255347000088, 9.53282936800008],
              [43.40487677200008, 9.545818340000039],
              [43.398239063000062, 9.552792593000049],
              [43.375175400000046, 9.569071766000093],
              [43.371318796000082, 9.576110894000067],
              [43.360671936000074, 9.586224511000069],
              [43.341552819000071, 9.59860419000006],
              [43.340621849000058, 9.600287450000053],
              [43.324783382000078, 9.606131510000068],
              [43.306899972000053, 9.608273587000042],
              [43.299194423000074, 9.611092446000043],
              [43.289581252000062, 9.657529916000044],
              [43.289442997000037, 9.658311868000055],
              [43.283725659000083, 9.690648087000056],
              [43.269947183000056, 9.768550833000063],
              [43.269058293000057, 9.787374493000073],
              [43.262985275000062, 9.815099763000092],
              [43.256874142000072, 9.842641847000039],
              [43.18181233100006, 9.882672278000086],
              [43.168483662000085, 9.888140598000064],
              [43.162147446000063, 9.890740321000067],
              [43.149890941000081, 9.899429371000053],
              [43.145263756000077, 9.898866662000046],
              [43.144477856000037, 9.892645781000056],
              [43.138130197000066, 9.886300194000057],
              [43.128852849000054, 9.886322901000085],
              [43.115352618000088, 9.888778635000051],
              [43.09986493200006, 9.896476705000055],
              [43.089473790000056, 9.904523933000064],
              [43.085895432000086, 9.908803852000062],
              [43.076381647000062, 9.933189453000068],
              [43.065181719000066, 9.961888311000052],
              [43.061817181000038, 9.966868350000084],
              [43.048900564000064, 9.999998955000081],
              [43.02924342700004, 10.050415032000046],
              [43.017398885000034, 10.08078282300005],
              [43.010650527000053, 10.091433469000037],
              [42.999999971000079, 10.101203964000092],
              [42.993209894000074, 10.105673735000039],
              [42.978626236000082, 10.117048198000077],
              [42.943225784000049, 10.15142721400008],
              [42.942001251000079, 10.152530646000059],
              [42.881149251000068, 10.207107612000073],
              [42.865295466000077, 10.224254590000044],
              [42.858325989000036, 10.23407067900007],
              [42.853359286000057, 10.24253662700005],
              [42.832630075000054, 10.277875898000048],
              [42.817436499000053, 10.318300739000051],
              [42.817313867000053, 10.31862701700004],
              [42.804828666000049, 10.351845811000089],
              [42.80324560300005, 10.358214374000056],
              [42.79185104700008, 10.404099498000051],
              [42.76307668000004, 10.456705046000081],
              [42.756904637000048, 10.471559472000081],
              [42.756122521000066, 10.474690366000061],
              [42.746669738000037, 10.512254701000074],
              [42.73943715300004, 10.521154417000048],
              [42.731651231000058, 10.526392968000039],
              [42.716869340000073, 10.530390767000085],
              [42.704048145000058, 10.538955741000052],
              [42.698398532000056, 10.546208329000081],
              [42.69541550100007, 10.55823137100009],
              [42.690391581000085, 10.572299896000061],
              [42.68316638500005, 10.592531185000041],
              [42.683010142000057, 10.606331917000091],
              [42.685016610000048, 10.612955119000048],
              [42.692962650000084, 10.625197297000057],
              [42.70320124300008, 10.636005356000055],
              [42.72260293100004, 10.651210832000061],
              [42.738666571000067, 10.677094526000076],
              [42.766525197000078, 10.721972541000071],
              [42.788574272000062, 10.750683744000071],
              [42.797496875000036, 10.764507363000064],
              [42.810882582000033, 10.780308706000085],
              [42.811267782000073, 10.780852222000078],
              [42.840930950000086, 10.822856954000088],
              [42.85570905600008, 10.838156681000044],
              [42.864223571000082, 10.85429186500005],
              [42.870437694000088, 10.861278462000087],
              [42.876255082000057, 10.871494348000056],
              [42.885539999000059, 10.88309858000008],
              [42.910045530000048, 10.913724974000047],
              [42.93132771900008, 10.94796279600007],
              [42.961639285000047, 10.984408471000052],
              [42.985897116000046, 11.02190405500005],
              [42.996627775000036, 11.04258352800008],
              [42.999999971000079, 11.054946808000068],
              [43.208805043000041, 11.394891633000043],
              [43.250999017000083, 11.463583535000055],
              [43.251000230000045, 11.463585508000051],
              [43.25140470000008, 11.463395998000067],
              [43.251999440000077, 11.463117340000053],
              [43.254536188000088, 11.462256507000063],
              [43.256689601000062, 11.461388004000071],
              [43.258635134000087, 11.460794759000066],
              [43.263245016000042, 11.460507604000043],
              [43.265588785000034, 11.460233966000089],
              [43.267753861000074, 11.460414698000079],
              [43.26906279800005, 11.460828831000072],
              [43.270419091000065, 11.461209545000088],
              [43.270999658000051, 11.461352809000061],
              [43.271181659000035, 11.461416925000037],
              [43.271573129000046, 11.461459388000037],
              [43.271941946000084, 11.461649745000045],
              [43.272275070000035, 11.461721129000068],
              [43.272593382000082, 11.461846718000061],
              [43.272976813000071, 11.46193267600006],
              [43.273255672000062, 11.461967059000074],
              [43.27349967400005, 11.46203582600009],
              [43.273691389000078, 11.462156168000092],
              [43.273774693000064, 11.462229208000053],
              [43.274380893000057, 11.462506351000059],
              [43.275117341000055, 11.463032883000039],
              [43.276189284000054, 11.463886438000088],
              [43.276498613000058, 11.464160076000041],
              [43.27750988400004, 11.465207039000063],
              [43.27739127600006, 11.465466225000057],
              [43.277426133000063, 11.465586565000081],
              [43.27721198200004, 11.465732009000078],
              [43.27721198200004, 11.465783583000075],
              [43.277281697000035, 11.465817966000088],
              [43.277368840000065, 11.465835158000061],
              [43.277543127000058, 11.465835158000061],
              [43.277647699000056, 11.465835158000061],
              [43.277670590000071, 11.465821875000074],
              [43.277861084000051, 11.465924367000071],
              [43.277874272000076, 11.465921115000071],
              [43.278048559000069, 11.465886732000058],
              [43.278292560000068, 11.465886732000058],
              [43.278536562000056, 11.465903923000042],
              [43.278797992000079, 11.465903923000042],
              [43.27907685100007, 11.465903923000042],
              [43.279268566000042, 11.465972689000068],
              [43.279373138000039, 11.466041455000038],
              [43.279495139000062, 11.466161795000062],
              [43.279704283000058, 11.466264943000056],
              [43.280070285000079, 11.466350900000066],
              [43.28034914400007, 11.466350900000066],
              [43.280575717000033, 11.466488431000073],
              [43.280750004000083, 11.46660877100004],
              [43.280994005000082, 11.46669472800005],
              [43.281220578000045, 11.466883833000054],
              [43.281499437000036, 11.466883833000054],
              [43.28176086700006, 11.466918215000078],
              [43.28190029600006, 11.466952598000091],
              [43.282004869000048, 11.467004172000088],
              [43.282109441000046, 11.467072938000058],
              [43.282196584000076, 11.467141703000038],
              [43.282239524000033, 11.467200349000052],
              [43.284060194000062, 11.467605869000067],
              [43.284270597000045, 11.467605869000067],
              [43.284636599000066, 11.467605869000067],
              [43.284981392000077, 11.467764960000068],
              [43.285338311000089, 11.467800652000051],
              [43.285814203000086, 11.467872035000084],
              [43.286209566000082, 11.467949639000039],
              [43.287743290000037, 11.468087169000057],
              [43.288550583000074, 11.46812187900008],
              [43.289193038000064, 11.468145673000038],
              [43.289668930000062, 11.468109982000044],
              [43.290044186000046, 11.468117567000093],
              [43.291351337000037, 11.467894080000065],
              [43.291677785000047, 11.46755429500007],
              [43.291886929000043, 11.467451147000077],
              [43.292200645000037, 11.467262042000073],
              [43.292514361000087, 11.467021364000061],
              [43.293107251000038, 11.466896456000086],
              [43.293476068000075, 11.466813175000084],
              [43.293618835000075, 11.46664661300008],
              [43.294094728000061, 11.466432461000068],
              [43.295629480000059, 11.46489770900007],
              [43.296902492000072, 11.462506351000059],
              [43.297675816000037, 11.460805036000068],
              [43.299253247000081, 11.459942346000048],
              [43.301562172000047, 11.460237583000037],
              [43.303291344000058, 11.460114993000047],
              [43.304854527000089, 11.460237583000037],
              [43.305752442000085, 11.460195406000082],
              [43.306134800000052, 11.459639100000061],
              [43.308240623000074, 11.460531398000057],
              [43.308407186000068, 11.461150058000044],
              [43.30900205100005, 11.461423696000054],
              [43.308978256000046, 11.462173226000061],
              [43.309025845000065, 11.46263722100008],
              [43.308942564000063, 11.463684184000044],
              [43.308787899000038, 11.465397396000071],
              [43.308419083000047, 11.466789381000069],
              [43.308157342000072, 11.467860138000049],
              [43.307729039000037, 11.468800025000064],
              [43.30725314700004, 11.469894577000048],
              [43.305873060000067, 11.471286562000046],
              [43.304576113000053, 11.472877591000042],
              [43.304453147000061, 11.473094633000073],
              [43.304477066000061, 11.47310407000009],
              [43.304383463000079, 11.473532126000066],
              [43.304158706000067, 11.474260541000092],
              [43.303987462000066, 11.475083964000078],
              [43.304019571000083, 11.475738478000039],
              [43.304158706000067, 11.476255754000078],
              [43.304351355000051, 11.476456330000076],
              [43.304618924000067, 11.476466887000072],
              [43.304779465000081, 11.476076291000084],
              [43.304640329000051, 11.475685694000049],
              [43.304501194000068, 11.475010067000085],
              [43.304576113000053, 11.474334438000085],
              [43.304736654000067, 11.473658807000049],
              [43.304878307000081, 11.473350786000083],
              [43.304918600000065, 11.473152067000058],
              [43.305336007000051, 11.472423649000064],
              [43.307487256000059, 11.470512864000057],
              [43.308949821000056, 11.469401532000063],
              [43.31058407900008, 11.467660576000071],
              [43.311607561000073, 11.466075542000056],
              [43.312285707000058, 11.463767465000046],
              [43.312790734000089, 11.462453412000059],
              [43.312893004000045, 11.461963427000057],
            ],
          ],
          [
            [
              [43.476711248000072, 11.439947056000051],
              [43.476554551000049, 11.43961029500008],
              [43.476277758000037, 11.439015434000055],
              [43.475768373000051, 11.437920702000042],
              [43.475244587000077, 11.437274817000059],
              [43.474303914000075, 11.435629787000039],
              [43.47383357800004, 11.432972411000037],
              [43.472850147000088, 11.431327356000054],
              [43.472166021000078, 11.430525915000089],
              [43.471652927000036, 11.429555747000052],
              [43.471182591000058, 11.427362312000071],
              [43.469215729000041, 11.425464132000059],
              [43.466992321000077, 11.42402994400004],
              [43.466254426000035, 11.42298350100009],
              [43.465708840000048, 11.422673604000067],
              [43.465066676000049, 11.420730802000037],
              [43.464840969000079, 11.419645284000069],
              [43.464384091000056, 11.41917952600005],
              [43.464298576000033, 11.418546782000078],
              [43.464042028000051, 11.417576573000076],
              [43.463058598000089, 11.417154741000047],
              [43.461818620000088, 11.417660939000086],
              [43.461006221000048, 11.417956220000065],
              [43.460375517000045, 11.417628733000072],
              [43.459886554000036, 11.417732103000048],
              [43.459835498000075, 11.417564940000091],
              [43.45972348500004, 11.417576573000076],
              [43.458697296000082, 11.417028192000089],
              [43.457799381000086, 11.41584706000009],
              [43.456901466000033, 11.415129942000078],
              [43.455533215000059, 11.414750291000075],
              [43.454378753000071, 11.415003392000074],
              [43.453865659000087, 11.415425227000071],
              [43.453694627000061, 11.416015794000089],
              [43.454464269000084, 11.41652199300006],
              [43.455191152000054, 11.41643762700005],
              [43.45608906700005, 11.41652199300006],
              [43.456815951000067, 11.416775093000069],
              [43.457713866000063, 11.417154741000047],
              [43.458483507000039, 11.418209318000038],
              [43.458355234000067, 11.418799880000051],
              [43.458055929000068, 11.419516988000055],
              [43.457115256000066, 11.419727902000091],
              [43.45608906700005, 11.419432623000091],
              [43.455105636000042, 11.418462416000068],
              [43.454250479000052, 11.417745305000039],
              [43.453865659000087, 11.418040586000075],
              [43.453951174000053, 11.41917952600005],
              [43.45467805800007, 11.422090695000065],
              [43.454806331000043, 11.422976525000081],
              [43.454928135000046, 11.424358409000092],
              [43.454977363000069, 11.424916903000053],
              [43.454806331000043, 11.428291442000045],
              [43.454464268000038, 11.42985215200008],
              [43.453609111000048, 11.430653594000091],
              [43.452069829000038, 11.430948862000037],
              [43.451377534000073, 11.431138572000066],
              [43.450530546000039, 11.43137067300006],
              [43.450083236000069, 11.431423626000083],
              [43.450076542000033, 11.431654777000062],
              [43.449997229000076, 11.431669447000047],
              [43.448841334000065, 11.431542747000037],
              [43.447342952000042, 11.431753914000069],
              [43.44702662900005, 11.432103415000086],
              [43.447195433000047, 11.433393991000059],
              [43.447451980000039, 11.433942340000044],
              [43.448221622000062, 11.434828131000074],
              [43.448520926000072, 11.435924822000061],
              [43.449119536000069, 11.436894968000047],
              [43.449974694000048, 11.437569850000045],
              [43.450402272000076, 11.438286911000091],
              [43.450721502000079, 11.438714296000057],
              [43.451000882000073, 11.439088329000072],
              [43.451000882000073, 11.439311472000043],
              [43.451000882000073, 11.439721026000086],
              [43.450616061000062, 11.439889745000073],
              [43.450060209000071, 11.439889745000073],
              [43.449205052000082, 11.439636666000069],
              [43.44901076900004, 11.439288200000078],
              [43.448734716000047, 11.438793070000088],
              [43.448636115000056, 11.438689723000039],
              [43.448050590000037, 11.438076011000078],
              [43.448178864000056, 11.436810608000087],
              [43.447965074000081, 11.43617790400009],
              [43.447366464000083, 11.436304445000076],
              [43.447056385000053, 11.436652048000042],
              [43.447043275000055, 11.436737400000084],
              [43.446872032000044, 11.438046606000057],
              [43.447022664000087, 11.438670711000043],
              [43.447166494000044, 11.439266633000045],
              [43.447300141000085, 11.439820360000056],
              [43.448712902000068, 11.44096062400007],
              [43.45076782600006, 11.441847493000068],
              [43.452180587000043, 11.44269212800009],
              [43.453208049000068, 11.444254695000041],
              [43.455048919000035, 11.446619645000055],
              [43.456632923000086, 11.448562267000057],
              [43.459115957000051, 11.449406881000073],
              [43.46172742400006, 11.449871418000043],
              [43.466002757000069, 11.449955765000084],
              [43.468873666000036, 11.449422202000051],
              [43.470412949000035, 11.449211310000067],
              [43.471225348000075, 11.44874734800004],
              [43.472037748000048, 11.448283385000082],
              [43.472850147000088, 11.447735065000074],
              [43.473448757000085, 11.44706020700005],
              [43.47396185100007, 11.446638420000056],
              [43.474988040000085, 11.445879203000061],
              [43.475629408000088, 11.444740372000069],
              [43.476313533000052, 11.442589235000071],
              [43.476793493000059, 11.440474416000086],
              [43.476711248000072, 11.439947056000051],
            ],
          ],
          [
            [
              [43.367546133000076, 11.503522644000043],
              [43.368744839000044, 11.502847084000052],
              [43.369429814000057, 11.501960409000048],
              [43.368659217000072, 11.501875964000078],
              [43.367460511000047, 11.502847084000052],
              [43.366176184000039, 11.503058197000087],
              [43.365319965000083, 11.502551526000047],
              [43.365020289000086, 11.50132706800008],
              [43.364549368000041, 11.499891490000039],
              [43.364463747000059, 11.499152336000066],
              [43.364292503000058, 11.498138980000078],
              [43.364249692000044, 11.496830056000078],
              [43.364463747000059, 11.49661893800004],
              [43.364977478000071, 11.497041173000071],
              [43.364977478000071, 11.49839232000005],
              [43.365405587000055, 11.498561212000084],
              [43.365833696000038, 11.49784341700007],
              [43.365962129000081, 11.496830056000078],
              [43.365919318000067, 11.495901138000079],
              [43.365877485000055, 11.495505053000045],
              [43.368257060000076, 11.493824554000071],
              [43.368102675000046, 11.493198564000068],
              [43.367417701000079, 11.493071892000046],
              [43.367344112000069, 11.492990773000088],
              [43.366689915000052, 11.492269635000071],
              [43.366861159000052, 11.491594047000092],
              [43.367111072000057, 11.491224315000068],
              [43.367374890000065, 11.490834010000071],
              [43.367374890000065, 11.490453990000049],
              [43.366997117000039, 11.490684645000044],
              [43.366475860000037, 11.491002907000052],
              [43.366261806000068, 11.490496215000064],
              [43.366347427000051, 11.48969394900007],
              [43.36660429300008, 11.489102805000073],
              [43.366646949000085, 11.489026311000089],
              [43.367075213000078, 11.488258311000038],
              [43.367216308000081, 11.488038343000085],
              [43.367145580000056, 11.487703775000057],
              [43.36660429300008, 11.488088662000052],
              [43.366464237000059, 11.488161020000064],
              [43.365705263000052, 11.488553134000085],
              [43.364463747000059, 11.488933156000087],
              [43.36382158300006, 11.488553134000085],
              [43.363950015000057, 11.487286390000065],
              [43.363920668000048, 11.487206789000084],
              [43.363603823000062, 11.486953806000088],
              [43.363219002000051, 11.486194698000077],
              [43.362748665000083, 11.485477760000038],
              [43.362329266000074, 11.484271246000048],
              [43.362235571000042, 11.484001707000061],
              [43.362153119000084, 11.482673411000064],
              [43.36168103600005, 11.482008226000062],
              [43.361734321000085, 11.481587778000062],
              [43.361337656000046, 11.481218271000046],
              [43.361337656000046, 11.480627842000047],
              [43.361081109000054, 11.479868716000055],
              [43.360525257000063, 11.479362632000061],
              [43.360495805000085, 11.478636381000058],
              [43.360204297000053, 11.478229052000074],
              [43.360140436000052, 11.478139818000045],
              [43.360140436000052, 11.477921618000039],
              [43.360140436000052, 11.477422860000047],
              [43.360097678000045, 11.476495029000091],
              [43.359926647000066, 11.475904590000084],
              [43.359627342000067, 11.475061103000087],
              [43.359719630000086, 11.474605958000041],
              [43.359883889000059, 11.473795867000092],
              [43.360482499000057, 11.472277577000057],
              [43.360995593000041, 11.469494024000085],
              [43.360995593000041, 11.468481816000065],
              [43.360867320000068, 11.467807009000069],
              [43.360396983000044, 11.466668268000092],
              [43.360525257000063, 11.46607780700009],
              [43.360761330000059, 11.465893970000081],
              [43.361337656000046, 11.465445170000066],
              [43.362363845000061, 11.465571698000076],
              [43.362736177000045, 11.465954930000066],
              [43.363222230000076, 11.465817948000051],
              [43.363778772000046, 11.465564579000045],
              [43.364292503000058, 11.465184525000041],
              [43.365439655000046, 11.464214718000051],
              [43.365677601000073, 11.462965501000042],
              [43.364592179000056, 11.462524132000055],
              [43.363393473000087, 11.462144074000037],
              [43.362023524000051, 11.46205961600009],
              [43.360396708000053, 11.462355217000038],
              [43.35915519200006, 11.462566361000086],
              [43.358470217000047, 11.462650818000043],
              [43.358389123000052, 11.462470838000058],
              [43.357929231000071, 11.462519691000068],
              [43.357504806000065, 11.462318738000079],
              [43.356858958000089, 11.462012947000062],
              [43.355274953000048, 11.461126142000069],
              [43.354594039000062, 11.460067772000059],
              [43.354568573000051, 11.46002818900007],
              [43.353536523000059, 11.458449211000072],
              [43.353247284000076, 11.458259005000059],
              [43.351277981000067, 11.457329958000059],
              [43.350971388000062, 11.457266289000074],
              [43.350823190000085, 11.457235513000057],
              [43.347913121000033, 11.45498531700008],
              [43.347355837000066, 11.454910355000038],
              [43.346329648000051, 11.454657290000057],
              [43.345474491000061, 11.454108982000037],
              [43.344277271000067, 11.453518495000083],
              [43.343293840000058, 11.452759296000067],
              [43.342267652000089, 11.451240891000054],
              [43.341369737000036, 11.450355152000043],
              [43.34094581100004, 11.449513269000079],
              [43.340802884000084, 11.449229428000081],
              [43.340569165000034, 11.448785360000045],
              [43.340480351000053, 11.448687198000073],
              [43.340752747000067, 11.448569761000044],
              [43.340574287000038, 11.448212842000089],
              [43.340298049000069, 11.448261827000067],
              [43.340336341000068, 11.448034383000049],
              [43.340157882000085, 11.447677464000037],
              [43.339800962000083, 11.447320544000092],
              [43.339384557000074, 11.446606706000068],
              [43.338789691000045, 11.446428247000085],
              [43.338373286000035, 11.446309273000054],
              [43.337837907000051, 11.446249787000056],
              [43.337179467000055, 11.446313840000073],
              [43.336238794000053, 11.445090653000079],
              [43.335127089000082, 11.443614386000093],
              [43.334699511000053, 11.443276953000066],
              [43.334271932000036, 11.443023877000087],
              [43.333972627000037, 11.442559905000053],
              [43.333972627000037, 11.441674138000053],
              [43.333801596000058, 11.440914907000092],
              [43.333288502000073, 11.440450931000044],
              [43.332818165000049, 11.440029134000042],
              [43.332305071000064, 11.440071314000079],
              [43.332305071000064, 11.440577470000051],
              [43.332390587000077, 11.44121016400004],
              [43.332860923000055, 11.442138112000066],
              [43.333587807000072, 11.443698745000063],
              [43.333929869000087, 11.445048475000078],
              [43.333673322000038, 11.446018589000062],
              [43.333074712000041, 11.446735628000056],
              [43.33251886000005, 11.447030878000078],
              [43.331995398000061, 11.446971297000061],
              [43.331499332000078, 11.447317917000078],
              [43.332269929000063, 11.447951381000053],
              [43.332740849000061, 11.447909150000044],
              [43.332978786000069, 11.448222100000066],
              [43.334143659000063, 11.448296235000043],
              [43.335896731000048, 11.448633662000077],
              [43.336118945000067, 11.448641934000079],
              [43.338162897000075, 11.448718019000069],
              [43.338616011000056, 11.449462974000085],
              [43.338862812000059, 11.449549922000074],
              [43.339890274000084, 11.450225611000064],
              [43.341131791000066, 11.451281373000086],
              [43.342159253000034, 11.452379360000066],
              [43.342887039000061, 11.453350653000086],
              [43.343914501000086, 11.454068563000078],
              [43.345027585000082, 11.454913160000046],
              [43.346269102000065, 11.45563106600008],
              [43.347424997000076, 11.455926674000068],
              [43.348623703000044, 11.456644577000077],
              [43.349822409000069, 11.457573626000055],
              [43.351281862000064, 11.458206579000091],
              [43.352055187000076, 11.458979903000056],
              [43.352947484000083, 11.459515282000041],
              [43.353675392000071, 11.460867500000063],
              [43.354916909000053, 11.462176593000038],
              [43.355773128000067, 11.462894481000092],
              [43.357357132000061, 11.463147852000077],
              [43.358127729000046, 11.463401223000062],
              [43.358684271000072, 11.463992422000047],
              [43.359369246000085, 11.46475253300008],
              [43.360097032000056, 11.465090359000044],
              [43.359968599000069, 11.46669502900005],
              [43.359925788000055, 11.467750728000055],
              [43.360268275000067, 11.468721967000079],
              [43.360225464000052, 11.470791118000079],
              [43.359840166000083, 11.472353528000042],
              [43.359278138000036, 11.473018764000074],
              [43.359339559000034, 11.47318028300009],
              [43.359112380000056, 11.473788499000079],
              [43.359076954000045, 11.474161215000038],
              [43.35898394700007, 11.475139759000058],
              [43.359369246000085, 11.476533238000059],
              [43.359388180000053, 11.476649025000086],
              [43.359583300000054, 11.477842258000067],
              [43.359754544000054, 11.478897914000072],
              [43.360439519000067, 11.480629182000087],
              [43.36095325000008, 11.482022634000089],
              [43.361041244000035, 11.482258209000065],
              [43.361552603000064, 11.483627206000051],
              [43.361511388000054, 11.484521538000081],
              [43.361509792000049, 11.484556165000072],
              [43.361604627000077, 11.48497040400008],
              [43.361809469000036, 11.485865147000084],
              [43.362047791000066, 11.486648683000055],
              [43.362194767000062, 11.486824167000066],
              [43.363050986000076, 11.488639835000072],
              [43.363521906000074, 11.489568777000045],
              [43.363573408000036, 11.489682091000077],
              [43.364078448000043, 11.490793287000088],
              [43.364549368000041, 11.491806670000074],
              [43.364677801000084, 11.493537858000082],
              [43.364610290000087, 11.494092735000038],
              [43.364335314000073, 11.494888271000093],
              [43.364256164000039, 11.495174507000058],
              [43.363950015000057, 11.496281651000061],
              [43.363479095000059, 11.497548355000049],
              [43.363907204000043, 11.499659514000086],
              [43.364463747000059, 11.501939549000042],
              [43.365063100000043, 11.504092897000078],
              [43.365748074000066, 11.506246229000055],
              [43.365576831000055, 11.50844176600009],
              [43.364977478000071, 11.510848392000071],
              [43.364420936000045, 11.512283914000079],
              [43.364806234000071, 11.513592765000055],
              [43.365148721000082, 11.515281597000069],
              [43.365491209000083, 11.516695734000052],
              [43.365919318000067, 11.518553428000075],
              [43.366561482000066, 11.519946689000051],
              [43.367289268000036, 11.520664428000089],
              [43.368188297000074, 11.520706647000054],
              [43.367631755000048, 11.518680088000053],
              [43.367032402000063, 11.516569073000085],
              [43.366304616000036, 11.514753587000087],
              [43.365748074000066, 11.51352918300006],
              [43.365962129000081, 11.511629235000044],
              [43.365919318000067, 11.510109267000075],
              [43.366946780000035, 11.50892706400009],
              [43.367203646000064, 11.507955965000065],
              [43.367374890000065, 11.506604865000043],
              [43.367631755000048, 11.505549314000064],
              [43.367075213000078, 11.504747092000059],
              [43.365919318000067, 11.504324869000072],
              [43.365491209000083, 11.503733756000088],
              [43.366047751000053, 11.50356486700008],
              [43.367546133000076, 11.503522644000043],
            ],
          ],
          [
            [
              [43.449012578000065, 11.489131630000088],
              [43.447771062000072, 11.488540484000055],
              [43.446872032000044, 11.488836057000071],
              [43.447428574000071, 11.489511651000043],
              [43.44905538900008, 11.490567263000059],
              [43.450254095000048, 11.492382907000092],
              [43.450725573000057, 11.493312936000052],
              [43.451067503000047, 11.493987420000053],
              [43.451195936000033, 11.494409658000052],
              [43.451423185000067, 11.494913956000062],
              [43.451335907000043, 11.495192528000075],
              [43.452449476000083, 11.499062440000046],
              [43.452394642000058, 11.499434249000046],
              [43.452371489000086, 11.500690131000056],
              [43.452370839000082, 11.500725402000057],
              [43.453081158000089, 11.501528987000086],
              [43.455435942000065, 11.504949273000079],
              [43.456591927000034, 11.506384937000064],
              [43.458261682000057, 11.507778368000061],
              [43.459760181000036, 11.509340691000091],
              [43.460787723000067, 11.511071904000062],
              [43.461772451000058, 11.51335202100006],
              [43.462671550000039, 11.514787641000055],
              [43.463656278000087, 11.515040985000041],
              [43.464855077000038, 11.51487208900005],
              [43.465968247000035, 11.514280953000082],
              [43.46678171800005, 11.513689815000077],
              [43.467295489000037, 11.512929779000046],
              [43.467552374000036, 11.512211965000063],
              [43.467680817000087, 11.511325251000073],
              [43.46712423200006, 11.51052298400009],
              [43.466353575000085, 11.509678490000056],
              [43.466098127000066, 11.509404964000055],
              [43.464855077000038, 11.508073943000056],
              [43.461601194000082, 11.504949273000079],
              [43.46023113800004, 11.502289054000073],
              [43.458561382000084, 11.49912209300004],
              [43.458392, 11.497994463000055],
              [43.458259739000084, 11.497766182000078],
              [43.45754635600008, 11.496276220000084],
              [43.457531953000057, 11.49624613800006],
              [43.457502868000063, 11.496213710000063],
              [43.456547302000047, 11.49514832400007],
              [43.455605461000061, 11.49417717700004],
              [43.45556348000008, 11.494001999000091],
              [43.455393376000075, 11.493896636000045],
              [43.454890401000057, 11.493358035000085],
              [43.454492377000065, 11.492931820000081],
              [43.453122427000039, 11.491496199000039],
              [43.451067503000047, 11.490060570000082],
              [43.449012578000065, 11.489131630000088],
            ],
          ],
          [
            [
              [45.867509169000073, 10.818334503000074],
              [45.86798506100007, 10.818144146000066],
              [45.868365775000086, 10.818144146000066],
              [45.868727453000076, 10.818029932000059],
              [45.868879739000079, 10.817782468000075],
              [45.869146238000042, 10.817496933000086],
              [45.869374667000045, 10.81747789700006],
              [45.869660202000034, 10.81747789700006],
              [45.869793452000067, 10.817496933000086],
              [45.870001625000043, 10.81764833200009],
              [45.870002844000055, 10.817649219000089],
              [45.870173281000064, 10.817632175000085],
              [45.870174166000083, 10.817630183000063],
              [45.870319686000073, 10.817302763000043],
              [45.870783308000057, 10.816259613000057],
              [45.871049807000077, 10.815650471000083],
              [45.871087879000072, 10.815098436000085],
              [45.870783308000057, 10.814717723000058],
              [45.870440665000046, 10.814222795000092],
              [45.870136094000088, 10.813784974000043],
              [45.869774416000041, 10.813804010000069],
              [45.869127203000062, 10.814222795000092],
              [45.868556132000037, 10.814565437000056],
              [45.867889883000032, 10.814965186000052],
              [45.867166527000052, 10.815193615000055],
              [45.866481242000077, 10.815326865000088],
              [45.865929207000079, 10.815631436000047],
              [45.865872100000047, 10.816107328000044],
              [45.865834029000041, 10.816545149000092],
              [45.866100528000061, 10.816735505000054],
              [45.866462206000051, 10.816906827000082],
              [45.866233778000037, 10.81711621900007],
              [45.865586565000058, 10.817249469000046],
              [45.865301029000079, 10.817401755000049],
              [45.864939351000032, 10.81747789700006],
              [45.864710923000075, 10.817782468000075],
              [45.864661661000071, 10.818102670000087],
              [45.864634780000074, 10.818277396000042],
              [45.864615745000037, 10.818601003000083],
              [45.864520566000067, 10.818981717000042],
              [45.864653816000043, 10.819286288000058],
              [45.865034530000059, 10.819590859000073],
              [45.865262958000073, 10.819743144000086],
              [45.865643672000033, 10.819724109000049],
              [45.865834029000041, 10.819533752000041],
              [45.866119564000087, 10.819191109000087],
              [45.866328957000064, 10.818810395000071],
              [45.866652563000059, 10.818524860000082],
              [45.867204598000058, 10.818429682000044],
              [45.867509169000073, 10.818334503000074],
            ],
          ],
          [
            [
              [43.290292862000058, 11.487954386000069],
              [43.291145602000086, 11.487738020000052],
              [43.291871067000045, 11.487267104000068],
              [43.292825626000081, 11.48636345500006],
              [43.292825626000081, 11.486236180000049],
              [43.292660169000044, 11.486159815000065],
              [43.29241834700008, 11.486236180000049],
              [43.292380165000054, 11.486376182000072],
              [43.292227435000086, 11.486452547000056],
              [43.291985614000055, 11.486299817000088],
              [43.291960159000041, 11.485968903000071],
              [43.291832884000087, 11.485892539000076],
              [43.291081964000057, 11.486427092000042],
              [43.290814688000069, 11.486847098000055],
              [43.290381954000054, 11.487585290000084],
              [43.289363758000036, 11.48772529200005],
              [43.288281924000046, 11.487827112000048],
              [43.287060089000079, 11.487827112000048],
              [43.286309169000049, 11.48772529200005],
              [43.286003710000045, 11.487407106000092],
              [43.286156439000081, 11.487178012000072],
              [43.286270986000034, 11.486923463000039],
              [43.286143712000069, 11.486732551000046],
              [43.285749161000069, 11.48652891100005],
              [43.28563517200007, 11.486545195000076],
              [43.285303700000043, 11.486592549000079],
              [43.285216048000052, 11.486665175000041],
              [43.284858239000073, 11.486961645000065],
              [43.284613418000049, 11.486982934000082],
              [43.284565507000082, 11.486987100000078],
              [43.284374595000088, 11.486618004000093],
              [43.283980044000089, 11.486108905000037],
              [43.283216397000047, 11.484925252000039],
              [43.282681844000081, 11.483907056000078],
              [43.282363657000076, 11.483207046000075],
              [43.282427295000048, 11.482685220000064],
              [43.282656389000067, 11.482265214000051],
              [43.282847301000061, 11.481997937000074],
              [43.282850874000076, 11.481990477000068],
              [43.282847301000061, 11.481985210000062],
              [43.28260547900004, 11.48180702500008],
              [43.282338203000052, 11.481743388000041],
              [43.282083653000086, 11.482277941000063],
              [43.281841832000055, 11.483474322000063],
              [43.281269096000074, 11.484441609000044],
              [43.281180004000078, 11.485141619000046],
              [43.281447281000055, 11.486045268000055],
              [43.282249110000066, 11.48680891500004],
              [43.283700040000042, 11.487508926000089],
              [43.285143980000043, 11.48780688100004],
              [43.285303700000043, 11.48783983900006],
              [43.286512808000055, 11.488018024000041],
              [43.287289183000041, 11.488132571000051],
              [43.28871465800006, 11.488170753000077],
              [43.290292862000058, 11.487954386000069],
            ],
          ],
        ],
      },
    },
  ],
};
