export default class LegendItem {
  low_color;
  medium_color;
  high_color;

  constructor(low_color, medium_color, high_color) {
    this.low_color = low_color;
    this.medium_color = medium_color;
    this.high_color = high_color;
  }
}
