import { LegendItems } from "./LegendItems";
import { useEffect, useState } from "react";

export const LegendComponent = (product) => {
  const [legend, toggleLegend] = useState(false);

  const [colors, setColors] = useState({
    low_color: "green",
    medium_color: "yellow",
    high_color: "red",
  });

  useEffect(() => {
    const legendColors = getColorCode(product.product);
    setColors(legendColors);
  }, [product]);

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 1000,
      }}
    >
      <div className="fixed bottom-[0] right-3 h-fit w-20 mb-3  bg-white bg-opacity-35 ">
        <div>
          <button
            type="button"
            onClick={() => toggleLegend((current) => !current)}
            className="text-teal-200 bg-gradient-to-br from-teal-400 to-teal-900 hover:bg-gradient-to-bl  font-bold w-[100%] text-lg p-1  text-center"
          >
            Legend
          </button>
          {legend ? (
            <div className="grid justify-start m-2">
              <ul className="max-w-md divide-gray-100 space-y-1 list-inside">
                <li className="flex items-center">
                  <div
                    className="w-3 h-3 "
                    style={{ backgroundColor: colors.low_color }}
                  ></div>
                  <span className=" text-sm text-black pl-1 font-extrabold text-left">
                    Low
                  </span>
                </li>
                <hr class="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
                <li className="flex items-center">
                  <div
                    className="w-3 h-3 "
                    style={{ backgroundColor: colors.medium_color }}
                  ></div>
                  <span className=" text-sm text-black pl-1 font-extrabold text-left">
                    Medium
                  </span>
                </li>
                <hr class="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
                <li className="flex items-center">
                  <div
                    className="w-3 h-3 "
                    style={{ backgroundColor: colors.high_color }}
                  ></div>
                  <span className=" text-sm text-black pl-1 font-extrabold text-left">
                    High
                  </span>
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default LegendComponent;

const getColorCode = (data) => {
  if (data.product === "pptanomaly") {
    return LegendItems["pptanomaly"];
  }
  if (data.product === "tempanomaly") {
    return LegendItems["tempanomaly"];
  }
  if (data.product === "spi") {
    return LegendItems["spi"];
  }
  if (data.product === "ndvianomaly") {
    return LegendItems["ndvianomaly"];
  }
  if (data.product === "vci" && data.sensor === "vci") {
    return LegendItems["vci"];
  }
  if (data.product === "windspeed") {
    if (data.platform === "era5_wind_north") {
      return LegendItems["era5_wind_north"];
    }
    if (data.platform === "era5_wind_east") {
      return LegendItems["era5_wind_east"];
    }
    if (data.platform === "gfs_wind_north") {
      return LegendItems["gfs_wind_north"];
    }
    if (data.platform === "gfs_wind_east") {
      return LegendItems["gfs_wind_east"];
    }
    if (data.platform === "vci") {
      return {
        low_color: "green",
        medium_color: "yellow",
        high_color: "red",
      };
    }
  }
  if (
    data.product === "cloud" ||
    data.product === "ch4" ||
    data.product === "so2" ||
    data.product === "ozone" ||
    data.product === "co"
  ) {
    return LegendItems["cloud"];
  }
};
