import * as React from "react";

export default function DatePickerComponent({
  label,
  defaultValue,
  onChange,
  itemId,
  inputType,
}) {
  return (
    <>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text" htmlFor="inputGroupSelect01">
            {label}
          </span>
        </div>

        <input
          type={inputType}
          className="form-control"
          name="begin"
          placeholder="mm"
          id={itemId}
          onChange={onChange}
        />

        <span className="add-on">
          <i className="icon-th"></i>
        </span>
      </div>
    </>
  );
}
