import { useState } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PlotlyPlot from "../../utils/chart/PlotlyPlot";

function Chart() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    /*  border: "2px solid #000",
    boxShadow: 24,
    p: 4, */
  };

  return (
    <div>
      <div
        style={{ zIndex: 1000, bottom: 150 }}
        className="fixed  right-3  h-fit w-20 mb-3  bg-white bg-opacity-35 "
      >
        <button
          type="button"
          onClick={handleOpen}
          className=" text-teal-200 bg-gradient-to-br from-teal-400 to-teal-900 hover:bg-gradient-to-bl  font-bold w-[100%] text-lg p-1  text-center"
        >
          Chart
        </button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            /*  display: "flex",

            justifyContent: "center",
            alignItems: "center",
            width: "50%",
            height: "auto", */
            position: "fixed",
            top: "30%",
            left: "35%",
            width: "80%",

            /* border: "1px solid #000",
            boxShadow: 24,
            p: 24, */
          }}
        >
          <PlotlyPlot />
        </div>

        {/*  <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}
      </Modal>
    </div>
  );
}

export default Chart;
