import SideNavigation from "../navigation/Sidenav";
import MapComponent from "./MapComponent";
import AppBarComponent from "../navigation/AppBar";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

export default function Map() {
  const _user = localStorage.getItem("user");

  if (_user == null || !_user) {
    // eslint-disable-next-line no-restricted-globals

    return <Navigate to={"/"} replace={true} />;
  }

  /* useEffect(() => {
    if (_user == null || !_user) {
      // eslint-disable-next-line no-restricted-globals
      return <Navigate to={"/"} replace={true} />;
    }
  }, [_user]); */

  console.log("user", _user);

  return (
    <>
      <AppBarComponent />
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <>
          <SideNavigation />
        </>
        <>
          <MapComponent />
        </>

        {/* <PlotlyPlot /> */}
      </div>
    </>
  );
}
