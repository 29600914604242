import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {
  Checkbox,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import DatePickerComponent from "../DatePickerComponent";
import SliderComponent from "../SliderComponent";
import ButtonComponent from "../ButtonComponent";
import {
  UseApiContext,
  UseUpdateApiContext,
  UseUpdateDataSentContext,
  UseUpdateResponseContext,
} from "../../context/ApiContext";
import { BaseSelectComponent, SensorSelect } from "../BaseSelect";
import getApiData from "../../utils/map/GetApiData";
import { ChangeTileLayer, UpdateBaseMap } from "../../context/MapContext";
import { UseUpdateStatsContext } from "../../context/ApiContext";

export default function NavCardComponent({ cardTitle, product, description }) {
  const apiData = UseApiContext();
  const updateApi = UseUpdateApiContext();
  const updateMap = UpdateBaseMap();
  const updateTileLayerUrl = ChangeTileLayer();
  const updateDataSentContext = UseUpdateDataSentContext();
  const updateResponseContext = UseUpdateResponseContext();
  const updateStats = UseUpdateStatsContext();

  const handle = (e) => {
    //console.log("BEFOre", apiData);
    const newdata = { ...apiData };

    newdata[e.target.id] = e.target.value;

    updateApi(newdata);
    //console.log("After", newdata);
  };

  const handleClick = (e) => {
    e.preventDefault();

    updateDataSentContext(true);
    updateResponseContext(false);

    getApiData(apiData).then((res) => {
      updateTileLayerUrl(res.map);
      //graph data will be updated in this function
      updateStats(res.statistics);
      updateResponseContext(true);
      updateDataSentContext(false);
    });
  };

  return (
    <FormControl>
      <Card
        sx={{
          minWidth: 200,
          borderRadius: 5,
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <CardContent>
          <List>
            <ListItem disablePadding>
              <>
                <ListItemText
                  style={{ overflowWrap: "break-word", width: 100 }}
                  secondary={description}
                  primary={cardTitle}
                />
              </>
            </ListItem>
            <Divider />
            <ListItemText primary="Select:" />
            <ListItem>
              {
                //(product === "windspeed")?
              }
              <BaseSelectComponent
                onChange={(e) => handle(e)}
                itemId={"platform"}
                platform={product}
                title={"Platform"}
                product={product}
              />
            </ListItem>

            <ListItem>
              {product === "tempanomaly" ||
              product === "ndvianomaly" ||
              product === "pptanomaly" ||
              product === "vci" ||
              product === "spi" ? (
                <DatePickerComponent
                  label={"Pick Month"}
                  onChange={(e) => {
                    handle(e);
                  }}
                  itemId={"start_date"}
                  inputType={"month"}
                />
              ) : (
                <DatePickerComponent
                  label={"Start Date"}
                  onChange={(e) => handle(e)}
                  itemId={"start_date"}
                  inputType={"date"}
                />
              )}
            </ListItem>
            <ListItem>
              {product === "tempanomaly" ||
              product === "ndvianomaly" ||
              product === "pptanomaly" ||
              product === "vci" ||
              product === "spi" ? null : (
                <DatePickerComponent
                  label={"End Date"}
                  onChange={(e) => handle(e)}
                  itemId={"end_date"}
                  inputType={"date"}
                />
              )}
            </ListItem>

            <ListItem>
              <ButtonComponent
                name={"Update Map"}
                type={"submit"}
                onClick={handleClick}
              />
            </ListItem>
            <ListItem>
              <SliderComponent />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </FormControl>
  );
}
