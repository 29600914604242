import "./App.css";
import "bootstrap";
import "leaflet/dist/leaflet.css";

import React, { createContext, useState } from "react";

import { CssBaseline } from "@mui/material";
import Favicon from "react-favicon";
import { MapProvider } from "./context/MapContext";
import { ApiProvider } from "./context/ApiContext";
import Map from "./components/map/Map";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeComponent from "./components/HomeCompent";
import AppBarComponent from "./components/navigation/AppBar";

export const BaseMapContext = createContext();

function App() {
  const [faviconUrl] = useState(
    "https://images.squarespace-cdn.com/content/v1/6231e566c73e710fad2e1cf1/1da3a8c3-860b-4b00-9b8a-6ea581579b62/favicon.ico?format=100w://icons8.com/icon/exqa6Bvswweq/somalia"
  );

  return (
    <main>
      <>
        <MapProvider>
          <ApiProvider>
            <Favicon url={faviconUrl} />
            <CssBaseline />
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  exact
                  element={<HomeComponent loginPg={true} />}
                />
                <Route
                  path="/signup"
                  element={<HomeComponent loginPg={false} />}
                />

                <Route path="/map" exact element={<Map />} />
              </Routes>
            </BrowserRouter>
          </ApiProvider>
        </MapProvider>
      </>
    </main>
  );
}

export default App;
