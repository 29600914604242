import { createContext, useContext, useState } from "react";
import { dummy } from "../utils/chart/dummyData";

const ApiContext = createContext();
const UpdateApiContext = createContext();

const DataSentContext = createContext();
const UpdateDataSentContext = createContext();

const ResponseContext = createContext();
const UpdateResponseContext = createContext();

const statsContext = createContext();
const UpdateStatsContext = createContext();

export const UseApiContext = () => {
  return useContext(ApiContext);
};

export const UseUpdateApiContext = () => {
  return useContext(UpdateApiContext);
};

export const UseDataSentContext = () => {
  return useContext(DataSentContext);
};

export const UseUpdateDataSentContext = () => {
  return useContext(UpdateDataSentContext);
};

export const UseResponseContext = () => {
  return useContext(ResponseContext);
};

export const UseUpdateResponseContext = () => {
  return useContext(UpdateResponseContext);
};

export const UseStatsContext = () => {
  return useContext(statsContext);
};

export const UseUpdateStatsContext = () => {
  return useContext(UpdateStatsContext);
};

export const ApiProvider = ({ children }) => {
  const [apiData, setApiData] = useState({
    platform: "vci",
    sensor: "vci",
    product: "vci",
    start_date: "2023-11-01",
    end_date: "2023-11-30",
    reducer: "median",
    roi_name: "banadir",
  });

  const updateApiData = (value) => {
    setApiData(value);
  };

  const [dataSent, setDataSent] = useState(false);

  const updateDataSent = (value) => {
    setDataSent(value);
  };

  const [response, setResponse] = useState(false);

  const updateResponse = (value) => {
    setResponse(value);
  };

  const [stats, setStats] = useState([]);

  const updateStats = (value) => {
    setStats(value);
  };

  return (
    <>
      <ApiContext.Provider value={apiData}>
        <UpdateApiContext.Provider value={updateApiData}>
          <DataSentContext.Provider value={dataSent}>
            <UpdateDataSentContext.Provider value={updateDataSent}>
              <ResponseContext.Provider value={response}>
                <UpdateResponseContext.Provider value={updateResponse}>
                  <statsContext.Provider value={stats}>
                    <UpdateStatsContext.Provider value={updateStats}>
                      {children}
                    </UpdateStatsContext.Provider>
                  </statsContext.Provider>
                </UpdateResponseContext.Provider>
              </ResponseContext.Provider>
            </UpdateDataSentContext.Provider>
          </DataSentContext.Provider>
        </UpdateApiContext.Provider>
      </ApiContext.Provider>
    </>
  );
};
