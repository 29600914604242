import React, { useEffect, useState } from "react";
import { MapContainer, GeoJSON, TileLayer, ZoomControl } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import MinimapControl from "../../utils/map/minimapControl";
import { Box, Fab } from "@mui/material";
import SelectBasemap from "./SelectBasemap";
import L from "leaflet";
import { UseBaseMap, UseOpacity, UseTileLayer } from "../../context/MapContext";

import center from "@turf/center";
import { multiPolygon } from "@turf/helpers";
import bboxPolygon from "@turf/bbox";
import {
  somaliland,
  galmudug,
  puntland,
  sws,
  hirshabelle,
  jubaland,
} from "../../data";
//import { useNavigate } from "react-router-dom";
import LegendComponent from "../../utils/legend/LegendComponent";
import {
  UseApiContext,
  UseDataSentContext,
  UseResponseContext,
} from "../../context/ApiContext";
import LinearProgress from "@mui/material/LinearProgress";
import Chart from "../../utils/chart/Chart";

export const MapComponent = () => {
  const TileLayerURL = UseTileLayer();
  const TileOpacity = UseOpacity();
  const api = UseApiContext();
  const data_sent = UseDataSentContext();
  const response_received = UseResponseContext();

  const _user = localStorage.getItem("user");

  const user = JSON.parse(_user);

  const baseMap = UseBaseMap();
  let geoJson,
    geoJsonName = user.state;

  if (geoJsonName === "somaliland") {
    geoJson = somaliland;
  } else if (geoJsonName === "puntland") {
    geoJson = puntland;
  } else if (geoJsonName === "galmudug") {
    geoJson = galmudug;
  } else if (geoJsonName === "sws") {
    geoJson = sws;
  } else if (geoJsonName === "hirshabelle") {
    geoJson = hirshabelle;
  } else if (geoJsonName === "jubaland") {
    geoJson = jubaland;
  } else {
    geoJson = somaliland;
  }

  const features = multiPolygon(geoJson.features[0].geometry.coordinates);
  const mapCenter = center(features);
  const rightMapCenter = mapCenter.geometry.coordinates;
  const Bounds = bboxPolygon(features);

  const [MapCenter, setMapCenter] = useState([
    rightMapCenter[0],
    rightMapCenter[1],
  ]);

  const [MaxBounds, SetMaxBounds] = useState([
    [Bounds[1], Bounds[0]],
    [Bounds[3], Bounds[2]],
  ]);

  const [loadingBarDisplayed, setLoadingBarDisplayed] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (data_sent && !response_received) setLoadingBarDisplayed(true);
    if (response_received) setLoadingBarDisplayed(false);
  }, [data_sent, response_received]);

  useEffect(() => {
    const features = multiPolygon(geoJson.features[0].geometry.coordinates);
    const mapCenter = center(features);
    const rightMapCenter = mapCenter.geometry.coordinates;
    setMapCenter(rightMapCenter);
    const Bounds = bboxPolygon(features);
    //console.log("Bounds", Bounds);
    SetMaxBounds([
      [Bounds[1], Bounds[0]],
      [Bounds[3], Bounds[2]],
    ]);
  }, [geoJson]);

  return (
    <>
      <MapContainer
        center={[MapCenter[1], MapCenter[0]]}
        zoom={8}
        minZoom={7}
        maxBounds={MaxBounds}
        zoomControl={false}
        className="leaflef-container"
        style={{
          height: "100vh",
          zIndex: "0!important",
          overflow: "hidden",
        }}
      >
        <LegendComponent product={api ? api : "null"} />

        

        <Chart />

        {/* <LineChart width={500} height={500} data={dummy.statistics} /> */}

        {loadingBarDisplayed ? (
          <LinearProgress style={{ zIndex: 1000 }} />
        ) : null}
        <TileLayer
          maxZoom={100}
          url={baseMap.url}
          attribution={baseMap.attribution}
          style={{ zIndex: "0!important" }}
        />
        <TileLayer
          maxZoom={100}
          style={{ zIndex: "0!important" }}
          url={TileLayerURL}
          opacity={TileOpacity}
        />
        <GeoJSON
          attribution="&copy; credits due..."
          data={geoJson}
          style={function (feature) {
            return feature.properties && feature.properties.style;
          }}
          pointToLayer={function (feature, latlng) {
            return L.circleMarker(latlng, {
              radius: 8,
              fillColor: "#ff7800",
              color: "#000",
              weight: 1,
              opacity: 1,
              fillOpacity: 0.8,
            });
          }}
        />
        <ZoomControl
          key={"zoom"}
          position="topright"
          style={{ zIndex: "10!important" }}
        />
        <MinimapControl position="bottomleft" />
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <Fab variant="circular">
            <SelectBasemap />
          </Fab>
        </Box>
      </MapContainer>
    </>
  );
};

export default MapComponent;
