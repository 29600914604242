import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

import { Checkbox, ListItemButton } from "@mui/material";
import { UpdateBaseMap } from "../../context/MapContext";
import { Public } from "@mui/icons-material";

const modalOpt = [
  {
    data: {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    },
    name: "Open Street Map",
    default: true,
  },

  {
    data: {
      url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
      attribution:
        'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    },
    name: "Open Fire Map",
  },
  {
    data: {
      url: "http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
      attribution: "",
    },
    name: "Carto CDN Map",
  },
  {
    data: {
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      attribution: "",
    },
    name: "Esri World Imagery",
  },
  {
    data: {
      url: "http://tile.mtbmap.cz/mtbmap_tiles/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &amp; USGS',
    },
    name: "Mtb Map",
  },
];
export default function ModalDisplay() {
  const updateBasemap = UpdateBaseMap();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        subheader={<ListSubheader>SELECT A BASEMAP</ListSubheader>}
      >
        {modalOpt.map((bmap, index) => (
          <>
            <ListItem key={bmap.url}>
              <ListItemButton onClick={(event) => handleClick(event, index)}>
                <ListItemIcon>
                  <Public />
                </ListItemIcon>
                <ListItemText primary={bmap.name} />
                {bmap.default ? (
                  <Checkbox defaultChecked disabled checked />
                ) : (
                  <Checkbox
                    checked={index === selectedIndex}
                    onClick={(event) => updateBasemap(bmap.data)}
                    onSelect={(event) => handleClick(event, index)}
                  />
                )}
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </List>
    </>
  );
}
