import * as React from "react";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { UpdateTileOpacity } from "../context/MapContext";

export default function SliderComponent() {
  const updateOpacity = UpdateTileOpacity();

  const handleChange = (e) => {
    let num = e.target.value;
    let opacity = (num / 100) * 1;
    updateOpacity(opacity);
  };

  return (
    <Box sx={{ width: 320 }}>
      <Typography gutterBottom>Opacity</Typography>
      <Slider
        aria-label="opacity"
        defaultValue={50}
        min={2}
        max={100}
        valueLabelDisplay="auto"
        onChange={handleChange}
        style={{ color: "#008080" }}
      />
    </Box>
  );
}
