export const sws = {
  type: "FeatureCollection",
  name: "sws",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        OBJECTID_1: 2,
        Name: "South West Administration",
        Shape_Leng: 12.3615965956,
        Shape_Area: 7.7630046321600004,
        "area-km2": 95396.286907999995,
        style: {
          color: "black",
          fill: false,
          opacity: 1,
          clickable: false,
        },
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [45.120962143000042, 3.041570662000083],
              [45.333665848000066, 2.73221778900006],
              [45.330968857000073, 2.731599807000066],
              [45.340970994000088, 2.721601486000054],
              [45.330968857000073, 2.181650162000039],
              [45.39097023100004, 2.151651382000068],
              [45.297956467000063, 2.030035020000071],
              [45.267764387000057, 1.989422514000069],
              [45.266838070000063, 1.989256088000047],
              [45.266790394000054, 1.989247522000085],
              [45.26589645200005, 1.989119034000055],
              [45.264576825000063, 1.988862058000052],
              [45.263640315000089, 1.988519423000071],
              [45.263129491000086, 1.988133958000049],
              [45.262533531000088, 1.987876982000046],
              [45.26210784400007, 1.98749151800007],
              [45.261554452000041, 1.987148882000042],
              [45.260779429000081, 1.986531442000057],
              [45.260065591000057, 1.986210214000039],
              [45.259137601000077, 1.98596037100009],
              [45.258378956000058, 1.985581546000049],
              [45.257617528000083, 1.985248422000041],
              [45.256332619000034, 1.984249048000038],
              [45.255380835000039, 1.983892129000083],
              [45.254643202000068, 1.983725567000079],
              [45.253834185000073, 1.983487621000052],
              [45.252406509000082, 1.982868961000065],
              [45.250407761000076, 1.982083739000075],
              [45.246505445000082, 1.980203964000054],
              [45.245253446000049, 1.980213973000048],
              [45.243721476000076, 1.979204591000041],
              [45.240899032000073, 1.978750604000084],
              [45.238129743000059, 1.977515173000086],
              [45.235631309000041, 1.976492005000068],
              [45.234536757000058, 1.975135712000053],
              [45.233465999000089, 1.974160133000055],
              [45.232083129000046, 1.97250451900004],
              [45.231654826000067, 1.96982762500005],
              [45.228945023000051, 1.96721210700008],
              [45.226765034000039, 1.966044282000041],
              [45.223543647000042, 1.96435675400005],
              [45.221304171000043, 1.963724308000053],
              [45.219665125000063, 1.962691131000042],
              [45.219284411000046, 1.963143229000082],
              [45.218662969000036, 1.964045535000082],
              [45.215950384000053, 1.964081227000065],
              [45.213380566000069, 1.964045535000082],
              [45.211122860000046, 1.963452559000075],
              [45.207672642000034, 1.962476980000076],
              [45.206197376000034, 1.96209626600006],
              [45.204279127000063, 1.960761879000074],
              [45.203636673000062, 1.960012349000067],
              [45.203080282000087, 1.959383681000077],
              [45.202985104000049, 1.958193950000066],
              [45.203651353000055, 1.958193950000066],
              [45.204698316000076, 1.957884620000073],
              [45.204032066000082, 1.957837031000054],
              [45.203603763000046, 1.957075603000078],
              [45.202818541000056, 1.956647300000043],
              [45.201819168000043, 1.956409354000073],
              [45.201723989000072, 1.956123819000084],
              [45.200891178000063, 1.956171408000046],
              [45.202580595000086, 1.958098772000085],
              [45.201031163000039, 1.959334202000093],
              [45.200245941000048, 1.959869581000078],
              [45.19772649500004, 1.959907162000093],
              [45.196179845000074, 1.959502654000062],
              [45.195570300000043, 1.959012975000064],
              [45.194799758000045, 1.958384307000074],
              [45.194035548000045, 1.958406212000057],
              [45.190421549000064, 1.957218371000067],
              [45.190540523000038, 1.956766273000085],
              [45.189041462000034, 1.956361765000054],
              [45.188351418000082, 1.955885873000057],
              [45.187923115000046, 1.95526721300007],
              [45.187542402000076, 1.955362391000051],
              [45.186828563000063, 1.954505785000038],
              [45.186852358000067, 1.953577796000047],
              [45.185781601000087, 1.953078109000046],
              [45.184663254000043, 1.952126324000062],
              [45.184169614000041, 1.952063773000077],
              [45.183649200000048, 1.951017986000068],
              [45.183782853000082, 1.950222756000073],
              [45.183330756000089, 1.949699274000068],
              [45.18211444800005, 1.949518926000053],
              [45.181293534000076, 1.94855524400009],
              [45.180758155000035, 1.948269709000044],
              [45.179452234000053, 1.948461955000084],
              [45.179937241000061, 1.949090623000075],
              [45.179473246000043, 1.949697385000093],
              [45.177617267000073, 1.949411850000047],
              [45.176501703000042, 1.948699901000055],
              [45.175297292000039, 1.948126941000055],
              [45.172147289000065, 1.948009857000045],
              [45.170478884000033, 1.947306027000081],
              [45.169015515000069, 1.946235269000056],
              [45.167445071000088, 1.943558376000055],
              [45.166231546000063, 1.942701770000042],
              [45.164723371000036, 1.942441918000043],
              [45.16269804600006, 1.942630386000076],
              [45.160416546000079, 1.941918437000083],
              [45.159179226000049, 1.941537723000067],
              [45.157180479000033, 1.940062457000067],
              [45.155919365000045, 1.939396208000062],
              [45.155562446000033, 1.938444424000068],
              [45.155181732000074, 1.937611612000069],
              [45.155523971000036, 1.937419366000086],
              [45.154158564000056, 1.936064963000092],
              [45.153061229000059, 1.935670463000065],
              [45.152017049000051, 1.935065589000089],
              [45.150571475000049, 1.93497314800004],
              [45.149661382000033, 1.934327956000061],
              [45.149224239000034, 1.93448632500008],
              [45.148376474000088, 1.934090010000091],
              [45.148138527000071, 1.934208983000076],
              [45.148064361000081, 1.935384927000086],
              [45.14749865400006, 1.935825535000049],
              [45.146708068000066, 1.936170149000077],
              [45.146136998000088, 1.936419993000072],
              [45.145423159000075, 1.936741220000044],
              [45.144423786000061, 1.936919680000074],
              [45.142808535000086, 1.936659828000074],
              [45.141269407000038, 1.93655368900005],
              [45.140000771000075, 1.936279114000058],
              [45.138192380000078, 1.935612865000053],
              [45.135075286000074, 1.934399340000084],
              [45.132315112000072, 1.932709923000061],
              [45.130768462000049, 1.931900906000067],
              [45.129412169000034, 1.93128224600008],
              [45.128183964000073, 1.93085205400007],
              [45.127398742000082, 1.930209600000069],
              [45.126506444000086, 1.928924691000077],
              [45.126363677000086, 1.926925943000072],
              [45.128118412000049, 1.927039956000044],
              [45.128005505000033, 1.926640408000083],
              [45.126675789000046, 1.925809486000048],
              [45.126006758000074, 1.926354873000093],
              [45.125469163000048, 1.925750079000068],
              [45.125257227000077, 1.925391191000074],
              [45.124962577000076, 1.925309799000047],
              [45.124462890000075, 1.925309799000047],
              [45.123972318000085, 1.925391191000074],
              [45.123579707000033, 1.925426883000057],
              [45.123222788000078, 1.924998580000079],
              [45.122892446000037, 1.924881496000069],
              [45.122606911000048, 1.924691139000061],
              [45.122249992000036, 1.92459596100008],
              [45.121916867000039, 1.924691139000061],
              [45.12168803600008, 1.924891504000072],
              [45.121616652000057, 1.925034272000062],
              [45.121224041000062, 1.92471304500009],
              [45.120938506000073, 1.924284742000054],
              [45.120465396000043, 1.924096274000078],
              [45.120132271000045, 1.924024890000055],
              [45.119584995000082, 1.924120068000093],
              [45.119236556000033, 1.92419642100009],
              [45.118918746000077, 1.924738728000079],
              [45.118657006000035, 1.924643550000042],
              [45.118368688000032, 1.923606595000081],
              [45.117776605000074, 1.923501409000039],
              [45.117491070000085, 1.923406230000069],
              [45.117157945000088, 1.923263462000079],
              [45.116774449000047, 1.923047422000081],
              [45.116348928000036, 1.922739981000063],
              [45.115896831000043, 1.922502035000093],
              [45.11534955500008, 1.922240294000062],
              [45.11472811200008, 1.921786308000094],
              [45.114088440000046, 1.92164542900008],
              [45.113478953000083, 1.921137520000059],
              [45.112515214000041, 1.920786934000091],
              [45.111908451000033, 1.920358631000056],
              [45.111946926000087, 1.919884628000091],
              [45.111328266000044, 1.919503914000074],
              [45.111090320000073, 1.91893284300005],
              [45.110281303000079, 1.918813870000065],
              [45.109948178000081, 1.918028648000075],
              [45.109353313000042, 1.91745757800004],
              [45.108806037000079, 1.916957891000038],
              [45.108258761000059, 1.91652958800006],
              [45.107235593000041, 1.916006106000054],
              [45.105332024000063, 1.915387447000057],
              [45.105189256000074, 1.914959144000079],
              [45.104689569000072, 1.915030527000056],
              [45.104618186000039, 1.914673608000044],
              [45.103923303000045, 1.914465113000063],
              [45.102985472000057, 1.914041162000046],
              [45.101667654000039, 1.913602851000064],
              [45.100121004000073, 1.912841423000089],
              [45.099145425000074, 1.913293521000071],
              [45.097836722000068, 1.912698656000089],
              [45.096789759000046, 1.911651693000067],
              [45.096765964000042, 1.910842676000073],
              [45.095311711000079, 1.910757506000039],
              [45.092221194000047, 1.911128211000062],
              [45.090864901000089, 1.910652319000064],
              [45.089603787000044, 1.909724329000085],
              [45.088747181000087, 1.908724956000071],
              [45.088533029000075, 1.908106296000085],
              [45.088080932000082, 1.908058707000066],
              [45.087557450000077, 1.908201474000066],
              [45.086415309000074, 1.908034912000062],
              [45.085725265000065, 1.907820761000039],
              [45.084678302000043, 1.907321074000038],
              [45.084321383000088, 1.906797592000089],
              [45.084440356000073, 1.906274111000073],
              [45.083274420000066, 1.905917192000061],
              [45.083332143000064, 1.905216671000062],
              [45.082703350000088, 1.904608488000065],
              [45.08275093900005, 1.903751882000051],
              [45.083488572000078, 1.903918445000045],
              [45.083726518000049, 1.903490142000066],
              [45.083084064000047, 1.903133222000065],
              [45.082179868000082, 1.902133849000052],
              [45.081251879000035, 1.901705546000073],
              [45.080062148000081, 1.900944118000041],
              [45.079134158000045, 1.900087512000084],
              [45.077873044000057, 1.899207112000056],
              [45.07732298500008, 1.899157633000073],
              [45.076255010000068, 1.899611620000087],
              [45.076635724000084, 1.900230280000073],
              [45.076302600000076, 1.900896529000079],
              [45.075731529000052, 1.901015502000064],
              [45.075017691000085, 1.90099170700006],
              [45.074518004000083, 1.900682378000056],
              [45.073542425000085, 1.900967913000045],
              [45.071258142000033, 1.899397469000064],
              [45.069045244000051, 1.897612873000071],
              [45.065499847000069, 1.895066850000092],
              [45.063620072000049, 1.893758146000039],
              [45.063331755000036, 1.892840164000063],
              [45.062014301000033, 1.892439804000048],
              [45.059931908000067, 1.891806988000042],
              [45.057005171000071, 1.89028413300008],
              [45.055196781000063, 1.889308554000081],
              [45.052722499000083, 1.888257921000047],
              [45.050390269000047, 1.887214628000038],
              [45.048367727000084, 1.886048692000088],
              [45.047368354000071, 1.885382443000083],
              [45.045821704000048, 1.884977935000052],
              [45.044417822000071, 1.883930972000087],
              [45.042990146000079, 1.882574679000072],
              [45.041230252000048, 1.881097975000046],
              [45.037422207000077, 1.878196471000081],
              [45.035835643000041, 1.877541673000053],
              [45.034209934000046, 1.876530848000073],
              [45.031735295000033, 1.875055583000062],
              [45.030474181000045, 1.873461344000077],
              [45.030069672000081, 1.872224024000047],
              [45.029451012000038, 1.871867105000092],
              [45.027787725000053, 1.871126168000046],
              [45.024715885000035, 1.867893405000075],
              [45.023285426000086, 1.866463839000062],
              [45.021051515000067, 1.864990462000094],
              [45.020075936000069, 1.865133230000083],
              [45.019038088000059, 1.863394334000077],
              [45.018254504000083, 1.861464848000082],
              [45.016530539000087, 1.860207746000071],
              [45.015435987000046, 1.859993594000059],
              [45.014365230000067, 1.859018015000061],
              [45.013246883000079, 1.858589712000082],
              [45.012720619000049, 1.857505168000046],
              [45.010828948000039, 1.856434411000066],
              [45.009115736000069, 1.855363654000087],
              [45.008426777000068, 1.854783034000093],
              [45.007926034000036, 1.854894237000053],
              [45.007512382000073, 1.85528226200006],
              [45.007155463000061, 1.85513949400007],
              [45.006560598000078, 1.854758780000054],
              [45.005989527000054, 1.855020521000085],
              [45.00651300800007, 1.856162662000088],
              [45.005466046000038, 1.856852706000041],
              [45.004181137000046, 1.856590965000066],
              [45.002691191000054, 1.855577805000053],
              [44.999255652000045, 1.853711817000089],
              [44.993411293000065, 1.850295402000086],
              [44.990665798000066, 1.847096916000055],
              [44.989586257000042, 1.846213655000042],
              [44.98493129700006, 1.843289778000042],
              [44.983527415000083, 1.842766297000082],
              [44.98183799800006, 1.841338620000045],
              [44.981121377000079, 1.839909054000088],
              [44.975993638000034, 1.836982317000093],
              [44.972153591000051, 1.83517581600006],
              [44.968942376000086, 1.832549577000066],
              [44.963873067000065, 1.829941002000055],
              [44.961541195000052, 1.82889403900009],
              [44.957755070000076, 1.826488894000079],
              [44.952213708000045, 1.823254716000065],
              [44.949072820000083, 1.821351148000076],
              [44.945143616000053, 1.819161439000084],
              [44.942862426000033, 1.817520215000059],
              [44.940205544000037, 1.815917906000038],
              [44.937698996000051, 1.81414138100007],
              [44.932749717000036, 1.81142879500004],
              [44.928751963000082, 1.809327304000078],
              [44.925025584000082, 1.807286644000044],
              [44.921899375000066, 1.806003624000084],
              [44.919850256000075, 1.806430038000087],
              [44.916673676000073, 1.805359281000051],
              [44.913333315000045, 1.803766931000041],
              [44.910644524000077, 1.801363675000061],
              [44.909811713000067, 1.80017394500004],
              [44.909274015000051, 1.798837501000094],
              [44.908978902000058, 1.797961046000069],
              [44.908107616000052, 1.797364292000054],
              [44.906861181000068, 1.796890288000043],
              [44.90624252200007, 1.796105066000052],
              [44.905696414000033, 1.796193912000092],
              [44.904851647000044, 1.79603811000004],
              [44.904166471000053, 1.795697918000087],
              [44.90409969500007, 1.795821103000094],
              [44.904085017000057, 1.795942281000066],
              [44.904156888000045, 1.796066859000064],
              [44.904176054000061, 1.79620101900008],
              [44.90412480100008, 1.796533369000088],
              [44.903696498000045, 1.79643819100005],
              [44.903291990000071, 1.79620024500008],
              [44.903054044000044, 1.795771942000044],
              [44.903125428000067, 1.795224666000081],
              [44.903581916000064, 1.795166068000071],
              [44.903840653000088, 1.795338560000062],
              [44.903821487000073, 1.795146903000045],
              [44.903457338000067, 1.794739630000038],
              [44.903227349000076, 1.794562347000067],
              [44.902911276000054, 1.794653595000057],
              [44.902482973000076, 1.79551020100007],
              [44.90126944800005, 1.794939130000046],
              [44.899437263000038, 1.79389216800007],
              [44.898006804000033, 1.792652959000065],
              [44.89529421800006, 1.79051144400006],
              [44.891727809000088, 1.78937119200009],
              [44.888372769000057, 1.786896552000087],
              [44.885469827000065, 1.784921600000075],
              [44.883518669000068, 1.783446334000075],
              [44.881874058000051, 1.782337995000091],
              [44.879304240000067, 1.780910319000043],
              [44.875761626000042, 1.778758796000091],
              [44.873129539000047, 1.776555905000066],
              [44.86956034800005, 1.774521466000067],
              [44.867671459000064, 1.773595365000062],
              [44.866253992000054, 1.77244662600009],
              [44.865506149000055, 1.771977332000063],
              [44.863948529000083, 1.770555305000073],
              [44.863031510000042, 1.76990720100008],
              [44.859450024000068, 1.76737859800005],
              [44.857710632000078, 1.766240942000081],
              [44.850679325000044, 1.761743760000059],
              [44.848804394000069, 1.760838898000088],
              [44.846772071000089, 1.759858077000047],
              [44.845637650000072, 1.759532751000052],
              [44.844947992000073, 1.758834594000064],
              [44.843757876000041, 1.758128869000075],
              [44.842925064000042, 1.757986101000085],
              [44.842092253000033, 1.757534003000046],
              [44.841473593000046, 1.756653603000075],
              [44.841164263000053, 1.75622530000004],
              [44.840307657000039, 1.75529731000006],
              [44.837248954000074, 1.753437465000047],
              [44.832733911000048, 1.750493906000088],
              [44.828695887000038, 1.747873392000088],
              [44.826355569000043, 1.746560110000075],
              [44.824923217000048, 1.745759373000055],
              [44.824648584000045, 1.745539916000041],
              [44.823519039000075, 1.744848058000059],
              [44.822168496000074, 1.744131491000076],
              [44.82121083800007, 1.74353847000009],
              [44.820326846000057, 1.743093704000046],
              [44.817085544000065, 1.741314639000052],
              [44.81541578100007, 1.740301560000091],
              [44.810897519000036, 1.737403763000088],
              [44.807946987000037, 1.73573814100007],
              [44.806560276000084, 1.734475976000056],
              [44.804762693000043, 1.73323365300007],
              [44.801879362000079, 1.731240959000047],
              [44.801345580000088, 1.730541377000065],
              [44.800237534000075, 1.729432569000039],
              [44.799499901000047, 1.729170828000065],
              [44.798452938000082, 1.728980471000057],
              [44.797501154000088, 1.72821904400007],
              [44.796573164000051, 1.727362438000057],
              [44.795169282000074, 1.726957929000093],
              [44.793931962000045, 1.72595855600008],
              [44.79219495600006, 1.724982977000082],
              [44.791005225000049, 1.724031192000041],
              [44.789815495000084, 1.723079408000046],
              [44.789101656000071, 1.722175213000071],
              [44.788164601000062, 1.722122561000049],
              [44.787388444000044, 1.721746910000093],
              [44.786055946000033, 1.720795125000052],
              [44.785532465000074, 1.72029543900004],
              [44.785056573000077, 1.719938520000085],
              [44.784437913000033, 1.719700573000068],
              [44.783843047000062, 1.719415038000079],
              [44.783414744000083, 1.719081914000071],
              [44.782748495000078, 1.718606021000085],
              [44.781336795000072, 1.717932226000073],
              [44.780747467000083, 1.717413326000042],
              [44.780297651000069, 1.717368702000044],
              [44.779750374000059, 1.717154550000089],
              [44.779084125000054, 1.716987988000085],
              [44.778536849000034, 1.716797631000077],
              [44.778060957000037, 1.716631069000073],
              [44.777656449000062, 1.716369328000042],
              [44.77720435100008, 1.716036203000044],
              [44.776765701000045, 1.715542717000062],
              [44.776078151000036, 1.715122654000083],
              [44.775587045000066, 1.714579043000072],
              [44.774850385000036, 1.714208399000086],
              [44.774015873000053, 1.713490180000065],
              [44.773302035000086, 1.713085672000091],
              [44.772611991000076, 1.712823931000059],
              [44.771969537000075, 1.712800137000045],
              [44.770896978000053, 1.712429307000093],
              [44.770405871000037, 1.712157501000092],
              [44.770184874000051, 1.712108082000043],
              [44.769620101000044, 1.712231630000076],
              [44.76947276900006, 1.71230575900006],
              [44.769114184000045, 1.71253839600007],
              [44.76859070200004, 1.712800137000045],
              [44.768114810000043, 1.71268116300007],
              [44.767543740000065, 1.712229066000077],
              [44.766874414000085, 1.711691752000092],
              [44.766183311000077, 1.711904963000052],
              [44.765449814000078, 1.711610406000091],
              [44.764640797000084, 1.711420049000083],
              [44.764093521000063, 1.710753800000077],
              [44.763070353000046, 1.710206524000057],
              [44.761499909000065, 1.708707464000042],
              [44.759792811000068, 1.706584838000083],
              [44.758302909000065, 1.705214077000051],
              [44.757499310000071, 1.704408624000052],
              [44.756903350000073, 1.703894589000072],
              [44.756605369000056, 1.703637571000058],
              [44.756146121000086, 1.702925373000085],
              [44.754647061000071, 1.70166425900004],
              [44.753647687000068, 1.700760064000065],
              [44.752814876000059, 1.69940377100005],
              [44.74981546500004, 1.696696802000076],
              [44.749483630000043, 1.696358061000069],
              [44.749198095000054, 1.696167704000061],
              [44.749018184000079, 1.695676106000064],
              [44.748772631000065, 1.695330168000055],
              [44.748453411000071, 1.694885391000071],
              [44.748207858000058, 1.694465323000088],
              [44.748134192000066, 1.694094675000088],
              [44.748134192000066, 1.693476928000052],
              [44.748183303000076, 1.692908601000056],
              [44.748256969000067, 1.692488533000073],
              [44.748502522000081, 1.692216724000048],
              [44.748674409000046, 1.692142595000064],
              [44.748993628000051, 1.691821366000056],
              [44.749212368000087, 1.691527878000045],
              [44.749198428000057, 1.691514852000068],
              [44.749047560000065, 1.691419966000069],
              [44.749067294000042, 1.691351878000091],
              [44.748576188000072, 1.690832970000088],
              [44.748330635000059, 1.690684711000074],
              [44.748035971000036, 1.690363482000066],
              [44.747765862000051, 1.69009167300004],
              [44.747643086000039, 1.689968123000085],
              [44.747225645000071, 1.689770444000089],
              [44.746808205000036, 1.689696314000059],
              [44.746488985000042, 1.689646894000077],
              [44.746366209000087, 1.689622184000086],
              [44.746120655000084, 1.689375085000052],
              [44.74584305500008, 1.689314856000067],
              [44.745628904000057, 1.689505213000075],
              [44.744991111000047, 1.689819864000071],
              [44.744573670000079, 1.689819864000071],
              [44.744033453000043, 1.689696314000059],
              [44.743714234000038, 1.689572764000047],
              [44.743626127000084, 1.689548584000079],
              [44.743621937000057, 1.689544368000043],
              [44.743515710000054, 1.689351956000053],
              [44.743332088000045, 1.689269832000093],
              [44.742107301000033, 1.688910348000093],
              [44.741445747000057, 1.688337980000085],
              [44.740346500000044, 1.687839591000056],
              [44.739275743000064, 1.686911601000077],
              [44.738393305000045, 1.685887551000064],
              [44.737021421000065, 1.68461056600006],
              [44.735944497000048, 1.683746918000054],
              [44.734552029000042, 1.682677830000046],
              [44.732391312000061, 1.681987566000089],
              [44.731396696000047, 1.681400842000073],
              [44.730328969000084, 1.680463261000057],
              [44.730019639000034, 1.679915985000093],
              [44.729686515000083, 1.679463888000043],
              [44.72947236300007, 1.678702460000068],
              [44.728972677000058, 1.678060006000067],
              [44.728163660000064, 1.678083800000081],
              [44.727583257000049, 1.677854902000092],
              [44.726997724000057, 1.677250989000072],
              [44.726164913000048, 1.677060632000064],
              [44.725260717000083, 1.677084427000068],
              [44.72449929000004, 1.677132016000087],
              [44.723714068000049, 1.676775097000075],
              [44.722619516000066, 1.676180232000092],
              [44.721620142000063, 1.675537777000045],
              [44.720858715000077, 1.675133269000071],
              [44.720121082000048, 1.674681171000088],
              [44.719478627000058, 1.674514609000084],
              [44.718978940000056, 1.675014296000086],
              [44.719084748000057, 1.675549956000054],
              [44.719036132000042, 1.675562187000082],
              [44.718790579000085, 1.675735157000076],
              [44.71845545900004, 1.675728134000053],
              [44.718027156000062, 1.675680545000091],
              [44.717503675000046, 1.675490188000083],
              [44.717122961000086, 1.675299831000075],
              [44.716718452000066, 1.67518085800009],
              [44.716337739000039, 1.674966707000067],
              [44.71595702500008, 1.674585993000051],
              [44.715409749000059, 1.674800144000073],
              [44.713671527000088, 1.673231263000048],
              [44.712697163000087, 1.67192099600004],
              [44.710294437000073, 1.670101246000058],
              [44.708109013000069, 1.668346823000093],
              [44.703988336000066, 1.665139533000058],
              [44.702417892000085, 1.663854624000066],
              [44.701275751000082, 1.66283145500006],
              [44.699351601000046, 1.660820421000039],
              [44.698951635000071, 1.660658307000062],
              [44.698094207000054, 1.659657414000094],
              [44.696945131000064, 1.658762577000061],
              [44.697254461000057, 1.657287311000061],
              [44.696808413000042, 1.656641725000043],
              [44.695496681000066, 1.655493887000091],
              [44.690449203000071, 1.652242854000065],
              [44.687308314000063, 1.650220312000044],
              [44.685071864000065, 1.648730176000072],
              [44.683263231000069, 1.648173976000066],
              [44.68257318700006, 1.647317370000053],
              [44.682168679000085, 1.646793888000047],
              [44.68150243000008, 1.646175228000061],
              [44.680526851000081, 1.645651747000045],
              [44.679337120000071, 1.644890319000069],
              [44.678194979000068, 1.644152686000041],
              [44.677671497000063, 1.644033713000056],
              [44.677235363000079, 1.643863463000059],
              [44.676957659000038, 1.643676794000044],
              [44.676243821000071, 1.643391259000055],
              [44.675671429000033, 1.644155391000083],
              [44.675278544000037, 1.644328365000092],
              [44.67488565900004, 1.644402496000055],
              [44.674517329000082, 1.644501338000055],
              [44.674075333000076, 1.644402496000055],
              [44.673707003000061, 1.644229523000092],
              [44.673314118000064, 1.644229523000092],
              [44.673019454000041, 1.644402496000055],
              [44.672823012000038, 1.644451917000083],
              [44.672700235000036, 1.644476628000064],
              [44.672331905000078, 1.644501338000055],
              [44.672061796000037, 1.644402496000055],
              [44.671840799000051, 1.644229523000092],
              [44.671644356000058, 1.644056549000084],
              [44.671472469000037, 1.644031839000093],
              [44.671276026000044, 1.644180102000064],
              [44.670932252000057, 1.644451917000083],
              [44.670735809000064, 1.644600180000054],
              [44.670441145000041, 1.644624891000092],
              [44.670240546000059, 1.644593834000091],
              [44.670191051000074, 1.644565373000091],
              [44.670020777000047, 1.644265509000093],
              [44.670009208000067, 1.644242225000085],
              [44.669113153000069, 1.643953676000081],
              [44.668201243000055, 1.643296081000074],
              [44.667055327000071, 1.642711177000081],
              [44.66702745300006, 1.642706190000069],
              [44.663056296000036, 1.640171978000069],
              [44.65844545200008, 1.63644323300008],
              [44.656769503000078, 1.635480754000071],
              [44.654278787000067, 1.633517354000048],
              [44.654259186000047, 1.633455715000082],
              [44.654136410000035, 1.633307451000064],
              [44.65391541200006, 1.633109766000075],
              [44.653620748000037, 1.632887370000049],
              [44.653350639000053, 1.632640264000088],
              [44.653080531000057, 1.63236844700009],
              [44.652761311000063, 1.632096630000092],
              [44.652417537000076, 1.631824813000094],
              [44.652098318000071, 1.631775391000076],
              [44.651803654000048, 1.631701259000067],
              [44.651558100000045, 1.63155299500005],
              [44.651116105000085, 1.631404731000089],
              [44.650895107000053, 1.63135531000006],
              [44.650354890000074, 1.630836386000055],
              [44.648046689000068, 1.629081928000062],
              [44.645517491000078, 1.627006229000074],
              [44.643121724000082, 1.62518838200009],
              [44.639967987000034, 1.623027800000045],
              [44.638666555000043, 1.622138212000038],
              [44.638136599000063, 1.621789135000085],
              [44.63764989200007, 1.621123024000042],
              [44.637253185000077, 1.620850225000083],
              [44.63731185100005, 1.620702631000086],
              [44.636923127000045, 1.620482589000062],
              [44.636702129000071, 1.620383746000073],
              [44.636530242000049, 1.620284903000083],
              [44.636358355000084, 1.620111927000039],
              [44.636284689000036, 1.619667133000064],
              [44.636333799000056, 1.619444736000048],
              [44.636432021000076, 1.619296471000041],
              [44.636628463000079, 1.619024652000064],
              [44.636653018000061, 1.618851676000077],
              [44.636653018000061, 1.618456303000073],
              [44.636456576000057, 1.618159773000059],
              [44.636284689000036, 1.617887954000082],
              [44.636161912000034, 1.617344316000072],
              [44.636260133000064, 1.616924232000088],
              [44.636333799000056, 1.616479436000077],
              [44.636235578000083, 1.616158195000082],
              [44.636088246000043, 1.615886376000049],
              [44.635842693000086, 1.615565135000054],
              [44.635719916000085, 1.615145050000081],
              [44.635713703000079, 1.615019994000079],
              [44.635540187000061, 1.614792995000073],
              [44.635334045000036, 1.614502568000091],
              [44.635130588000038, 1.614502568000091],
              [44.634811369000033, 1.614304881000066],
              [44.63461492600004, 1.614131905000079],
              [44.634418484000037, 1.613983639000082],
              [44.63417293100008, 1.613662398000088],
              [44.633878267000057, 1.613044626000089],
              [44.633780045000037, 1.612599830000079],
              [44.633583603000034, 1.612204456000086],
              [44.633559048000052, 1.611833792000084],
              [44.633559048000052, 1.611694760000091],
              [44.633369187000085, 1.611580122000078],
              [44.633130158000085, 1.611408305000054],
              [44.633135491000075, 1.611268792000089],
              [44.631729309000036, 1.610060787000066],
              [44.63094981100005, 1.609452848000046],
              [44.63003468800008, 1.609079431000055],
              [44.628821163000055, 1.608151441000075],
              [44.627407874000085, 1.607092544000068],
              [44.626104584000075, 1.606367739000063],
              [44.624526917000082, 1.605297790000066],
              [44.623806678000051, 1.604503956000087],
              [44.623463707000042, 1.603675607000071],
              [44.621988932000079, 1.603295947000049],
              [44.620350282000061, 1.602535914000043],
              [44.619348054000056, 1.601880850000043],
              [44.618583332000071, 1.601223898000057],
              [44.618583278000074, 1.601223734000087],
              [44.618355472000076, 1.600965828000085],
              [44.618099191000056, 1.600851203000047],
              [44.617729008000083, 1.60065060900007],
              [44.617558154000051, 1.600535984000089],
              [44.617273397000076, 1.600392703000068],
              [44.61696016500008, 1.600306734000071],
              [44.616646932000037, 1.600306734000071],
              [44.61644760300004, 1.600306734000071],
              [44.616333700000041, 1.600335391000044],
              [44.615849614000069, 1.600392703000068],
              [44.615394003000063, 1.600306734000071],
              [44.614909917000034, 1.600106141000083],
              [44.613571560000082, 1.599418391000086],
              [44.612859669000045, 1.599017203000074],
              [44.612404058000038, 1.598873922000053],
              [44.611692166000068, 1.598644671000045],
              [44.61146436100006, 1.598501390000081],
              [44.61120808000004, 1.598300796000046],
              [44.610695518000057, 1.598272140000063],
              [44.610182956000074, 1.598415421000084],
              [44.609755821000078, 1.598787953000055],
              [44.609442589000082, 1.599246453000092],
              [44.609333377000041, 1.599585382000043],
              [44.608881280000048, 1.599799533000066],
              [44.608388989000048, 1.59959032800009],
              [44.608161184000039, 1.599504359000093],
              [44.607876427000065, 1.599418391000086],
              [44.60733538900007, 1.599389734000056],
              [44.607193011000049, 1.599418391000086],
              [44.606338741000059, 1.599447047000069],
              [44.606082460000039, 1.599447047000069],
              [44.605171238000082, 1.599475703000053],
              [44.604459347000045, 1.599447047000069],
              [44.603377271000056, 1.599418391000086],
              [44.602722331000052, 1.599418391000086],
              [44.602123610000035, 1.599157079000065],
              [44.601790486000084, 1.599133284000061],
              [44.601485283000045, 1.599156964000088],
              [44.601390686000059, 1.59906176700008],
              [44.600633911000045, 1.598642901000062],
              [44.600506205000045, 1.598557224000047],
              [44.599791739000068, 1.597991143000058],
              [44.599458614000071, 1.597634224000046],
              [44.598863749000088, 1.597158332000049],
              [44.598697187000084, 1.596825207000052],
              [44.598387857000034, 1.596254136000084],
              [44.597993602000088, 1.595589425000071],
              [44.597754139000074, 1.594826315000091],
              [44.597315124000033, 1.59426402400004],
              [44.597315124000033, 1.59378206000008],
              [44.597328185000038, 1.593545477000077],
              [44.597228420000079, 1.593459421000091],
              [44.596845303000066, 1.592902518000074],
              [44.596763471000088, 1.59274696500006],
              [44.596596736000038, 1.592657476000056],
              [44.59591825800004, 1.592095184000073],
              [44.595559064000042, 1.591733711000074],
              [44.595239780000043, 1.591492728000048],
              [44.594720944000073, 1.590649290000044],
              [44.594295184000089, 1.589591646000088],
              [44.593224426000063, 1.587259774000074],
              [44.592845152000052, 1.586713238000073],
              [44.592446048000056, 1.586351764000085],
              [44.591927212000087, 1.585990289000051],
              [44.591488197000047, 1.58558865100008],
              [44.591049182000063, 1.584865701000069],
              [44.590896512000086, 1.584492578000038],
              [44.590843997000036, 1.584439730000042],
              [44.590668527000048, 1.584181645000058],
              [44.590398573000073, 1.583950727000058],
              [44.590088126000069, 1.583665476000078],
              [44.589791177000052, 1.583407391000094],
              [44.589413241000045, 1.583054222000044],
              [44.589089296000054, 1.582782554000062],
              [44.58869786300005, 1.582456552000053],
              [44.588157955000042, 1.582089800000062],
              [44.587361591000047, 1.581560047000039],
              [44.586632715000064, 1.580840125000066],
              [44.585836351000069, 1.580201704000046],
              [44.585228954000058, 1.579767034000042],
              [44.584783530000038, 1.57949536600006],
              [44.58428411500006, 1.579182947000049],
              [44.583811696000055, 1.578829777000067],
              [44.58342026300005, 1.578422274000047],
              [44.583040333000042, 1.578051260000052],
              [44.582778592000068, 1.577860903000044],
              [44.582397879000041, 1.577718135000055],
              [44.581818577000035, 1.577351997000051],
              [44.58097020200006, 1.576837735000083],
              [44.58018498000007, 1.576790145000075],
              [44.579519871000059, 1.576795089000086],
              [44.578881341000056, 1.576452376000077],
              [44.578242812000042, 1.576023985000063],
              [44.577817125000081, 1.575766951000048],
              [44.577758238000058, 1.57568991100004],
              [44.577710736000085, 1.575658042000043],
              [44.57721132100005, 1.575277705000076],
              [44.576981860000046, 1.575073953000071],
              [44.576590427000042, 1.574747950000074],
              [44.576225989000079, 1.574449113000071],
              [44.575780565000059, 1.574041609000062],
              [44.575362136000081, 1.573606938000069],
              [44.575186666000036, 1.57345752000009],
              [44.574430795000069, 1.572907389000079],
              [44.57287855900006, 1.571576208000067],
              [44.570961886000077, 1.570082024000044],
              [44.569909066000037, 1.569104012000082],
              [44.56907220800008, 1.568424836000077],
              [44.568653780000034, 1.568153166000059],
              [44.568329835000043, 1.567935830000067],
              [44.567951899000036, 1.567718494000076],
              [44.567344503000072, 1.567324572000075],
              [44.566426659000058, 1.566482394000047],
              [44.566048724000041, 1.56621072300004],
              [44.565900249000038, 1.566047721000075],
              [44.565850875000081, 1.565986991000045],
              [44.565727640000034, 1.565953920000084],
              [44.564843546000077, 1.565148140000076],
              [44.562029692000067, 1.562894093000068],
              [44.560221302000059, 1.561823336000089],
              [44.557461127000067, 1.560062535000043],
              [44.555700326000078, 1.557944814000052],
              [44.553222904000052, 1.55544449100006],
              [44.551188465000052, 1.554052506000062],
              [44.548895068000036, 1.553447633000076],
              [44.546349044000067, 1.551544064000041],
              [44.54462822000005, 1.549970262000045],
              [44.541923247000057, 1.547784516000092],
              [44.53968655400007, 1.547356213000057],
              [44.538350734000062, 1.546608214000059],
              [44.538201105000041, 1.546605010000064],
              [44.53628046700004, 1.545086327000092],
              [44.534332766000034, 1.543263540000055],
              [44.53300026800008, 1.541288587000054],
              [44.532027626000058, 1.539839960000052],
              [44.531917637000049, 1.539397204000068],
              [44.531019310000033, 1.538380146000065],
              [44.530518553000036, 1.538528366000094],
              [44.529264514000033, 1.537029352000047],
              [44.528122373000087, 1.536505871000088],
              [44.527765454000075, 1.535244756000054],
              [44.527027821000047, 1.534483329000068],
              [44.526076037000053, 1.533650517000069],
              [44.52631398300008, 1.534435740000049],
              [44.524910101000046, 1.534459534000064],
              [44.523744165000039, 1.53346016100005],
              [44.519913233000068, 1.530152710000038],
              [44.516581987000052, 1.527178383000091],
              [44.514055943000074, 1.524929162000092],
              [44.513702839000075, 1.524442003000047],
              [44.511169034000034, 1.523083363000069],
              [44.509086685000057, 1.52211013200008],
              [44.506112359000042, 1.52008759000006],
              [44.504541680000045, 1.519012858000053],
              [44.501377231000049, 1.516328041000065],
              [44.499925760000053, 1.515733176000083],
              [44.497808040000052, 1.51459103500008],
              [44.496071033000078, 1.512877823000053],
              [44.495650166000075, 1.512493420000055],
              [44.495218635000072, 1.512421497000048],
              [44.494617912000081, 1.511707019000085],
              [44.494300811000073, 1.511329871000044],
              [44.492448767000042, 1.509535034000066],
              [44.489265775000035, 1.505906002000074],
              [44.485601405000068, 1.502098864000061],
              [44.47950998500005, 1.497339942000053],
              [44.471681558000057, 1.491248522000092],
              [44.466161209000063, 1.487036876000047],
              [44.464415272000053, 1.486290138000072],
              [44.46219872100005, 1.484942684000089],
              [44.461030688000051, 1.48318026000004],
              [44.457499970000072, 1.480374385000061],
              [44.45501414000006, 1.477817937000054],
              [44.452750163000076, 1.475922904000072],
              [44.448624581000047, 1.472426986000073],
              [44.447075149000057, 1.471021215000064],
              [44.443936496000049, 1.468647454000063],
              [44.440605797000046, 1.466478333000055],
              [44.435942054000066, 1.463289855000085],
              [44.433405145000052, 1.460718148000069],
              [44.429273500000079, 1.457794773000046],
              [44.426305237000065, 1.455271072000073],
              [44.422069796000073, 1.452035005000084],
              [44.420237611000061, 1.450750096000093],
              [44.418667167000081, 1.44929862500004],
              [44.417025339000077, 1.448442019000083],
              [44.413241996000068, 1.446038763000047],
              [44.409006555000076, 1.442850285000077],
              [44.404580758000066, 1.439423862000069],
              [44.402129913000067, 1.438019980000092],
              [44.400154960000066, 1.436854044000086],
              [44.397442375000082, 1.434950475000051],
              [44.39599090300004, 1.433974896000052],
              [44.394382003000032, 1.432704543000057],
              [44.393635237000069, 1.431952354000089],
              [44.39225515000004, 1.430810213000086],
              [44.390422965000084, 1.429144590000078],
              [44.386782389000075, 1.426432004000048],
              [44.383522528000071, 1.424290490000089],
              [44.38178552100004, 1.42305317000006],
              [44.380048515000055, 1.421815850000087],
              [44.376979010000071, 1.419698130000086],
              [44.375687661000086, 1.418991736000066],
              [44.374908879000088, 1.418318042000067],
              [44.372910132000072, 1.416533447000063],
              [44.371815580000089, 1.415724430000068],
              [44.371149331000083, 1.415890992000072],
              [44.370887590000052, 1.41567684100005],
              [44.371015678000049, 1.414604194000049],
              [44.370483081000089, 1.413725683000052],
              [44.369802321000066, 1.413336534000052],
              [44.367675900000052, 1.411679688000049],
              [44.366961479000054, 1.411488989000077],
              [44.36551912300007, 1.409071947000086],
              [44.363734527000076, 1.407323043000076],
              [44.363203537000061, 1.406134227000052],
              [44.362488093000081, 1.404588552000064],
              [44.361628704000054, 1.403539700000067],
              [44.360965237000073, 1.403946098000063],
              [44.360632113000065, 1.404374401000041],
              [44.359650378000083, 1.404776152000068],
              [44.358847517000072, 1.403850920000082],
              [44.358300241000052, 1.40294672400006],
              [44.356706002000067, 1.402375654000082],
              [44.355025700000056, 1.402254792000065],
              [44.354025652000075, 1.401255340000091],
              [44.349957448000055, 1.398614216000055],
              [44.348279516000048, 1.397513933000084],
              [44.346102721000079, 1.39554471200006],
              [44.342844796000065, 1.392660395000064],
              [44.340032434000079, 1.390658356000074],
              [44.337789287000078, 1.389098261000072],
              [44.335644990000048, 1.387121420000085],
              [44.33451627900007, 1.385778062000043],
              [44.33288759800007, 1.383839652000063],
              [44.33248707100006, 1.384307045000071],
              [44.330360650000046, 1.383409576000076],
              [44.327136722000034, 1.380579094000041],
              [44.325526144000037, 1.379386656000065],
              [44.324630868000042, 1.378723811000043],
              [44.323707012000057, 1.37781764500005],
              [44.320277301000033, 1.375263302000064],
              [44.318425258000048, 1.373744502000079],
              [44.316728966000085, 1.371877221000091],
              [44.31403522800008, 1.36891195000004],
              [44.310331141000063, 1.36511493800009],
              [44.308554826000034, 1.363100761000055],
              [44.308136126000079, 1.361939250000091],
              [44.306078300000081, 1.358901632000084],
              [44.306166251000036, 1.357903527000076],
              [44.306665937000048, 1.357308662000094],
              [44.305642769000087, 1.35590478000006],
              [44.304912198000068, 1.354897494000056],
              [44.304783507000081, 1.353926080000065],
              [44.304239193000058, 1.353398075000086],
              [44.304027893000068, 1.353448114000059],
              [44.303511522000065, 1.353448114000059],
              [44.303103861000068, 1.35317458600008],
              [44.302669022000089, 1.352736940000057],
              [44.302478781000048, 1.352408706000062],
              [44.30220700700005, 1.352107825000076],
              [44.302071120000051, 1.351752238000074],
              [44.302028417000088, 1.351655537000056],
              [44.301473928000064, 1.350187823000056],
              [44.301416819000053, 1.350036658000079],
              [44.301308277000032, 1.350079225000059],
              [44.300595529000077, 1.350358747000087],
              [44.299178659000063, 1.34978744600005],
              [44.298346939000055, 1.35039443900007],
              [44.29795432800006, 1.351393813000072],
              [44.296954954000057, 1.351465196000049],
              [44.295789169000045, 1.350893348000056],
              [44.293983410000067, 1.34917090600004],
              [44.292029470000045, 1.347824621000086],
              [44.288724801000058, 1.344935465000049],
              [44.285464940000054, 1.341984933000049],
              [44.284084852000035, 1.34077140800008],
              [44.281895748000068, 1.338963018000072],
              [44.279064190000042, 1.336654941000063],
              [44.276102630000082, 1.334117286000094],
              [44.273615224000082, 1.331658073000085],
              [44.271026659000086, 1.32942273000009],
              [44.269474962000061, 1.327946113000053],
              [44.267596949000051, 1.326868335000086],
              [44.264739834000068, 1.324281743000085],
              [44.261527562000083, 1.32121223900009],
              [44.258957744000043, 1.318999340000062],
              [44.256245159000059, 1.31678644100009],
              [44.253889492000042, 1.31462113200007],
              [44.252485610000065, 1.313478991000068],
              [44.251340686000049, 1.312525317000052],
              [44.247869456000046, 1.309481496000046],
              [44.245537584000033, 1.307458954000083],
              [44.244490621000068, 1.306768910000073],
              [44.242610847000037, 1.305055699000093],
              [44.241873214000066, 1.304008736000071],
              [44.240873841000052, 1.303580433000093],
              [44.239707905000046, 1.30212896200004],
              [44.237923309000053, 1.30055851700007],
              [44.236471838000057, 1.299416376000067],
              [44.23582940700004, 1.299344967000081],
              [44.23530590200005, 1.298845305000043],
              [44.234092377000081, 1.297726959000045],
              [44.232878852000056, 1.296751380000046],
              [44.231094256000063, 1.295061962000091],
              [44.230023498000037, 1.294490892000056],
              [44.228905152000038, 1.293705670000065],
              [44.228005591000056, 1.293415746000051],
              [44.227126365000061, 1.292487303000087],
              [44.22621636100007, 1.291254825000067],
              [44.225213114000042, 1.290592486000094],
              [44.223551364000059, 1.288946748000058],
              [44.222195072000034, 1.286733849000086],
              [44.221021480000047, 1.285859579000089],
              [44.219482486000061, 1.283735728000067],
              [44.218816237000055, 1.282355641000038],
              [44.218245430000081, 1.282117031000041],
              [44.217585822000046, 1.281468220000079],
              [44.217009560000065, 1.280572870000071],
              [44.216270214000076, 1.279714439000088],
              [44.215636461000088, 1.278775745000075],
              [44.215184776000058, 1.277404473000047],
              [44.215327544000047, 1.275762644000054],
              [44.216184150000061, 1.275048806000086],
              [44.216841284000054, 1.274669982000091],
              [44.215794322000079, 1.273718197000051],
              [44.214863549000086, 1.27401374100009],
              [44.213114645000076, 1.271265463000077],
              [44.21272203400008, 1.270230398000081],
              [44.210616211000058, 1.26955225100005],
              [44.209616838000045, 1.268517186000054],
              [44.208760232000088, 1.267589196000074],
              [44.207989689000044, 1.266222895000055],
              [44.207096856000078, 1.264940713000044],
              [44.206014737000089, 1.263058212000089],
              [44.20449188200007, 1.263391336000041],
              [44.203635276000057, 1.26329615800006],
              [44.202350367000065, 1.262748882000039],
              [44.201255815000081, 1.262249195000038],
              [44.19966157600004, 1.261511562000067],
              [44.197781802000065, 1.26034562600006],
              [44.19647309800007, 1.25965558300004],
              [44.194593324000039, 1.258394468000063],
              [44.192428014000086, 1.256586078000055],
              [44.190453062000074, 1.255491526000071],
              [44.186907665000035, 1.252778940000042],
              [44.181996955000045, 1.249906832000079],
              [44.177812708000033, 1.246558398000047],
              [44.174344111000039, 1.243760783000084],
              [44.169370041000036, 1.239706672000068],
              [44.164503366000076, 1.235283424000045],
              [44.161447432000045, 1.232505932000038],
              [44.15916315000004, 1.23031682800007],
              [44.156236413000045, 1.227675627000053],
              [44.154459767000048, 1.225567518000048],
              [44.153047935000075, 1.223892284000044],
              [44.149121824000076, 1.220108941000092],
              [44.144607531000077, 1.215749642000048],
              [44.138434052000036, 1.209674049000057],
              [44.133664442000054, 1.204878501000053],
              [44.130545718000064, 1.201112963000071],
              [44.128076327000088, 1.196211039000048],
              [44.127668201000063, 1.193814007000071],
              [44.127953736000052, 1.191779568000072],
              [44.128556486000036, 1.190584172000058],
              [44.12934572100005, 1.190102048000085],
              [44.130452170000069, 1.190316200000041],
              [44.130966537000063, 1.189961170000061],
              [44.13123739200006, 1.18953097800005],
              [44.131166008000037, 1.188674372000094],
              [44.131558619000089, 1.18796053300008],
              [44.130181315000073, 1.187343763000058],
              [44.130880473000047, 1.186639932000048],
              [44.130059559000074, 1.186532857000088],
              [44.129791182000076, 1.185337021000066],
              [44.129538860000082, 1.184654972000089],
              [44.128738958000042, 1.184034423000071],
              [44.128182567000067, 1.184131490000084],
              [44.127615904000038, 1.184823390000076],
              [44.127061438000055, 1.186140246000093],
              [44.125327214000038, 1.186558540000078],
              [44.122828780000077, 1.18567814000005],
              [44.120282757000041, 1.18420287400005],
              [44.114786202000062, 1.180943012000057],
              [44.109694155000057, 1.177802124000038],
              [44.106148758000074, 1.175946144000079],
              [44.103245816000083, 1.174256727000056],
              [44.098686366000038, 1.171542253000041],
              [44.098106180000059, 1.170901687000082],
              [44.095512568000061, 1.168855351000047],
              [44.092823777000035, 1.167118344000073],
              [44.090850173000035, 1.166046046000076],
              [44.088731104000033, 1.164191607000078],
              [44.085304680000036, 1.160931745000084],
              [44.081769656000063, 1.158001124000066],
              [44.074668489000032, 1.152793989000088],
              [44.072645947000069, 1.151152161000084],
              [44.069957156000044, 1.149248592000049],
              [44.063186204000033, 1.144326051000064],
              [44.060510696000051, 1.142062620000047],
              [44.057322219000071, 1.139373829000078],
              [44.054205125000067, 1.136946778000038],
              [44.050279014000068, 1.133401382000045],
              [44.046376698000074, 1.129951163000044],
              [44.042950913000084, 1.126582062000068],
              [44.038905190000037, 1.121956174000047],
              [44.036439666000035, 1.119717592000086],
              [44.035406284000032, 1.117279186000076],
              [44.034907696000062, 1.116102700000056],
              [44.034503187000041, 1.114318104000063],
              [44.034455598000079, 1.112652482000044],
              [44.034268933000078, 1.111916256000086],
              [44.034193858000037, 1.110748913000066],
              [44.032861359000037, 1.109416415000055],
              [44.03145747700006, 1.108607398000061],
              [44.030339131000062, 1.108155300000078],
              [44.029601498000034, 1.107631819000062],
              [44.029125606000036, 1.106751418000044],
              [44.02905422200007, 1.105918607000092],
              [44.02889465800007, 1.104005671000039],
              [44.027697929000055, 1.102325621000091],
              [44.027293421000081, 1.102087675000064],
              [44.026579582000068, 1.101873523000052],
              [44.025437441000065, 1.10044584700006],
              [44.025746771000058, 1.099137143000064],
              [44.026222663000055, 1.097923618000038],
              [44.024866370000041, 1.097304958000052],
              [44.024390478000043, 1.09656732500008],
              [44.024438067000062, 1.095710719000067],
              [44.023652845000072, 1.095306211000093],
              [44.023248337000041, 1.094497194000041],
              [44.023866997000084, 1.093807150000089],
              [44.023914586000046, 1.093402642000058],
              [44.024103349000086, 1.09254717400006],
              [44.023629051000057, 1.092165322000085],
              [44.022915212000044, 1.091855992000092],
              [44.023153158000071, 1.091332511000076],
              [44.022439320000046, 1.091165949000072],
              [44.022177579000072, 1.090594878000047],
              [44.022058606000087, 1.089762067000038],
              [44.021487536000052, 1.089452737000045],
              [44.021249590000082, 1.089024434000066],
              [44.021225795000078, 1.088239212000076],
              [44.020440573000087, 1.087953676000041],
              [44.019726735000063, 1.088619925000046],
              [44.019607762000078, 1.090404521000039],
              [44.018156290000036, 1.09135630600008],
              [44.015324732000067, 1.091070770000044],
              [44.01296906500005, 1.090166575000069],
              [44.008923982000056, 1.088310596000042],
              [44.004997871000057, 1.085812161000092],
              [44.002927740000075, 1.085026939000045],
              [43.996431811000036, 1.08102944500007],
              [43.993314717000032, 1.078697573000056],
              [43.990483159000064, 1.076556058000051],
              [43.988698563000071, 1.075294944000063],
              [43.984962809000081, 1.072844099000065],
              [43.981490501000053, 1.071036463000041],
              [43.97634593500004, 1.067239025000049],
              [43.969591491000074, 1.062231703000066],
              [43.965883493000035, 1.059514319000073],
              [43.964332882000065, 1.058234208000044],
              [43.962119984000083, 1.056616175000045],
              [43.960089108000034, 1.055225283000084],
              [43.954434325000079, 1.050120246000063],
              [43.952125174000059, 1.047958362000088],
              [43.951055490000044, 1.046765206000089],
              [43.950317857000073, 1.046122752000088],
              [43.949722992000034, 1.045456503000082],
              [43.948937770000043, 1.044671281000092],
              [43.948081164000087, 1.044623691000083],
              [43.947081790000084, 1.043100836000065],
              [43.946677282000053, 1.042125257000066],
              [43.945915854000077, 1.041292446000057],
              [43.945582730000069, 1.041125884000053],
              [43.94489268600006, 1.040507224000066],
              [43.944726124000056, 1.039793386000042],
              [43.94385483800005, 1.039946161000046],
              [43.942784080000081, 1.03876832800006],
              [43.943033924000076, 1.037876030000064],
              [43.942846350000082, 1.037461514000086],
              [43.942418047000046, 1.037509103000048],
              [43.942013538000083, 1.03703321100005],
              [43.941870771000083, 1.036200400000041],
              [43.941537646000086, 1.036295578000079],
              [43.940371710000079, 1.035772097000063],
              [43.93991961200004, 1.035034464000091],
              [43.938706087000071, 1.032678797000074],
              [43.938253990000078, 1.031536656000071],
              [43.937778098000081, 1.030418309000083],
              [43.937348827000051, 1.029633179000086],
              [43.93723082200006, 1.028395767000063],
              [43.93730955500007, 1.027698414000042],
              [43.937397384000064, 1.026920502000053],
              [43.93806363300007, 1.025421441000049],
              [43.939110596000035, 1.024303094000061],
              [43.939657872000055, 1.023779613000045],
              [43.938991623000049, 1.023256132000085],
              [43.937944660000085, 1.02194742800009],
              [43.937778098000081, 1.022470910000038],
              [43.936873902000059, 1.022994391000054],
              [43.936545281000065, 1.023025062000045],
              [43.935089307000055, 1.023160953000058],
              [43.931972213000051, 1.021471536000092],
              [43.925404900000046, 1.018021317000091],
              [43.920289059000083, 1.014785250000045],
              [43.915244602000087, 1.011691951000046],
              [43.911318491000088, 1.008955571000058],
              [43.904751179000073, 1.004006292000042],
              [43.900506479000057, 1.000897222000049],
              [43.897993510000049, 0.998581121000086],
              [43.896304092000037, 0.997296212000037],
              [43.895471281000084, 0.996534785000051],
              [43.89397222000008, 0.994702600000039],
              [43.89249695500007, 0.993608048000056],
              [43.891069278000089, 0.992180371000075],
              [43.88993619200005, 0.992252628000074],
              [43.88885637900006, 0.990705105000075],
              [43.887999773000047, 0.990015061000065],
              [43.88752388100005, 0.98934881200006],
              [43.887190757000042, 0.988468412000088],
              [43.885572723000053, 0.989063277000071],
              [43.88433540300008, 0.988563590000069],
              [43.882241478000083, 0.987207297000054],
              [43.880006207000065, 0.98574237400004],
              [43.878029832000038, 0.984447123000052],
              [43.874936532000049, 0.98197248300005],
              [43.872604661000082, 0.980592396000077],
              [43.870058637000056, 0.979569228000059],
              [43.867131900000061, 0.977475302000073],
              [43.865728018000084, 0.976142804000062],
              [43.863562709000064, 0.974382003000073],
              [43.859612804000051, 0.972073925000075],
              [43.855639104000034, 0.969147188000079],
              [43.851974734000066, 0.966743933000089],
              [43.846287822000079, 0.962103984000066],
              [43.843456263000064, 0.960033853000084],
              [43.839910866000082, 0.956821580000053],
              [43.837864530000047, 0.955274931000076],
              [43.834771231000047, 0.953656897000087],
              [43.831725521000067, 0.951229847000093],
              [43.829250881000064, 0.948969359000046],
              [43.826904763000073, 0.947102745000052],
              [43.824563343000079, 0.945162221000089],
              [43.822731158000067, 0.943425215000048],
              [43.821136919000082, 0.942187895000075],
              [43.818352950000076, 0.938261785000066],
              [43.814926526000079, 0.935882323000044],
              [43.810238988000037, 0.931718267000065],
              [43.804013915000041, 0.927695089000053],
              [43.799007932000052, 0.924365732000069],
              [43.793868296000085, 0.920986898000081],
              [43.78941870400007, 0.917893598000092],
              [43.78430286300005, 0.914086461000068],
              [43.779805681000084, 0.910422091000044],
              [43.778734924000048, 0.91001758200008],
              [43.777973496000072, 0.90923236000009],
              [43.776814752000064, 0.907767857000067],
              [43.775808187000052, 0.906591159000072],
              [43.775118143000043, 0.905353839000043],
              [43.77373805600007, 0.90435446500004],
              [43.771786898000073, 0.90249848600007],
              [43.770731778000084, 0.901983453000071],
              [43.769245903000069, 0.900548971000092],
              [43.765336034000086, 0.896337051000046],
              [43.763530168000045, 0.894836621000081],
              [43.760746199000039, 0.892290598000045],
              [43.759508879000066, 0.890315645000044],
              [43.759009192000065, 0.889102120000075],
              [43.758628478000048, 0.88750788100009],
              [43.758530517000054, 0.886720770000068],
              [43.75874745200008, 0.885556723000093],
              [43.758095733000062, 0.885461475000056],
              [43.757248391000076, 0.885176009000077],
              [43.756867677000059, 0.884438377000038],
              [43.756272812000077, 0.884438377000038],
              [43.755773090000048, 0.884129056000063],
              [43.755392411000059, 0.883248646000084],
              [43.755487590000087, 0.882225478000066],
              [43.754295077000052, 0.880831604000093],
              [43.753586564000045, 0.880218801000069],
              [43.752396833000034, 0.879433579000079],
              [43.751599714000065, 0.878267643000072],
              [43.751201777000063, 0.878023840000083],
              [43.750868653000055, 0.877083953000067],
              [43.75144504900004, 0.876483047000079],
              [43.751123822000068, 0.875935771000059],
              [43.748951941000087, 0.876429418000043],
              [43.748101906000045, 0.876090436000084],
              [43.746269721000033, 0.874995884000043],
              [43.745234656000036, 0.873972716000083],
              [43.744318563000036, 0.872842472000059],
              [43.743664212000056, 0.872021558000085],
              [43.742641043000049, 0.871724125000071],
              [43.742248432000054, 0.87200966000006],
              [43.741487005000067, 0.870831827000075],
              [43.741475107000042, 0.86988004300008],
              [43.741713054000058, 0.86947553400006],
              [43.74060660400005, 0.868654620000086],
              [43.739902120000067, 0.868900496000037],
              [43.736252191000062, 0.865989624000065],
              [43.734265341000082, 0.864811791000079],
              [43.733444427000052, 0.864347796000061],
              [43.73281386900004, 0.863931390000062],
              [43.73230228500006, 0.863265141000056],
              [43.731636036000054, 0.86250371400007],
              [43.731588447000036, 0.861647108000057],
              [43.731243425000059, 0.860933269000043],
              [43.729768159000059, 0.860255123000059],
              [43.729479615000059, 0.859566340000072],
              [43.729030526000088, 0.85849432200007],
              [43.728197715000078, 0.858054121000066],
              [43.727543363000052, 0.857661510000071],
              [43.726793833000045, 0.857982738000089],
              [43.725865843000065, 0.858054121000066],
              [43.724580934000073, 0.857947046000049],
              [43.724212118000082, 0.856900083000085],
              [43.723236539000084, 0.855793633000076],
              [43.722260960000085, 0.854794260000062],
              [43.720821386000068, 0.853664016000039],
              [43.719643553000083, 0.853485556000066],
              [43.718453822000072, 0.852533772000072],
              [43.717335476000073, 0.851451117000067],
              [43.716502665000064, 0.850796765000041],
              [43.715872107000052, 0.850011543000051],
              [43.715479496000057, 0.849297705000083],
              [43.715562777000059, 0.848786121000046],
              [43.715170166000064, 0.848512483000093],
              [43.714741863000086, 0.84844109900007],
              [43.714408738000088, 0.847013422000089],
              [43.714170792000061, 0.847025320000057],
              [43.713837668000053, 0.847179985000082],
              [43.713409821000084, 0.846928461000061],
              [43.710125709000067, 0.845335902000045],
              [43.709232831000065, 0.84453145000009],
              [43.708150756000066, 0.842861263000088],
              [43.706580312000085, 0.842206911000062],
              [43.70443879700008, 0.840434213000094],
              [43.701806950000048, 0.838514779000093],
              [43.700120075000086, 0.837079173000063],
              [43.698332937000089, 0.835730810000086],
              [43.695596556000055, 0.833803446000047],
              [43.693083443000035, 0.832005064000043],
              [43.689442867000082, 0.829149711000071],
              [43.686307304000081, 0.826502468000058],
              [43.685245901000087, 0.825475333000043],
              [43.684151349000047, 0.824761495000075],
              [43.682449740000038, 0.823488349000058],
              [43.680843898000035, 0.822286855000073],
              [43.678788207000082, 0.820660892000092],
              [43.677800731000048, 0.819792388000053],
              [43.677170174000082, 0.819364085000075],
              [43.674041183000043, 0.816556321000064],
              [43.673291652000046, 0.816413554000064],
              [43.672030688000063, 0.815535804000092],
              [43.67105495900006, 0.814747931000056],
              [43.668945301000065, 0.812697648000039],
              [43.664963539000041, 0.809632090000093],
              [43.662768555000071, 0.807639315000074],
              [43.661382450000076, 0.806396023000048],
              [43.660668098000087, 0.805527970000071],
              [43.659991170000069, 0.804847533000043],
              [43.655827113000043, 0.800897628000087],
              [43.654101299000047, 0.800138041000082],
              [43.653233501000045, 0.799255799000093],
              [43.651376816000038, 0.797710990000041],
              [43.650425032000044, 0.796997152000074],
              [43.649373666000088, 0.796426997000083],
              [43.647897478000061, 0.795028240000079],
              [43.646713073000058, 0.792452381000089],
              [43.644524673000035, 0.790808713000047],
              [43.643096610000043, 0.789382558000057],
              [43.642620400000055, 0.78890698500004],
              [43.639147406000063, 0.785538900000063],
              [43.637718710000058, 0.784124268000085],
              [43.635744462000048, 0.781695377000062],
              [43.634732487000065, 0.781209428000068],
              [43.633054967000078, 0.780091082000069],
              [43.630557237000062, 0.777436142000056],
              [43.629152651000084, 0.776426712000045],
              [43.628105688000062, 0.775510619000045],
              [43.626535948000082, 0.773985924000044],
              [43.624395185000083, 0.772109682000064],
              [43.623115578000068, 0.770988188000047],
              [43.622413829000038, 0.770336535000069],
              [43.621538821000058, 0.768952382000066],
              [43.620051212000078, 0.766266413000039],
              [43.619725825000046, 0.765593946000081],
              [43.619694293000066, 0.765528780000068],
              [43.619660528000054, 0.765528010000082],
              [43.618915643000037, 0.76551102600007],
              [43.616708070000072, 0.763791774000083],
              [43.61506694600007, 0.762278975000072],
              [43.61389803700007, 0.760997953000071],
              [43.611544639000044, 0.75905664600009],
              [43.609130427000082, 0.756944557000054],
              [43.606547771000066, 0.754476184000055],
              [43.605948836000039, 0.754576719000056],
              [43.603675195000051, 0.752519169000038],
              [43.60163285200008, 0.750658612000052],
              [43.598351232000084, 0.747669084000051],
              [43.595495879000055, 0.745361007000042],
              [43.592712658000039, 0.743017488000078],
              [43.59146269300004, 0.741886995000073],
              [43.587559672000054, 0.73851],
              [43.583978583000089, 0.73545239300006],
              [43.581617592000043, 0.733352611000043],
              [43.579527835000079, 0.731572113000084],
              [43.568401129000051, 0.721633770000039],
              [43.566240406000077, 0.719876980000038],
              [43.563229683000088, 0.717344695000065],
              [43.560458316000052, 0.714832523000041],
              [43.558542145000047, 0.713156843000093],
              [43.555282284000043, 0.710075441000072],
              [43.552867131000085, 0.70782685100005],
              [43.551320481000062, 0.706482455000071],
              [43.548681762000058, 0.70399182400007],
              [43.546747187000051, 0.702252274000045],
              [43.545240504000049, 0.700897480000094],
              [43.542518864000044, 0.698332298000082],
              [43.540981723000073, 0.696952714000076],
              [43.536195053000085, 0.692154366000068],
              [43.532034950000082, 0.688434244000064],
              [43.527629891000061, 0.684311068000056],
              [43.522909717000061, 0.67983249200006],
              [43.520577845000048, 0.67766718300004],
              [43.519947288000083, 0.677119907000076],
              [43.51984000200008, 0.677023088000055],
              [43.339689255000053, 0.937211990000094],
              [43.261045456000033, 1.046407700000088],
              [42.980920793000053, 1.46171379000009],
              [42.95330238300005, 1.482427598000072],
              [42.900918961000059, 1.521711349000043],
              [42.520910263000076, 1.881677628000091],
              [42.400911331000088, 1.951669693000042],
              [42.515989304000072, 2.600419998000064],
              [42.542623520000063, 2.751028060000067],
              [42.60091209400008, 3.071569443000044],
              [42.930921556000044, 3.041570662000083],
              [43.100923538000075, 3.061571122000089],
              [43.100923538000075, 3.081567764000056],
              [43.102094650000083, 3.257364273000064],
              [43.120923996000045, 3.671514512000044],
              [43.140924454000071, 3.851499557000068],
              [43.100923538000075, 3.931493759000091],
              [43.091444016000082, 3.947744370000066],
              [42.890477541000052, 4.290922768000087],
              [42.885265730000071, 4.307892628000047],
              [42.898044474000073, 4.318555373000038],
              [42.913485893000086, 4.335073107000085],
              [42.92565918400004, 4.348094916000093],
              [42.939674457000081, 4.36436120400009],
              [42.952098829000079, 4.379923407000092],
              [42.969238328000074, 4.403353259000085],
              [42.981162975000075, 4.42258266500005],
              [42.99016180700005, 4.436949800000093],
              [42.997550905000082, 4.451755568000067],
              [43.00339514500007, 4.461715736000087],
              [43.007217328000081, 4.467740096000057],
              [43.009666485000082, 4.473977467000054],
              [43.012329191000049, 4.482287352000071],
              [43.021236296000041, 4.523561509000046],
              [43.021865863000073, 4.528397469000083],
              [43.023849444000064, 4.535319280000067],
              [43.026062074000038, 4.542247308000071],
              [43.030334424000046, 4.549198584000067],
              [43.034790049000037, 4.560288043000071],
              [43.039085286000045, 4.565399095000089],
              [43.044280947000061, 4.572127900000055],
              [43.080143009000039, 4.602682210000069],
              [43.122447972000089, 4.631236540000089],
              [43.127857513000038, 4.634493703000089],
              [43.169582408000053, 4.659616876000086],
              [43.235187561000089, 4.69625853000008],
              [43.324321682000061, 4.741442183000061],
              [43.421474468000042, 4.788547514000072],
              [43.434925140000075, 4.794680091000089],
              [43.469184767000058, 4.806794590000038],
              [43.543434097000045, 4.831321656000057],
              [43.659065260000034, 4.868028637000066],
              [43.813857967000047, 4.909974079000051],
              [44.007579745000044, 4.958975320000093],
              [44.015136710000036, 4.960435749000055],
              [44.030281094000088, 4.959679133000066],
              [44.243202183000051, 4.950423320000084],
              [44.592083837000075, 4.93477374400004],
              [44.609581372000036, 4.831744426000057],
              [44.677564621000045, 4.431444168000041],
              [44.680955887000039, 4.411447525000085],
              [44.749658585000077, 4.077772140000093],
              [44.750959395000052, 4.07147789000004],
              [44.800958632000061, 3.861497880000059],
              [44.770959855000058, 3.70150947500008],
              [44.78095817600007, 3.27155113200007],
              [44.879796900000088, 3.265242633000071],
              [45.070962906000034, 3.251550674000043],
              [45.120962143000042, 3.041570662000083],
            ],
          ],
        ],
      },
    },
  ],
};
