import LegendItem from "./LegendItemClass";

export const LegendItems = {
  pptanomaly: new LegendItem("#D6E8EE", "#6B829B", "#001B48"),
  tempanomaly: new LegendItem("#375FC5", "#7D4174", "#C22323"),
  era5_wind_east: new LegendItem("#001137", "#E7EB05", "#620500"),
  era5_wind_north: new LegendItem("#001137", "#E7EB05", "#620500"),
  gfs_wind_east: new LegendItem("001137", "E7EB05", "620500"),
  gfs_wind_north: new LegendItem("001137", "E7EB05", "620500"),
  spi: new LegendItem("#D6E8EE", "#6B829B", "#001B48"),
  ndvianomaly: new LegendItem("#DA2A2A", "#FFF9AB", "#3AD03A"),
  vci: new LegendItem("#DA2A2A", "#FFF9AB", "#3AD03A"),
  cloud: new LegendItem("#209C05", "#EBFF0A", "#ff0A0A"),
  co: new LegendItem("#209C05", "#EBFF0A", "#ff0A0A"),
  ozone: new LegendItem("209C05", "EBFF0A", "ff0A0A"),
  so2: new LegendItem("209C05", "EBFF0A", "ff0A0A"),
  ch4: new LegendItem("209C05", "EBFF0A", "ff0A0A"),
};
