import axios from "axios";

export async function SignUpUSer(credentials) {
  try {
    //const URL = "https://sews.firsake.com/auth";
    const URL = "https://apisews.somrep.org/register";

    /*   const repsonse = await fetch(`${URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
      //credentials: false,
    });

    let data = await repsonse.json(); */

    const params = {
      method: "post",
      data: credentials,
      //url: "https://sews.firsake.com/auth/login",
      url: "https://apisews.somrep.org/register/",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: false,
    };

    const response = await axios(params);

    console.log(response.data);

    return response.data;
  } catch (err) {
    console.error("error", err);
    return err;
  }
}
